import { AddBeneficiary } from "./AddBeneficiary/AddBeneficiary";
import { BeneficiariesList } from "./BeneficiariesList/BeneficiariesList";

import { useState } from "react";

import "./styles.scss";

export type BeneficiariesCreateActions = "single" | "bulk";

export const AdminBeneficiariesPanel = () => {
    const [displayAddBeneficiary, setDisplayAddBeneficiary] = useState(false);
    const [preselectedAction, setPreselectedAction] = useState<BeneficiariesCreateActions>();

    const handleDisplayAddBeneficiary = (display: boolean, action?: BeneficiariesCreateActions) => {
        setDisplayAddBeneficiary(display);
        if (action) {
            setPreselectedAction(action);
        } else {
            setPreselectedAction(undefined);
        }
    };

    return (
        <div className={"admin-beneficiaries-container"}>
            {!displayAddBeneficiary ? (
                <BeneficiariesList handleDisplayAddBeneficiary={handleDisplayAddBeneficiary} />
            ) : (
                <AddBeneficiary
                    handleSwitchToTable={() => handleDisplayAddBeneficiary(false)}
                    preselectedAction={preselectedAction}
                />
            )}
        </div>
    );
};
