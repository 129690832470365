import { ColumnItems, ExportFrequencies, exportFormatOptions } from "../../const";
import { ExportCompareSelectedWithTotal, exportCheckNameValidation } from "../Export/Personalised";
import { DrawerHeaderForm } from "../shared/DrawerHeader";
import { NameFieldExport } from "../shared/NameFIeldExport";
import { SelectFrequency } from "./SelectFrequency";
import {
    billingtransactionStatusesFilterOptions,
    getTransactionTypeSlugs,
    transactionTypesFilterOptions,
    transactionTypesSlug,
} from "src/shared/const/transactions";
import { ExportFormatData, RegularTemplateFormData } from "src/shared/models/Export";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getWalletsAsSelectOptions } from "src/modules/wallets/selectors";
import { fetchWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { ColumnSelection } from "src/components/Export/Drawers/shared/ColumnSelection";
import { ExportFormat } from "src/components/Export/Drawers/shared/ExportFormat";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

type ExportTemplateRegularCreationProps = {
    isScheduled?: boolean;
    handleFormDataChange: (newData: RegularTemplateFormData) => void;
    template: RegularTemplateFormData | undefined;
    sendFormValidation: (validation: boolean) => void;
};

export const ExportTemplateRegularCreation = ({
    isScheduled,
    handleFormDataChange,
    template,
    sendFormValidation,
}: ExportTemplateRegularCreationProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const exportFrequecies = useMemo(() => ExportFrequencies(t), [t]);
    const walletOptions = useAppSelector((state) => getWalletsAsSelectOptions(state));

    const [validatedForm, setValidatedForm] = useState(false);
    const [exportFormatFormValidation, setExportFormatFormValidation] = useState(false);
    const [name, setName] = useState(template ? template.name : "");
    const [selectedWallets, setSelectedWallets] = useState<SelectOption[] | null>();
    const [status, setStatus] = useState<SelectOption[] | null>(
        billingtransactionStatusesFilterOptions(t).map((option) => option)
    );
    const [transactionTypes, setTransactionTypes] = useState<SelectOption[] | null>(
        transactionTypesFilterOptions.map((option) => option)
    );
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [selectedFrequency, setSelectedFrequency] = useState<SelectOption>();
    const [listEmails, setListEmails] = useState<Array<string>>();
    const [exportFormats, setExportFormats] = useState<Array<string>>();
    const [errorNameMessage, setErrorNameMessage] = useState<string | undefined>();
    const columnItems = useMemo(() => ColumnItems(t), [t]);

    useEffect(() => {
        if (!walletOptions.length) {
            dispatch(fetchWalletsAuthUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFormData = () => {
        const selectedWalletIds = selectedWallets?.map((wallet) => wallet.id) ?? [];
        const selectedStatuses = status?.map((s) => s.id) ?? [];
        const selectedTransactionTypes = transactionTypes?.map((type) => type.id) ?? [];

        const finalTransactionTypes = ExportCompareSelectedWithTotal(
            selectedTransactionTypes,
            transactionTypesFilterOptions
        );
        const finalStatuses = ExportCompareSelectedWithTotal(
            selectedStatuses,
            billingtransactionStatusesFilterOptions(t)
        );
        const finalExportFormats = ExportCompareSelectedWithTotal(exportFormats, exportFormatOptions);
        const finalColumns = ExportCompareSelectedWithTotal(selectedColumns, columnItems);

        return {
            name,
            frequency: isScheduled ? selectedFrequency?.id : undefined,
            walletIds: selectedWalletIds.length > 0 ? selectedWalletIds : undefined,
            transactionStatus: finalStatuses,
            transactionTypes:
                finalTransactionTypes && !finalTransactionTypes.includes("all")
                    ? getTransactionTypeSlugs(finalTransactionTypes)
                    : finalTransactionTypes,
            recipients: listEmails,
            formats: finalExportFormats,
            columns: finalColumns,
        };
    };

    useEffect(() => {
        if (template) {
            if (template.walletIds) {
                const walletIdStrings = template.walletIds.map((id) => id.toString());
                const selectedWalletsFromTemplate = walletOptions.filter((wallet) =>
                    walletIdStrings.includes(wallet.id)
                );
                setSelectedWallets(selectedWalletsFromTemplate);
            }
            if (template.transactionTypes) {
                const selectedTransactionTypes = template.transactionTypes?.includes("all")
                    ? transactionTypesFilterOptions.map((option) => option)
                    : (template?.transactionTypes.map((slug: string) => {
                          return transactionTypesFilterOptions.find(
                              (option) => transactionTypesSlug[option.id as any] === slug
                          );
                      }) as SelectOption[]);

                setTransactionTypes(selectedTransactionTypes);
            }
            if (template.transactionStatus) {
                const selectedTransactionStatus = template.transactionStatus.includes("all")
                    ? billingtransactionStatusesFilterOptions(t).map((option) => option)
                    : billingtransactionStatusesFilterOptions(t).filter((transactionStatus) =>
                          template?.transactionStatus?.includes(transactionStatus.id)
                      );
                setStatus(selectedTransactionStatus);
            }
            if (template.frequency) {
                const selectedFrequencyFromTemplate = exportFrequecies.filter((frequency) =>
                    template?.frequency?.includes(frequency.id)
                );
                setSelectedFrequency(selectedFrequencyFromTemplate[0]);
            }
            if (template.columns) {
                setSelectedColumns(template.columns);
            }
        }
    }, [template, walletOptions, exportFrequecies]);

    useEffect(() => {
        handleFormDataChange(getFormData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        name,
        selectedFrequency,
        selectedWallets,
        transactionTypes,
        selectedFrequency,
        selectedColumns,
        listEmails,
        exportFormats,
    ]);

    useEffect(() => {
        const isNameValid = exportCheckNameValidation(name);
        setErrorNameMessage(name.length > 0 && !isNameValid ? t<string>("Export.name-must-be-alphanum") : undefined);
        const isAtLeastSelectedOneColumn = !!(selectedColumns && selectedColumns.length > 0);
        const isWalletSelected = !!(selectedWallets && selectedWallets.length > 0);
        let isFrequencyValid = true;

        if (isScheduled) {
            isFrequencyValid = selectedFrequency !== undefined;
        }

        const isFormValid = isNameValid && isFrequencyValid && isAtLeastSelectedOneColumn && isWalletSelected;

        setValidatedForm(isFormValid);
    }, [name, selectedWallets, selectedFrequency, isScheduled, selectedColumns]);

    useEffect(() => {
        sendFormValidation(validatedForm && exportFormatFormValidation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatedForm, exportFormatFormValidation]);

    const handleChangeName = (name: string) => {
        setName(name);
    };

    const handleSetSelectedWallets = (newSelectedWallets: SelectOption[]) => {
        setSelectedWallets(newSelectedWallets.length === 0 ? null : newSelectedWallets);
    };

    const handleSetStatus = (newStatus: SelectOption[]) => {
        setStatus(newStatus.length === 0 ? null : newStatus);
    };

    const handleSetTransactionTypes = (newStatus: SelectOption[]) => {
        setTransactionTypes(newStatus.length === 0 ? null : newStatus);
    };

    const onExportFormatDataChange = (exportFormatData: ExportFormatData) => {
        if (exportFormatData.formats) {
            setExportFormats(exportFormatData.formats);
        }
        if (exportFormatData.recipients) {
            setListEmails(exportFormatData.recipients);
        }
    };

    const onColumnDataChange = (columnData: string[]) => {
        setSelectedColumns(columnData);
    };

    const handleSelectFrequency = (frequency: SelectOption) => {
        setSelectedFrequency(frequency);
    };

    const handleExportFormatValidation = (validation: boolean) => {
        setExportFormatFormValidation(validation);
    };

    return (
        <div className='create-export-template-personalised'>
            <div className='drawer-content-section'>
                <DrawerHeaderForm isFormValid={validatedForm} formTitle={t("Export.informations")} />
                <div className='drawer-content-section-body'>
                    <div className='create-export-template-form-container'>
                        <NameFieldExport
                            name={name}
                            handleChangeName={handleChangeName}
                            errorNameMessage={errorNameMessage}
                        />
                        {isScheduled && (
                            <SelectFrequency
                                selectedFrequency={selectedFrequency}
                                setSelectedFrequency={handleSelectFrequency}
                            />
                        )}
                        <SelectMultipleOptions
                            label={t<string>("CommonUse.accounts")}
                            selected={selectedWallets}
                            items={walletOptions}
                            name={t("Export.select-export-accounts")}
                            onSelectionChange={handleSetSelectedWallets}
                            allSelectable
                            mandatory
                        />
                        <SelectMultipleOptions
                            label={t<string>("Transactions.transactions-status")}
                            selected={status}
                            items={billingtransactionStatusesFilterOptions(t)}
                            name={t("Export.select-export-transactions-status")}
                            onSelectionChange={handleSetStatus}
                            allSelectable
                        />
                        <SelectMultipleOptions
                            label={t<string>("Transactions.transactions-types")}
                            selected={transactionTypes}
                            items={transactionTypesFilterOptions}
                            name={t("Export.select-export-transactions-types")}
                            onSelectionChange={handleSetTransactionTypes}
                            allSelectable
                        />
                        <ColumnSelection handleColumnDataChange={onColumnDataChange} template={template} />
                    </div>
                </div>
            </div>

            <ExportFormat
                handleFormDataChanged={onExportFormatDataChange}
                template={template}
                sendIsFormValidated={handleExportFormatValidation}
            />
        </div>
    );
};
