import classnames from "classnames";
import moment from "moment";
import { EditUserContext } from "src/shared/context/editUser";
import { AdminAccountsFormsProfile, ProfileParams } from "../../Forms/Profile/Profile";

import { useContext, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { getUserDocument } from "src/modules/documents/selectors";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

export function AdminAccountDrawerPersonalInfo() {
    const { editingSection, setEditingSection, canRoleEdit, setPayload, handleOpenPasswordDialog } =
        useContext(EditUserContext);
    const { t } = useTranslation();

    const { userDetail } = useAppSelector((state) => getUserDetail(state));
    const { loading } = useAppSelector((state) => getUserDocument(state));

    const isEditing = editingSection === "profile";

    const [color, text] = useMemo(() => {
        if (!loading && !canRoleEdit) {
            return ["color-lilas-500", "Non modifiable"];
        } else if (canRoleEdit) {
            return isEditing
                ? ["color-warning", t("CommonUse.being-modified")]
                : ["color-lilas-900", t("CommonUse.modifiable")];
        }
        return ["", ""];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing, canRoleEdit, loading]);

    const handleToggleEditing = () => {
        if (canRoleEdit) {
            setEditingSection(!isEditing ? "profile" : null);
            setPayload(null);
        }
    };

    const handleChangeValue = (values: ProfileParams) => {
        const dateFormat = values.dateOfBirth?.includes("-") ? "YYYY-MM-DD" : "M/D/YYYY, hh:mm:ss A";
        console.log(moment(values.dateOfBirth, dateFormat).format("MM/DD/YYYY"));
        const params = {
            email: values.email ?? "",
            gender: values.gender,
            dateOfBirth: values.dateOfBirth,
            countryOfCitizenship: values.countryOfCitizenship?.toUpperCase(),
            firstname: values.firstname,
            lastname: values.lastname,
            customReference: values.customReference,
            accessType: userDetail?.accessType,
            ...(userDetail?.addWaveCollect && {
                collectorInfo: {
                    countryCode: userDetail.waveCountryCode,
                    phone: userDetail.wavePhone ?? userDetail.phone,
                    walletId: userDetail.waveWalletId,
                },
            }),
        };
        setPayload(params);
    };

    const handleOpenConfirmPassword = () => {
        handleOpenPasswordDialog();
    };

    return (
        <div
            className={classnames("drawer-content-section users-details-drawer-personal-info", {
                disabled: editingSection && !isEditing,
            })}
        >
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("CommonUse.personal-information")} />
                <div className={classnames({ disabled: editingSection && !isEditing })} onClick={handleToggleEditing}>
                    <Typography className={`fw-bold cursor-pointer ${color}`} message={text} />
                </div>
            </div>
            <div className='drawer-content-section-body drawer-content-personal-info'>
                {!isEditing ? (
                    <>
                        <div className='personal-info-item'>
                            <Typography className='fw-bold text-muted color-neutral-500' message={t("Users.user")} />
                            <Typography
                                className='fw-bold text-muted'
                                message={
                                    (userDetail?.gender === 0 ? t("CommonUse.mr") : t("CommonUse.mme")) +
                                    " " +
                                    userDetail?.firstname +
                                    " " +
                                    userDetail?.lastname?.toUpperCase()
                                }
                            />
                        </div>
                        <div className='personal-info-item'>
                            <Typography
                                className='fw-bold text-muted color-neutral-500'
                                message={t("Users.user-reference")}
                            />
                            <Typography
                                className='fw-bold text-muted'
                                message={
                                    userDetail?.UserCompanies &&
                                    userDetail.UserCompanies.length > 0 &&
                                    userDetail.UserCompanies[0].customReference !== null
                                        ? userDetail.UserCompanies[0].customReference
                                        : "-"
                                }
                            />
                        </div>
                    </>
                ) : (
                    <AdminAccountsFormsProfile detail={userDetail} onChange={handleChangeValue} />
                )}
            </div>
            {isEditing && (
                <div className='drawer-content-section-footer'>
                    <div className='button-part'>
                        <Button
                            label={t("CommonUse.cancel")}
                            variant='tertiary'
                            className='flex-grow-1'
                            onClick={handleToggleEditing}
                        />
                        <Button
                            className='flex-grow-1'
                            label={t("CommonUse.modify")}
                            onClick={handleOpenConfirmPassword}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
