import { BankDisposalFormType, RECHARGEMENT_STEP } from "../../Panel";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import { WalletApi } from "src/services/wallets/types";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getBankDisposalWallets } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { WalletSelectionDrawer } from "src/components/WalletSelectionDrawer/Drawer";
import { DrawerInput } from "src/shared/atoms/Inputs/Drawer/Input";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
    step: RECHARGEMENT_STEP;
    handleSetNextStep: () => void;
};

export const WalletSelectDrawerContainer = ({ form, step, handleSetNextStep }: Props) => {
    const wallets = useAppSelector((state) => getBankDisposalWallets(state));
    const { t } = useTranslation();
    const selectedWallet = useMemo(() => {
        return wallets.find((item) => String(item.id) === String(form.values.walletId));
    }, [wallets, form.values.walletId]);

    const [showDrawer, setShowDrawer] = useState(false);

    const isWalletRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_WALLET);

    useEffect(() => {
        if (step === RECHARGEMENT_STEP.WALLET_SELECT && !form.values.walletId) {
            setShowDrawer(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (step === RECHARGEMENT_STEP.WALLET_SELECT && wallets.length === 1) {
            handleSetNextStep();
        }
    }, [step, wallets]);

    const handleToggleDrawer = () => {
        setShowDrawer(!showDrawer);
    };

    const onSelectWallet = (wallet: WalletApi) => {
        form.setFieldValue("walletId", wallet.id);
        handleToggleDrawer();
        handleSetNextStep();
    };

    return (
        <div className='wallet-select-drawer'>
            <DrawerInput
                label={
                    !selectedWallet
                        ? t<string>("WalletSelectDrawerContainer.selectionnez-un-compte-a-crediter")
                        : `${selectedWallet.label} - ${selectedWallet.reference}`
                }
                handleClickDrawerInput={handleToggleDrawer}
                icon='wallet'
            />
            <WalletSelectionDrawer
                handleSelectedWallet={onSelectWallet}
                isOpen={showDrawer}
                onClose={handleToggleDrawer}
                walletType='TOPUP'
                isWalletRestricted={isWalletRestricted}
                title='WalletSelectDrawerContainer.selectionnez-un-compte-a-crediter'
                id='topup_bank-transfer_option_wallet'
            />
        </div>
    );
};
