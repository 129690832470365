import { AdminExpensePolicyCreateDrawerAmount } from "../CreateDrawer/Sections/Amount";
import { AdminExpensePolicyCreateDrawerBeneficiary } from "../CreateDrawer/Sections/Beneficiary";
import { AdminExpensePolicyCreateDrawerGeneral } from "../CreateDrawer/Sections/General";
import { AdminExpensePolicyCreateDrawerUsers } from "../CreateDrawer/Sections/Users";
import { AdminExpensePolicyCreateDrawerWallets } from "../CreateDrawer/Sections/Wallets";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getAdminExpensePoliciesCreateForm,
    getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicyOptions,
} from "src/modules/admin/expensePolicies/selectors";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    openValidationModal: (action?: "edit" | "delete") => () => void;
};

export function AdminExpensePolicyEditDrawerBody({ openValidationModal }: Props) {
    const { t } = useTranslation();

    const [edit, setEdit] = useState(false);

    const {
        loading,
        excludedWallets,
        excludedUsers,
        limitPerOperation,
        limitPerUnitOfTime,
        unitOfTime,
        isForBeneficiariesOnly,
        title,
    } = useAppSelector((state) => getAdminExpensePoliciesCreateForm(state));
    const walletsWithExpensePolicy = useAppSelector((state) =>
        getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicyOptions(state)
    );

    const walletsToDisplay = [...(excludedWallets ?? []), ...walletsWithExpensePolicy];

    const handleSwitchEdit = () => {
        setEdit((oldEdit) => !oldEdit);
    };

    return (
        <>
            <div className='drawer-content-section edit-expense-policy-drawer'>
                <div className='header'>
                    <Typography className='fw-bold' message={t("Expense-policy.rule-parameters")} />
                    <Button
                        onClick={handleSwitchEdit}
                        label={edit ? t("CommonUse.being-modified") : t("CommonUse.modify")}
                        variant='ghost'
                        color={edit ? "warning" : "lilas"}
                    />
                </div>
                <div className='divider drawer-divider mb-3 mt-2' />
                {edit ? (
                    <div className='edition'>
                        <AdminExpensePolicyCreateDrawerWallets />
                        <AdminExpensePolicyCreateDrawerUsers />
                        <AdminExpensePolicyCreateDrawerAmount />
                        <AdminExpensePolicyCreateDrawerBeneficiary />
                        <AdminExpensePolicyCreateDrawerGeneral />
                        <div className='divider drawer-divider' />
                        <div className='buttons'>
                            <Button variant='tertiary' label={t("CommonUse.cancel")} onClick={handleSwitchEdit} />
                            <Button
                                disabled={!title}
                                label={t("CommonUse.modify")}
                                onClick={openValidationModal("edit")}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className='label-or-description'>
                            <Typography
                                className='element color-neutral-500 fw-bold'
                                message={t("Expense-policy.impacted-wallets")}
                            />
                            <Typography
                                className='element fw-bold'
                                message={t(
                                    excludedWallets?.length || walletsWithExpensePolicy.length
                                        ? "Expense-policy.all-wallets-except-names"
                                        : "Expense-policy.all-wallets",
                                    {
                                        amount: walletsToDisplay?.length ?? 0,
                                        names: walletsToDisplay?.map((option) => option.label).join(", "),
                                    }
                                )}
                            />
                        </div>
                        <div className='label-or-description'>
                            <Typography
                                className='element color-neutral-500 fw-bold'
                                message={t("Expense-policy.signataires-impacted")}
                            />
                            <Typography
                                className='element fw-bold'
                                message={t<string>(
                                    excludedUsers?.length
                                        ? "Expense-policy.all-signatories-except-names"
                                        : "Wallets.all-signatories",
                                    {
                                        amount: excludedUsers?.length ?? 0,
                                        names: excludedUsers?.map((option) => option.label).join(", "),
                                    }
                                )}
                            />
                        </div>
                        <div className='label-or-description'>
                            <Typography
                                className='element color-neutral-500 fw-bold'
                                message={t("Expense-policy.maximum-amount-without-approve")}
                            />
                            <Typography
                                className='element fw-bold'
                                message={t("Expense-policy.limit-money-of-operation", {
                                    limitPerOperation: formatMoneyToString({
                                        amount: limitPerOperation,
                                    }),
                                    limitPerUnitOfTime: formatMoneyToString({
                                        amount: limitPerUnitOfTime,
                                    }),
                                    unitOfTime: t(unitOfTime?.label ?? ""),
                                })}
                            />
                        </div>
                        <div className='label-or-description centered'>
                            <Typography
                                className='element color-neutral-500 fw-bold'
                                message={t("Expense-policy.pay-only-beneficiaries")}
                            />
                            <div className='element fw-bold'>
                                <Tag
                                    type='accent'
                                    size='lg'
                                    label={t<string>(
                                        isForBeneficiariesOnly ? "Expense-policy.active" : "Expense-policy.disabled"
                                    )}
                                    icon={isForBeneficiariesOnly ? "check-circle" : "forbidden"}
                                    color={isForBeneficiariesOnly ? "success" : "neutral"}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className='drawer-content-section p-3 mt-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <Typography message={t("Expense-policy.delete-the-rule")} className='fw-bold' />
                    <Button
                        variant='ghost'
                        onClick={openValidationModal("delete")}
                        disabled={loading}
                        color='error'
                        label={t("CommonUse.delete")}
                    />
                </div>
            </div>
        </>
    );
}
