import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminWalletsPanel } from "src/components/Admin/Wallets/Panel";

const AdminWallets = () => {
    return <AdminWalletsPanel />;
};

export const AdminWalletsPage = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminWallets
);
