import { TaskListApiReturnItem } from "src/services/tasks/types";
import { computeTimestampToString } from "src/shared/utils/formatDate";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import "src/components/History/Wallet/Drawer/Sections/styles.scss";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

type Props = {
    task: TaskListApiReturnItem;
};

export function TasksDetailsDrawerSectionsDetails({ task }: Props) {
    const { metadata, createdAt, TaskSignatures, description, Wallet } = task;
    const totalAmount = metadata?.sum ?? 0;
    const totalFees = metadata?.fees ?? 0;
    const { t } = useTranslation();
    const sentAt =
        Array.isArray(TaskSignatures) && TaskSignatures.length > 0
            ? TaskSignatures[TaskSignatures.length - 1].createdAt
            : null;

    return (
        <div className='task-details-drawer drawer-content-section'>
            <Accordion
                icon='information-circle'
                iconColor='white'
                iconSize='sm'
                preSelectedOpen
                title={t("Tasks.task-detail")}
                color='white'
            >
                <div className='task-details-body'>
                    <div className='task-details-body-item'>
                        <Typography className='fw-bold color-neutral-500' message={t("Transactions.operation-on")} />
                        <Typography
                            className='drawer-section-content fw-bold'
                            message={`${Wallet.label} - ${Wallet.reference}`}
                        />
                    </div>
                    <div className='task-details-body-item'>
                        <Typography className='fw-bold color-neutral-500' message={t("CommonUse.description")} />
                        <Typography
                            className='drawer-section-content fw-bold'
                            message={description?.trim().length ? description : "-"}
                        />
                    </div>

                    <div className='task-details-body-item'>
                        <Typography className='fw-bold color-neutral-500' message={t("Tasks.total-amount")} />
                        <Typography
                            className='drawer-section-content fw-bold'
                            message={`- ${formatMoneyToString({ amount: totalAmount, noValue: true })}`}
                        />
                    </div>
                    <div className='task-details-body-item'>
                        <Typography className='fw-bold color-neutral-500' message={t("Tasks.total-fees")} />
                        <Typography
                            className='drawer-section-content fw-bold'
                            message={totalFees ? `- ${formatMoneyToString({ amount: totalFees })}` : "-"}
                        />
                    </div>
                    <div className='task-details-body-item'>
                        <Typography
                            className='drawer-section-label fw-bold color-neutral-500'
                            message={t("Tasks.nb-receivers")}
                        />
                        <Typography
                            className='drawer-section-content fw-bold'
                            message={t(`Tasks.amount-receiver`, { amount: metadata?.total ?? 0 })}
                        />
                    </div>
                    <div className='task-details-body-item'>
                        <Typography
                            className='drawer-section-label fw-bold color-neutral-500'
                            message={t("Tasks.created-at")}
                        />
                        <Typography
                            className='drawer-section-content fw-bold'
                            message={computeTimestampToString(createdAt, "DD/MM/YY - HH:mm")}
                        />
                    </div>
                    <div className='task-details-body-item'>
                        <Typography
                            className='drawer-section-label fw-bold color-neutral-500'
                            message={t("Tasks.sent-at")}
                        />
                        <Typography
                            className='drawer-section-content  fw-bold'
                            message={sentAt ? computeTimestampToString(sentAt, "DD/MM/YY - HH:mm") : "-"}
                        />
                    </div>
                </div>
            </Accordion>
        </div>
    );
}
