import {
    failedRequestSendWallets,
    failedRequestWallets,
    receiveAuthorizedWallets,
    receiveBankDisposalWallets,
    receiveSendWallets,
    receiveWallet,
    receiveWallets,
    requestSendWallets,
    requestWallets,
} from "./slice";
import { requestAuthUserWallets, requestWallet } from "src/services/wallets/operations";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchWallet = createAsyncThunk(
    "fetchWalletsAuthUser",
    async ({ walletId }: { walletId: string | number }, { dispatch }) => {
        dispatch(requestWallets());

        try {
            const wallet = await requestWallet({ walletId });
            dispatch(receiveWallet({ wallet }));
        } catch (error) {
            dispatch(failedRequestWallets());
        }
    }
);

export const fetchWalletsAuthUser = createAsyncThunk(
    "fetchWalletsAuthUser",
    async (_props: undefined, { dispatch }) => {
        dispatch(requestWallets());

        try {
            const wallets = await requestAuthUserWallets({ access: "READ" });
            dispatch(receiveWallets({ wallets }));
        } catch (error) {
            dispatch(failedRequestWallets());
        }
    }
);

export const fetchAuthorizedWalletsAuthUser = createAsyncThunk(
    "fetchAuthorizedWalletsAuthUser",
    async (_props: undefined, { dispatch }) => {
        try {
            const wallets = await requestAuthUserWallets({ access: "INIT" });
            dispatch(receiveAuthorizedWallets({ wallets }));
        } catch (error) {
            dispatch(failedRequestWallets());
        }
    }
);

export const fetchBankDisposalWalletsAuthUser = createAsyncThunk(
    "fetchBankDisposalWalletsAuthUser",
    async (_props: undefined, { dispatch }) => {
        dispatch(requestWallets());

        try {
            const wallets = await requestAuthUserWallets({ access: "TOPUP" });
            dispatch(receiveBankDisposalWallets({ wallets }));
        } catch (error) {
            dispatch(failedRequestWallets());
        }
    }
);

export const fetchSendWalletsAuthUser = createAsyncThunk(
    "fetchBankDisposalWalletsAuthUser",
    async (_props: undefined, { dispatch }) => {
        dispatch(requestSendWallets());

        try {
            const wallets = await requestAuthUserWallets({ access: "SEND" });
            dispatch(receiveSendWallets({ wallets }));
        } catch (error) {
            dispatch(failedRequestSendWallets());
        }
    }
);
