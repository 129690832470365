import { CreateExportDrawer } from "./Drawers/Export/CreateDrawer";
import { ExportInformationsDrawer } from "./Drawers/Export/InformationsDrawer";
import { ExportTypeAsOptions } from "./const";
import moment from "moment";
import { fetchExportHistoryList } from "src/services/export/operations";
import { ExportTemplate } from "src/shared/models/Export";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAccountsAsOptions } from "src/modules/admin/usersList/selectors";
import { fetchUsersList } from "src/modules/admin/usersList/thunkActions";
import { getAuthUserRole } from "src/modules/auth/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { StatusIcon } from "src/shared/atoms/Icons/StatusIcons";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Select } from "src/shared/atoms/Select/Select";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { CustomDatePicker } from "src/shared/components/DatePicker/DatePicker";
import { Empty } from "src/shared/components/Empty/Empty";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const emptyListImg = require("src/shared/images/empty/welcome.png");
const emptyResearchdImg = require("src/shared/images/empty/empty-user.svg").default;

export const ExportPanel = () => {
    const { t } = useTranslation();

    const [query, setQuery] = useState("");
    const [closeSelect, setCloseSelect] = useState(false);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);
    const [informationsDrawerOpen, setInformationsDrawerOpen] = useState(false);
    const [selectedExportItem, setSelectedExportItem] = useState<ExportTemplate>();
    const [exportHistoryList, setExportHistoryList] = useState<any>([]);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastEvaluatedKeysStack, setLastEvaluatedKeysStack] = useState<any[]>([null]);
    const [filterStartDate, setFilterStartDate] = useState<Date | undefined>(undefined);
    const [filterEndDate, setFilterEndDate] = useState<Date | undefined>(undefined);
    const [activeFilters, setActiveFilters] = useState(false);

    const [selectedExportedByFilter, setSelectedExportedByFilter] = useState<SelectOption[] | undefined>();
    const [selectedExportTypeFilter, setSelectedExportTypeFilter] = useState<SelectOption[] | undefined>();

    const usersList = useAppSelector((state) => getAccountsAsOptions(state));
    const exportTypeAsOptions = useMemo(() => ExportTypeAsOptions(t), [t]);
    const userRole = useAppSelector((state) => getAuthUserRole(state));

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (userRole === "ADMIN") {
            dispatch(fetchUsersList({ page: -1, query: "", role: ["ADMIN", "USER", "READ"] }));
        }
    }, [dispatch]);

    useEffect(() => {
        handleFetchHistoryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, currentPage]);

    useEffect(() => {
        setActiveFilters(
            filterEndDate !== undefined ||
                filterStartDate !== undefined ||
                query !== "" ||
                selectedExportTypeFilter !== undefined ||
                selectedExportedByFilter !== undefined
        );
    }, [filterStartDate, filterEndDate, query, selectedExportTypeFilter, selectedExportedByFilter]);

    const handleOpenCreateDrawer = () => {
        setCreateDrawerOpen(!createDrawerOpen);
    };

    const handleOpenInformationsDrawer = (item?: any) => {
        setInformationsDrawerOpen(!informationsDrawerOpen);
        setSelectedExportItem(item);
    };

    const handleSetQueryFilter = useCallback((newQuery: string) => {
        setQuery(newQuery);
    }, []);

    const handleFetchHistoryList = async (noFilter?: boolean) => {
        setIsLoading(true);
        const lastEvaluatedKey = lastEvaluatedKeysStack[currentPage - 1];
        let payload = { limit: 20 };
        if (noFilter === false || noFilter === undefined) {
            payload = {
                ...payload,
                ...(query && { name: query }),
                ...(filterStartDate && { startDate: moment(filterStartDate).format("YYYY-MM-DD") }),
                ...(filterEndDate && { endDate: moment(filterEndDate).format("YYYY-MM-DD") }),
                ...(selectedExportTypeFilter &&
                    selectedExportTypeFilter.length > 0 && {
                        types: selectedExportTypeFilter.map((type) => type.id),
                    }),
                ...(selectedExportedByFilter &&
                    selectedExportedByFilter.length > 0 && {
                        createdBy: selectedExportedByFilter.map((user) => +user.id),
                    }),
                ...(lastEvaluatedKey && { lastEvaluatedKey }),
            };
        }

        const response = await fetchExportHistoryList(payload);
        setExportHistoryList(response.exports);
        setHasMoreItems(!!response.lastEvaluatedKey);

        if (response.lastEvaluatedKey && currentPage === lastEvaluatedKeysStack.length) {
            setLastEvaluatedKeysStack([...lastEvaluatedKeysStack, response.lastEvaluatedKey]);
        }
        setIsLoading(false);
        setCloseSelect(true);
    };

    const handlePageChange = (isNext: boolean) => {
        if (isNext && hasMoreItems) {
            setCurrentPage(currentPage + 1);
        } else if (!isNext && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleCtaButtonsInformationsDrawer = (cta: string, exportUrl?: string) => {
        if (cta === "reuse") {
            handleOpenInformationsDrawer(selectedExportItem);
            handleOpenCreateDrawer();
        } else if (cta === "download") {
            if (exportUrl) {
                const fileUrl = exportUrl;
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = fileUrl.split("/").pop() ?? "download";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    const handleChangeStartDate = (newStartDate: Date | null) => {
        setFilterStartDate(newStartDate ?? undefined);
    };

    const handleChangeEndDate = (newEndDate: Date | null) => {
        setFilterEndDate(newEndDate ?? undefined);
    };

    const handleResetAllFilters = () => {
        setFilterEndDate(undefined);
        setFilterStartDate(undefined);
        setQuery("");
        setSelectedExportTypeFilter(undefined);
        setSelectedExportedByFilter(undefined);
        setCloseSelect(true);
        handleFetchHistoryList(true);
    };

    const handleCancelFilter = () => {
        setFilterEndDate(undefined);
        setFilterStartDate(undefined);
        setSelectedExportTypeFilter(undefined);
        setSelectedExportedByFilter(undefined);
        setCloseSelect(true);
    };

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    const renderTagLabel = (exportItem: any) => {
        if (exportItem.isBankStatement === false) {
            return t("Export." + exportItem.type);
        } else {
            return t("Export.bank-statement");
        }
    };

    const renderTagIcon = (exportItem: any) => {
        if (exportItem.isBankStatement === false) {
            if (exportItem.type === "custom") {
                return "clock";
            } else {
                return "pencil-square";
            }
        } else {
            return "bank";
        }
    };

    const renderTagColor = (exportItem: any) => {
        if (exportItem.isBankStatement === false) {
            if (exportItem.type === "custom") {
                return "lilas";
            } else {
                return "neutral";
            }
        } else {
            return "success";
        }
    };

    const handleSelectedExportedByFilter = (exportedByFilter: SelectOption[]) => {
        setSelectedExportedByFilter(exportedByFilter);
    };

    const handleSelectedExportTypeFilter = (exportTypeFilter: SelectOption[]) => {
        setSelectedExportTypeFilter(exportTypeFilter);
    };

    const tableRows: SelectableTableRow[][] = exportHistoryList.map((exportItem: any) => {
        return [
            {
                content: <Avatar icon={"arrow-down-on-square"} color='lilas-900' backgroundColor='lilas-200' />,
                onClick: () => handleOpenInformationsDrawer(exportItem),
                type: "image",
            },
            {
                content: <Typography className='fw-bold' message={exportItem.name} />,
                onClick: () => handleOpenInformationsDrawer(exportItem),
                type: "text",
            },
            {
                content: (
                    <Tag
                        type='accent'
                        label={renderTagLabel(exportItem)}
                        icon={renderTagIcon(exportItem)}
                        color={renderTagColor(exportItem)}
                    />
                ),
                onClick: () => handleOpenInformationsDrawer(exportItem),
            },
            {
                content: <Typography className='fw-bold' message={computeTimestampToString(exportItem.createdAt)} />,
                onClick: () => handleOpenInformationsDrawer(exportItem),
            },
            {
                content: <Typography className='fw-bold' message={exportItem.createdBy} />,
                onClick: () => handleOpenInformationsDrawer(exportItem),
            },
            {
                content: <StatusIcon status={exportItem.status} withMessage />,
                onClick: () => handleOpenInformationsDrawer(exportItem),
            },
            {
                content: (
                    <Button
                        icon='arrow-down-on-square'
                        color='primary'
                        variant='tertiary'
                        onClick={() => handleCtaButtonsInformationsDrawer("download", exportItem.exportUrl)}
                        disabled={exportItem.status !== "downloadable"}
                    />
                ),
                type: "button",
            },
        ];
    });

    return (
        <div className='export-templates-panel-container'>
            <PageHeader>
                <div className='export-header-container'>
                    <div className='left-content'>
                        <Input
                            placeholder={t("CommonUse.search")}
                            inputIcon='magnifying-glass'
                            onChange={handleSetQueryFilter}
                            value={query}
                            className='search-input'
                        />
                        <div className='filters-select'>
                            <Select
                                forceClose={closeSelect}
                                color='white'
                                content={{
                                    header: {
                                        component: <Typography message={t("CommonUse.filters")} />,
                                        icon: "adjustments-vertical",
                                        disabled: false,
                                    },
                                    dropDownContent: {
                                        header: {
                                            title: <Typography message={t("CommonUse.filters")} />,
                                            label: (
                                                <Button
                                                    variant='ghost'
                                                    color='lilas'
                                                    onClick={handleResetAllFilters}
                                                    size='sm'
                                                    label={t("AdminAccountsFilters.reinitialiser")}
                                                />
                                            ),
                                        },
                                        body: (
                                            <div className='export-filter-container'>
                                                <CustomDatePicker
                                                    startDate={filterStartDate}
                                                    endDate={filterEndDate}
                                                    onChangeStartDate={handleChangeStartDate}
                                                    onChangeEndDate={handleChangeEndDate}
                                                />
                                                <SelectMultipleOptions
                                                    items={exportTypeAsOptions}
                                                    onSelectionChange={handleSelectedExportTypeFilter}
                                                    selected={selectedExportTypeFilter}
                                                    icon='gear'
                                                    name={t("Export.by-export-type")}
                                                />
                                                {userRole === "ADMIN" && (
                                                    <SelectMultipleOptions
                                                        items={usersList}
                                                        selected={selectedExportedByFilter}
                                                        onSelectionChange={handleSelectedExportedByFilter}
                                                        icon='user'
                                                        scrollable
                                                        name={t("Export.table-exported-by")}
                                                    />
                                                )}
                                            </div>
                                        ),
                                        footer: (
                                            <div className='footer-content'>
                                                <Button
                                                    className='background-neutral-200 footer-btn'
                                                    variant='tertiary'
                                                    onClick={handleCancelFilter}
                                                    label={t("CommonUse.cancel")}
                                                />
                                                <Button
                                                    className='footer-btn'
                                                    variant='primary'
                                                    onClick={() => handleFetchHistoryList(false)}
                                                    label={t("CommonUse.validate")}
                                                />
                                            </div>
                                        ),
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <Button label={t("Export.new-export")} icon='plus-circle' onClick={handleOpenCreateDrawer} />
                </div>
            </PageHeader>
            <div className='export-content'>
                <div className='export-template-table'>
                    <SelectableTable
                        isLoading={isLoading}
                        paginationDynamo={{
                            onPageChange: handlePageChange,
                            hasMoreItems: hasMoreItems,
                            currentPage: currentPage,
                            canGoBack: currentPage > 1,
                        }}
                        empty={
                            activeFilters ? (
                                <Empty
                                    image={emptyResearchdImg}
                                    title={t("Table.no-result-found")}
                                    description={t<string>("Table.no-result-found-description")}
                                />
                            ) : (
                                <Empty
                                    image={emptyListImg}
                                    title={t("Export.empty-title")}
                                    description={t<string>("Export.empty-msg")}
                                    button={
                                        <Button
                                            icon='plus-circle'
                                            color='primary'
                                            label={t("Export.new-export")}
                                            onClick={handleOpenCreateDrawer}
                                        />
                                    }
                                />
                            )
                        }
                        header={[
                            "",
                            t("Export.export-table-name"),
                            t("Export.export-table-type"),
                            t("Export.export-table-date"),
                            t("Export.table-exported-by"),
                            t("CommonUse.statut"),
                            "",
                        ]}
                        rows={tableRows as SelectableTableRow[][]}
                    />
                </div>
            </div>
            <CreateExportDrawer
                handleOpenCreateDrawer={handleOpenCreateDrawer}
                isOpen={createDrawerOpen}
                refreshList={handleFetchHistoryList}
                preSelectedTemplate={selectedExportItem}
            />
            <ExportInformationsDrawer
                handleOpenInformationsDrawer={handleOpenInformationsDrawer}
                isOpen={informationsDrawerOpen}
                exportItem={selectedExportItem}
                ctaButtons={handleCtaButtonsInformationsDrawer}
            />
        </div>
    );
};
