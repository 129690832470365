import {
    failedDeleteAdminBeneficiary,
    failedPostAdminBeneficiaries,
    failedRequestAdminBeneficiaries,
    receiveAdminBeneficiaries,
    receiveDeleteAdminBeneficiary,
    receivePostAdminBeneficiaries,
    requestAdminBeneficiaries,
    requestDeleteAdminBeneficiaries,
    requestPostAdminBeneficiaries,
} from "./slice";
import {
    requestDeleteAdminBeneficiary,
    requestGetAdminBeneficiaries,
    requestPostAdminBeneficiary,
    requestPostBulkAdminBeneficiary,
    requestPutAdminBeneficiary,
} from "src/services/admin/operations";
import { AdminBeneficiaryQuery } from "src/services/admin/types";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { UpdateBeneficiaryPayload } from "../../../components/Admin/Beneficiaries/DetailsDrawer/Container";
import { CreateBeneficiaryPayload } from "src/components/Admin/Beneficiaries/CreateDrawer/Container";

export const fetchAdminBeneficiaries = createAsyncThunk(
    "fetchAdminBeneficiaries",
    async (query: AdminBeneficiaryQuery, { dispatch }) => {
        dispatch(requestAdminBeneficiaries());
        try {
            const response = await requestGetAdminBeneficiaries(query);
            dispatch(receiveAdminBeneficiaries(response));
        } catch (error) {
            dispatch(failedRequestAdminBeneficiaries());
        }
    }
);

export const handleCreateAdminBeneficiary = createAsyncThunk(
    "handleCreateAdminBeneficiary",
    async (payload: CreateBeneficiaryPayload, { dispatch }) => {
        dispatch(requestPostAdminBeneficiaries());
        try {
            const response = await requestPostAdminBeneficiary(payload);
            dispatch(receivePostAdminBeneficiaries(response));
        } catch (error: any) {
            dispatch(failedPostAdminBeneficiaries(error));
        }
    }
);

export const handleCreateBulkAdminBeneficiary = createAsyncThunk(
    "handleCreateBulkAdminBeneficiary",
    async (
        {
            twoFactor,
            password,
            beneficiaryList,
        }: { twoFactor: string | null; password: string; beneficiaryList: CreateBeneficiaryPayload[] },
        { dispatch }
    ) => {
        dispatch(requestPostAdminBeneficiaries());
        try {
            const response = await requestPostBulkAdminBeneficiary({
                ...(twoFactor ? { otp: twoFactor } : {}),
                password,
                beneficiaries: beneficiaryList,
            });
            dispatch(receivePostAdminBeneficiaries(response));
        } catch (error: any) {
            dispatch(failedPostAdminBeneficiaries(error));
        }
    }
);

export const handleUpdateAdminBeneficiary = createAsyncThunk(
    "handleUpdateAdminBeneficiary",
    async (
        {
            payload,
            password,
            twoFactor,
        }: { payload: UpdateBeneficiaryPayload; password: string; twoFactor: string | null },
        { dispatch }
    ) => {
        const { id, ...data } = payload;
        dispatch(requestPostAdminBeneficiaries());
        try {
            const response = await requestPutAdminBeneficiary(id, {
                ...data,
                ...(twoFactor ? { otp: twoFactor } : {}),
                password,
            });
            dispatch(receivePostAdminBeneficiaries(response));
        } catch (error: any) {
            dispatch(failedPostAdminBeneficiaries(error));
        }
    }
);

export const handleDeleteAdminBeneficiary = createAsyncThunk(
    "handleDeleteAdminBeneficiary",
    async (
        {
            twoFactor,
            password,
            beneficiariesIds,
        }: { twoFactor: string | null; password: string; beneficiariesIds: string[] },
        { dispatch }
    ) => {
        dispatch(requestDeleteAdminBeneficiaries());
        try {
            await requestDeleteAdminBeneficiary({
                ...(twoFactor ? { otp: twoFactor } : {}),
                password,
                beneficiariesIds,
            });
            dispatch(receiveDeleteAdminBeneficiary(true));
        } catch (error: any) {
            dispatch(failedDeleteAdminBeneficiary(error));
        }
    }
);
