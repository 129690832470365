import { BeneficiaryModel } from "src/shared/models/Beneficiary";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminBeneficiaries } from "src/modules/admin/adminBeneficiaries/selectors";
import { clearBeneficiaryIntegration } from "src/modules/admin/adminBeneficiaries/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type InformationsKeys = "email" | "firstname" | "lastname" | "label";
type InformationsForm = Pick<BeneficiaryModel, InformationsKeys>;
export type InformationsParams = InformationsForm;

type Props = {
    editInformations: BeneficiaryModel | null;
    setInformations: (value: InformationsParams) => void;
    validateForms: () => void;
    isBulk?: boolean;
};

export const AdminBeneficiaryDrawerSectionInfo = ({
    editInformations,
    setInformations,
    validateForms,
    isBulk = false,
}: Props) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { integrateBeneficiaryLoading, integratedBeneficiaryResponse } = useAppSelector(getAdminBeneficiaries);

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [isEditing, setIsEditing] = useState(isBulk);
    const [email, setEmail] = useState(editInformations?.email ?? "");
    const [firstname, setFirstname] = useState(editInformations?.firstname ?? "");
    const [lastname, setLastname] = useState(editInformations?.lastname ?? "");
    const [label, setLabel] = useState(editInformations?.label ?? "");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isEmailTouched, setIsEmailTouched] = useState(false);

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsFormValidated(firstname !== "" && lastname !== "" && (email === "" || emailRegex.test(email)));
        setInformations({ email, firstname, lastname, label });
        // eslint-disable-next-line
    }, [firstname, lastname, label, email]);

    const handleChangeInput = (name: string) => (value: string) => {
        switch (name) {
            case "email":
                setEmail(value);
                if (value !== "" && isEmailTouched) {
                    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
                }
                break;
            case "firstname":
                setFirstname(value);
                break;
            case "lastname":
                setLastname(value);
                break;
            case "label":
                setLabel(value);
                break;
            default:
                break;
        }
    };

    const handleBlur = (name: string) => () => {
        if (name === "email") {
            setIsEmailTouched(true);
            if (email !== "") {
                setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
            } else {
                setIsEmailValid(true);
            }
        }
    };

    useEffect(() => {
        if (integratedBeneficiaryResponse) {
            setIsEditing(false);
            dispatch(clearBeneficiaryIntegration());
        }
    }, [integratedBeneficiaryResponse]);

    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
    };

    return (
        <div className='drawer-content-section mb-3'>
            <div className='drawer-content-section-header'>
                <Typography
                    className='fw-bold'
                    message={t("CreateBeneficiaryInformationForm.informations-sur-le-beneficiaire")}
                />
                <div onClick={handleToggleEdit}>
                    {!isEditing ? (
                        <Typography
                            className={`fw-bold cursor-pointer color-lilas-900`}
                            message={t("CommonUse.modify")}
                        />
                    ) : (
                        <Typography
                            className={`fw-bold cursor-pointer ${!isFormValidated ? "color-warning" : "color-success"}`}
                            message={!isFormValidated ? t("CommonUse.to-complete") : t("CommonUse.done")}
                        />
                    )}
                </div>
            </div>
            {isEditing ? (
                <div className='beneficiary-informations-content drawer-content-section-body'>
                    <div className='form-part'>
                        <div className='inline-inputs'>
                            <Input
                                autoFocus
                                label={t("CreateBeneficiaryInformationForm.prenom-du-beneficiaire").toString()}
                                name='firstname'
                                value={firstname}
                                placeholder={t("CreateBeneficiaryInformationForm.prenom")}
                                onChange={handleChangeInput("firstname")}
                            />
                            <Input
                                label={t("CreateBeneficiaryInformationForm.nom-du-beneficiaire").toString()}
                                name='lastname'
                                value={lastname}
                                placeholder={t("CreateBeneficiaryInformationForm.nom")}
                                onChange={handleChangeInput("lastname")}
                            />
                        </div>
                        <Input
                            label={t("CreateBeneficiaryInformationForm.adresse-mail-facultatif").toString()}
                            name='email'
                            value={email}
                            placeholder={t("CommonUse.email-exemple")}
                            onChange={handleChangeInput("email")}
                            onBlur={handleBlur("email")}
                            underMessage={!isEmailValid ? t<string>("CommonUse.invalid-email") : undefined}
                            variant={isEmailValid ? "primary" : "error"}
                        />
                        <Input
                            label={t("Beneficiaries.add-note").toString()}
                            name='label'
                            value={label}
                            placeholder={t("Beneficiaries.add-note-placeholder")}
                            onChange={handleChangeInput("label")}
                        />
                    </div>
                </div>
            ) : (
                <div className='operation-details-content drawer-content-section-body'>
                    <div className='content-item'>
                        <Typography message={t("CommonUse.firstnames")} />
                        <Typography message={editInformations?.firstname} />
                    </div>
                    <div className='content-item'>
                        <Typography message={t("CommonUse.name")} />
                        <Typography message={editInformations?.lastname} />
                    </div>
                    <div className='content-item'>
                        <Typography message={t("CommonUse.email")} />
                        <Typography message={editInformations?.email ? editInformations?.email : "-"} />
                    </div>
                    <div className='content-item'>
                        <Typography message={t("CommonUse.label")} />
                        <Typography message={editInformations?.label} />
                    </div>
                    {editInformations?.externalAccounts && (
                        <div className='content-item'>
                            <Typography message={t("CommonUse.nb-accounts")} />
                            <Typography message={editInformations?.externalAccounts.length.toString()} />
                        </div>
                    )}
                </div>
            )}

            {isEditing && (
                <div className='button-part'>
                    <Button
                        className='flex-grow-1'
                        variant='tertiary'
                        label={t("CommonUse.cancel")}
                        onClick={handleToggleEdit}
                    />
                    <Button
                        className='flex-grow-1'
                        variant='primary'
                        loading={integrateBeneficiaryLoading}
                        label={!integrateBeneficiaryLoading ? t("CommonUse.validate") : t("CommonUse.send")}
                        disabled={!isFormValidated || integrateBeneficiaryLoading}
                        onClick={validateForms}
                    />
                </div>
            )}
        </div>
    );
};
