import { BankDisposalFormType } from "../Panel";
import { useDisposalMethodList } from "../const";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import { transactionServiceSlugImage } from "src/shared/const/transactions";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getBankDisposalWallets } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
};

export const DisposalInfoContainer = ({ form }: Props) => {
    const wallets = useAppSelector((state) => getBankDisposalWallets(state));
    const { t } = useTranslation();

    const methodList = useDisposalMethodList();

    const method = useMemo(() => {
        return methodList.find((item) => item.slug === form.values.disposalMethod);
    }, [methodList, form.values.disposalMethod]);

    const selectedWallet = useMemo(() => {
        return wallets.find((item) => String(item.id) === String(form.values.walletId));
    }, [wallets, form.values.walletId]);

    return (
        <div className='disposal-info-container'>
            <div className='disposal-list-item'>
                <Typography message={t("DisposalInfoContainer.methode-choisie")} />
                <Typography message={method?.title} />
            </div>
            <div className='disposal-list-item'>
                <Typography message={t("DisposalInfoContainer.delais-estime")} />
                <Typography message={method?.description} />
            </div>
            <div className='divider' />
            <div className='disposal-list-item'>
                <Typography message={t("DisposalInfoContainer.compte-a-recharger")} />
                <Typography message={selectedWallet?.label} />
            </div>
            <div className='disposal-list-item'>
                <Typography message={t("DisposalInfoContainer.solde-disponible")} />
                <Typography message={selectedWallet ? formatMoneyToString({ amount: selectedWallet.balance }) : "-"} />
            </div>
            <div className='disposal-list-item'>
                <Typography message={t("RechargementPanel.top-up-amount")} />
                <Typography className='color-lilas-900' message={formatMoneyToString({ amount: form.values.amount })} />
            </div>
            <div className='disposal-list-item'>
                <Typography message={t("DisposalInfoContainer.banque-partenaire")} />

                <div className='d-flex align-items-center'>
                    {form.values.service ? (
                        <>
                            <Typography message={form.values.service?.title} />
                            <img
                                className='service-img'
                                src={transactionServiceSlugImage[form.values.service?.slug]}
                                alt=''
                            />
                        </>
                    ) : (
                        "-"
                    )}
                </div>
            </div>
        </div>
    );
};
