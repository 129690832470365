import { useNavigate } from "react-router-dom";
import { QuestKeys } from "src/services/admin/types";

import { useTranslation } from "react-i18next";

import { getAdminDashboardInfoData } from "src/modules/admin/selectors";
import { useAppSelector } from "src/store";

import { exportDisplayOptions } from "src/components/Export/Templates/Panel";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    questKey: QuestKeys;
    name: string;
    action?: () => void;
    link?: string;
    state?: { display?: exportDisplayOptions["display"] };
};

export function AdminDashboardQuestsItem({ link, name, action, questKey, state }: Readonly<Props>) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const quests = useAppSelector((state) => getAdminDashboardInfoData(state)?.quests);

    const handleAction = (action?: () => void, link?: string, state?: Props["state"]) => () => {
        if (action) {
            action();
        } else if (link) {
            navigate(link, state && { state });
        }
    };

    const isDone = quests?.[questKey]?.status ?? false;

    return (
        <div className='quest-row'>
            <div className='quest-name'>
                <Icon
                    name={isDone ? "check-circle" : "arrow-right-circle"}
                    variant='squared'
                    color={isDone ? "success" : "lilas"}
                />
                <Typography className='fw-bold' message={t(name)} />
            </div>
            <Button
                variant={isDone ? "tertiary" : "primary"}
                icon='chevron-right'
                onClick={handleAction(action, link, state)}
            />
        </div>
    );
}
