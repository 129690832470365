import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { EditTaskPanel } from "src/components/Tasks/Edit/Panel";

const EditTaskPageUnauthorized = () => {
    return <EditTaskPanel />;
};

export const EditTaskPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER],
})(EditTaskPageUnauthorized);
