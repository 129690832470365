import { DrawerContext, DrawerDisplays } from "src/wrapper/context";

import { ReactNode, useContext, useEffect, useMemo } from "react";

import { getAuthUser } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { CreateTaskDrawer } from "src/components/CreateTask/Drawer/Drawer";
import { IbanDrawer } from "src/components/Iban/Drawer/Container";
import { TopUpDrawer } from "src/components/TopUp/Drawer/Drawer";
import { TransfertDrawer } from "src/components/TransfertDrawer/Drawer/Drawer";

const drawers: { [key in DrawerDisplays]: ReactNode } = {
    createTask: <CreateTaskDrawer />,
    topUp: <TopUpDrawer />,
    iban: <IbanDrawer />,
    transfer: <TransfertDrawer />,
};

export function LayoutDrawerPanel() {
    const { open, setOpen } = useContext(DrawerContext);
    const authUser = useAppSelector((state) => getAuthUser(state));

    useEffect(() => {
        if (!authUser) {
            setOpen(undefined);
        }
    }, [authUser, setOpen]);

    const drawerToDisplay = useMemo(() => (open ? drawers[open] : null), [open]);

    return <>{drawerToDisplay}</>;
}
