import { PropsWithChildren } from "react";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./../../common/Password/styles.scss";

export type HandleSubmitPropsPasswordValidation = { password: string; twoFactor: string | null };

type Props = {
    header?: { title: string; icon?: IconListType; subtitle?: string };
    open: boolean;
    confirmLabel?: string;
    size?: "lg" | "md";
    cancelLabel?: string;
    loading?: boolean;
    handleOnSubmit: () => void;
    handleOnCancel?: () => void;
    disableSubmitButton?: boolean;
    hideCancelButton?: boolean;
    showCloseIconButton?: boolean;
};

export const Modal = ({
    header,
    open,
    confirmLabel = "Confirmer",
    cancelLabel = "Annuler",
    loading = false,
    size,
    children,
    handleOnSubmit,
    handleOnCancel,
    disableSubmitButton = false,
    hideCancelButton = false,
    showCloseIconButton = false,
}: PropsWithChildren<Props>) => {
    return open ? (
        <div className='modal-container'>
            <div className='modal'>
                <div className='modal-content'>
                    {header && (
                        <div className='modal-header'>
                            <div className='modal-header-content'>
                                {header?.icon && <Avatar icon={header.icon} />}
                                <div className='d-flex flex-column'>
                                    <Typography className='fw-bold' message={header.title} />
                                    {header.subtitle && <Typography message={header.subtitle} />}
                                </div>
                                {showCloseIconButton && handleOnCancel && (
                                    <Button
                                        onClick={handleOnCancel}
                                        className='header-close-button'
                                        variant='secondary'
                                        icon='x-bold'
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    <div className={`modal-content ${size}`}>{children}</div>

                    <div className='modal-footer d-flex justify-content-between'>
                        {!hideCancelButton && (
                            <Button
                                variant='tertiary'
                                className='w-100 mr-2'
                                label={cancelLabel}
                                onClick={handleOnCancel}
                            />
                        )}
                        <Button
                            label={
                                <div className='d-flex align-items-center'>
                                    {confirmLabel}
                                    {loading && (
                                        <div className='ml-2'>
                                            <Spinner size='xs' />
                                        </div>
                                    )}
                                </div>
                            }
                            className='w-100'
                            onClick={handleOnSubmit}
                            disabled={loading || disableSubmitButton}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};
