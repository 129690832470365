import { ServiceModel } from "src/shared/models/Service";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

const details1 = ["accountOwner", "counterCode", "bankCode", "accountNumber", "ribKey"];
const details2 = ["rib"];

type Props = {
    service: ServiceModel;
};
export const BankInfo = ({ service }: Props) => {
    const { t } = useTranslation();

    const details: { [key: string]: { title: string; getValue: (value: ServiceModel) => string } } = {
        ribKey: {
            title: t("BankDisposal.rib-key"),
            getValue: ({ options }: ServiceModel) => {
                return options.rib.key;
            },
        },
        bankCode: {
            title: t("BankDisposal.bank-code"),
            getValue: ({ options }: ServiceModel) => {
                return options.rib.bankCode;
            },
        },
        accountNumber: {
            title: t("BankDisposal.account-number"),
            getValue: ({ options }: ServiceModel) => {
                return options.rib.accountNumber;
            },
        },
        counterCode: {
            title: t("BankDisposal.office-code"),
            getValue: ({ options }: ServiceModel) => {
                return options.rib.counterCode;
            },
        },
        accountOwner: {
            title: t("BankDisposal.account-holder"),
            getValue: ({ options }: ServiceModel) => {
                return options.accountOwner;
            },
        },
        rib: {
            title: t("BankDisposal.rib"),
            getValue: ({ options }: ServiceModel) => {
                return `${options.rib.bankCode}${options.rib.counterCode}${options.rib.accountNumber}${options.rib.key}`;
            },
        },
    };

    const handleOpenBankLink = (bankLink: string) => () => {
        window.open(bankLink, "_blank");
    };

    return (
        <div className='bank-info-container'>
            {details1.map((item) => (
                <div className='details-list' key={item + "-bank-info-details"}>
                    <Typography className='fw-bold' message={details[item].title} />
                    <Typography message={details[item].getValue(service)} />
                </div>
            ))}

            <div className='divider' />

            {details2.map((item) => (
                <div className='details-list' key={item + "-bank-info-details-2"}>
                    <Typography className='fw-bold' message={details[item].title} />
                    <Typography message={details[item].getValue(service)} />
                </div>
            ))}

            <div className='divider' />
            <div className='bank-button-container'>
                <Button
                    variant='tertiary'
                    onClick={handleOpenBankLink(service.options.link)}
                    label={t("BankDisposal.find-agency")}
                    icon='map-pin'
                />
            </div>
        </div>
    );
};
