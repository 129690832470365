import cx from "classnames";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

const validProofs = [
    {
        image: require("src/shared/images/icons/complete.png"),
        title: "DisposalProofInfo.virement-electronique",
        description: "DisposalProofInfo.signed-validated-transmitted-transfer-order",
    },
    {
        image: require("src/shared/images/icons/bank_deposit.png"),
        title: "DisposalProofInfo.virement-physique",
        description: "DisposalProofInfo.signed-discharged-transfer-order",
    },
    {
        image: require("src/shared/images/icons/deposit.png"),
        title: "BankDisposal.cash",
        description: "DisposalProofInfo.cleared-cash-deposit-slip",
    },
    {
        image: require("src/shared/images/icons/check.png"),
        title: "DisposalProofInfo.cheques",
        description: "DisposalProofInfo.check-discharge-photo",
    },
];

export const DisposalProofInfo = () => {
    const [showContent, setShowContent] = useState(false);
    const { t } = useTranslation();

    const toggle = () => {
        setShowContent(!showContent);
    };

    return (
        <div className='disposal-proof-info' onClick={toggle}>
            <div className='content-title'>
                <Typography className='fw-bold' message={t("DisposalProofInfo.quels-sont-les-justificatifs-valides")} />

                <Icon
                    className={cx("alert-down-icon", {
                        opened: showContent,
                    })}
                    name='chevron-down'
                />
            </div>

            {showContent && (
                <div className='content-item'>
                    {validProofs.map((item) => (
                        <div key={item.title} className='proof-info-item'>
                            <img src={item.image} alt={item.title + "-img"} />

                            <div className='text-part'>
                                <Typography className='fw-bold' message={t(item.title)} />
                                <Typography size='xs' message={t(item.description)} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
