import { getInStorage } from "src/shared/utils/storage";

import { getCompany } from "./selectors";
import { useAppSelector } from "src/store";

export function useAuthUserSelectedCompany() {
    const companyId = getInStorage("currentCompanyId");

    return useAppSelector((state) => getCompany(state, { companyId }));
}
