import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type SignataireProps = {
    name: string;
    status?: string;
    picture?: string | null;
    isSuperSignataire?: boolean;
};

export const Signataire = ({ name, status, picture, isSuperSignataire = false }: SignataireProps) => {
    const firstNameInitial = name.split(" ")[0]?.charAt(0);
    const lastNameInitial = name.split(" ")[1]?.charAt(0);

    return (
        <div className='signataire-container'>
            <Avatar avatar={picture ?? undefined} firstname={firstNameInitial} lastname={lastNameInitial} />
            <div className='signataire-content-container'>
                <div className='signataire-name'>
                    <Typography size='xs' message={name} className='fw-bold' />
                    {status && <Typography size='xs' message={status} className='color-neutral' />}
                </div>
                {isSuperSignataire && (
                    <Typography
                        size='xs'
                        message={"Super-Signataire"}
                        className='color-primary fw-bold super-signataire'
                    />
                )}
            </div>
        </div>
    );
};
