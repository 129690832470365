export function replaceAll<T extends { [key: string]: string }>(str: string, dictionary: T) {
    const lowerCaseDictionary: { [key: string]: string } = Object.keys(dictionary).reduce(
        (acc: { [key: string]: string }, key) => {
            acc[key.toLowerCase()] = dictionary[key];
            return acc;
        },
        {}
    );

    const regex = new RegExp("\\[" + Object.keys(lowerCaseDictionary).join("\\]|\\[") + "\\]", "gi");
    return str.replace(regex, (matched) => {
        const key = matched.replace(/\[/g, "").replace(/\]/g, "").toLowerCase();

        return lowerCaseDictionary[key];
    });
}
