import { ReactNode, useEffect, useRef } from "react";

import "./styles.scss";

type ArcGaugeProps = {
    value: number;
    rangeValues?: [number, number, number];
    arcValues?: [string, string, string];
    message?: ReactNode;
    width?: number;
    height?: number;
    inactiveGray?: boolean;
    dashedLine?: boolean;
    displayPreviousColors?: boolean;
};

export const ArcGauge = ({
    value,
    rangeValues,
    message,
    width,
    height,
    inactiveGray = true,
    arcValues = ["#21c45d", "#ffac38", "#ef3d34"],
    dashedLine = false,
    displayPreviousColors = false,
}: ArcGaugeProps) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const getDefaultAdjustedValue = (value: number) => {
        if (value <= 33) {
            return 26.5;
        } else if (value <= 66) {
            return 66;
        } else {
            return 100;
        }
    };

    const getCustomAdjustedValue = (value: number, rangeValues: [number, number, number]) => {
        if (value <= rangeValues[0]) {
            return 26.5;
        } else if (value <= rangeValues[1]) {
            return 66;
        } else {
            return 100;
        }
    };

    useEffect(() => {
        let adjustedValue = rangeValues ? getCustomAdjustedValue(value, rangeValues) : getDefaultAdjustedValue(value);

        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        const centerX = canvas.width / 2;
        const centerY = canvas.height;
        const radius = canvas.width / 2 - 5;

        const drawArc = (startAngle: number, endAngle: number, color: string) => {
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, startAngle, endAngle);
            ctx.lineWidth = 10;
            ctx.lineCap = "round";
            ctx.strokeStyle = color;
            ctx.stroke();
        };

        const gapAngle = Math.PI / 180;

        const drawGauge = () => {
            const greenStartAngle = Math.PI;
            const greenEndAngle = (4 * Math.PI) / 3 - gapAngle - 0.2;
            const orangeStartAngle = greenEndAngle + 2 * gapAngle + 0.2;
            const orangeEndAngle = (5 * Math.PI) / 3 - gapAngle;
            const redStartAngle = orangeEndAngle + 2 * gapAngle + 0.2;

            const inactiveColor = inactiveGray ? "#e6e6e6" : null;
            const inactiveGreenColor = inactiveColor || "#ebfaf1";
            const inactiveOrangeColor = inactiveColor || "#fff7eb";
            const inactiveRedColor = inactiveColor || "#fdedec";

            drawArc(greenStartAngle, greenEndAngle, inactiveGreenColor);
            drawArc(orangeStartAngle, orangeEndAngle, inactiveOrangeColor);
            drawArc(redStartAngle, 2 * Math.PI, inactiveRedColor);
        };

        const drawValueArc = () => {
            const greenStartAngle = Math.PI;
            const greenEndAngle = (4 * Math.PI) / 3 - gapAngle - 0.2;
            const orangeStartAngle = greenEndAngle + 2 * gapAngle + 0.2;
            const orangeEndAngle = (5 * Math.PI) / 3 - gapAngle;
            const redStartAngle = orangeEndAngle + 2 * gapAngle + 0.2;

            const adjustedEndAngle = Math.PI + Math.PI * (adjustedValue / 100);

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            drawGauge();

            if (displayPreviousColors) {
                if (adjustedValue <= 33.3) {
                    drawArc(greenStartAngle, adjustedEndAngle, arcValues[0]);
                } else if (adjustedValue <= 66.6) {
                    drawArc(greenStartAngle, greenEndAngle, arcValues[0]);
                    drawArc(orangeStartAngle, adjustedEndAngle, arcValues[1]);
                } else {
                    drawArc(greenStartAngle, greenEndAngle, arcValues[0]);
                    drawArc(orangeStartAngle, orangeEndAngle, arcValues[1]);
                    drawArc(redStartAngle, adjustedEndAngle, arcValues[2]);
                }
            } else {
                if (adjustedValue <= 33.3) {
                    drawArc(greenStartAngle, adjustedEndAngle, arcValues[0]);
                } else if (adjustedValue <= 66.6) {
                    drawArc(orangeStartAngle, adjustedEndAngle, arcValues[1]);
                } else {
                    drawArc(redStartAngle, adjustedEndAngle, arcValues[2]);
                }
            }
        };

        const drawDashedMiddleLine = () => {
            const middleRadius = radius - 25;
            ctx.beginPath();
            ctx.arc(centerX, centerY, middleRadius, Math.PI, 2 * Math.PI);
            ctx.lineWidth = 2;
            ctx.setLineDash([5, 5]);
            ctx.strokeStyle = "#cccccc";
            ctx.stroke();
            ctx.setLineDash([]);
        };

        drawValueArc();
        if (dashedLine) drawDashedMiddleLine();

        // eslint-disable-next-line
    }, [value, rangeValues, displayPreviousColors]);

    return (
        <div className='arc-gauge-container'>
            <canvas
                data-testid='arc-gauge'
                ref={canvasRef}
                width={width ? width : "250"}
                height={height ? height : "125"}
                className='arc-gauge'
            ></canvas>
            <div className='message-container'>{message}</div>
        </div>
    );
};
