import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type CopyInputProps = {
    label: string;
    value: string;
    copiedContent: string;
    belowMessage?: string;
    testId?: string;
};

export function CopyInput({ label, value, copiedContent, belowMessage, testId }: Readonly<CopyInputProps>) {
    const { t } = useTranslation();
    const [confirmCopy, setConfirmCopy] = useState(false);

    const handleConfirmCopy = () => {
        setConfirmCopy(true);

        setTimeout(() => {
            setConfirmCopy(false);
        }, 2000);
    };

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(copiedContent);
        handleConfirmCopy();
    };

    return (
        <div className='copy-input-container'>
            <Typography className='fw-bold' message={t(label)} />
            <div className='copyable-text-container'>
                <Typography className='copyable-text' size='sm' message={value} />
                <Button label={t("CommonUse.copy")} onClick={handleCopyToClipboard} testId={testId} />
            </div>
            {belowMessage && <Typography className='copy-input-below-message' size='xs' message={belowMessage} />}
            {confirmCopy && (
                <div className='link-copied-container'>
                    <Icon name='check-circle-filled' color='success' size='sm' />
                    <Typography message={t("Profile.link-copied")} className='color-success' />
                </div>
            )}
        </div>
    );
}
