import { getInStorage } from "./storage";
import CryptoJS from "crypto-js";

export const encryptCode = (code: string) => {
    const accessToken = getInStorage("accessToken");
    if (!accessToken) {
        return code;
    }
    return CryptoJS.AES.encrypt(code, accessToken?.id).toString();
};
