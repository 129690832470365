import { logoutResetStore } from "../auth/Actions";
import { AuthCompany } from "src/shared/models/AuthCompany";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CompaniesState {
    companies: AuthCompany[];
    loading: boolean;
}

const initialState = {
    companies: [],
    loading: false,
} as CompaniesState;

export const companiesSlice = createSlice({
    name: "companies",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestCompanies: (state) => {
            return {
                ...state,
                loading: true,
                requested: true,
            };
        },
        receiveCompanies: (state, action: PayloadAction<{ companies: AuthCompany[] }>) => {
            return {
                ...state,
                loading: false,
                companies: action.payload.companies,
            };
        },
        requestFavoriteBillers: (state) => {
            state.loading = true;
        },
        failedRequestFavoriteBillers: (state) => {
            state.loading = false;
        },
        receiveFavoriteBillers: (
            state,
            { payload }: PayloadAction<{ favoriteBillers: AuthCompany["favoriteBillers"]; companyId: number | null }>
        ) => {
            const { companyId, favoriteBillers } = payload;
            const companyToChange = state.companies.find((company) => company.id === companyId);

            if (companyToChange) {
                companyToChange.favoriteBillers = favoriteBillers;
            }

            const newCompanies = state.companies.map((company) =>
                companyToChange && company.id === companyId ? companyToChange : company
            );

            state.companies = newCompanies;
        },
    },
});

export const {
    requestCompanies,
    receiveCompanies,
    receiveFavoriteBillers,
    failedRequestFavoriteBillers,
    requestFavoriteBillers,
} = companiesSlice.actions;

export const companiesSliceReducer = companiesSlice.reducer;
