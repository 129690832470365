import { fetchAdminDocuments } from "./thunkActions";

import { useEffect } from "react";

import { getAdminDocuments } from "./selectors";
import { useAppDispatch, useAppSelector } from "src/store";

export function useAdminDocuments() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAdminDocuments());
    }, [dispatch]);

    return useAppSelector((state) => getAdminDocuments(state));
}
