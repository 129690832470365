import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SmsTransferCheckbox } from "src/shared/components/SmsTransferCheckbox/SmsTransferCheckbox";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";

export type BulkTransfertInformations = {
    transfertName: string;
    description: string;
    selectedFees: boolean;
    smsTemplate?: string;
};

type BulkTransfertInformationsFormProps = {
    informations: BulkTransfertInformations | null;
    onChangeInformationsForm: (informations: BulkTransfertInformations) => void;
    setIsFormValid: (isValid: boolean) => void;
};

export const BulkTransfertInformationsForm = ({
    informations,
    onChangeInformationsForm,
    setIsFormValid,
}: BulkTransfertInformationsFormProps) => {
    const { t } = useTranslation();

    const [transfertNameValue, setTransfertNameValue] = useState(informations ? informations.transfertName : "");
    const [descriptionValue, setDescriptionValue] = useState(informations ? informations.description : "");
    const [selectedFees, setSelectedFees] = useState(informations ? informations.selectedFees : false);
    const [smsMessage, setSmsMessage] = useState<string | undefined>(undefined);
    const [transfertNameValueCheck, setTransfertNameValueCheck] = useState(false);
    const isSmsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_SMS_TRANSFER_BULK);

    useEffect(() => {
        onChangeInformationsForm({
            transfertName: transfertNameValue,
            description: descriptionValue,
            selectedFees: selectedFees,
            smsTemplate: smsMessage,
        });
        validateForm();
    }, [transfertNameValue, descriptionValue, selectedFees, smsMessage]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setTransfertNameValueCheck(transfertNameValue.length < 4 && transfertNameValue.length > 0);
        }, 700);
        return () => clearTimeout(handler);
    }, [transfertNameValue]);

    const validateForm = useCallback(() => {
        const isValidName = transfertNameValue.length >= 4;
        setIsFormValid(isValidName);
    }, [transfertNameValue, setIsFormValid]);

    const handleChangeSelectedFees = () => {
        setSelectedFees(!selectedFees);
    };

    const handleChangeTransfertName = (value: string) => {
        setTransfertNameValue(value);
    };

    const handleChangeDescription = (value: string) => {
        setDescriptionValue(value);
    };

    const handleSetSmsTemplate = (smsTemplate: string | undefined) => {
        setSmsMessage(smsTemplate);
    };

    return (
        <div className='bulk-transfert-info-container'>
            <Input
                placeholder={t("BulkTransferPage.name-input-placeholder")}
                label={t<string>("BulkTransferPage.name-input-label")}
                underMessage={
                    transfertNameValueCheck
                        ? t<string>("BulkTransferPage.undertext-at-minimum-four-char")
                        : transfertNameValue.length === 0
                        ? t<string>("BulkTransferPage.enter-four-char-mini")
                        : undefined
                }
                variant={transfertNameValueCheck ? "error" : undefined}
                value={transfertNameValue}
                onChange={handleChangeTransfertName}
            />
            <Input
                placeholder={t("BulkTransferPage.desc-input-placeholder")}
                label={t<string>("BulkTransferPage.desc-input-label")}
                value={descriptionValue}
                onChange={handleChangeDescription}
            />
            <div className='divider' />
            <Checkbox
                label={<Typography className='fw-bold' message={t("BulkTransferPage.fees-checkbox-label")} />}
                checked={selectedFees}
                onChange={handleChangeSelectedFees}
            />
            <ToolTip
                position='bottom'
                content={
                    <div className='tooltip-limited'>
                        <Typography message={t("Subscription.not-accessible-feature")} className='fw-bold' />
                        <Typography message={t("Subscription.unlock-unlimited-access")} />
                    </div>
                }
                activation={isSmsRestricted}
            >
                <SmsTransferCheckbox
                    show={informations ? !!informations.smsTemplate : false}
                    handleSetSmsTemplate={handleSetSmsTemplate}
                    disabled={isSmsRestricted}
                />
            </ToolTip>
        </div>
    );
};
