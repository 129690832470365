import { useTranslation } from "react-i18next";

import { getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy } from "src/modules/admin/expensePolicies/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";

export function ExpensePolicyCreateDrawerAlert() {
    const { t } = useTranslation();

    const selectedWalletsHaveExpensePolicy = useAppSelector((state) =>
        getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy(state)
    );

    return selectedWalletsHaveExpensePolicy.length > 0 ? (
        <Alert
            color='warning'
            message={t("Expense-policy.alert-wallet-already-have-expense-policy-title")}
            subMessage={t<string>("Expense-policy.alert-wallet-already-have-expense-policy")}
        />
    ) : null;
}
