import { ReactNode } from "react";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type EmptyProps = {
    image: string;
    button?: ReactNode;
    title: string;
    description?: string;
};

export const Empty = ({ button, title, description, image }: EmptyProps) => {
    return (
        <div className='empty-state-container'>
            <img src={image} alt='empty-img' className='empty-img' />
            <Typography size='lg' className='color-neutral-900 fw-bold' message={title} />
            {description && <Typography className='color-neutral-500 ' message={description} />}
            {button && button}
        </div>
    );
};
