import { EditTaskInputCell } from "./InputCell";
import { createTaskItems } from "src/services/taskItems/operations";
import { TaskItemModelToCreate } from "src/services/taskItems/types";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { removeFileUploadingTaskItem } from "src/modules/tasksItems/slice";
import { fetchTaskItems } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch } from "src/store";

import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { IconButton } from "src/shared/components/IconButton/IconButton";

type Props = {
    taskId: number | undefined;
    taskItemId?: string;
    taskItem: TaskItemModelToCreate;
    isDisabled?: boolean;
    loading?: boolean;
    retry?: boolean;
    handleOpenDrawer: () => void;
    handleRenameBeneficiary: (name: string) => void;
    handleCancelCreateTaksItem: () => void;
};

export function EditTaskCreateRow({
    taskId,
    taskItem,
    taskItemId,
    isDisabled,
    loading = false,
    retry = false,
    handleOpenDrawer,
    handleRenameBeneficiary,
    handleCancelCreateTaksItem,
}: Readonly<Props>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(loading);
    const { externalReference, externalFullname, serviceSlug, typeSlug, amount } = taskItem;

    const handleRetry = async () => {
        if (taskId && taskItemId && externalReference && amount) {
            setIsLoading(true);
            await createTaskItems({
                taskId,
                tasksItems: [
                    {
                        externalReference,
                        externalFullname: removeExtraSpaces(externalFullname ?? ""),
                        serviceSlug,
                        amount: String(amount),
                        typeSlug,
                    },
                ],
            }).then(() => {
                dispatch(removeFileUploadingTaskItem({ taskId, taskItemId }));
                dispatch(fetchTaskToEdit({ taskId }));
                dispatch(
                    fetchTaskItems({
                        taskId,
                    })
                );
            });
            setIsLoading(false);
        }
    };

    const handleDeleteTaskItem = () => {
        handleCancelCreateTaksItem();
    };

    const handleDeleteFileTaskItem = () => {
        if (taskId && taskItemId) {
            dispatch(removeFileUploadingTaskItem({ taskId, taskItemId }));
        }
    };

    const handleUpdateAmount = async (newAmount: string) => {
        if (taskId && externalReference && serviceSlug && parseInt(newAmount)) {
            setIsLoading(true);
            await createTaskItems({
                taskId,
                tasksItems: [
                    {
                        externalReference,
                        externalFullname,
                        serviceSlug,
                        amount: newAmount,
                        typeSlug,
                    },
                ],
            }).then(() => dispatch(fetchTaskToEdit({ taskId })));
            setIsLoading(false);
            handleCancelCreateTaksItem();
            dispatch(
                fetchTaskItems({
                    taskId,
                })
            );
        }
    };

    const amountDisabled =
        !!externalReference && typeSlug?.includes("bank-transfer") && (externalFullname ?? "").trim() === "";

    const disabled = isDisabled ?? isLoading ?? retry;

    return (
        <>
            <tr className={retry ? "background-error-100" : ""} key={`task-item-${taskItem.externalReference}`}>
                <td>
                    <div className='row-item'>
                        {taskItem.serviceSlug && <TransactionServiceImage serviceSlug={taskItem.serviceSlug} />}
                        <Typography message={taskItem.externalReference} />
                        <IconButton
                            classname='cursor-pointer'
                            disabled={disabled || retry}
                            handleOnClick={handleOpenDrawer}
                            iconProps={{
                                name: "pencil",
                            }}
                        />
                    </div>
                </td>
                <td>
                    <div className='row-item'>
                        <EditTaskInputCell
                            onValidate={handleRenameBeneficiary}
                            editOpen={amountDisabled}
                            initialValue={taskItem.externalFullname ?? ""}
                            disabled={disabled || retry}
                        >
                            <Typography
                                className='create-task-table-cell-content'
                                message={taskItem.externalFullname}
                            />
                        </EditTaskInputCell>
                    </div>
                </td>
                <td>
                    <div className='row-item'>
                        <EditTaskInputCell
                            onValidate={handleUpdateAmount}
                            disabled={amountDisabled || disabled || retry}
                            editOpen={!isLoading && !retry && !!externalFullname && !amount}
                            initialValue={String(taskItem.amount)}
                            type='number'
                            testId='create-task-row-amount-input'
                        >
                            <Typography
                                className='edit-task-table-cell-content'
                                message={formatMoneyToString({ amount: taskItem.amount })}
                            />
                        </EditTaskInputCell>
                    </div>
                </td>
                <td>-</td>
                <td>
                    <div className='row-item'>
                        <EditTaskInputCell disabled={disabled || retry} initialValue={taskItem.comment ?? ""}>
                            <Typography className='edit-task-table-cell-content' message={taskItem.comment} />
                        </EditTaskInputCell>
                    </div>
                </td>
                <td>
                    <div className='row-item'>
                        {isLoading ? (
                            <Spinner size='xs' />
                        ) : retry ? (
                            <div className='d-flex'>
                                <IconButton
                                    classname='mr-2'
                                    handleOnClick={handleRetry}
                                    iconProps={{ name: "retry" }}
                                />
                                <IconButton handleOnClick={handleDeleteFileTaskItem} iconProps={{ name: "thrash" }} />
                            </div>
                        ) : (
                            <IconButton
                                disabled={disabled}
                                handleOnClick={handleDeleteTaskItem}
                                iconProps={{ name: "thrash" }}
                            />
                        )}
                    </div>
                </td>
            </tr>
            {retry ? (
                <tr key={`task-item-${taskItemId}-error`}>
                    <td colSpan={6}>
                        <div className='d-flex justify-content-start' data-testid='create-task-error-message'>
                            <Icon name='information-circle' color='error' />
                            <Typography
                                className='ml-2 task-items-error-message'
                                message={t("EditTaskPanel.task-item-retry-error")}
                            />
                        </div>
                    </td>
                </tr>
            ) : null}
        </>
    );
}
