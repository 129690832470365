import { ReactNode } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button, ButtonProps } from "src/shared/atoms/Buttons/Button";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

type FormContainerProps = {
    icon: IconListType;
    title: string;
    subTitle?: string;
    children: ReactNode;
    collapsed?: boolean;
    editMode?: {
        isEditMode: boolean;
        setIsEditMode: () => void;
    };
    buttons?: {
        primary?: ButtonProps;
        secondary?: ButtonProps;
    };
    testId?: string;
};

export const FormContainer = ({
    icon,
    title,
    subTitle,
    children,
    collapsed,
    editMode,
    buttons,
    testId,
}: FormContainerProps) => {
    const { t } = useTranslation();
    const shouldShowContent = !collapsed || (editMode && editMode.isEditMode);

    return (
        <div className='form-container'>
            <div className='header'>
                <div className='left-content'>
                    <Avatar icon={icon} />
                    <div className='text-part'>
                        <Typography message={title} className='fw-bold' />
                        {subTitle && <Typography message={subTitle} />}
                    </div>
                </div>
                {editMode && (
                    <div className='right-content'>
                        <Button
                            testId={testId}
                            variant='ghost'
                            label={editMode.isEditMode ? t("CommonUse.being-modified") : t("CommonUse.modify")}
                            onClick={editMode.setIsEditMode}
                            color={editMode.isEditMode ? "warning" : "lilas"}
                        />
                    </div>
                )}
            </div>
            {shouldShowContent && <div className='body'>{children}</div>}
            {shouldShowContent && buttons && (
                <div className='footer'>
                    {buttons.secondary && <Button {...buttons.secondary} />}
                    {buttons.primary && <Button {...buttons.primary} />}
                </div>
            )}
        </div>
    );
};
