import classNames from "classnames";
import { transactionServiceSlugImage } from "src/shared/const/transactions";
import { ServiceModel } from "src/shared/models/Service";
import { getAverageTime } from "src/shared/utils/timing";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { getBankServicesListFiltered, getBankServicesLoading } from "src/modules/transactions/selectors";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

const emptyArchivedImg = require("src/shared/images/empty/empty-user.svg").default;

type Props = {
    selectService: (value: ServiceModel) => void;
};

export const BankSelectDrawerBody = ({ selectService }: Props) => {
    const { t } = useTranslation();

    const [search, setSearch] = useState("");

    const bankServices = useAppSelector((state) => getBankServicesListFiltered(state, { search }));
    const isLoading = useAppSelector((state) => getBankServicesLoading(state));

    const handleSearchQuery = (value: string) => {
        setSearch(value);
    };

    return (
        <div className='bank-select-drawer-body h-100'>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={handleSearchQuery}
                value={search}
                className='search-input'
            />

            <div className='services-list h-100 mt-2'>
                <List
                    variant='secondary'
                    loadingRowsToshow={8}
                    isLoading={isLoading}
                    keyName='service-list'
                    animation='fadeInUp'
                    extended
                    items={bankServices.map((service) => (
                        <div
                            className={classNames("services-list-item", {
                                disabled: !service.options,
                            })}
                            onClick={service.options ? () => selectService(service) : undefined}
                        >
                            {transactionServiceSlugImage[service.slug] ? (
                                <img
                                    src={transactionServiceSlugImage[service.slug]}
                                    alt={"Service"}
                                    className='service-img'
                                />
                            ) : (
                                <div className='default-image'>
                                    <Avatar icon='bank' />
                                </div>
                            )}

                            <div className='flex-grow-1'>
                                <Typography variant='p' className='my-0 fw-bold' message={service.title} />
                            </div>

                            <div className={`time-item ${getAverageTime(service.slug).color}`}>
                                <Icon name='clock' className={`mr-2 color-${getAverageTime(service.slug).color}`} />

                                <Typography
                                    size='xs'
                                    className={`fw-bold`}
                                    message={getAverageTime(service.slug).text}
                                />
                            </div>

                            <Icon name='chevron-right-filled' className='ml-2' />
                        </div>
                    ))}
                />
                {!isLoading && (!bankServices || Object.keys(bankServices).length === 0) && (
                    <div className='align-text-center'>
                        <Empty
                            image={emptyArchivedImg}
                            title={t("Table.no-result-found")}
                            description={t<string>("Table.no-result-found-description")}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
