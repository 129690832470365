import { Typography } from "../Typography/Typography";
import classNames from "classnames";

import { ChangeEvent, TextareaHTMLAttributes, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

type Props = {
    value: string | undefined;
    onChange?: (newText: string) => void;
    testId?: string;
    label?: string;
    disabled?: boolean;
    variant?: "neutral";
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange" | "value">;

export function TextArea({ value, onChange, testId, variant, maxLength, label, disabled, ...props }: Props) {
    const { t } = useTranslation();

    const [text, setText] = useState(value ?? "");

    useEffect(() => {
        setText(value ?? "");
    }, [value]);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const newText = event.target.value;

        setText(newText);
        if (onChange) {
            onChange(newText ?? "");
        }
    };

    return (
        <div className='input-container'>
            {(label || maxLength) && (
                <div className='input-label'>
                    <Typography
                        message={label ?? ""}
                        className={`${disabled ? "color-neutral-400" : "color-neutral-900"} fw-bold`}
                    />
                    {maxLength ? (
                        <Typography
                            className='d-flex color-neutral-500'
                            message={t(`CommonUse.remaining-caracters`, {
                                textLength: text.length,
                                maxLength,
                            })}
                        />
                    ) : null}
                </div>
            )}
            <textarea
                className={classNames("text-area", { "variant-neutral": variant === "neutral" }, { disabled })}
                value={text}
                onChange={handleChange}
                maxLength={maxLength}
                data-testid={testId}
                disabled={disabled}
                {...props}
            />
        </div>
    );
}
