import { CreateUserDocumentParams, CreateUserParams } from "src/services/admin/types";

import { Dispatch, SetStateAction, createContext } from "react";

import { InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";

export type UserValuesType = Partial<CreateUserParams & CreateUserDocumentParams>;

export type CreateUserContextProps = {
    phone: InputPhoneValue | null;
    setPhone: Dispatch<SetStateAction<InputPhoneValue | null>>;
    step: number;
    setStep: Dispatch<SetStateAction<number>>;
    userValues: UserValuesType | undefined;
    setUserValues: Dispatch<SetStateAction<UserValuesType | undefined>>;
    attachToWallet?: boolean;
    initState: () => void;
    handleValidatedStep: (validated: boolean, step: number) => void;
};

export const CreateUserContext = createContext<CreateUserContextProps>({} as CreateUserContextProps);
