import { middlewareRequest } from "../utils/request";
import { TaskItemModelToUpdate, TaskItemsListApiReturn } from "./types";

export async function requestGetTaskItems(taskId: number | string) {
    try {
        return await middlewareRequest<TaskItemsListApiReturn>({ endpoint: `/tasks/${taskId}/items` });
    } catch (error) {
        return Promise.reject(error);
    }
}

export type CreateTaskApiPayload = {
    amount: string;
    externalReference: string;
    serviceSlug?: string | null;
    typeSlug?: string | null;
    externalFullname?: string | null;
    comment?: string | null;
};

export async function createTaskItems({ taskId, tasksItems }: { taskId: number; tasksItems: CreateTaskApiPayload[] }) {
    try {
        return await middlewareRequest<TaskItemsListApiReturn>({
            endpoint: `/tasks/${taskId}/items`,
            method: "POST",
            params: tasksItems,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestUpdateTaskItems({
    taskId,
    tasksItems,
}: {
    taskId: number;
    tasksItems: Partial<TaskItemModelToUpdate>[];
}) {
    try {
        return await middlewareRequest<TaskItemsListApiReturn>({
            endpoint: `/tasks/${taskId}/items`,
            method: "PUT",
            params: tasksItems,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function deleteTaskItems({ taskId, tasksItems }: { taskId: number; tasksItems: string[] }) {
    try {
        return await middlewareRequest<TaskItemsListApiReturn>({
            endpoint: `/tasks/${taskId}/items`,
            method: "DELETE",
            params: { ids: tasksItems },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
