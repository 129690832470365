import { ExpensePolicy } from "src/services/admin/expensePolicy/types";

import { TFunction } from "i18next";

import { AdminExpensePolicyForm } from "src/modules/admin/expensePolicies/slice";

import { expensePolicyUnitOfTimeOptions } from "src/components/Admin/ExpensePolicy/CreateDrawer/Sections/const";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

const computeExcludedWallets = (
    selectedExpensePolicy: ExpensePolicy,
    expensePolicies: { [expensePolicyId: string]: ExpensePolicy }
) => {
    return selectedExpensePolicy.excludedWallets?.filter((walletId) => {
        return Object.values(expensePolicies).every((expensePolicy) => {
            return expensePolicy.excludedWallets?.find((excludedWallet) => excludedWallet.id === walletId.id);
        });
    });
};

export const computeExpensePolicyInForm = ({
    t,
    selectedExpensePolicy,
    expensePolicies,
    walletOptions,
    userOptions,
}: {
    t: TFunction<"translation", string, "translation">;
    selectedExpensePolicy: ExpensePolicy;
    expensePolicies: { [expensePolicyId: string]: ExpensePolicy };
    walletOptions: SelectOption[];
    userOptions: SelectOption[];
}): AdminExpensePolicyForm => {
    const { excludedUsers, unitOfTime, isForBeneficiariesOnly, limitPerOperation, limitPerUnitOfTime, title } =
        selectedExpensePolicy;

    const excludedWallets = computeExcludedWallets(selectedExpensePolicy, expensePolicies);

    return {
        id: selectedExpensePolicy.id,
        allWallets: !(excludedWallets && excludedWallets?.length > 0),
        allUsers: !(excludedUsers && excludedUsers?.length > 0),
        excludedWallets: walletOptions.filter((option) =>
            excludedWallets?.some((wallet) => wallet.id === parseInt(option.id))
        ),
        excludedUsers: userOptions.filter((option) => excludedUsers?.some((user) => user.id === parseInt(option.id))),
        limitPerOperation: limitPerOperation,
        limitPerUnitOfTime: limitPerUnitOfTime,
        isForBeneficiariesOnly: isForBeneficiariesOnly,
        unitOfTime: expensePolicyUnitOfTimeOptions(t).find((timeUnit) => timeUnit.id === unitOfTime),
        title: title,
    };
};
