import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export const CollectSuccessModal = () => {
    const { t } = useTranslation();

    return (
        <div className='success-modal-content-container'>
            <img
                className='mx-auto'
                height={200}
                src={require("src/shared/images/rapid-transfer/rapid-transfer-success.svg").default}
                alt=''
            />
            <Typography className='fw-bold' message={t("CommonUse.the-request-has-been-registered")} />
        </div>
    );
};
