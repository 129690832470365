import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

const getState = (state: RootState) => state.admin.adminWalletsReducer;

export const getAdminWallets = createSelector(getState, (state) => ({
    wallets: state.adminWallets,
    loading: state.loading,
    totalCount: state.adminWalletsCount,
    pageSize: state.pageSize,
    currentPage: state.currentPage,
}));

export const getAdminWalletIntegration = createSelector(getState, (state) => ({
    integratedWallet: state.integratedWallet,
    integrateWalletLoading: state.integrateWalletLoading,
    integrateWalletError: state.integrateWalletError,
}));

export const getAdminWalletsAsSelectOptions = createSelector(getState, (state) =>
    Object.values(state.adminWallets)
        .filter((wallet) => wallet.label || wallet.reference)
        .map<SelectOption>((wallet) => ({ id: wallet.id.toString(), label: wallet.label || wallet.reference }))
);

export const getAdminWalletsWithReferenceAsSelectOptions = createSelector(getState, (state) =>
    Object.values(state.adminWallets)
        .filter((wallet) => wallet.label || wallet.reference)
        .map<SelectOption>((wallet) => ({ id: wallet.id.toString(), label: `${wallet.label} - ${wallet.reference}` }))
);
