import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { TransactionIconStatusType } from "src/services/transactions/types";
import { topupErrorReasons } from "src/shared/const/transactions";
import { TransactionContext } from "src/shared/context/transaction";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

const getStatusData = (status: TransactionIconStatusType | null) => {
    switch (status) {
        case "refunded": {
            return "HistoryWalletDrawerStatus.your-operation-refunded";
        }

        case "failed": {
            return "HistoryWalletDrawerStatus.operation-annulee";
        }
        case "refund-pending":
        case "waiting": {
            return "HistoryWalletDrawerStatus.traitement-en-cours";
        }
        case "refund-failed": {
            return "HistoryWalletDrawerStatus.echec-du-remboursement";
        }
        default: {
            return;
        }
    }
};

const statusToDisplay = (
    partnerComment: string | null,
    status: TransactionIconStatusType | null
): {
    [status: string]: {
        status: "success" | "waiting" | "failed";
        title: string;
        icon?: IconListType;
        description?: string;
        color: string;
        backgroundColor: string;
    }[];
} => ({
    failed: [
        {
            status: "success",
            color: "success",
            backgroundColor: "success-50",
            icon: "check-circle",
            title: "RechargementPanel.top-up-status-drawer-waiting-first-step-title",
            description: "RechargementPanel.top-up-status-drawer-waiting-first-step-description",
        },
        {
            status: "failed",
            color: "error",
            backgroundColor: "error-50",
            icon: "x-circle",
            title: "RechargementPanel.top-up-status-drawer-failed-second-step-title",
            description: partnerComment ? topupErrorReasons[partnerComment] : getStatusData(status),
        },
    ],
    done: [
        {
            status: "success",
            color: "success",
            backgroundColor: "success-50",
            icon: "check-circle",
            title: "RechargementPanel.top-up-status-drawer-waiting-first-step-title",
            description: "RechargementPanel.top-up-status-drawer-waiting-first-step-description",
        },
        {
            status: "success",
            color: "success",
            icon: "check-circle",
            backgroundColor: "success-50",
            title: "RechargementPanel.top-up-status-drawer-waiting-second-step-title",
            description: "RechargementPanel.top-up-status-drawer-waiting-second-step-description",
        },
        {
            status: "success",
            color: "success",
            icon: "check-circle",
            backgroundColor: "success-50",
            title: "RechargementPanel.top-up-status-drawer-waiting-third-step-title",
            description: "RechargementPanel.top-up-status-drawer-waiting-third-step-description",
        },
    ],
    waiting: [
        {
            status: "success",
            color: "success",
            backgroundColor: "success-50",
            icon: "check-circle",
            title: "RechargementPanel.top-up-status-drawer-waiting-first-step-title",
            description: "RechargementPanel.top-up-status-drawer-waiting-first-step-description",
        },
        {
            status: "success",
            color: "success",
            backgroundColor: "success-50",
            icon: "check-circle",
            title: "RechargementPanel.top-up-status-drawer-waiting-second-step-title",
            description: "RechargementPanel.top-up-status-drawer-waiting-second-step-description",
        },
        {
            status: "waiting",
            color: "neutral",
            backgroundColor: "neutral-100",
            title: "RechargementPanel.top-up-status-drawer-waiting-third-step-title",
        },
    ],
});

export function HistoryWalletDrawerTopUpStatus() {
    const { t } = useTranslation();
    const transaction = useContext(TransactionContext);
    const navigate = useNavigate();

    const { status, TransactionType, amount, partnerComment } = transaction;

    const toDisplay = statusToDisplay(partnerComment, status)[status ?? "waiting"];

    const handleOpenDrawer = () => {
        navigate("/settings", { state: { currentTab: "notifications" } });
    };

    return TransactionType?.slug === "bank-disposal" && toDisplay ? (
        <div className='history-wallet-drawer-details'>
            <Accordion preSelectedOpen noHeaderPadding title={t("Wallets.top-up-on-going-status")} color='white'>
                {toDisplay?.map(({ status, title, description, icon, color, backgroundColor }, index) => (
                    <>
                        <div className='top-up-status-step-container' key={title}>
                            <Avatar
                                backgroundColor={backgroundColor}
                                color={color}
                                icon={icon}
                                firstname={status === "waiting" ? (index + 1).toString() : undefined}
                            />
                            <div className='top-up-status-step'>
                                <Typography
                                    className={classNames("fw-bold color-neutral-500", {
                                        "color-success": status === "success",
                                        "color-error": status === "failed",
                                    })}
                                    message={t(title)}
                                />
                                {description && (
                                    <Typography
                                        className='fw-lighter color-neutral-500'
                                        message={t(description, { amount: formatMoneyToString({ amount }) })}
                                    />
                                )}
                            </div>
                        </div>
                        {index + 1 < toDisplay.length ? (
                            <div className='top-up-dashed-line-container'>
                                <div className='dashed-line' />
                            </div>
                        ) : null}
                    </>
                ))}
                <div className='divider mt-3' />
                <div className='drawer-top-up-notification-container'>
                    <div className='drawer-top-up-notification'>
                        <Icon name='bell' color='lilas' variant='squared' size='lg' />
                        <Typography
                            message={t("HistoryWalletDrawerStatus.real-time-notifications")}
                            className='fw-bold'
                        />
                    </div>
                    <Button
                        variant='tertiary'
                        onClick={handleOpenDrawer}
                        label={t("HistoryWalletDrawerStatus.top-up-notifications")}
                    />
                </div>
            </Accordion>
        </div>
    ) : null;
}
