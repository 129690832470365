import { requestApiBillingBiller } from "src/services/billing/biller/operations";
import { BillingBiller } from "src/services/billing/biller/type";

import { useEffect, useState } from "react";

import { getBillingBiller } from "./selectors";
import { getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

export function useBillerWithExternalReference({
    walletId,
    externalReference,
    typeSlug,
    serviceSlug,
}: {
    externalReference: string;
    typeSlug: string;
    serviceSlug: string;
    walletId?: string | number;
}) {
    const [loading, setLoading] = useState(true);
    const [biller, setBiller] = useState<BillingBiller>();

    const reduxBiller = useAppSelector((state) => getBillingBiller(state, { reference: externalReference }));
    const company = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state));

    useEffect(() => {
        const fetchBiller = async () => {
            if (company?.favoriteBillers) {
                const biller = company.favoriteBillers.find(
                    (biller) => biller?.Biller?.reference === externalReference
                );
                if (biller?.Biller) {
                    setBiller(biller.Biller);
                }
            } else if (walletId) {
                const res = await requestApiBillingBiller({
                    walletId,
                    externalReference,
                    typeSlug,
                    serviceSlug,
                }).finally(() => setLoading(false));
                setBiller(res);
            } else if (company?.favoriteBillers) {
                const biller = company.favoriteBillers.find(
                    (biller) => biller?.Biller?.reference === externalReference
                );
                if (biller?.Biller) {
                    setBiller(biller.Biller);
                }
            }
            setLoading(false);
        };
        if (!reduxBiller) {
            fetchBiller();
        } else {
            setLoading(false);
        }
    }, [externalReference, reduxBiller, walletId]);

    return { loading, biller: reduxBiller ?? biller };
}
