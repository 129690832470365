import { middlewareRequest } from "../utils/request";
import { DocumentModel } from "src/shared/models/Document";

export async function requestGetUserDocumentByUserId(userId: string | number) {
    try {
        return await middlewareRequest<DocumentModel>({
            endpoint: `/users/${userId}/documents`,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetUserDocumentByCitizen(countryOfCitizenship: string) {
    try {
        return await middlewareRequest<DocumentModel>({
            endpoint: `/documents`,
            method: "POST",
            params: { countryOfCitizenship: countryOfCitizenship.toUpperCase() },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
