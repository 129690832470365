import { WalletBeneficiary } from "src/services/wallets/types";
import { BeneficiaryDrawerBody } from "./Body";

import { useTranslation } from "react-i18next";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

type Props = {
    isOpen: boolean;
    walletId: string | number;
    selectOnlyBeneficiary?: boolean;
    handleSetClose: () => void;
    handleSelectBeneficiary: (beneficiary: WalletBeneficiary) => void;
};

export function BeneficiaryDrawer({
    isOpen,
    walletId,
    selectOnlyBeneficiary = false,
    handleSetClose,
    handleSelectBeneficiary,
}: Props) {
    const { t } = useTranslation();
    return (
        <Drawer
            isOpen={isOpen}
            onClose={handleSetClose}
            header={{
                title: t("Transactions.choose-beneficiary"),
                image: "wallet",
            }}
            body={
                <BeneficiaryDrawerBody
                    walletId={walletId}
                    selectOnlyBeneficiary={selectOnlyBeneficiary}
                    handleSelectBeneficiary={handleSelectBeneficiary}
                />
            }
        />
    );
}
