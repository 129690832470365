import { requestDownloadReceipts } from "src/services/transactions/operations";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Modal } from "src/shared/components/Modal/Modal";

const successImage = require("src/shared/images/user/success.png");

type Props = {
    open: boolean;
    transactionUuids?: string[];
    onCloseDialog: () => void;
};

export function ReceiptsModal({ open, onCloseDialog, transactionUuids }: Readonly<Props>) {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [emailInput, setEmailInput] = useState("");
    const [emailList, setEmailList] = useState<string[]>([]);
    const [emailErrors, setEmailErrors] = useState<string | undefined>();

    const handleDowloadReceipts = () => {
        if (transactionUuids) {
            setLoading(true);

            requestDownloadReceipts({ transactionsUuids: transactionUuids, recipients: emailList })
                .then(() => setSuccess(true))
                .finally(() => setLoading(false));
        }
    };

    const handleAddEmail = () => {
        const trimmedEmail = emailInput.trim();
        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedEmail)) {
            setEmailList([...emailList, trimmedEmail]);
            setEmailInput("");
            setEmailErrors(undefined);
        } else {
            setEmailErrors("Invalid email format");
        }
    };

    const handleChangeEmailInput = (value: string) => {
        setEmailInput(value);
    };

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            handleAddEmail();
        }
    };

    const handleCloseDialog = () => {
        setSuccess(false);
        setEmailList([]);
        setEmailInput("");
        setEmailErrors(undefined);
        onCloseDialog();
    };

    const handleRemoveEmail = (emailToRemove: string) => () => {
        setEmailList(emailList.filter((email) => email !== emailToRemove));
    };

    return (
        <Modal
            open={open}
            header={{
                title: t("Transactions.download-multiple-receipts"),
                icon: "document",
            }}
            cancelLabel={t<string>("CommonUse.close")}
            handleOnSubmit={success ? handleCloseDialog : handleDowloadReceipts}
            disableSubmitButton={loading || !emailList.length}
            handleOnCancel={handleCloseDialog}
            size='md'
            confirmLabel={t<string>("CommonUse.continue")}
        >
            {!success ? (
                <div className='modal-multiple-action-content'>
                    <Alert
                        message={t("Transactions.download-multiple-receipts-alert", {
                            count: transactionUuids?.length,
                        })}
                    />

                    <div className='email-input-multiple-receipt-container'>
                        <Input
                            placeholder={t("Transactions.fill-a-recipient")}
                            label={t<string>("Transactions.recipient-email")}
                            value={emailInput}
                            onChange={handleChangeEmailInput}
                            onKeyDown={handleEnterKeyPress}
                            underMessage={emailErrors}
                            variant={emailErrors ? "error" : "primary"}
                            className='flex-grow-1'
                        />
                        <Button
                            onClick={handleAddEmail}
                            label={t("Export.add-email")}
                            className={emailErrors ? "error" : ""}
                        />
                    </div>
                    {emailList.length > 0 && (
                        <div className='email-list-multiple-receipt-show'>
                            {emailList.map((email) => (
                                <div key={email} className='item'>
                                    <Typography message={email} />
                                    <Icon
                                        onClick={handleRemoveEmail(email)}
                                        name='x-circle-filled'
                                        className='cursor-pointer'
                                        size='sm'
                                        color='neutral-500'
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className='success-multiple-receipt'>
                    <Typography className='fw-bold' message={t<string>("CommonUse.request-taken-into-consideration")} />
                    <div className='d-flex justify-content-center'>
                        <img src={successImage} alt='SUCCESS' />
                    </div>
                </div>
            )}
        </Modal>
    );
}
