import { AdminActivityLogs } from "src/services/admin/activityLogs/types";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

interface AdminActivityLogsState {
    loading: boolean;
    lastEvaluatedKey: AdminActivityLogs["lastEvaluatedKey"] | null;
    items: AdminActivityLogs["data"] | null;
    filters: {
        query?: string | null;
        date?: Date | null;
        userIds?: SelectOption[];
        actionSlugs?: SelectOption[];
    };
}

const initialState: AdminActivityLogsState = {
    loading: false,
    lastEvaluatedKey: null,
    items: null,
    filters: {},
};

export const adminActivityLogsSlice = createSlice({
    name: "adminActivityLogsSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestAdminActivityLogs: (state) => {
            state.loading = true;
        },
        receiveAdminActivityLogs: (state, { payload }: PayloadAction<AdminActivityLogs>) => {
            const { data, lastEvaluatedKey } = payload;

            state.loading = false;
            state.lastEvaluatedKey = lastEvaluatedKey;
            state.items = [...(state.items ? state.items : []), ...data];
        },
        failedRequestAdminActivityLogs: (state) => {
            state.loading = false;
        },
        setAdminActivityLogsUserFilter: (
            state,
            { payload }: PayloadAction<AdminActivityLogsState["filters"]["userIds"]>
        ) => {
            state.filters.userIds = payload;
        },
        setAdminActivityDateFilter: (state, { payload }: PayloadAction<Date | null>) => {
            state.filters.date = payload;
        },
        setAdminActivityQueryFilter: (state, { payload }: PayloadAction<string | null>) => {
            state.filters.query = payload;
        },
        setAdminActivityLogsActionSlugFilter: (
            state,
            { payload }: PayloadAction<AdminActivityLogsState["filters"]["actionSlugs"]>
        ) => {
            state.filters.actionSlugs = payload;
        },
        resetAdminActivityLogsFilters: (state) => {
            state.filters = {};
            state.lastEvaluatedKey = null;
        },
        clearAdminActivityLogs: (state) => {
            state.items = null;
            state.lastEvaluatedKey = null;
        },
    },
});

export const {
    requestAdminActivityLogs,
    receiveAdminActivityLogs,
    failedRequestAdminActivityLogs,
    setAdminActivityDateFilter,
    setAdminActivityQueryFilter,
    setAdminActivityLogsActionSlugFilter,
    setAdminActivityLogsUserFilter,
    resetAdminActivityLogsFilters,
    clearAdminActivityLogs,
} = adminActivityLogsSlice.actions;
