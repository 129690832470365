import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { TextArea } from "src/shared/atoms/Inputs/TextArea";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const defaultSmsMessage = "Tasks.sms-exemple";

const smsDefaultVariables = {
    DESTINATAIRE: "Transactions.receiver-name",
    SERVICE: "Tasks.sms-services-exemple",
    MONTANT: "Tasks.sms-amount-exemple",
    COMMENTAIRE: "Tasks.sms-comment-exemple",
};

const smsVariablesValues = {
    DESTINATAIRE: "[DESTINATAIRE]",
    SERVICE: "[SERVICE]",
    MONTANT: "[MONTANT]",
    COMMENTAIRE: "[COMMENTAIRE]",
};

function replaceAllDictionnary(
    str: string | undefined,
    dictionnary: { [key: string]: string },
    smsVariables: SmsVariablesValues | undefined
) {
    if (!str) {
        return "";
    }

    if (smsVariables) {
        dictionnary = {
            ...dictionnary,
            COMMENTAIRE: smsVariables.comment ?? "",
            MONTANT: smsVariables.amount ? smsVariables.amount.toString() : "0",
            DESTINATAIRE: smsVariables.receiverName ?? smsVariablesValues.DESTINATAIRE,
            SERVICE: smsVariables.service ?? smsVariablesValues.SERVICE,
        };
    }

    const regex = new RegExp("\\[" + Object.keys(dictionnary).join("\\]|\\[") + "\\]", "gi");
    return str.replace(regex, (matched) => {
        return dictionnary[matched.replace(/\[/g, "").replace(/\]/g, "")];
    });
}

export type SmsVariablesValues = {
    comment: string | null;
    amount: number | null;
    receiverName: string | null;
    service: string | null;
};

type SmsTransferCheckboxProps = {
    show?: boolean;
    handleSetSmsTemplate?: (smsTemplate: string | undefined) => void;
    smsVariables?: SmsVariablesValues;
    disabled?: boolean
};

export const SmsTransferCheckbox = ({ show, handleSetSmsTemplate, smsVariables, disabled = false }: SmsTransferCheckboxProps) => {
    const { t } = useTranslation();
    const companyName = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state)?.name ?? "");

    const [showSMS, setShowSMS] = useState(show ?? false);
    const [smsTemplate, setSmsTemplate] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (handleSetSmsTemplate) {
            handleSetSmsTemplate(replaceAllDictionnary(smsTemplate, smsVariablesValues, smsVariables));
        }
    }, [smsTemplate, handleSetSmsTemplate]);

    const handleChangeShowSMS = () => {
        setShowSMS(!showSMS);
    };

    useEffect(() => {
        if (!showSMS) {
            setSmsTemplate(undefined);
        } else {
            setSmsTemplate(t<string>(defaultSmsMessage, { companyName }));
        }
    }, [showSMS, companyName, t]);

    const handleUpdateSmsTemplate = (newTemplate: string) => {
        setSmsTemplate(newTemplate);
    };

    const handleAddVariableToText = (variable: string) => () => {
        setSmsTemplate(`${smsTemplate}[${variable}]`);
    };

    return (
        <div>
            <Checkbox
                label={<Typography className='fw-bold' message={t("BulkTransferPage.sms-checkbox-label")}  />}
                checked={showSMS}
                onChange={handleChangeShowSMS}
                disabled={disabled}
            />
            {showSMS && (
                <div className='sms-generator-container'>
                    <div className='sms-generator-item'>
                        <TextArea maxLength={230} value={smsTemplate} onChange={handleUpdateSmsTemplate} rows={4} />
                    </div>
                    <div className='sms-generator-item'>
                        <Typography message={t("Tasks.variables")} className='fw-bold' />
                        <div className='sms-generator-variables-container '>
                            {Object.entries(smsDefaultVariables).map(([title, description]) => (
                                <ToolTip
                                    activation
                                    content={<Typography message={t<string>(description)} />}
                                    key={title}
                                    position='bottom'
                                >
                                    <div onClick={handleAddVariableToText(title)} className='sms-generator-variables'>
                                        <Typography message={`[${t(title)}]`} />
                                    </div>
                                </ToolTip>
                            ))}
                        </div>
                    </div>
                    <div className='sms-generator-item'>
                        <Typography message={t("Tasks.custom-sms")} />
                        <TextArea
                            value={replaceAllDictionnary(smsTemplate, smsVariablesValues, smsVariables)}
                            disabled
                            rows={4}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
