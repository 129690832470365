import { SyntheticEvent } from "react";

import { Button } from "src/shared/atoms/Buttons/Button";
import { TypographyProps } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    classname?: string;
    handleDelete?: () => void;
    disabled?: boolean;
} & TypographyProps;

export function Chip({ classname = "", handleDelete, disabled, ...typographyProps }: Props) {
    const handlePreventDefault = (event: SyntheticEvent) => {
        event.stopPropagation();
        if (handleDelete) {
            handleDelete();
        }
    };

    return (
        <div className='chip-container'>
            <Button
                disabled={disabled}
                variant='tertiary'
                icon='x-circle-filled'
                onClick={handlePreventDefault}
                label={typographyProps.message}
            />
        </div>
    );
}
