import { setInStorage } from "../utils/storage";

import { useCallback, useEffect } from "react";

export function useCloseTabLogout() {
    const handleSetLastTabTimestamp = useCallback(() => {
        setInStorage("lastTabTimestamp", new Date().toString());
    }, []);

    useEffect(() => {
        window.addEventListener("beforeunload", handleSetLastTabTimestamp);
        return () => {
            window.removeEventListener("beforeunload", handleSetLastTabTimestamp);
        };
    }, [handleSetLastTabTimestamp]);
}
