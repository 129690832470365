import moment from "moment";
import "moment/locale/fr";

type Format =
    | "DD/MM/YYYY"
    | "DD/MM/YY - HH:mm"
    | "HH:mm:ss"
    | "DD/MM/YY HH:mm:s"
    | "dddd D MMM Y - HH:mm"
    | "DD/MM/YYYY - HH:mm";

export const formatDateForRequest = (date: Date | null | undefined) => {
    if (date) {
        return moment(date).format("YYYY-MM-DD"); // date format for network call
    } else {
        return null;
    }
};

export function computeTimestampToString(timestamp: Date | string, format: Format = "DD/MM/YYYY") {
    return moment(timestamp).format(format);
}
