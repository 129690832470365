import { Button } from "../../Buttons/Button";
import { Typography } from "../../Typography/Typography";
import { DrawerButton } from "../Drawer";

import "./styles.scss";

type DrawerFooterProps = {
    primaryButton: DrawerButton;
    secondaryButton?: DrawerButton;
    optionalText?: string;
};

export const DrawerFooter = ({ primaryButton, secondaryButton, optionalText }: DrawerFooterProps) => {
    return (
        <div className='drawer-footer-container'>
            <div className='drawer-footer-text'>
                {optionalText && (
                    <>
                        <div className='centered-dot' />
                        <Typography message={optionalText} />
                    </>
                )}
            </div>
            <div className='drawer-footer-buttons'>
                {secondaryButton && (
                    <Button
                        variant='tertiary'
                        color='primary'
                        {...secondaryButton}
                        testId='drawer-footer-secondary-button'
                    />
                )}
                <Button variant='primary' color='primary' {...primaryButton} testId='drawer-footer-primary-button' />
            </div>
        </div>
    );
};
