import { Dispatch, SetStateAction } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";
import { Modal } from "src/shared/components/Modal/Modal";

import "./styles.scss";

const success = require("src/shared/images/login/email-verification-success.png");

type Props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};
export function LoginEmailVerificationDialog({ open, setOpen }: Readonly<Props>) {
    const { t } = useTranslation();

    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <Modal
            hideCancelButton
            size='md'
            open={open}
            confirmLabel={t<string>("CommonUse.close")}
            header={{ title: t("LoginPanel.verify-email"), icon: "send" }}
            handleOnSubmit={handleCloseModal}
        >
            <div className='modal-email-success'>
                <Typography message={t("LoginPanel.verification-link-success")} className='fw-bold' />
                <Typography message={t("LoginPanel.verification-link-success-description")} />
                <div className='link-image-container'>
                    <img className='link-image-success' alt='success' src={success} />
                </div>
            </div>
        </Modal>
    );
}
