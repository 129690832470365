import { formatMoney } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { getBillingEnterpriseBillsReceived } from "src/modules/billing/enterprise/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

export function BillingGetPaidModuleBillNumber() {
    const { t } = useTranslation();

    const billsReceived = useAppSelector((state) => getBillingEnterpriseBillsReceived(state));

    return (
        <div className='bill-module-container'>
            <Icon name='receipt-accepted' />
            <Typography className='color-neutral-500 fw-bold' message={t("Bills.bills-payed")} />
            <Typography size='lg' className='color-lilas-900 fw-bold' message={formatMoney(billsReceived ?? 0)} />
        </div>
    );
}
