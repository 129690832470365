import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { DrawerContext } from "src/wrapper/context";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type TransfertMethod = {
    title: string;
    description: string;
    icon: IconListType;
    link: string;
    id?: string;
};

const transferMethods: TransfertMethod[] = [
    {
        title: "NavLinks.transfert-rapide",
        description: "TransferDrawer.rapid-transfer-msg",
        icon: "bolt",
        link: "/transfert/rapid-transfert",
        id: "drawer_topup_iban",
    },
    {
        title: "CommonUse.add-task",
        description: "TransferDrawer.bulk-transfer-msg",
        icon: "bulk",
        link: "/transfert/bulk-transfert",
        id: "drawer_topup_bank-transfer",
    },
];

export function TransfertDrawerBody() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { providedState, setOpen } = useContext(DrawerContext);

    const handleRedirect = (link: string) => () => {
        navigate(link, { ...(providedState?.sendStateOnRedirection ? { state: providedState } : {}) });
        setOpen(undefined);
    };

    return (
        <div className='transfer-drawer-body-container'>
            {transferMethods.map(({ title, description, icon, link, id }) => {
                return (
                    <div
                        key={title}
                        onClick={handleRedirect(link)}
                        id={id}
                        className={classNames("transfer-drawer-body-section")}
                    >
                        <Avatar icon={icon} color='lilas-900' backgroundColor='lilas-50' size='lg' />
                        <div className='transfer-drawer'>
                            <Typography size='sm' className='fw-bold' message={t(title)} />
                            <Typography size='sm' message={t(description)} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
