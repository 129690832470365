import { useTranslation } from "react-i18next";

import { getTaskToEdit } from "src/modules/tasks/selectors";
import {
    getTaskItems,
    getTaskItemsErrorsCount,
    getTaskItemsFileUploading,
    getTaskItemsIsUpdatingCount,
} from "src/modules/tasksItems/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    taskId: number | undefined;
};

export const EditTaskFooterStatus = ({ taskId }: Props) => {
    const { t } = useTranslation();

    const taskItems = useAppSelector((state) => getTaskItems(state, { taskId }));
    const taskItemsLoading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));
    const task = useAppSelector((state) => getTaskToEdit(state));
    const updateCount = useAppSelector((state) => getTaskItemsIsUpdatingCount(state, { taskId }));
    const errorsCount = useAppSelector((state) => getTaskItemsErrorsCount(state, { taskId }));

    const taskItemsLoadingLenght = Object.keys(taskItemsLoading ?? {}).length;
    const total = Object.keys(taskItems).length + taskItemsLoadingLenght;
    const current = total - (updateCount + taskItemsLoadingLenght);
    const percent = (current / total) * 100;

    return updateCount || taskItemsLoadingLenght ? (
        <div className='edit-task-footer-item background-warning-50 '>
            <Icon name='clock' color='warning-500' />
            <Typography className='mr-3 fw-bold' message={`${t("CommonUse.checking")} (${current}/${total})`} />
            <div className='loader d-block' style={{ height: 10 }}>
                <div className='loader-inner' style={{ width: `${percent}%` }}></div>
            </div>
        </div>
    ) : task?.errorCode ? (
        <div className='edit-task-footer-item background-error-50'>
            <Icon name='x-circle' color='error-500' />
            <Typography className='fw-bold' message={t("EditTaskFooterStatus.solde-insuffisant")} />
        </div>
    ) : errorsCount > 0 ? (
        <div className='edit-task-footer-item background-error-50'>
            <Icon name='x-circle' color='error-500' />
            <Typography
                className='fw-bold'
                message={`${errorsCount} ${t("EditTaskFooterStatus.operation-s-a-verifier")}`}
            />
        </div>
    ) : Object.keys(taskItems).length === 0 ? (
        <div className='edit-task-footer-item background-neutral-50'>
            <Icon name='check-circle' color='neutral-500' />
            <Typography className='fw-bold' message={t("EditTaskFooterStatus.aucune-transaction-a-verifier")} />
        </div>
    ) : (
        <div className='edit-task-footer-item background-success-50'>
            <Icon name='check-circle' color='success-500' />
            <Typography className='fw-bold' message={t("EditTaskFooterStatus.les-operations-sont-verifiees")} />
        </div>
    );
};
