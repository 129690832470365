import { QuestTutorialItem } from "./Section";
import classNames from "classnames";

import { useTranslation } from "react-i18next";

import { getAdminDashboardInfoData } from "src/modules/admin/selectors";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = QuestTutorialItem & {
    handleSetContent: (item: QuestTutorialItem) => () => void;
};

export function AdminDashboardQuestsTutorialsItem({
    id,
    buttonLabel,
    description,
    image,
    minutes,
    title,
    videoLink,
    handleSetContent,
    ...props
}: Readonly<Props>) {
    const { t } = useTranslation();

    const questsData = useAppSelector((state) => getAdminDashboardInfoData(state)?.quests);

    const questStatus = questsData?.[id]?.status ?? false;

    return (
        <div className='quests-carousel-item' key={`quest-${id}`}>
            <div
                className={classNames("quests-carousel-item-header", {
                    "to-do-status": !questStatus,
                    "done-status": questStatus,
                })}
            >
                <div>
                    <div className='quest-status'>
                        <Icon
                            size='sm'
                            name={questStatus ? "check-circle" : "bulb"}
                            color={questStatus ? "lilas" : "pink"}
                        />
                        <Typography className='fw-bold' message={t(questStatus ? "Terminé" : "À découvrir")} />
                    </div>
                </div>
                <img height={60} className='header-image' src={image} alt='' />
            </div>
            <div
                className={classNames("quests-carousel-item-content", {
                    "to-do-status": !questStatus,
                    "done-status": questStatus,
                })}
            >
                <div className='d-flex flex-column'>
                    <div className='d-flex flex-column'>
                        <Typography message={t(title)} className='fw-bold' />
                        <Typography message={t("{{minutes}} minutes", { minutes })} className='color-lilas-900 mb-2' />
                    </div>
                    <Typography message={t(description)} />
                </div>
                <Button
                    className='w-100'
                    onClick={handleSetContent({
                        buttonLabel,
                        description,
                        id,
                        image,
                        minutes,
                        title,
                        videoLink,
                        ...props,
                    })}
                    label={t(buttonLabel)}
                />
            </div>
        </div>
    );
}
