import { TransactionSlug } from "../../../services/transactions/types";
import { operationTypes } from "../../../shared/const/transactions";
import { Tag } from "./Tag";

type Props = {
    typeSlug: TransactionSlug;
    size?: "sm" | "md" | "lg";
};

export const OperationTag = ({ typeSlug, size }: Props) => {
    const operationType = operationTypes?.[typeSlug];

    return operationType ? (
        <Tag
            label={operationType.title}
            type='accent'
            icon={operationType.icon}
            color={operationType.color ? operationType.color : "primary"}
            testId='operation-tag'
            size={size}
        />
    ) : (
        <span className='text-center'>-</span>
    );
};
