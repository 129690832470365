import { WalletApi } from "src/services/wallets/types";
import UneligibleImage from "src/shared/images/loan/uneligible.svg";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getAuthorizedWallets,
    getBankDisposalWallets,
    getSendWallets,
    getWalletLoading,
    getWallets,
} from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

import "./styles.scss";

export type WalletType = "SEND" | "INIT" | "TOPUP";

type WalletSelectionDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    handleSelectedWallet: (wallet: WalletApi) => void;
    walletType?: WalletType;
    disableZeroBalance?: boolean;
    isWalletRestricted?: boolean;
    id?: string;
    title?: string;
};

export function WalletSelectionDrawer({
    isOpen,
    onClose,
    handleSelectedWallet,
    walletType,
    disableZeroBalance = false,
    isWalletRestricted = false,
    id,
    title,
}: Readonly<WalletSelectionDrawerProps>) {
    const { t } = useTranslation();
    const [research, setResearch] = useState("");

    const wallets = useAppSelector((state) => {
        switch (walletType) {
            case "INIT":
                return getAuthorizedWallets(state);
            case "SEND":
                return getSendWallets(state);
            case "TOPUP":
                return getBankDisposalWallets(state);
            default:
                return getWallets(state);
        }
    });

    const walletsLoading = useAppSelector((state) => getWalletLoading(state));

    const filteredWallets = Object.values(wallets).filter(
        (wallet) => wallet.label !== null && wallet.label.toLowerCase().includes(research.toLowerCase())
    );

    const sortedWallets = [...filteredWallets].sort((a, b) =>
        a.balance !== null && b.balance !== null ? b.balance - a.balance : 0
    );

    const handleResearch = (value: string) => {
        setResearch(value);
    };

    const selectWallet = (wallet: WalletApi) => {
        handleSelectedWallet(wallet);
    };

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            header={{
                title: t(title ?? "Transactions.choose-debit-wallet"),
                image: "wallet",
            }}
            body={
                <div className='debit-drawer-body-container'>
                    <Input
                        placeholder={t("CommonUse.search")}
                        inputIcon='magnifying-glass'
                        onChange={handleResearch}
                        value={research}
                        className='search-input'
                    />
                    {!walletsLoading && sortedWallets.length === 0 && research && (
                        <div className='error-message-no-wallets-available'>
                            <div
                                className='uneligible-image'
                                style={{ backgroundImage: `url(${UneligibleImage})` }}
                            ></div>
                            <Typography message={t("DebitDrawerBody.aucun-compte-credite-a-ce-nom")} />
                        </div>
                    )}
                    <List
                        keyName={"wallets"}
                        extended
                        isLoading={walletsLoading}
                        animation='fadeInUp'
                        items={sortedWallets.map((wallet, index) => {
                            let isDisabled =  false;
                            let errorWording;
                            
                            if(disableZeroBalance && wallet.balance === 0){
                                isDisabled = true;
                                errorWording = t("CommonUse.insufficient-balance");
                            }else if(isWalletRestricted && !wallet.isDefault){
                                isDisabled = true;
                            }

                            return (
                                <div
                                    className={`item-container ${isDisabled ? "disabled" : ""}`}
                                    onClick={() => {
                                        if (!isDisabled) {
                                            selectWallet(wallet);
                                        }
                                    }}
                                    id={id}
                                    data-testid={"drawer-wallet-debit-item-" + index}
                                >
                                    <div className='left-content'>
                                        <Avatar size='sm' icon='wallet' />
                                        <div className='item-content'>
                                            <Typography className='fw-bold' message={wallet.label} />
                                            <Typography
                                                className={`color-neutral-500`}
                                                message={`
                                                ${formatMoneyToString({
                                                    amount: wallet.balance,
                                                    zeroValue: true,
                                                })} ${isDisabled && errorWording? `(${errorWording})` : ""}
                                            `}
                                            />
                                        </div>
                                    </div>
                                    <Icon
                                        className='item-icon'
                                        size='md'
                                        name={!isDisabled ? "chevron-right" : "lock"}
                                    />
                                </div>
                            );
                        })}
                    />
                </div>
            }
        />
    );
}
