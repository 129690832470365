import { Dispatch, SetStateAction, createContext } from "react";

export type LoginContextProps = {
    identifier: string;
    setIdentifier: Dispatch<SetStateAction<string>>;
    pinCode: string;
    setPinCode: Dispatch<SetStateAction<string>>;
    tfaCode: string;
    setTfaCode: Dispatch<SetStateAction<string>>;
    password: string;
    setPassword: Dispatch<SetStateAction<string>>;
};

export const LoginContext = createContext<LoginContextProps>({} as LoginContextProps);
