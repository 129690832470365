import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { TasksSignaturePanel } from "src/components/Tasks/Signature/Panel";

const SignatureTaskPageUnauthorized = () => {
    return <TasksSignaturePanel />;
};

export const SignatureTaskPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER],
})(SignatureTaskPageUnauthorized);
