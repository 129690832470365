import { HistoryWalletDrawerContainer } from "./Drawer/Container";
import { BillingGetPaidModuleBillAmount } from "./Modules/BillAmount";
import { BillingGetPaidModuleBillNumber } from "./Modules/BillNumber";
import { BillingGetPaidModuleEnterprise } from "./Modules/Enterprise";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUserPreferenceSelectedCompanyId } from "src/modules/auth/selectors";
import { getBillingEnterpriseError, getBillingEnterpriseIsLoading } from "src/modules/billing/enterprise/selectors";
import { fetchBillingEnterprise } from "src/modules/billing/enterprise/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Empty } from "src/shared/components/Empty/Empty";

import "./styles.scss";

const emptyListImg = require("src/shared/images/empty/no-data.svg").default;

export function BillingGetPaidPanel() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();

    const companyId = useAppSelector((state) => getAuthUserPreferenceSelectedCompanyId(state));
    const loading = useAppSelector((state) => getBillingEnterpriseIsLoading(state));
    const error = useAppSelector((state) => getBillingEnterpriseError(state));

    useEffect(() => {
        if (companyId) {
            dispatch(fetchBillingEnterprise({ companyId }))
                .unwrap()
                .catch(() => dispatch(fetchBillingEnterprise({ companyId })));
        }
    }, [dispatch, companyId]);

    const handleSwitchOpen = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    return (
        <>
            <div className='get-paid-container'>
                {loading ? (
                    <div className=' bill-modules-skeleton-container'>
                        <div className='main-module' />
                        <div className='secondary-module-container'>
                            <div className='module' />
                            <div className='module' />
                        </div>
                    </div>
                ) : error ? (
                    <div className='get-paid-error'>
                        <Empty
                            image={emptyListImg}
                            title={t("ErrorsBasedOnReturnCode.default")}
                            description={t<string>("Bills.error-fetch-biller-details")}
                        />
                    </div>
                ) : (
                    <>
                        <BillingGetPaidModuleEnterprise setOpen={handleSwitchOpen} />
                        <div className='get-paid-modules'>
                            <BillingGetPaidModuleBillNumber />
                            <BillingGetPaidModuleBillAmount />
                        </div>
                    </>
                )}
            </div>
            <HistoryWalletDrawerContainer open={open} handleCloseDrawer={handleSwitchOpen} />
        </>
    );
}
