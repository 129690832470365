import { IbanDrawerSectionDetails } from "./sections/Details";
import { IbanDrawerSectionInformations } from "./sections/Informations";
import { IbanDrawerSectionRib } from "./sections/Rib";

export function IbanDrawerBody() {
    return (
        <div className='history-wallet-drawer-body'>
            <IbanDrawerSectionInformations />
            <IbanDrawerSectionRib />
            <IbanDrawerSectionDetails />
        </div>
    );
}
