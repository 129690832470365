import { AdminDashboardQuestsTutorialsItem } from "./Item";
import { useNavigate } from "react-router-dom";
import { QuestKeys } from "src/services/admin/types";
import { DrawerContext } from "src/wrapper/context";

import { useCallback, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { fetchAdminDashboardInfoData } from "src/modules/admin/thunkActions";
import { getWalletWithIban } from "src/modules/wallets/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Carousel } from "src/shared/atoms/Carousel/Carousel";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Modal } from "src/shared/components/Modal/Modal";

import "./styles.scss";

const topUpImage = require("src/shared/images/on-boarding/top-up.png");
const facturationImage = require("src/shared/images/on-boarding/facturation.png");
const walletImage = require("src/shared/images/on-boarding/wallet.png");
const exportImage = require("src/shared/images/on-boarding/export.png");

const scriptVideo = "https://js.storylane.io/js/v1/storylane.js";

export type QuestTutorialItem = {
    id: QuestKeys;
    title: string;
    minutes: number;
    description: string;
    image: string;
    buttonLabel: string;
    videoLink: string;
    action?: () => void;
    link?: string;
};

const quests = (handleOpenTopUpDrawer: () => void): QuestTutorialItem[] => {
    return [
        {
            id: "top_up",
            title: "RechargementPanel.rechargement",
            videoLink: "https://app.storylane.io/demo/os48p2efjbd1",
            buttonLabel: "TopUp.recharge-your-wallet",
            description: "TopUp.recharge-your-wallet-description",
            minutes: 4,
            image: topUpImage,
            action: handleOpenTopUpDrawer,
        },
        {
            id: "bill_payment",
            title: "Billing.billing",
            videoLink: "https://app.storylane.io/demo/ukhjcanzkaxv",
            buttonLabel: "Billing.pay-bill",
            description: "Billing.pay-bill-description",
            minutes: 4,
            image: facturationImage,
            link: "/billing/pay-bill",
        },
        {
            id: "wallets",
            title: "Wallets.multi-wallets",
            videoLink: "https://app.storylane.io/demo/sxkq8y1pal6e",
            buttonLabel: "Wallets.create-a-wallet",
            description: "Wallets.create-a-wallet-description",
            minutes: 5,
            image: walletImage,
            link: "/admin/wallets",
        },
        {
            id: "exports",
            title: "Export.export-quest-title",
            videoLink: "https://app.storylane.io/demo/9cagwptcwbta",
            buttonLabel: "Export.do-an-export",
            description: "Export.do-an-export-description",
            minutes: 3,
            image: exportImage,
            link: "/export",
        },
    ];
};

export function AdminDashboardQuestsTutorialsSection() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { setOpen, setProvidedState } = useContext(DrawerContext);

    const [modalContent, setModalContent] = useState<QuestTutorialItem>();

    const wallet = useAppSelector((state) => getWalletWithIban(state));

    useEffect(() => {
        dispatch(fetchAdminDashboardInfoData());
    }, [dispatch]);

    const handleSetContent = (item: QuestTutorialItem) => () => {
        setModalContent(item);
    };

    const handleCloseModal = () => {
        setModalContent(undefined);
    };

    const handleTriggerAction = () => {
        if (modalContent?.link) {
            navigate(modalContent.link);
        } else if (modalContent?.action) {
            modalContent.action();
        }
        handleCloseModal();
    };

    const handleOpenTopUpDrawer = useCallback(() => {
        setOpen("topUp");
        setProvidedState({ walletId: String(wallet?.id), sendStateOnRedirection: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet?.id]);

    return (
        <>
            <div className='quests-container'>
                <div className='quests-header'>
                    <Avatar icon='bulb' />
                    <div className='d-flex flex-column'>
                        <Typography className='fw-bold' message={t("Dashboard.discover-julaya")} />
                        <Typography message={t("Dashboard.discover-julaya-description")} />
                    </div>
                </div>

                <div className='quests'>
                    <Carousel
                        header={<Typography className='fw-bold' message={t("Dashboard.how-to-use-services")} />}
                        items={quests(handleOpenTopUpDrawer).map((quest) => (
                            <AdminDashboardQuestsTutorialsItem {...quest} handleSetContent={handleSetContent} />
                        ))}
                    />
                </div>
            </div>
            <Modal
                size='lg'
                confirmLabel={t<string>(modalContent?.buttonLabel ?? "CommonUse.continue")}
                open={!!modalContent}
                header={{ title: modalContent?.title ? t(modalContent?.title) : "" }}
                handleOnSubmit={handleTriggerAction}
                handleOnCancel={handleCloseModal}
                hideCancelButton
                showCloseIconButton
            >
                {modalContent?.videoLink && (
                    <div className='quest-modal-container'>
                        <script src={scriptVideo} />
                        <div className='quest-video-container'>
                            <iframe src={modalContent.videoLink} title={modalContent?.title} className='quest-video' />
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
}
