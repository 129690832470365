import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { CollectPanel } from "src/components/Collect/Panel";

const CollectPageUnauthorized = () => {
    return <CollectPanel />;
};

export const CollectPage = withAuthorization({
    allowedRoles: [CompanyRole.USER, CompanyRole.READ, CompanyRole.ADMIN],
})(CollectPageUnauthorized);
