import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { BillingPayBillPanel } from "src/components/Billing/PayBill/PaymentList/Panel";

export const BillingPayBillListPageUnauthorized = () => {
    return <BillingPayBillPanel />;
};

export const BillingPayBillListPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER],
})(BillingPayBillListPageUnauthorized);
