import { failedRequestAdminDocuments, receiveAdminDocuments, requestAdminDocuments } from "./slice";
import { handleRequestAdminDocuments } from "src/services/admin/documents/operations";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAdminDocuments = createAsyncThunk("fetchAdminDocuments", async (_params: undefined, { dispatch }) => {
    dispatch(requestAdminDocuments());
    try {
        const response = await handleRequestAdminDocuments();
        dispatch(receiveAdminDocuments(response));
    } catch (error: any) {
        dispatch(failedRequestAdminDocuments());
    }
});
