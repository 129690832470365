import { BillersCategoryFilter, failedBillingBillers, receiveBillingBillers, requestBillingBillers } from "./slice";
import { requestApiBillingBillers } from "src/services/billing/biller/operations";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export const fetchBillingBillers = createAsyncThunk(
    "fetchNewNotifications",
    async (
        {
            page,
            pageSize = 20,
            query,
            sectors,
            categoryFilter,
        }: {
            page: number;
            pageSize?: number;
            query?: string;
            sectors?: SelectOption[];
            categoryFilter?: BillersCategoryFilter;
        },
        { dispatch }
    ) => {
        dispatch(requestBillingBillers());
        try {
            const { data, count, currentPage } = await requestApiBillingBillers({
                page,
                pageSize,
                query,
                ...(sectors?.length ? { sectors: sectors.map((sector) => sector.id) } : {}),
                ...(categoryFilter ? { categories: [categoryFilter] } : {}),
            });
            dispatch(receiveBillingBillers({ data, totalCount: count, currentPage }));
            return data;
        } catch (error) {
            dispatch(failedBillingBillers());
            return error;
        }
    }
);
