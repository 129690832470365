import { AdminBeneficiariesFilters } from "../BeneficiariesList/Filters";
import { CreateBeneficiaryDrawerContainer } from "../CreateDrawer/Container";
import { DetailsBeneficiaryDrawerContainer } from "../DetailsDrawer/Container";
import { BeneficiariesCreateActions } from "../Panel";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { transactionTypesMap } from "src/shared/const/transactions";
import { BeneficiaryModel, ExternalAccount } from "src/shared/models/Beneficiary";
import { isValidPhone } from "src/shared/utils/isValidPhone";
import { parseBeneficiaries } from "src/utils/xlsx/parseBeneficiaries";

import { ChangeEvent, DragEvent, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminBeneficiaries } from "src/modules/admin/adminBeneficiaries/selectors";
import { handleCreateBulkAdminBeneficiary } from "src/modules/admin/adminBeneficiaries/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { ButtonSelect } from "src/shared/atoms/Buttons/ButtonSelect/ButtonSelect";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const successImage = require("src/shared/images/user/success.png");

type AddBeneficiaryProps = {
    handleSwitchToTable: () => void;
    preselectedAction?: BeneficiariesCreateActions;
};

export const AddBeneficiary = ({ handleSwitchToTable, preselectedAction }: AddBeneficiaryProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [displayTable, setDisplayTable] = useState(false);
    const [query, setQuery] = useState("");
    const [beneficiaryList, setBeneficiaryList] = useState<BeneficiaryModel[]>([]);
    const [filteredBeneficiaryList, setFilteredBeneficiaryList] = useState<BeneficiaryModel[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
    const [openPasswordValidationModal, setOpenPasswordValidationModal] = useState(false);

    const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState<BeneficiaryModel | null>(null);
    const [selectedBeneficiaryIndex, setSelectedBeneficiaryIndex] = useState<number | undefined>(undefined);
    const [disableForm, setDisableForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const previousPreselectedActionRef = useRef<BeneficiariesCreateActions | undefined>(undefined);

    const [errorFileData, setErrorFileData] = useState<string | undefined>(undefined);
    const { integrateBeneficiaryError, integratedBeneficiaryResponse } = useAppSelector(getAdminBeneficiaries);

    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        if (!integrateBeneficiaryError && integratedBeneficiaryResponse) {
            setShowSuccess(true);
        }
    });

    useEffect(() => {
        if (query) {
            const newFilteredBeneficiaryList = beneficiaryList.filter((beneficiary) => {
                const { firstname, lastname, label } = beneficiary;
                return (
                    firstname.toLowerCase().includes(query.toLowerCase()) ||
                    lastname.toLowerCase().includes(query.toLowerCase()) ||
                    label?.toLowerCase().includes(query.toLowerCase())
                );
            });
            setFilteredBeneficiaryList(newFilteredBeneficiaryList);
        } else {
            setFilteredBeneficiaryList(beneficiaryList);
        }
    }, [query, beneficiaryList]);

    useEffect(() => {
        if (beneficiaryList.length) {
            setDisplayTable(true);
        }
    }, [beneficiaryList]);

    useEffect(() => {
        if (preselectedAction !== previousPreselectedActionRef.current) {
            handlePreSelectActions(preselectedAction);
            previousPreselectedActionRef.current = preselectedAction;
        }
    }, [preselectedAction]);

    const handlePreSelectActions = (action?: BeneficiariesCreateActions) => {
        switch (action) {
            case "single":
                handleOpenCreateDrawer();
                break;
            case "bulk":
                handleClickInput();
                break;
            default:
                break;
        }
    };

    const handleOpenDetailsDrawer = (beneficiary: BeneficiaryModel, index: number) => {
        setSelectedBeneficiary(beneficiary);
        setSelectedBeneficiaryIndex(index);
        setOpenDetailsDrawer(!openDetailsDrawer);
    };

    const handleSelectedRows = (selectedRows: number[] | ["all"]) => {
        setSelectedRows(selectedRows as number[]);
    };

    const handleSelectRow = (index: number) => {
        const newSelectedRows = selectedRows.includes(index)
            ? selectedRows.filter((selectedIndex) => selectedIndex !== index)
            : [...selectedRows, index];
        setSelectedRows(newSelectedRows);
    };

    const handleDisplayTable = () => {
        setDisplayTable(!displayTable);
    };

    const handleSetQuery = (query: string) => {
        setQuery(query);
    };

    const handleBulkDelete = () => {
        const newBeneficiaryList = beneficiaryList.filter((_, index) => !selectedRows.includes(index));
        setBeneficiaryList(newBeneficiaryList);
        handleSelectedRows([]);
    };

    const handleOpenCreateDrawer = () => {
        setOpenCreateDrawer(true);
    };

    const handleCloseAddDrawer = (refresh?: boolean | undefined, beneficiary?: BeneficiaryModel | undefined) => {
        console.log(refresh);
        if (beneficiary) {
            setBeneficiaryList([...beneficiaryList, beneficiary]);
        }
        setOpenCreateDrawer(false);
    };

    const handleCloseEditDrawer = (refresh?: boolean | undefined, beneficiary?: BeneficiaryModel | undefined) => {
        if (!refresh) {
            setSelectedBeneficiary(null);
            setSelectedBeneficiaryIndex(undefined);
        }

        if (beneficiary) {
            const newBeneficiaryList = [...beneficiaryList];
            newBeneficiaryList[selectedBeneficiaryIndex as number] = beneficiary;
            setBeneficiaryList(newBeneficiaryList);
        }
        setOpenDetailsDrawer(false);
    };

    const handleSendBulkBeneficiaries = async (password: string, twoFactor: string | null) => {
        await dispatch(handleCreateBulkAdminBeneficiary({ beneficiaryList, password, twoFactor }));
    };

    const handleDeleteRow = (index: number) => {
        const newBeneficiaryList = [...beneficiaryList];
        newBeneficiaryList.splice(index, 1);
        setBeneficiaryList(newBeneficiaryList);
    };

    const onFileLoaded = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            let reader = new FileReader();
            reader.onload = async function (e) {
                setIsLoading(true);
                let data = e?.target?.result;
                if (data) {
                    try {
                        const fileData = parseBeneficiaries(t, data);

                        if (typeof fileData === "string") {
                            alert(fileData);
                        } else if (fileData) {
                            setBeneficiaryList((prevList) => [...prevList, ...(fileData as BeneficiaryModel[])]);
                        } else {
                            if (fileData === null) {
                                setErrorFileData("Tasks.empty-file");
                            }
                        }
                    } catch (e) {
                        setErrorFileData("Tasks.file-is-not-standart-choose-another");
                        setDisableForm(true);
                    }
                    setIsLoading(false);
                }
            };
            reader.readAsBinaryString(selectedFile);
            event.target.value = "";
            setIsLoading(false);
        }
    };

    const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = "copy";
        }
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];

            onFileLoaded({
                target: { files: [file] },
                preventDefault: event.preventDefault,
            } as unknown as ChangeEvent<HTMLInputElement>);
        }
    };

    const handleClickInput = () => {
        fileInputRef.current?.click();
    };

    const handleDownloadModel = () => {
        const link = document.createElement("a");
        link.href = "/models/beneficiares_modele_julaya.xlsx";
        link.download = "beneficiares_modele_julaya.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleOpenPasswordValidationModal = () => {
        setShowSuccess(false);
        setOpenPasswordValidationModal(!openPasswordValidationModal);
    };

    const handleOnSuccess = () => {
        setBeneficiaryList([]);
        handleSwitchToTable();
    };

    const handleContinueWithoutFile = () => {
        handleDisplayTable();
        handleOpenCreateDrawer();
    };

    const renderTooltipExternalAccountError = (externalAccounts: ExternalAccount[]) => {
        const atLeastOneExternalAccount = externalAccounts.length > 0;
        const noEmptyExternalAccount = externalAccounts.every((account) => account.externalReference !== "");
        const hasBankTransferError = externalAccounts.some((account) => {
            if (account.typeSlug === "bank-transfer") {
                return (
                    !/^[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{22}$/.test(account.externalReference) &&
                    !/^[A-Z]{2}[0-9]{22}$/.test(account.externalReference)
                );
            }
            return false;
        });
        const hasValidPhoneNumber = externalAccounts.some((account) => {
            if (
                account.typeSlug === "wave-transfer" ||
                account.typeSlug === "cb-transfer" ||
                account.typeSlug === "disposal"
            ) {
                return !isValidPhone(account.externalReference);
            }
            return false;
        });

        if (!atLeastOneExternalAccount) {
            return { title: t("Beneficiaries.no-external-account") };
        } else if (!noEmptyExternalAccount) {
            return { title: t("Beneficiaries.no-empty-external-account") };
        } else if (hasBankTransferError) {
            return { title: t("Beneficiaries.iban-format-wrong") };
        } else if (hasValidPhoneNumber) {
            return { title: t("Beneficiaries.invalid-phone") };
        }

        return undefined;
    };

    const tableRows = filteredBeneficiaryList.map((beneficiary, index) => {
        const { firstname, lastname, email, label, externalAccounts } = beneficiary;

        const isEmailValid = email ? email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g) : true;

        const tooltipError = renderTooltipExternalAccountError(externalAccounts);

        return [
            {
                content: <Avatar firstname={firstname} lastname={lastname} />,
                onClick: () => handleOpenDetailsDrawer(beneficiary, index),
                type: "image",
            },
            {
                content: <Typography className='fw-bold' message={firstname + " " + lastname} />,
                onClick: () => handleOpenDetailsDrawer(beneficiary, index),
            },
            {
                content: <Typography className='fw-bold' message={label ? label : "-"} />,
                onClick: () => handleOpenDetailsDrawer(beneficiary, index),
            },
            {
                content: <Typography className='fw-bold' message={email ? email : "-"} />,
                onClick: () => handleOpenDetailsDrawer(beneficiary, index),
                status: isEmailValid ? undefined : "error",
                toolTip: isEmailValid ? undefined : { title: t("CommonUse.invalid-email") },
            },
            {
                content: (
                    <div className='external-account-avatars' key={index}>
                        {externalAccounts.map((network) => {
                            return transactionTypesMap[network.typeSlug]?.type === "image" ? (
                                <Avatar key={network.uuid} avatar={transactionTypesMap[network.typeSlug].img} />
                            ) : (
                                <Icon
                                    variant='squared'
                                    color='lilas'
                                    name={transactionTypesMap[network.typeSlug]?.img as IconListType}
                                />
                            );
                        })}
                    </div>
                ),
                onClick: () => handleOpenDetailsDrawer(beneficiary, index),
                status: tooltipError ? "error" : undefined,
                toolTip: tooltipError,
            },
            {
                content: (
                    <ButtonSelect
                        buttonProps={{
                            icon: "ellipsis-vertical",
                            variant: "secondary",
                        }}
                        selectItems={[
                            {
                                icon: "square-check",
                                title: selectedRows.includes(index) ? t("CommonUse.remove") : t("CommonUse.select"),
                                onClick: () => handleSelectRow(index),
                            },
                            {
                                icon: "pencil-square",
                                title: t("CommonUse.see-and-edit"),
                                onClick: () => handleOpenDetailsDrawer(beneficiary, index),
                            },
                            {
                                icon: "thrash",
                                title: t("CommonUse.delete"),
                                onClick: () => handleDeleteRow(index),
                            },
                        ]}
                        dividerPositions={[]}
                        displayArrow={false}
                    />
                ),
                type: "button",
            },
        ];
    });

    return (
        <>
            <div className='add-beneficiary-container'>
                <PageHeader title={t<string>("Beneficiaries.my-beneficiaries")}>
                    <div className='page-header-items'>
                        <AdminBeneficiariesFilters query={query} setQuery={handleSetQuery} />
                        <ButtonSelect
                            buttonProps={{
                                icon: "plus-circle",
                                label: t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") }),
                                onClick: handleOpenCreateDrawer,
                            }}
                            selectItems={[
                                {
                                    icon: "plus-circle",
                                    title: t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") }),
                                    onClick: handleOpenCreateDrawer,
                                },
                                {
                                    icon: "document",
                                    title: t("EditTaskPanel.utiliser-un-modele"),
                                    onClick: handleClickInput,
                                },
                                {
                                    icon: "arrow-down-on-square",
                                    title: t("EditTaskPanel.telecharger-un-modele"),
                                    onClick: handleDownloadModel,
                                },
                            ]}
                            dividerPositions={[0]}
                        />
                    </div>
                </PageHeader>
                {!displayTable && (
                    <div
                        className='add-beneficiary-select-buttons-container'
                        onDragOver={isLoading ? undefined : handleDragOver}
                        onDrop={isLoading ? undefined : handleDrop}
                    >
                        <div className='titles'>
                            <Typography variant='h5' className='fw-bold' message={t("Beneficiaries.add-beneficiary")} />
                            <Typography
                                className='color-neutral-500'
                                message={t("Beneficiaries.save-beneficiaries-info")}
                            />
                        </div>
                        <Alert
                            message={t("Beneficiaries.info-for-julaya")}
                            subMessage={t<string>("Beneficiaries.beneficiary-model-limit")}
                        />
                        <div className='buttons-container'>
                            <div className='button' onClick={handleClickInput}>
                                <Avatar icon='document' size='xl' />
                                <Typography className='fw-bold' message={t("Tasks.already-have-model")} />
                            </div>
                            <div className='button' onClick={handleContinueWithoutFile}>
                                <Avatar icon='pencil-square' size='xl' />
                                <Typography className='fw-bold' message={t("Tasks.continue-without-file")} />
                            </div>
                        </div>
                        {errorFileData && <Alert message={t(errorFileData)} color='error' />}
                        <Alert
                            message={t("Beneficiaries.download-model")}
                            color='warning'
                            icon='question-mark-circle'
                            buttons={{ label: t("CommonUse.download"), onClick: handleDownloadModel, color: "warning" }}
                        />
                    </div>
                )}
                {displayTable && (
                    <SelectableTable
                        selectable
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        header={[
                            "",
                            t("Beneficiaries.beneficiary"),
                            t("CommonUse.description"),
                            t("CommonUse.email"),
                            t("CommonUse.payment-method"),
                            "",
                        ]}
                        rows={tableRows as SelectableTableRow[][]}
                        selectedBar={{
                            itemName: "bénéficiaires",
                            buttonActions: {
                                content: {
                                    header: {
                                        title: t<string>("CommonUse.multiple-action"),
                                        icon: "squares",
                                    },
                                    dropDownContent: {
                                        body: (
                                            <div className='selectable-bar-actions' onClick={handleBulkDelete}>
                                                <Icon name='thrash' />
                                                <Typography message={t("CommonUse.delete")} />
                                            </div>
                                        ),
                                    },
                                },
                            },
                        }}
                        empty={
                            <div className='beneficiaries-empty-table'>
                                <img
                                    src={require("src/shared/images/beneficiaries/connect-users.png")}
                                    alt='empty-table'
                                />
                                <Typography className='fw-bold' message={t("Beneficiaries.add-beneficiaries")} />
                                <Typography
                                    className='fw-bold color-neutral-500'
                                    message={t("Beneficiaries.empty-sentence")}
                                />
                                <ButtonSelect
                                    buttonProps={{
                                        icon: "plus-circle",
                                        label: t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") }),
                                        onClick: handleOpenCreateDrawer,
                                    }}
                                    selectItems={[
                                        {
                                            icon: "plus-circle",
                                            title: t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") }),
                                            onClick: handleOpenCreateDrawer,
                                        },
                                        {
                                            icon: "document",
                                            title: t("EditTaskPanel.utiliser-un-modele"),
                                            onClick: handleClickInput,
                                        },
                                        {
                                            icon: "arrow-down-on-square",
                                            title: t("EditTaskPanel.telecharger-un-modele"),
                                            onClick: handleDownloadModel,
                                        },
                                    ]}
                                    dividerPositions={[0]}
                                />
                            </div>
                        }
                    />
                )}
            </div>
            <div className='add-beneficiary-bottom-actions'>
                <Tag
                    type='ghost'
                    label={t<string>("Beneficiaries.beneficiaries-to-add", { number: beneficiaryList.length })}
                />
                <div className='buttons'>
                    <Button label={t("CommonUse.cancel")} variant='tertiary' onClick={handleSwitchToTable} />
                    <Button
                        label={t("CommonUse.continue")}
                        disabled={beneficiaryList.length === 0 || disableForm}
                        onClick={handleOpenPasswordValidationModal}
                    />
                </div>
            </div>
            <input
                ref={fileInputRef}
                onChange={onFileLoaded}
                type='file'
                disabled={isLoading}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                style={{ display: "none" }}
            />
            <CreateBeneficiaryDrawerContainer
                isOpen={openCreateDrawer}
                handleCloseDrawer={(refresh, beneficiary) => handleCloseAddDrawer(refresh, beneficiary)}
                selectedBeneficiary={null}
                isBulk
            />
            <DetailsBeneficiaryDrawerContainer
                isOpen={openDetailsDrawer}
                handleCloseDrawer={(refresh, beneficiary) => handleCloseEditDrawer(refresh, beneficiary)}
                selectedBeneficiary={selectedBeneficiary}
                isBulk
            />
            <PasswordValidationModal
                header={{
                    title: t("Beneficiaries.add-beneficiaries"),
                    icon: "user-group",
                }}
                successContent={{
                    text: t<string>("PasswordModal.validated-demand"),
                    image: successImage,
                }}
                setOpen={handleOpenPasswordValidationModal}
                error={integrateBeneficiaryError}
                asyncFromParent
                succeeded={showSuccess}
                onSuccess={handleOnSuccess}
                open={!!openPasswordValidationModal}
                handleOnSubmit={({ password, twoFactor }) => handleSendBulkBeneficiaries(password, twoFactor)}
            />
        </>
    );
};
