import { useEffect, useState } from "react";

import "./styles.scss";

export type ProgressBarProps = {
    className?: string;
};

export const ProgressBar = ({ className = "" }: ProgressBarProps) => {
    const [progress, setProgress] = useState(21);
    const [accelerate, setAccelerate] = useState(true);
    const [animationDelay, setAnimationDelay] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 99 || prevProgress < 20) {
                    setAccelerate(!(prevProgress >= 99));
                }
                return accelerate ? prevProgress + 1 : prevProgress - 1;
            });
        }, 30);

        setAnimationDelay(100 - progress);

        return () => {
            clearInterval(intervalId);
        };
    }, [progress, accelerate]);

    return (
        <div className={`linear-progress ${className}`}>
            <div
                className='bar'
                role='progressbar'
                style={{ width: "25%", animationDelay: `-${animationDelay}ms` }}
            ></div>
        </div>
    );
};
