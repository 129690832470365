import { TaskListApiReturnItem } from "src/services/tasks/types";

import { Dispatch, SetStateAction, createContext } from "react";

import { WalletTransactionsStateFilters } from "src/modules/walletsTransactions/slice";

export type DrawerDisplays = "createTask" | "topUp" | "iban" | "transfer";
export type DrawerContextExport = { walletId: string; filters: WalletTransactionsStateFilters };

export type DrawerProvidedState = {
    walletId?: string;
    duplicateTask?: TaskListApiReturnItem;
    sendStateOnRedirection?: boolean;
};

export type DrawerContextProps = {
    open?: DrawerDisplays;
    setOpen: Dispatch<SetStateAction<DrawerDisplays | undefined>>;
    providedState?: DrawerProvidedState;
    setProvidedState: Dispatch<SetStateAction<DrawerProvidedState | undefined>>;
};

export const DrawerContext = createContext({} as DrawerContextProps);
