import { RolesForm } from "../../Forms/Role/Role";
import classnames from "classnames";
import { isValidNumberForRegion } from "libphonenumber-js";
import { EditUserContext } from "src/shared/context/editUser";
import { CompanyRole, phoneCountryCodes } from "src/shared/models/UserCompany";

import { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminWalletsAsSelectOptions } from "src/modules/admin/adminWallets/selectors";
import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { handlePhoneValidation } from "src/modules/admin/userDetail/thunkActions";
import { getAuthUser } from "src/modules/auth/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { InputPhone, InputPhoneValue } from "src/shared/atoms/Inputs/Phone/Phone";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

export function AdminUsersDetailDrawerSectionCollector() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { userDetail } = useAppSelector((state) => getUserDetail(state));

    const [form, setForm] = useState<RolesForm["collectorInfo"]>(userDetail?.collectorInfo);
    const [selectedWallet, setSelectedWallet] = useState<SelectOption | undefined>();
    const [isCustomWavePhoneNumberOpen, setIsCustomWavePhoneNumberOpen] = useState(false);
    const [access, setAccess] = useState(false);
    const user = useAppSelector(getAuthUser);

    const { editingSection, setEditingSection, setPayload, handleOpenPasswordDialog } = useContext(EditUserContext);

    const walletOptions = useAppSelector((state) => getAdminWalletsAsSelectOptions(state));

    const haveCollectorInfo = !!userDetail?.collectorInfo;
    const disabled = !access && !userDetail?.collectorInfo;
    const showAlert = !access && !!userDetail?.collectorInfo;
    const isEditing = editingSection === "collector";

    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        if (user?.role === CompanyRole.ADMIN || user?.role === CompanyRole.ADMIN_ONLY) {
            setCanEdit(true);
        }
    }, [userDetail]);

    const [color, text] = useMemo(() => {
        if (!canEdit) {
            return ["color-lilas-500", t("CommonUse.non-modifiable")];
        } else if (!isEditing) {
            return ["color-lilas-900", t("CommonUse.modify")];
        } else {
            return ["color-warning", t("CommonUse.being-modified")];
        }
        // eslint-disable-next-line
    }, [canEdit, editingSection]);

    const handleToggleEditing = () => {
        if (canEdit) {
            setEditingSection(!editingSection ? "collector" : null);
            setPayload(null);
        }
    };

    const handleSwitchOpenCustomWavePhoneNumberOpen = () => {
        setIsCustomWavePhoneNumberOpen((oldValue) => {
            if (oldValue === true && form?.phone) {
                setForm({ ...form, phone: userDetail?.phone });
            }
            return !oldValue;
        });
    };

    const handleSelectWallet = (wallet: SelectOption) => {
        const newForm = { ...form, ...(wallet ? { walletId: wallet?.id } : {}) };
        setSelectedWallet(wallet);
        setPayload({ accessType: userDetail?.accessType, collectorInfo: newForm });
    };

    const handleChangePhone = (newPhone: InputPhoneValue) => {
        const newForm = { ...form, ...(selectedWallet ? { walletId: selectedWallet?.id } : {}), phone: newPhone.value };
        setForm(newForm);
        setPayload({ accessType: userDetail?.accessType, collectorInfo: newForm });
    };

    const handleSwitchAccess = () => {
        setAccess((value) => !value);
    };

    const handleDeleteCollectorSettings = () => {
        setPayload({ accessType: userDetail?.accessType });
        handleOpenPasswordDialog();
    };

    useEffect(() => {
        dispatch(
            fetchAdminWallets({
                page: -1,
                archived: false,
            })
        );
    }, [dispatch]);

    useEffect(() => {
        setAccess(!!userDetail?.collectorInfo);
    }, [userDetail]);

    useEffect(() => {
        if (
            isCustomWavePhoneNumberOpen &&
            form?.phone &&
            form?.countryCode &&
            isValidNumberForRegion(form.phone, phoneCountryCodes[form.countryCode])
        ) {
            dispatch(
                handlePhoneValidation({
                    countryCode: form?.countryCode,
                    phone: form?.phone,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, form?.phone]);

    useEffect(() => {
        if (userDetail?.collectorInfo) {
            const walletOption = walletOptions.find(
                (wallet) => wallet.id === String(userDetail?.collectorInfo?.wallet?.id)
            );
            setSelectedWallet(walletOption);
            setForm(userDetail.collectorInfo);
        } else {
            setForm({ countryCode: userDetail?.countryCode, phone: userDetail?.phone });
        }
    }, [userDetail, walletOptions]);

    return (
        <div
            className={classnames("drawer-content-section users-details-drawer-phone", {
                disabled: editingSection && !isEditing,
            })}
        >
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Users.mobile-money")} />
                <div className={classnames({ disabled: editingSection && !isEditing })} onClick={handleToggleEditing}>
                    <Typography className={`fw-bold cursor-pointer ${color}`} message={text} />
                </div>
            </div>

            {isEditing ? (
                <>
                    <div className='drawer-content-section-body'>
                        <div className='user-drawer-roles-form'>
                            <div className='collector-role-form'>
                                <div className='access-text'>
                                    <Switch isOn={access} onToggle={handleSwitchAccess} />
                                    <Typography className='fw-bold' message={t("Users.collector-access")} />
                                </div>
                                {showAlert && (
                                    <Alert
                                        message={t("Users.what-happen-delete-collector-title")}
                                        subMessage={t<string>("Users.what-happen-delete-collector-description")}
                                        icon='exclamation-triangle'
                                        color='error'
                                    />
                                )}
                                <div className='wave-text'>
                                    <Avatar icon='question-mark-circle' backgroundColor='lilas-50' />
                                    <Typography message={t("Roles.collector-access")} />
                                </div>
                                <InputPhone
                                    disabled={!isCustomWavePhoneNumberOpen || !access}
                                    label={t<string>("CommonUse.phone-number")}
                                    placeholder={t("Users.enter-phone-number")}
                                    onChange={handleChangePhone}
                                    disablePhonePrefix
                                    prefix={form?.countryCode}
                                    value={form?.phone}
                                />
                                {!haveCollectorInfo && (
                                    <>
                                        <Alert
                                            color='warning'
                                            icon='exclamation-triangle'
                                            message={t<string>("Users.choose-collector-phone-warning")}
                                        />
                                        <Switch
                                            disabled={!access}
                                            label={t<string>("Users.use-another-number")}
                                            isOn={isCustomWavePhoneNumberOpen}
                                            onToggle={handleSwitchOpenCustomWavePhoneNumberOpen}
                                        />
                                    </>
                                )}
                                <SimpleSelect
                                    disabled={!access}
                                    useRadio
                                    placeholder={t<string>("CommonUse.select-account")}
                                    label={t<string>("TopUp.mobile-money-wallet-deposit")}
                                    options={walletOptions}
                                    selected={selectedWallet}
                                    onSelectionChange={handleSelectWallet}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='drawer-content-section-footer'>
                        <div className='button-part'>
                            <Button label={t("CommonUse.cancel")} variant='tertiary' onClick={handleToggleEditing} />
                            <Button
                                disabled={disabled || !selectedWallet}
                                label={t(!haveCollectorInfo ? "Users.activate-collector" : "CommonUse.modify")}
                                onClick={showAlert ? handleDeleteCollectorSettings : handleOpenPasswordDialog}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className='drawer-content-section-body drawer-content-gap'>
                    <div className='drawer-content-phone'>
                        <Typography
                            className='fw-bold color-neutral-500 color-neutral'
                            message={t("Users.collect-status")}
                        />
                        <div>
                            <Tag
                                type='accent'
                                size='lg'
                                label={t<string>(haveCollectorInfo ? "Bills.active" : "Users.no-access")}
                                icon={haveCollectorInfo ? "check-circle" : "x-circle"}
                                color={haveCollectorInfo ? "success" : "neutral"}
                            />
                        </div>
                    </div>
                    <div className='drawer-content-phone'>
                        <Typography
                            className='fw-bold color-neutral-500 color-neutral'
                            message={t("CommonUse.phone-number")}
                        />
                        <Typography
                            className='fw-bold'
                            message={haveCollectorInfo ? userDetail?.collectorInfo?.phone : "-"}
                        />
                    </div>
                    <div className='drawer-content-phone'>
                        <Typography
                            className='fw-bold color-neutral-500 color-neutral'
                            message={t("Users.collect-account")}
                        />
                        <Typography
                            className='fw-bold'
                            message={
                                haveCollectorInfo
                                    ? `${userDetail?.collectorInfo?.wallet?.label} - ${userDetail?.collectorInfo?.wallet?.reference}`
                                    : "-"
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
