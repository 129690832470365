import { TransactionReceipt } from "src/services/transactions/types";
import { TransactionContext } from "src/shared/context/transaction";
import { CompanyRole } from "src/shared/models/UserCompany";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import {
    handleDeleteWalletTransactionReceipt,
    handleUpdateWalletTransactionReceipts,
} from "src/modules/walletsTransactions/thunkActions";
import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";
import { useAppDispatch, useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";
import {
    FileUploaderDragnDropWithFiles,
    FileUploaderFiles,
    LoadingFileUploader,
} from "src/shared/components/FileUploader/FileUploaderDragNDropWithFiles";
import { IconButton } from "src/shared/components/IconButton/IconButton";

import "./styles.scss";

type Props = {
    walletId: string;
};

export function HistoryWalletDrawerCredentials({ walletId }: Props) {
    const transaction = useContext(TransactionContext);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [loadingFile, setLoadingFile] = useState<LoadingFileUploader | null>(null);
    const [success, setSuccess] = useState<undefined | boolean>();
    const [deletingFiles, setDeletingFiles] = useState<{ [fileId: string]: boolean }>({});
    const [cancelLoading, setCancelLoading] = useState<boolean>(false);

    const authUser = useAppSelector((state) => getAuthUser(state));

    const { attachment, receipts, uuid } = transaction;

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.READ] });

    useEffect(() => {
        if (typeof success === "boolean") {
            setTimeout(() => setSuccess(undefined), 5000);
        }
    }, [success]);

    const handleDeleteFile = async (fileId: string) => {
        if (uuid) {
            setDeletingFiles((oldValue) => ({ ...oldValue, [fileId]: true }));
            await dispatch(
                handleDeleteWalletTransactionReceipt({
                    fileId,
                    fileType: "receipt",
                    transactionUuid: uuid,
                    walletId,
                })
            ).then(() => setDeletingFiles((oldValue) => ({ ...oldValue, [fileId]: false })));
        }
    };

    const onFileLoaded = async (file: File) => {
        if (uuid) {
            const newLoadingFile: LoadingFileUploader = {
                file,
                image: URL.createObjectURL(file),
                name: file.name.split("/").pop() ?? "",
                fileType: "receipt",
                loading: true,
            };
            setLoadingFile(newLoadingFile);

            const payload = {
                file,
                transactionUuid: uuid,
                walletId,
                type: "receipt",
                uploader: authUser?.firstname
                    ? `${authUser?.firstname}${authUser?.lastname ? authUser?.lastname : null}`
                    : null,
            };

            await dispatch(handleUpdateWalletTransactionReceipts(payload))
                .then((res) => {
                    const fileId = (res.payload as { dataValues: TransactionReceipt })?.dataValues?.id;
                    setLoadingFile(null);

                    setCancelLoading((lastCancelLoading) => {
                        if (lastCancelLoading && fileId) {
                            handleDeleteFile(fileId.toString());
                        } else {
                            setSuccess(true);
                        }
                        return false;
                    });

                    return res;
                })
                .catch(() => {
                    setLoadingFile({
                        ...newLoadingFile,
                        loading: false,
                        error: true,
                        message: t<string>("HistoryWalletDrawerCredentials.error-when-loading-document"),
                    });
                });
        }
    };

    const handleCancelLoading = () => {
        setCancelLoading(true);
    };

    const validReceipts = receipts.filter((receipt) => receipt && receipt.id && receipt.data && receipt.updatedAt);

    const receiptsFormatted = validReceipts.map<FileUploaderFiles>(({ id, data, updatedAt }) => ({
        id: String(id),
        fileType: "receipt",
        image: data,
        name: data?.split("/").pop() ?? "",
        message: computeTimestampToString(updatedAt, "DD/MM/YYYY"),
    }));

    return (
        <div className='drawer-content-section no-padding'>
            <Accordion noHeaderPadding title='Justificatifs' color='white'>
                {attachment ? (
                    <div className='mb-1 d-flex justify-content-between align-items-center'>
                        <Typography message={t("HistoryWalletDrawerCredentials.bill")} />
                        <div className='d-flex'>
                            <a
                                className='button-container type-primary mr-2'
                                href={attachment}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <IconButton iconProps={{ name: "eye" }} color='grey-dark' />
                            </a>
                        </div>
                    </div>
                ) : (
                    <>
                        {success && (
                            <Typography
                                className='color-success-400 pt-1'
                                message={t("HistoryWalletDrawerCredentials.document-ajoute-avec-succes")}
                            />
                        )}
                        {success === false && (
                            <Typography
                                message={t(
                                    "HistoryWalletDrawerCredentials.une-erreur-est-survenue-lors-du-chargement-de-votre-document"
                                )}
                                className='color-error-400 pt-1'
                            />
                        )}
                        <FileUploaderDragnDropWithFiles
                            files={receiptsFormatted}
                            loadingFiles={loadingFile ? [loadingFile] : undefined}
                            deleteFile={handleDeleteFile}
                            fileUploaderProps={{
                                handleOnSubmit: onFileLoaded,
                                acceptedFiles: ["*"],
                            }}
                            retryUpload={onFileLoaded}
                            cancelLoading={cancelLoading ? undefined : handleCancelLoading}
                            disabled={isUserUnauthorized}
                            deletingFiles={deletingFiles}
                        />
                    </>
                )}
            </Accordion>
        </div>
    );
}
