import { NotificationCard } from "./Card";
import { Link } from "react-router-dom";
import { NotificationDrawerContext } from "src/shared/context/notificationDrawer";

import React, { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getNotificationsState } from "src/modules/notifications/selectors";
import { archiveAllNotifications, fetchNotifications } from "src/modules/notifications/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { TabOption, Tabs } from "src/shared/atoms/Tabs/Tabs";
import { Typography } from "src/shared/atoms/Typography/Typography";

const DEFAULT_PAGE_SIZE = 20;

export enum NOTIFICATION_MODE {
    RECENT = "recent",
    ARCHIVED = "ARCHIVED",
}

type NotificationsDrawerBodyProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export const NotificationsDrawerBody = ({ isOpen, handleClose }: NotificationsDrawerBodyProps) => {
    const dispatch = useAppDispatch();
    const { selectedTab: selectedTabName, setSelectedTab } = useContext(NotificationDrawerContext);
    const { t } = useTranslation();
    const { isLoading, notifications, total, newNotificationsCount } = useAppSelector(getNotificationsState);

    const notificationTabs = useMemo<TabOption[]>(() => {
        return [
            {
                icon: "sparkles",
                label: t("CommonUse.recent-value", { value: newNotificationsCount }),
                value: NOTIFICATION_MODE.RECENT,
            },
            { icon: "archive", label: t("CommonUse.archived"), value: NOTIFICATION_MODE.ARCHIVED },
        ];
    }, [t, newNotificationsCount]);

    const [page, setPage] = useState(1);

    const selectedTab = useMemo(
        () => notificationTabs.find((item) => item.value === selectedTabName) ?? notificationTabs[0],
        [notificationTabs, selectedTabName]
    );

    const handleChangeTab = (tabOption: TabOption) => {
        setSelectedTab(tabOption.value as NOTIFICATION_MODE);
    };

    const isLast = useMemo(() => {
        if (total > 0) {
            const totalPage = Math.ceil(total / DEFAULT_PAGE_SIZE);
            return totalPage === page;
        }

        return true;
    }, [page, total]);

    const loadNotifications = (page = 1) => {
        dispatch(
            fetchNotifications({
                params: {
                    page,
                    status: selectedTab.value === NOTIFICATION_MODE.RECENT ? ["read", "unread"] : ["archived"],
                },
            })
        );
    };

    const onScroll = (event: React.UIEvent) => {
        if (isLoading) {
            return;
        }

        const element = event.target as HTMLDivElement;

        if (element.scrollHeight - element.offsetHeight - element.scrollTop < 1) {
            if (!isLast) {
                loadNotifications(page + 1);
                setPage(page + 1);
            }
        }
    };

    const archiveAll = () => {
        dispatch(archiveAllNotifications());
    };

    useEffect(() => {
        if (
            isOpen &&
            (selectedTab.value === NOTIFICATION_MODE.RECENT || selectedTab.value === NOTIFICATION_MODE.ARCHIVED)
        ) {
            setPage(1);
            loadNotifications();
        }
    }, [isOpen, selectedTab]);

    return (
        <div className='notification-drawer-body'>
            <div className='d-flex flex-column flex-grow-1 pt-3'>
                <div className='notification-buttons'>
                    <Tabs options={notificationTabs} selected={selectedTab} onChange={handleChangeTab} />
                </div>

                <div className='notification-list-container px-3'>
                    <div className='notification-list' onScroll={onScroll}>
                        {notifications.map((item) =>
                            item.link ? (
                                <Link to={item.link} onClick={handleClose}>
                                    <NotificationCard key={item.uuid} notification={item} />
                                </Link>
                            ) : (
                                <NotificationCard key={item.uuid} notification={item} />
                            )
                        )}

                        {notifications.length === 0 && !isLoading && (
                            <div className='d-flex w-100 py-4 justify-content-center align-items-center'>
                                <Typography
                                    variant='h5'
                                    className='my-0'
                                    message={t("NotificationsDrawerBody.aucune-notification")}
                                />
                            </div>
                        )}

                        {isLoading && (
                            <div className='d-flex w-100 py-3 justify-content-center align-items-center'>
                                <Spinner />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {selectedTab.value === NOTIFICATION_MODE.RECENT && (
                <div className='drawer-footer-container justify-content-end'>
                    <div className='drawer-footer-buttons'>
                        <Button
                            onClick={archiveAll}
                            icon='archive'
                            variant='tertiary'
                            label={t("NotificationsDrawerBody.archiver-toutes-les-notifications")}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
