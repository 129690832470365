import { CreateAttachmentParams, CreateTransactionParams } from "../../services/transactions/operations";
import { RechargementAmountContainer } from "./Amount/Amount";
import { BankSelectContainer } from "./BankSelect/Container";
import { DisposalInfoContainer } from "./DisposalInfo/Container";
import { DisposalProofContainer } from "./DisposalProof/Container";
import { RechargementHelperDrawerContainer } from "./HelperDrawer/Container";
import { MethodSelectContainer } from "./MethodSelect/Container";
import { RechargementSuccess } from "./Success";
import { WalletSelectContainer } from "./WalletSelect/Container";
import { useDisposalMethodList } from "./const";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { ServiceModel } from "src/shared/models/Service";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { getTransactionsState } from "src/modules/transactions/selectors";
import { makeTransaction } from "src/modules/transactions/thunkActions";
import { getBankDisposalWallet, getBankDisposalWallets } from "src/modules/wallets/selectors";
import { fetchBankDisposalWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "../../shared/atoms/Buttons/Button";
import { Alert } from "src/shared/atoms/Alert/Alert";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

import "./styles.scss";

export enum RECHARGEMENT_STEP {
    BANK_SELECT,
    WALLET_SELECT,
    AMOUNT,
    METHOD_SELECT,
    DISPOSAL_PROOF,
    DISPOSAL_INFO,
}

const bankDisposalFormInitialValues: {
    walletId: string;
    disposalMethod: string;
    service: ServiceModel | null;
    amount: number;
    comment: string;
    disposalProof: File | null;
    preferredBank: boolean;
} = {
    walletId: "",
    disposalMethod: "",
    service: null,
    amount: 0,
    comment: "",
    disposalProof: null,
    preferredBank: false,
};

const amountLimit = 2000000000;

export type BankDisposalFormType = typeof bankDisposalFormInitialValues;

type LocationState = { walletId: string; amount?: number };

export const RechargementPanel = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const locationState = useLocation().state as LocationState;
    const { t } = useTranslation();

    const [step, setStep] = useState(RECHARGEMENT_STEP.BANK_SELECT);
    const [helperDrawerOpen, setHelperDrawerOpen] = useState(false);
    const [openBankDrawer, setOpenBankDrawer] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [transactionSuccess, setTransactionSuccess] = useState(false);

    const validationSchema = Yup.object().shape({
        walletId: Yup.string().required(t("RechargementPanel.veuillez-selectionner-un-compte-a-recharger").toString()),
        disposalMethod: Yup.string().required(
            t("RechargementPanel.veuillez-selectionner-un-moyen-de-rechangement").toString()
        ),
        service: Yup.object().required(t("RechargementPanel.veuillez-selectionner-une-banque").toString()),
        amount: Yup.number().required(t("RechargementPanel.veuillez-enter-un-montant").toString()),
        comment: Yup.string(),
        preferredBank: Yup.boolean(),
        disposalProof: Yup.object(),
    });

    const form = useFormik({
        initialValues: bankDisposalFormInitialValues,
        validationSchema: validationSchema,
        onSubmit: () => {},
    });

    const methodList = useDisposalMethodList();
    const selectedMethod = useMemo(() => {
        const method = form.values.disposalMethod;
        return method ? methodList.find((methodItem) => methodItem.slug === method) : undefined;
    }, [form.values.disposalMethod, methodList]);

    const wallets = useAppSelector((state) => getBankDisposalWallets(state));
    const selectedWallet = useAppSelector((state) =>
        getBankDisposalWallet(state, { walletId: form.values?.walletId ? parseInt(form.values?.walletId) : undefined })
    );
    const tfaActive = useAppSelector((state) => getAuthUser(state)?.tfaActive);
    const { makeTransactionError } = useAppSelector((state) => getTransactionsState(state));
    const { transactionNotify } = useAppSelector((state) => getTransactionsState(state));
    const company = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state));

    const taskUuid = useMemo(() => {
        return uuidv4().toString();
    }, []);

    const amountError =
        company?.kyb !== "kyb1" && company?.kyb !== "kyb2" && form.values.amount > 10000
            ? "RechargementPanel.company-is-not-kyb-error"
            : form.values.amount > amountLimit
            ? "RechargementPanel.top-up-amount-exceed-max-limit-error"
            : null;

    const handleSwitchOpenHelperDrawer = () => {
        setHelperDrawerOpen((open) => !open);
    };

    const goToHistoryPage = () => {
        if (transactionNotify?.TaskItems[0]?.uuid) {
            navigate(`/history/wallet/${form.values.walletId}`, {
                state: { taskUuid: transactionNotify?.TaskItems[0]?.uuid },
            });
        }
    };

    const handleSwitchOpenDialog = () => {
        setOpenPassword((oldOpenDialog) => {
            return !oldOpenDialog;
        });
    };

    const onConfirm = () => {
        setOpenPassword(true);
    };

    const handleConfirm = async ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        await handleSubmit(password, twoFactor);
    };

    const taskSignUniqueId = useMemo(() => {
        return uuidv4().toString();
    }, []);

    const handleSubmit = async (password: string, twoFactor: string | null) => {
        const params: CreateTransactionParams = {
            walletId: form.values.walletId,
            typeSlug: "bank-disposal",
            way: "in",
            title: t("RechargementPanel.rechargement"),
            code: password,
            taskUuid,
            items: [
                {
                    amount: form.values.amount,
                    externalReference: " ",
                    serviceSlug: form.values.service?.slug ?? "",
                    typeSlug: "bank-disposal",
                    tags: form.values.disposalMethod,
                    comment: form.values.comment,
                },
            ],
            ...(tfaActive ? { otp: twoFactor } : {}),
        };

        let attachmentParams: CreateAttachmentParams | null = null;

        if (form.values.disposalProof) {
            attachmentParams = {
                file: form.values.disposalProof,
                walletId: form.values.walletId,
            };
        }

        const res = await dispatch(
            makeTransaction({
                params,
                attachmentParams,
                uniqueId: taskSignUniqueId,
            })
        );

        if (res.payload) {
            setTransactionSuccess(true);
        }
    };

    const handleOpenAccordion = (step: RECHARGEMENT_STEP) => () => {
        setStep(step);
    };

    const handleReset = () => {
        form.resetForm();
        setOpenPassword(false);
        setStep(RECHARGEMENT_STEP.BANK_SELECT);
        setOpenBankDrawer(true);
    };

    useEffect(() => {
        dispatch(fetchBankDisposalWalletsAuthUser());
    }, [dispatch]);

    useEffect(() => {
        if (wallets.length === 1) {
            form.setFieldValue("walletId", wallets[0].id);
        }
    }, [wallets]);

    useEffect(() => {
        if (locationState) {
            if (locationState?.walletId) {
                form.setFieldValue("walletId", locationState.walletId);
            }
            if (locationState?.amount) {
                setStep(RECHARGEMENT_STEP.METHOD_SELECT);
                form.setFieldValue("amount", locationState.amount);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationState]);

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter") {
                switch (step) {
                    case RECHARGEMENT_STEP.BANK_SELECT:
                        if (form.values.service) {
                            setStep(
                                locationState?.walletId ? RECHARGEMENT_STEP.AMOUNT : RECHARGEMENT_STEP.WALLET_SELECT
                            );
                        }
                        break;
                    case RECHARGEMENT_STEP.WALLET_SELECT:
                        if (form.values.walletId) {
                            setStep(RECHARGEMENT_STEP.AMOUNT);
                        }
                        break;
                    case RECHARGEMENT_STEP.AMOUNT:
                        if (form.values.amount || amountError) {
                            setStep(RECHARGEMENT_STEP.METHOD_SELECT);
                        }
                        break;
                    case RECHARGEMENT_STEP.DISPOSAL_PROOF:
                        if (form.values.disposalProof) {
                            setStep(RECHARGEMENT_STEP.DISPOSAL_INFO);
                        }
                        break;
                    case RECHARGEMENT_STEP.DISPOSAL_INFO:
                        if (form.values.amount && !openPassword) {
                            onConfirm();
                        }
                        break;
                    default:
                        break;
                }

                if (transactionSuccess) {
                    goToHistoryPage();
                }
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    }, [step, form, setStep]);

    return (
        <>
            <PageHeader title={t<string>("RechargementPanel.recharger-un-compte")} />
            <div className='rechargement-drawer-alert'>
                <Alert
                    color='warning'
                    message={t("RechargementPanel.rechargement-helper-drawer-button-title")}
                    icon='bulb'
                    customButton={
                        <div className='rechargement-helper-drawer-button' onClick={handleSwitchOpenHelperDrawer}>
                            <Typography message={t("RechargementPanel.learn-more-about")} />
                        </div>
                    }
                />
            </div>
            <div className='mb-3'>
                <StepAccordion
                    title={t("RechargementPanel.choose-disposal-bank")}
                    subTitle={step > RECHARGEMENT_STEP.BANK_SELECT ? form.values.service?.title : undefined}
                    step={RECHARGEMENT_STEP.BANK_SELECT}
                    currentStep={step}
                    onOpen={handleOpenAccordion(RECHARGEMENT_STEP.BANK_SELECT)}
                    footer={
                        <Button
                            label={t("CommonUse.continue")}
                            disabled={!form.values.service}
                            onClick={() =>
                                setStep(
                                    locationState?.walletId ? RECHARGEMENT_STEP.AMOUNT : RECHARGEMENT_STEP.WALLET_SELECT
                                )
                            }
                        />
                    }
                >
                    <BankSelectContainer
                        forceOpen={openBankDrawer}
                        setForceOpen={setOpenBankDrawer}
                        form={form}
                        handleSwitchOpenHelperDrawer={handleSwitchOpenHelperDrawer}
                    />
                </StepAccordion>
            </div>

            <div className='mb-3'>
                <StepAccordion
                    title={t("RechargementPanel.choisissez-le-compte-a-recharger")}
                    subTitle={step > RECHARGEMENT_STEP.WALLET_SELECT ? selectedWallet?.label ?? undefined : undefined}
                    step={RECHARGEMENT_STEP.WALLET_SELECT}
                    currentStep={step}
                    onOpen={handleOpenAccordion(RECHARGEMENT_STEP.WALLET_SELECT)}
                    footer={
                        <>
                            <Button
                                className='mr-3'
                                variant='tertiary'
                                label={t("CommonUse.go-back")}
                                onClick={() => setStep(RECHARGEMENT_STEP.BANK_SELECT)}
                            />
                            <Button
                                label={t("CommonUse.continue")}
                                disabled={!form.values.walletId}
                                onClick={() => setStep(RECHARGEMENT_STEP.AMOUNT)}
                            />
                        </>
                    }
                >
                    <WalletSelectContainer
                        form={form}
                        step={step}
                        handleSetNextStep={() => setStep(RECHARGEMENT_STEP.AMOUNT)}
                    />
                </StepAccordion>
            </div>

            <div className='mb-3'>
                <StepAccordion
                    title={t("RechargementPanel.amount-section-title")}
                    subTitle={
                        step > RECHARGEMENT_STEP.AMOUNT
                            ? formatMoneyToString({ amount: form.values.amount })
                            : undefined
                    }
                    step={RECHARGEMENT_STEP.AMOUNT}
                    currentStep={step}
                    onOpen={handleOpenAccordion(RECHARGEMENT_STEP.DISPOSAL_INFO)}
                    footer={
                        <>
                            <Button
                                className='mr-3'
                                variant='tertiary'
                                label={t("CommonUse.go-back")}
                                onClick={() => setStep(RECHARGEMENT_STEP.WALLET_SELECT)}
                            />
                            <Button
                                label={t("CommonUse.continue")}
                                disabled={!form.values.amount || !!amountError}
                                onClick={() => setStep(RECHARGEMENT_STEP.METHOD_SELECT)}
                            />
                        </>
                    }
                >
                    <RechargementAmountContainer error={amountError} form={form} />
                </StepAccordion>
            </div>

            <div className='mb-3'>
                <StepAccordion
                    title={t("RechargementPanel.choisissez-un-moyen-pour-recharger-votre-compte")}
                    subTitle={step > RECHARGEMENT_STEP.METHOD_SELECT ? selectedMethod?.title : undefined}
                    step={RECHARGEMENT_STEP.METHOD_SELECT}
                    currentStep={step}
                    onOpen={handleOpenAccordion(RECHARGEMENT_STEP.METHOD_SELECT)}
                    footer={
                        <>
                            <Button
                                className='mr-3'
                                variant='tertiary'
                                label={t("CommonUse.go-back")}
                                onClick={() => setStep(RECHARGEMENT_STEP.AMOUNT)}
                            />
                            <Button
                                label={t("CommonUse.continue")}
                                disabled={!form.values.disposalMethod}
                                onClick={() => setStep(RECHARGEMENT_STEP.DISPOSAL_PROOF)}
                            />
                        </>
                    }
                >
                    <MethodSelectContainer
                        form={form}
                        currentStep={step}
                        handleSetNextStep={() => setStep(RECHARGEMENT_STEP.DISPOSAL_PROOF)}
                    />
                </StepAccordion>
            </div>

            <div className='mb-3'>
                <StepAccordion
                    subTitle={step > RECHARGEMENT_STEP.DISPOSAL_PROOF ? form.values.disposalProof?.name : undefined}
                    title={t("RechargementPanel.disposal-proof-upload")}
                    step={RECHARGEMENT_STEP.DISPOSAL_PROOF}
                    currentStep={step}
                    onOpen={handleOpenAccordion(RECHARGEMENT_STEP.DISPOSAL_PROOF)}
                    footer={
                        <>
                            <Button
                                className='mr-3'
                                variant='tertiary'
                                label={t("CommonUse.go-back")}
                                onClick={() => setStep(RECHARGEMENT_STEP.METHOD_SELECT)}
                            />
                            <Button
                                label={t("CommonUse.continue")}
                                disabled={!form.values.disposalProof}
                                onClick={() => setStep(RECHARGEMENT_STEP.DISPOSAL_INFO)}
                            />
                        </>
                    }
                >
                    <DisposalProofContainer form={form} />
                </StepAccordion>
            </div>

            <div className='mb-3'>
                <StepAccordion
                    title={t("RechargementPanel.supply-ask-info")}
                    step={RECHARGEMENT_STEP.DISPOSAL_INFO}
                    currentStep={step}
                    onOpen={handleOpenAccordion(RECHARGEMENT_STEP.DISPOSAL_INFO)}
                    footer={
                        <>
                            <Button
                                className='mr-3'
                                variant='tertiary'
                                label={t("CommonUse.go-back")}
                                onClick={() => setStep(RECHARGEMENT_STEP.DISPOSAL_PROOF)}
                            />
                            <Button
                                label={t("CommonUse.validate")}
                                disabled={!form.values.amount}
                                onClick={onConfirm}
                            />
                        </>
                    }
                >
                    <DisposalInfoContainer form={form} />
                </StepAccordion>
            </div>
            <RechargementHelperDrawerContainer open={helperDrawerOpen} setOpen={setHelperDrawerOpen} />

            <PasswordValidationModal
                successSize='md'
                header={{
                    title: t("RechargementPanel.recharger-mon-compte"),
                    icon: "wallet",
                }}
                successComponent={<RechargementSuccess form={form} showSuccess={transactionSuccess} />}
                successCustomButton={
                    <Button
                        variant='tertiary'
                        className='w-100'
                        label={t("RechargementPanel.new-op")}
                        id='success-modal_topup_new-operation'
                        onClick={handleReset}
                    />
                }
                successButtonId='success-modal_topup_close'
                successButtonLabel='CommonUse.close'
                asyncFromParent
                error={makeTransactionError}
                succeeded={transactionSuccess}
                open={openPassword}
                setOpen={handleSwitchOpenDialog}
                handleOnSubmit={handleConfirm}
                onSuccess={goToHistoryPage}
            />
        </>
    );
};
