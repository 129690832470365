import { Icon } from "../Icons/Icon";
import { IconListType } from "../Icons/IconList";
import { Typography } from "../Typography/Typography";

import "./styles.scss";

export type TagType = "filled" | "accent" | "outline" | "ghost";
export type TagSize = "lg" | "md" | "sm";
export type TagColor = "primary" | "success" | "error" | "neutral" | "warning" | "blue" | "lilas";
export type TagPosition = "left" | "right" | "both";

export interface TagProps {
    type?: TagType;
    size?: TagSize;
    color?: TagColor;
    label?: string;
    icon?: IconListType;
    position?: TagPosition;
    disabled?: boolean;
    hoverable?: boolean;
    testId?: string;
}

export const Tag = ({
    type = "filled",
    size = "md",
    color = "primary",
    label,
    icon,
    position = "left",
    disabled = false,
    hoverable = false,
    testId,
}: TagProps) => {
    return (
        <div
            className={`tag-container ${hoverable ? "hoverable" : ""} type-${type} size-${size} ${color} ${
                disabled ? "disabled" : ""
            }`}
            data-testid={testId}
        >
            {icon && (position === "both" || position === "left") && (
                <Icon
                    name={icon}
                    className={`color-${color} ${position === "both" ? "left" : position}`}
                    data-testid={"tag-icon-left"}
                />
            )}
            {label && <Typography className={`color-black`} message={label} />}
            {icon && (position === "both" || position === "right") && (
                <Icon
                    name={icon}
                    className={`color-${color} ${position === "both" ? "right" : position}`}
                    data-testid={"tag-icon-right"}
                />
            )}
        </div>
    );
};
