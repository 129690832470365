import { logoutResetStore } from "../auth/Actions";
import { merge } from "lodash";
import { NotificationModel, NotificationSetting } from "src/shared/models/Notification";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface NotificationsState {
    isLoading: boolean;
    notifications: NotificationModel[];
    total: number;
    loadingArchiveNotification: boolean;
    loadingArchiveAll: boolean;
    newNotificationsCount: number;
    loadingNewNotifications: boolean;
    loadingReadNotifications: boolean;
    loadingSettings: boolean;
    settings?: NotificationSetting;
    loadingUpdateSettings: boolean;
    updateSettingsError?: string;
}

const initialState: NotificationsState = {
    isLoading: false,
    notifications: [],
    total: 0,
    loadingArchiveNotification: false,
    loadingArchiveAll: false,
    newNotificationsCount: 0,
    loadingNewNotifications: false,
    loadingReadNotifications: false,
    loadingSettings: false,
    settings: undefined,
    loadingUpdateSettings: false,
    updateSettingsError: undefined,
};

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestNotifications: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        failedRequestNotifications: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
        receiveNotifications: (
            state,
            { payload }: PayloadAction<{ notifications: NotificationModel[]; total: number; initLoad?: boolean }>
        ) => {
            const { notifications, total, initLoad = true } = payload;

            return {
                ...state,
                notifications: initLoad ? notifications : [...state.notifications, ...notifications],
                total,
                isLoading: false,
            };
        },
        setNotifications: (
            state,
            { payload }: PayloadAction<{ notifications: NotificationModel[]; total: number }>
        ) => {
            const { notifications, total } = payload;

            return {
                ...state,
                notifications,
                total,
            };
        },
        requestArchiveNotification: (state) => {
            return {
                ...state,
                loadingArchiveNotification: true,
            };
        },
        receiveArchiveNotification: (state, { payload }: PayloadAction<{ noteId: string }>) => {
            const { noteId } = payload;

            return {
                ...state,
                total: state.total >= 1 ? state.total - 1 : 0,
                notifications: state.notifications.filter((item) => item.uuid !== noteId),
                loadingArchiveNotification: false,
            };
        },
        failedArchiveNotification: (state) => {
            return {
                ...state,
                loadingArchiveNotification: false,
            };
        },
        requestArchiveAllNotifications: (state) => {
            return {
                ...state,
                loadingArchiveAll: true,
            };
        },
        receiveArchiveAllNotifications: (state) => {
            return {
                ...state,
                loadingArchiveAll: false,
                total: 0,
                notifications: [],
            };
        },
        failedArchiveAllNotifications: (state) => {
            return {
                ...state,
                loadingArchiveAll: false,
            };
        },
        requestNewNotifications: (state) => {
            return {
                ...state,
                loadingNewNotifications: true,
            };
        },
        failedRequestNewNotifications: (state) => {
            return {
                ...state,
                loadingNewNotifications: false,
            };
        },
        receiveNewNotifications: (state, { payload }: PayloadAction<{ count: number }>) => {
            const { count } = payload;

            return {
                ...state,
                newNotificationsCount: count,
                loadingNewNotifications: false,
            };
        },
        requestReadNotifications: (state) => {
            return {
                ...state,
                loadingReadNotifications: true,
            };
        },
        failedRequestReadNotifications: (state) => {
            return {
                ...state,
                loadingReadNotifications: false,
            };
        },
        receiveReadNotifications: (state) => {
            return {
                ...state,
                loadingReadNotifications: false,
            };
        },
        requestNotificationSettings: (state) => {
            return {
                ...state,
                loadingSettings: true,
            };
        },
        failedRequestNotificationSettings: (state) => {
            return {
                ...state,
                loadingSettings: false,
            };
        },
        receiveNotificationSettings: (state, { payload }: PayloadAction<NotificationSetting>) => {
            return {
                ...state,
                settings: payload,
                loadingSettings: false,
                updateSettingsError: undefined,
            };
        },
        requestUpdateNotificationSettings: (state) => {
            return {
                ...state,
                loadingUpdateSettings: true,
                updateSettingsError: undefined,
            };
        },
        failedRequestUpdateNotificationSettings: (state, { payload }: PayloadAction<string>) => {
            return {
                ...state,
                loadingUpdateSettings: false,
                updateSettingsError: payload,
            };
        },
        receiveUpdateNotificationSettings: (state, { payload }: PayloadAction<NotificationSetting>) => {
            return {
                ...state,
                settings: merge({}, state.settings, payload),
                loadingUpdateSettings: false,
                updateSettingsError: undefined,
            };
        },
    },
});

export const {
    requestNotifications,
    failedRequestNotifications,
    receiveNotifications,
    setNotifications,
    requestArchiveNotification,
    receiveArchiveNotification,
    failedArchiveNotification,
    requestArchiveAllNotifications,
    receiveArchiveAllNotifications,
    failedArchiveAllNotifications,
    requestNewNotifications,
    receiveNewNotifications,
    failedRequestNewNotifications,
    requestReadNotifications,
    failedRequestReadNotifications,
    receiveReadNotifications,
    requestNotificationSettings,
    failedRequestNotificationSettings,
    receiveNotificationSettings,
    requestUpdateNotificationSettings,
    failedRequestUpdateNotificationSettings,
    receiveUpdateNotificationSettings,
} = notificationsSlice.actions;

export const notificationsSliceReducer = notificationsSlice.reducer;
