import { IconButton } from "../../IconButton/IconButton";
import classNames from "classnames";

import { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from "react";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "../styles.scss";
import "./styles.scss";

type AccordionProps = {
    title: string;
    subTitle?: string;
    icon?: IconListType;
    onOpen?: (isOpened: boolean) => void;
    step?: number;
    currentStep?: number;
    buttonDisabled?: boolean;
    footer?: ReactNode;
    showSuccess?: boolean;
    showOverflow?: true;
};

export const StepAccordion = ({
    title,
    subTitle,
    icon,
    children,
    onOpen,
    step = 0,
    currentStep = 0,
    footer,
    buttonDisabled = false,
    showSuccess = true,
    showOverflow,
}: PropsWithChildren<AccordionProps>) => {
    const [showContent, setShowContent] = useState<boolean>(false);

    const disabled = useMemo(() => {
        return buttonDisabled || (step !== 0 && currentStep < step);
    }, [buttonDisabled, step, currentStep]);

    const validated = showSuccess ? currentStep > step : false;

    useEffect(() => {
        setShowContent(step === currentStep);
    }, [step, currentStep]);

    const handleShowContent = () => {
        if (!disabled) {
            setShowContent(!showContent);
            onOpen && onOpen(!showContent);
        }
    };

    return (
        <div className='step-accordion-container accordion-container'>
            <div
                className={classNames("accordion accordion-step", {
                    "overflow-initial": showOverflow && showContent,
                })}
            >
                <div
                    className={`accordion-header ${disabled ? "disabled" : ""} ${showContent ? "show" : "hide"} `}
                    data-testid='accordion-header'
                >
                    <div className='d-flex align-items-center'>
                        <div className={`step-status-icon ${validated ? "validated" : ""}`}>
                            {icon ? (
                                <Avatar icon={icon} color={"lilas-900"} size='md' />
                            ) : validated || currentStep === step ? (
                                <Avatar
                                    size='md'
                                    icon={validated ? "check-circle" : "arrow-right-circle"}
                                    color={validated ? "success-500" : "lilas-900"}
                                    backgroundColor={validated ? "success-50" : "gradiant-pink-horizontal-2"}
                                />
                            ) : (
                                <Avatar firstname={(step + 1).toString()} backgroundColor='lilas-50' />
                            )}
                        </div>
                        <div className='d-flex flex-column mx-2'>
                            <Typography message={title} />
                            {subTitle && <Typography className='subtitle' message={subTitle} />}
                        </div>
                    </div>
                    <IconButton
                        testId='step-accordion-button'
                        classname={`${currentStep === step ? "opened" : ""}`}
                        iconProps={{
                            className: showContent ? "show" : "",
                            name: "chevron-down",
                            size: "sm",
                        }}
                        color={currentStep !== step ? "grey" : "grey-dark"}
                        handleOnClick={handleShowContent}
                    />
                </div>
                {!disabled && (
                    <div
                        className={classNames("accordion-content bg-white", {
                            show: showContent,
                            hide: !showContent,
                            "overflow-initial": showOverflow && showContent,
                        })}
                    >
                        <div className='content-padding'>{children}</div>
                        {footer && <div className='footer d-flex justify-content-end'>{footer}</div>}
                    </div>
                )}
            </div>
        </div>
    );
};
