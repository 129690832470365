import { activityLogDescription } from "src/services/admin/activityLogs/const";
import { AdminActivityLog, AdminDocumentSlugs } from "src/services/admin/activityLogs/types";
import { computeSignataireLogs } from "src/services/admin/activityLogs/utils";
import { CreateTransactionParams } from "src/services/transactions/operations";
import { computeTimestampToString } from "src/shared/utils/formatDate";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { Trans, useTranslation } from "react-i18next";

import { getAdminWallets } from "src/modules/admin/adminWallets/selectors";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    item: AdminActivityLog;
};

const roles: { [role: string]: string } = {
    ADMIN: "AdminActivity.administrator",
    "ADMIN-ONLY": "Roles.admin-only",
    USER: "Roles.user",
    READ: "Roles.read",
    COLLECTOR: "Roles.collector",
};

const documentNames: { [key in AdminDocumentSlugs]: string } = {
    "address-proof": "AdminDocumentsItem.address-proof",
    "company-contract": "AdminDocumentsItem.company-contrat",
    "company-seal": "AdminDocumentsItem.company-seal",
    "constitution-notification": "AdminDocumentsItem.constitution-notification",
    bic: "AdminDocumentsItem.bic",
    cni: "AdminDocumentsItem.cni",
    dfe: "AdminDocumentsItem.dfe",
    fatca: "AdminDocumentsItem.fatca",
    rccm: "AdminDocumentsItem.rccm",
};

export function AdminActivityLogsItem({ item }: Readonly<Props>) {
    const { t } = useTranslation();
    const { wallets } = useAppSelector(getAdminWallets);

    const { actionSlug, createdAt, user, details, body } = item;

    const data = activityLogDescription[actionSlug];
    const amount = body?.items
        ? (body?.items as CreateTransactionParams["items"]).reduce((res, item) => {
              return res + item.amount;
          }, 0)
        : 0;
    const translationData = {
        userName: `${user?.firstname} ${user?.lastname}`.trim(),
        newUserName: details?.userModifiedName,
        beneficiaryName: details?.beneficiaryName ?? "",
        exportName: body?.name ?? "",
        wallet: wallets.find((wallet: any) => wallet.id === body?.walletId)?.label ?? t("Wallets.a-wallet"),
        taskName: details?.taskName ?? "",
        walletName: details?.walletName ?? "",
        documentName: details?.documentSlug ? t(documentNames[details.documentSlug]) : "",
        amount: formatMoneyToString({ amount }),
        userModifiedName: details?.userModifiedName ?? "",
        interpolation: { escapeValue: false },
        transactionReference: details?.transactionReference,
        newRole: details?.newRole ? t(roles[details.newRole]) : "",
        oldRole: details?.oldRole ? t(roles[details.oldRole]) : t("Loans.none"),
        exportType:
            body?.type === "bank_statement"
                ? t("AdminActivity.bank-statement")
                : t("AdminActivity.personnalised-export"),
    };

    const computedLogs = computeSignataireLogs(t, user, details, body);

    return (
        <div className='activity-log-item-container'>
            <Avatar firstname={user?.firstname} lastname={user?.lastname} avatar={user?.avatar} />
            <div className='item i18n' data-i18n='[html]content.body'>
                <div>
                    <Trans t={t}>
                        {computedLogs?.length
                            ? computedLogs.map((sentence) => sentence).join("<br/><br/>")
                            : t(data, translationData)}
                    </Trans>
                </div>
                <Typography
                    className='color-neutral'
                    message={computeTimestampToString(createdAt, "DD/MM/YY - HH:mm")}
                />
            </div>
        </div>
    );
}
