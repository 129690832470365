import { useNavigate } from "react-router-dom";
import { sectorOptions } from "src/shared/const/sectors";

import { useEffect, useMemo, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import {
    getBillingBillersCategoryFilter,
    getBillingBillersQueryFilter,
    getBillingBillersSectorFilter,
} from "src/modules/billing/billers/selectors";
import {
    BillersCategoryFilter,
    handleSetBillerCategoryFilter,
    resetBillingBillerSectorFilters,
    setBillingBillerSectorFilter,
} from "src/modules/billing/billers/slice";
import { fetchBillingBillers } from "src/modules/billing/billers/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Chip } from "src/shared/components/Chip/Chip";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

const categoryOptions = (t: TFunction<string>) => [
    { id: "direct-billers", label: t("Bills.category-direct-billers") },
    { id: "favorite-billers", label: t("Billing.favorite-billers") },
];

type Props = {
    showTopBillerFilter?: boolean;
};

export function BillingPayBillFilters({ showTopBillerFilter = true }: Readonly<Props>) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [closeSelect, setCloseSelect] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const sectorFilter = useAppSelector((state) => getBillingBillersSectorFilter(state));
    const categoryFilter = useAppSelector((state) => getBillingBillersCategoryFilter(state));
    const query = useAppSelector((state) => getBillingBillersQueryFilter(state));

    useEffect(() => {
        if (closeSelect) {
            if (redirect) {
                navigate("/billing/pay-bill-list");
                setRedirect(false);
            } else {
                dispatch(
                    fetchBillingBillers({
                        page: 1,
                        query,
                        sectors: sectorFilter,
                        categoryFilter: categoryFilter,
                    })
                );
            }
            setCloseSelect(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeSelect]);

    const handleSetSectorFilter = (value: SelectOption[]) => {
        dispatch(setBillingBillerSectorFilter(value));
    };

    const handleChangeBigBillerCategoryFilter = (value: SelectOption) => {
        dispatch(
            handleSetBillerCategoryFilter((value.id === categoryFilter ? null : value.id) as BillersCategoryFilter)
        );
    };

    const handleResetFilters = () => {
        dispatch(resetBillingBillerSectorFilters());
        setCloseSelect(true);
    };

    const handleValidateFilters = () => {
        setCloseSelect(true);
        if (!showTopBillerFilter) {
            setRedirect(true);
        }
    };

    const handleResetSectorFilter = () => {
        dispatch(resetBillingBillerSectorFilters());
        dispatch(
            fetchBillingBillers({
                page: 1,
                query,
                categoryFilter: showTopBillerFilter ? categoryFilter : null,
            })
        );
    };

    const renderChip = () => {
        return sectorFilter?.length && sectorFilter.length > 0 ? (
            <div className='chip-list-container'>
                <Chip
                    handleDelete={handleResetSectorFilter}
                    className='fw-lighter'
                    message={t("Bills.activity-sector")}
                />
            </div>
        ) : (
            <Typography message={t("CommonUse.filters")} size='sm' />
        );
    };

    const selectedCategory = useMemo(
        () => categoryOptions(t).find((option) => option.id === categoryFilter),
        [categoryFilter, t]
    );

    return (
        <div className='select-filter'>
            <Select
                fitContent
                forceClose={closeSelect}
                color='white'
                content={{
                    header: {
                        component: renderChip(),
                        icon: "adjustments-vertical",
                        disabled: false,
                    },
                    dropDownContent: {
                        header: {
                            title: <Typography message={t("CommonUse.filters")} />,
                            label: (
                                <Button
                                    variant='ghost'
                                    color='lilas'
                                    onClick={handleResetFilters}
                                    label={<Typography message={t("AdminWalletsFilters.reinitialiser")} size='sm' />}
                                />
                            ),
                        },
                        body: (
                            <div className='body-content'>
                                <div className='select-filter-content mb-2'>
                                    <SelectMultipleOptions
                                        scrollable
                                        showSelectedItems={false}
                                        name={`${t("Bills.filter-by-sector")} ${
                                            sectorFilter?.length ? "(" + sectorFilter?.length + ")" : ""
                                        }`}
                                        selected={sectorFilter}
                                        items={sectorOptions(t)}
                                        onSelectionChange={handleSetSectorFilter}
                                        icon='kiosk'
                                    />
                                    {showTopBillerFilter ? (
                                        <SimpleSelect
                                            useRadio
                                            headerClassname='select-simple-custom'
                                            scrollable
                                            placeholder={t<string>("Bills.filter-by-category")}
                                            selected={selectedCategory}
                                            options={categoryOptions(t)}
                                            onSelectionChange={handleChangeBigBillerCategoryFilter}
                                            icon='information-circle'
                                        />
                                    ) : null}
                                </div>
                            </div>
                        ),
                        footer: (
                            <div className='footer-content'>
                                <Button
                                    variant='tertiary'
                                    color='primary'
                                    className='flex-grow-1'
                                    onClick={handleResetFilters}
                                    label={t("CommonUse.cancel")}
                                />
                                <Button
                                    variant='primary'
                                    className='flex-grow-1'
                                    onClick={handleValidateFilters}
                                    label={t("CommonUse.validate")}
                                />
                            </div>
                        ),
                    },
                }}
            />
        </div>
    );
}
