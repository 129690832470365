import { fetchAdminExpensePolicies } from "./thunkActions";

import { useEffect } from "react";

import {
    getAdminExpensePolicies,
    getAdminExpensePoliciesPage,
    getAdminExpensePoliciesPageSize,
    getAdminExpensePoliciesQuery,
} from "./selectors";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

export const useAdminExpensePolicies = () => {
    const dispatch = useAppDispatch();

    const currentPage = useAppSelector((state) => getAdminExpensePoliciesPage(state));
    const query = useAppSelector((state) => getAdminExpensePoliciesQuery(state));
    const pageSize = useAppSelector((state) => getAdminExpensePoliciesPageSize(state));

    const debouncedQuery = useDebounce(query, 700);

    useEffect(() => {
        dispatch(fetchAdminExpensePolicies({ page: currentPage ?? 1, query: debouncedQuery, pageSize }));
    }, [dispatch, currentPage, debouncedQuery, pageSize]);

    return useAppSelector((state) => getAdminExpensePolicies(state));
};
