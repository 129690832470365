import { QuestKeys } from "src/services/admin/types";
import { DrawerContext } from "src/wrapper/context";

import { useCallback, useContext } from "react";

import { getWalletWithIban } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { exportDisplayOptions } from "src/components/Export/Templates/Panel";

export type AdminQuests = {
    [key in QuestKeys]: {
        name: string;
        link?: string;
        action?: () => void;
        state?: { display?: exportDisplayOptions["display"] };
    };
};

export const useAdminQuests = (): AdminQuests => {
    const { setOpen, setProvidedState } = useContext(DrawerContext);

    const wallet = useAppSelector((state) => getWalletWithIban(state));

    const handleOpenTopUpDrawer = useCallback(() => {
        setOpen("topUp");
        setProvidedState({ walletId: String(wallet?.id), sendStateOnRedirection: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet?.id]);

    return {
        kyb_validation: { name: "Dashboard.quest-kyb-validation", link: "/admin/documents" },
        wallets: { name: "Dashboard.quest-wallets", link: "/admin/wallets" },
        users: { name: "Dashboard.quest-users", link: "/admin/users" },
        top_up: { name: "Dashboard.quest-top-up", action: handleOpenTopUpDrawer },
        rapid_transfer: { name: "Dashboard.quest-rapid-transfer", link: "/transfert/rapid-transfert" },
        bulk_transfer: { name: "Dashboard.quest-bulk-transfer", link: "/tasks" },
        bill_payment: { name: "Dashboard.quest-bill-payment", link: "/billing/pay-bill" },
        exports: { name: "Dashboard.quest-exports", link: "/export" },
        templates: { name: "Dashboard.quest-templates", link: "/export/models" },
        programmed_exports: {
            name: "Dashboard.quest-programmed-exports",
            link: "/export/models",
            state: { display: "programmedExports" },
        },
    };
};
