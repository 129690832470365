import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    creation?: boolean;
};

export const AdminExpensePolicySuccess = ({ creation = false }: Props) => {
    const { t } = useTranslation();

    return (
        <div className='d-flex flex-column justify-content-center align-items-center text-center'>
            <img className='mx-auto mb-2' height={200} src={require("src/shared/images/user/success.png")} alt='' />
            <Typography
                className='fw-bold mb-1'
                message={t(
                    creation
                        ? "Expense-policy.create-expense-policy-success"
                        : "CommonUse.the-request-has-been-registered"
                )}
            />
        </div>
    );
};
