import { CreateTaskApiPayload } from "src/services/taskItems/operations";
import * as XLSX from "xlsx";

import { TFunction } from "i18next";

type TypeSlugsKeys =
    | "transfert-mobile-money"
    | "transfert-julaya"
    | "achat-credit-telephonique"
    | "virement-bancaire"
    | "transfert-wizall"
    | "transfert-wave"
    | "rechargement-cb";

const validKeys: TypeSlugsKeys[] = [
    "transfert-mobile-money",
    "transfert-julaya",
    "achat-credit-telephonique",
    "virement-bancaire",
    "transfert-wizall",
    "transfert-wave",
    "rechargement-cb",
];

function isTypeSlugsKeys(key: string): key is TypeSlugsKeys {
    return validKeys.includes(key as TypeSlugsKeys);
}

const typeSlugs = {
    "transfert-mobile-money": "disposal",
    "transfert-julaya": "transfer",
    "achat-credit-telephonique": "airtime",
    "virement-bancaire": "bank-transfer",
    "transfert-wizall": "wizall-transfer",
    "transfert-wave": "wave-transfer",
    "rechargement-cb": "cb-transfer",
};

const requiredHeaderFields = [
    "Référence (Numéro ou IBAN)",
    "Montant (FCFA)",
    "Nom du Destinataire (obligatoire)",
    "Commentaire (facultatif)",
    "Type d’opération (obligatoire)",
];

export function checkItemFields(item: Partial<CreateTaskApiPayload>) {
    const haveExternalFullName = item.typeSlug === "bank-transfer" ? !!item.externalFullname : true;

    return (
        item.amount &&
        parseInt(item.amount) > 0 &&
        item.externalReference &&
        item.externalReference !== "" &&
        item.typeSlug &&
        haveExternalFullName &&
        Object.values(typeSlugs).includes(item.typeSlug)
    );
}

function computeMissingData(item: Partial<CreateTaskApiPayload>, index: number) {
    const missingData: { error: string; line: number } = { line: index + 2, error: "" };

    if (!item.amount || parseInt(item.amount) === 0) {
        missingData.error = "CommonUse.amount";
    }
    if (!item.externalReference || item.externalReference === "") {
        missingData.error = "CommonUse.reference";
    }
    if (!item.typeSlug || !Object.values(typeSlugs).includes(item.typeSlug)) {
        missingData.error = "Transactions.transaction-type";
    }
    if (item.typeSlug === "bank-transfer" && (!item.externalFullname || item.externalFullname === "")) {
        missingData.error = "Tasks.excel-rows-wrong-data-beneficiary-name";
    }
    return missingData;
}

type Header = {
    externalReference?: number;
    amount?: number;
    externalFullname?: number;
    comment?: number;
    typeSlug?: number;
};

function parseHeaderColumnIndexes(dataParse: string[][]) {
    const header = dataParse?.splice(0, 1)?.pop();

    if (
        !header ||
        header.length !== requiredHeaderFields.length ||
        !requiredHeaderFields.every((field, index) => field === header[index])
    ) {
        return null;
    }

    return header.reduce<Header>((acc, item, idx) => {
        if (item.includes("Référence (Numéro ou IBAN)")) acc.externalReference = idx;
        if (item.includes("Montant (FCFA)")) acc.amount = idx;
        if (item.includes("Nom du Destinataire (obligatoire)")) acc.externalFullname = idx;
        if (item.includes("Commentaire (facultatif)")) acc.comment = idx;
        if (item.includes("Type d’opération (obligatoire)")) acc.typeSlug = idx;
        return acc;
    }, {});
}

function parseRows(dataParse: string[][], headerColumnIndexes: Header) {
    return dataParse.map<Partial<CreateTaskApiPayload>>((row) => {
        const obj: Partial<CreateTaskApiPayload> = {};

        Object.entries(headerColumnIndexes || {}).forEach(([key, columnIndex]) => {
            switch (key) {
                case "externalReference": {
                    obj[key] = row[columnIndex] !== undefined ? row[columnIndex].toString().replace(/\s/g, "") : "";
                    break;
                }
                case "amount": {
                    obj[key] =
                        row[columnIndex] !== undefined ? parseInt(row[columnIndex]).toString().replace(/\D/g, "") : "";
                    break;
                }
                case "typeSlug": {
                    const typeSlug = row[columnIndex];
                    if (isTypeSlugsKeys(typeSlug) && typeSlugs[typeSlug]) {
                        obj[key] = typeSlugs[typeSlug];
                    }
                    break;
                }
                default: {
                    obj[key as keyof CreateTaskApiPayload] =
                        row[columnIndex] !== undefined ? row[columnIndex].toString() : "";
                }
            }
        });

        return obj;
    });
}

export const parseCreateTaskFile = (t: TFunction<"translation", string, "translation">, data: string | ArrayBuffer) => {
    const readedData = XLSX.read(data, { type: "binary" });

    const sheetNames = readedData.SheetNames;
    if (sheetNames.length !== 2) {
        return t("Tasks.dont-add-sheets");
    }

    const firstSheet = readedData.Sheets[sheetNames[0]];
    const secondSheet = readedData.Sheets[sheetNames[1]];

    if (firstSheet && secondSheet) {
        const dataParseFirstSheet = XLSX.utils.sheet_to_json<string[]>(firstSheet, { header: 1 });
        const dataParseSecondSheet = XLSX.utils.sheet_to_json<string[]>(secondSheet, { header: 1 });

        const headerColumnIndexes = parseHeaderColumnIndexes(dataParseFirstSheet);

        if (!headerColumnIndexes) {
            return t("Tasks.excel-file-wrong-header");
        }

        const rowsFormatted = parseRows(dataParseFirstSheet, headerColumnIndexes);
        let missingData: { line: number; error: string } = { line: 1, error: "" };

        const checkRowContainsRequiredData = rowsFormatted
            .filter((item) => {
                const nonEmptyValues = Object.values(item).filter((value) => value !== "");

                return nonEmptyValues.length > 1;
            })
            .every((item, index) => {
                const check = checkItemFields(item);
                if (!check) {
                    missingData = computeMissingData(item, index);
                }
                return check;
            });

        if (!checkRowContainsRequiredData) {
            if (missingData.error === "Tasks.excel-rows-wrong-data-beneficiary-name") {
                return t(missingData.error);
            }
            return t("Tasks.excel-rows-wrong-data", {
                line: missingData.line,
                error: t(missingData.error),
            });
        }

        const typeSlugColumnIndex = 3;
        const validTypeSlugs = [
            "transfert-mobile-money",
            "transfert-julaya",
            "achat-credit-telephonique",
            "virement-bancaire",
            "transfert-wizall",
            "rechargement-cb",
            "transfert-wave",
        ];

        const isValidTypeSlugColumn = dataParseSecondSheet
            .slice(1)
            .filter((row) => row[typeSlugColumnIndex] !== undefined)
            .every((row) => {
                const typeSlugValue = row[typeSlugColumnIndex];
                console.log(typeSlugValue);
                console.log(typeSlugValue, validTypeSlugs, validTypeSlugs.includes(typeSlugValue));
                return validTypeSlugs.includes(typeSlugValue);
            });

        if (!isValidTypeSlugColumn) {
            return t("Tasks.dont-edit-data-sheet");
        }

        const cleanInputData = (rowsFormatted as CreateTaskApiPayload[]).filter((row) => {
            return Object.keys(headerColumnIndexes || {}).reduce((acc, key) => {
                if (["externalReference", "amount"].includes(key) && row[key as keyof CreateTaskApiPayload] === "") {
                    return false;
                }
                return acc;
            }, true);
        });

        return cleanInputData.length === 0 ? null : cleanInputData;
    } else {
        return null;
    }
};
