import { failedRequestDashboardInfo, receiveDashboardInfo, requestDashboardInfo } from "./slice";
import { requestGetAdminDashboardInfo } from "src/services/admin/operations";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAdminDashboardInfoData = createAsyncThunk(
    "fetchAdminDashboardInfoData",
    async (_props: undefined, { dispatch }) => {
        dispatch(requestDashboardInfo());
        try {
            const infoData = await requestGetAdminDashboardInfo();
            dispatch(receiveDashboardInfo(infoData));
        } catch (error) {
            dispatch(failedRequestDashboardInfo());
            return error;
        }
    }
);
