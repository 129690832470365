import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminAccountActivityPanel } from "src/components/Admin/ActivityLogs/Panel";

const AdminAccountActivityPageWithoutAuthorizations = () => {
    return <AdminAccountActivityPanel />;
};

export const AdminAccountActivityPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
})(AdminAccountActivityPageWithoutAuthorizations);
