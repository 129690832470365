import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export const AdminWalletCreationSignaturePlaceholder = () => {
    const { t } = useTranslation();

    return (
        <div className='signature-placeholder'>
            <div className='signature-placeholder-content'>
                <Icon name='user-x' />
                <Typography message={t("Wallets.no-user-assigned")} className='fw-bold' />
            </div>
        </div>
    );
};
