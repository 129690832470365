import { HistoryWalletDrawerBodyProps } from "../Body";
import { bankDisposalCancelReasonsOptions, moneyOutCancelReasonsOptions } from "src/shared/const/transactions";
import { TransactionContext } from "src/shared/context/transaction";
import { CompanyRole } from "src/shared/models/UserCompany";
import { formatMoney } from "src/shared/utils/formatMoney";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getWallet } from "src/modules/wallets/selectors";
import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

export function HistoryWalletDrawerCancel({
    walletId,
    handleOpenPasswordDialog,
}: Pick<HistoryWalletDrawerBodyProps, "handleOpenPasswordDialog" | "walletId">) {
    const transaction = useContext(TransactionContext);
    const { t } = useTranslation();

    const [reason, setReason] = useState<SelectOption>();
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.READ] });
    const [isButtonDisabled, setIsButtonDisabled] = useState(isUserUnauthorized);

    const wallet = useAppSelector((state) => getWallet(state, { walletId: parseInt(walletId) }));

    const { amount, canCancel, isCancelled, createdAt } = transaction;

    const isPendingTopUp = transaction.status === "waiting" && transaction.TransactionType.slug === "bank-disposal";
    const totalFees =
        isPendingTopUp || !transaction.refundServiceFees?.fees
            ? 0
            : Math.ceil((amount ?? 0) * (transaction.refundServiceFees.fees / 100));
    const reasons = isPendingTopUp ? bankDisposalCancelReasonsOptions : moneyOutCancelReasonsOptions;

    useEffect(() => {
        const createdAtDate = new Date(createdAt);
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

        const isOlderThanThreeDays = createdAtDate < threeDaysAgo;
        setIsButtonDisabled(isUserUnauthorized || isOlderThanThreeDays);
    }, [createdAt, isUserUnauthorized]);

    const handleSwitchOpen = () => {
        setOpen((open) => !open);
    };

    const handleChangeReason = (option: SelectOption) => {
        if (error) {
            setError(false);
        }
        setReason(option);
    };

    const handleCheckErrors = () => {
        if (!reason) {
            setError(true);
            return;
        }
        handleOpenPasswordDialog({ action: "cancel", data: { reason } });
    };

    return wallet && canCancel && !isCancelled ? (
        <div className='drawer-content-section history-wallet-drawer-cancel'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Transactions.refund-transaction")} />
            </div>
            <div className='drawer-content-section-body'>
                {open ? (
                    <div className='cancel-drawer-open'>
                        <Alert
                            color='warning'
                            message={t("Transactions.refund-title")}
                            subMessage={t<string>("Transactions.refund-description")}
                        />
                        <div className='drawer-cancel-list'>
                            <div className='drawer-cancel-item'>
                                <Typography message={t("Tasks.total-amount")} />
                                <Typography message={amount ? `${formatMoney(amount)}` : ""} />
                            </div>
                            <div className='drawer-cancel-item'>
                                <Typography message={t("Transactions.refund-fees")} />
                                <Typography
                                    className={isPendingTopUp ? "color-black" : "color-error"}
                                    message={
                                        isPendingTopUp || !transaction.refundServiceFees?.fees
                                            ? t("CommonUse.free")
                                            : t("Transactions.fees-applied", {
                                                  fees: transaction.refundServiceFees.fees,
                                                  totalFees: formatMoney(totalFees.toString().replace(/\D+/g, "")),
                                              })
                                    }
                                />
                            </div>
                            <div className='drawer-cancel-item'>
                                <Typography message={t("Transactions.refund-estimation")} />
                                <Typography
                                    message={
                                        amount
                                            ? `${formatMoney((amount - totalFees).toString().replace(/\D+/g, ""))}`
                                            : ""
                                    }
                                />
                            </div>
                            <div className='drawer-cancel-motive'>
                                <Typography message={t("Transactions.refund-motif")} />
                                <div className='d-flex flex-column'>
                                    <SimpleSelect
                                        options={reasons}
                                        selected={reason}
                                        placeholder={t<string>("Transactions.select-refund-motif")}
                                        onSelectionChange={handleChangeReason}
                                        variant={error ? "error" : "primary"}
                                        underMessage={t<string>("Transactions.select-refund-motif")}
                                    />
                                </div>
                            </div>
                            <Button label={t("CommonUse.validate")} onClick={handleCheckErrors} />
                        </div>
                    </div>
                ) : (
                    <div className='drawer-cancel-button-section'>
                        <Typography className='fw-bold' message={t("Transactions.refund-deadline")} />
                        <Button
                            className='d-flex justify-content-center w-100'
                            color='primary'
                            disabled={isButtonDisabled}
                            icon='retry-filled'
                            onClick={handleSwitchOpen}
                            label={t("Transactions.refund-transaction")}
                        />
                    </div>
                )}
            </div>
        </div>
    ) : null;
}
