import { HistoryWalletDrawerBodyProps } from "../Body";
import { useNavigate } from "react-router-dom";
import { transactionsErrorReasons } from "src/shared/const/transactions";
import { TransactionContext } from "src/shared/context/transaction";
import { CompanyRole } from "src/shared/models/UserCompany";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";

import { Alert } from "src/shared/atoms/Alert/Alert";

export const HistoryWalletDrawerRetry = ({
    handleOpenPasswordDialog,
}: Pick<HistoryWalletDrawerBodyProps, "handleOpenPasswordDialog">) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const transaction = useContext(TransactionContext);

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.READ] });

    const { canRetry, partnerComment, TransactionType, externalReference } = transaction;

    const handleSetAsArchive = () => {
        handleOpenPasswordDialog({ action: "archive", data: {} });
    };

    const handleRetryTransaction = () => {
        if (TransactionType.slug === "bill-payment") {
            navigate(`/billing/pay-bill/${externalReference}`);
        } else {
            handleOpenPasswordDialog({ action: "retry", data: {} });
        }
    };

    return canRetry && transaction.TransactionType.way === "out" ? (
        <Alert
            topBottom
            icon='x-circle'
            color='error'
            message={t<string>("Transactions.retry-section")}
            subMessage={partnerComment ? transactionsErrorReasons[partnerComment] : undefined}
            buttons={[
                {
                    disabled: isUserUnauthorized,
                    label: t("Transactions.retry"),
                    onClick: handleRetryTransaction,
                    color: "error",
                    className: "flex-grow-1",
                },
                {
                    disabled: isUserUnauthorized,
                    label: t("Transactions.Archive"),
                    variant: "tertiary",
                    className: "flex-grow-1",
                    onClick: handleSetAsArchive,
                },
            ]}
        />
    ) : null;
};
