import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";

export const useDisposalMethodList = () => {
    const { t } = useTranslation();

    return [
        {
            slug: "cash",
            icon: "bills",
            title: t("BankDisposal.cash"),
            description: t("RechargementPanel.top-up-method-selection-description"),
            details: (
                <>
                    <Typography
                        message={t(
                            "DisposalMethodList.un-justificatif-sera-necessaire-pour-valider-votre-rechargement"
                        )}
                    />
                    <Typography
                        variant='p'
                        className='mt-0 mb-1 fw-bold'
                        message={t("DisposalMethodList.le-bordereau-de-versement-d-especes-decharge-par-la-banque")}
                    />
                </>
            ),
            isFaster: true,
        },
        {
            slug: "bank",
            icon: "bank",
            title: t("BankDisposal.bank-transfer"),
            description: t("RechargementPanel.top-up-method-selection-description"),
            details: (
                <>
                    <Typography
                        message={t(
                            "DisposalMethodList.un-justificatif-sera-necessaire-pour-valider-votre-rechargement"
                        )}
                    />
                    <ul className='fw-bold mt-0 mb-1'>
                        <li>
                            <Typography
                                message={t(
                                    "DisposalMethodList.virement-physique-ordre-de-virement-signe-et-decharge-par-la-banque"
                                )}
                            />
                        </li>
                        <li>
                            <Typography
                                message={t("DisposalMethodList.virement-electronique-ordre-de-virement-signe-transmis")}
                            />
                        </li>
                    </ul>
                </>
            ),
            alert: t(
                "DisposalMethodList.le-virement-interbancaire-vous-permet-de-gagner-du-temps-sur-le-traitement-de-votre-demande-d-approvisionnement"
            ),
            isFaster: false,
        },
        {
            slug: "check",
            icon: "pencil-square",
            title: t("BankDisposal.check"),
            description: t("RechargementPanel.top-up-method-selection-description"),
            details: (
                <>
                    <Typography
                        message={t(
                            "DisposalMethodList.un-justificatif-sera-necessaire-pour-valider-votre-rechargement"
                        )}
                    />
                    <Typography
                        className='mb-3 mt-0 fw-bold'
                        variant='p'
                        message={t("DisposalMethodList.une-photo-de-la-decharge-du-cheque-par-julaya")}
                    />
                    <Typography
                        message={t(
                            "DisposalMethodList.attention-pour-que-votre-banque-accepte-votre-cheque-inscrivez-l-ordre-comme-suit-Julaya-Cote-d-Ivoire-ou-Julaya-Senegal"
                        )}
                    />
                </>
            ),
            alert: t(
                "DisposalMethodList.le-depot-cheque-interbancaire-vous-permet-de-gagner-du-temps-sur-le-traitement-de-votre-demande-d-approvisionnement"
            ),
            isFaster: false,
        },
    ];
};
