import { CompanyRole, CountriesTag } from "../models/UserCompany";
import { Navigate, RouteProps } from "react-router-dom";

import { ComponentType, FC, useMemo } from "react";

import { getAuthUserRole, getUserCountryTag } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

type Props = {
    allowedRoles: CompanyRole[];
    disallowedCountries?: CountriesTag[];
    redirectUrl?: { [key in CompanyRole]: string };
    noRedirection?: boolean;
};

const defaultRedirection = {
    [CompanyRole.ADMIN]: "/dashboard",
    [CompanyRole.ADMIN_ONLY]: "/admin",
    [CompanyRole.COLLECTOR]: "/dashboard",
    [CompanyRole.READ]: "/dashboard",
    [CompanyRole.USER]: "/dashboard",
};

export const withAuthorization = ({
    allowedRoles,
    disallowedCountries,
    redirectUrl = defaultRedirection,
    noRedirection = false,
}: Props) =>
    function <T>(WrappedComponent: ComponentType<T>) {
        const WithAuthorizationWrapper: FC<RouteProps & T> = (props) => {
            const userRole = useAppSelector((state) => getAuthUserRole(state));
            const userCountryCode = useAppSelector((state) => getUserCountryTag(state));

            const isAuthorized = useMemo(
                () =>
                    allowedRoles.some((allowedRole) => userRole === allowedRole) &&
                    userCountryCode &&
                    (!disallowedCountries || !disallowedCountries.includes(userCountryCode)),
                [userRole, userCountryCode]
            );

            return !isAuthorized && userRole ? (
                noRedirection ? (
                    <></>
                ) : (
                    <Navigate to={redirectUrl[userRole]} />
                )
            ) : (
                <WrappedComponent {...props} />
            );
        };
        return WithAuthorizationWrapper;
    };
