import { useNavigate } from "react-router-dom";
import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { useEffect } from "react";

import { getSendWalletLoading, getSendWallets } from "src/modules/wallets/selectors";
import { fetchSendWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { BillingTopBillersPanel } from "src/components/Billing/PayBill/TopBillers/Panel";

export const BillingPayBillPageUnauthorized = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const wallets = useAppSelector((state) => getSendWallets(state));
    const isLoading = useAppSelector((state) => getSendWalletLoading(state));

    useEffect(() => {
        dispatch(fetchSendWalletsAuthUser());
    }, []);

    useEffect(() => {
        if (wallets.length === 0 && !isLoading) {
            navigate("/dashboard");
        }
    }, [isLoading, wallets]);

    return <BillingTopBillersPanel />;
};

export const BillingPayBillPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER],
})(BillingPayBillPageUnauthorized);
