import { BankDisposalFormType } from "../Panel";
import { BankInfo } from "./BankInfo";
import { BankSelectDrawerContainer } from "./Drawer/Container";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import {
    getInStorageUserPreferences,
    removeInStorageUserPreferences,
    setInStorageUserPreferences,
} from "src/shared/utils/storage";

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const bankDepositImage = require("src/shared/images/transfer-bank-disposal-illutstraions/bank_deposit.png");

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
    handleSwitchOpenHelperDrawer: () => void;
    forceOpen: boolean;
    setForceOpen: Dispatch<SetStateAction<boolean>>;
};

export const BankSelectContainer = ({ form, handleSwitchOpenHelperDrawer, forceOpen, setForceOpen }: Props) => {
    const { t } = useTranslation();

    const [preferredBankChecked, setPreferredBankChecked] = useState(false);
    const isPreferedBankChecked = getInStorageUserPreferences("bankDisposalPreferredBank");

    useEffect(() => {
        if (isPreferedBankChecked) {
            setPreferredBankChecked(true);
        }
    }, [isPreferedBankChecked]);

    const setPreferedBank = () => {
        if (!preferredBankChecked) {
            if (form.values.service) {
                setInStorageUserPreferences("bankDisposalPreferredBank", form.values.service.slug);
            }
        } else {
            removeInStorageUserPreferences("bankDisposalPreferredBank");
        }
        setPreferredBankChecked(!preferredBankChecked);
    };

    return (
        <>
            <BankSelectDrawerContainer form={form} forceOpen={forceOpen} setForceOpen={setForceOpen} />
            <div className='divider my-3' />
            {form.values.service ? (
                <>
                    <div className='mb-3'>
                        <BankInfo service={form.values.service} />
                    </div>

                    <div className='rechargement-drawer-alert'>
                        <Alert
                            color='lilas'
                            message={t("RechargementPanel.bank-select-alert-title")}
                            icon='information-circle'
                            customButton={
                                <div
                                    className='rechargement-helper-drawer-button'
                                    onClick={handleSwitchOpenHelperDrawer}
                                >
                                    <Typography message={t("RechargementPanel.learn-more-about")} />
                                </div>
                            }
                        />
                    </div>

                    <Checkbox
                        checked={preferredBankChecked}
                        label={t("BankDisposal.define-as-preferred-bank")}
                        onChange={setPreferedBank}
                    />
                </>
            ) : (
                <div className='bank-select-content'>
                    <img src={bankDepositImage} alt='bank deposit illustration' className='image-container' />
                    <Typography className='fw-bold' size='lg' message={t("BankDisposal.choose-a-bank")} />
                    <Typography message={t("BankDisposal.choose-bank-description")} />
                </div>
            )}
        </>
    );
};
