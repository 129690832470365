import { ReceiptsModal } from "../../ReceiptsModal";
import { HistoryWalletDrawerBody } from "./Body";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { NotificationDrawerContext } from "src/shared/context/notificationDrawer";
import { TransactionContext } from "src/shared/context/transaction";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useContext, useEffect, useState } from "react";

import { getBillingTransaction } from "src/modules/billing/billingTransactions/selectors";
import { useTransactionStatusChange } from "src/modules/walletsTransactions/hooks";
import { getWalletTransaction } from "src/modules/walletsTransactions/selectors";
import { useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

type Props = {
    walletId?: string;
    selectedTransactionUuid: string | null;
    handleCloseDrawer: () => void;
};

export function HistoryWalletDrawerContainer({ walletId = "", selectedTransactionUuid, handleCloseDrawer }: Props) {
    const { open } = useContext(NotificationDrawerContext);
    const [hidden, setHidden] = useState(false);

    const { actionDialog, setActionDialog, passwordModalError, handleConfirm } = useTransactionStatusChange({
        walletId,
        selectedTransactionUuid,
        onSuccess: handleCloseDrawer,
    });

    const transaction = useAppSelector((state) =>
        walletId
            ? getWalletTransaction(state, { walletId, uuid: selectedTransactionUuid })
            : getBillingTransaction(state, { uuid: selectedTransactionUuid })
    );

    const handleCloseDialog = () => {
        setActionDialog(undefined);
    };

    useEffect(() => {
        if (!open) {
            setHidden(false);
        }
    }, [open]);

    const handleDownloadElem = () => {
        setActionDialog({ action: "receipts", data: {} });
    };

    const isOpenDrawer = Boolean(selectedTransactionUuid);

    return transaction ? (
        <TransactionContext.Provider value={transaction}>
            <Drawer
                isHidden={hidden}
                isOpen={isOpenDrawer}
                onClose={handleCloseDrawer}
                header={{
                    title: transaction.TransactionType.title,
                    subtitle: `${transaction.TransactionType.way === "out" ? "-" : "+"} ${
                        transaction.amount ? formatMoneyToString({ amount: transaction.amount }) : null
                    }`,
                    image: { status: transaction?.isArchived ? "archived" : transaction.status },
                    optionalButtons: [{ icon: "document", onClick: handleDownloadElem }],
                }}
                body={<HistoryWalletDrawerBody handleOpenPasswordDialog={setActionDialog} walletId={walletId} />}
            />
            <PasswordValidationModal
                open={!!actionDialog && actionDialog.action !== "receipts"}
                setOpen={handleCloseDialog}
                handleOnSubmit={handleConfirm}
                error={passwordModalError}
            />
            <ReceiptsModal
                onCloseDialog={handleCloseDialog}
                transactionUuids={[String(selectedTransactionUuid)]}
                open={Boolean(actionDialog && actionDialog.action === "receipts")}
            />
        </TransactionContext.Provider>
    ) : null;
}
