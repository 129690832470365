import { useNavigate } from "react-router-dom";
import { requestDeleteAdminWallet } from "src/services/admin/operations";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { AdminWalletModel } from "src/shared/models/Wallet";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminWalletsAsSelectOptions } from "src/modules/admin/adminWallets/selectors";
import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

type ArchiveCardProps = {
    adminWallet: AdminWalletModel;
};

export const ArchiveCard = ({ adminWallet }: ArchiveCardProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const allWalletOptions = useAppSelector(getAdminWalletsAsSelectOptions);
    const walletOptions = allWalletOptions.filter((option) => option.id.toString() !== adminWallet.id.toString());

    const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [passwordValidationError, setPasswordValidationError] = useState(null);
    const [selectedDestinationWallet, setSelectedDestinationWallet] = useState<SelectOption | null>(null);
    const [verificationWord, setVerificationWord] = useState("");

    const [errorWalletMessage, setErrorWalletMessage] = useState<string | undefined>(undefined);
    const [errorVerificationMessage, setErrorVerificationMessage] = useState<string | undefined>(undefined);
    const [walletTouched, setWalletTouched] = useState(false);
    const [verificationTouched, setVerificationTouched] = useState(false);

    const debouncedVerificationWord = useDebounce(verificationWord, 700);

    useEffect(() => {
        dispatch(
            fetchAdminWallets({
                page: -1,
                archived: false,
            })
        );
    }, [dispatch]);

    const handleArchiveWallet = async ({ password, twoFactor }: { password: string; twoFactor: string | null }) => {
        setPasswordValidationError(null);
        if (selectedDestinationWallet && verificationWord === "SUPPRIMER") {
            try {
                await requestDeleteAdminWallet(adminWallet.id, {
                    destinationWalletId: selectedDestinationWallet.id,
                    otp: twoFactor,
                    password,
                });
                navigate("/admin/wallets");
                return Promise.resolve();
            } catch (error: any) {
                setPasswordValidationError(error.message);
                return Promise.reject(error);
            }
        }
    };

    const handleToggleEdit = () => {
        setIsEditing(!isEditing);
    };

    const handleTogglePasswordModal = () => {
        setIsOpenPasswordModal(!isOpenPasswordModal);
    };

    const handleChangeWallet = (option: SelectOption) => {
        setSelectedDestinationWallet(option);
        setWalletTouched(true);
    };

    useEffect(() => {
        if (selectedDestinationWallet === null) {
            setErrorWalletMessage(t<string>("Wallets.pick-destination-wallet"));
        } else {
            setErrorWalletMessage(undefined);
        }
    }, [selectedDestinationWallet, t]);

    useEffect(() => {
        setErrorVerificationMessage(undefined);
        if (debouncedVerificationWord !== "SUPPRIMER" && verificationTouched) {
            setErrorVerificationMessage(t<string>("CommonUse.wrong-verification-words"));
        }
    }, [debouncedVerificationWord, t, verificationTouched]);

    const handleVerificationInput = (value: string) => {
        setVerificationWord(value);
        if (!verificationTouched) setVerificationTouched(true);
    };

    return (
        <>
            <div className='admin-wallet-detail-section archive-section'>
                <div className='section-header'>
                    <div className='header-title'>
                        <Typography className='fw-bold' message={t("Wallets.permanently-archive")} />
                    </div>
                    {isEditing ? (
                        <Typography className='fw-bold color-warning' message={t("CommonUse.being-modified")} />
                    ) : (
                        <div onClick={handleToggleEdit}>
                            <Typography
                                className='fw-bold color-error cursor-pointer'
                                message={t("CommonUse.archive")}
                            />
                        </div>
                    )}
                </div>
                <div className='section-horizontal' />
                <div className='archive-message'>
                    <Alert
                        icon='exclamation-triangle'
                        color='error'
                        message={t("Wallets.if-archived")}
                        subMessage={t<string>("Wallets.archived-description")}
                    />
                    {isEditing && (
                        <Alert color='warning' icon='exclamation-circle' message={t("Wallets.if-archived-warning")} />
                    )}
                </div>
                {isEditing && (
                    <>
                        <div className='edit-section'>
                            <div className='form-section'>
                                <SimpleSelect
                                    useRadio
                                    selected={selectedDestinationWallet}
                                    label={t<string>("CommonUse.select-destination-account")}
                                    placeholder={t<string>("CommonUse.select-destination-account")}
                                    options={walletOptions}
                                    onSelectionChange={handleChangeWallet}
                                    variant={walletTouched && errorWalletMessage ? "error" : "primary"}
                                    underMessage={walletTouched && errorWalletMessage ? errorWalletMessage : ""}
                                />

                                <Input
                                    label={t<string>("CommonUse.checking")}
                                    placeholder={t("CommonUse.verification-words")}
                                    value={verificationWord}
                                    onChange={handleVerificationInput}
                                    underMessage={
                                        verificationTouched && errorVerificationMessage ? errorVerificationMessage : ""
                                    }
                                    variant={verificationTouched && errorVerificationMessage ? "error" : "primary"}
                                />
                                <Typography
                                    className='archive-words'
                                    message={t("CommonUse.archive-words", { entity: t("Wallets.a-wallet") })}
                                />
                            </div>
                        </div>
                        <div className='button-section'>
                            <Button
                                variant='tertiary'
                                className='flex-grow-1'
                                label={t("CommonUse.cancel")}
                                onClick={handleToggleEdit}
                            />
                            <Button
                                label={t("CommonUse.archive")}
                                className='flex-grow-1'
                                disabled={selectedDestinationWallet === null || verificationWord !== "SUPPRIMER"}
                                onClick={handleTogglePasswordModal}
                            />
                        </div>
                    </>
                )}
            </div>
            <PasswordValidationModal
                header={{
                    title: t("CommonUse.archive").toString(),
                    icon: "archive",
                }}
                successContent={{
                    text: t("CommonUse.request-taken-into-consideration").toString(),
                }}
                open={isOpenPasswordModal}
                setOpen={handleTogglePasswordModal}
                handleOnSubmit={handleArchiveWallet}
                error={passwordValidationError}
            />
        </>
    );
};
