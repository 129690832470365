import { BankDisposalFormType } from "../Panel";
import { RechargementDisposalProofHelperPanel } from "./Helper/Panel";
import { DisposalProofInfo } from "./Info";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";

import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { FileUploaderDragnDrop } from "src/shared/components/FileUploader/FileUploaderDragNDrop";

import "./styles.scss";

const subMessageDisposalProof: { [disposalMethod: string]: string } = {
    cash: "RechargementPanel.alert-disposal-proof-cash-description",
    bank: "RechargementPanel.alert-disposal-proof-bank-description",
    check: "RechargementPanel.alert-disposal-proof-check-description",
};

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
};

export const DisposalProofContainer = ({ form }: Props) => {
    const { t } = useTranslation();

    const handleRetrieveFile = () => (file: File) => {
        form.setFieldValue("disposalProof", file);
    };

    const handleDeleteFile = () => () => {
        form.setFieldValue("disposalProof", null);
    };

    return (
        <div className='disposal-proof-container'>
            {!form.values.disposalProof && (
                <Alert
                    classname='mt-3 mb-3'
                    color='warning'
                    icon='information-circle'
                    message={t("RechargementPanel.alert-disposal-proof-title")}
                    subMessage={t<string>(subMessageDisposalProof[form.values.disposalMethod ?? "cash"])}
                />
            )}
            <FileUploaderDragnDrop
                preview
                file={form.values.disposalProof}
                handleOnSubmit={handleRetrieveFile()}
                handleDelete={handleDeleteFile()}
                acceptedFiles={["image/*", "application/pdf"]}
            />

            <div className='divider my-3' />
            <DisposalProofInfo />
            <RechargementDisposalProofHelperPanel disposalMethod={form.values.disposalMethod} />
            <div className='d-flex align-items-center mt-3 px-2'>
                <Icon name='information-circle' className='mr-2' />
                <Typography
                    size='xs'
                    message={t("DisposalProofContainer.vous-n-avez-pas-de-justificatif-effectuez-votre-depot-parmi")}
                />
            </div>
        </div>
    );
};
