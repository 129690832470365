import { IbanDrawerBody } from "./Body";
import { DrawerContext } from "src/wrapper/context";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

import "./styles.scss";

export function IbanDrawer() {
    const { open, setOpen } = useContext(DrawerContext);
    const { t } = useTranslation();

    const handleCloseDrawer = () => {
        setOpen(undefined);
    };

    return (
        <Drawer
            isOpen={open === "iban"}
            onClose={handleCloseDrawer}
            header={{
                title: t("Iban.bank-informations"),
                image: "bank",
            }}
            body={<IbanDrawerBody />}
        />
    );
}
