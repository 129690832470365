import { UserDetailDrawerContainer } from "../Users/DetailDrawer/Container";
import { useNavigate } from "react-router-dom";
import { Todo, TodoData, TodoDataKey } from "src/services/admin/types";
import { TodoFormatKey } from "src/shared/models/Todo";
import { replaceAll } from "src/shared/utils/common";

import { useEffect, useMemo, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

const todoFormatsRaw = (t: TFunction<"translation", string, "translation">): TodoFormat => ({
    "account-kyc-document-incomplete": {
        title: t("Documents.missing"),
        description: t("TodoWidget.account-limits-warning"),
        action: {
            type: "link",
            title: t("CommonUse.add", { entity: t("Documents.a-document") }),
            to: "/admin/documents",
        },
    },
    "user-kycStatus-document-incomplete": {
        title: t("Documents.missing"),
        description: "[USERNAME] ([PHONE])",
        action: {
            type: "button",
            title: t("CommonUse.add", { entity: t("Documents.a-document") }),
            slug: "USER_KYC_DOC_INCOMPLETE",
        },
    },
    "user-kycStatus-profile-incomplete": {
        title: t("Users.missing-infos"),
        description: `${t("Users.user-kycStatus-profile-incomplete")} [USERNAME] ([PHONE]).`,
        action: {
            type: "button",
            title: t("Users.complete-infos"),
            slug: "USER_KYC_PROFILE_INCOMPLETE",
        },
    },
    "user-status-unlinked": {
        title: t("Users.blocked"),
        description: `[USERNAME] ([PHONE]) ${t("Users.status-unlinked")}.`,
        action: {
            type: "link",
            title: t("CommonUse.link-account"),
            to: "/admin/wallets",
        },
    },
    "wallet-no-users": {
        title: t("Wallets.no-signatories"),
        description: "[LABEL] ([REFERENCE])",
        action: {
            type: "link",
            title: t("Wallets.add-signatories"),
            to: "/admin/wallets/[ID]/details",
        },
    },
});

type TodoFormatType = {
    title: string;
    description: string;
    action: TodoAction;
};

type TodoFormat = {
    [key in TodoFormatKey]: TodoFormatType;
};

type TodoAction = {
    type: "link" | "button";
    title: string;
    to?: string;
    slug?: string;
    data?: TodoData | undefined;
};

type TodoWidgetProps = {
    todos: Todo[];
    isLoading: boolean;
};

export const TodoWidget = (props: TodoWidgetProps) => {
    const { t } = useTranslation();
    const { todos = [], isLoading } = props;
    const [formattedTodos, setFormattedTodos] = useState<TodoFormatType[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>(undefined);
    const todoFormats = useMemo(() => todoFormatsRaw(t), [t]);
    const navigate = useNavigate();

    useEffect(() => {
        if (Array.isArray(todos)) {
            const data = todos.map((todo) => {
                const format = todoFormats[`${todo.type}-${todo.status}` as TodoFormatKey];

                const variables = Object.keys(todo.data).reduce((acc: any, key) => {
                    acc[key.toUpperCase()] = todo.data[key as TodoDataKey];
                    return acc;
                }, {});

                const title = replaceAll(format.title, variables);
                const description = replaceAll(format.description, variables);

                const actionTitle = replaceAll(format.action.title, variables);

                const actionTo = format.action.to ? replaceAll(format.action.to, variables) : undefined;

                return {
                    title,
                    description,
                    action: {
                        type: format.action.type,
                        title: actionTitle,
                        to: actionTo,
                        slug: format.action.slug,
                        data: todo.data,
                    },
                };
            });
            setFormattedTodos(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos]);

    const onClickButtonCard = (slug: string | undefined, data: TodoData | undefined) => {
        switch (slug) {
            case "USER_KYC_PROFILE_INCOMPLETE":
                setSelectedUserId(data?.id);
                break;
            default:
                break;
        }
    };

    const handleCloseDrawer = () => {
        setSelectedUserId(undefined);
    };

    const handleRedirectTo = (link?: string) => {
        if (link) {
            navigate(link);
        }
    };

    return (
        <>
            <div className='todo-widget-container'>
                <div className='todo-widget-header'>
                    <Avatar icon='square-check' />
                    <Typography className='fw-bold' message={t("CommonUse.to-complete")} />
                </div>
                <div className='todo-list-container'>
                    {isLoading ? (
                        <div className='loading-placeholder'>
                            <div className='placeholder' style={{ animationDelay: "0.1s" }} />
                            <div className='placeholder' style={{ animationDelay: "0.2s" }} />
                            <div className='placeholder' style={{ animationDelay: "0.6s" }} />
                        </div>
                    ) : (
                        <>
                            {formattedTodos.length > 0 ? (
                                <List
                                    isLoading={isLoading}
                                    animation='fadeInLeft'
                                    keyName='beneficiary-account'
                                    items={formattedTodos.map((item, i) => {
                                        return (
                                            <div key={i} className='item-list-row'>
                                                <div className='left-content'>
                                                    <Avatar
                                                        icon='arrow-right'
                                                        backgroundColor='lilas-50'
                                                        color='lilas-900'
                                                    />

                                                    <div className='left-content-texts'>
                                                        <Typography className='fw-bold' message={item.title} />
                                                        <Typography message={item.description} />
                                                    </div>
                                                </div>
                                                <div className='button-link'>
                                                    {item.action.type === "link" ? (
                                                        <Button
                                                            color='primary'
                                                            position='right'
                                                            icon='chevron-right'
                                                            size='md'
                                                            label={item.action.title}
                                                            onClick={() => handleRedirectTo(item.action.to)}
                                                        />
                                                    ) : (
                                                        <Button
                                                            color='primary'
                                                            icon='chevron-right'
                                                            size='md'
                                                            position='right'
                                                            label={item.action.title}
                                                            onClick={() =>
                                                                onClickButtonCard(item.action.slug, item.action.data)
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                />
                            ) : (
                                <div className='up-to-date'>
                                    <img
                                        style={{ width: "200px" }}
                                        src={require("src/shared/images/user/success.png")}
                                        alt='SUCCESS'
                                    />
                                    <div className='text-part'>
                                        <Typography
                                            className='fw-bold'
                                            size='lg'
                                            message={`${t("TodoWidget.up-to-date")} !`}
                                        />
                                        <Typography
                                            className='fw-bold color-neutral-500'
                                            message={t("TodoWidget.elements-to-complete")}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {selectedUserId && (
                <UserDetailDrawerContainer selectedUserId={selectedUserId} onCloseDrawer={handleCloseDrawer} />
            )}
        </>
    );
};
