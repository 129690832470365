import { DocumentStatus, UserStatus } from "src/shared/models/User";
import { CompanyRole } from "src/shared/models/UserCompany";

import { IconListType } from "src/shared/atoms/Icons/IconList";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export type DocumentStatusDataType = {
    [key in DocumentStatus]:
        | {
              text: string;
              color?: "primary" | "success" | "error" | "neutral" | "warning" | "blue" | "lilas";
              icon: IconListType;
          }
        | undefined;
};

export type UserStatusDataType = {
    [key in UserStatus]: {
        icon: IconListType;
        color?: "primary" | "success" | "error" | "neutral" | "warning" | "blue" | "lilas";
        text: string;
    };
};

//TODO:translations in text
export const documentsStatus: DocumentStatusDataType = {
    "profile-incomplete": {
        text: "Informations manquantes",
        color: "warning",
        icon: "exclamation-triangle",
    },
    "document-incomplete": {
        text: "Document(s) manquant(s)",
        color: "neutral",
        icon: "exclamation-circle",
    },
    "document-pending": {
        text: "Vérifications en cours",
        color: "warning",
        icon: "clock",
    },
    "document-refused": {
        text: "Document(s) refusé(s)",
        color: "error",
        icon: "x-circle",
    },
    "document-validated": {
        text: "Vérifiés",
        color: "success",
        icon: "check-circle",
    },
    "general-error": undefined,
};

//TODO:translations in text
export const userStatus: UserStatusDataType = {
    archived: {
        icon: "forbidden",
        color: "neutral",
        text: "Archivé",
    },
    unlinked: {
        icon: "exclamation-circle",
        color: "error",
        text: "Aucun compte",
    },
    active: {
        icon: "check-circle",
        color: "success",
        text: "Actif",
    },
    inactive: {
        icon: "forbidden",
        color: "neutral",
        text: "Désactivé",
    },
};

//TODO:translations
export const userRoles = {
    ADMIN: "Administrateur",
    "ADMIN-ONLY": "Modérateur",
    USER: "Signataire",
    READ: "Lecture seule",
    COLLECTOR: "Collecteur Wave",
};

//TODO:translations
export const roles: { label: string; role: CompanyRole; description: string }[] = [
    {
        label: "Administrateur",
        role: CompanyRole.ADMIN,
        description:
            "Peut visualiser l'historique comptable de tous les comptes attachés à l'entreprise, administrer le service Julaya en créant des utilisateurs et des bénéficiaires.",
    },
    {
        label: "Modérateur",
        role: CompanyRole.ADMIN_ONLY,
        description:
            "Peut créer et modifier des comptes, peut créer et modifier des utilisateurs, peut créer et modifier des bénéficiaires, peut ajouter et modifier les documents de l’entreprise.",
    },
    {
        label: "Signataire",
        role: CompanyRole.USER,
        description: "Peut signer des paiements sur un compte Julaya et consulter l'historiques des transactions.",
    },
    {
        label: "Lecture seule",
        role: CompanyRole.READ,
        description:
            "Peut visualiser l'historique comptable de tous les comptes attachés à l'entreprise et faire des export comptables.",
    },
];

//TODO:translations
export const documentStatusLabel = {
    done: "Vérifié",
    waiting: "Validation en cours",
    failed: "Documents refusés",
};

//TODO:translations
export const rolesFilter: SelectOption[] = [
    { id: "READ", label: "Lecture seule" },
    { id: "USER", label: "Signataire" },
    { id: "ADMIN", label: "Administrateur" },
    { id: "ADMIN-ONLY", label: "Modérateur" },
    { id: "COLLECTOR", label: "Collecteur" },
];
