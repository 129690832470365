import { AvatarProps } from "../Avatar/Avatar";
import { ButtonProps } from "../Buttons/Button";
import { IconListType } from "../Icons/IconList";
import { StatusIconProps } from "../Icons/StatusIcons";
import { DrawerFooter } from "./DrawerFooter/DrawerFooter";
import { DrawerHeader } from "./DrawerHeader/DrawerHeader";

import { ReactNode, useEffect } from "react";

import { MenuProps } from "src/shared/components/Menu/Menu";

import "./styles.scss";

export type DrawerButton = Pick<ButtonProps, "icon" | "label" | "onClick" | "disabled">;
export type AvatarDrawer = Pick<AvatarProps, "avatar" | "firstname" | "lastname">;
export type StatusIconDrawer = Pick<StatusIconProps, "status">;

export type DrawerProps = {
    className?: string;
    isOpen: boolean;
    isHidden?: boolean;
    onClose: () => void;
    onHide?: () => void;
    header: {
        title: string;
        image: IconListType | AvatarDrawer | StatusIconDrawer;
        subtitle?: string;
        optionalButtons?: [DrawerButton] | [DrawerButton, DrawerButton];
        menuProps?: MenuProps;
    };
    body: ReactNode;
    bodyBackgroundColor?: "white" | "neutral-100";
    footer?: {
        primaryButton: DrawerButton;
        secondaryButton?: DrawerButton;
        optionalText?: string;
    };
};

export const Drawer = ({
    className = "",
    isOpen,
    isHidden = false,
    onClose,
    header,
    body,
    bodyBackgroundColor = "neutral-100",
    footer,
}: DrawerProps) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    const handleCloseDrawer = () => {
        if (!isHidden) {
            document.body.style.overflow = "auto";
            onClose();
        }
    };

    return (
        <div className={`drawer-container ${className}`}>
            {(isOpen || isHidden) && (
                <>
                    {!isHidden && <div className={`overlay right`} data-testid='overlay' onClick={handleCloseDrawer} />}
                    <div className={`drawer ${isOpen && !isHidden ? "open" : ""} right`} data-testid='drawer'>
                        <DrawerHeader {...header} onCloseDrawer={handleCloseDrawer} />
                        <div className={`drawer-body background-${bodyBackgroundColor}`}>{body}</div>
                        {footer && <DrawerFooter {...footer} />}
                    </div>
                </>
            )}
        </div>
    );
};
