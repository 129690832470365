import { CompanyRole } from "src/shared/models/UserCompany";

import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import {
    getAdminExpensePoliciesCreateAllUsers,
    getAdminExpensePoliciesCreateExcludedUsers,
} from "src/modules/admin/expensePolicies/selectors";
import {
    setAdminExpensePolicyCreateExcludedUsers,
    switchAdminExpensePolicyCreateAllUsers,
} from "src/modules/admin/expensePolicies/slice";
import { useAdminAccountsAsSelectOptions } from "src/modules/admin/usersList/hooks";
import { getAdminAccountsListFiltered } from "src/modules/admin/usersList/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

type Props = {
    step?: number;
    handleSetStep?: (newStep: number) => () => void;
};

export function AdminExpensePolicyCreateDrawerUsers({ step, handleSetStep }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const userOptions = useAdminAccountsAsSelectOptions();
    const usersList = useAppSelector((state) =>
        getAdminAccountsListFiltered(state, { roles: [CompanyRole.ADMIN, CompanyRole.USER] })
    );
    const allUsers = useAppSelector((state) => getAdminExpensePoliciesCreateAllUsers(state));
    const excludedUsers = useAppSelector((state) => getAdminExpensePoliciesCreateExcludedUsers(state));

    const switchAllUsers = () => {
        dispatch(switchAdminExpensePolicyCreateAllUsers());
    };

    const handleSetExcludedUsers = (selectedItems: SelectOption[]) => {
        dispatch(setAdminExpensePolicyCreateExcludedUsers({ value: selectedItems }));
    };

    const removeExcludedUser = (id: string) => () => {
        dispatch(
            setAdminExpensePolicyCreateExcludedUsers({
                value: excludedUsers?.filter((item) => item.id !== id),
            })
        );
    };

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter" && handleSetStep && step === 1) {
                handleSetStep(2)();
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    });

    return (
        <StepAccordion
            showOverflow
            showSuccess={false}
            title={t("Expense-policy.who-is-concerned-by-rule")}
            subTitle={t<string>(allUsers ? "Wallets.all-signatories" : "Expense-policy.all-signatories-except-names", {
                amount: excludedUsers?.length ?? 0,
                names: excludedUsers
                    ?.slice(0, 3)
                    ?.map((option) => option.label)
                    .join(", "),
            })}
            icon='user-check'
            currentStep={handleSetStep ? step : 5}
            onOpen={handleSetStep ? handleSetStep(1) : undefined}
            step={1}
            footer={handleSetStep && <Button label={t("CommonUse.validate")} onClick={handleSetStep(2)} />}
        >
            <div className='pt-3'>
                <Alert message={t<string>("Expense-policy.make-sure-user-linked-to-wallet")} color='warning' />
                <div className='d-flex mt-3'>
                    <Switch onToggle={switchAllUsers} isOn={allUsers} />
                    <div className='d-flex flex-column ml-2'>
                        <Typography className='fw-bold' message={t("Wallets.all-signatories")} />
                        <Typography
                            className='color-neutral-500'
                            message={t("Expense-policy.select-all-user-option")}
                        />
                    </div>
                </div>
                {!allUsers && (
                    <>
                        <div className='mt-2'>
                            <SelectMultipleOptions
                                selected={excludedUsers}
                                disabled={allUsers}
                                label={t<string>("Expense-policy.all-users-except", {
                                    amount: excludedUsers?.length ?? 0,
                                })}
                                headerClassname='background-white text-ellipsis'
                                items={userOptions}
                                name={t("Expense-policy.select-wallets")}
                                onSelectionChange={handleSetExcludedUsers}
                            />
                        </div>
                        {excludedUsers?.map((item) => {
                            const { id } = item;
                            const user = usersList.find((userOption) => String(userOption.id) === id);
                            return (
                                user && (
                                    <div key={`user-${id}`} className='drawer-content-section p-3 mt-3'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='d-flex'>
                                                <Avatar
                                                    avatar={user.avatar || undefined}
                                                    firstname={user.firstname}
                                                    lastname={user.lastname}
                                                    backgroundColor='lilas-50'
                                                    color='lilas-900'
                                                />
                                                <div className='d-flex flex-column ml-2'>
                                                    <Typography
                                                        className='fw-bold'
                                                        message={`${user.firstname ?? ""} ${user.lastname}`}
                                                    />
                                                    <Typography message={user.phone} />
                                                </div>
                                            </div>
                                            <Button
                                                className='icon-trash'
                                                icon='thrash'
                                                onClick={removeExcludedUser(id)}
                                                variant='tertiary'
                                                size='md'
                                            />
                                        </div>
                                    </div>
                                )
                            );
                        })}
                    </>
                )}
            </div>
        </StepAccordion>
    );
}
