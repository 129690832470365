import { HelperDrawerOptionKeys } from "./Panel";

import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const checkDrawerExample = require("src/shared/images/rechargement/check-drawer.png");
const physicalTransferDrawerExample = require("src/shared/images/rechargement/physical-transfer-drawer.png");
const electronicTransferDrawerExample = require("src/shared/images/rechargement/electronic-transfert-drawer.png");
const drawerExample = require("src/shared/images/rechargement/drawer-example.png");

const disposalProofTranslation: { [key in HelperDrawerOptionKeys]: string } = {
    cash: "BankDisposal.cash",
    electronicTransfer: "DisposalProofInfo.virement-electronique",
    physicalTransfer: "DisposalProofInfo.virement-physique",
    check: "BankDisposal.check",
};

const disposalProofImage: { [key in HelperDrawerOptionKeys]: string } = {
    cash: drawerExample,
    electronicTransfer: electronicTransferDrawerExample,
    physicalTransfer: physicalTransferDrawerExample,
    check: checkDrawerExample,
};

type Props = {
    disposalMethod: HelperDrawerOptionKeys;
    open: boolean;
    setOpen: () => void;
};

export function RechargementDisposalProofHelperDrawer({ open, disposalMethod = "cash", setOpen }: Readonly<Props>) {
    const { t } = useTranslation();

    return (
        <Drawer
            isOpen={open}
            onClose={setOpen}
            header={{
                title: t("RechargementPanel.bad-justificative"),
                image: "x-circle",
            }}
            body={
                <div className='drawer-content-section disposal-proof-helper-drawer-container'>
                    <Typography message={t(disposalProofTranslation[disposalMethod] ?? "BankDisposal.cash")} />
                    <div className='disposal-proof-drawer-divider' />
                    <Alert
                        message={t("RechargementPanel.unreadable-photo")}
                        color='error'
                        icon='exclamation-triangle'
                    />
                    <img
                        alt={"drawer-example"}
                        className='disposal-proof-drawer-image'
                        src={disposalProofImage[disposalMethod]}
                    />
                </div>
            }
        />
    );
}
