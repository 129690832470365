/* eslint-disable react-hooks/exhaustive-deps */
import { getInStorage } from "../utils/storage";
import { REACT_APP_ENV } from "src/services/utils/const";

import { useCallback, useEffect, useState } from "react";

import { logoutResetStore } from "src/modules/auth/Actions";
import { getAuthUser } from "src/modules/auth/selectors";
import { logout } from "src/modules/auth/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

const events = ["mousedown", "keydown"];

export function useAutoLogout(delayInMinutes: number) {
    const dispatch = useAppDispatch();
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const authUser = useAppSelector((state) => getAuthUser(state));

    const clearTimer = useCallback(() => {
        if (timer) {
            clearTimeout(timer);
        }
    }, [timer]);

    const resetTimer = useCallback(() => {
        clearTimer();
        setTimer(
            setTimeout(() => {
                dispatch(logout());
            }, delayInMinutes * 60 * 1000)
        );
    }, [dispatch, clearTimer, delayInMinutes]);

    useEffect(() => {
        if (REACT_APP_ENV === "live") {
            resetTimer();
            return () => clearTimer();
        }
        return;
    }, []);

    useEffect(() => {
        if (REACT_APP_ENV === "live") {
            events.forEach((eventType) => window.addEventListener(eventType, resetTimer));

            return () => {
                events.forEach((eventType) => window.removeEventListener(eventType, resetTimer));
                clearTimer();
            };
        }
        return;
    }, [timer, resetTimer, clearTimer]);

    useEffect(() => {
        const handleStorageChange = () => {
            const storedUser = getInStorage("user");

            if (authUser && storedUser?.id !== authUser.id) {
                dispatch(logoutResetStore());
            }
        };
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, [authUser, dispatch]);
}
