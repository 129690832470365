import { TransactionService } from "../../../services/transactions/types";
import { transactionServiceSlugImage } from "../../../shared/const/transactions";
import defaultImage from "../../../shared/images/operators/julaya.png";

import "../styles.scss";

type Props = {
    serviceSlug: TransactionService["slug"] | null;
    classname?: string;
    size?: "sm" | "md" | "lg";
};

export const TransactionServiceImage = ({ serviceSlug, classname, size = "md" }: Props) => {
    const serviceImage =
        serviceSlug && transactionServiceSlugImage[serviceSlug]
            ? transactionServiceSlugImage[serviceSlug]
            : defaultImage;

    return (
        <div className={`transaction-service-img image-${size} overflow-hidden ${classname ? classname : ""}`}>
            <img src={serviceImage} alt={"Service"} className={"service-img"} />
        </div>
    );
};
