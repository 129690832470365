import { UserDetailsResponse } from "src/services/admin/types";
import { UserModel } from "src/shared/models/User";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

interface AdminUsersState {
    loading: boolean;
    usersList: UserModel[];
    totalCount: number;
    page: number;
    pageSize: number;
}

const initialState = {
    loading: false,
    usersList: [],
    totalCount: 0,
    page: 1,
    pageSize: 20,
} as AdminUsersState;

export const adminUsersListSlice = createSlice({
    name: "adminUsersList",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestUsersList: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        failedRequestUsers: (state) => {
            return {
                ...state,
                loading: false,
            };
        },
        receiveUsersList: (state, { payload }: PayloadAction<UserDetailsResponse>) => {
            const { count, users } = payload;
            return {
                ...state,
                usersList: users,
                totalCount: count,
                loading: false,
            };
        },
        setUserListPage: (state, { payload }: PayloadAction<{ currentPage: number }>) => {
            const { currentPage } = payload;
            return {
                ...state,
                page: currentPage,
            };
        },
        setUserListPageSize: (state, { payload }: PayloadAction<{ pageSize: number }>) => {
            const { pageSize } = payload;

            return {
                ...state,
                pageSize,
                page: 1,
            };
        },
    },
});

export const { requestUsersList, failedRequestUsers, receiveUsersList, setUserListPage, setUserListPageSize } =
    adminUsersListSlice.actions;
