import { TransactionNotify } from "../../shared/models/Task";
import { logoutResetStore } from "../auth/Actions";
import { ServiceModel } from "src/shared/models/Service";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TransactionsState {
    isLoading: boolean;
    servicesByTypes: ServiceModel[];

    makeTransactionLoading: boolean;
    makeTransactionError: string;
    transactionNotify: TransactionNotify | null;

    cancelTransactionLoading: boolean;
    cancelTransactionError: string;
}

const initialState: TransactionsState = {
    isLoading: false,
    servicesByTypes: [],

    makeTransactionLoading: false,
    makeTransactionError: "",
    transactionNotify: null,

    cancelTransactionLoading: false,
    cancelTransactionError: "",
};

export const transactionsSlice = createSlice({
    name: "transactions",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestBankServices: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        failedRequestBankServices: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
        receiveBankServices: (state, { payload }: PayloadAction<{ services: ServiceModel[] }>) => {
            const { services } = payload;

            return {
                ...state,
                isLoading: false,
                servicesByTypes: services,
            };
        },
        requestMakeTransaction: (state) => {
            return {
                ...state,
                makeTransactionError: "",
                makeTransactionLoading: true,
            };
        },
        failedMakeTransaction: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;

            return {
                ...state,
                makeTransactionLoading: false,
                makeTransactionError: error,
            };
        },
        receiveMakeTransaction: (state, { payload }: PayloadAction<TransactionNotify>) => {
            return {
                ...state,
                makeTransactionLoading: false,
                makeTransactionError: "",
                transactionNotify: payload,
            };
        },
        requestCancelTransaction: (state) => {
            return {
                ...state,
                cancelTransactionLoading: true,
            };
        },
        failedCancelTransaction: (state, { payload }: PayloadAction<{ error: string }>) => {
            return {
                ...state,
                cancelTransactionLoading: false,
                cancelTransactionError: payload.error,
            };
        },
        receiveCancelTransaction: (state) => {
            return {
                ...state,
                cancelTransactionLoading: false,
            };
        },
    },
});

export const {
    requestBankServices,
    failedRequestBankServices,
    receiveBankServices,
    requestMakeTransaction,
    failedMakeTransaction,
    receiveMakeTransaction,
    requestCancelTransaction,
    failedCancelTransaction,
    receiveCancelTransaction,
} = transactionsSlice.actions;

export const transactionsReducer = transactionsSlice.reducer;
