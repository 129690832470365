import { useTranslation } from "react-i18next";

import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

type NameFieldExportProps = {
    name: string;
    handleChangeName: (newName: string) => void;
    errorNameMessage: string | undefined;
};

export const NameFieldExport = ({ name, handleChangeName, errorNameMessage }: NameFieldExportProps) => {
    const { t } = useTranslation();

    return (
        <>
            <div className='required-hint'>
                <Typography message='*' className='color-error' />
                <Typography message={t("CommonUse.required-field")} />
            </div>
            <Input
                autoFocus
                label={t<string>("Export.export-name")}
                placeholder={t("Export.export-name-placeholder")}
                value={name}
                underMessage={errorNameMessage}
                variant={errorNameMessage ? "error" : "primary"}
                onChange={handleChangeName}
                mandatory
            />
        </>
    );
};
