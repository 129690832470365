import { default as classnames } from "classnames";
import { EditUserContext } from "src/shared/context/editUser";
import { CompanyRole } from "src/shared/models/UserCompany";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { getAuthUser } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

export const AdminAccountDetailDrawerDisableUser = () => {
    const user = useAppSelector(getAuthUser);
    const { setPayload, editingSection, handleOpenPasswordDialog } = useContext(EditUserContext);
    const { userDetail } = useAppSelector((state) => getUserDetail(state));
    const { t } = useTranslation();
    const [isDisabledFromRoles, setIsDisabledFromRoles] = useState(false);

    const handleToggleActiveStatus = () => {
        setPayload({ activeStatus: true });
        handleOpenPasswordDialog();
    };

    useEffect(() => {
        if (userDetail?.role === CompanyRole.ADMIN && user?.role !== CompanyRole.ADMIN) {
            setIsDisabledFromRoles(true);
        }
        if (userDetail?.role === CompanyRole.ADMIN_ONLY && user?.role !== CompanyRole.ADMIN) {
            setIsDisabledFromRoles(true);
        }
    }, [user, userDetail]);

    return (
        <div className={classnames("drawer-content-section", { disabled: editingSection })}>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Users.deactivate")} />
                <label
                    onClick={handleToggleActiveStatus}
                    className={classnames("switch-container", { disabled: editingSection || isDisabledFromRoles })}
                >
                    <input type='checkbox' checked={!userDetail?.isActive} />
                    <div className='button'>
                        <Icon name='check-bold' className='icon' size='sm' />
                        <Icon name='x-bold' className='icon' size='sm' />
                    </div>
                </label>
            </div>
            <div className='drawer-content-section-body'>
                <Alert
                    color='warning'
                    icon='exclamation-circle'
                    message={t("Users.if-deactivated")}
                    subMessage={t<string>("Users.deactivate-description")}
                />
            </div>
        </div>
    );
};
