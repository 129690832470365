import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { DashboardPanel } from "src/components/Dashboard/Panel";

const DashboardPageUnauthorized = () => {
    return <DashboardPanel />;
};

export const DashboardPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.READ, CompanyRole.USER],
})(DashboardPageUnauthorized);
