import parsePhoneNumberFromString from "libphonenumber-js";

export const formatPhone = (phone: string | null | undefined): string => {
    if (phone === null || phone === undefined) {
        return "-";
    }

    // CIV
    if (phone.startsWith("+225") || phone.startsWith("225")) {
        return phone.replace(/^(\+225|225)(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/, "+225 $2 $3 $4 $5 $6");
    }

    // SN
    if (phone.length === 9 && phone.startsWith("77")) {
        return phone.replace(/(\d{2})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
    }

    const startsWithPlus = phone.startsWith("+");

    const regExpNumeric = /^[0-9\s]*$/g;

    if (!regExpNumeric.test(phone) && !startsWithPlus) {
        return phone;
    }

    const phoneNumber = parsePhoneNumberFromString(phone);

    if (phoneNumber?.isValid()) {
        return startsWithPlus ? phoneNumber.formatInternational() : phoneNumber.formatNational();
    }

    const cleaned = phone.replace(/\D/g, "");

    if (cleaned.length % 2 === 0) {
        const matches = cleaned.match(/.{1,2}/g);
        if (matches) {
            return matches.join(" ");
        }
    }

    return phone;
};
