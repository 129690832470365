import { API_URL, APP_VERSION, SECRET_TOKEN } from "./const";
import { callApiWithErrorHandling } from "./errorHandling";
import { MiddlewareRequestHeader } from "./types";
import { sha256 } from "js-sha256";
import { encryptCode } from "src/shared/utils/encryptCode";
import { AccessToken, getInStorage } from "src/shared/utils/storage";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type Request = {
    method: HttpMethod;
    headers: MiddlewareRequestHeader;
    body?: string;
};

type MiddlewareRequestProps = {
    endpoint: string;
    method?: HttpMethod;
    params?: unknown | null;
    noCredentials?: boolean;
};

type InitializeRequestOptionsProps = Required<
    Pick<MiddlewareRequestProps, "endpoint" | "params" | "method" | "noCredentials">
> & {
    accessToken: AccessToken | null;
};

type PayloadWithPassword = { password: string };

const isPayloadWithPassword = (payload: unknown | PayloadWithPassword): payload is PayloadWithPassword => {
    return !!(payload as PayloadWithPassword).password;
};

const initializeRequestOptions = ({
    method,
    accessToken,
    params,
    endpoint,
    noCredentials,
}: InitializeRequestOptionsProps) => {
    const options: Request = {
        method,
        headers: {
            "Content-Type": "application/json",
            "App-Version": APP_VERSION,
            "x-secret-token": SECRET_TOKEN,
        },
    };
    const currentCompanyId = getInStorage("currentCompanyId");
    const isGhost = getInStorage("isGhost");

    if (accessToken && accessToken.userId) {
        options.headers["Authorization"] = accessToken.id;

        const stringBody = params ? JSON.stringify(params) : "{}";
        options.headers["x-signature"] = sha256.hmac(accessToken.id, stringBody + accessToken.userId + endpoint);
    }
    if (currentCompanyId) {
        options.headers["x-current-company"] = String(currentCompanyId);
    }
    if (isGhost) {
        options.headers["is-ghost"] = String(true);
    }
    if (params) {
        let computedParams = params;
        if (!noCredentials && isPayloadWithPassword(params)) {
            const { password, ...toKeep } = params;
            computedParams = { ...toKeep, password: encryptCode(password) };
        }
        options.body = JSON.stringify(computedParams);
    }

    return options;
};

export async function middlewareRequest<T>({
    endpoint,
    method = "GET",
    params = null,
    noCredentials = false,
}: MiddlewareRequestProps): Promise<T> {
    const accessToken = getInStorage("accessToken");

    if (!accessToken && !noCredentials) {
        throw new Error("Veuillez vous identifier pour continuer");
    }

    const options = initializeRequestOptions({ noCredentials, method, accessToken, params, endpoint });

    try {
        let res = await callApiWithErrorHandling(fetch, `${API_URL}${endpoint}`, options);
        return method === "DELETE" ? Promise.resolve() : await res.json();
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function middlewareRequestWithTextResponse({
    endpoint,
    method = "GET",
    params = null,
    noCredentials = false,
}: MiddlewareRequestProps): Promise<string> {
    const accessToken = getInStorage("accessToken");

    if (!accessToken && !noCredentials) {
        throw new Error("Veuillez vous identifier pour continuer");
    }

    const options = initializeRequestOptions({ noCredentials, method, accessToken, params, endpoint });

    let res = await callApiWithErrorHandling(fetch, API_URL + endpoint, options);

    return await res.text();
}
