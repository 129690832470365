const standardTiming = {
    text: "Standard",
    color: "neutral",
};

const rapidTiming = {
    text: "Plus rapide",
    color: "success",
};

const slowTiming = {
    text: "Plus lent",
    color: "warning",
};

const averageTimingMapBySlug: {
    [key: string]: { text: string; color: string };
} = {
    "bank-disposal-uba-civ": standardTiming,
    "bank-disposal-ecobank-civ": rapidTiming,
    "bank-disposal-sib-civ": standardTiming,
    "bank-disposal-bdu-civ": standardTiming,
    "bank-disposal-gtb-civ": standardTiming,
    "bank-disposal-dgtcp-civ": standardTiming,
    "bank-disposal-orabank-civ": standardTiming,
    "bank-disposal-sgbci-civ": slowTiming,
};

export const getAverageTime = (slug: string | null) => {
    if (slug) {
        return averageTimingMapBySlug[slug] || standardTiming;
    }

    return standardTiming;
};
