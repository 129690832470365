import "./App.scss";
import { HubspotWrapper } from "./Hubspot";
import { BaseRouter } from "./routes/BaseRouter";
import { DEMO_WARNING, JULAYA_APP_URL, REACT_APP_ENV } from "./services/utils/const";
import { DrawerWrapper } from "./wrapper/drawer";
import { isMobile, isTablet } from "react-device-detect";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useEffect } from "react";

import "./i18n";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";

import { store } from "./store";

import { StickyAlert } from "./shared/components/StickyAlert/StickyAlert";

export const App = () => {
    useEffect(() => {
        if (REACT_APP_ENV === "live" && isMobile && !isTablet) {
            window.location.replace(JULAYA_APP_URL);
        }
    }, []);

    const { t } = useTranslation();

    return (
        <HubspotWrapper>
            <Provider store={store}>
                <div className='App'>
                    {DEMO_WARNING && <StickyAlert label={t("CommonUse.demo-warning")} />}
                    <DrawerWrapper>
                        <BaseRouter />
                    </DrawerWrapper>
                </div>
            </Provider>
            <ToastContainer
                position='bottom-right'
                autoClose={5000}
                hideProgressBar={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
        </HubspotWrapper>
    );
};
