import { billingtransactionStatusesFilterOptions } from "src/shared/const/transactions";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getBillingTransactionFilters,
    getBillingTransactionsArchivedFilter,
    getBillingTransactionsEndDateFilter,
    getBillingTransactionsLoading,
    getBillingTransactionsQueryFilter,
    getBillingTransactionsShowPageSize,
    getBillingTransactionsStartDateFilter,
    getBillingTransactionsStatusFilter,
} from "src/modules/billing/billingTransactions/selectors";
import {
    resetAllBillingTransactionFilters,
    resetBillingTransactionFilters,
    setBillingTransactionEndDateFilter,
    setBillingTransactionQueryFilter,
    setBillingTransactionStartDateFilter,
    setBillingTransactionStatusFilter,
    switchBillingTransactionArchivedFilter,
} from "src/modules/billing/billingTransactions/slice";
import { fetchBillingTransactions } from "src/modules/billing/billingTransactions/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { HistoryFilterKeys, historyFiltersChipName } from "src/components/History/utils";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Chip } from "src/shared/components/Chip/Chip";
import { CustomDatePicker } from "src/shared/components/DatePicker/DatePicker";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export const BillingHistoryFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [closeSelect, setCloseSelect] = useState(false);

    const filters = useAppSelector((state) => getBillingTransactionFilters(state));
    const query = useAppSelector((state) => getBillingTransactionsQueryFilter(state));
    const statusFilter = useAppSelector((state) => getBillingTransactionsStatusFilter(state));
    const startDateFilter = useAppSelector((state) => getBillingTransactionsStartDateFilter(state));
    const endDateFilter = useAppSelector((state) => getBillingTransactionsEndDateFilter(state));
    const archivedFilter = useAppSelector((state) => getBillingTransactionsArchivedFilter(state));
    const isLoading = useAppSelector((state) => getBillingTransactionsLoading(state));
    const showPageSize = useAppSelector((state) => getBillingTransactionsShowPageSize(state) ?? 10);

    const debouncedQuery = useDebounce(query, 700);

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    useEffect(() => {
        return () => {
            dispatch(resetAllBillingTransactionFilters());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!isLoading) {
            dispatch(
                fetchBillingTransactions({
                    filters,
                    page: 1,
                    pageSize: showPageSize,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, debouncedQuery]);

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    const handleSetArchived = useCallback(() => {
        dispatch(switchBillingTransactionArchivedFilter());
    }, [dispatch]);

    const handleSetStatusFilter = useCallback(
        (selectedItems: SelectOption[]) => {
            dispatch(setBillingTransactionStatusFilter({ status: selectedItems }));
        },
        [dispatch]
    );

    const handleSetQueryFilter = useCallback(
        (newQuery: string) => {
            dispatch(setBillingTransactionQueryFilter({ query: newQuery }));
        },
        [dispatch]
    );

    const handleSetStartDateFilter = useCallback(
        (date: Date | null) => {
            dispatch(setBillingTransactionStartDateFilter({ date: date ?? undefined }));
        },
        [dispatch]
    );

    const handleSetEndDateFilter = useCallback(
        (date: Date | null) => {
            dispatch(setBillingTransactionEndDateFilter({ date: date ?? undefined }));
        },
        [dispatch]
    );

    const handleValidateFilters = () => {
        setCloseSelect(true);
        dispatch(
            fetchBillingTransactions({
                filters,
                page: 1,
                pageSize: showPageSize,
            })
        );
    };

    const handleResetAllFilters = useCallback(() => {
        dispatch(resetAllBillingTransactionFilters());
        setCloseSelect(true);
        dispatch(
            fetchBillingTransactions({
                filters,
                page: 1,
                pageSize: showPageSize,
            })
        );
    }, [dispatch]);

    const handleResetFilter = useCallback(
        (filter: HistoryFilterKeys) => () => {
            dispatch(resetBillingTransactionFilters({ filter }));
            const { [filter]: filterToRemove, ...updatedFilters } = filters;
            setCloseSelect(true);
            dispatch(
                fetchBillingTransactions({
                    filters: updatedFilters,
                    page: 1,
                    pageSize: showPageSize,
                })
            );
        },
        [dispatch, filters]
    );

    const renderChip = () => {
        const filtersKey = Object.keys(filters).filter((filterKey) => filterKey !== "query") as HistoryFilterKeys[];

        return filtersKey.length ? (
            <div className='history-filters-chip-container'>
                {filtersKey.map((filterKey) => (
                    <div className='mr-1' key={filterKey}>
                        <Chip
                            handleDelete={handleResetFilter(filterKey)}
                            className='fw-lighter'
                            message={t(historyFiltersChipName[filterKey])}
                        />
                    </div>
                ))}
            </div>
        ) : (
            <Typography message={t("CommonUse.filters")} />
        );
    };

    return (
        <div className='filter-history-container d-flex align-items-center'>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={handleSetQueryFilter}
                value={query ?? ""}
                className='search-input'
                inputClassname='background-white'
            />
            <div className='filters-select ml-2'>
                <Select
                    forceClose={closeSelect}
                    color='white'
                    content={{
                        header: {
                            component: renderChip(),
                            icon: "adjustments-vertical",
                            disabled: false,
                        },
                        dropDownContent: {
                            header: {
                                title: <Typography message={t("CommonUse.filters")} />,
                                label: (
                                    <Button
                                        variant='ghost'
                                        color='lilas'
                                        onClick={handleResetAllFilters}
                                        label={t("HistoryWalletFilters.supprimer-les-filtres")}
                                    />
                                ),
                            },
                            body: (
                                <div className='body-content'>
                                    <SelectMultipleOptions
                                        showSelectedItems={false}
                                        name={`${t("HistoryWalletFilters.par-status")} ${
                                            statusFilter?.length ? "(" + statusFilter?.length + ")" : ""
                                        }`}
                                        selected={statusFilter}
                                        headerClassname='background-color-neutral-50'
                                        items={billingtransactionStatusesFilterOptions(t)}
                                        onSelectionChange={handleSetStatusFilter}
                                        icon='clock'
                                    />
                                    <CustomDatePicker
                                        startDate={startDateFilter}
                                        onChangeStartDate={handleSetStartDateFilter}
                                        endDate={endDateFilter}
                                        onChangeEndDate={handleSetEndDateFilter}
                                        className='background-color-neutral-50'
                                    />
                                    <Button
                                        icon={"archive"}
                                        label={t("HistoryWalletFilters.transaction-s-archivee-s")}
                                        onClick={handleSetArchived}
                                        variant={archivedFilter ? "primary" : "outline"}
                                    />
                                </div>
                            ),
                            footer: (
                                <div className='footer-content'>
                                    <Button
                                        className='background-color-neutral-200 footer-btn'
                                        variant='tertiary'
                                        onClick={handleResetAllFilters}
                                        label={t("CommonUse.cancel")}
                                    />
                                    <Button
                                        className='footer-btn'
                                        variant='primary'
                                        onClick={handleValidateFilters}
                                        label={t("CommonUse.validate")}
                                    />
                                </div>
                            ),
                        },
                    }}
                />
            </div>
        </div>
    );
};
