import clsx from "classnames";

import { PropsWithChildren, useEffect, useState } from "react";

import { Icon, IconStatus } from "../../atoms/Icons/Icon";
import { IconListType } from "../../atoms/Icons/IconList";
import { Typography, TypographyProps } from "../../atoms/Typography/Typography";
import { Avatar, AvatarSize } from "src/shared/atoms/Avatar/Avatar";

import "./styles.scss";

type AccordionProps = {
    title: string;
    subTitle?: string;
    titleSize?: TypographyProps["size"];
    color?: "white" | "grey";
    size?: "sm" | "md";
    icon?: IconListType;
    disabled?: boolean;
    iconStatus?: IconStatus;
    iconColor?: string;
    preSelectedOpen?: boolean;
    noHeaderPadding?: true;
    onOpen?: (isOpened: boolean) => void;
    isOpened?: boolean;
    iconSize?: AvatarSize;
};

export const Accordion = ({
    title,
    icon,
    subTitle,
    titleSize = "sm",
    color = "grey",
    size = "sm",
    disabled = false,
    iconStatus,
    iconColor,
    preSelectedOpen = false,
    children,
    noHeaderPadding,
    onOpen,
    isOpened,
    iconSize = "md",
}: PropsWithChildren<AccordionProps>) => {
    const [showContent, setshowContent] = useState<boolean>(preSelectedOpen);
    const [isAnimating, setIsAnimating] = useState<boolean>(false);

    useEffect(() => {
        setshowContent((prevState) => isOpened ?? prevState);
    }, [isOpened]);

    const handleShowContent = () => {
        if (!disabled) {
            setIsAnimating(true);
            setshowContent(!showContent);
            onOpen && onOpen(!showContent);
        }
    };

    useEffect(() => {
        if (isAnimating) {
            const timeoutId = setTimeout(() => {
                setIsAnimating(false);
            }, 500); // corresponds to the scss transition duration
            return () => clearTimeout(timeoutId);
        } else {
            return () => {};
        }
    }, [isAnimating]);

    useEffect(() => {
        setshowContent(preSelectedOpen);
    }, [preSelectedOpen]);

    return (
        <div className='accordion-container'>
            <div className={"accordion"}>
                <div
                    className={`accordion-header  ${color} size-${size} ${disabled ? "disabled" : ""} ${
                        showContent ? "show" : "hide"
                    } ${noHeaderPadding ? "no-padding" : ""}`}
                    data-testid='accordion-header'
                    onClick={handleShowContent}
                >
                    <div className='header-content'>
                        {icon && (
                            <div className='title-icon-wrapper'>
                                <Avatar
                                    icon={icon}
                                    color={
                                        iconStatus
                                            ? iconStatus === "lilas"
                                                ? "lilas-900"
                                                : iconStatus + "-500"
                                            : undefined
                                    }
                                    backgroundColor={iconColor}
                                    size={iconSize}
                                />
                            </div>
                        )}
                        <div className='header-texts'>
                            <Typography
                                className={clsx({
                                    "color-neutral-400": disabled,
                                })}
                                size={titleSize}
                                message={title}
                            />
                            {subTitle && (
                                <Typography
                                    className={clsx("fw-medium", {
                                        "color-neutral-400": disabled,
                                    })}
                                    message={subTitle}
                                />
                            )}
                        </div>
                    </div>
                    <div className='toggle-icon-container'>
                        <Icon
                            className={clsx({
                                "color-neutral-400": disabled,
                                show: showContent,
                            })}
                            name={disabled ? "lock" : "chevron-down"}
                        />
                    </div>
                </div>
                {!disabled && (
                    <div
                        className={`accordion-content ${showContent ? "show" : "hide"} ${color} size-${size} ${
                            isAnimating ? "animating" : ""
                        }`}
                        data-testid='accordion-content'
                    >
                        <div className='content-padding'>{children}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
