import { Dispatch, SetStateAction } from "react";

import { useTranslation } from "react-i18next";

import { getAuthCompanies } from "src/modules/authCompanies/selectors";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

import "./styles.scss";

type Props = {
    isLoading?: boolean;
    companyIndex: number | null;
    setCompanyIndex: Dispatch<SetStateAction<number | null>>;
};

export function CompaniesList({ isLoading, companyIndex, setCompanyIndex }: Props) {
    const authCompanies = useAppSelector((state) => getAuthCompanies(state));

    const { t } = useTranslation();

    const handleChangeValue = (index: number) => () => {
        setCompanyIndex(index === companyIndex ? null : index);
    };

    if (!authCompanies.length) {
        return (
            <Typography
                variant='h4'
                message={t("Companies.no-available-companies")}
                className='d-flex justify-content-center fw-bold mb-0'
            />
        );
    }

    if (!authCompanies) {
        return <></>;
    }

    return (
        <List
            isLoading={isLoading}
            className='companies-list-container'
            animation='fadeInLeft'
            keyName='bankAccount'
            selectedValue={companyIndex}
            items={Object.values(authCompanies).map((authCompany, index) => (
                <ToolTip
                    activation={!authCompany.hasAccess}
                    fullWidth
                    key={`company-${authCompany.id}`}
                    position='bottom'
                    content={
                        <>
                            <Typography
                                variant='p'
                                className='fw-bold mt-0'
                                message={t("Companies.no-access-to-companies")}
                            />
                            <Typography message={t("Companies.access-denied")} />
                        </>
                    }
                    children={
                        <div
                            onClick={authCompany.hasAccess ? handleChangeValue(index) : undefined}
                            data-testid={"company-list-item-" + index}
                            className={`${
                                authCompany.hasAccess ? "" : "disabled"
                            } d-flex flex-row justify-content-between align-items-center h-100`}
                        >
                            <div className='d-flex flex-column h-100 justify-content-between'>
                                <div className='d-flex flex-row mb-3'>
                                    <Typography className='fw-bold' variant='span' message={authCompany.name} />
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Avatar icon='briefcase' size='sm' />
                                    <Typography
                                        variant='span'
                                        message={t("Companies.list")}
                                        className='px-2 color-neutral'
                                    />
                                </div>
                            </div>

                            <Typography
                                message={`${authCompany.nbrUsers} ${t("Users.list")}`}
                                className='user-list-nb-users'
                            />
                        </div>
                    }
                />
            ))}
        />
    );
}
