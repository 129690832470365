import { TasksDetailsDrawerContainer } from "../Details/Drawer/Container";
import { Link, useLocation } from "react-router-dom";
import { requestGetTask, requestMarkTaskAsComplete } from "src/services/tasks/operations";
import { TaskListApiReturnItem } from "src/services/tasks/types";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useRetryTaskValidationPassword } from "src/modules/tasks/hooks";
import { receiveTask } from "src/modules/tasks/slice";
import { useAppDispatch } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Modal } from "src/shared/components/Modal/Modal";

type LocationState = {
    taskId?: number;
};

type Props = {
    task: TaskListApiReturnItem;
};

export function TasksTableCard({ task }: Readonly<Props>) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const locationState = useLocation().state as LocationState;

    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [markAsCompleteLoading, setMarkAsCompleteLoading] = useState(false);

    const { title, status, Wallet, type, metadata, id } = task;

    const { error, handleRetryTasksTransaction, handleSwitchPasswordDialogOpen, passwordDialogOpen } =
        useRetryTaskValidationPassword(String(id));

    useEffect(() => {
        if (locationState?.taskId === task.id) {
            setOpen(true);
        }
    }, [locationState?.taskId, task.id]);

    const handleOpenPasswordDialog = () => {
        handleSwitchPasswordDialogOpen();
        setOpen(false);
    };

    const handleClosePasswordDialog = () => {
        handleSwitchPasswordDialogOpen();
        setOpen(true);
    };

    const handleSwitchOpen = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    const handleOpenMarkAsCompleteModal = () => {
        setOpenDialog(true);
        handleSwitchOpen();
    };
    const handleCloseMarkAsCompleteModal = () => {
        setOpenDialog(false);
        handleSwitchOpen();
    };

    const handleSubmit = () => {
        setMarkAsCompleteLoading(true);
        requestMarkTaskAsComplete(task.id)
            .then(async () => {
                setMarkAsCompleteLoading(false);
                const taskResponse = await requestGetTask(task.id);
                setOpen(false);
                dispatch(receiveTask({ task: taskResponse }));
            })
            .finally(() => setMarkAsCompleteLoading(false));
    };

    const amount = (metadata?.sum ? parseInt(metadata.sum) : 0) + (metadata?.fees ?? 0);

    return (
        <>
            <div className='task-table-card'>
                <div className='task-card-overlay'>
                    <div onClick={handleSwitchOpen} className='task-card-overlay-btn color-neutral'>
                        <Icon name='information-circle' className='mr-2' />
                        <Typography message={t("Tasks.task-details")} />
                    </div>
                    {status && !["draft", "to-validate", "sending"].includes(status) && (
                        <Link
                            to={`/history/wallet/${task?.walletId}/task/${task?.id}`}
                            className='task-card-overlay-btn color-neutral mt-2'
                        >
                            <Icon name='list-bullet' className='mr-2' />
                            <Typography message={t("Tasks.task-transactions")} />
                        </Link>
                    )}
                </div>

                <div className={`task-card-content status-${status}`}>
                    <div className='d-flex flex-column w-100'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='mb-0 mt-2 font-weight-bold'>
                                <Typography className='mt-0 mb-1 color-neutral-500 fw-bold' message={Wallet?.label} />

                                <Typography
                                    className='mt-0 fw-bold'
                                    variant='h6'
                                    message={`${formatMoneyToString({ amount })}`}
                                />
                            </div>

                            <div className='task-icon py-2 px-2'>
                                <Avatar
                                    icon={type === "VALIDATION" ? "bulk" : "bolt"}
                                    color='lilas-900'
                                    backgroundColor='lilas-50'
                                />
                            </div>
                        </div>

                        <div className='task-card-body'>
                            <Typography message={title} className='mb-0' />
                        </div>

                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                {status === "to-validate" || status === "draft" ? (
                                    <div className='d-flex align-items-center'>
                                        <Icon name='bulk' className='color-neutral-500' />
                                        <Typography
                                            className='ml-2'
                                            message={t("Tasks.total-transactions", {
                                                nbTotal: task.metadata?.total || 0,
                                            })}
                                        />
                                    </div>
                                ) : status === "pending" ? (
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <Icon name='clock' className='color-neutral-500' />
                                            <Typography
                                                className='color-warning-500 ml-2'
                                                message={task.metadata.nbPending}
                                            />
                                        </div>

                                        <div className='d-flex align-items-center ml-3'>
                                            <Icon name='x-circle' className='color-neutral-500' />
                                            <Typography
                                                className='ml-2 color-error-500'
                                                message={task.metadata.nbFailed}
                                            />
                                        </div>

                                        <div className='d-flex align-items-center ml-3'>
                                            <Icon name='check-circle' className='color-neutral-500' />
                                            <Typography
                                                className='ml-2 color-success-500'
                                                message={task.metadata.nbSuccess}
                                            />
                                        </div>
                                    </div>
                                ) : status === "to-retry" ? (
                                    <div className='d-flex align-items-center'>
                                        <Icon name='retry' className='color-error-500' />
                                        <Typography
                                            className='color-error-500 ml-2'
                                            message={t("Tasks.retriable-transactions", {
                                                nbFailed: task.metadata?.nbFailed,
                                            })}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div className='d-flex align-items-center'>
                                <Typography className='color-neutral-500 mr-1' message={task.TaskActivities.length} />

                                <Icon name='chat-bubble' className='color-neutral-500' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TasksDetailsDrawerContainer
                open={open}
                task={task}
                handleSwitchOpen={handleSwitchOpen}
                handleSwitchPasswordDialogOpen={handleOpenPasswordDialog}
                handleSwitchArchiveModal={handleOpenMarkAsCompleteModal}
            />
            <PasswordValidationModal
                error={error}
                handleOnSubmit={handleRetryTasksTransaction}
                setOpen={handleClosePasswordDialog}
                open={passwordDialogOpen}
            />
            <Modal
                open={openDialog}
                loading={markAsCompleteLoading}
                handleOnSubmit={handleSubmit}
                handleOnCancel={handleCloseMarkAsCompleteModal}
                header={{ title: t("Tasks.mark-as-complete"), icon: "arrow-path" }}
            >
                <Typography className='color-neutral-400' message={t("Tasks.mark-as-complete-warning")} />
            </Modal>
        </>
    );
}
