import { useNavigate } from "react-router-dom";
import { CompanyKybLevel, CompanyRole } from "src/shared/models/UserCompany";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserLoading } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";

type KybAlertProps = {
    topBottom?: boolean;
};

export const KybAlert = ({ topBottom = false }: KybAlertProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authUser = useAppSelector((state) => getAuthUser(state));
    const isLoadingAuthUser = useAppSelector((state) => getAuthUserLoading(state));

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        setIsAdmin(authUser?.role === CompanyRole.ADMIN || authUser?.role === CompanyRole.ADMIN_ONLY);
    }, [authUser]);

    const handleRedirectToDocuments = () => {
        navigate("/admin/documents");
    };

    return !isLoadingAuthUser && authUser?.Company?.kybRole === CompanyKybLevel.USER ? (
        <Alert
            icon='information-circle'
            topBottom={topBottom}
            color='warning'
            message={t("KybIndicator.to-complete-status")}
            subMessage={
                isAdmin
                    ? t<string>("KybIndicator.complete-repo-to-use-all")
                    : t<string>("KybIndicator.contact-admin-to-upgrade-tier")
            }
            buttons={
                isAdmin
                    ? {
                          label: t("KybIndicator.complete-repo"),
                          variant: "primary",
                          color: "warning",
                          className: "w-100",
                          onClick: handleRedirectToDocuments,
                      }
                    : undefined
            }
        />
    ) : null;
};
