import { useNavigate } from "react-router-dom";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getTaskToEdit } from "src/modules/tasks/selectors";
import { getTaskItemsUpdating } from "src/modules/tasksItems/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";

import "./styles.scss";

type Props = {
    taskId: number | undefined;
};

export function TasksEditFooterDialog({ taskId }: Props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const task = useAppSelector((state) => getTaskToEdit(state));

    const missingWalletMoney = parseInt(task?.metadata?.sum ?? "0") - (task?.Wallet?.balance ?? 0);
    const taskItemsUpdating = useAppSelector((state) => getTaskItemsUpdating(state, { taskId }));

    const disabled = taskItemsUpdating ? Object.values(taskItemsUpdating).some((task) => task) : false;

    useEffect(() => {
        setOpen(missingWalletMoney > 0 && task?.errorCode === "BATCH_CHECKS_NOT_ENOUGHT_MONEY");
    }, [task, missingWalletMoney]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleRedirect = () => {
        navigate("/transfer/bank-disposal", { state: { walletId: task?.walletId, amount: missingWalletMoney } });
    };

    return (
        <>
            {open && (
                <div className={`task-manager-notification mb-3 open`}>
                    <Alert
                        message={t("Tasks.not-enough-money", {
                            amount: formatMoneyToString({ amount: missingWalletMoney }),
                        })}
                        color='error'
                        buttons={[
                            { label: t("CommonUse.ignore"), variant: "tertiary", onClick: handleClose },
                            {
                                label: t("Tasks.keep-draft"),
                                variant: "primary",
                                color: "error",
                                onClick: handleRedirect,
                                disabled: disabled,
                            },
                        ]}
                    />
                </div>
            )}
        </>
    );
}
