import { useNavigate } from "react-router-dom";
import { requestCancelTask, signTask } from "src/services/tasks/operations";
import { HandleSubmitPropsPasswordValidation, PasswordValidationModal } from "src/shared/common/Password/Modal";
import successImage from "src/shared/images/rapid-transfer/rapid-transfer-success.svg";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { v4 as uuidv4 } from "uuid";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getTaskToEdit } from "src/modules/tasks/selectors";
import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    taskId: number | string | undefined;
    open: boolean;
    handleSwitchOpen: () => void;
};

export function TasksSignatureDialog({ taskId, open, handleSwitchOpen }: Props) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [seconds, setSeconds] = useState(15);
    const [error, setError] = useState<string | undefined>();
    const [activeTimer, setActiveTimer] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);

    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        if (activeTimer) {
            if (seconds === 0) {
                navigate("/tasks");
            } else {
                interval = setInterval(() => {
                    setSeconds((seconds) => seconds - 1);
                }, 1000);
            }
            return () => clearInterval(interval as NodeJS.Timeout);
        }
        return;
    }, [seconds, activeTimer]);

    const task = useAppSelector((state) => getTaskToEdit(state));

    const taskSignUniqueId = useMemo(() => {
        return uuidv4().toString();
    }, []);

    const Wallet = task?.Wallet;
    const TaskSignatures = task?.TaskSignatures;
    const newBalance = (task?.Wallet?.balance ?? 0) - parseInt(task?.metadata?.sum ?? "0");
    const isRefundable =
        TaskSignatures && Wallet ? task?.Wallet?.nbSignatures === Object.keys(TaskSignatures).length : false;

    const handleConfirm = async ({ password, twoFactor }: HandleSubmitPropsPasswordValidation) => {
        setError(undefined);

        return signTask({ password, taskId: taskId as string, twoFactor, uniqueId: taskSignUniqueId })
            .then(() => {
                setActiveTimer(true);
                dispatch(fetchTaskToEdit({ taskId: taskId as string }));
            })
            .catch((error) => {
                if (error.message) {
                    setError(error.message);
                }
                throw error;
            });
    };

    const handleOnSuccess = () => {
        navigate("/tasks");
    };

    const handleCancelTask = async () => {
        if (taskId) {
            setActiveTimer(false);
            setCancelLoading(true);
            await requestCancelTask({ taskId });
            setCancelLoading(false);
            navigate("/tasks");
        }
    };

    return (
        <PasswordValidationModal
            onSuccess={handleOnSuccess}
            error={error}
            successComponent={
                <div className='task-success-dialog-container'>
                    <Typography className='fw-bold' message={t("Tasks.operation-success")} />
                    <Typography message={t("Tasks.redirect", { nbSeconds: seconds })} />
                    <img src={successImage} alt='success' className='d-block mx-auto' height={200} />
                    {isRefundable && (
                        <div className='d-flex justify-content-center align-items-center'>
                            <Button
                                disabled={cancelLoading}
                                onClick={handleCancelTask}
                                loading={cancelLoading}
                                variant='outline'
                                label={t("Tasks.cancel-transfer")}
                            />
                        </div>
                    )}
                    <div className='divider' />
                    <div className='amount-summary'>
                        <Typography message={t("Tasks.total-amount")} />
                        <Typography message={formatMoneyToString({ amount: task?.metadata?.sum ?? 0 })} />
                    </div>
                    <div className='divider' />
                    <div className='amount-summary'>
                        <Typography message={t("Tasks.new-balance")} />
                        <Typography className='color-lilas-900' message={formatMoneyToString({ amount: newBalance })} />
                    </div>
                </div>
            }
            open={open}
            setOpen={handleSwitchOpen}
            handleOnSubmit={handleConfirm}
        />
    );
}
