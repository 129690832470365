import { TasksDetailsDrawerBody } from "./Body";
import { useNavigate } from "react-router-dom";
import { deleteTask } from "src/services/tasks/operations";
import { TaskListApiReturnItem } from "src/services/tasks/types";

import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { useExportAsExcelOrDuplicateTask } from "src/modules/tasks/hooks";
import { removeTask } from "src/modules/tasks/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer, DrawerProps } from "src/shared/atoms/Drawer/Drawer";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    open: boolean;
    task: TaskListApiReturnItem;
    handleSwitchOpen: () => void;
    handleSwitchPasswordDialogOpen?: () => void;
    handleSwitchArchiveModal?: () => void;
};

export function TasksDetailsDrawerContainer({
    open,
    task,
    handleSwitchOpen,
    handleSwitchPasswordDialogOpen,
    handleSwitchArchiveModal,
}: Readonly<Props>) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [loadingDelete, setLoadingDelete] = useState(false);

    const { id, metadata, Wallet, TaskSignatures } = task;

    const authUser = useAppSelector((state) => getAuthUser(state));

    const handleDeleteTask = () => {
        setLoadingDelete(true);
        deleteTask({ taskId: id })
            .then(() => {
                dispatch(removeTask({ taskId: id }));
                handleSwitchOpen();
            })
            .finally(() => setLoadingDelete(false));
    };

    const handleRedirectToEdit = () => {
        navigate(`/tasks/${id}/edit`);
    };

    const handleRedirectToValidate = () => {
        navigate(`/tasks/signature/${id}`);
    };

    const handleOpenHubspot = () => {
        if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.open();
        }
    };

    const footer: DrawerProps["footer"] = useMemo(() => {
        const hasSigned = TaskSignatures.some((item) => item.userId === authUser?.id);

        switch (task.status) {
            case "draft": {
                return {
                    primaryButton: {
                        label: <Typography message={t("Tasks.edit-draft")} />,
                        icon: "pencil",
                        onClick: handleRedirectToEdit,
                    },
                    secondaryButton: {
                        label: <Typography message={t("Tasks.delete-draft")} />,
                        icon: "thrash",
                        disabled: loadingDelete,
                        onClick: handleDeleteTask,
                    },
                };
            }
            case "to-validate": {
                return {
                    primaryButton: {
                        label: (
                            <Typography
                                message={t("Tasks.see-sign-send", {
                                    ratio: `${task.TaskSignatures.length ?? 0}/${Wallet.nbSignatures ?? 1}`,
                                })}
                            />
                        ),
                        icon: "pencil",
                        disabled: hasSigned,
                        onClick: handleRedirectToValidate,
                    },
                    secondaryButton: {
                        label: <Typography message={t("Tasks.delete-this-task")} />,
                        icon: "thrash",
                        disabled: loadingDelete,
                        onClick: handleDeleteTask,
                    },
                };
            }
            case "to-retry": {
                return {
                    primaryButton: {
                        label: (
                            <Typography
                                message={t("Tasks.retry-transactions", {
                                    amount: metadata?.nbFailed ?? 0,
                                })}
                            />
                        ),
                        icon: "retry",
                        onClick: handleSwitchPasswordDialogOpen,
                    },
                    secondaryButton: {
                        label: <Typography message={t("Tasks.mark-as-done")} />,
                        icon: "check-circle",
                        onClick: handleSwitchArchiveModal,
                    },
                };
            }
            case "cancelled": {
                return {
                    primaryButton: {
                        label: <Typography message={t("CommonUse.contact-support")} />,
                        onClick: handleOpenHubspot,
                    },
                };
            }
            default: {
                return;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task, loadingDelete]);

    const { duplicateDisabled, handleDownloadTaskAsExcel, handleDuplicateTask } = useExportAsExcelOrDuplicateTask({
        handleSwitchOpen,
        open,
        task,
    });

    return (
        <Drawer
            isOpen={open}
            onClose={handleSwitchOpen}
            header={{
                title: task.title,
                image: "task",
                menuProps: {
                    dropdownIcon: "ellipsis-vertical",
                    items: duplicateDisabled
                        ? [
                              {
                                  action: handleDownloadTaskAsExcel,
                                  icon: "arrow-down-on-square",
                                  title: t("Tasks.download-excel-file"),
                              },
                          ]
                        : [
                              {
                                  action: handleDuplicateTask,
                                  icon: "square-2-stack",
                                  title: t("Tasks.duplicate-task"),
                              },
                              {
                                  action: handleDownloadTaskAsExcel,
                                  icon: "arrow-down-on-square",
                                  title: t("Tasks.download-excel-file"),
                              },
                          ],
                    color: "darkgrey",
                    fitContent: true,
                    position: "left",
                    closeMenuOnAction: true,
                },
            }}
            body={<TasksDetailsDrawerBody task={task} />}
            footer={footer}
        />
    );
}
