import { RechargementDisposalProofHelperDrawer } from "./Drawer";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

export type HelperDrawerOptionKeys = "cash" | "physicalTransfer" | "electronicTransfer" | "check";

const validImage = require("src/shared/images/rechargement/valid.png");
const notReadableImage = require("src/shared/images/rechargement/not-readable.png");
const noSignatureImage = require("src/shared/images/rechargement/no-signature.png");
const checkValidImage = require("src/shared/images/rechargement/check-valid.png");
const checkUnreadableImage = require("src/shared/images/rechargement/check-unreadable.png");
const checkNoSignatureImage = require("src/shared/images/rechargement/check-no-signature.png");
const electronicTransferValidImage = require("src/shared/images/rechargement/electronic-transfer-valid.png");
const electronicTransferUnreadableImage = require("src/shared/images/rechargement/electronic-transfer-unreadable.png");
const electronicTransferNoSignatureImage = require("src/shared/images/rechargement/electronic-transfer-no-signature.png");
const physicalTransferValidImage = require("src/shared/images/rechargement/physical-transfer-no-signature.png");
const physicalTransferUnreadableImage = require("src/shared/images/rechargement/physical-transfer-no-signature.png");
const physicalTransferNoSignatureImage = require("src/shared/images/rechargement/electronic-transfer-no-signature.png");

const helperItems: {
    [disposalMethod: string]: {
        sectionLabel: string;
        icon: IconListType;
        items: { label: string; color: "green" | "red"; image: string }[];
        option: HelperDrawerOptionKeys;
    }[];
} = {
    cash: [
        {
            sectionLabel: "BankDisposal.cash",
            icon: "bills",
            items: [
                { label: "RechargementPanel.method-bills-valid", color: "green", image: validImage },
                { label: "RechargementPanel.method-bills-unreadable", color: "red", image: notReadableImage },
                { label: "RechargementPanel.method-bills-no-signature", color: "red", image: noSignatureImage },
            ],
            option: "cash",
        },
    ],
    bank: [
        {
            sectionLabel: "DisposalProofInfo.virement-physique",
            icon: "bank",
            items: [
                {
                    label: "DisposalProofInfo.signed-discharged-transfer-order",
                    color: "green",
                    image: physicalTransferValidImage,
                },
                {
                    label: "RechargementPanel.method-bills-unreadable",
                    color: "red",
                    image: physicalTransferUnreadableImage,
                },
                {
                    label: "RechargementPanel.method-bills-no-signature",
                    color: "red",
                    image: physicalTransferNoSignatureImage,
                },
            ],
            option: "physicalTransfer",
        },
        {
            sectionLabel: "DisposalProofInfo.virement-electronique",
            icon: "device-computer",
            items: [
                {
                    label: "DisposalProofInfo.signed-validated-transmitted-transfer-order",
                    color: "green",
                    image: electronicTransferValidImage,
                },
                {
                    label: "RechargementPanel.method-bills-unreadable",
                    color: "red",
                    image: electronicTransferUnreadableImage,
                },
                {
                    label: "RechargementPanel.method-electronic-transfer-no-signature",
                    color: "red",
                    image: electronicTransferNoSignatureImage,
                },
            ],
            option: "electronicTransfer",
        },
    ],
    check: [
        {
            sectionLabel: "BankDisposal.check",
            icon: "pencil-square",
            items: [
                {
                    label: "DisposalMethodList.une-photo-de-la-decharge-du-cheque-par-julaya",
                    color: "green",
                    image: checkValidImage,
                },
                { label: "RechargementPanel.method-bills-unreadable", color: "red", image: checkUnreadableImage },
                {
                    label: "RechargementPanel.method-check-no-signature",
                    color: "red",
                    image: checkNoSignatureImage,
                },
            ],
            option: "check",
        },
    ],
};

type Props = {
    disposalMethod: string;
};

export function RechargementDisposalProofHelperPanel({ disposalMethod }: Readonly<Props>) {
    const [option, setOption] = useState<HelperDrawerOptionKeys | null>(null);

    const { t } = useTranslation();

    const handleSetOption = (newOption: HelperDrawerOptionKeys) => () => {
        setOption(newOption);
    };

    const handleCloseDrawer = () => {
        setOption(null);
    };

    return (
        <>
            <div className='disposal-proof-helper-container'>
                {helperItems[disposalMethod]?.map(({ sectionLabel, items, option }) => (
                    <Accordion titleSize='sm' icon={"bills"} key={sectionLabel} title={t(sectionLabel)} preSelectedOpen>
                        <div className='disposal-proof-helper-accordion'>
                            {items?.map(({ label, color, image }) => (
                                <div
                                    className={`disposal-proof-helper-item color-${color}`}
                                    key={label}
                                    onClick={handleSetOption(option)}
                                >
                                    <div className='disposal-proof-helper-title'>
                                        <div>
                                            <Icon
                                                name={color === "green" ? "check-bold" : "x-bold"}
                                                size='lg'
                                                variant={"squared"}
                                                status={color === "green" ? "success" : "error"}
                                            />
                                        </div>
                                        <Typography className='fw-bold' message={t(label)} />
                                    </div>
                                    <img alt={label} className='disposal-proof-helper-image' src={image} />
                                </div>
                            ))}
                        </div>
                    </Accordion>
                ))}
            </div>
            <RechargementDisposalProofHelperDrawer
                open={!!option}
                setOpen={handleCloseDrawer}
                disposalMethod={option ?? "cash"}
            />
        </>
    );
}
