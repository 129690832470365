import CryptoJS from "crypto-js";
import { UserAuthApiReturn } from "src/services/auth/types";

export type AccessToken = {
    ttl: number;
    id: string;
    userId: number;
    createdAt: string;
};

type UserPreferences = {
    preferredCompanyId: number;
    bankDisposalPreferredBank: string;
    useBaseRouter: boolean;
};

type Storage = {
    user: UserAuthApiReturn;
    currentCompanyId: number;
    isGhost: boolean;
    preferences: { [userId: string]: UserPreferences };
    accessToken: AccessToken;
    lastTabTimestamp: string;
    companyLegalFields: string;
};

export type StorageKeys = keyof Storage;

export function getInStorage<T extends StorageKeys>(key: T | undefined, parse = true): Storage[T] | null {
    if (!key) {
        return null;
    }

    try {
        const digest = localStorage.getItem(CryptoJS.SHA256(`${key}`).toString().substring(0, 15));

        if (digest) {
            const val = CryptoJS.AES.decrypt(digest, `${process.env.REACT_APP_SECRET}`).toString(CryptoJS.enc.Utf8);
            if (parse) {
                return JSON.parse(val) as Storage[T];
            } else if (typeof val === "number") {
                return val as Storage[T];
            }
        }
        return null;
    } catch (error) {
        return null;
    }
}

export type UserPreferencesKeys = keyof UserPreferences;

export function getInStorageUserPreferences<T extends UserPreferencesKeys>(key: T) {
    const authUser = getInStorage("user");

    try {
        if (authUser) {
            const digest = localStorage.getItem(CryptoJS.SHA256("preferences").toString().substring(0, 15));

            return digest ? (JSON.parse(digest)?.[authUser.id]?.[key] as UserPreferences[T]) : null;
        }
        return null;
    } catch (error) {
        return null;
    }
}

export function setInStorage<T extends StorageKeys>(key: T, value: Storage[T]) {
    localStorage.setItem(
        CryptoJS.SHA256(`${key}`).toString().substring(0, 15),
        CryptoJS.AES.encrypt(`${JSON.stringify(value)}`, `${process.env.REACT_APP_SECRET}`).toString()
    );
}

function getInStorageUsersPreferences() {
    try {
        const digest = localStorage.getItem(CryptoJS.SHA256("preferences").toString().substring(0, 15));
        return digest ? (JSON.parse(digest) as Storage["preferences"]) : null;
    } catch (error) {
        return null;
    }
}

export function setInStorageUserPreferences<T extends UserPreferencesKeys>(key: T, value: UserPreferences[T]) {
    const authUser = getInStorage("user");
    const preferences = getInStorageUsersPreferences() ?? {};

    if (authUser) {
        localStorage.setItem(
            CryptoJS.SHA256("preferences").toString().substring(0, 15),
            JSON.stringify({ ...preferences, [authUser.id]: { ...preferences[authUser.id], [key]: value } })
        );
    }
}

export const removeInStorage = (key: StorageKeys) => {
    localStorage.removeItem(CryptoJS.SHA256(`${key}`).toString().substring(0, 15));
};

export const removeInStorageUserPreferences = (key: UserPreferencesKeys) => {
    const authUser = getInStorage("user");
    const preferences = getInStorageUsersPreferences();

    if (preferences && authUser) {
        const newUserPreferences = preferences[authUser.id];

        if (newUserPreferences?.[key]) {
            delete newUserPreferences[key];
        }

        localStorage.setItem(
            CryptoJS.SHA256("preferences").toString().substring(0, 15),
            JSON.stringify({ ...preferences, [authUser.id]: newUserPreferences })
        );
    }
};

export const clearStorage = () => {
    removeInStorage("lastTabTimestamp");
    removeInStorage("accessToken");
    removeInStorage("user");
    removeInStorage("currentCompanyId");
    removeInStorage("companyLegalFields");
    removeInStorage("isGhost");
};
