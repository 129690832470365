import { LoginEmailVerificationDialog } from "./Dialog";
import { requestVerifyEmail } from "src/services/auth/operations";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";

export const EmailValidationAlertPanel = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const authUser = useAppSelector((state) => getAuthUser(state));

    const openModal = () => {
        setOpen(true);
    };

    const handleSendValidationMail = () => {
        setLoading(true);
        requestVerifyEmail()
            .then(() => openModal())
            .finally(() =>
                setTimeout(() => {
                    setLoading(false);
                }, 30000)
            );
    };

    return authUser?.isEmailVerified ? null : (
        <>
            <div className='d-flex w-100'>
                <Alert
                    message={t("LoginPanel.email-not-verified")}
                    subMessage={t<string>("LoginPanel.email-not-verified-alert")}
                    color='warning'
                    classname='w-100'
                    buttons={{
                        disabled: loading,
                        onClick: handleSendValidationMail,
                        label: t("LoginPanel.send-email-verification-link"),
                        color: "warning",
                        testId: "send-email-verification-button",
                    }}
                />
            </div>
            <LoginEmailVerificationDialog open={open} setOpen={setOpen} />
        </>
    );
};
