import { AdminExpensePolicySuccess } from "../Success";
import { AdminExpensePolicyEditDrawerBody } from "./Body";
import { HandleSubmitPropsPasswordValidation, PasswordValidationModal } from "src/shared/common/Password/Modal";

import { Dispatch, SetStateAction, useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getAdminExpensePoliciesCreateId,
    getAdminExpensePoliciesCreateTitle,
    getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy,
} from "src/modules/admin/expensePolicies/selectors";
import { resetAdminExpensePolicyCreate } from "src/modules/admin/expensePolicies/slice";
import {
    handleDeleteAdminExpensePolicies,
    handleUpdateAdminExpensePolicies,
} from "src/modules/admin/expensePolicies/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

type Props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export function AdminExpensePolicyEditDrawerContainer({ open, setOpen }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [openValidationModal, setOpenValidationModal] = useState<"edit" | "delete" | undefined>();
    const [hidden, setHidden] = useState(false);

    const id = useAppSelector((state) => getAdminExpensePoliciesCreateId(state) ?? "");
    const title = useAppSelector((state) => getAdminExpensePoliciesCreateTitle(state) ?? "");
    const walletsWithExpensePolicy = useAppSelector((state) =>
        getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy(state)
    );

    const handleSwitchOpenValidationModal = (action?: "edit" | "delete") => () => {
        setOpenValidationModal(() => {
            setHidden(!!action);
            return action;
        });
    };

    const handleUpdateExpensePolicy = async (props: HandleSubmitPropsPasswordValidation) => {
        return dispatch(
            handleUpdateAdminExpensePolicies({
                ...props,
                walletsWithExpensePolicy,
            })
        ).unwrap();
    };

    const handleOnSuccess = () => {
        setOpen(false);
        setOpenValidationModal(undefined);
        setHidden(false);
        dispatch(resetAdminExpensePolicyCreate());
    };

    const handleDeleteExpensePolicy = async (props: HandleSubmitPropsPasswordValidation) => {
        return dispatch(handleDeleteAdminExpensePolicies({ expensePolicyId: id, ...props })).unwrap();
    };

    const handleCloseDrawer = () => {
        setOpen(false);
        dispatch(resetAdminExpensePolicyCreate());
    };

    return (
        <>
            <Drawer
                isHidden={hidden}
                isOpen={open}
                onClose={handleCloseDrawer}
                body={<AdminExpensePolicyEditDrawerBody openValidationModal={handleSwitchOpenValidationModal} />}
                header={{
                    title,
                    subtitle: t<string>("Expense-policy.pay-without-approve"),
                    image: "adjustments-horizontal",
                }}
            />
            <PasswordValidationModal
                header={{
                    title: t(openValidationModal === "edit" ? "CommonUse.modify" : "CommonUse.delete"),
                    icon: openValidationModal === "edit" ? "pencil" : "thrash",
                }}
                setOpen={handleSwitchOpenValidationModal()}
                onSuccess={handleOnSuccess}
                successComponent={<AdminExpensePolicySuccess />}
                open={!!openValidationModal}
                handleOnSubmit={openValidationModal === "edit" ? handleUpdateExpensePolicy : handleDeleteExpensePolicy}
            />
        </>
    );
}
