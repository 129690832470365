import { TransactionContext } from "src/shared/context/transaction";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

export function HistoryWalletDrawerRecovery() {
    const transaction = useContext(TransactionContext);
    const { parents } = transaction;
    const { t } = useTranslation();
    const parentsFiltered = parents.filter((parent) => parent.uuid.trim() !== "");

    return parentsFiltered.length > 0 ? (
        <div className='drawer-content-section no-padding'>
            <Accordion
                noHeaderPadding
                title={`${t("HistoryWalletDrawerRecovery.recovery-history")} (${parentsFiltered.length})`}
                color='white'
            >
                <div className='history-wallet-drawer-recovery-list'>
                    {parentsFiltered.map((parent, index) => (
                        <div key={`recovery-${parent.uuid}`} className='history-wallet-drawer-recovery-item '>
                            <div className='item-title'>
                                <div className='item-title-content'>
                                    <Icon name='retry' />
                                    <Typography
                                        className='fw-bold color-neutral-500'
                                        message={`${t("HistoryWalletDrawerRecovery.recovery")} #${index + 1}`}
                                    />
                                </div>
                            </div>
                            <div className='item-content'>
                                <Typography message={parent.uuid} />
                                <Typography
                                    className='color-neutral-500'
                                    message={computeTimestampToString(parent.createdAt, "DD/MM/YYYY - HH:mm")}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Accordion>
        </div>
    ) : null;
}
