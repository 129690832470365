import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { CreateTaskPanel } from "src/components/CreateTask/Panel";

const CreateTaskPageUnauthorized = () => {
    return <CreateTaskPanel />;
};

export const CreateTaskPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER],
})(CreateTaskPageUnauthorized);
