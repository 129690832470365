import {
    WalletTransactionsStateFilters,
    deleteWalletTransactionReceipts,
    failedRequestWalletTransactions,
    receiveWalletTransactions,
    requestWalletTransactions,
    setWalletTransactionAttachment,
    setWalletTransactionComment,
    setWalletTransactionReceipts,
} from "./slice";
import {
    DeleteFileToTransactionProps,
    RefundtransactionsProps,
    UpdateTransactionCommentProps,
    deleteFileToTransaction,
    refundTransactions,
    requestGetWalletTransactions,
    updateTransactionAttachmentOrReceipts,
    updateTransactionComment,
} from "src/services/transactions/operations";
import { transactionStatusArchived } from "src/shared/const/transactions";
import { formatDateForRequest } from "src/shared/utils/formatDate";

import { createAsyncThunk } from "@reduxjs/toolkit";

const computeFilterToWalletFiltersApi = (filters: WalletTransactionsStateFilters, page: number, pageSize: number) => {
    const { query, startDate, endDate, archived, status, transactionType, tasks } = filters;

    const statusFilter = status?.map((filter) => filter.id) ?? [];

    if (archived) {
        statusFilter?.push(transactionStatusArchived);
    }

    return {
        query: query === "" ? undefined : query,
        minDate: formatDateForRequest(startDate),
        maxDate: formatDateForRequest(endDate),
        page,
        pageSize,
        status: statusFilter,
        taskIds: tasks?.map((filter) => filter.id) ?? [],
        transactionType: transactionType?.map((filter) => parseInt(filter.id)),
    };
};

export const fetchWalletTransactions = createAsyncThunk(
    "fetchWalletTransactions",
    async (
        {
            walletId,
            filters,
            page,
            pageSize,
        }: { walletId: string; filters: WalletTransactionsStateFilters; page: number; pageSize: number },
        { dispatch }
    ) => {
        dispatch(requestWalletTransactions({ walletId }));
        try {
            const transactions = await requestGetWalletTransactions(
                walletId,
                computeFilterToWalletFiltersApi(filters, page, pageSize)
            );
            dispatch(receiveWalletTransactions({ walletId, transactions }));
            return transactions;
        } catch (error) {
            dispatch(failedRequestWalletTransactions({ walletId }));
            return Promise.reject(error);
        }
    }
);

export const handleUpdateWalletTransactionComment = createAsyncThunk(
    "fetchCardTransactions",
    async ({ transactionUuid, walletId, comment }: UpdateTransactionCommentProps, { dispatch }) => {
        try {
            await updateTransactionComment({ walletId, transactionUuid, comment });
            dispatch(setWalletTransactionComment({ walletId, transactionUuid, comment }));
        } catch (error) {
            return error;
        }
    }
);

type HandleUpdateWalletTransactionAttachmentProps = {
    type: string;
    file: File;
    transactionUuid: string;
    uploader: string | null;
    walletId: string;
};

export const handleUpdateWalletTransactionAttachment = createAsyncThunk(
    "handleUpdateWalletTransactionAttachment",
    async (
        { type, file, transactionUuid, walletId, uploader }: HandleUpdateWalletTransactionAttachmentProps,
        { dispatch }
    ) => {
        try {
            const response = await updateTransactionAttachmentOrReceipts({
                walletId,
                transactionUuid,
                type,
                file,
                uploader,
            });
            dispatch(setWalletTransactionAttachment({ file: response.data, transactionUuid, walletId }));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const handleUpdateWalletTransactionReceipts = createAsyncThunk(
    "handleUpdateWalletTransactionAttachment",
    async (
        { type, file, transactionUuid, walletId, uploader }: HandleUpdateWalletTransactionAttachmentProps,
        { dispatch }
    ) => {
        try {
            const response = await updateTransactionAttachmentOrReceipts({
                walletId,
                transactionUuid,
                type,
                file,
                uploader,
            });
            dispatch(setWalletTransactionReceipts({ file: response, walletId, transactionUuid }));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const handleDeleteWalletTransactionReceipt = createAsyncThunk(
    "handleUpdateWalletTransactionAttachment",
    async ({ walletId, fileId, transactionUuid, fileType }: DeleteFileToTransactionProps, { dispatch }) => {
        try {
            const response = await deleteFileToTransaction({
                walletId,
                transactionUuid,
                fileId,
                fileType,
            });
            dispatch(deleteWalletTransactionReceipts({ fileId, transactionUuid, walletId }));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

type HandleRequestCancelTransaction = RefundtransactionsProps & {
    filters: WalletTransactionsStateFilters;
    page: number;
    pageSize: number;
};

export const handleRequestCancelTransaction = createAsyncThunk(
    "handleRequestCancelTransaction",
    async (
        { walletId, reason, uuids, password, twoFactor, page, filters, pageSize }: HandleRequestCancelTransaction,
        { dispatch }
    ) => {
        try {
            const res = await refundTransactions({ walletId, uuids, password, twoFactor, reason });
            dispatch(fetchWalletTransactions({ walletId, filters, page, pageSize }));
            return res;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
);
