import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { ProfileTwoFactorPanel } from "src/components/UserSettings/Security/TwoFactor/Panel";

const TwoFactorUnauthorized = () => {
    return <ProfileTwoFactorPanel />;
};

export const TwoFactorPage = withAuthorization({
    allowedRoles: [CompanyRole.USER, CompanyRole.READ, CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY],
})(TwoFactorUnauthorized);
