import { AdminActivityLogs } from "./types";
import { middlewareRequest } from "src/services/utils/request";

export type RequestMiddlewareAdminActivityLogsParams = {
    userIds?: string[];
    actionSlugs?: string[];
    lastEvaluatedKey?: AdminActivityLogs["lastEvaluatedKey"];
    query?: string;
    date?: Date;
};

export async function requestMiddlewareAdminActivityLogs(params: RequestMiddlewareAdminActivityLogsParams) {
    try {
        return await middlewareRequest<AdminActivityLogs>({ endpoint: "/admin/activities", method: "POST", params });
    } catch (error) {
        return Promise.reject(error);
    }
}
