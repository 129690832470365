import {
    failedRequestTaskToEdit,
    failedRequestTasks,
    receiveTask,
    receiveTaskComments,
    receiveTaskToEdit,
    receiveTasks,
    requestTaskToEdit,
    requestTasks,
} from "./slice";
import {
    createTaskComment,
    fetchTaskComments,
    requestGetTask,
    requestGetTasksList,
} from "src/services/tasks/operations";
import { ApiFilters } from "src/services/types";
import { TaskStatus } from "src/shared/models/Task";

import { createAsyncThunk } from "@reduxjs/toolkit";

export type FetchTasksFilters = ApiFilters & {
    wallets?: string[];
    status?: TaskStatus[];
};

export const fetchTasks = createAsyncThunk(
    "fetchTasks",
    async ({ filters }: { filters?: FetchTasksFilters }, { dispatch }) => {
        dispatch(requestTasks());
        try {
            const tasks = await requestGetTasksList(filters);
            dispatch(receiveTasks({ tasks }));
        } catch (error) {
            dispatch(failedRequestTasks());
            return error;
        }
    }
);

export const fetchTaskToEdit = createAsyncThunk(
    "fetchTasks",
    async ({ taskId }: { taskId: number | string }, { dispatch }) => {
        dispatch(requestTaskToEdit());
        try {
            const task = await requestGetTask(taskId);
            dispatch(receiveTaskToEdit({ task }));
        } catch (error) {
            dispatch(failedRequestTaskToEdit());
            return error;
        }
    }
);

export const fetchTask = createAsyncThunk(
    "fetchTasks",
    async ({ taskId }: { taskId: number | string }, { dispatch }) => {
        try {
            const task = await requestGetTask(taskId);
            dispatch(receiveTask({ task }));
        } catch (error) {
            return error;
        }
    }
);

export const handleCreateTaskComment = createAsyncThunk(
    "handleCreateTaskComment",
    async ({ taskId, content }: { taskId: number; content: string }, { dispatch }) => {
        try {
            await createTaskComment({ taskId, content });
            fetchTaskComments({ taskId }).then((res) => dispatch(receiveTaskComments({ taskId, comments: res.data })));
        } catch (error) {
            return error;
        }
    }
);
