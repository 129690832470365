import { useEffect, useState } from "react";

export function useFileSize(fileUrl?: string | null) {
    const [size, setSize] = useState(0);

    useEffect(() => {
        const fetchSize = async () => {
            if (!fileUrl) {
                return;
            }

            try {
                const response = await fetch(fileUrl, { method: "HEAD" });

                if (response.ok) {
                    const contentLength = response.headers.get("content-length");
                    if (contentLength) {
                        const sizeInBytes = parseInt(contentLength, 10);
                        const sizeInMegaBytes = (sizeInBytes / (1024 * 1024)).toFixed(2);
                        setSize(parseFloat(sizeInMegaBytes));
                    }
                } else {
                    console.error("Error while retrieving file size :", response.status);
                }
            } catch (error) {
                console.error("Error while retrieving file size :", error);
            }
        };

        fetchSize();
    }, [fileUrl]);

    return size;
}
