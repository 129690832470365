import { useEffect, useState } from "react";

import { Trans, useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";
import { ArcGauge } from "src/shared/components/ArcGauge/ArcGauge";

const enum KYB_STATUSES {
    TO_COMPLETE = "to-complete",
    APPROVED = "approved",
    CERTIFIED = "certified",
}

const advantages = {
    "to-complete": ["KybIndicator.platform-access"],
    approved: ["KybIndicator.business-account", "KybIndicator.unlimited-transfers", "KybIndicator.multi-companies"],
    certified: ["KybIndicator.access-to-credit", "KybIndicator.priority-support", "KybIndicator.priority-access"],
};

type GoFurtherWithJulayaProps = {
    currentRole: string;
    handleOpenAccordion: (accordion: string) => void;
};

export const GoFurtherWithJulaya = ({ currentRole, handleOpenAccordion }: GoFurtherWithJulayaProps) => {
    const { t } = useTranslation();
    const [kybStatus, setKybStatus] = useState(KYB_STATUSES.TO_COMPLETE);
    const [opennedAccordion, setOpennedAccordion] = useState(false);

    useEffect(() => {
        switch (currentRole) {
            case "kyb0":
                setKybStatus(KYB_STATUSES.TO_COMPLETE);
                break;
            case "kyb1":
                setKybStatus(KYB_STATUSES.APPROVED);
                break;
            case "kyb2":
                setKybStatus(KYB_STATUSES.CERTIFIED);
                break;
        }
    }, [currentRole]);

    const handleOpennedAccordion = (isOpenned: boolean) => {
        setOpennedAccordion(isOpenned);
    };

    const gaugeKybStatus = () => {
        return kybStatus === KYB_STATUSES.TO_COMPLETE ? 10 : kybStatus === KYB_STATUSES.APPROVED ? 70 : 100;
    };

    return (
        <div className='kyb-indicator-container'>
            <div className='kyb-indicator-header'>
                <Avatar icon='bulb' />
                <Typography message={t("KybIndicator.go-further-with-jul")} className='fw-bold' />
            </div>
            <div className='kyb-indicator-content'>
                <div className={"going-further-container " + kybStatus}>
                    <div className='image-part'>
                        <img src={require(`src/shared/images/kyb/${kybStatus}.png`)} />
                    </div>
                    <div className='first-part'>
                        <div className='text-part'>
                            <Typography message={t("KybIndicator.acutal-file")} />
                            <Typography variant='h5' message={t("KybIndicator." + kybStatus + "-status")} />
                            <div onClick={() => handleOpennedAccordion(true)}>
                                <Typography
                                    message={t("KybIndicator.know-more-about-folders-types")}
                                    className='link'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='kyb-indicator-divider' />
                    <div className='second-part'>
                        <div className='text-part'>
                            <Typography message={t("KybIndicator.your-advantages")} className='fw-bold' />
                            <div className='advantages-list'>
                                {advantages[kybStatus].map((advantage) => {
                                    return (
                                        <div className='advantage-item'>
                                            <Icon name='check-bold' size='sm' />
                                            <Typography message={t(advantage)} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='kyb-indicator-divider' />
                    <div className='third-part'>
                        <ArcGauge
                            value={gaugeKybStatus() - 20}
                            arcValues={["#ffdcfc", "#cbe0ff", "#d1d0ff"]}
                            dashedLine
                            displayPreviousColors={true}
                            message={
                                <div className='gauge-text'>
                                    <Typography message={gaugeKybStatus() + "%"} variant='h6' className='bold' />
                                    <Typography
                                        message={t("KybIndicator.usage-of-julaya")}
                                        className='color-neutral-500'
                                    />
                                </div>
                            }
                        />
                    </div>
                </div>
                <Accordion
                    title={t("KybIndicator.different-types-of-folders")}
                    icon='document'
                    iconColor='neutral-100'
                    iconStatus='neutral'
                    isOpened={opennedAccordion}
                    onOpen={(value: boolean) => handleOpennedAccordion(value)}
                    noHeaderPadding
                >
                    <div className='type-of-folders-container'>
                        <div className='tooltip-sentence'>
                            <Typography message={<Trans i18nKey='KybIndicator.tooltip-sentence' />} />
                            <ToolTip
                                position='left'
                                activation
                                content={<Typography message={t("KybIndicator.tooltip-info")} />}
                            >
                                <Icon name='information-circle' color='neutral' size='sm' />
                            </ToolTip>
                        </div>
                        <div className='advantages-table'>
                            <div className='advantages-list'>
                                <div className='item-list transparent'>
                                    <div className='item row'>
                                        <Icon name='squares' size='sm' />
                                        <Typography message={t("KybIndicator.multi-companies")} />
                                    </div>
                                    <div className='item row'>
                                        <Icon name='arrows-right-left' size='sm' />
                                        <Typography message={t("KybIndicator.unlimited-transfers")} />
                                    </div>
                                    <div className='item row'>
                                        <Icon name='briefcase' size='sm' />
                                        <Typography message={t("KybIndicator.business-account")} />
                                    </div>
                                    <div className='item row'>
                                        <Icon name='bills' size='sm' />
                                        <Typography message={t("KybIndicator.access-to-credit")} />
                                    </div>
                                    <div className='item row'>
                                        <Icon name='phone' size='sm' />
                                        <Typography message={t("KybIndicator.piority-assist")} />
                                    </div>
                                    <div className='item row'>
                                        <Icon name='sparkles' size='sm' />
                                        <Typography message={t("KybIndicator.priority-updates")} />
                                    </div>
                                </div>
                            </div>
                            <div className='advantages-table-item'>
                                <div className='title'>
                                    <Typography variant='h5' message={t("KybIndicator.approved-status")} />
                                    <Typography message={t("KybIndicator.main-features")} className='color-lilas' />
                                </div>
                                <Button
                                    label={t("KybIndicator.access-to-service")}
                                    onClick={() => handleOpenAccordion("approved")}
                                />
                                <div className='item-list blue'>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                    <div className='item'></div>
                                    <div className='item'></div>
                                    <div className='item'></div>
                                </div>
                            </div>
                            <div className='advantages-table-item'>
                                <div className='title'>
                                    <Typography variant='h5' message={t("KybIndicator.certified-status")} />
                                    <Typography
                                        message={t("KybIndicator.all-functionnalities-and-prio")}
                                        className='color-lilas'
                                    />
                                </div>
                                <Button
                                    label={t("KybIndicator.access-to-service")}
                                    onClick={() => handleOpenAccordion("certified")}
                                />
                                <div className='item-list lilas'>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                    <div className='item'>
                                        <Icon name='check-bold' size='sm' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Accordion>
            </div>
        </div>
    );
};
