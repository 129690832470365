import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { ExportTemplatesPanel } from "src/components/Export/Templates/Panel";

const ExportTemplatesPageUnauthorized = () => {
    return <ExportTemplatesPanel />;
};

export const ExportTemplatesPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER, CompanyRole.READ],
})(ExportTemplatesPageUnauthorized);
