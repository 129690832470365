import { logoutResetStore } from "../auth/Actions";
import { DocumentModel } from "src/shared/models/Document";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DocumentState {
    document: DocumentModel | null;
    loading: boolean;
    error: string | null;
    sendDocumentLoading: boolean;
    sendDocumentError: string | null;
    uploadSlug: string | null;
}

const initialState = {
    loading: false,
    document: null,
    error: null,
    sendDocumentLoading: false,
    sendDocumentError: null,
    uploadSlug: null,
} as DocumentState;

export const documentSlice = createSlice({
    name: "documentSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestDocument: (state) => {
            state.loading = true;
        },
        receiveDocument: (state, { payload }: PayloadAction<DocumentModel | null>) => {
            state.loading = false;
            state.document = payload;
        },
        failedRequestDocument: (state) => {
            state.loading = false;
        },
        requestSendDocument: (state, { payload }: PayloadAction<string>) => {
            state.uploadSlug = payload;
            state.error = null;
            state.sendDocumentLoading = true;
        },
        successSendDocument: (state) => {
            state.uploadSlug = null;
            state.sendDocumentError = null;
            state.sendDocumentLoading = false;
        },
        failedSendDocument: (state, action: PayloadAction<{ error: string }>) => {
            const {
                payload: { error },
            } = action;

            state.sendDocumentError = error;
            state.sendDocumentLoading = false;
            state.uploadSlug = null;
        },
    },
});

export const {
    requestDocument,
    receiveDocument,
    failedRequestDocument,
    requestSendDocument,
    successSendDocument,
    failedSendDocument,
} = documentSlice.actions;

export const documentReducer = documentSlice.reducer;
