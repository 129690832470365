import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { useRouterTaskToEdit } from "src/modules/tasks/hooks";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export function SignatureTaskSummaryRecap() {
    const task = useRouterTaskToEdit();
    const { t } = useTranslation();
    const title = task?.title ?? "";
    const description = task?.description ?? "";
    const wallet = task?.Wallet;
    const metadata = task?.metadata;

    const totalWithFees = parseInt(metadata?.sum ?? "0") + (metadata?.fees ?? 0);
    const newBalance = (wallet?.balance ?? 0) - totalWithFees;

    return (
        <div className='edit-task-summary-container'>
            <div className='edit-task-summary-title'>
                <Typography size='lg' message={t("SignatureTaskSummaryRecap.recapitulatif")} className='fw-bold' />
            </div>
            <div className='divider' />
            <div className='edit-task-summary-content'>
                <Typography size='md' message={title} className='fw-bold' />
                {description && (
                    <div className='item-col'>
                        <Typography className='fw-bold' message={t("CommonUse.description")} />
                        <Typography size='xs' message={description} />
                    </div>
                )}
                <div className='item-col'>
                    <Typography message={t("CommonUse.operation-details")} className='fw-bold' />

                    <div className='item-row'>
                        <Typography size='xs' className='fw-bold' message={t("TasksPanel.wallet-debited")} />
                        <Typography size='xs' message={wallet?.label} className='fw-bold' />
                    </div>

                    <div className='item-row '>
                        <Typography size='xs' className='fw-bold' message={t("TasksPanel.recipient")} />
                        <Typography size='xs' message={metadata?.total} className='fw-bold' />
                    </div>

                    <div className='item-divider' />

                    <div className='item-row '>
                        <Typography size='xs' className='fw-bold' message={t("EditTaskPanel.montant-du-transfert")} />
                        <Typography
                            size='xs'
                            message={`${formatMoneyToString({ amount: metadata?.sum ?? 0 })}`}
                            className='fw-bold'
                        />
                    </div>
                    {metadata?.opFees !== 0 ? (
                        <div className='item-row '>
                            <Typography size='xs' className='fw-bold' message={t("TasksPanel.withdrawal-fee")} />
                            <Typography
                                size='xs'
                                message={formatMoneyToString({ amount: metadata?.opFees ?? 0 })}
                                className='fw-bold'
                            />
                        </div>
                    ) : null}

                    {metadata?.fees !== 0 ? (
                        <div className='item-row '>
                            <Typography size='xs' className='fw-bold' message={t("Tasks.julaya-fees")} />
                            <Typography
                                size='xs'
                                message={formatMoneyToString({ amount: metadata?.fees ?? 0 })}
                                className='fw-bold'
                            />
                        </div>
                    ) : null}

                    <div className='item-divider' />

                    <div className='item-row '>
                        <Typography size='xs' className='fw-bold' message={t("Tasks.total-amount")} />
                        <Typography
                            size='xs'
                            message={`${formatMoneyToString({ amount: totalWithFees ?? 0 })}`}
                            className='fw-bold'
                        />
                    </div>
                </div>
            </div>

            <div className='divider' />

            <div className='edit-task-summary-footer'>
                <Typography message={t("Tasks.new-balance")} className='fw-bold' />
                <Typography message={formatMoneyToString({ amount: newBalance })} className='fw-bold color-primary' />
            </div>
        </div>
    );
}
