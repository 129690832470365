import { useMemo } from "react";

import { useAuthUserSelectedCompany } from "src/modules/authCompanies/hooks";

export enum SubscriptionRestrictions {
    RESTRICTIONS_WALLET = "RESTRICTIONS_WALLET",
    RESTRICTIONS_USER = "RESTRICTIONS_USER",
    RESTRICTIONS_EXPENSE_POLICY = "RESTRICTIONS_EXPENSE_POLICY",
    RESTRICTIONS_BENEFICIARY = "RESTRICTIONS_BENEFICIARY",
    RESTRICTIONS_SMS_TRANSFER_BULK = "RESTRICTIONS_SMS_TRANSFER_BULK",
    RESTRICTIONS_SMS_TRANSFER_RAPID = "RESTRICTIONS_SMS_TRANSFER_RAPID",

    ACCESS_ONLY_1_WALLET = "ACCESS_ONLY_1_WALLET",

    LIMIT_EXPORT_PERSONALIZED_30_DAYS = "LIMIT_EXPORT_PERSONALIZED_30_DAYS",
    LIMIT_EXPORT_REGULAR_1_YEAR = "LIMIT_EXPORT_REGULAR_1_YEAR",
    FEATURE_EXPORT_TEMPLATE = "FEATURE_EXPORT_TEMPLATE",
    
    LIMIT_15_TRANSACTION = "LIMIT_15_TRANSACTION",
    FEATURE_LOAN = "FEATURE_LOAN",
    FEATURE_ACTIVITY_LOG = "FEATURE_ACTIVITY_LOG",
}

export function useSubscriptionRestrictions(restriction: SubscriptionRestrictions) {
    const company = useAuthUserSelectedCompany();

    return useMemo(() => {
        if (!company || !company.plan || !Array.isArray(company.plan.acls) || company.plan.acls.length === 0) {
            return false;
        }

        const { plan } = company;

        return plan.acls.includes(restriction);
    }, [company, restriction]);
}
