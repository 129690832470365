import { useNavigate } from "react-router-dom";

import { useMemo } from "react";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    breadcrumbItems: {
        label: string;
        link?: string;
        icon?: IconListType;
        action?: () => void;
        id?: string;
    }[];
};

export function LayoutBreadcrumbStandard({ breadcrumbItems }: Props) {
    const navigate = useNavigate();

    const handleRedirect = (link: string) => () => {
        navigate(link);
    };

    const itemsFiltered = useMemo(() => {
        return breadcrumbItems.filter((item) => item.label.length);
    }, [breadcrumbItems]);

    const breadcrumbItemsLenght = itemsFiltered.length;

    return (
        <div className='breadcrumb-container'>
            {itemsFiltered.map(({ label, link, icon, action, id }, index) => (
                <div className='breadcrumb-item' key={label + "-breadcrumb"}>
                    <div
                        className={`breadcrumb-button ${index + 1 === breadcrumbItemsLenght ? "last-item" : ""} ${
                            link || action ? "" : "no-link"
                        }`}
                        onClick={link ? handleRedirect(link) : action ? action : undefined}
                        id={id}
                    >
                        {icon && <Icon name={icon} size='sm' />}
                        <Typography message={label} className='fw-bold' size='xs' />
                    </div>
                    {index + 1 < breadcrumbItemsLenght && (
                        <div className='icon-chevron'>
                            <Icon name='chevron-right' size='sm' />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}
