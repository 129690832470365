import moment from "moment";
import { LoanModel } from "src/shared/models/Loan";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { StatusIcon } from "src/shared/atoms/Icons/StatusIcons";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type LoanHistoryProps = {
    loanHistory: LoanModel[];
};

export const LoanHistory = ({ loanHistory }: LoanHistoryProps) => {
    const { t } = useTranslation();

    const calculateDisbursementDate = (createdAt: Date, disbursedDate: Date) => {
        const diffInDays = moment(disbursedDate).diff(moment(createdAt), "days");

        const duration = moment.duration(diffInDays, "days");
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();

        let result = "";

        if (years > 0) {
            result += `${years} ${t("CommonUse.year")}${years > 1 ? "s" : ""} `;
        }

        if (months > 0) {
            result += `${months} ${t("CommonUse.month")} `;
        }

        if (days > 0) {
            result += `${days} ${t("CommonUse.day")}${days > 1 ? "s" : ""}`;
        }

        return result;
    };

    return (
        <div className='loan-history-container'>
            <div className='loan-history-header'>
                <Avatar icon='check-circle' />
                <div className='d-flex flex-column w-100'>
                    <Typography message={t("Loans.closed")} className='ml-2 fw-bold' />
                    <Typography
                        message={
                            loanHistory.length === 0 ? t("Loans.none") : `${loanHistory.length} ${t("Loans.contracts")}`
                        }
                        className='ml-2'
                    />
                </div>
                <div className={loanHistory.length === 0 ? "locked-icon" : ""}>
                    <Avatar
                        icon={loanHistory.length === 0 ? "lock" : "chevron-up"}
                        size='sm'
                        backgroundColor='neutral-200'
                        color='neutral'
                    />
                </div>
            </div>
            {loanHistory.length > 0 && (
                <div className='loan-history-content'>
                    {loanHistory.map((loan: LoanModel, index: number) => {
                        return (
                            <div className='loan-history-item' key={index}>
                                <div className='loan-history-item-inline-content'>
                                    <Typography
                                        message={`${formatMoneyToString({ amount: loan.amountToDisburse })}`}
                                        className='fw-bold mt-0'
                                        variant='h6'
                                    />
                                    <StatusIcon status='done-loan' withMessage={true} />
                                </div>
                                <div className='loan-history-item-inline-content'>
                                    <div className='item-inline'>
                                        <Icon name='hashtag' />
                                        <Typography message={loan.offerId} />
                                    </div>
                                    <div className='item-inline'>
                                        <Icon name='calendar' />
                                        <Typography
                                            message={`${t("Loans.paiement-date")} ${calculateDisbursementDate(
                                                loan.createdAt,
                                                loan.disbursementDate
                                            )}`}
                                        />
                                    </div>
                                    <div className='item-inline'>
                                        <Icon name='receipt-percent' />
                                        <Typography message={`1% ${t("Loans.fees-on-transactions")}`} />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
