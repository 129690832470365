import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getState = (state: RootState) => state.billing.billers;

export const getBillingBillers = createSelector(getState, (state) =>
    state.data && state.data.length > 0 ? state.data : []
);
export const getBillingBiller = createSelector(
    getBillingBillers,
    (_state: RootState, { reference }: { reference?: string }) => reference,
    (billers, reference) => (reference ? billers?.find((biller) => biller.reference.includes(reference)) : undefined)
);

export const getBillingBillersTotalCount = createSelector(getState, (state) => state.totalCount);
export const getBillingBillersIsLoading = createSelector(getState, (state) => state.isLoading);
export const getBillingBillersQueryFilter = createSelector(getState, (state) => state.filters.query);
export const getBillingBillersSectorFilter = createSelector(getState, (state) => state.filters.sector);
export const getBillingBillersCategoryFilter = createSelector(getState, (state) => state.filters.category);
export const getBillingBillersCurrentPage = createSelector(getState, (state) => state.currentPage);
export const getBillingBillersPageSize = createSelector(getState, (state) => state.pageSize);
