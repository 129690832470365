import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export function CompaniesAdd() {
    const { t } = useTranslation();

    return (
        <div className='mb-3'>
            <ToolTip
                fullWidth
                activation={true}
                position='bottom'
                content={
                    <>
                        <Typography
                            size='sm'
                            className='fw-bold mt-0'
                            message={t("Companies.add-new-compnay-contact-support")}
                        />
                        <div className='d-flex flex-row'>
                            <Typography size='sm' message={t("Countries.ivory-coast")} />
                            <Typography size='sm' className={"ml-1 color-lilas-900"} message={"25 22 01 86 16"} />
                        </div>

                        <div className='d-flex flex-row'>
                            <Typography size='sm' message={t("Countries.senegal")} />
                            <Typography size='sm' className={"ml-1 color-lilas-900"} message={"77 801 60 00"} />
                        </div>

                        <div className='d-flex flex-row'>
                            <Typography size='sm' message={t("Countries.benin")} />
                            <Typography size='sm' className={"ml-1 color-lilas-900"} message={"63 20 22 22"} />
                        </div>
                    </>
                }
                children={
                    <div className='add-company-container'>
                        <Icon name='office' color='lilas-900' />
                        <Typography
                            message={t("CommonUse.add", { entity: t("Companies.a-company") })}
                            className='d-flex justify-content-center mb-0 fw-bold'
                        />
                    </div>
                }
            />
        </div>
    );
}
