import { Typography } from "../Typography/Typography";

import { InputHTMLAttributes, ReactNode } from "react";

import "./styles.scss";

export type CheckboxProps = {
    label: ReactNode | string;
    checked: boolean;
    onChange?: () => void;
    className?: string;
    testId?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function Checkbox({ label, checked, onChange, className, testId, ...props }: CheckboxProps) {
    const handleChange = () => {
        if (onChange) {
            onChange();
        }
    };

    return (
        <label className={`checkbox-container ${className}`} data-testid={testId}>
            <div className='checkbox'>
                <input type='checkbox' checked={checked} onChange={handleChange} {...props} />
            </div>
            <Typography message={label} />
        </label>
    );
}
