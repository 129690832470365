import { PasswordValidationModal, PasswordValidationProps } from "src/shared/common/Password/Modal";
import { PasswordValidationContext } from "src/shared/context/passwordValidation";

import { PropsWithChildren, useEffect, useState } from "react";

import { getAuthUser } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

export function PasswordValidationWrapper({ children }: PropsWithChildren) {
    const [open, setOpen] = useState(false);
    const [passwordValidationProps, setPasswordValidationProps] = useState<
        Omit<PasswordValidationProps, "open"> | undefined
    >();

    const authUser = useAppSelector((state) => getAuthUser(state));

    const switchOpen = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    const handleOnSubmit = async () => {};

    useEffect(() => {
        if (!authUser) {
            setOpen(false);
            setPasswordValidationProps(undefined);
        }
    }, [authUser]);

    return (
        <PasswordValidationContext.Provider
            value={{ open, setOpen, passwordValidationProps, setPasswordValidationProps }}
        >
            {children}
            <PasswordValidationModal
                {...passwordValidationProps}
                open={open}
                setOpen={passwordValidationProps?.setOpen ?? switchOpen}
                handleOnSubmit={passwordValidationProps?.handleOnSubmit ?? handleOnSubmit}
            />
        </PasswordValidationContext.Provider>
    );
}
