import { BankDisposalFormType, RECHARGEMENT_STEP } from "../Panel";
import { WalletSelectDrawerContainer } from "./Drawer/Container";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";

import { getWalletLoading } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Spinner } from "src/shared/atoms/Spinner/Spinner";

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
    step: RECHARGEMENT_STEP;
    handleSetNextStep: () => void;
};

export const WalletSelectContainer = ({ form, step, handleSetNextStep }: Props) => {
    const isLoading = useAppSelector((state) => getWalletLoading(state));

    return (
        <>
            {isLoading ? (
                <div className='d-flex w-100 h-100 justify-content-center align-items-center py-4'>
                    <Spinner size='lg' />
                </div>
            ) : (
                <WalletSelectDrawerContainer form={form} step={step} handleSetNextStep={handleSetNextStep} />
            )}
        </>
    );
};
