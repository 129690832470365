import { middlewareRequest } from "../../utils/request";
import { BillingBiller, ReturnApiServiceDetails } from "./type";
import { RequestWalletsBeneficiariesApi } from "src/services/wallets/types";
import { AuthCompany, FavoriteBiller } from "src/shared/models/AuthCompany";

export async function requestApiBillingBiller({
    walletId,
    ...params
}: {
    walletId: string | number;
    externalReference: string;
    typeSlug: string;
    serviceSlug: string;
}) {
    try {
        const services = await middlewareRequest<RequestWalletsBeneficiariesApi>({
            endpoint: `/me/companies/services`,
            method: "POST",
            params: {
                walletId,
                ...params,
            },
        });
        const service = services?.[0];

        if (service?.directBillerOptions) {
            return {
                avatar: service.directBillerOptions?.billerInfos.avatar,
                country: service.country.slug,
                name: service.typeTitle,
                reference: service.serviceSelected.externalReference,
                typeSlug: service.typeSlug,
                serviceSlug: service.serviceSelected.serviceSlug,
                sector: service.directBillerOptions.billerInfos.sector,
                billerFields: service.directBillerOptions.billerFields,
                description: service.directBillerOptions?.billerInfos.description,
            };
        }
        return;
    } catch (error) {
        return Promise.reject(error);
    }
}

type RequestApiBillingBillersProps = { page: number; pageSize: number; query?: string };

export async function requestApiBillingBillers(props: RequestApiBillingBillersProps) {
    try {
        return await middlewareRequest<{ data: BillingBiller[]; count: number; currentPage: number }>({
            endpoint: "/billing/billers",
            method: "POST",
            params: props,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestPartnerDetails({
    walletId,
    ...params
}: {
    walletId: number;
    typeSlug: string;
    serviceSlug: string;
    invoiceReference?: string;
    payerReference?: string;
    batchCustomId?: string;
    externalReference: string;
}) {
    try {
        return await middlewareRequest<ReturnApiServiceDetails>({
            endpoint: `/tasks/partner/checks`,
            method: "POST",
            params: {
                ...params,
            },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

type RequestApiFavoriteBillerProps = { billerReference: string | number };

export async function requestAddBillerAsFavorite(props: RequestApiFavoriteBillerProps) {
    try {
        return await middlewareRequest<{ favoriteBiller: FavoriteBiller }>({
            endpoint: "/billing/favorite/billers",
            method: "POST",
            params: props,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestRemoveBillerAsFavorite(props: RequestApiFavoriteBillerProps) {
    try {
        return await middlewareRequest<{ ok: boolean }>({
            endpoint: "/billing/favorite/billers",
            method: "DELETE",
            params: props,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestCompanyFavoriteBillers() {
    try {
        return await middlewareRequest<{ favoriteBillers: AuthCompany["favoriteBillers"] }>({
            endpoint: "/billing/favorite/billers",
            method: "GET",
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
