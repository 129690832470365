import { LoginDisplays } from "./Panel";
import { requestUpdatePassword } from "src/services/auth/operations";
import { LoginContext } from "src/shared/context/login";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { isAuthLoginResponse, sendLoginRequest } from "src/modules/auth/thunkActions";
import { useAppDispatch } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

function checkErrors(password: string, verification: string) {
    const errors: { password?: string; verification?: string; global?: string } = {};

    if (password.trim().length === 0 || password.trim().length < 4) {
        errors.password = "LoginPasswordReset.character-error-password";
    }
    if (verification.trim().length === 0) {
        errors.verification = "LoginPasswordReset.validate-password";
    } else if (password !== verification) {
        errors.global = "LoginPasswordReset.password-no-match";
    }
    return errors;
}

type Props = {
    handleSetFormToDisplay: (switchName: LoginDisplays) => () => void;
};

export function LoginNewPassword({ handleSetFormToDisplay }: Props) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { identifier, password, pinCode, tfaCode } = useContext(LoginContext);

    const [newPassword, setNewPassword] = useState<string>("");
    const [verification, setVerification] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<{ password?: string; verification?: string; global?: string }>({});

    const [passwordCriteriaAllValid, setIsPasswordCriteriaAllValid] = useState(false);
    const [passwordConfirmationIsSame, setPasswordConfirmationIsSame] = useState(false);

    const [passwordCriteria, setPasswordCriteria] = useState({
        minLength: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        specialChar: false,
    });
    const [isValidateButtonDisabled, setIsValidateButtonDisabled] = useState(false);
    const [focusedPasswordForm, setFocusedPasswordForm] = useState(false);

    const numberOfValidCriteria = Object.values(passwordCriteria).filter(Boolean).length;

    useEffect(() => {
        const isDisabled = !passwordConfirmationIsSame || !passwordCriteriaAllValid;

        setIsValidateButtonDisabled(isDisabled);
    }, [passwordConfirmationIsSame, passwordCriteriaAllValid]);

    useEffect(() => {
        setPasswordConfirmationIsSame(newPassword === verification);
    }, [newPassword, verification]);

    useEffect(() => {
        const checks = {
            minLength: newPassword.length >= 8,
            upperCase: /[A-Z]/.test(newPassword),
            lowerCase: /[a-z]/.test(newPassword),
            number: /\d/.test(newPassword),
            specialChar: /[~`!@#$%^&*+=\[\]\\;',/{}|":<>?().-]/.test(newPassword),
        };

        setPasswordCriteria(checks);
    }, [newPassword]);

    useEffect(() => {
        const allValid =
            passwordCriteria.minLength &&
            passwordCriteria.upperCase &&
            passwordCriteria.lowerCase &&
            passwordCriteria.number &&
            passwordCriteria.specialChar;
        setIsPasswordCriteriaAllValid(allValid);
    }, [passwordCriteria]);

    const handleSetFocusedPasswordForm = () => {
        setFocusedPasswordForm(true);
    };

    const handleSetPassword = (value: string) => {
        setNewPassword(value);
    };

    const handleSetVerification = (value: string) => {
        setVerification(value);
    };

    useEffect(() => {
        setPasswordConfirmationIsSame(newPassword === verification);
    }, [newPassword, verification]);

    const handleSendPassword = () => {
        const newErrors = checkErrors(newPassword, verification);

        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
        } else {
            setLoading(true);
            setErrors({});
            requestUpdatePassword({
                password: password || pinCode,
                newPassword: newPassword,
                ...(tfaCode ? { twoFactor: tfaCode } : {}),
            })
                .then(() => {
                    dispatch(
                        sendLoginRequest({ identifier, password: newPassword, ...(tfaCode ? { otp: tfaCode } : {}) })
                    )
                        .unwrap()
                        .then((res) => {
                            if (!isAuthLoginResponse(res)) {
                                handleSetFormToDisplay("twoFactor")();
                            }
                        });
                })
                .catch((error) => {
                    setErrors({ global: error.message });
                })
                .finally(() => setLoading(false));
        }
    };

    const renderItem = (isValid: boolean, typo: string) => {
        return (
            <div className={`item ${!newPassword ? "" : isValid ? "valid" : "invalid"}`}>
                {newPassword.length > 0 && (
                    <Icon name={isValid ? "check-circle-filled" : "x-circle-filled"} size='sm' />
                )}
                <Typography message={t(typo)} />
            </div>
        );
    };

    return (
        <div className='password-change-form'>
            <div className='password-change-title'>
                <Typography variant='h3' message={t("LoginPasswordReset.define-new-password")} className='fw-bold' />
                <Typography
                    className='color-neutral-500 fw-bold'
                    message={t("LoginPasswordReset.almost-over-password")}
                />
            </div>
            <Input
                label={t<string>("PasswordEdition.new-password")}
                placeholder={t("PasswordEdition.fill-your-new-password")}
                type='password'
                onChange={handleSetPassword}
                value={newPassword}
                variant={errors.password || errors.global ? "error" : "primary"}
                underMessage={errors.password ? t<string>(errors.password) : undefined}
                autoComplete='new-password'
                onFocusCapture={handleSetFocusedPasswordForm}
                testId='new-password-input'
            />
            {(newPassword || focusedPasswordForm) && (
                <div className='password-validation-checker'>
                    <Typography
                        message={t("PasswordEdition.password-must-be-at-least")}
                        className={
                            !newPassword
                                ? "color-neutral-500"
                                : passwordCriteriaAllValid
                                ? "color-success"
                                : "color-error"
                        }
                    />
                    {renderItem(passwordCriteria.minLength, "PasswordEdition.height-char")}
                    {renderItem(passwordCriteria.upperCase, "PasswordEdition.one-big-letter")}
                    {renderItem(passwordCriteria.lowerCase, "PasswordEdition.one-small-letter")}
                    {renderItem(passwordCriteria.number, "PasswordEdition.one-number")}
                    {renderItem(passwordCriteria.specialChar, "PasswordEdition.special-char")}
                    {newPassword.length > 0 && (
                        <div className='progress-bar-container'>
                            <div className='progress-bar'>
                                {Array.from({ length: 5 }).map((_, index) => (
                                    <div
                                        key={index}
                                        className={`progress-bar-item ${index < numberOfValidCriteria ? "valid" : ""} ${
                                            numberOfValidCriteria < 2
                                                ? "error"
                                                : numberOfValidCriteria < 4
                                                ? "warning"
                                                : "success"
                                        } ${index === numberOfValidCriteria - 1 ? "last" : ""}`}
                                    />
                                ))}
                            </div>
                            <Typography
                                message={
                                    numberOfValidCriteria < 2
                                        ? t("PasswordEdition.weak")
                                        : numberOfValidCriteria < 4
                                        ? t("PasswordEdition.medium")
                                        : t("PasswordEdition.strong")
                                }
                                className={
                                    numberOfValidCriteria === 0
                                        ? "color-neutral-300"
                                        : numberOfValidCriteria < 2
                                        ? "color-error"
                                        : numberOfValidCriteria < 4
                                        ? "color-warning"
                                        : "color-success"
                                }
                            />
                        </div>
                    )}
                </div>
            )}
            <Input
                label={t<string>("PasswordEdition.confirm-new")}
                placeholder={t("PasswordEdition.confirm-new-password")}
                type='password'
                onChange={handleSetVerification}
                value={verification}
                variant={errors.verification || errors.global ? "error" : "primary"}
                underMessage={
                    errors.verification || errors.global
                        ? t<string>((errors.verification || errors.global) as string)
                        : undefined
                }
                autoComplete='new-password'
                testId='new-password-verification-input'
            />
            <div className='button-part'>
                <Button
                    disabled={loading}
                    variant='tertiary'
                    onClick={handleSetFormToDisplay("login")}
                    label={t("CommonUse.cancel")}
                />
                <Button
                    disabled={isValidateButtonDisabled}
                    loading={loading}
                    onClick={handleSendPassword}
                    label={t("CommonUse.validate")}
                    testId='new-password-continue-button'
                />
            </div>
        </div>
    );
}
