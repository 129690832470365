export const CountryOptions = [
    { id: "ben", label: "Benin" },
    { id: "bur", label: "Burkina Faso" },
    { id: "civ", label: "Cote d'Ivoire" },
    { id: "fra", label: "France" },
    { id: "gbs", label: "Guinea-bissau" },
    { id: "mli", label: "Mali" },
    { id: "nig", label: "Niger" },
    { id: "sen", label: "Senegal" },
    { id: "tog", label: "Togo" },
];
