import { CompanyRole } from "src/shared/models/UserCompany";

import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

const getState = (state: RootState) => state.admin.usersListReducer;
const getUserList = (state: RootState) => state.admin.usersListReducer.usersList;

export const getAdminAccountsList = createSelector(getState, (state) => ({
    usersList: state.usersList,
    loading: state.loading,
    totalCount: state.totalCount,
    currentPage: state.page,
    pageSize: state.pageSize,
}));

export const getAccountsAsOptions = createSelector(getState, (state) =>
    Object.values(state.usersList).map<SelectOption>((user) => ({
        id: user.id.toString(),
        label: `${user.firstname} ${user.lastname}`,
    }))
);

export const getShowUnlinkedUserAlert = createSelector(
    getState,
    (state) => state.usersList.filter((user) => user.status === "unlinked").length
);

export const getAdminAccountsListFiltered = createSelector(
    getUserList,
    (_state: RootState, { roles }: { roles: CompanyRole[] }) => roles,
    (userList, roles) => {
        return userList.filter((user) => roles.includes(user.role));
    }
);
