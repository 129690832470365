import { fetchTaskItems } from "./thunkActions";
import { useParams } from "react-router-dom";

import { useEffect } from "react";

import { getTaskItems } from "./selectors";
import { useAppDispatch, useAppSelector } from "src/store";

type RouteParams = {
    taskId: string;
};

export function useRouterTaskItems() {
    const dispatch = useAppDispatch();
    const { taskId } = useParams<keyof RouteParams>() as RouteParams;

    useEffect(() => {
        if (taskId) {
            dispatch(fetchTaskItems({ taskId }));
        }
    }, [dispatch, taskId]);

    return useAppSelector((state) => getTaskItems(state, { taskId }));
}
