import classNames from "classnames";

import { HtmlHTMLAttributes, ReactNode, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { AnimationType, Delayed } from "src/shared/atoms/Delayed/Delayed";

import "./styles.scss";

type Props = {
    keyName: string;
    isLoading?: boolean;
    items: ReactNode[];
    rowsToShow?: number;
    loadingRowsToshow?: number;
    animation?: AnimationType;
    selectedValue?: number | null;
    extended?: boolean;
    disabled?: boolean;
    variant?: "primary" | "secondary";
} & HtmlHTMLAttributes<HTMLUListElement>;

export function List({
    keyName,
    items,
    isLoading,
    rowsToShow = 3,
    loadingRowsToshow = 3,
    animation,
    selectedValue,
    extended,
    disabled,
    variant = "primary",
    ...props
}: Props) {
    const [isExtended, setIsExtended] = useState(extended ?? false);
    const { t } = useTranslation();
    const handleSwitchIsExtended = () => {
        setIsExtended((oldExtended) => !oldExtended);
    };

    useEffect(() => {
        if (extended) {
            setIsExtended(extended)
        }
    }, [extended])

    const itemsToShow = useMemo(() => {
        return isExtended ? items : items.slice(0, rowsToShow);
    }, [isExtended, items, rowsToShow]);

    return (
        <div className={`list-container ${variant}`}>
            {isLoading ? (
                <ul {...props}>
                    {Array.from({ length: loadingRowsToshow }).map((_, index) => (
                        <li
                            key={index}
                            className='table-placeholder'
                            data-testid='table-placeholder'
                            style={{ animationDelay: (100 * index).toString() + "ms" }}
                        />
                    ))}
                </ul>
            ) : itemsToShow.length > 0 ? (
                <>
                    <ul {...props}>
                        {itemsToShow.map((component, index) => (
                            <Delayed animation={animation} delayTime={index * 100} key={`${keyName}${index}`}>
                                <li
                                    className={classNames({
                                        active: selectedValue === index,
                                        disabled: disabled,
                                    })}
                                    value={index}
                                >
                                    {component}
                                </li>
                            </Delayed>
                        ))}
                    </ul>
                    {items.length > rowsToShow && !isExtended ? (
                        <Delayed
                            className='d-flex justify-content-end'
                            animation={animation ? "fadeInUp" : undefined}
                            delayTime={(itemsToShow.length + 1) * 100}
                        >
                            <div className='button-see-more'>
                                <Button
                                    label={t("List.voir-plus")}
                                    testId='button-see-more'
                                    onClick={handleSwitchIsExtended}
                                />
                            </div>
                        </Delayed>
                    ) : null}
                </>
            ) : null}
        </div>
    );
}
