import { CompanyRole } from "../models/UserCompany";

import { useMemo } from "react";

import { getAuthUserRole } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

type Props = {
    allowedRoles: CompanyRole[];
};

export const useIsUserAuthorized = ({ allowedRoles }: Props) => {
    const userRole = useAppSelector((state) => getAuthUserRole(state));

    const isAuthorized = useMemo(() => allowedRoles.some((allowedRole) => userRole === allowedRole), [userRole]);

    return isAuthorized;
};
