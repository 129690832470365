import classNames from "classnames";
import { SignataireTask, TaskListApiReturnItem } from "src/services/tasks/types";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

const getSignataires = (task: TaskListApiReturnItem) => {
    const {
        TaskSignatures,
        Wallet: { UserWallets, isOrderedSignatures },
    } = task;

    const remainingSignataires = UserWallets?.reduce<{
        [key: string]: {
            User: SignataireTask;
            isSuperSignature: boolean;
            order: number | null;
            userId: number;
        }[];
    }>((res, signataire) => {
        const order = signataire.order;
        const cannotSign = isOrderedSignatures && typeof order === "number" && order <= TaskSignatures.length;

        const hasAlreadySigned = TaskSignatures.some((signature) => signature.userId === signataire.userId);

        if (!hasAlreadySigned && !cannotSign) {
            const index = order ?? TaskSignatures.length + 1;
            if (res[index]) {
                res[index].push(signataire);
            } else {
                res = { ...res, [index]: [signataire] };
            }
        }
        return res;
    }, {});

    return remainingSignataires ?? [];
};

type Props = {
    task: TaskListApiReturnItem;
};

export function TasksDetailsDrawerSectionsSignatories({ task }: Readonly<Props>) {
    const { t } = useTranslation();
    const {
        TaskSignatures,
        status,
        Wallet: { nbSignatures },
    } = task;

    const signataires = useMemo(() => getSignataires(task), [task]);
    const orderedTaskSignatures = useMemo(
        () => [...TaskSignatures].sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
        [TaskSignatures]
    );

    return (
        <div className='task-details-signatures drawer-content-section drawer-content-section'>
            <Accordion
                icon='square-check'
                iconColor='white'
                iconSize='sm'
                preSelectedOpen
                disabled={status === "draft"}
                title={t("Tasks.signatures-ratio", {
                    ratio: nbSignatures
                        ? status === "pending" || status === "to-retry"
                            ? `(${TaskSignatures.length}/${TaskSignatures.length})`
                            : `(${TaskSignatures.length}/${nbSignatures})`
                        : "",
                })}
                color='white'
            >
                <div className='drawer-details-features-body '>
                    <div className='task-signatures-container'>
                        {orderedTaskSignatures.map((signature, index) => (
                            <div key={`signature-${signature.id}-${signature.taskId}`} className='d-flex flex-column'>
                                <div className='d-flex align-items-center'>
                                    <Avatar icon='arrow-right-circle' backgroundColor='lilas-100' size='lg' />
                                    <Typography
                                        className='fw-bold pl-2'
                                        message={t("Tasks.signataire-index", { index: index + 1 })}
                                    />
                                </div>
                                <div className='d-flex'>
                                    <div className='line-container'>
                                        <div className='purple-line' />
                                    </div>
                                    <div className='task-signatures task-details-body-item'>
                                        <div className='task-signature task-content'>
                                            <Avatar
                                                firstname={signature.User.firstname}
                                                lastname={signature.User.lastname}
                                                size='lg'
                                            />
                                        </div>
                                        <div className='d-flex flex-column justify-content-center'>
                                            <Typography
                                                className='fw-bold'
                                                message={`${signature.User.firstname} ${signature.User.lastname}`}
                                            />
                                            <Typography
                                                message={computeTimestampToString(
                                                    signature.createdAt,
                                                    "DD/MM/YY - HH:mm"
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {status === "to-validate" &&
                            Object.entries(signataires).map(([index, signatures]) => (
                                <div key={`remaining-signatures-${index}`} className='d-flex flex-column'>
                                    <div className='d-flex align-items-center'>
                                        <Avatar icon='clock' color='warning' backgroundColor='warning-50' size='lg' />
                                        <div className='d-flex flex-column pl-2'>
                                            <Typography
                                                className='fw-bold color-warning-500'
                                                message={t("Tasks.signataire-index", { index: index })}
                                            />
                                            <Typography
                                                className='color-neutral-500'
                                                message={t("Tasks.waiting-for-signature")}
                                            />
                                        </div>
                                    </div>
                                    {signatures.map((signature) => (
                                        <div className='d-flex' key={`signature-${signature.User.id}`}>
                                            <div className='line-container'>
                                                <div className='warning-line' />
                                            </div>
                                            <div className='task-signatures task-details-body-item'>
                                                <div className='task-signature task-content'>
                                                    <Avatar
                                                        firstname={signature.User.firstname}
                                                        lastname={signature.User.lastname}
                                                        size='lg'
                                                    />
                                                </div>
                                                <div className='d-flex flex-column justify-content-center'>
                                                    <Typography
                                                        className='fw-bold'
                                                        message={`${signature.User.firstname} ${signature.User.lastname}`}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        <div className='d-flex align-items-center'>
                            <Avatar
                                color={status === "to-validate" ? "warning" : "lilas"}
                                icon='arrow-right-circle'
                                backgroundColor={status === "to-validate" ? "warning-50" : "lilas-100"}
                                size='lg'
                            />
                            <Typography
                                className={classNames("fw-bold pl-2", {
                                    "color-warning-500": status === "to-validate",
                                })}
                                message={t("Tasks.send-payment")}
                            />
                        </div>
                    </div>
                </div>
            </Accordion>
        </div>
    );
}
