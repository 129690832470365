import { AdminBeneficiaryResponse } from "src/services/admin/types";
import { BeneficiaryModel } from "src/shared/models/Beneficiary";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

interface AdminBeneficiariesState {
    adminBeneficiaries: BeneficiaryModel[];
    count: number;
    loading: boolean;
    page: number;
    totalPage: number;
    pageSize: number;
    integrateBeneficiaryLoading: boolean;
    integrateBeneficiaryError: string | null;
    integratedBeneficiaryResponse: boolean | null;
    deleteBeneficiaryLoading: boolean;
    deleteBeneficiaryError: string | null;
    deleteBeneficiaryResponse: boolean | null;
}

const initialState = {
    adminBeneficiaries: [],
    count: 0,
    loading: false,
    page: 1,
    totalPage: 0,
    pageSize: 20,
    integrateBeneficiaryLoading: false,
    integrateBeneficiaryError: null,
    integratedBeneficiaryResponse: null,
    deleteBeneficiaryLoading: false,
    deleteBeneficiaryError: null,
    deleteBeneficiaryResponse: null,
} as AdminBeneficiariesState;

export const adminBeneficiariesSlice = createSlice({
    name: "adminBeneficiariesSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestAdminBeneficiaries: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        receiveAdminBeneficiaries: (state, { payload }: PayloadAction<AdminBeneficiaryResponse>) => {
            const { count, data } = payload;

            return {
                ...state,
                adminBeneficiaries: data,
                loading: false,
                count: count,
            };
        },
        failedRequestAdminBeneficiaries: (state) => {
            return {
                ...state,
                loading: false,
            };
        },
        requestPostAdminBeneficiaries: (state) => {
            return {
                ...state,
                integrateBeneficiaryError: null,
                integrateBeneficiaryLoading: true,
            };
        },
        receivePostAdminBeneficiaries: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                integratedBeneficiaryResponse: payload,
                integrateBeneficiaryLoading: false,
                integrateBeneficiaryError: null,
            };
        },
        failedPostAdminBeneficiaries: (state, action: PayloadAction<{ message: string; status: number }>) => {
            const error = action.payload.message;
            return {
                ...state,
                integrateBeneficiaryLoading: false,
                integrateBeneficiaryError: error,
            };
        },
        clearBeneficiaryIntegration: (state) => {
            return {
                ...state,
                integrateBeneficiaryLoading: false,
                integrateBeneficiaryError: null,
                integratedBeneficiaryResponse: null,
            };
        },
        requestDeleteAdminBeneficiaries: (state) => {
            return {
                ...state,
                deleteBeneficiaryLoading: true,
                deleteBeneficiaryError: null,
            };
        },
        receiveDeleteAdminBeneficiary: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                deleteBeneficiaryLoading: false,
                deleteBeneficiaryError: null,
                deleteBeneficiaryResponse: payload,
            };
        },
        failedDeleteAdminBeneficiary: (state, action: PayloadAction<{ message: string; status: number }>) => {
            const error = action.payload.message;

            return {
                ...state,
                deleteBeneficiaryLoading: false,
                deleteBeneficiaryError: error,
            };
        },
        clearBeneficiaryDeleteIntegration: (state) => {
            state.deleteBeneficiaryLoading = false;
            state.deleteBeneficiaryError = null;
            state.deleteBeneficiaryResponse = false;
        },
        setAdminBeneficiaryPageSize: (state, { payload }: PayloadAction<{ pageSize: number }>) => {
            const { pageSize } = payload;

            return {
                ...state,
                pageSize,
                page: 1,
            };
        },
        setAdminBeneficiaryPage: (state, { payload }: PayloadAction<{ page: number }>) => {
            const { page } = payload;

            return {
                ...state,
                page,
            };
        },
    },
});

export const {
    requestAdminBeneficiaries,
    receiveAdminBeneficiaries,
    failedRequestAdminBeneficiaries,
    requestPostAdminBeneficiaries,
    receivePostAdminBeneficiaries,
    failedPostAdminBeneficiaries,
    clearBeneficiaryIntegration,
    requestDeleteAdminBeneficiaries,
    receiveDeleteAdminBeneficiary,
    failedDeleteAdminBeneficiary,
    clearBeneficiaryDeleteIntegration,
    setAdminBeneficiaryPageSize,
    setAdminBeneficiaryPage,
} = adminBeneficiariesSlice.actions;
