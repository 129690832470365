import { AdminWalletForm } from "../Form/WalletForm";
import { useParams } from "react-router-dom";
import { requestMiddlewareAdminWalletById } from "src/services/admin/operations";
import { AdminWalletModel } from "src/shared/models/Wallet";

import { useEffect, useState } from "react";

import { Spinner } from "src/shared/atoms/Spinner/Spinner";

export const AdminWalletEditPanel = () => {
    const { walletId } = useParams<{ walletId: string }>();
    const [wallet, setWallet] = useState<AdminWalletModel>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (walletId) {
            setLoading(true);
            requestMiddlewareAdminWalletById(parseInt(walletId))
                .then((response) => {
                    setWallet(response);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [walletId]);

    return (
        <>
            {loading ? (
                <div className='mt-4 d-flex justify-content-center'>
                    <Spinner size='xl' />
                </div>
            ) : (
                <AdminWalletForm wallet={wallet} />
            )}
        </>
    );
};
