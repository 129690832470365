import { TopUpDrawerBody } from "./Body";
import { DrawerContext } from "src/wrapper/context";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

import "./styles.scss";

export function TopUpDrawer() {
    const { t } = useTranslation();
    const { open, setOpen } = useContext(DrawerContext);

    const handleCloseDrawer = () => {
        setOpen(undefined);
    };

    return (
        <Drawer
            isOpen={open === "topUp"}
            body={<TopUpDrawerBody />}
            header={{ title: t("TopUp.top-up-method"), image: "wallet" }}
            onClose={handleCloseDrawer}
        />
    );
}
