import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";

type DrawerHeaderFormProps = {
    formTitle: string;
    isFormValid: boolean;
};

export const DrawerHeaderForm = ({ formTitle, isFormValid }: DrawerHeaderFormProps) => {
    const { t } = useTranslation();

    return (
        <div className='drawer-content-section-header'>
            <Typography className='fw-bold' message={formTitle} />
            <Typography
                className={`${!isFormValid ? "color-warning" : "color-success"} fw-bold`}
                message={!isFormValid ? t("CommonUse.to-complete") : t("CommonUse.done")}
            />
        </div>
    );
};
