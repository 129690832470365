import "./Note.scss";

import { ReactNode, isValidElement } from "react";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";

export type NoteColors = "primary" | "success" | "error" | "warning";
export type NoteContent = {
    title: string;
    value?: string | ReactNode | undefined | null;
};

type NoteProps = {
    title: string;
    content: NoteContent[];
    icon?: IconListType;
    isVertical?: boolean;
    color?: NoteColors;
};

export const Note = ({ title, icon, content, isVertical = false, color = "primary" }: NoteProps) => {
    const renderNoteContent = (content: string | ReactNode | undefined) => {
        if (typeof content === "string") {
            return <Typography message={content} className='fw-bold' />;
        } else if (isValidElement(content)) {
            return content;
        }
        return <Typography message='-' className='fw-bold' />;
    };

    return (
        <div className={`note-container container-${color}`} data-testid='note-container'>
            <div className='note-header'>
                {icon && <Icon name={icon} data-testid={`icon-${icon}`} />}
                <Typography message={title} className='fw-bold' />
            </div>
            <div className='note-content'>
                {content.map((item, index) => {
                    return (
                        <div
                            key={item.title}
                            className={`note-item ${isVertical ? "vertical" : ""}`}
                            data-testid={`note-content-item-${index}`}
                        >
                            <div className='note-item-title'>
                                <Typography message={item.title} className='color-neutral-500 fw-bold' />
                            </div>
                            <div className='note-item-value'>{renderNoteContent(item.value)}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
