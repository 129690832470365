import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { TasksPanel } from "src/components/Tasks/Panel";

const TasksPageUnauthorized = () => {
    return <TasksPanel />;
};

export const TasksPage = withAuthorization({ allowedRoles: [CompanyRole.USER, CompanyRole.ADMIN] })(
    TasksPageUnauthorized
);
