import { switchBillingTransactionArchivedFilter } from "./slice";
import { fetchBillingTransactions } from "./thunkActions";
import { useLocation } from "react-router-dom";
import {
    refundTransactions,
    requestArchiveTransaction,
    requestRetryTransaction,
} from "src/services/transactions/operations";
import { WalletApi } from "src/services/wallets/types";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
    getBillingTransaction,
    getBillingTransactionFilters,
    getBillingTransactionsCurrentPage,
    getBillingTransactionsShowPageSize,
} from "./selectors";
import { BillReferenceFields } from "src/modules/transactions/thunkActions";
import { getSendWallets } from "src/modules/wallets/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { FACTURATION_TRANSFERT_STEPS } from "src/components/Billing/PayBill/Payment/Panel";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

type Props = {
    walletId: string;
    selectedTransactionUuid: string | null;
    onSuccess: () => void;
};

export const useTransactionStatusChange = ({ walletId, selectedTransactionUuid, onSuccess }: Props) => {
    const dispatch = useAppDispatch();

    const [actionDialog, setActionDialog] = useState<
        { action: "archive" | "cancel" | "retry"; data: { reason?: SelectOption } | undefined } | undefined
    >();
    const [passwordModalError, setPasswordModalError] = useState(null);

    const transaction = useAppSelector((state) => getBillingTransaction(state, { uuid: selectedTransactionUuid }));
    const filters = useAppSelector((state) => getBillingTransactionFilters(state));
    const page = useAppSelector((state) => getBillingTransactionsCurrentPage(state) ?? 1);
    const showPageSize = useAppSelector((state) => getBillingTransactionsShowPageSize(state) ?? 10);

    const handleCloseDialog = () => {
        setActionDialog(undefined);
    };

    const handleConfirm = async (props: HandleSubmitPropsPasswordValidation) => {
        setPasswordModalError(null);

        if (actionDialog && transaction) {
            const { uuid } = transaction;
            const { action, data } = actionDialog;

            if (uuid) {
                switch (action) {
                    case "archive": {
                        await requestArchiveTransaction({
                            uuid,
                            walletId,
                            ...props,
                        })
                            .then((res) => {
                                if (res.ok) {
                                    onSuccess();
                                    handleCloseDialog();
                                    dispatch(switchBillingTransactionArchivedFilter());
                                    dispatch(
                                        fetchBillingTransactions({
                                            filters: { ...filters, archived: true },
                                            page,
                                            pageSize: showPageSize,
                                        })
                                    );
                                }
                            })
                            .catch((error) => setPasswordModalError(error.message));
                        break;
                    }
                    case "cancel": {
                        if (data?.reason) {
                            await refundTransactions({
                                walletId,
                                uuids: [uuid],
                                reason: data.reason.id,
                                ...props,
                            })
                                .then(() => {
                                    onSuccess();
                                    handleCloseDialog();
                                    dispatch(fetchBillingTransactions({ filters, page, pageSize: showPageSize }));
                                })
                                .catch((error) => setPasswordModalError(error.message));
                        }
                        break;
                    }
                    case "retry": {
                        await requestRetryTransaction({ walletId, uuids: [uuid], ...props })
                            .then(() => {
                                onSuccess();
                                handleCloseDialog();
                                dispatch(fetchBillingTransactions({ filters, page, pageSize: showPageSize }));
                            })
                            .catch((error) => setPasswordModalError(error.message));
                    }
                }
            }
        }
        return Promise.reject();
    };

    return { passwordModalError, setPasswordModalError, actionDialog, setActionDialog, handleConfirm };
};

type FacturationTransfertState = {
    setAmount: Dispatch<SetStateAction<number>>;
    setComment: Dispatch<SetStateAction<string>>;
    setSelectedDebitWallet: Dispatch<SetStateAction<WalletApi | null>>;
    setStep: Dispatch<SetStateAction<FACTURATION_TRANSFERT_STEPS>>;
};

export type FacturationTransfertLocationState =
    | ({
          amount: number;
          selectedDebitAccountId: number;
          comment: string;
      } & BillReferenceFields)
    | undefined;

export function usePopulateFacturationState({
    setAmount,
    setComment,
    setSelectedDebitWallet,
    setStep,
}: FacturationTransfertState) {
    const [stateFilled, setStateFilled] = useState(false);

    const providedState = useLocation().state as FacturationTransfertLocationState;

    const wallets = useAppSelector((state) => getSendWallets(state));

    useEffect(() => {
        if (!stateFilled) {
            const wallet = Object.values(wallets).find((wallet) => wallet.id === providedState?.selectedDebitAccountId);

            if (providedState && wallet) {
                setAmount(providedState.amount);
                setComment(providedState.comment);
                setSelectedDebitWallet(wallet);
                setStep(FACTURATION_TRANSFERT_STEPS.AMOUNT);
                setStateFilled(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providedState, wallets]);
}
