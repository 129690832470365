import classnames from "classnames";
import { TransactionApi } from "src/services/transactions/types";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

type Props = {
    amount: TransactionApi["amount"];
    TransactionType: TransactionApi["TransactionType"];
    status: TransactionApi["status"];
    strikeThroughText?: boolean;
};

export const HistoryInfoAmount = ({ amount, TransactionType, status, strikeThroughText = false }: Props) => {
    const sign = TransactionType.way === "out" ? "-" : "+";

    return (
        <span
            className={classnames("fw-bold sm", {
                "strike-through-text text-muted":
                    ["archived", "failed", "refunded"].includes(status ?? "") && strikeThroughText,
                "color-neutral": status === "waiting",
                "color-error": status === "done" && TransactionType.way === "out",
                "color-success": status === "done" && TransactionType.way === "in",
            })}
        >
            {sign}
            {amount ? `${formatMoneyToString({amount})}` : null}
        </span>
    );
};
