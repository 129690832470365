import { BillingTopBillersFavoriteItem } from "./Item";

import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { getAuthCompaniesLoading } from "src/modules/authCompanies/selectors";
import { getCompanyFavoriteBillers } from "src/modules/authCompanies/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Carousel } from "src/shared/atoms/Carousel/Carousel";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";

import "../styles.scss";

const emptyListImg = require("src/shared/images/empty/empty-favorites.png");
const skeletonArray = [...Array(4).keys()];

export const BillingTopBillersFavoritesCarousel = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const company = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state));
    const billerLoading = useAppSelector((state) => getAuthCompaniesLoading(state));

    const billers = useMemo(
        () =>
            company?.favoriteBillers
                ?.map(({ Biller: biller }) => <BillingTopBillersFavoriteItem key={biller?.reference} biller={biller} />)
                .filter((element) => element) ?? [],
        [company?.favoriteBillers]
    );

    useEffect(() => {
        dispatch(getCompanyFavoriteBillers());
    }, [dispatch]);

    return (
        <div className='mt-3'>
            {billerLoading && !company?.favoriteBillers ? (
                skeletonArray.map((placeholder, index) => (
                    <div
                        key={`skeleton-${placeholder}`}
                        style={{ animationDelay: (100 * index).toString() + "ms" }}
                        className='skeleton'
                    />
                ))
            ) : (
                <Carousel
                    scrollPage
                    header={
                        <Typography variant='h6' className='fw-bold' message={t("Billing.your-favorite-billers")} />
                    }
                    items={billers}
                    empty={
                        !billers.length ? (
                            <div className='favorite-billers-empty'>
                                <Empty
                                    image={emptyListImg}
                                    title={t("Billing.no-billers-in-favorite")}
                                    description={t<string>("Billing.no-billers-in-favorite-description")}
                                />
                            </div>
                        ) : undefined
                    }
                />
            )}
        </div>
    );
};
