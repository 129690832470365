import { AdminExpensePolicyCreateDrawerAmount } from "./Sections/Amount";
import { AdminExpensePolicyCreateDrawerBeneficiary } from "./Sections/Beneficiary";
import { AdminExpensePolicyCreateDrawerGeneral } from "./Sections/General";
import { AdminExpensePolicyCreateDrawerUsers } from "./Sections/Users";
import { AdminExpensePolicyCreateDrawerWallets } from "./Sections/Wallets";
import classNames from "classnames";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getAdminExpensePoliciesCreateLimitPerOperation,
    getAdminExpensePoliciesCreateLimitPerUnitOfTime,
    getAdminExpensePoliciesCreateTitle,
    getAdminExpensePoliciesCreateUnitOfTime,
} from "src/modules/admin/expensePolicies/selectors";
import { useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export function AdminExpensePolicyCreateDrawerBody() {
    const { t } = useTranslation();

    const [step, setStep] = useState(0);

    const limitPerOperation = useAppSelector((state) => getAdminExpensePoliciesCreateLimitPerOperation(state));
    const limitPerUnitOfTime = useAppSelector((state) => getAdminExpensePoliciesCreateLimitPerUnitOfTime(state));
    const unitOfTime = useAppSelector((state) => getAdminExpensePoliciesCreateUnitOfTime(state));
    const title = useAppSelector((state) => getAdminExpensePoliciesCreateTitle(state));

    const handleSetStep = (newStep: number) => () => {
        setStep(newStep);
    };

    const toComplete = !limitPerUnitOfTime || !limitPerOperation || !unitOfTime || !title;

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Expense-policy.rule-parameters")} />
                <Typography
                    message={t(toComplete ? "CommonUse.to-complete" : "CommonUse.done")}
                    className={classNames("fw-bold", {
                        "color-warning-500": toComplete,
                        "color-success-500": !toComplete,
                    })}
                />
            </div>
            <div className='expense-policy-parameters'>
                <AdminExpensePolicyCreateDrawerWallets step={step} handleSetStep={handleSetStep} />
                <AdminExpensePolicyCreateDrawerUsers step={step} handleSetStep={handleSetStep} />
                <AdminExpensePolicyCreateDrawerAmount step={step} handleSetStep={handleSetStep} />
                <AdminExpensePolicyCreateDrawerBeneficiary step={step} handleSetStep={handleSetStep} />
                <AdminExpensePolicyCreateDrawerGeneral step={step} handleSetStep={handleSetStep} />
            </div>
        </div>
    );
}
