import { AdminWalletParams } from "src/services/admin/types";

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useDebounce } from "src/shared/hooks/useDebounce";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Chip } from "src/shared/components/Chip/Chip";

type Props = {
    archiveLabel: string;
    filters: AdminWalletParams;
    setFilters: Dispatch<SetStateAction<AdminWalletParams>>;
};

export function AdminWalletsFilters({ archiveLabel, filters, setFilters }: Props) {
    const { t } = useTranslation();
    const [query, setQuery] = useState("");
    const [archived, setArchived] = useState(false);
    const [closeSelect, setCloseSelect] = useState(false);

    const debouncedQuery = useDebounce(query, 700);

    const haveFieldsChanged = useMemo(() => {
        if (!filters) return false;

        return filters.archived !== archived;
    }, [filters, archived]);

    useEffect(() => {
        setFilters((oldFilters) => {
            return debouncedQuery !== oldFilters.query
                ? {
                      ...oldFilters,
                      page: 1,
                      query: debouncedQuery === "" ? "" : debouncedQuery,
                  }
                : oldFilters;
        });
    }, [debouncedQuery, setFilters]);

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    const handleSetArchived = () => {
        setArchived((oldArchived) => !oldArchived);
    };

    const handleSetQueryFilter = useCallback((newQuery: string) => {
        setQuery(newQuery);
    }, []);

    const handleValidateFilters = () => {
        setFilters(() => {
            return {
                query: debouncedQuery === "" ? "" : debouncedQuery,
                archived,
                page: 1,
                pageSize: 20,
            };
        });
        setCloseSelect(true);
    };

    const handleFilterReinit = useCallback(() => {
        setArchived(false);
        setQuery("");
        setFilters({
            ...filters,
            archived: false,
            query: "",
            page: 1,
        });
        setCloseSelect(true);
    }, [setFilters, filters]);

    const handleReinitDropdownFilter = () => {
        setArchived(!!filters?.archived);
    };

    const handleResetArchiveFilter = () => {
        setArchived(false);
        setFilters({
            ...filters,
            page: 1,
            archived: false,
        });
    };

    const renderChip = () => {
        return archived ? (
            <Chip handleDelete={handleResetArchiveFilter} className='fw-lighter' message={archiveLabel} />
        ) : (
            <Typography message={t("CommonUse.filters")} />
        );
    };

    return (
        <div className='filter-history-container d-flex align-items-center'>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={handleSetQueryFilter}
                value={query}
                className='search-input'
            />
            <div className='filters-select ml-2'>
                <Select
                    color='white'
                    forceClose={closeSelect}
                    onBlur={handleReinitDropdownFilter}
                    content={{
                        header: {
                            component: renderChip(),
                            icon: "adjustments-horizontal",
                            disabled: false,
                        },
                        dropDownContent: {
                            header: {
                                title: <Typography message={t("CommonUse.filters")} />,
                                label: (
                                    <Button
                                        variant='ghost'
                                        color='lilas'
                                        onClick={handleFilterReinit}
                                        label={<Typography message={t("AdminWalletsFilters.reinitialiser")} />}
                                    />
                                ),
                            },
                            body: (
                                <div className='body-content'>
                                    <Button
                                        icon={"archive"}
                                        label={archiveLabel}
                                        onClick={handleSetArchived}
                                        variant={archived ? "primary" : "outline"}
                                    />
                                </div>
                            ),
                            footer: (
                                <div className='footer-content'>
                                    <Button
                                        className='background-neutral-200 footer-btn'
                                        variant='tertiary'
                                        onClick={handleFilterReinit}
                                        label={t("CommonUse.cancel")}
                                    />
                                    <Button
                                        className='footer-btn'
                                        variant='primary'
                                        onClick={handleValidateFilters}
                                        label={t("CommonUse.validate")}
                                        disabled={!haveFieldsChanged}
                                    />
                                </div>
                            ),
                        },
                    }}
                />
            </div>
        </div>
    );
}
