import { HistoryWalletDrawerCancel } from "./Sections/Cancel";
import { HistoryWalletDrawerComment } from "./Sections/Comment";
import { HistoryWalletDrawerCredentials } from "./Sections/Credentials";
import { HistoryWalletDrawerDetails } from "./Sections/Details";
import { HistoryWalletDrawerRecovery } from "./Sections/Recovery";
import { HistoryWalletDrawerRetry } from "./Sections/Retry";
import { HistoryWalletDrawerSignatures } from "./Sections/Signatures";
import { HistoryWalletDrawerStatus } from "./Sections/Status";
import { HistoryWalletDrawerTopUpStatus } from "./Sections/TopUpStatus";
import { HistoryWalletDrawerTopupRetry } from "./Sections/TopupRetry";

import { Dispatch, SetStateAction } from "react";

import { TransactionDrawerModalActions } from "src/modules/walletsTransactions/hooks";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export type HistoryWalletDrawerBodyProps = {
    walletId: string;
    handleOpenPasswordDialog: Dispatch<
        SetStateAction<
            | {
                  action: TransactionDrawerModalActions;
                  data:
                      | {
                            reason?: SelectOption;
                        }
                      | undefined;
              }
            | undefined
        >
    >;
};

export function HistoryWalletDrawerBody({ walletId, handleOpenPasswordDialog }: HistoryWalletDrawerBodyProps) {
    return (
        <div className='history-wallet-drawer-body'>
            <HistoryWalletDrawerStatus />
            <HistoryWalletDrawerCancel walletId={walletId} handleOpenPasswordDialog={handleOpenPasswordDialog} />
            <HistoryWalletDrawerRetry handleOpenPasswordDialog={handleOpenPasswordDialog} />
            <HistoryWalletDrawerTopupRetry handleOpenPasswordDialog={handleOpenPasswordDialog} />
            <HistoryWalletDrawerTopUpStatus />
            <HistoryWalletDrawerDetails />
            <HistoryWalletDrawerComment walletId={walletId} />
            <HistoryWalletDrawerSignatures />
            <HistoryWalletDrawerRecovery />
            {walletId && <HistoryWalletDrawerCredentials walletId={walletId} />}
        </div>
    );
}
