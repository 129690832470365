import {
    failedIntegrateAdminWallets,
    failedRequestAdminWallets,
    receiveAdminWallets,
    receiveIntegrateAdminWallet,
    requestAdminWallets,
    requestIntegrateAdminWallet,
} from "./slice";
import {
    requestMiddlewareAdminWallets,
    requestPostCreateAdminWallet,
    requestUpdateAdminWallet,
} from "src/services/admin/operations";
import { AdminWalletCreateParams, AdminWalletParams, AdminWalletUpdateParams } from "src/services/admin/types";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAdminWallets = createAsyncThunk(
    "fetchAdminWallets",
    async (params: AdminWalletParams, { dispatch }) => {
        dispatch(requestAdminWallets());
        try {
            const response = await requestMiddlewareAdminWallets(params);
            dispatch(receiveAdminWallets(response));
            return response;
        } catch (error: any) {
            dispatch(failedRequestAdminWallets(error));
            return Promise.reject(error);
        }
    }
);

export const handleCreateAdminWallet = createAsyncThunk(
    "handleCreateAdminWallet",
    async (params: AdminWalletCreateParams, { dispatch }) => {
        dispatch(requestIntegrateAdminWallet());
        try {
            const response = await requestPostCreateAdminWallet(params);
            dispatch(receiveIntegrateAdminWallet(response));
            return response;
        } catch (error: any) {
            dispatch(failedIntegrateAdminWallets(error));
            return Promise.reject(error);
        }
    }
);

export const handleUpdateAdminWallet = createAsyncThunk(
    "handleUpdateAdminWallet",
    async ({ walletId, params }: { walletId: number; params: AdminWalletUpdateParams }, { dispatch }) => {
        dispatch(requestIntegrateAdminWallet());
        try {
            const response = await requestUpdateAdminWallet(walletId, params);
            dispatch(receiveIntegrateAdminWallet(response));
            return response;
        } catch (error: any) {
            dispatch(failedRequestAdminWallets(error));
            return Promise.reject(error);
        }
    }
);
