import { useTranslation } from "react-i18next";

import { Input } from "src/shared/atoms/Inputs/Input";

type AdminBeneficiariesFiltersProps = {
    query: string;
    setQuery: (query: string) => void;
};

export function AdminBeneficiariesFilters({ query, setQuery }: AdminBeneficiariesFiltersProps) {
    const { t } = useTranslation();

    return (
        <div className='filter-history-container d-flex align-items-center'>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={setQuery}
                value={query}
                className='search-input'
            />
        </div>
    );
}
