import { InformationsParams } from "../CreateDrawer/InformationsForm";
import { ExternalAccountForm } from "../utils";
import { AdminBeneficiaryDrawerSectionBeneficiaries } from "./Beneficiaries";
import { AdminBeneficiaryDrawerSectionInfo } from "./Information";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { BeneficiaryModel } from "src/shared/models/Beneficiary";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { clearBeneficiaryIntegration } from "../../../../modules/admin/adminBeneficiaries/slice";
import { getAdminBeneficiaries } from "src/modules/admin/adminBeneficiaries/selectors";
import {
    handleDeleteAdminBeneficiary,
    handleUpdateAdminBeneficiary,
} from "src/modules/admin/adminBeneficiaries/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const successImage = require("src/shared/images/user/success.png");

type DetailsBeneficiaryDrawerContainerProps = {
    isOpen: boolean;
    handleCloseDrawer: (refresh?: boolean, beneficiary?: BeneficiaryModel) => void;
    selectedBeneficiary: BeneficiaryModel | null;
    isBulk?: boolean;
};

export type UpdateBeneficiaryPayload = InformationsParams & {
    id: number;
    externalAccounts: ExternalAccountForm[];
};

export const DetailsBeneficiaryDrawerContainer = ({
    isOpen,
    selectedBeneficiary,
    handleCloseDrawer,
    isBulk = false,
}: DetailsBeneficiaryDrawerContainerProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [externalAccounts, setExternalAccounts] = useState<ExternalAccountForm[]>(
        selectedBeneficiary?.externalAccounts || []
    );

    const [informations, setInformations] = useState<InformationsParams | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [info, setInfo] = useState("");
    const [errors, setErrors] = useState<{ info?: boolean }>();
    const [openPasswordValidationModalDelete, setOpenPasswordValidationModalDelete] = useState(false);
    const [openPasswordValidationModalEdit, setOpenPasswordValidationModalEdit] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const { integratedBeneficiaryResponse, integrateBeneficiaryLoading, integrateBeneficiaryError } =
        useAppSelector(getAdminBeneficiaries);

    useEffect(() => {
        setExternalAccounts(selectedBeneficiary?.externalAccounts || []);
    }, [selectedBeneficiary]);

    useEffect(() => {
        if (integratedBeneficiaryResponse && !integrateBeneficiaryLoading && !integrateBeneficiaryError) {
            dispatch(clearBeneficiaryIntegration());
            handleCloseDrawer(true);
        }
    }, [integratedBeneficiaryResponse, integrateBeneficiaryLoading, integrateBeneficiaryError]);

    useEffect(() => {
        if (integratedBeneficiaryResponse) {
            handleCloseDrawer(true);
            setShowSuccess(true);
        }
    }, [integratedBeneficiaryResponse]);

    const validateForms = async (password?: string, twoFactor?: string | null) => {
        if (!informations) return;

        const payload = {
            email: informations.email,
            firstname: informations.firstname,
            lastname: informations.lastname,
            label: informations.label,
            externalAccounts,
        };

        if (isBulk) {
            handleCloseDrawer(false, { ...selectedBeneficiary, ...payload } as BeneficiaryModel);
            return;
        }

        if (selectedBeneficiary?.id && password && twoFactor !== undefined) {
            await dispatch(
                handleUpdateAdminBeneficiary({
                    payload: { id: selectedBeneficiary?.id, ...payload },
                    password,
                    twoFactor,
                })
            );
        }
    };

    const handleSwitchDelete = () => {
        setIsDeleting(!isDeleting);
    };

    const handleDelete = async (password: string, twoFactor: string | null) => {
        let mErrors: { info?: boolean } = {};
        if (info !== "SUPPRIMER") {
            mErrors.info = true;
        }

        if (Object.keys(mErrors).length !== 0) {
            setErrors(mErrors);
        } else {
            setErrors({});
            if (selectedBeneficiary?.id) {
                await dispatch(
                    handleDeleteAdminBeneficiary({
                        beneficiariesIds: [selectedBeneficiary.id.toString()],
                        password,
                        twoFactor,
                    })
                );
            }
            setShowSuccess(true);
            handleCloseDrawer();
        }

        handleSwitchDelete();
    };

    const handleOpenPasswordValidationModalDelete = () => {
        setShowSuccess(false);
        setOpenPasswordValidationModalDelete(!openPasswordValidationModalDelete);
    };

    const handleOpenPasswordValidationModalEdit = () => {
        setShowSuccess(false);
        setOpenPasswordValidationModalEdit(!openPasswordValidationModalEdit);
    };

    const handleChangeInfo = (value: string) => {
        setInfo(value);
    };

    return (
        <>
            <Drawer
                isOpen={isOpen}
                onClose={handleCloseDrawer}
                header={{
                    title: t("DetailsBeneficiaryDrawerHeader.beneficiary-details"),
                    image: "user-circle",
                }}
                body={
                    <div className='details-beneficiary-drawer-body-container'>
                        <div className='drawer-content-section-form'>
                            <AdminBeneficiaryDrawerSectionInfo
                                validateForms={isBulk ? validateForms : handleOpenPasswordValidationModalEdit}
                                setInformations={setInformations}
                                editInformations={selectedBeneficiary}
                                isBulk={isBulk}
                            />
                            <AdminBeneficiaryDrawerSectionBeneficiaries
                                validateForms={isBulk ? validateForms : handleOpenPasswordValidationModalEdit}
                                selectedBeneficiary={selectedBeneficiary}
                                externalAccounts={externalAccounts}
                                setExternalAccounts={setExternalAccounts}
                                isBulk={isBulk}
                            />
                            {!isBulk && (
                                <div className='drawer-content-section d-flex justify-content-between p-4 px-3 mt-3 delete-section'>
                                    <div className='section-header'>
                                        <Typography
                                            className='fw-bold'
                                            message={t("Beneficiaries.delete-beneficiary")}
                                        />
                                        <div onClick={handleSwitchDelete}>
                                            <Typography
                                                className='color-error fw-bold cursor-pointer'
                                                message={t("CommonUse.delete")}
                                            />
                                        </div>
                                    </div>
                                    {isDeleting && (
                                        <div className='drawer-content-section-body content-section'>
                                            <Input
                                                label={t("CommonUse.checking").toString()}
                                                placeholder={t("CommonUse.verification-words")}
                                                onChange={handleChangeInfo}
                                                value={info}
                                                name='info'
                                                autoComplete='off'
                                                variant={errors?.info ? "error" : undefined}
                                                underMessage={
                                                    errors?.info
                                                        ? t("CommonUse.wrong-verification-words").toString()
                                                        : t("CommonUse.archive-words", {
                                                              entity: t("Beneficiaries.a-beneficiary"),
                                                          }).toString()
                                                }
                                            />
                                            <div className='button-part'>
                                                <Button
                                                    label={t("CommonUse.cancel")}
                                                    className='flex-grow-1'
                                                    variant='tertiary'
                                                    onClick={handleSwitchDelete}
                                                />
                                                <Button
                                                    label={t("CommonUse.archive")}
                                                    onClick={handleOpenPasswordValidationModalDelete}
                                                    className='flex-grow-1'
                                                    disabled={info.length === 0}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                }
            />
            <PasswordValidationModal
                header={{
                    title: t("Beneficiaries.edit-bene"),
                    icon: "user-group",
                }}
                successContent={{
                    text: t<string>("PasswordModal.validated-demand"),
                    image: successImage,
                }}
                setOpen={handleOpenPasswordValidationModalEdit}
                error={integrateBeneficiaryError}
                asyncFromParent
                succeeded={showSuccess}
                open={!!openPasswordValidationModalEdit}
                handleOnSubmit={({ password, twoFactor }) => validateForms(password, twoFactor)}
            />
            <PasswordValidationModal
                header={{
                    title: t("Beneficiaries.delete-bene"),
                    icon: "user-group",
                }}
                successContent={{
                    text: t<string>("PasswordModal.validated-demand"),
                    image: successImage,
                }}
                setOpen={handleOpenPasswordValidationModalDelete}
                error={integrateBeneficiaryError}
                asyncFromParent
                succeeded={showSuccess}
                open={!!openPasswordValidationModalDelete}
                handleOnSubmit={({ password, twoFactor }) => handleDelete(password, twoFactor)}
            />
        </>
    );
};
