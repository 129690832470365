import { HistoryMultipleActions } from "./MultipleActions";
import { TransactionApi } from "src/services/transactions/types";
import emptyTransactionListImg from "src/shared/images/empty/empty-admin.svg";
import emptyListImg from "src/shared/images/empty/empty-user.svg";
import { CompanyRole } from "src/shared/models/UserCompany";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";

import { HistoryInfoAmount } from "src/components/History/Info/Amount";
import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { getTransactionReceiver } from "src/components/History/utils";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { StatusIcon } from "src/shared/atoms/Icons/StatusIcons";
import { OperationTag } from "src/shared/atoms/Tag/OperationTag";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

type TransactionHistoryTableProps = {
    transactions: TransactionApi[];
    handleOpenDrawer: (uuid: string | null) => () => void;
    currentPage: number;
    showPageSize: number;
    totalCount: number;
    setCurrentPage: (newPage: number) => void;
    setPageSize: (newPageSize: number) => void;
    handleFetchWalletTransactions?: () => void;
    activeFilters: boolean;
    isLoading: boolean;
};

export type TransactionMultipleActions = "receipts" | "retry" | "cancel-reason" | "cancel";

function isTypeOfNumberArrays(value: number[] | ["all"]): value is number[] {
    return typeof value[0] === "number";
}

export const TransactionHistoryTable = ({
    transactions,
    handleOpenDrawer,
    currentPage,
    showPageSize,
    totalCount,
    setCurrentPage,
    setPageSize,
    handleFetchWalletTransactions,
    activeFilters,
    isLoading,
}: TransactionHistoryTableProps) => {
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = useState<number[]>();
    const [action, setAction] = useState<TransactionMultipleActions>();
    const [loading, setLoading] = useState(false);

    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.READ] });

    const handleSetSelectedRows = (newSelectedRows: number[] | ["all"]) => {
        setSelectedRows(
            isTypeOfNumberArrays(newSelectedRows) ? newSelectedRows : transactions.map((_, index) => index)
        );
    };

    const handleSetAction = (newAction: TransactionMultipleActions) => () => {
        setAction(newAction);
    };

    const canMultipleCancel = useMemo(() => {
        return (
            (selectedRows
                ?.map((index) => {
                    const transaction = transactions?.[index];

                    const createdAtDate = new Date(transaction?.createdAt);
                    const threeDaysAgo = new Date();
                    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);

                    const isOlderThanThreeDays = createdAtDate < threeDaysAgo;
                    const disabled = isUserUnauthorized || isOlderThanThreeDays;
                    return transaction?.canCancel && !transaction?.isCancelled && !disabled ? transaction : null;
                })
                .filter((transaction) => transaction)?.length ?? 0) > 0
        );
    }, [transactions, selectedRows]);

    const canMultipleRetry = useMemo(() => {
        return (
            (selectedRows
                ?.map((index) => {
                    const transaction = transactions?.[index];
                    return transaction?.canRetry ? transaction : null;
                })
                .filter((transaction) => transaction)?.length ?? 0) > 0
        );
    }, [transactions, selectedRows]);

    const tableRows: SelectableTableRow[][] = transactions.map((transaction) => {
        const { comment, createdAt, status, amount, Service, TransactionType, receipts, uuid } = transaction;
        const [phoneNumber, name] = getTransactionReceiver(transaction);

        return [
            {
                content: (
                    <div className='center-item' onClick={handleOpenDrawer(uuid)}>
                        <TransactionServiceImage classname='' serviceSlug={Service.slug} size='lg' />
                    </div>
                ),
                type: "image",
                onClick: handleOpenDrawer(uuid),
            },
            {
                content: (
                    <div className='transaction-name'>
                        <Typography message={phoneNumber} />
                        {name && <Typography className='color-neutral-500' message={name} />}
                    </div>
                ),
                type: "text",
                onClick: handleOpenDrawer(uuid),
            },
            {
                content: (
                    <div className='truncated-value'>
                        <OperationTag typeSlug={TransactionType.slug} size='sm' />
                    </div>
                ),
                type: "text",
                onClick: handleOpenDrawer(uuid),
            },
            {
                content: <Typography message={comment ? comment : "-"} />,
                type: "text",
                onClick: handleOpenDrawer(uuid),
            },
            {
                content: <Typography className={"fw-bold"} message={computeTimestampToString(createdAt)} />,
                type: "text",
                onClick: handleOpenDrawer(uuid),
            },
            {
                content: (
                    <div className='center-item'>
                        <StatusIcon withColor status={transaction?.isArchived ? "archived" : status} size='sm' />
                    </div>
                ),
                type: "image",
                onClick: handleOpenDrawer(uuid),
            },
            {
                content: (
                    <HistoryInfoAmount
                        status={status}
                        TransactionType={TransactionType}
                        amount={amount}
                        strikeThroughText
                    />
                ),
                type: "text",
                onClick: handleOpenDrawer(uuid),
            },
            {
                content: receipts && receipts.length > 0 && <Icon name='link' size='sm' />,
                type: "button",
                onClick: handleOpenDrawer(uuid),
            },
        ];
    });

    return (
        <>
            <SelectableTable
                selectedBar={{
                    itemName: t<string>("Transactions.operation"),
                    buttonActions: {
                        fitContent: true,
                        content: {
                            header: { title: t<string>("Transactions.multiple-actions"), icon: "squares" },

                            dropDownContent: {
                                body: (
                                    <>
                                        <Button
                                            label={t("Transactions.download-receipts")}
                                            icon='document'
                                            variant='ghost'
                                            onClick={handleSetAction("receipts")}
                                            disabled={loading}
                                        />
                                        <Button
                                            label={t("Transactions.replay-transactions")}
                                            icon='retry'
                                            variant='ghost'
                                            onClick={handleSetAction("retry")}
                                            disabled={loading || !canMultipleRetry}
                                        />
                                        <Button
                                            label={t("Transactions.cancel-operations")}
                                            icon='forbidden'
                                            variant='ghost'
                                            onClick={handleSetAction("cancel-reason")}
                                            disabled={loading || !canMultipleCancel}
                                        />
                                    </>
                                ),
                            },
                        },
                    },
                }}
                isLoading={isLoading}
                setSelectedRows={handleSetSelectedRows}
                selectable
                pagination={{
                    currentPage,
                    pageSize: showPageSize,
                    totalCount,
                    onPageChange: setCurrentPage,
                    onPageSizeChange: setPageSize,
                }}
                header={[
                    "",
                    t("Transactions.receiver"),
                    t("Transactions.transaction-type"),
                    t("CommonUse.comment"),
                    t("CommonUse.date"),
                    t("CommonUse.statut"),
                    t("CommonUse.net-amount"),
                    "",
                ]}
                rows={tableRows as SelectableTableRow[][]}
                empty={
                    <Empty
                        image={activeFilters ? emptyListImg : emptyTransactionListImg}
                        title={t(activeFilters ? "Table.no-result-found" : "Table.welcome-to-julaya")}
                        description={t<string>(
                            activeFilters ? "Table.no-result-found-description" : "Table.welcome-to-julaya-description"
                        )}
                    />
                }
            />
            {handleFetchWalletTransactions && (
                <HistoryMultipleActions
                    loading={loading}
                    setAction={setAction}
                    action={action}
                    selectedRows={selectedRows}
                    setLoading={setLoading}
                    transactions={transactions}
                    handleFetchWalletTransactions={handleFetchWalletTransactions}
                />
            )}
        </>
    );
};
