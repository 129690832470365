import { useState } from "react";

import { Button } from "src/shared/atoms/Buttons/Button";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Select, SelectProps } from "src/shared/atoms/Select/Select";

import "./styles.scss";

export type MenuItem = {
    icon: IconListType;
    title: string;
    action: () => void | Promise<void>;
    disabled?: boolean;
};

export type MenuProps = {
    closeMenuOnAction?: boolean;
    disabled?: boolean;
    icon?: IconListType;
    dropdownIcon?: IconListType;
    title?: string;
    items: MenuItem[];
} & Pick<SelectProps, "color" | "position" | "fitContent" | "testId">;

export function Menu({
    closeMenuOnAction,
    disabled = false,
    title,
    dropdownIcon,
    icon,
    items,
    ...selectProps
}: Readonly<MenuProps>) {
    const [forceClose, setForceClose] = useState(false);

    const handleAction = (action: () => void) => () => {
        action();
        if (closeMenuOnAction) {
            setForceClose(true);
        }
    };

    return (
        <Select
            forceClose={forceClose}
            {...selectProps}
            content={{
                header: { disabled, dropdownIcon, icon, title },
                dropDownContent: {
                    body: (
                        <>
                            {items.map((item) => (
                                <div className='menu-fit-content' key={item.title}>
                                    <Button
                                        key={item.title}
                                        onClick={handleAction(item.action)}
                                        icon={item.icon}
                                        className='item-button w-100'
                                        variant='ghost'
                                        label={item.title}
                                    />
                                </div>
                            ))}
                        </>
                    ),
                },
            }}
        />
    );
}
