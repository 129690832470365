import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const sections = [
    {
        title: "RechargementPanel.helper-drawer-section-bank-select-title",
        description: "RechargementPanel.helper-drawer-section-bank-select-description",
    },
    {
        title: "RechargementPanel.helper-drawer-section-wallet-selection-title",
        description: "RechargementPanel.helper-drawer-section-wallet-selection-description",
    },
    {
        title: "RechargementPanel.helper-drawer-section-type-slug-title",
        description: "RechargementPanel.helper-drawer-section-type-slug-description",
    },
    {
        title: "RechargementPanel.helper-drawer-section-disposal-proof-title",
        description: "RechargementPanel.helper-drawer-section-disposal-proof-description",
    },
    {
        title: "RechargementPanel.helper-drawer-section-recap-title",
        description: "RechargementPanel.helper-drawer-section-recap-description",
    },
];

export const RechargementHelperDrawerBody = () => {
    const { t } = useTranslation();

    return (
        <div className='drawer-content-section rechargement-helper-drawer-container '>
            <Typography className='fw-bold' message={t("Approvisionner votre compte Julaya")} />
            <div className='divider' />
            {sections.map((section, index) => (
                <>
                    <div className='d-flex' key={section.title}>
                        <Avatar backgroundColor='lilas-50' firstname='0' lastname={(index + 1).toString()} />

                        <div className='helper-description-section'>
                            <Typography className='fw-bold' message={t(section.title)} />
                            <Typography message={t(section.description)} />
                        </div>
                    </div>
                    {index < sections.length ? <div className='divider' /> : null}
                </>
            ))}
        </div>
    );
};
