import { useNavigate, useParams } from "react-router-dom";
import { DEMO_WARNING } from "src/services/utils/const";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { requestSendEmailVerification } from "src/modules/auth/thunkActions";
import { useAppDispatch } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";

const loginImage = require("src/shared/images/login/login.png");
const success = require("src/shared/images/login/email-verification-success.png");
const failed = require("src/shared/images/login/email-verification-failed.png");

export const EmailValidationCheckPanel = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { token = "" } = useParams<{ token: string }>();

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(false);

    useEffect(() => {
        const verifyMail = async () => {
            dispatch(requestSendEmailVerification({ token }))
                .unwrap()
                .then((res) => {
                    setResult(res as boolean);
                })
                .finally(() => setLoading(false));
        };
        verifyMail();
    }, [dispatch, token]);

    const handleRedirect = () => {
        navigate("/");
    };

    return (
        <div className={`login-panel-container ${DEMO_WARNING ? "demo-warning" : ""}`}>
            <div className='form-part'>
                <div className='login-form'>
                    {loading ? (
                        <Spinner size='md' />
                    ) : result ? (
                        <>
                            <Typography
                                variant={"h2"}
                                message={t("LoginPanel.email-verified-success")}
                                className='login-title-happy'
                            />
                            <Typography
                                message={t("LoginPanel.email-verified-success-description")}
                                className='color-neutral-500 fw-bold'
                            />
                            <div className='link-image-container'>
                                <img className='link-image-success' alt='success' src={success} />
                            </div>
                        </>
                    ) : result === false ? (
                        <>
                            <Typography
                                variant={"h2"}
                                message={t("LoginPanel.email-verified-failed")}
                                className='login-title-happy'
                            />
                            <Typography
                                message={t("LoginPanel.email-verification-failed")}
                                className='color-neutral-500 fw-bold'
                            />
                            <div className='link-image-container'>
                                <img className='link-image-success' alt='success' src={failed} />
                            </div>
                        </>
                    ) : null}
                    <Button
                        disabled={loading}
                        onClick={handleRedirect}
                        className='w-100'
                        label={t("LoginPanel.access-to-the-platform")}
                    />
                </div>
            </div>
            <div className='image-part'>
                <img src={loginImage} alt='julaya login page img' className='login-right-image' />
            </div>
        </div>
    );
};
