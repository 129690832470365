import { rolesFilter } from "./const";
import { UserFilterParams } from "src/services/admin/types";

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { useDebounce } from "src/shared/hooks/useDebounce";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Chip } from "src/shared/components/Chip/Chip";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

type Props = {
    filters: UserFilterParams;
    setFilters: Dispatch<SetStateAction<UserFilterParams>>;
};

export function AdminAccountsFilters({ filters, setFilters }: Props) {
    const { t } = useTranslation();
    const [query, setQuery] = useState("");
    const [archived, setArchived] = useState(false);
    const [roles, setRoles] = useState<string[]>([]);
    const [closeSelect, setCloseSelect] = useState(false);

    const debouncedQuery = useDebounce(query, 700);

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    useEffect(() => {
        setFilters((oldFilters: UserFilterParams) => {
            return debouncedQuery !== oldFilters.query
                ? {
                      ...oldFilters,
                      query: debouncedQuery === "" ? "" : debouncedQuery,
                  }
                : oldFilters;
        });
    }, [debouncedQuery, setFilters]);

    useEffect(() => {
        setQuery(filters.query);
        setArchived(filters.archived);
        setRoles(filters.role);
    }, [filters]);

    const haveFieldsChanged = useMemo(() => {
        if (!filters) return false;

        return filters.archived !== archived || JSON.stringify(filters.role) !== JSON.stringify(roles);
    }, [filters, archived, roles]);

    const handleSetArchived = () => {
        setArchived((oldArchived) => !oldArchived);
    };

    const handleSetQueryFilter = useCallback((newQuery: string) => {
        setQuery(newQuery);
    }, []);

    const handleValidateFilters = () => {
        setFilters(() => {
            return {
                query: debouncedQuery === "" ? "" : debouncedQuery,
                archived,
                page: 1,
                role: roles,
            };
        });
        setCloseSelect(true);
    };

    const handleFilterReinit = useCallback(() => {
        setArchived(filters.archived);
        setQuery("");
        setRoles([]);
        setCloseSelect(true);
        setFilters({
            ...filters,
            page: 1,
            archived: false,
            query: "",
            role: [],
        });
    }, [setFilters, filters]);

    const handleSelectRole = (values: SelectOption[]) => {
        setRoles(values.map((value) => value.id));
    };

    const handleReinitDropdownFilter = () => {
        setArchived(filters.archived);
        setRoles(filters.role);
    };

    const handleResetRoleFilter = () => {
        setRoles([]);
        setFilters({
            ...filters,
            page: 1,
            archived: archived,
            role: [],
        });
    };

    const handleResetArchiveFilter = () => {
        setArchived(false);
        setFilters({
            ...filters,
            page: 1,
            role: roles,
            archived: false,
        });
    };

    const renderChip = () => {
        return roles.length > 0 || archived ? (
            <div className='chip-list-container'>
                {roles.length > 0 && (
                    <Chip
                        handleDelete={handleResetRoleFilter}
                        className='fw-lighter'
                        message={t("AdminAccountsFilters.role-de-l-utilisateur")}
                    />
                )}
                {archived && (
                    <Chip
                        handleDelete={handleResetArchiveFilter}
                        className='fw-lighter'
                        message={t("AdminAccountsFilters.utilisateur-s-archivee-s")}
                    />
                )}
            </div>
        ) : (
            <Typography message={t("CommonUse.filters")} />
        );
    };

    return (
        <div className='filter-history-container d-flex align-items-center'>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={handleSetQueryFilter}
                value={query}
                className='search-input'
            />
            <div className='filters-select ml-2'>
                <Select
                    forceClose={closeSelect}
                    onBlur={handleReinitDropdownFilter}
                    color='white'
                    content={{
                        header: {
                            component: renderChip(),
                            icon: "adjustments-horizontal",
                            disabled: false,
                        },
                        dropDownContent: {
                            header: {
                                title: <Typography message={t("CommonUse.filters")} />,
                                label: (
                                    <Button
                                        variant='ghost'
                                        color='lilas'
                                        onClick={handleFilterReinit}
                                        label={<Typography message={t("AdminAccountsFilters.reinitialiser")} />}
                                    />
                                ),
                            },
                            body: (
                                <div className='body-content'>
                                    <SelectMultipleOptions
                                        items={rolesFilter}
                                        selected={rolesFilter.filter((role) => roles.includes(role.id))}
                                        onSelectionChange={handleSelectRole}
                                        name={t("AdminAccountsFilters.role-de-l-utilisateur")}
                                    />
                                    <Button
                                        icon={"archive"}
                                        label={t("AdminAccountsFilters.utilisateur-s-archivee-s")}
                                        onClick={handleSetArchived}
                                        variant={archived ? "primary" : "outline"}
                                    />
                                </div>
                            ),
                            footer: (
                                <div className='footer-content'>
                                    <Button
                                        className='background-neutral-200 footer-btn'
                                        variant='tertiary'
                                        onClick={handleFilterReinit}
                                        label={t("CommonUse.cancel")}
                                    />
                                    <Button
                                        className='footer-btn'
                                        variant='primary'
                                        onClick={handleValidateFilters}
                                        label={t("CommonUse.validate")}
                                        disabled={!haveFieldsChanged}
                                    />
                                </div>
                            ),
                        },
                    }}
                />
            </div>
        </div>
    );
}
