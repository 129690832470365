import { useLocation, useNavigate } from "react-router-dom";
import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

export function TaskButtonUnauthorized() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const isActive = useMemo(() => {
        return location.pathname === "/tasks" || location.pathname === "/tasks-list";
    }, [location.pathname]);

    const handleRedirect = () => {
        navigate("/tasks");
    };

    return (
        <div
            onClick={handleRedirect}
            onKeyUp={() => {}}
            className={`menu-button cursor-pointer ${isActive ? "active" : ""}`}
            data-testid='menu-button-create-task'
        >
            <Icon name='task' color={"black"} />
            <Typography className='fw-bold color-black' size={"xs"} variant={"span"} message={t("Tasks.my-tasks")} />
        </div>
    );
}

export const TaskButton = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER] })(
    TaskButtonUnauthorized
);
