import {
    handleSwitchBillerDisponibility,
    handleUpdateBillerProfileDetails,
    handleUpdateBillerProfileInvoiceReceipt,
} from "./thunkActions";
import {
    BillingBillerUpdatePayload,
    isBillerUpdatePayloadDetails,
    isBillerUpdatePayloadDisponibility,
    isBillerUpdatePayloadInvoiceReceipt,
} from "src/services/billing/enterprise/type";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";

import { useState } from "react";

import { useAppDispatch } from "src/store";

export function useUpdateBillerProfile() {
    const dispatch = useAppDispatch();

    const [edit, setEdit] = useState<"invoiceReceipt" | "details" | null>(null);
    const [payload, setPayload] = useState<BillingBillerUpdatePayload>();

    const handleUpdateBiller = (props: HandleSubmitPropsPasswordValidation) => {
        if (payload && edit === "invoiceReceipt" && isBillerUpdatePayloadInvoiceReceipt(payload)) {
            return dispatch(handleUpdateBillerProfileInvoiceReceipt({ ...payload, ...props }))
                .unwrap()
                .then(() => setEdit(null));
        }
        if (payload && edit === "details" && isBillerUpdatePayloadDetails(payload)) {
            return dispatch(handleUpdateBillerProfileDetails({ ...payload, ...props }))
                .unwrap()
                .then(() => setEdit(null));
        }
        if (payload && edit === null && isBillerUpdatePayloadDisponibility(payload)) {
            return dispatch(handleSwitchBillerDisponibility({ ...payload, ...props }))
                .unwrap()
                .then(() => setEdit(null));
        }
        return Promise.reject();
    };

    const handleCloseDialog = () => {
        setPayload(undefined);
    };

    const handleSetPayload = (props: BillingBillerUpdatePayload) => () => {
        setPayload(props);
    };

    return { edit, payload, setEdit, handleSetPayload, handleCloseDialog, handleUpdateBiller };
}
