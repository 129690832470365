import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../store";

const getAuthCompaniesState = (state: RootState) => state.authCompanies;

export const getCompany = createSelector(
    getAuthCompaniesState,
    (_state: RootState, { companyId }: { companyId: number | null }) => companyId,
    (state, companyId) => state.companies.find((company) => company.id === companyId)
);

export const getAuthCompanies = createSelector(
    getAuthCompaniesState,
    (authCompaniesState) => authCompaniesState.companies
);

export const getAuthCompaniesLoading = createSelector(
    getAuthCompaniesState,
    (authCompaniesState) => authCompaniesState.loading
);

export const isOneAuthCompanyAccessible = createSelector(
    getAuthCompaniesState,
    (authCompaniesState) => authCompaniesState.companies.length === 1 && authCompaniesState.companies[0].hasAccess
);
