import { LoanCurrent } from "./Current/Current";
import { LoanHistory } from "./History/History";
import { LoanSimulator } from "./Simulator/Simulator";
import { requestGetLoanOffers, requestGetLoanTransactions, requestGetLoans } from "src/services/loans/operations";
import { TransactionApi } from "src/services/transactions/types";
import { LoanModel, LoanModelApiResponse, LoanOfferModel } from "src/shared/models/Loan";

import { useEffect, useState } from "react";

import "./styles.scss";

export const LoanPanel = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loans, setLoans] = useState<LoanModel[]>([]);
    //const [disburseTransactions, setDisburseTransactions] = useState<TransactionApi[]>([]);
    const [historyTransactions, setHistoryTransactions] = useState<TransactionApi[]>([]);
    const [closedLoans, setClosedLoans] = useState<LoanModel[]>([]);
    const [loanOffers, setLoansOffers] = useState<LoanOfferModel[]>([]);

    useEffect(() => {
        requestGetLoans().then((response: LoanModelApiResponse) => {
            setLoans(response.data.filter((loan) => loan.status.includes("active")));
            setClosedLoans(response.data.filter((loan) => loan.status.includes("closed")));
            setIsLoading(false);
        });
        requestGetLoanOffers({ status: ["to-propose", "pending"] }).then((response) => {
            setLoansOffers(response.data);
        });
    }, []);

    useEffect(() => {
        if (loans.length > 0) {
            requestGetLoanTransactions({ loanUuid: loans[0].uuid }).then((response) => {
                setHistoryTransactions(response.data);
            });
            // requestGetLoanTransactions({ offerId: loans[0].offerId.toString() }).then((response) => {
            //     setDisburseTransactions(response.data);
            // });
        }
    }, [loans]);

    return (
        <div className='loan-panel-container'>
            {!isLoading && (
                <>
                    {loans.length > 0 && (
                        <LoanCurrent currentLoan={loans[0]} historyTransactions={historyTransactions || []} />
                    )}
                    {loans.length === 0 &&
                        (loanOffers.length > 0 ? (
                            <LoanSimulator
                                isEligible={true}
                                maxRange={loanOffers[0].amount}
                                interest={loanOffers[0].fees}
                                date={loanOffers[0].createdAt}
                            />
                        ) : (
                            <LoanSimulator
                                isEligible={false}
                                maxRange={0}
                                interest={0}
                                date={loanOffers.length ? loanOffers[0].createdAt : null}
                            />
                        ))}

                    {closedLoans.length > 0 ? (
                        <LoanHistory loanHistory={closedLoans} />
                    ) : (
                        <LoanHistory loanHistory={[]} />
                    )}
                </>
            )}
            {isLoading && (
                <>
                    <div className='placeholder' />
                    <div className='placeholder' />
                </>
            )}
        </div>
    );
};
