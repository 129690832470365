import { WalletApi } from "src/services/wallets/types";
import { countryCodeBank } from "src/shared/const/countryPhonePrefixes";
import { DrawerContext } from "src/wrapper/context";

import { useContext } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { getWallet } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { CopyInput } from "src/shared/components/Copy/Input";

const getFieldsToDisplay = ({
    t,
    wallet,
    companyName,
    countryCode,
}: {
    t: TFunction<"translation", string, "translation">;
    wallet: WalletApi | undefined;
    companyName: string;
    countryCode: string;
}): { [key: string]: { title: string; value: string; subMessage?: string } } => ({
    beneficiary: {
        title: "Iban.account-owner",
        value: companyName ?? "-",
    },
    iban: {
        title: "TopUp.iban",
        value: wallet?.iban ?? "-",
    },
    bic: {
        title: "TopUp.bic",
        value: countryCodeBank[countryCode]?.bic ?? "-",
        subMessage: "Iban.bic-sub-message",
    },
    beneficiaryBank: {
        title: "Iban.domiciliation",
        value: t(countryCodeBank[countryCode]?.bankName) ?? "-",
    },
});

export function IbanDrawerSectionDetails() {
    const { t } = useTranslation();
    const { providedState } = useContext(DrawerContext);

    const companyName = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state)?.name ?? "");
    const wallet = useAppSelector((state) =>
        getWallet(state, { walletId: providedState?.walletId ? parseInt(providedState?.walletId) : undefined })
    );
    const authUser = useAppSelector((state) => getAuthUser(state));

    const fields = getFieldsToDisplay({ t, wallet, companyName, countryCode: authUser?.countryCode ?? "" });

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <div className='d-flex align-items-center'>
                    <Icon name='information-circle' variant='squared' color='lilas' size='lg' />
                    <Typography className='ml-2' message={t("TopUp.account-details")} />
                </div>
            </div>
            <div className='drawer-content-section-body iban-copyable-container'>
                {Object.entries(fields).map(([key, data]) => (
                    <CopyInput
                        key={`field-${key}`}
                        label={data.title}
                        value={data.value}
                        copiedContent={data.value}
                        belowMessage={data.subMessage && t<string>(data.subMessage)}
                    />
                ))}
            </div>
        </div>
    );
}
