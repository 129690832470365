import { regularFormItems, templateFormItems } from "../../const";
import { fetchAllExportTemplatesByTypes } from "src/services/export/operations";
import {
    ExportFormMode,
    ExportFormType,
    ExportTemplate,
    ExportTemplateFormType,
    FormTypeInfo,
} from "src/shared/models/Export";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

import "../Templates/styles.scss";

const selectedTemplateIcon = {
    regular: "document",
    recurrent: "clock",
    bank_statement: "bank",
};

type ExportFormSelectionProps = {
    mode: ExportFormMode;
    selectedFormType: ExportTemplateFormType | ExportFormType | undefined;
    handleSelectedFormType?: (type?: ExportFormType) => void;
    handleSelectedTemplateFormType?: (type?: ExportTemplateFormType) => void;
    handleSelectedTemplate?: (template?: ExportTemplate) => void;
};

export const ExportFormSelection = ({
    mode,
    selectedFormType,
    handleSelectedFormType,
    handleSelectedTemplateFormType,
    handleSelectedTemplate,
}: ExportFormSelectionProps) => {
    const { t } = useTranslation();
    const formTypes: FormTypeInfo[] = mode === "regular" ? regularFormItems : templateFormItems;
    const [exportTemplatesList, setExportTemplatesList] = useState<ExportTemplate[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<ExportTemplate | undefined>(undefined);

    const isFeatureExportTemplateLimited = useSubscriptionRestrictions(
        SubscriptionRestrictions.FEATURE_EXPORT_TEMPLATE
    );

    useEffect(() => {
        if (mode === "regular") {
            fetchAllExportTemplatesByTypes(["regular"]).then(setExportTemplatesList);
        }
    }, [mode]);

    const handleTemplateClick = (templateId: string) => {
        const selected = exportTemplatesList.find((template) => template.id === templateId);
        setSelectedTemplate(selected);
        if (handleSelectedTemplate && selected) {
            handleSelectedTemplate(selected);
        }
    };

    const handleItemClick = (type: ExportTemplateFormType | ExportFormType) => () => {
        if (mode === "regular" && handleSelectedFormType) {
            handleSelectedFormType(type as ExportFormType);
        } else if (handleSelectedTemplateFormType) {
            handleSelectedTemplateFormType(type as ExportTemplateFormType);
        }
    };

    const handleSetFormTypeUndefined = () => {
        setSelectedTemplate(undefined);

        if (handleSelectedFormType) {
            handleSelectedFormType(undefined);
        }
        if (handleSelectedTemplateFormType) {
            handleSelectedTemplateFormType(undefined);
        }
    };

    return (
        <div className='drawer-content-section export-form-selection-container'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Export.export-base")} />
                {(selectedFormType || selectedTemplate) && (
                    <Button label={t("CommonUse.modify")} onClick={handleSetFormTypeUndefined} />
                )}
            </div>
            <div className='drawer-content-section-body'>
                <div className='form-type-selection-container'>
                    <Typography
                        message={mode === "template" ? t("Export.template-type") : t("Export.predefined-tempalte")}
                        className='color-neutral-500 fw-bold'
                    />
                    {!selectedFormType && !selectedTemplate ? (
                        <>
                            <List
                                extended
                                animation='fadeInLeft'
                                keyName='exportTemplateList'
                                items={formTypes.map(({ type, iconName, messages }) => (
                                    <div
                                        key={type}
                                        className='form-type-selection-item'
                                        onClick={handleItemClick(type)}
                                    >
                                        <div className='left-content'>
                                            <Icon name={iconName} />
                                            <div className='form-type-selection-texts'>
                                                <Typography message={t(messages.title)} />
                                                <Typography message={t<string>(messages.description)} />
                                            </div>
                                        </div>
                                        <Icon name='chevron-right' />
                                    </div>
                                ))}
                            />

                            {mode === "regular" && exportTemplatesList.length > 0 && (
                                <>
                                    <div className='divider' />
                                    <Typography
                                        message={t("Export.personalised-templates")}
                                        className='color-neutral-500 fw-bold'
                                    />
                                    {exportTemplatesList && (
                                        <ToolTip
                                            content={
                                                <div className='tooltip-limited'>
                                                    <Typography
                                                        message={t("Subscription.not-accessible-feature")}
                                                        className='fw-bold'
                                                    />
                                                    <Typography message={t("Subscription.unlock-unlimited-access")} />
                                                </div>
                                            }
                                            activation={isFeatureExportTemplateLimited}
                                        >
                                            <List
                                                extended
                                                animation='fadeInLeft'
                                                keyName='exportTemplateList'
                                                disabled={isFeatureExportTemplateLimited}
                                                items={exportTemplatesList.map((template) => {
                                                    return (
                                                        <div
                                                            key={template.id}
                                                            className='form-type-selection-item'
                                                            onClick={() =>
                                                                isFeatureExportTemplateLimited
                                                                    ? undefined
                                                                    : handleTemplateClick(template.id)
                                                            }
                                                        >
                                                            <div className='left-content'>
                                                                <Icon
                                                                    name={
                                                                        selectedTemplateIcon[
                                                                            template.type
                                                                        ] as IconListType
                                                                    }
                                                                />
                                                                <Typography
                                                                    message={template.name}
                                                                    className='fw-bold'
                                                                />
                                                            </div>
                                                            <Icon name='chevron-right' />
                                                        </div>
                                                    );
                                                })}
                                            />
                                        </ToolTip>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {selectedTemplate && (
                                <div className='form-type-selection-item selected'>
                                    <div className='left-content'>
                                        <Icon name={selectedTemplateIcon[selectedTemplate.type] as IconListType} />
                                        <Typography
                                            message={selectedTemplate.name}
                                            className='fw-bold color-neutral-900'
                                        />
                                    </div>
                                </div>
                            )}
                            {!selectedTemplate &&
                                selectedFormType &&
                                formTypes
                                    .filter((formType) => formType.type === selectedFormType)
                                    .map((formType) => {
                                        return (
                                            <div className='form-type-selection-item selected' key={formType.type}>
                                                <div className='left-content'>
                                                    <Icon name={formType.iconName} />
                                                    <div className='form-type-selection-texts'>
                                                        <Typography message={t(formType.messages.title)} />
                                                        <Typography
                                                            message={t<string>(formType.messages.description)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
