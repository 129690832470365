import { DocumentApiResponseModel } from "src/shared/models/Document";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

interface AdminDocumentsState {
    loading: boolean;
    documents: DocumentApiResponseModel | null;
}

const initialState: AdminDocumentsState = {
    loading: false,
    documents: null,
};

export const adminDocumentsSlice = createSlice({
    name: "adminDocumentsSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestAdminDocuments: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        receiveAdminDocuments: (state, { payload }: PayloadAction<DocumentApiResponseModel>) => {
            return {
                ...state,
                documents: payload,
                loading: false,
            };
        },
        failedRequestAdminDocuments: (state) => {
            return {
                ...state,
                loading: false,
            };
        },
    },
});

export const { requestAdminDocuments, failedRequestAdminDocuments, receiveAdminDocuments } =
    adminDocumentsSlice.actions;
