import { middlewareRequest } from "../utils/request";
import { NotificationsApiReturn, NotificationsFilterParam, UpdateNotificationStatusParams } from "./types";
import { NotificationSetting } from "src/shared/models/Notification";

export async function getNotifications(filters?: NotificationsFilterParam) {
    try {
        return await middlewareRequest<NotificationsApiReturn>({
            endpoint: "/me/notifications/list",
            method: "POST",
            params: filters,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function updateNotificationStatuses(params: UpdateNotificationStatusParams) {
    try {
        return await middlewareRequest<void>({
            endpoint: "/me/notifications/status",
            method: "PUT",
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function fetchNotificationSettings() {
    try {
        return await middlewareRequest<NotificationSetting>({
            endpoint: "/me/subscriptions",
            method: "GET",
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function updateNotificationSettings(params: NotificationSetting) {
    try {
        return await middlewareRequest<NotificationSetting>({
            endpoint: "/me/subscriptions",
            method: "PUT",
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
