import { EmailValidationAlertPanel } from "../Login/EmailValidation/Alert";
import { DashboardListItem } from "./ListItem";
import { useNavigate } from "react-router-dom";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { GetJulayaWalletsTotalBalance, getJulayaWallets, getWalletsTotalBalance } from "src/modules/wallets/selectors";
import { fetchWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";
import { BlockedAccountAlert } from "src/shared/components/BlockedAccountAlert/BlockedAccountAlert";
import { List } from "src/shared/components/ExpandableList/ExpandableList";
import { KybAlert } from "src/shared/components/KybAlert/KybAlert";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

export const DashboardPanel = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const totalBalance = useAppSelector((state) => getWalletsTotalBalance(state));

    const wallets = useAppSelector((state) => getJulayaWallets(state));
    const totalWalletsBalance = useAppSelector((state) => GetJulayaWalletsTotalBalance(state));

    const [selectedWallet, setSelectedWallet] = useState<number | null>(null);
    const [isExtended, setIsExtended] = useState(false);

    useEffect(() => {
        dispatch(fetchWalletsAuthUser());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRedirect = (walletId: number, type: string) => () => {
        navigate(`/history/${type}/${walletId}`);
    };

    const handleGlobalKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                if (selectedWallet === null || selectedWallet === 0) {
                    handleRedirect(wallets[0].id, "wallet")();
                }
                if (selectedWallet) {
                    handleRedirect(wallets[selectedWallet].id, "wallet")();
                }
            } else if (event.key === "Tab") {
                event.preventDefault();
                const nextIndex = selectedWallet === null ? 0 : (selectedWallet + 1) % wallets.length;
                if (selectedWallet && selectedWallet > 1) {
                    setIsExtended(true);
                }
                setSelectedWallet(nextIndex);
            }
        },
        [selectedWallet, wallets.length, handleRedirect]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleGlobalKeyPress);

        return () => document.removeEventListener("keydown", handleGlobalKeyPress);
    }, [selectedWallet, wallets.length, handleRedirect]);

    return (
        <div className='dashboard-container'>
            <PageHeader title={t<string>("DashboardPanel.vue-totale-des-comptes")}>
                <Typography
                    className='dashboard-subtitle'
                    message={`${t("DashboardPanel.total")}: ${formatMoneyToString({
                        amount: totalBalance,
                        zeroValue: true,
                    })}`}
                />
            </PageHeader>
            <BlockedAccountAlert />
            <EmailValidationAlertPanel />
            <KybAlert />
            {Object.keys(wallets).length ? (
                <div className='dashboard-list-container'>
                    <div className='list-title-container'>
                        <Typography
                            className='list-title'
                            message={`${t("Wallets.my-wallets")} (${Object.keys(wallets).length})`}
                        />
                        <Typography
                            className='list-total'
                            message={`${t("Dashboard.wallet-amount")} : ${formatMoneyToString({
                                amount: totalWalletsBalance,
                                zeroValue: true,
                            })}`}
                        />
                    </div>
                    <List
                        className='dashboard-card-list'
                        animation='fadeInLeft'
                        keyName='bankAccount'
                        extended={isExtended}
                        selectedValue={selectedWallet}
                        items={Object.values(wallets).map((wallet) => (
                            <DashboardListItem
                                icon='wallet'
                                handleRedirect={handleRedirect(wallet.id, "wallet")}
                                wallet={wallet}
                                key={`Wallet-list-${wallet.id}`}
                            />
                        ))}
                    />
                </div>
            ) : null}
        </div>
    );
};
