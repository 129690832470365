import { Avatar } from "../Avatar/Avatar";
import { Tag } from "../Tag/Tag";
import { IconSizeType } from "./Icon";
import { IconListType } from "./IconList";
import { TaskStatus } from "src/shared/models/Task";

import { HTMLAttributes } from "react";

import { useTranslation } from "react-i18next";

import "./styles.scss";

export type IconStatusType =
    | "waiting"
    | "done"
    | "failed"
    | "archived"
    | TaskStatus
    | "done-loan"
    | "active"
    | "inactive"
    | "disabled"
    | "downloadable";

//TODO:translationss
export const IconStatus: {
    [key in IconStatusType]: { icon: IconListType; text: string; color: "success" | "warning" | "error" | "neutral" };
} = {
    complete: { icon: "check-circle", text: "CommonUse.done", color: "success" },
    done: { icon: "check-circle", text: "CommonUse.done", color: "success" },
    "done-loan": { icon: "check-circle", text: "CommonUse.closed", color: "success" },
    "refund-pending": { icon: "retry", text: "Tasks.ongoing-reimbursement", color: "warning" },
    refunded: { icon: "retry", text: "CommonUse.refunded", color: "warning" },
    pending: { icon: "clock", text: "CommonUse.ongoing", color: "warning" },
    "to-validate": { icon: "thumb-up", text: "Tasks.waiging-sign", color: "warning" },
    waiting: { icon: "clock", text: "Tasks.pending", color: "warning" },
    "refund-failed": { icon: "retry", text: "Tasks.refund-fail", color: "error" },
    failed: { icon: "x-circle", text: "Tasks.failed", color: "error" },
    cancelled: { icon: "x-circle", text: "CommonUse.canceled", color: "error" },
    "to-retry": { icon: "arrow-path", text: "Tasks.to-replay", color: "error" },
    sending: { icon: "send", text: "Tasks.sent", color: "warning" },
    draft: { icon: "pencil", text: "Tasks.draft", color: "neutral" },
    archived: { icon: "archive", text: "HistoryWalletFilters.archive", color: "error" },
    active: { icon: "check-circle", text: "Bills.active", color: "success" },
    inactive: { icon: "x-circle", text: "CommonUse.inactive", color: "error" },
    disabled: { icon: "x-circle", text: "CommonUse.inactive", color: "error" },
    downloadable: { icon: "check-circle", text: "CommonUse.downloadable", color: "success" },
};

export type StatusIconProps = {
    status: IconStatusType | null;
    size?: IconSizeType;
    withMessage?: boolean;
    withColor?: boolean;
} & HTMLAttributes<HTMLElement>;

export const StatusIcon = ({ status = "done", size = "lg", withMessage, withColor = true }: StatusIconProps) => {
    const { t } = useTranslation();
    return status ? (
        withMessage ? (
            <Tag
                icon={IconStatus[status].icon}
                color={withColor ? IconStatus[status].color : undefined}
                label={t<string>(IconStatus[status].text)}
                type='accent'
                testId='tag-container'
            />
        ) : (
            <Avatar
                color={withColor ? IconStatus[status].color : undefined}
                backgroundColor={withColor ? IconStatus[status].color + "-50" : undefined}
                icon={IconStatus[status].icon}
                size={size}
            />
        )
    ) : null;
};
