import { PasswordValidationProps } from "../common/Password/Modal";

import { Dispatch, SetStateAction, createContext } from "react";

export type PasswordValidationContextProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    passwordValidationProps: Omit<PasswordValidationProps, "open"> | undefined;
    setPasswordValidationProps: Dispatch<SetStateAction<Omit<PasswordValidationProps, "open"> | undefined>>;
};

export const PasswordValidationContext = createContext<PasswordValidationContextProps>(
    {} as PasswordValidationContextProps
);
