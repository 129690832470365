import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getTasksState = (state: RootState) => state.tasks;

export const getTasksIsLoading = createSelector(getTasksState, (tasksState) => tasksState.isLoading);
export const getTaskToEditLoading = createSelector(getTasksState, (tasksState) => tasksState.taskToEditLoading);

export const getTasksCurrentPage = createSelector(getTasksState, (tasksState) => tasksState.currentPage);
export const getTasksShowPageSize = createSelector(getTasksState, (tasksState) => tasksState.pageSize);
export const getTasksTotalCount = createSelector(getTasksState, (tasksState) => tasksState.count ?? 0);

export const getTasksList = createSelector(getTasksState, (tasksState) => tasksState?.data ?? []);

export const getTaskToEdit = createSelector(getTasksState, (tasksState) => tasksState?.taskToEdit);
export const getTaskWalletReferenceFilter = createSelector(
    getTasksState,
    (tasksState) => tasksState?.filters?.walletReferences
);
export const getTaskQuery = createSelector(getTasksState, (tasksState) => tasksState?.filters?.query);
export const getTaskToSignFilter = createSelector(getTasksState, (tasksState) => tasksState?.filters?.toSign);

export const getTask = createSelector(
    getTasksList,
    (_state: RootState, { taskId }: { taskId: number | undefined }) => taskId,
    (tasks, taskId) => (taskId ? tasks?.[taskId] : null)
);

export const getWalletTasksAsSelectOption = createSelector(
    getTasksList,
    (_state: RootState, { walletId }: { walletId: string | undefined }) => walletId,
    (tasks, walletId) =>
        walletId
            ? Object.values(tasks)
                  .filter((task) => String(task.walletId) === walletId)
                  .map((task) => ({
                      id: String(task.id),
                      label: task.title,
                  }))
            : []
);
