const regex = /{(\d+)}/;

export const retrieveNumberFromPattern = (pattern: string) => {
    const match = regex.exec(pattern);

    if (match?.[1]) {
        return parseInt(match[1], 10);
    }
    return null;
};
