import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getState = (state: RootState) => state.transactions;

export const getBankServicesLoading = createSelector(getState, (transactionsState) => transactionsState.isLoading);

export const getBankServicesList = createSelector(
    getState,
    (transactionsState) => transactionsState.servicesByTypes
);

export const getBankServicesListFiltered = createSelector(
    getBankServicesList,
    (_state: RootState, { search }: { search: string }) => search,
    (servicesByTypes, search) =>
        servicesByTypes.filter((item) => item.title?.toLowerCase().includes(search.toLowerCase()))
);

export const getTransactionsState = createSelector(getState, (transactionsState) => transactionsState);
