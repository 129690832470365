export function formatMoney(amount: number | string, locale?: Intl.LocalesArgument) {
    amount = typeof amount === "string" ? parseInt(amount, 10) : amount;
    let formattedAmount = amount.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    formattedAmount = formattedAmount.replace(/\u202F/g, " ");
    formattedAmount = formattedAmount.replace(/,/g, " ");

    return formattedAmount;
}

type Props = {
    amount: number | string | null | undefined;
    locale?: Intl.LocalesArgument;
    zeroValue?: boolean;
    noValue?: boolean;
};

export function formatMoneyToString({ amount, locale, zeroValue, noValue }: Props) {
    return amount ? `${formatMoney(amount, locale)} FCFA` : zeroValue ? "0 FCFA" : noValue ? "" : "-";
}
