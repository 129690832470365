import { SignatureTaskSummaryRecap } from "./Recap";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { useRouterTaskItems } from "src/modules/tasksItems/hooks";
import { getTasksItemsIsLoading } from "src/modules/tasksItems/selectors";
import { useAppSelector } from "src/store";

import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { Bullet } from "src/shared/atoms/Bullet/Bullet";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Table } from "src/shared/components/Table/Table";

export function EditTaskSummaryPanel() {
    const taskItems = useRouterTaskItems();
    const isLoading = useAppSelector((state) => getTasksItemsIsLoading(state));
    const { t } = useTranslation();

    return (
        <div className='d-flex w-100'>
            <div className='w-100'>
                <Table
                    isLoading={isLoading}
                    header={[
                        t("CommonUse.identifier"),
                        t("CommonUse.comment"),
                        t("CommonUse.amount"),
                        t("Tasks.julaya-fees"),
                        "",
                    ]}
                    rows={[
                        Object.values(taskItems).map((taskItem) => (
                            <tr
                                key={`task-item-${taskItem.id}`}
                                className={taskItem.errorCode ? "edit-task-row-error" : ""}
                            >
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <Bullet
                                            bullet={
                                                <TransactionServiceImage
                                                    classname='d-flex align-items-center'
                                                    serviceSlug={taskItem.serviceSlug}
                                                />
                                            }
                                            type='avatar'
                                        >
                                            <Typography className='fw-bold' message={taskItem.externalFullname} />
                                            <Typography message={taskItem.externalReference} />
                                        </Bullet>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='edit-task-table-cell-content'>{taskItem.comment ?? "-"}</div>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='edit-task-table-cell-content'>
                                            {formatMoneyToString({ amount: taskItem.amount ?? 0 })}
                                        </div>
                                    </div>
                                </td>
                                <td>{taskItem.fees ? `${taskItem.fees} FCFA` : "-"}</td>
                                <td>
                                    {taskItem.hasSimilarTransaction ? (
                                        <ToolTip
                                            position='bottom'
                                            activation
                                            content={
                                                <Typography
                                                    message={t(
                                                        "EditTaskSummaryPanel.attention-une-transaction-similaire-a-deja-ete-envoyee-durant-les-15-derniers-jours"
                                                    )}
                                                />
                                            }
                                        >
                                            <Icon color='warning' name='exclamation-circle' />
                                        </ToolTip>
                                    ) : (
                                        <Icon color='success' name='check-circle' />
                                    )}
                                </td>
                            </tr>
                        )),
                    ]}
                />
            </div>
            <div className='summary-recap-container'>
                <SignatureTaskSummaryRecap />
            </div>
        </div>
    );
}
