import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { ExportPanel } from "src/components/Export/Panel";

const ExportPageUnauthorized = () => {
    return <ExportPanel />;
};

export const ExportPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER, CompanyRole.READ],
})(ExportPageUnauthorized);
