import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";

export function IbanDrawerSectionInformations() {
    const { t } = useTranslation();

    return (
        <Alert
            message={t("Iban.iban-alert-funds-title")}
            subMessage={t<string>("Iban.iban-alert-funds-description")}
            icon='sparkles'
            color='lilas'
        />
    );
}
