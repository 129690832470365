import { ChangeEvent, InputHTMLAttributes, ReactNode } from "react";

import "./styles.scss";

export type RadioProps = {
    label: ReactNode | string;
    checked: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    textClassname?: string;
    disabled?: boolean;
    testId?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export function Radio({ label, checked, onChange, textClassname, disabled, testId, ...props }: RadioProps) {
    return (
        <label className={`custom-radio ${disabled ? "disabled" : ""}`}>
            <input
                type='radio'
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                {...props}
                data-testid={testId}
            />
            {label}
        </label>
    );
}
