import "./styles.scss";

export type SpinnerSizeType = "xs" | "sm" | "md" | "lg" | "xl";

export type SpinnerProps = {
    size?: SpinnerSizeType;
};
export const Spinner = ({ size = "sm" }: SpinnerProps) => {
    return <div className={`spinner size-${size}`} data-testid='spinner' />;
};
