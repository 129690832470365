import { ExpensePolicyCreateDrawerAlert } from "./Alert";

import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { getAdminWallets } from "src/modules/admin/adminWallets/selectors";
import {
    getAdminExpensePoliciesCreateAllWallets,
    getAdminExpensePoliciesCreateExludedWallets,
    getAdminExpensePoliciesWalletOptions,
    getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicyOptions,
} from "src/modules/admin/expensePolicies/selectors";
import {
    setAdminExpensePolicyCreateExcludedWallets,
    switchAdminExpensePolicyCreateAllWallets,
} from "src/modules/admin/expensePolicies/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";
import { IconButton } from "src/shared/components/IconButton/IconButton";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

type Props = {
    step?: number;
    handleSetStep?: (newStep: number) => () => void;
};

export function AdminExpensePolicyCreateDrawerWallets({ step, handleSetStep }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const creation = !!handleSetStep;

    const walletsOptions = useAppSelector((state) => getAdminExpensePoliciesWalletOptions(state));

    const { wallets } = useAppSelector((state) => getAdminWallets(state));
    const allWallets = useAppSelector((state) => getAdminExpensePoliciesCreateAllWallets(state));
    const excludedWalletOptions = useAppSelector((state) => getAdminExpensePoliciesCreateExludedWallets(state));
    const walletsWithExpensePolicy = useAppSelector((state) =>
        getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicyOptions(state)
    );
    const walletsToDisplay = [...(excludedWalletOptions ?? []), ...walletsWithExpensePolicy];

    const switchAllWallets = () => {
        dispatch(switchAdminExpensePolicyCreateAllWallets());
    };

    const handleSetExcludedWallets = (selectedItems: SelectOption[]) => {
        dispatch(setAdminExpensePolicyCreateExcludedWallets({ value: selectedItems }));
    };

    const removeExcludedWallets = (id: string) => () => {
        dispatch(
            setAdminExpensePolicyCreateExcludedWallets({
                value: excludedWalletOptions?.filter((item) => item.id !== id),
            })
        );
    };

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter" && creation && handleSetStep && step === 0) {
                handleSetStep(1)();
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    });

    return (
        <StepAccordion
            showOverflow
            showSuccess={false}
            title={t("Expense-policy.which-wallet-to-apply-rule")}
            subTitle={t<string>(
                allWallets && !walletsWithExpensePolicy.length
                    ? "Expense-policy.all-wallets"
                    : "Expense-policy.all-wallets-except-names",
                {
                    amount: walletsToDisplay.length ?? 0,
                    names: walletsToDisplay
                        ?.slice(0, 3)
                        ?.map((item) => item.label)
                        .join(", "),
                }
            )}
            icon='wallet'
            onOpen={creation ? handleSetStep(0) : undefined}
            currentStep={creation ? step : 5}
            step={0}
            footer={creation && <Button label={t("CommonUse.validate")} onClick={handleSetStep(1)} />}
        >
            <div className='d-flex pt-3'>
                <Switch onToggle={switchAllWallets} isOn={allWallets} />
                <div className='d-flex flex-column ml-2'>
                    <Typography className='fw-bold' message={t("Expense-policy.all-wallets")} />
                    <Typography className='color-neutral-500' message={t("Expense-policy.select-all-wallet-option")} />
                </div>
            </div>
            {!allWallets && (
                <>
                    <div className='mt-2'>
                        <SelectMultipleOptions
                            selected={excludedWalletOptions}
                            disabled={allWallets}
                            label={t<string>("Expense-policy.all-wallets-except", {
                                amount: excludedWalletOptions?.length ?? 0,
                            })}
                            headerClassname='text-ellipsis'
                            items={walletsOptions}
                            name={t("Expense-policy.select-wallets")}
                            onSelectionChange={handleSetExcludedWallets}
                        />
                    </div>
                    {excludedWalletOptions?.map((item) => {
                        const { id } = item;
                        const wallet = wallets.find((walletOption) => String(walletOption.id) === id);

                        return (
                            wallet && (
                                <div key={`wallet-${id}`} className='drawer-content-section p-3 mt-3'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <Avatar icon='wallet' backgroundColor='lilas-50' color='lilas-900' />
                                            <div className='d-flex flex-column ml-2'>
                                                <Typography className='fw-bold' message={wallet.label} />
                                                <Typography message={wallet.reference} />
                                            </div>
                                        </div>
                                        <IconButton
                                            iconProps={{ name: "thrash" }}
                                            handleOnClick={removeExcludedWallets(id)}
                                        />
                                    </div>
                                </div>
                            )
                        );
                    })}
                </>
            )}

            <div className='mt-2'>
                <ExpensePolicyCreateDrawerAlert />
            </div>
        </StepAccordion>
    );
}
