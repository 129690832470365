import { PasswordValidationWrapper } from "./PasswordValidation";
import { NotificationDrawerContext } from "src/shared/context/notificationDrawer";
import { DrawerContext, DrawerDisplays, DrawerProvidedState } from "src/wrapper/context";

import { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { LayoutDrawerPanel } from "src/components/Layout/Drawer/Panel";
import { NOTIFICATION_MODE } from "src/components/Notifications/Body";

export function DrawerWrapper({ children }: Readonly<PropsWithChildren>) {
    const [open, setOpen] = useState<undefined | DrawerDisplays>();
    const [openNotification, setOpenNotification] = useState(false);
    const [providedState, setProvidedState] = useState<DrawerProvidedState>();
    const [selectedTab, setSelectedTab] = useState(NOTIFICATION_MODE.RECENT);

    const notificationContextValue = useMemo(
        () => ({ open: openNotification, setOpen: setOpenNotification, selectedTab, setSelectedTab }),
        [openNotification, selectedTab]
    );

    const drawerContextValue = useMemo(
        () => ({ open, setOpen, providedState, setProvidedState }),
        [providedState, open]
    );

    useEffect(() => {
        if (!open && providedState) {
            setProvidedState(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <NotificationDrawerContext.Provider value={notificationContextValue}>
            <DrawerContext.Provider value={drawerContextValue}>
                <PasswordValidationWrapper>{children}</PasswordValidationWrapper>
                <LayoutDrawerPanel />
            </DrawerContext.Provider>
        </NotificationDrawerContext.Provider>
    );
}
