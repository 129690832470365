import { App } from "./App";
import { hydrateState } from "./hydrateState";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { REACT_APP_ENV, REACT_GTM_ID } from "./services/utils/const";
import * as Sentry from "@sentry/react";
import TagManager from "@sooro-io/react-gtm-module";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import React from "react";

import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import { store } from "./store";

if (REACT_APP_ENV === "live") {
    Sentry.init({
        dsn: "https://e50b2e8e8e714cbbbfb352d28e1ef56e@o4505323263688704.ingest.sentry.io/4505323266375680",
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            }),
            new Sentry.Replay(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

    const tagManagerArgs = {
        gtmId: REACT_GTM_ID,
        nonce: "julaya-gtm",
    };

    TagManager.initialize(tagManagerArgs);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

hydrateState(store).finally(() =>
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <I18nextProvider i18n={i18n}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </I18nextProvider>
            </Provider>
        </React.StrictMode>
    )
);

if (window.Cypress) {
    //@ts-expect-error
    window.store = store;
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
