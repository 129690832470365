import {
    failedRequestDocument,
    failedSendDocument,
    receiveDocument,
    requestDocument,
    requestSendDocument,
    successSendDocument,
} from "./slice";
import { UserDocumentParams } from "src/services/admin/types";
import { requestGetUserDocumentByCitizen, requestGetUserDocumentByUserId } from "src/services/document/operations";
import { fileUploader } from "src/services/utils/fileUploader";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchUserDocument = createAsyncThunk(
    "fetchUserDocument",
    async (params: UserDocumentParams | null, { dispatch }) => {
        dispatch(requestDocument());
        try {
            if (params?.userId) {
                const response = await requestGetUserDocumentByUserId(params.userId);
                dispatch(receiveDocument(response));
            } else if (params?.countryOfCitizenship) {
                const response = await requestGetUserDocumentByCitizen(params.countryOfCitizenship);
                dispatch(receiveDocument(response));
            } else {
                dispatch(receiveDocument(null));
            }
        } catch (error) {
            dispatch(failedRequestDocument());
        }
    }
);

export const handleSendUserDocument = createAsyncThunk(
    "handleSendUserDocument",
    async ({ params, userId }: { params: any; userId: string }, { dispatch }) => {
        dispatch(requestSendDocument(params.slug));
        try {
            const response = await fileUploader({ endpoint: "/admin/documents", params });

            if (userId) {
                await dispatch(fetchUserDocument({ userId }));
            }
            dispatch(successSendDocument());

            return response;
        } catch (error: any) {
            dispatch(failedSendDocument({ error: error?.message as string }));
            return Promise.reject(error);
        }
    }
);
