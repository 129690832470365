import { Typography } from "../Typography/Typography";
import classnames from "classnames";

import { HtmlHTMLAttributes, ReactNode } from "react";

import "./styles.scss";

type BulletProps = {
    type?: string;
    bullet?: ReactNode;
    className?: string;
    [key: string]: any;
} & HtmlHTMLAttributes<HTMLDivElement>;

export const Bullet = ({ children, type, bullet: _bullet, className, ...attrs }: BulletProps) => {
    let bullet = _bullet;

    if (type === "icon" && bullet) {
        bullet = (
            <div className='jla-bullet-icon' data-testid='bullet-icon'>
                {bullet}
            </div>
        );
    }

    if (type === "dot") {
        bullet = (
            <div data-testid='bullet-dot'>
                <Typography className='primary' variant='h4' message='·' />
            </div>
        );
    }

    return (
        <div className={`jla-bullet ${className ? className : ""}`} data-testid='bullet-container'>
            {bullet}
            <div
                className={classnames(`jla-bullet-content${bullet ? "" : "-m0"}`)}
                {...attrs}
                data-testid='bullet-content'
            >
                {children}
            </div>
        </div>
    );
};
