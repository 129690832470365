import { TransactionContext } from "src/shared/context/transaction";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { useContext, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

function removeDuplicatesById<T extends { customId: any }>(array: T[]): T[] {
    const res = array.reduce<{ [key: string]: T }>((res, item) => {
        res[item.customId] = item;
        return res;
    }, {});

    return Object.values(res);
}

export function HistoryWalletDrawerSignatures() {
    const transaction = useContext(TransactionContext);
    const { t } = useTranslation();
    const { signatures } = transaction;

    const signaturesComputed = useMemo(() => {
        if (!signatures) return [];

        const uniqueSignatures = removeDuplicatesById(signatures);

        return uniqueSignatures.sort((a, b) => new Date(a.signedAt).getTime() - new Date(b.signedAt).getTime());
    }, [signatures]);

    return (
        <div className='drawer-content-section history-wallet-drawer-signatures'>
            <Accordion
                noHeaderPadding
                title={`${t("Tasks.signatories")} (${signaturesComputed?.length ?? 0})`}
                color='white'
            >
                <div className='signature-list-container'>
                    {signaturesComputed &&
                        signaturesComputed.map((signature, index) => (
                            <div
                                key={`signature-${signature.customId}-${signature.signedAt}`}
                                className='signature-item-container'
                            >
                                <div className='signature-number-squared'>
                                    <Typography message={`#${index + 1}`} />
                                </div>
                                <div className='signature-content'>
                                    <Typography message={signature.fullname} />
                                    <Typography
                                        message={computeTimestampToString(signature.signedAt, "DD/MM/YY - HH:mm")}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            </Accordion>
        </div>
    );
}
