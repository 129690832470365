export type BillingEnterpriseFieldNames = "payerReference" | "invoiceReference";

export type BillingEnterpriseFieldData = {
    display: boolean;
    label: string;
    patern: string;
    required: boolean;
};

export type BillingEnterprise = {
    id: number;
    avatar: string | null;
    customId: number;
    prefix: null | string;
    description: null | string;
    name: string;
    slug: null | string;
    createdAt: Date;
    updatedAt: Date;
    billerReference: string;
    billsWalletReference: string;
    address: {
        city: string;
        postCode: number;
        addressLine: string | null;
    };
    bills?: {
        nbrReceived?: string;
        amountReceived?: string;
    };
    billerFields: {
        [fieldName in BillingEnterpriseFieldNames]: BillingEnterpriseFieldData;
    };
    options: null;
    doubleValidation: boolean;
    isActive: boolean;
    isPublic: boolean;
    supportCustomId: null;
    hasLoan: boolean;
    mansaRadical: null;
    includeFees: boolean;
    api: null;
    sector: string;
};

export type BillingUpdateBillerDisponibilityPayload = {
    isPublic: boolean;
};

export type BillingUpdateBillerInvoiceReceiptPayload = {
    label: string;
    walletReference: number;
};

export type BillingUpdateBillerDetailsPayload = {
    description?: string | null;
    sector?: string;
    billerFields: BillingEnterprise["billerFields"];
};

export type BillingBillerUpdatePayload =
    | BillingUpdateBillerInvoiceReceiptPayload
    | BillingUpdateBillerDetailsPayload
    | BillingUpdateBillerDisponibilityPayload;

export const isBillerUpdatePayloadInvoiceReceipt = (
    payload: BillingBillerUpdatePayload
): payload is BillingUpdateBillerInvoiceReceiptPayload => {
    return "walletReference" in (payload as BillingUpdateBillerInvoiceReceiptPayload);
};

export const isBillerUpdatePayloadDetails = (
    payload: BillingBillerUpdatePayload
): payload is BillingUpdateBillerDetailsPayload => {
    return (
        "sector" in (payload as BillingUpdateBillerDetailsPayload) ||
        "description" in (payload as BillingUpdateBillerDetailsPayload) ||
        "billerFields" in (payload as BillingUpdateBillerDetailsPayload)
    );
};

export const isBillerUpdatePayloadDisponibility = (
    payload: BillingBillerUpdatePayload
): payload is BillingUpdateBillerDisponibilityPayload => {
    return "isPublic" in (payload as BillingUpdateBillerDisponibilityPayload);
};
