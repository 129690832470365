import classnames from "classnames";
import { EditUserContext } from "src/shared/context/editUser";
import { CompanyRole } from "src/shared/models/UserCompany";

import { useContext, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { getAuthUser } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

export const AdminAccountDetailDrawerArchiveUser = () => {
    const { setPayload, editingSection, setEditingSection, handleOpenPasswordDialog } = useContext(EditUserContext);
    const { t } = useTranslation();
    const user = useAppSelector(getAuthUser);
    const { userDetail } = useAppSelector((state) => getUserDetail(state));
    const [isDisabledFromRoles, setIsDisabledFromRoles] = useState(false);

    const archiveContainerRef = useRef<HTMLDivElement>(null);
    const [info, setInfo] = useState("");
    const [errors, setErrors] = useState<{ info?: boolean }>();

    const isEditing = editingSection === "archive";

    useEffect(() => {
        if (isEditing) {
            handleScroll();
        } else {
            setPayload("");
        }
        // eslint-disable-next-line
    }, [isEditing]);

    useEffect(() => {
        if (userDetail?.role === CompanyRole.ADMIN && user?.role !== CompanyRole.ADMIN) {
            setIsDisabledFromRoles(true);
        }
        if (userDetail?.role === CompanyRole.ADMIN_ONLY && user?.role !== CompanyRole.ADMIN) {
            setIsDisabledFromRoles(true);
        }
    }, [user, userDetail]);

    const handleScroll = () => {
        document.querySelector(".edit-user-drawer-content")?.scrollTo({
            top: archiveContainerRef?.current?.offsetTop,
            left: 0,
            behavior: "smooth",
        });
    };

    const handleToggleEditing = () => {
        if (!isDisabledFromRoles) {
            setEditingSection(!isEditing ? "archive" : null);
            setErrors({});
            setInfo("");
        }
    };

    const handleArchive = () => {
        let mErrors: { info?: boolean } = {};
        if (info !== "SUPPRIMER") {
            mErrors.info = true;
        }

        if (Object.keys(mErrors).length !== 0) {
            setErrors(mErrors);
        } else {
            setErrors({});
            setPayload({ archiveStatus: true });
            handleOpenPasswordDialog();
        }
    };

    const handleChangeInfo = (value: string) => {
        setInfo(value);
    };

    return (
        <div
            className={classnames("drawer-content-section users-details-drawer-archive-user", {
                disabled: editingSection && !isEditing,
            })}
            ref={archiveContainerRef}
        >
            <div className='drawer-content-section-header'>
                <Typography className={"fw-bold"} message={t("Users.archive")} />
                <div className={classnames({ disabled: editingSection && !isEditing })} onClick={handleToggleEditing}>
                    <Typography
                        className={`fw-bold cursor-pointer ${isDisabledFromRoles ? "disabled" : ""} ${
                            isEditing ? "color-warning" : "color-error"
                        }`}
                        message={!isEditing ? t("CommonUse.archive") : t("CommonUse.being-modified")}
                    />
                </div>
            </div>

            {isEditing && (
                <div className='drawer-content-section-body content-section'>
                    <Alert
                        icon='exclamation-triangle'
                        color='error'
                        message={t("Users.if-archived")}
                        subMessage={t<string>("Users.archive-description")}
                    />
                    <Input
                        label={t("CommonUse.checking").toString()}
                        placeholder={t("CommonUse.verification-words")}
                        onChange={handleChangeInfo}
                        value={info}
                        name='info'
                        autoComplete='off'
                        variant={errors?.info ? "error" : undefined}
                        underMessage={
                            errors?.info
                                ? t("CommonUse.wrong-verification-words").toString()
                                : t("CommonUse.archive-words", { entity: t("Users.a-user") }).toString()
                        }
                    />
                    <div className='button-part'>
                        <Button
                            label={t("CommonUse.cancel")}
                            className='flex-grow-1'
                            variant='tertiary'
                            onClick={handleToggleEditing}
                        />
                        <Button
                            label={t("CommonUse.archive")}
                            onClick={handleArchive}
                            className='flex-grow-1'
                            disabled={info.length === 0}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
