import { logoutResetStore } from "../auth/Actions";
import { adminActivityLogsSlice } from "./activityLogs/slice";
import { adminBeneficiariesSlice } from "./adminBeneficiaries/slice";
import { adminWalletsSlice } from "./adminWallets/slice";
import { adminDocumentsSlice } from "./documents/slice";
import { adminExpensePoliciesSlice } from "./expensePolicies/slice";
import { adminUserDetailSlice } from "./userDetail/slice";
import { adminUsersListSlice } from "./usersList/slice";
import { AdminDashboardInfoResponse } from "src/services/admin/types";

import { PayloadAction, combineReducers, createSlice } from "@reduxjs/toolkit";

interface AdminState {
    loading: boolean;
    dashboardInfo: AdminDashboardInfoResponse | null;
}

const initialState = {
    loading: false,
    dashboardInfo: null,
} as AdminState;

export const adminSlice = createSlice({
    name: "admin",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestDashboardInfo: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        failedRequestDashboardInfo: (state) => {
            return {
                ...state,
                loading: false,
            };
        },
        receiveDashboardInfo: (state, { payload }: PayloadAction<AdminDashboardInfoResponse>) => {
            return {
                ...state,
                dashboardInfo: {
                    ...payload,
                },
                loading: false,
            };
        },
    },
});

export const { requestDashboardInfo, failedRequestDashboardInfo, receiveDashboardInfo } = adminSlice.actions;

export const adminReducer = combineReducers({
    adminReducer: adminSlice.reducer,
    usersListReducer: adminUsersListSlice.reducer,
    userDetailReducer: adminUserDetailSlice.reducer,
    adminWalletsReducer: adminWalletsSlice.reducer,
    adminBeneficiariesReducer: adminBeneficiariesSlice.reducer,
    documents: adminDocumentsSlice.reducer,
    expensePolicies: adminExpensePoliciesSlice.reducer,
    activityLogs: adminActivityLogsSlice.reducer,
});
