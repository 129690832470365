import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getState = (state: RootState) => state.admin.activityLogs;

export const getAdminActivityLogsIsLoading = createSelector(getState, (state) => state.loading);
export const getAdminActivityLogs = createSelector(getState, (state) => state.items);
export const getAdminActivityLogsLastEvaluatedKey = createSelector(getState, (state) => state.lastEvaluatedKey);

export const getAdminActivityLogsUserFilter = createSelector(getState, (state) => state.filters.userIds);
export const getAdminActivityLogsActionSlugFilter = createSelector(getState, (state) => state.filters.actionSlugs);
export const getAdminActivityLogsDateFilter = createSelector(getState, (state) => state.filters.date);
export const getAdminActivityLogsQueryFilter = createSelector(getState, (state) => state.filters.query);

export const getAdminActivityLogIsFilterChanged = createSelector(getState, (state) => {
    return Object.values(state.filters).some((value) => value !== undefined);
});
