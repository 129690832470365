import { AdminActivityLog, AdminActivityLogSignataires } from "./types";

import { TFunction } from "i18next";

type ComputeSignatairesProps = {
    t: TFunction<"translation", string, "translation">;
    newUsers: AdminActivityLogSignataires;
    oldUsers: AdminActivityLogSignataires;
    userLogged: AdminActivityLog["user"];
    details: AdminActivityLog["details"];
};

const computeSuperSignatairesLogs = ({ t, newUsers, oldUsers, userLogged, details }: ComputeSignatairesProps) => {
    const newUserFiltered = newUsers.filter((user) => user.isSuperSignature);
    const oldUsersFiltered = oldUsers.filter((user) => user.isSuperSignature);
    const superSignatairesDeletedLogs = oldUsersFiltered.map((superSignataire) => {
        const isRemoved = !newUserFiltered.find((user) => user.id === superSignataire.id);
        return isRemoved
            ? t("AdminActivity.delete-super-signataire", {
                  userName: `${userLogged.firstname} ${userLogged.lastname}`,
                  newUserName: `${superSignataire.firstname ?? ""} ${superSignataire.lastname ?? ""}`,
                  walletName: details?.walletName ?? "",
              })
            : null;
    });

    const superSignatairesAddedLogs = newUserFiltered.map((superSignataire) => {
        const isAdded = !oldUsersFiltered.find((user) => user.id === superSignataire.id);
        return isAdded
            ? t("AdminActivity.add-super-signataire", {
                  userName: `${userLogged.firstname} ${userLogged.lastname}`,
                  newUserName: `${superSignataire.firstname ?? ""} ${superSignataire.lastname ?? ""}`,
                  walletName: details?.walletName ?? "",
              })
            : null;
    });
    return [...superSignatairesDeletedLogs, ...superSignatairesAddedLogs];
};

const computeSignataireLogsSwitchOrder = ({
    t,
    newUsers,
    oldUsers,
    userLogged,
    details,
    order,
}: ComputeSignatairesProps & { order: boolean }) => {
    const usersLogs = newUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            return t(order ? "AdminActivity.add-ordered-signataire" : "AdminActivity.add-unorder-signataire", {
                userName: `${userLogged.firstname} ${userLogged.lastname}`,
                newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                order: user.order,
                walletName: details?.walletName ?? "",
            });
        });

    return [
        t(
            order ? "AdminActivity.switch-order-to-ordered-wallet" : "AdminActivity.switch-order-to-unordered-wallet",

            {
                userName: `${userLogged.firstname ?? ""} ${userLogged.lastname ?? ""}`,
                walletName: details?.walletName ?? "",
            }
        ),
        ...computeSuperSignatairesLogs({ t, newUsers, oldUsers, userLogged, details }),
        ...usersLogs,
    ].filter((sentence) => sentence);
};

const computeSignataireLogsOrdered = ({ t, newUsers, oldUsers, userLogged, details }: ComputeSignatairesProps) => {
    const usersCreated = newUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            const isCreated = !oldUsers.some((oldUser) => user.id === oldUser.id);
            const oldUser = oldUsers.find((oldUser) => user.id === oldUser.id && user.order !== oldUser.order);

            if (isCreated || oldUser) {
                return t(isCreated ? "AdminActivity.add-ordered-signataire" : "AdminActivity.edit-ordered-signataire", {
                    userName: `${userLogged.firstname} ${userLogged.lastname}`,
                    newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                    order: user.order,
                    oldOrder: oldUser?.order,
                    walletName: details?.walletName ?? "",
                });
            }
            return null;
        });
    const usersDeleted = oldUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            const isDeleted = !newUsers.some((oldUser) => user.id === oldUser.id);

            if (isDeleted) {
                return t("AdminActivity.delete-ordered-signataire", {
                    userName: `${userLogged.firstname} ${userLogged.lastname}`,
                    newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                    order: user.order,
                    walletName: details?.walletName ?? "",
                });
            }
            return null;
        });
    return [
        ...computeSuperSignatairesLogs({ t, newUsers, oldUsers, userLogged, details }),
        ...usersCreated,
        ...usersDeleted,
    ].filter((sentence) => sentence);
};

const computeSignataireLogsUnordered = ({ t, newUsers, oldUsers, userLogged, details }: ComputeSignatairesProps) => {
    const usersCreated = newUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            const isCreated = !oldUsers.some((oldUser) => user.id === oldUser.id);

            if (isCreated) {
                return t("AdminActivity.add-unorder-signataire", {
                    userName: `${userLogged.firstname} ${userLogged.lastname}`,
                    newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                    walletName: details?.walletName ?? "",
                });
            }
            return null;
        });
    const usersDeleted = oldUsers
        .filter((user) => !user.isSuperSignature)
        .sort((userA, userB) => userA.order - userB.order)
        .map((user) => {
            const isDeleted = !newUsers.some((oldUser) => user.id === oldUser.id);

            if (isDeleted) {
                return t("AdminActivity.delete-unordered-signataire", {
                    userName: `${userLogged.firstname} ${userLogged.lastname}`,
                    newUserName: `${user.firstname ?? ""} ${user.lastname ?? ""}`,
                    walletName: details?.walletName ?? "",
                });
            }
            return null;
        });
    return [
        ...computeSuperSignatairesLogs({ t, newUsers, oldUsers, userLogged, details }),
        ...usersCreated,
        ...usersDeleted,
    ].filter((sentence) => sentence);
};

export const computeSignataireLogs = (
    t: TFunction<"translation", string, "translation">,
    userLogged: AdminActivityLog["user"],
    details: AdminActivityLog["details"],
    body: AdminActivityLog["body"]
) => {
    const newUsers = details?.newUserWallets;
    const oldUsers = details?.oldUserWallets;

    if (newUsers && oldUsers) {
        if (
            typeof details?.oldIsOrderedSignatures === "boolean" &&
            typeof body?.isOrderedSignatures === "boolean" &&
            details.oldIsOrderedSignatures !== body.isOrderedSignatures
        ) {
            return computeSignataireLogsSwitchOrder({
                t,
                newUsers,
                oldUsers,
                details,
                userLogged,
                order: body.isOrderedSignatures,
            });
        }
        if (body?.isOrderedSignatures) {
            return computeSignataireLogsOrdered({ t, newUsers, oldUsers, userLogged, details });
        }
        return computeSignataireLogsUnordered({ t, newUsers, oldUsers, userLogged, details });
    }

    return null;
};
