import { withAuthorization } from "src/shared/utils/withAuthorization";
import { CompanyRole } from "src/shared/models/UserCompany";
import { AdminWalletEditPanel } from "src/components/Admin/Wallets/Edit/Panel";

const AdminWalletEdit = () => {
    return <AdminWalletEditPanel />;
};

export const AdminWalletEditPage = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminWalletEdit
);