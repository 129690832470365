import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import fr from './locales/fr.json';

const defaultLanguage = 'fr';
const languageKey = 'i18nextLng';

if (typeof localStorage !== 'undefined') {
    if (!localStorage.getItem(languageKey)) {
        localStorage.setItem(languageKey, defaultLanguage);
    }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
        fr: { translation: fr },
        en: { translation: en },
    },
    fallbackLng: 'fr',
    debug: process.env.NODE_ENV !== 'live' || process.env.NODE_ENV !== 'staging',
    interpolation: {
      escapeValue: false,
    },
    
  });

export default i18n;