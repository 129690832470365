import { TasksDetailsDrawerSectionsActivity } from "./Sections/Activity";
import { TasksDetailsDrawerSectionsDetails } from "./Sections/Details";
import { TasksDetailsDrawerSectionsSignatories } from "./Sections/Signatures";
import { TasksDetailsDrawerSectionsStatus } from "./Sections/Status";
import { TasksDetailsDrawerTransactionStatus } from "./Sections/TransactionStatus";
import { TaskListApiReturnItem } from "src/services/tasks/types";

import "./styles.scss";

type Props = {
    task: TaskListApiReturnItem;
};

export function TasksDetailsDrawerBody({ task }: Props) {
    return (
        <div className='task-details-drawer-body'>
            <TasksDetailsDrawerSectionsStatus task={task} />
            <TasksDetailsDrawerTransactionStatus task={task} />
            <TasksDetailsDrawerSectionsDetails task={task} />
            <TasksDetailsDrawerSectionsSignatories task={task} />
            <TasksDetailsDrawerSectionsActivity task={task} />
        </div>
    );
}
