import { Icon } from "../Icons/Icon";
import { IconListType } from "../Icons/IconList";
import { Typography } from "../Typography/Typography";
import clsx from "classnames";

import { ChangeEvent, HTMLInputTypeAttribute, InputHTMLAttributes, useEffect, useState } from "react";

import "./styles.scss";

export type InputProps = {
    placeholder: string;
    label?: string;
    hint?: string;
    onChange?: (value: string) => void;
    type?: HTMLInputTypeAttribute;
    variant?: "primary" | "success" | "error" | "warning";
    inputIcon?: IconListType;
    underMessage?: string;
    className?: string;
    inputClassname?: string;
    onBlur?: () => void;
    testId?: string;
    mandatory?: boolean;
    allowedChar?: RegExp;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;

export const Input = ({
    placeholder,
    label,
    hint,
    onChange,
    value,
    type = "classic",
    variant = "primary",
    inputIcon,
    underMessage,
    className,
    inputClassname = "",
    onBlur,
    disabled,
    testId,
    mandatory = false,
    allowedChar,
    ...inputProps
}: InputProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputType, setInputType] = useState(type);

    useEffect(() => {
        setInputType(type);
    }, [type]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (allowedChar && !allowedChar.test(inputValue)) {
            return;
        }
        if (onChange) {
            onChange(inputValue);
        }
    };

    const handleSwitchInputType = () => {
        if (inputType === "password") {
            setInputType("text");
        } else {
            setInputType("password");
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleOnBlur = () => {
        setIsFocused(false);
        onBlur && onBlur();
    };

    return (
        <div className={`input-container ${className} variant-${variant} type-${type}`} data-testid='input-container'>
            {(label || hint) && (
                <div className='input-label'>
                    <div className='label-input-mandatory'>
                        <Typography
                            message={label}
                            className={`${disabled ? "color-neutral-400" : "color-neutral-900"} fw-bold`}
                        />
                        {mandatory && <Typography message={" *"} className='color-error fw-bold' />}
                    </div>
                    <Typography message={hint} className='color-neutral-500' />
                </div>
            )}

            <div className={`input variant-${variant} ${isFocused ? "focused" : ""} ${inputClassname}`}>
                {inputIcon && <Icon name={inputIcon} className='mr-2 my-auto' data-testid='input-icon' />}
                <input
                    {...inputProps}
                    disabled={disabled}
                    className={clsx("input-field", {
                        focused: isFocused,
                        disabled: disabled,
                    })}
                    type={inputType}
                    placeholder={placeholder}
                    value={value}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleOnBlur}
                    data-testid={testId}
                />
                {type === "password" && (
                    <Icon
                        name={`${inputType === "password" ? "eye" : "eye-off"}`}
                        onClick={handleSwitchInputType}
                        className='my-auto'
                        data-testid='icon-password'
                    />
                )}
            </div>
            {underMessage && (
                <div
                    className={`under-message ${
                        variant === "primary" ? "color-neutral-500" : `variant-${variant}`
                    } fadeInUp`}
                >
                    {variant === "success" && <Icon size='sm' name='check-circle-filled' data-testid='icon-success' />}
                    {variant === "error" && <Icon size='sm' name='x-circle-filled' data-testid='icon-error' />}
                    {variant === "warning" && (
                        <Icon size='sm' name='exclamation-triangle-filled' data-testid='icon-warning' />
                    )}
                    <Typography message={underMessage} testId='under-message' size='xs' />
                </div>
            )}
        </div>
    );
};
