import { failedRequestUsers, receiveUsersList, requestUsersList } from "./slice";
import { requestGetUsersList } from "src/services/admin/operations";
import { UserRequestParams } from "src/services/admin/types";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchUsersList = createAsyncThunk("fetchUsersList", async (props: UserRequestParams, { dispatch }) => {
    dispatch(requestUsersList());
    try {
        const response = await requestGetUsersList(props);
        dispatch(receiveUsersList(response));
    } catch (error) {
        dispatch(failedRequestUsers());
        return error;
    }
});
