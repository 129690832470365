import { BillingEnterprise } from "src/services/billing/enterprise/type";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

interface BillersState {
    isLoading: boolean;
    error: null | string;
    data?: BillingEnterprise;
}

const initialState: BillersState = {
    error: null,
    isLoading: false,
};

export const billingEnterpriseSlice = createSlice({
    name: "enterpriseSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestBillingEnterprise: (state) => {
            state.error = null;
            state.isLoading = true;
        },
        receiveBillingEnterprise: (state, { payload }: PayloadAction<{ data: BillingEnterprise }>) => {
            if (state.data) {
                state.data = { ...state.data, ...payload.data };
            } else {
                state.data = payload.data;
            }
            state.isLoading = false;
        },
        receiveBillingEnterpriseWallet: (
            state,
            { payload }: PayloadAction<{ walletReference: BillingEnterprise["billsWalletReference"] }>
        ) => {
            if (state.data) {
                state.data.billsWalletReference = payload.walletReference;
            }
        },
        failedBillingEnterprise: (state, { payload }: PayloadAction<{ error: string }>) => {
            state.isLoading = false;
            state.error = payload.error;
        },
    },
});

export const {
    requestBillingEnterprise,
    receiveBillingEnterprise,
    receiveBillingEnterpriseWallet,
    failedBillingEnterprise,
} = billingEnterpriseSlice.actions;

export const billingEnterpriseSliceReducer = billingEnterpriseSlice.reducer;
