import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { getAdminExpensePoliciesCreateIsForBeneficiariesOnly } from "src/modules/admin/expensePolicies/selectors";
import { switchAdminExpensePolicyCreateIsForBeneficiariesOnly } from "src/modules/admin/expensePolicies/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";

type Props = {
    step?: number;
    handleSetStep?: (newStep: number) => () => void;
};

export function AdminExpensePolicyCreateDrawerBeneficiary({ step, handleSetStep }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const isForBeneficiariesOnly = useAppSelector((state) =>
        getAdminExpensePoliciesCreateIsForBeneficiariesOnly(state)
    );

    const handleChangeIsForBeneficiariesOnly = () => {
        dispatch(switchAdminExpensePolicyCreateIsForBeneficiariesOnly());
    };

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter" && handleSetStep && step === 3) {
                handleSetStep(4)();
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    });

    return (
        <StepAccordion
            showSuccess={false}
            title={t("Expense-policy.pay-beneficiary")}
            subTitle={t<string>(isForBeneficiariesOnly ? "Expense-policy.active" : "Expense-policy.disabled")}
            icon='user-group'
            currentStep={handleSetStep ? step : 5}
            onOpen={handleSetStep ? handleSetStep(3) : undefined}
            step={3}
            footer={handleSetStep && <Button label={t("CommonUse.validate")} onClick={handleSetStep(4)} />}
        >
            <div className='expense-policy-beneficiary'>
                <div className='switch-container'>
                    <Switch isOn={isForBeneficiariesOnly} onToggle={handleChangeIsForBeneficiariesOnly} />
                    <div className='d-flex flex-column'>
                        <Typography className='fw-bold' message={t("Expense-policy.pay-only-beneficiary")} />
                        <Typography
                            className='color-neutral-500'
                            message={t("Expense-policy.payment-to-beneficiary")}
                        />
                    </div>
                </div>
                <Alert
                    color='warning'
                    icon='information-circle'
                    message={t("Expense-policy.payment-to-beneficiary-alert")}
                />
            </div>
        </StepAccordion>
    );
}
