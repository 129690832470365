import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { BillingHistoryPanel } from "src/components/Billing/History/Panel";

export const BillingHistoryPageUnauthorized = () => {
    return <BillingHistoryPanel />;
};

export const BillingHistoryPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN, CompanyRole.USER],
})(BillingHistoryPageUnauthorized);
