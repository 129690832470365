import {
    AdminExpensePolicyForm,
    failedAdminExpensePolicyCreateOrUpdate,
    failedRequestAdminExpensePolicies,
    receiveAdminExpensePolicies,
    receiveAdminExpensePolicy,
    removeAdminExpensePolicy,
    requestAdminExpensePolicies,
    requestAdminExpensePolicyCreateOrUpdate,
} from "./slice";
import {
    createAdminExpensePolicy,
    deleteAdminExpensePolicy,
    handleRequestAdminExpensePolicies,
    updateAdminExpensePolicy,
} from "src/services/admin/expensePolicy/operation";
import { ExpensePolicy, ExpensePolicyCreateOrUpdatePayload } from "src/services/admin/expensePolicy/types";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/store";

export const fetchAdminExpensePolicies = createAsyncThunk(
    "fetchAdminExpensePolicies",
    async (params: { page: number; query?: string; pageSize: number }, { dispatch }) => {
        dispatch(requestAdminExpensePolicies());
        try {
            const response = await handleRequestAdminExpensePolicies(params);
            dispatch(receiveAdminExpensePolicies({ ...response, page: params.page }));
        } catch (error: any) {
            dispatch(failedRequestAdminExpensePolicies());
        }
    }
);

const computeCreateExpensePolicyParams = (props: AdminExpensePolicyForm, walletsWithExpensePolicy: number[]) => {
    const {
        allUsers,
        allWallets,
        isForBeneficiariesOnly,
        excludedUsers,
        excludedWallets,
        limitPerOperation,
        limitPerUnitOfTime,
        title,
        unitOfTime,
    } = props;

    const params: ExpensePolicyCreateOrUpdatePayload = {
        excludedUserIds: !allUsers ? excludedUsers?.map((item) => parseInt(item.id)) ?? [] : [],
        excludedWalletIds: !allWallets
            ? [...new Set([...(excludedWallets?.map((item) => parseInt(item.id)) ?? []), ...walletsWithExpensePolicy])]
            : [...walletsWithExpensePolicy],
        limitPerOperation: parseInt(String(limitPerOperation)),
        limitPerUnitOfTime: parseInt(String(limitPerUnitOfTime)),
        unitOfTime: unitOfTime?.id as ExpensePolicyCreateOrUpdatePayload["unitOfTime"],
        isForBeneficiariesOnly,
        title: String(title),
    };

    return params;
};

export const handleCreateAdminExpensePolicies = createAsyncThunk<
    ExpensePolicy,
    HandleSubmitPropsPasswordValidation & { walletsWithExpensePolicy: string[] },
    { state: RootState }
>(
    "fetchAdminExpensePolicies",
    async (
        {
            password,
            twoFactor,
            walletsWithExpensePolicy,
        }: HandleSubmitPropsPasswordValidation & { walletsWithExpensePolicy: string[] },
        { dispatch, getState }
    ) => {
        dispatch(requestAdminExpensePolicyCreateOrUpdate());
        try {
            const form = getState().admin.expensePolicies.form;
            const params = computeCreateExpensePolicyParams(
                form,
                walletsWithExpensePolicy.map((walletId) => parseInt(walletId))
            );
            const response = await createAdminExpensePolicy({
                ...params,
                password,
                ...(twoFactor ? { otp: twoFactor } : {}),
            });
            dispatch(receiveAdminExpensePolicy(response));
            return response;
        } catch (error: any) {
            dispatch(failedAdminExpensePolicyCreateOrUpdate({ error: error.message }));
            return Promise.reject(error.message);
        }
    }
);

export const handleUpdateAdminExpensePolicies = createAsyncThunk<
    ExpensePolicy,
    HandleSubmitPropsPasswordValidation & { walletsWithExpensePolicy: string[] },
    { state: RootState }
>(
    "fetchAdminExpensePolicies",
    async (
        {
            password,
            twoFactor,
            walletsWithExpensePolicy,
        }: HandleSubmitPropsPasswordValidation & { walletsWithExpensePolicy: string[] },
        { dispatch, getState }
    ) => {
        dispatch(requestAdminExpensePolicyCreateOrUpdate());
        try {
            const form = getState().admin.expensePolicies.form;
            const params = computeCreateExpensePolicyParams(
                form,
                walletsWithExpensePolicy.map((walletId) => parseInt(walletId))
            );
            const response = await updateAdminExpensePolicy({
                expensePolicyId: form.id ?? "",
                payload: { ...params, password, ...(twoFactor ? { otp: twoFactor } : {}) },
            });
            dispatch(receiveAdminExpensePolicy(response));
            return response;
        } catch (error: any) {
            dispatch(failedAdminExpensePolicyCreateOrUpdate({ error }));
            return Promise.reject(error);
        }
    }
);

export const handleDeleteAdminExpensePolicies = createAsyncThunk(
    "fetchAdminExpensePolicies",
    async (
        {
            expensePolicyId,
            password,
            twoFactor,
        }: { expensePolicyId: string | number } & HandleSubmitPropsPasswordValidation,
        { dispatch }
    ) => {
        dispatch(requestAdminExpensePolicyCreateOrUpdate());
        try {
            const response = await deleteAdminExpensePolicy({
                expensePolicyId,
                password,
                ...(twoFactor ? { otp: twoFactor } : {}),
            });
            dispatch(removeAdminExpensePolicy({ id: expensePolicyId }));
            return response;
        } catch (error: any) {
            dispatch(failedAdminExpensePolicyCreateOrUpdate({ error }));
            return Promise.reject(error);
        }
    }
);
