import { CountryOptions } from "./const";
import moment from "moment";
import { Gender, UserModel } from "src/shared/models/User";

import { useEffect, useMemo, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { CustomDatePicker } from "src/shared/components/DatePicker/DatePicker";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

const genderOptionsRaw = (t: TFunction<"translation", string, "translation">) => [
    { id: t("AdminAccountsFormsProfile.m"), label: t("CommonUse.mr") },
    { id: t("AdminAccountsFormsProfile.f"), label: t("CommonUse.mme") },
];

type ProfileKeys =
    | "gender"
    | "countryOfCitizenship"
    | "firstname"
    | "lastname"
    | "email"
    | "dateOfBirth"
    | "customReference";
type ProfileForm = Pick<UserModel, ProfileKeys>;
export type ProfileParams = ProfileForm;

type AdminAccountsFormsProfileProps = {
    detail?: UserModel | null;
    onChange: (values: ProfileParams) => void;
    handleValidatedProfile?: (validated: boolean) => void;
};

export const AdminAccountsFormsProfile = ({
    detail,
    onChange,
    handleValidatedProfile,
}: AdminAccountsFormsProfileProps) => {
    const { t } = useTranslation();

    const [selectedGenderOption, setSelectedGenderOption] = useState<SelectOption | null>(null);
    const [selectedCountryOption, setSelectedCountryOption] = useState<SelectOption | null>(null);
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [dateOfBirth, setDateOfBirth] = useState<string>("");
    const [customReference, setCustomReference] = useState<string>("");

    const GenderOptions = useMemo(() => genderOptionsRaw(t), [t]);

    useEffect(() => {
        onChange({
            firstname,
            lastname,
            email,
            dateOfBirth,
            customReference,
            gender: selectedGenderOption?.id ?? "",
            countryOfCitizenship: selectedCountryOption?.id ?? "",
        });
    }, [firstname, lastname, email, dateOfBirth, customReference, selectedGenderOption, selectedCountryOption]);

    useEffect(() => {
        const isValidatedForm =
            !!selectedGenderOption && !!selectedCountryOption && !!firstname && !!lastname && !!email && !!dateOfBirth;

        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        if (handleValidatedProfile) {
            handleValidatedProfile(isValidatedForm && isValidEmail);
        }
    }, [selectedGenderOption, selectedCountryOption, firstname, lastname, email, dateOfBirth]);

    useEffect(() => {
        if (detail) {
            setSelectedGenderOption(
                GenderOptions.find((option) =>
                    option.id ? option.id === (GenderOptions[detail.gender as number]?.id as Gender) : null
                ) || null
            );
            setSelectedCountryOption(
                CountryOptions.find((option) =>
                    option.id ? option.id === detail.countryOfCitizenship?.toLowerCase() : null
                ) || null
            );
            setFirstname(detail.firstname);
            setLastname(detail.lastname);
            setEmail(detail.email ?? "");
            setDateOfBirth(detail.dateOfBirth ?? "");

            setCustomReference(
                detail?.UserCompanies &&
                    detail.UserCompanies.length > 0 &&
                    detail.UserCompanies[0].customReference !== null
                    ? detail.UserCompanies[0].customReference
                    : ""
            );
        }
    }, [detail]);

    const handleChangeGender = (option: SelectOption) => {
        setSelectedGenderOption(option);
    };

    const handleChangeCountry = (option: SelectOption) => {
        setSelectedCountryOption(option);
    };

    const handleChangeFirstname = (value: string) => {
        setFirstname(value);
    };

    const handleChangeLastname = (value: string) => {
        setLastname(value);
    };

    const handleChangeEmail = (value: string) => {
        setEmail(value);
    };

    const handleChangeDateOfBirth = (date: Date | null) => {
        setDateOfBirth(moment(date).format("YYYY-MM-DD"));
    };

    const handleChangeCustomReference = (value: string) => {
        setCustomReference(value);
    };

    return (
        <div className='profile-form-container'>
            <div className='profile-form-item'>
                <SimpleSelect
                    label={t<string>("AdminAccountsFormsProfile.civilite")}
                    placeholder={t<string>("AdminAccountsFormsProfile.civilite")}
                    selected={selectedGenderOption}
                    onSelectionChange={handleChangeGender}
                    useRadio
                    options={GenderOptions}
                />
            </div>

            <div className='profile-form-item'>
                <SimpleSelect
                    label={t<string>("AdminAccountsFormsProfile.nationalite")}
                    options={CountryOptions}
                    placeholder={t<string>("CommonUse.country-of-citizenship")}
                    selected={selectedCountryOption}
                    useRadio
                    onSelectionChange={handleChangeCountry}
                />
            </div>

            <div className='profile-form-item'>
                <Input
                    label={t<string>("AdminAccountsFormsProfile.prenom")}
                    name='firstname'
                    value={firstname}
                    placeholder={t<string>("CommonUse.firstnames")}
                    onChange={handleChangeFirstname}
                    autoComplete='nope'
                />
            </div>

            <div className='profile-form-item'>
                <Input
                    label={t<string>("AdminAccountsFormsProfile.nom")}
                    name='lastname'
                    value={lastname}
                    placeholder={t<string>("CommonUse.lastname")}
                    onChange={handleChangeLastname}
                    autoComplete='nope'
                />
            </div>

            <div className='profile-form-item'>
                <Input
                    label={t<string>("AdminAccountsFormsProfile.adresse-mail")}
                    name='email'
                    value={email}
                    placeholder='email@example.com'
                    autoComplete='nope'
                    onChange={handleChangeEmail}
                />
            </div>

            <div className='profile-form-item'>
                <CustomDatePicker
                    label={t<string>("Users.date-of-birth")}
                    single
                    startDate={dateOfBirth && +new Date(dateOfBirth) ? new Date(dateOfBirth) : null}
                    onChangeStartDate={handleChangeDateOfBirth}
                />
            </div>

            <div className='profile-form-item'>
                <Input
                    label={t<string>("Users.user-reference") + " (" + t<string>("CommonUse.facultative") + ")"}
                    name='customReference'
                    value={customReference}
                    placeholder={t<string>("Users.personalized-reference")}
                    onChange={handleChangeCustomReference}
                />
                <Typography message={t<string>("Users.you-can-add-reference")} className='color-neutral-500' />
            </div>
        </div>
    );
};
