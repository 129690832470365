import clsx from "classnames";

import { PropsWithChildren, ReactNode, useRef, useState } from "react";

import "./styles.scss";

type ToolTipProps = {
    content: ReactNode;
    position?: "top" | "bottom" | "left" | "right";
    activation: boolean;
    fullWidth?: boolean;
    tooltipClassname?: string;
};

export const ToolTip = ({
    content,
    position = "top",
    children,
    activation,
    fullWidth,
    tooltipClassname = "",
}: PropsWithChildren<ToolTipProps>) => {
    const [visible, setVisible] = useState(false);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const handleMouseEnter = () => setVisible(true);
    const handleMouseLeave = () => setVisible(false);

    return (
        <div
            className={`tooltip-container ${fullWidth ? "w-100" : ""}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-testid='tooltip-container'
        >
            {children}

            <div
                className={clsx(`tooltip tooltip-${position} ${tooltipClassname}`, {
                    show: activation && visible,
                })}
                ref={tooltipRef}
                data-testid='tooltip'
            >
                {content}
            </div>
        </div>
    );
};
