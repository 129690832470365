import { BeneficiaryModel } from "src/shared/models/Beneficiary";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type InformationsKeys = "email" | "firstname" | "lastname" | "label";
type InformationsForm = Pick<BeneficiaryModel, InformationsKeys>;
export type InformationsParams = InformationsForm;

type CreateBeneficiaryInformationFormProps = {
    isFormValidated: boolean;
    setIsFormValidated: (value: boolean) => void;
    setInformations: (value: InformationsParams) => void;
    editInformations: BeneficiaryModel | null;
};

export const CreateBeneficiaryInformationForm = ({
    isFormValidated,
    setIsFormValidated,
    setInformations,
    editInformations,
}: CreateBeneficiaryInformationFormProps) => {
    const { t } = useTranslation();

    const [email, setEmail] = useState(editInformations?.email ?? "");
    const [firstname, setFirstname] = useState(editInformations?.firstname ?? "");
    const [lastname, setLastname] = useState(editInformations?.lastname ?? "");
    const [label, setLabel] = useState(editInformations?.label ?? "");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isEmailTouched, setIsEmailTouched] = useState(false);

    useEffect(() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsFormValidated(firstname !== "" && lastname !== "" && (email === "" || emailRegex.test(email)));
        setInformations({ email, firstname, lastname, label });
        // eslint-disable-next-line
    }, [firstname, lastname, label, email]);

    const handleChangeInput = (name: string) => (value: string) => {
        switch (name) {
            case "email":
                setEmail(value);
                if (value !== "" && isEmailTouched) {
                    setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value));
                }
                break;
            case "firstname":
                setFirstname(value);
                break;
            case "lastname":
                setLastname(value);
                break;
            case "label":
                setLabel(value);
                break;
            default:
                break;
        }
    };

    const handleBlur = (name: string) => () => {
        if (name === "email") {
            setIsEmailTouched(true);
            if (email !== "") {
                setIsEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
            } else {
                setIsEmailValid(true);
            }
        }
    };

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <Typography
                    className='fw-bold'
                    message={t("CreateBeneficiaryInformationForm.informations-sur-le-beneficiaire")}
                />
                <Typography
                    className={`${!isFormValidated ? "color-warning" : "color-success"} fw-bold`}
                    message={!isFormValidated ? t("CommonUse.to-complete") : t("CommonUse.done")}
                />
            </div>
            <div className='beneficiary-informations-content drawer-content-section-body'>
                <div className='form-part'>
                    <div className='inline-inputs'>
                        <Input
                            autoFocus
                            label={t("CreateBeneficiaryInformationForm.prenom-du-beneficiaire").toString()}
                            name='firstname'
                            value={firstname}
                            placeholder={t("CreateBeneficiaryInformationForm.prenom")}
                            onChange={handleChangeInput("firstname")}
                        />
                        <Input
                            label={t("CreateBeneficiaryInformationForm.nom-du-beneficiaire").toString()}
                            name='lastname'
                            value={lastname}
                            placeholder={t("CreateBeneficiaryInformationForm.nom")}
                            onChange={handleChangeInput("lastname")}
                        />
                    </div>
                    <Input
                        label={t("CreateBeneficiaryInformationForm.adresse-mail-facultatif").toString()}
                        name='email'
                        value={email}
                        underMessage={!isEmailValid ? t<string>("CommonUse.invalid-email") : undefined}
                        variant={isEmailValid ? "primary" : "error"}
                        placeholder={t("CommonUse.email-exemple")}
                        onChange={handleChangeInput("email")}
                        onBlur={handleBlur("email")}
                    />
                    <Input
                        label={t("Beneficiaries.add-note").toString()}
                        name='label'
                        value={label}
                        placeholder={t("Beneficiaries.add-note-placeholder")}
                        onChange={handleChangeInput("label")}
                    />
                </div>
            </div>
        </div>
    );
};
