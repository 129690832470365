import { Select } from "../../Select/Select";
import { Typography } from "../../Typography/Typography";

import { useEffect, useState } from "react";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

type Props = {
    placeholder?: string;
    options: SelectOption[];
    selected?: SelectOption | null;
    onSelectionChange: (selectedItems: SelectOption) => void;
    disabled?: boolean;
};

export const PhonePrefixSelect = ({ placeholder, options, onSelectionChange, selected, disabled }: Props) => {
    const [selectedItem, setSelectedItem] = useState<SelectOption | null | undefined>(selected);
    const [closeSelect, setCloseSelect] = useState(false);

    useEffect(() => {
        setCloseSelect(false);
    }, [closeSelect]);

    useEffect(() => {
        setSelectedItem(!selected ? undefined : selected);
    }, [selected]);

    const handleItemClick = (item: SelectOption) => () => {
        setSelectedItem(item);
        if (onSelectionChange) {
            onSelectionChange(item);
        }
        setCloseSelect(true);
    };

    const renderLabel = () => {
        return selectedItem ? (
            <div className='label-item'>
                {selectedItem?.prefixImage && (
                    <img
                        src={selectedItem?.prefixImage}
                        alt={`${selectedItem.label} - ${selectedItem.prefixImage}`}
                        className='country-flag'
                    />
                )}

                <Typography message={selectedItem?.label} />
            </div>
        ) : (
            <div className='label-item'>
                <Typography message={placeholder} size='xs' />
            </div>
        );
    };

    return (
        <Select
            className={`phone-prefix-select ${disabled ? "disabled" : ""}`}
            forceClose={closeSelect}
            data-testid='phone-prefix-select'
            disabled={disabled}
            content={{
                header: {
                    component: renderLabel(),
                },
                dropDownContent: {
                    body: (
                        <div className='d-flex flex-column w-100'>
                            {options.map((option, index) => (
                                <div key={index} className='select-item label-item' onClick={handleItemClick(option)}>
                                    {option.prefixImage && (
                                        <img
                                            src={option.prefixImage}
                                            alt={`${option.label}-${option.prefixImage}`}
                                            className='country-flag'
                                        />
                                    )}

                                    <Typography
                                        className='my-auto'
                                        message={option.label}
                                        data-testid='phone-prefix-select-text'
                                    />
                                </div>
                            ))}
                        </div>
                    ),
                },
            }}
        />
    );
};
