import { useCallback } from "react";

import { useTranslation } from "react-i18next";

import { getAdminExpensePoliciesQuery } from "src/modules/admin/expensePolicies/selectors";
import { setAdminExpensePolicyFilterQuery } from "src/modules/admin/expensePolicies/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Input } from "src/shared/atoms/Inputs/Input";

export function AdminExpensePolicyFilters() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const query = useAppSelector((state) => getAdminExpensePoliciesQuery(state));

    const handleSetQueryFilter = useCallback(
        (newQuery: string) => {
            dispatch(setAdminExpensePolicyFilterQuery({ value: newQuery }));
        },
        [dispatch]
    );

    return (
        <div className='expense-policy-filters-container'>
            <Input
                placeholder={t("CommonUse.search")}
                inputIcon='magnifying-glass'
                onChange={handleSetQueryFilter}
                value={query}
                className='search-input'
            />
        </div>
    );
}
