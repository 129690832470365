import { TasksTableCard } from "./TableCard";
import { Link } from "react-router-dom";
import { TaskStatus } from "src/shared/models/Task";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getTasksList } from "src/modules/tasks/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    title: string;
    status: TaskStatus[];
};

export function TasksTableColumn({ title, status }: Props) {
    const { t } = useTranslation();

    const tasks = useAppSelector((state) => getTasksList(state));

    const columnTasks = useMemo(() => {
        return Object.values(tasks)
            .reverse()
            .filter((item) => item.status && status.includes(item.status));
    }, [tasks, status]);

    return (
        <div className='tasks-table-column'>
            <div className={`table-column-status status-${status[0]}`}>
                <Typography message={title} />

                <Typography message={columnTasks.length} />
            </div>

            <>
                {status.includes("complete") ? (
                    <Link to='/tasks-list#complete' className='complete-task-link color-julaya-500 py-5'>
                        <Typography className='fw-bold text-center mr-2' message={t("Tasks.finished-tasks")} />

                        <Icon className='fw-bold' name='arrow-top-right-on-square' />
                    </Link>
                ) : columnTasks.length > 0 ? (
                    columnTasks.map((item) => <TasksTableCard key={item.id} task={item} />)
                ) : (
                    <div className='empty-task-card py-5'>
                        <Typography className='fw-bold text-center mr-2' message={t("Tasks.no-pending-task")} />
                    </div>
                )}
            </>
        </div>
    );
}
