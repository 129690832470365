import { Dispatch, SetStateAction, createContext } from "react";

export type EditUserContextProps = {
    editingSection: string | null;
    setEditingSection: Dispatch<SetStateAction<string | null>>;
    canRoleEdit: boolean;
    payload: any;
    setPayload: any;
    handleOpenPasswordDialog: any;
    onCloseDrawer: () => void;
};

export const EditUserContext = createContext<EditUserContextProps>({} as EditUserContextProps);
