import { fetchCompaniesAuthUser } from "../authCompanies/thunkActions";
import { logoutResetStore } from "./Actions";
import {
    addAuthUser,
    failedLogin,
    failedUpdateCollectorSettings,
    requestLogin,
    requestUpdateCollectorSettings,
    setAuthUserExpensePolicies,
    setAuthUserPreferencePreferredCompanyId,
    setUseBaseRouter,
    successLogin,
    updateAuthUserInformation,
    updateCollectorSettings,
} from "./slice";
import {
    LoginProps,
    createAuthUserCollectorSettings,
    login,
    requestAuthUser,
    requestAuthUserExpensePolicies,
    sendEmailVerification,
    updateAuthUserCollectorSettings,
} from "src/services/auth/operations";
import { AuthLoginResponse, CreateCollectorSettingPayload } from "src/services/auth/types";
import { UserModel } from "src/shared/models/User";
import {
    clearStorage,
    getInStorageUserPreferences,
    setInStorage,
    setInStorageUserPreferences,
} from "src/shared/utils/storage";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "src/store";

export function isAuthLoginResponse(
    res:
        | AuthLoginResponse
        | {
              tfaActive: boolean;
          }
): res is AuthLoginResponse {
    return !!(res as AuthLoginResponse).user;
}

export const sendLoginRequest = createAsyncThunk("login", async (props: LoginProps, { dispatch }) => {
    dispatch(requestLogin());

    try {
        const res = await login(props);

        if (!isAuthLoginResponse(res)) {
            dispatch(successLogin());
            return res;
        }

        const { ttl, id, userId, createdAt, user } = res;
        const useBaseRouter = !res.user.isEmailVerified;

        setInStorage("accessToken", { ttl, id, userId, createdAt });
        setInStorage("user", { ...user, id: userId });
        setInStorageUserPreferences("useBaseRouter", useBaseRouter);

        dispatch(setUseBaseRouter(useBaseRouter));
        dispatch(fetchCompaniesAuthUser())
            .unwrap()
            .finally(() => {
                dispatch(addAuthUser({ user: { ...user, id: userId } }));
                const preferredCompanyId = getInStorageUserPreferences("preferredCompanyId");

                if (preferredCompanyId) {
                    dispatch(setAuthUserPreferencePreferredCompanyId({ companyId: preferredCompanyId }));
                }
            });
        return res;
    } catch (error: any) {
        dispatch(
            failedLogin({
                error: error.message,
                remainingConnection: error?.data ?? undefined,
            })
        );
        return Promise.reject(error);
    }
});

export const requestSendEmailVerification = createAsyncThunk(
    "requestSendEmailVerification",
    async (props: { token: string }, { dispatch, getState }) => {
        try {
            const user = (getState() as RootState).auth.user;
            const res = await sendEmailVerification(props);

            if (user) {
                dispatch(updateAuthUserInformation({ isEmailVerified: true }));
                setInStorage("user", { ...user, isEmailVerified: true });
            }

            return res;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const retrieveAuthCompanyInformations = createAsyncThunk(
    "retrieveAuthCompnayInformations",
    async (_props: undefined, { dispatch }) => {
        dispatch(requestLogin());

        try {
            const user = await requestAuthUser();

            setInStorage("user", user);

            dispatch(addAuthUser({ user }));
            return user;
        } catch (error) {
            return Promise.resolve(error);
        }
    }
);

export const logout = createAsyncThunk("logout", async (_props: undefined, { dispatch }) => {
    dispatch(logoutResetStore());
    clearStorage();
});

export const fetchAuthUserExpensePolicies = createAsyncThunk(
    "fetchAuthUserExpensePolicies",
    async (_props: undefined, { dispatch }) => {
        try {
            const expensePolicies = await requestAuthUserExpensePolicies();
            dispatch(setAuthUserExpensePolicies(expensePolicies.policies));
        } catch (error) {
            throw error;
        }
    }
);

export const createOrUpdateAuthUserCollectorSettings = createAsyncThunk(
    "createOrUpdateAuthUserCollectorSettings",
    async ({ create, ...params }: CreateCollectorSettingPayload & { create: boolean }, { dispatch, getState }) => {
        try {
            dispatch(requestUpdateCollectorSettings());
            let res: UserModel["collectorInfo"];

            if (create) {
                res = await createAuthUserCollectorSettings(params);
            } else {
                res = await updateAuthUserCollectorSettings(params);
            }
            const user = (getState() as RootState).auth.user;
            setInStorage("user", { ...user, collectorInfo: res } as UserModel);
            dispatch(updateCollectorSettings(res));
            return res;
        } catch (error: any) {
            dispatch(failedUpdateCollectorSettings({ error }));
            return Promise.reject(error);
        }
    }
);
