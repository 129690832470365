import { BillingPayBillPaymentFields } from "./Fields";
import { BillingBiller } from "src/services/billing/biller/type";

import { Dispatch, SetStateAction } from "react";

import { BillReferenceFields } from "src/modules/transactions/thunkActions";

import "./styles.scss";

type TransfertDetailsProps = {
    errors: { message?: string; invoiceReference?: string; payerReference?: string };
    fields: BillReferenceFields;
    biller?: BillingBiller;
    setFields: Dispatch<SetStateAction<BillReferenceFields>>;
};

export const BillsTransfertDetails = ({ errors, biller, fields, setFields }: TransfertDetailsProps) => {
    return (
        <div className='transfert-details-container'>
            <BillingPayBillPaymentFields errors={errors} biller={biller} fields={fields} setFieldValues={setFields} />
        </div>
    );
};
