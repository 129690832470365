import {
    failedArchiveAllNotifications,
    failedArchiveNotification,
    failedRequestNewNotifications,
    failedRequestNotificationSettings,
    failedRequestNotifications,
    failedRequestReadNotifications,
    failedRequestUpdateNotificationSettings,
    receiveArchiveAllNotifications,
    receiveArchiveNotification,
    receiveNewNotifications,
    receiveNotificationSettings,
    receiveNotifications,
    receiveReadNotifications,
    receiveUpdateNotificationSettings,
    requestArchiveAllNotifications,
    requestArchiveNotification,
    requestNewNotifications,
    requestNotificationSettings,
    requestNotifications,
    requestReadNotifications,
    requestUpdateNotificationSettings,
    setNotifications,
} from "./slice";
import {
    fetchNotificationSettings,
    getNotifications,
    updateNotificationSettings,
    updateNotificationStatuses,
} from "src/services/notifications/operations";
import { NotificationsFilterParam } from "src/services/notifications/types";
import { NotificationSetting } from "src/shared/models/Notification";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchNotifications = createAsyncThunk(
    "fetchNotifications",
    async ({ params }: { params: NotificationsFilterParam }, { dispatch }) => {
        dispatch(requestNotifications());
        try {
            if (params.page === 1) {
                dispatch(setNotifications({ notifications: [], total: 0 }));
            }

            const { data, count } = await getNotifications(params);
            dispatch(receiveNotifications({ notifications: data, total: count, initLoad: params.page === 1 }));
            return data;
        } catch (error) {
            dispatch(failedRequestNotifications());
            return error;
        }
    }
);

export const archiveNotification = createAsyncThunk(
    "archiveNotification",
    async ({ id }: { id: string }, { dispatch }) => {
        dispatch(requestArchiveNotification());
        try {
            const res = await updateNotificationStatuses({
                status: "archived",
                notificationIds: [id],
                all: false,
            });
            dispatch(receiveArchiveNotification({ noteId: id }));
            return res;
        } catch (error) {
            dispatch(failedArchiveNotification());
            return error;
        }
    }
);

export const archiveAllNotifications = createAsyncThunk("archiveAllNotifications", async (_, { dispatch }) => {
    dispatch(requestArchiveAllNotifications());
    try {
        const res = await updateNotificationStatuses({
            status: "archived",
            all: true,
        });
        dispatch(receiveArchiveAllNotifications());
        return res;
    } catch (error) {
        dispatch(failedArchiveAllNotifications());
        return error;
    }
});

export const fetchNewNotifications = createAsyncThunk("fetchNewNotifications", async (_, { dispatch }) => {
    dispatch(requestNewNotifications());
    try {
        const { count } = await getNotifications({
            page: 1,
            status: ["unread"],
        });
        dispatch(receiveNewNotifications({ count }));
        return { count };
    } catch (error) {
        dispatch(failedRequestNewNotifications());
        return error;
    }
});

export const readNotifications = createAsyncThunk("archiveAllNotifications", async (_, { dispatch }) => {
    dispatch(requestReadNotifications());
    try {
        const res = await updateNotificationStatuses({
            status: "read",
            all: true,
        });
        dispatch(receiveReadNotifications());
        dispatch(fetchNewNotifications());
        return res;
    } catch (error) {
        dispatch(failedRequestReadNotifications());
        return error;
    }
});

export const getNotificationSettings = createAsyncThunk("getNotificationSettings", async (_, { dispatch }) => {
    dispatch(requestNotificationSettings());
    try {
        const res = await fetchNotificationSettings();
        dispatch(receiveNotificationSettings(res));
        return res;
    } catch (error) {
        dispatch(failedRequestNotificationSettings());
        return error;
    }
});

export const editNotificationSettings = createAsyncThunk(
    "editNotificationSettings",
    async (body: NotificationSetting, { dispatch }) => {
        dispatch(requestUpdateNotificationSettings());
        try {
            const result = await updateNotificationSettings(body);
            dispatch(receiveUpdateNotificationSettings(result));
            return result;
        } catch (error: any) {
            dispatch(failedRequestUpdateNotificationSettings(error.message));
            return error;
        }
    }
);
