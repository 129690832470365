import { Icon } from "../Icons/Icon";
import { IconListType } from "../Icons/IconList";
import { Spinner } from "../Spinner/Spinner";
import { Typography } from "../Typography/Typography";

import { ButtonHTMLAttributes, ReactNode, forwardRef } from "react";

import "./styles.scss";

export type ButtonColors = "primary" | "lilas" | "success" | "warning" | "error";
export type ButtonVariants = "primary" | "secondary" | "tertiary" | "outline" | "ghost";
export type ButtonSize = "lg" | "md" | "sm";
export type ButtonIconPosition = "left" | "right" | "both";

export type ButtonProps = {
    variant?: ButtonVariants;
    size?: ButtonSize;
    color?: ButtonColors;
    label?: string | ReactNode;
    icon?: IconListType;
    position?: ButtonIconPosition;
    testId?: string;
    loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = "primary",
            size = "md",
            color = "primary",
            label,
            icon,
            position,
            disabled = false,
            onClick,
            testId,
            loading = false,
            className,
            ...buttonProps
        }: ButtonProps,
        ref
    ) => {
        if (icon && !position) {
            position = "left";
        }

        return (
            <button
                ref={ref}
                className={`button-container variant-${variant} size-${size} ${className} ${color} ${
                    disabled ? "disabled" : ""
                }`}
                onClick={disabled ? undefined : onClick}
                {...buttonProps}
                data-testid={testId}
            >
                {icon && (position === "both" || position === "left") && (
                    <Icon name={icon} data-testid='button-icon-left' />
                )}
                {label && <Typography variant='span' className={`color-black padding-${position}`} message={label} />}
                {icon && (position === "both" || position === "right") && (
                    <Icon name={icon} data-testid='button-icon-right' />
                )}
                {loading && <Spinner size='xs' />}
            </button>
        );
    }
);
