import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminExpensePolicyPanel } from "src/components/Admin/ExpensePolicy/Panel";

const AdminExpensePolicyUnauthorized = () => {
    return <AdminExpensePolicyPanel />;
};

export const AdminExpensePolicy = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminExpensePolicyUnauthorized
);
