import { TransactionService } from "../../../../services/transactions/types";
import { Icon } from "../../Icons/Icon";
import { IconListType } from "../../Icons/IconList";
import { Typography } from "../../Typography/Typography";

import { TransactionServiceImage } from "../../../../components/History/Info/ServiceImage";

import "./styles.scss";

type DrawerInputProps = {
    handleClickDrawerInput?: () => void;
    label?: string;
    icon?: IconListType;
    serviceSlug?: TransactionService["slug"] | null;
    testId?: string;
    disabled?: boolean;
};

export const DrawerInput = ({
    handleClickDrawerInput,
    label,
    icon,
    serviceSlug,
    disabled,
    testId,
}: DrawerInputProps) => {
    return (
        <div
            className={`drawer-input-container ${disabled ? "disabled" : ""}`}
            onClick={disabled ? undefined : handleClickDrawerInput}
            data-testid={testId}
        >
            <div className='content'>
                {!serviceSlug && icon && (
                    <Icon
                        name={icon}
                        color={disabled ? "neutral-500" : "lilas-900"}
                        size='sm'
                        data-testid='drawer-input-icon'
                    />
                )}
                {serviceSlug && <TransactionServiceImage serviceSlug={serviceSlug} size='sm' />}
                {label && <Typography message={label} size='xs' />}
            </div>
            <Icon name='chevron-up-down' size='sm' />
        </div>
    );
};
