import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getState = (state: RootState) => state.billing.enterprise;

export const getBillingEnterprise = createSelector(getState, (state) => state.data);
export const getBillingEnterpriseBillsReceived = createSelector(
    getBillingEnterprise,
    (data) => data?.bills?.nbrReceived
);
export const getBillingEnterpriseBillsAmount = createSelector(
    getBillingEnterprise,
    (data) => data?.bills?.amountReceived
);
export const getBillingEnterpriseIsLoading = createSelector(getState, (state) => state.isLoading);
export const getBillingEnterpriseError = createSelector(getState, (state) => state.error);
