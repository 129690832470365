import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminWalletCreationPanel } from "src/components/Admin/Wallets/Create/Panel";

const AdminWalletCreation = () => {
    return <AdminWalletCreationPanel />;
};

export const AdminWalletCreationPage = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminWalletCreation
);
