import { ERROR_LIST } from "./const";

import { logout } from "src/modules/auth/thunkActions";
import { store } from "src/store";

enum ErrorCode {
    NotFound = 404,
    Unauthorized = 401,
    InternalServerError = 500,
    BadRequest = 400,
}

const logoutMessage = "UNAUTHORIZED";

const errorMessageKey = "ErrorsBasedOnReturnCode";

function getErrorMessage(errorCode: ErrorCode): string {
    switch (errorCode) {
        case ErrorCode.NotFound:
            return `${errorMessageKey}.notfound`;
        case ErrorCode.Unauthorized:
            return `${errorMessageKey}.unauthorized`;
        case ErrorCode.InternalServerError:
            return `${errorMessageKey}.internalservererror`;
        case ErrorCode.BadRequest:
            return `${errorMessageKey}.default`;
        default:
            return `${errorMessageKey}.default`;
    }
}

type ApiCallReturn = {
    status: number;
    ok: boolean;
    error?: { status: string };
    json: () => Promise<{ error?: string }>;
};

export async function callApiWithErrorHandling<T extends ApiCallReturn, P, O>(
    apiCall: (endpoint: P, options: O) => Promise<T>,
    endpoint: P,
    options: O
) {
    return apiCall(endpoint, options)
        .then(async (response) => {
            const { status } = response;

            if (!response.ok) {
                const errorData = await response.json();

                // waiting webSocket to remove redux dispatch from service function
                if (errorData?.error && logoutMessage === errorData.error) {
                    store.dispatch(logout());
                }

                const { error, ...props } = errorData;
                const preDefinedErrorMessage = errorData?.error && ERROR_LIST[errorData.error];
                return Promise.reject({
                    ...props,
                    message:
                        preDefinedErrorMessage ||
                        errorData?.error ||
                        getErrorMessage(status ? status : ErrorCode.NotFound),
                    status,
                });
            }
            return response;
        })
        .catch((error) => {
            const { status, message, ...props } = error;

            return Promise.reject({
                ...props,
                message: message || getErrorMessage(status ? status : ErrorCode.NotFound),
                status,
            });
        });
}
