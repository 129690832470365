import { Button, ButtonProps } from "../Buttons/Button";
import { Icon } from "../Icons/Icon";
import { IconListType } from "../Icons/IconList";
import { Typography } from "../Typography/Typography";

import { ReactNode } from "react";

import "./styles.scss";

export type AlertColors = "lilas" | "warning" | "success" | "error";

export type AlertProps = {
    message: string;
    subMessage?: string;
    classname?: string;
    icon?: IconListType;
    buttons?: ButtonProps | ButtonProps[];
    customButton?: ReactNode;
    color?: AlertColors;
    closable?: boolean;
    topBottom?: boolean;
};

export const Alert = ({
    icon = "information-circle",
    color = "lilas",
    message,
    subMessage,
    classname = "",
    buttons,
    closable = false,
    topBottom = false,
    customButton,
}: AlertProps) => {
    const renderButtons = (buttons: ButtonProps | ButtonProps[]) => {
        if (!buttons) {
            return null;
        }

        if (Array.isArray(buttons)) {
            return buttons.map((buttonProps) => <Button key={"button-" + buttonProps.label} {...buttonProps} />);
        } else {
            return <Button {...buttons} />;
        }
    };
    return (
        <div className={`alert-container alert-${color} ${classname} ${topBottom ? "top-bottom" : ""}`}>
            <div className='first-part'>
                <Icon name={icon} className='icon' data-testid='alert-icon' />
                <div className='content-part'>
                    <div className='content-text'>
                        <Typography className='fw-bold' message={message} />
                        {subMessage && <Typography message={subMessage} className='color-neutral-500 mt-1' size='xs' />}
                        {customButton ?? null}
                    </div>
                    {!topBottom && buttons && <div className='buttons-part'>{renderButtons(buttons)}</div>}
                </div>
                {closable && <Icon name='x-mark' data-testid='alert-icon-close' />}
            </div>
            {topBottom && buttons && (
                <div className='second-part'>
                    {buttons && <div className='buttons-part'>{renderButtons(buttons)}</div>}
                </div>
            )}
        </div>
    );
};
