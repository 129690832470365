import { selectTransactionTypesMap } from "src/shared/const/transactions";

import { SelectOptionWithAvatar } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export type ExternalAccountForm = {
    externalReference: string;
    typeSlug: string;
    uuid: string | null;
};

export const createBeneficiaryExternalAccounts = (): SelectOptionWithAvatar[] => {
    const excludeKeys = [31, 32];
    const result: SelectOptionWithAvatar[] = [];

    Object.entries(selectTransactionTypesMap).forEach(([key, value]) => {
        const intKey = parseInt(key, 10);
        if (excludeKeys.includes(intKey)) return;

        result.push({
            id: key,
            label: value.title,
            ...(value.type === "image" && { image: { className: "mr-2 service-icon", src: value.img } }),
            ...(value.type === "icon" && { icon: { className: "mr-2", name: value.img, color: "primary" } }),
        });
    });

    return result;
};
