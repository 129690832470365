import { useEffect, useState } from "react";

export const convertKebabToCamel = (string: string) => {
    let arr = string.split("-");
    let capital = arr.map((item, index) =>
        index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase()
    );
    let capitalString = capital.join("");

    return capitalString;
};

const cachedScripts: string[] = [];

type Props = {
    src: string;
    attributes: {
        type: string;
        id: string;
        async: boolean;
        defer: boolean;
    };
    head: boolean;
};

const useScript = ({ src, attributes, head = false }: Props) => {
    const [state, setState] = useState({
        loaded: false,
        error: false,
    });

    const [shoulLoad, setShouldLoad] = useState(false);

    useEffect(() => {
        if (shoulLoad) {
            if (cachedScripts.includes(src)) {
                setState({
                    loaded: true,
                    error: false,
                });
            } else {
                cachedScripts.push(src);

                let script = document.createElement("script");
                script.src = src;
                script.async = true;

                Object.entries(attributes).forEach(([key, value]) => {
                    if (key.startsWith("data-")) {
                        //@ts-expect-error
                        script.dataset[convertKebabToCamel(key.replace("data-", ""))] = value;
                    } else {
                        //@ts-expect-error
                        script[key] = value;
                    }
                });

                const onScriptLoad = () => {
                    setState({
                        loaded: true,
                        error: false,
                    });
                };

                const onScriptError = () => {
                    const index = cachedScripts.indexOf(src);
                    if (index >= 0) cachedScripts.splice(index, 1);
                    script.remove();

                    setState({
                        loaded: true,
                        error: true,
                    });
                };

                script.addEventListener("load", onScriptLoad);
                script.addEventListener("error", onScriptError);

                if (head) {
                    document.getElementsByTagName("head")[0].appendChild(script);
                } else {
                    document.body.appendChild(script);
                }

                return () => {
                    script.removeEventListener("load", onScriptLoad);
                    script.removeEventListener("error", onScriptError);
                    const index = cachedScripts.indexOf(src);
                    if (index >= 0) {
                        cachedScripts.splice(index, 1);
                    }
                    script.remove();
                };
            }
        }
        return;
    }, [shoulLoad]);

    const load = () => {
        if (!shoulLoad) {
            setShouldLoad(true);
        }
    };

    return { load, loading: state.loaded, error: state.error };
};

export default useScript;
