import { clearAdminActivityLogs } from "./slice";
import { fetchAdminActivityLog } from "./thunkActions";

import { useEffect } from "react";

import {
    getAdminActivityLogs,
    getAdminActivityLogsActionSlugFilter,
    getAdminActivityLogsDateFilter,
    getAdminActivityLogsLastEvaluatedKey,
    getAdminActivityLogsQueryFilter,
    getAdminActivityLogsUserFilter,
} from "./selectors";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

export const useAdminActivityLogs = () => {
    const dispatch = useAppDispatch();

    const actionSlugs = useAppSelector((state) => getAdminActivityLogsActionSlugFilter(state));
    const users = useAppSelector((state) => getAdminActivityLogsUserFilter(state));
    const date = useAppSelector((state) => getAdminActivityLogsDateFilter(state));
    const query = useAppSelector((state) => getAdminActivityLogsQueryFilter(state));

    const debouncedQuery = useDebounce(query, 700);

    useEffect(() => {
        dispatch(clearAdminActivityLogs());
        dispatch(
            fetchAdminActivityLog({
                ...(actionSlugs ? { actionSlugs: actionSlugs.map((actionSlug) => actionSlug.id) } : {}),
                ...(users ? { userIds: users.map((user) => user.id) } : {}),
                ...(debouncedQuery ? { query: debouncedQuery } : {}),
                ...(date ? { date } : {}),
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, debouncedQuery]);

    return useAppSelector((state) => getAdminActivityLogs(state));
};

export const useFetchAdminActivityLogs = () => {
    const dispatch = useAppDispatch();

    const lastEvaluatedKey = useAppSelector((state) => getAdminActivityLogsLastEvaluatedKey(state));
    const actionSlugs = useAppSelector((state) => getAdminActivityLogsActionSlugFilter(state));
    const users = useAppSelector((state) => getAdminActivityLogsUserFilter(state));
    const date = useAppSelector((state) => getAdminActivityLogsDateFilter(state));
    const query = useAppSelector((state) => getAdminActivityLogsQueryFilter(state));

    const debouncedQuery = useDebounce(query, 700);

    const handleFetchActivityLogs = () => {
        dispatch(
            fetchAdminActivityLog({
                ...(lastEvaluatedKey ? { lastEvaluatedKey } : {}),
                ...(actionSlugs ? { actionSlugs: actionSlugs.map((actionSlug) => actionSlug.id) } : {}),
                ...(users ? { userIds: users.map((user) => user.id) } : {}),
                ...(debouncedQuery ? { query: debouncedQuery } : {}),
                ...(date ? { date } : {}),
            })
        );
    };

    return handleFetchActivityLogs;
};
