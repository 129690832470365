import { UserAuthApiReturn } from "./services/auth/types";
import { AuthCompany } from "./shared/models/AuthCompany";
import { CompanyRole } from "./shared/models/UserCompany";
import {
    clearStorage,
    getInStorage,
    getInStorageUserPreferences,
    removeInStorage,
    removeInStorageUserPreferences,
    setInStorage,
} from "./shared/utils/storage";
import { requestAuthUserCompanies } from "src/services/companies/operations";

import { EnhancedStore } from "@reduxjs/toolkit";

import {
    addAuthUser,
    setAuthUserPreferencePreferredCompanyId,
    setAuthUserPreferenceSelectedCompanyId,
    setUseBaseRouter,
} from "src/modules/auth/slice";
import { receiveCompanies } from "src/modules/authCompanies/slice";
import { RootState } from "src/store";

const checkIfAuthUserIsStillLogged = async () => {
    try {
        return await requestAuthUserCompanies();
    } catch (_e) {
        return false;
    }
};

const retrievePreferredCompany = (store: EnhancedStore<RootState>, companies: AuthCompany[]) => {
    const preferredCompanyId = getInStorageUserPreferences("preferredCompanyId");

    if (preferredCompanyId) {
        const selectedCompany = companies.find((company) => company.id === preferredCompanyId);

        if (selectedCompany?.hasAccess) {
            store.dispatch(setAuthUserPreferencePreferredCompanyId({ companyId: preferredCompanyId }));
        } else {
            removeInStorageUserPreferences("preferredCompanyId");
        }
    }
};

const retrieveCompanies = (store: EnhancedStore<RootState>, companies: AuthCompany[]) => {
    const companyId = getInStorage("currentCompanyId");

    if (companyId) {
        const selectedCompany = companies.find((company) => company.id === companyId);

        store.dispatch(receiveCompanies({ companies }));
        if (selectedCompany?.hasAccess) {
            store.dispatch(setAuthUserPreferenceSelectedCompanyId({ companyId }));
        } else {
            removeInStorage("currentCompanyId");
        }
        return companies;
    }
    return [];
};

const retrieveParamsFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params: Record<string, string> = {};

    for (const [key, value] of searchParams.entries()) {
        params[key] = decodeURIComponent(value);
    }

    return params;
};
const GHOST_USER: Omit<UserAuthApiReturn, "id"> = {
    firstname: "ghost",
    lastname: "login",
    tfaActive: false,
    phone: "",
    isEmailVerified: false,
    avatar: "",
    countryCode: "",
    countryOfCitizenship: null,
    createdAt: "",
    customId: 0,
    failedLogin: 0,
    gender: "F",
    isActive: true,
    options: null,
    ownPassword: true,
    prefix: null,
    UserCompanies: [],
    updatedAt: "",
    role: CompanyRole.ADMIN,
};

const retrieveGhostLogin = (store: EnhancedStore<RootState>) => {
    const params = retrieveParamsFromUrl();

    if (params?.userId && params.adminToken) {
        const { userId, adminToken } = params;
        clearStorage();
        setInStorage("isGhost", true);
        setInStorage("accessToken", {
            ttl: 86400,
            id: adminToken,
            userId: parseInt(userId),
            createdAt: Date.now().toString(),
        });
        setInStorage("user", { ...GHOST_USER, id: parseInt(userId) });
        store.dispatch(addAuthUser({ user: { ...GHOST_USER, id: parseInt(userId) } }));
        return true;
    }
    return false;
};

const retrieveUser = async (store: EnhancedStore<RootState>) => {
    const user = getInStorage("user");
    const useBaseRouter = getInStorageUserPreferences("useBaseRouter") ?? false;

    if (user) {
        if (user.ownPassword === false) {
            clearStorage();
        } else {
            store.dispatch(addAuthUser({ user }));
            store.dispatch(setUseBaseRouter(useBaseRouter));
        }
    }
    return user;
};

const checkIsReload = () => {
    const date = new Date().getTime();
    const lastTabTimestamp = getInStorage("lastTabTimestamp");
    if (lastTabTimestamp) {
        const lastTabDate = Date.parse(lastTabTimestamp);
        const milliseconds = date - lastTabDate;

        return milliseconds < 60 * 1000;
    }
    return true;
};

export const hydrateState = async (store: EnhancedStore<RootState>) => {
    if (retrieveGhostLogin(store)) {
        return;
    }

    if (!checkIsReload()) {
        clearStorage();
        return;
    }

    const companies = await checkIfAuthUserIsStillLogged();

    if (companies) {
        retrieveUser(store);
        retrieveCompanies(store, companies);
        retrievePreferredCompany(store, companies);
    } else {
        clearStorage();
    }
};
