import { Navigate, useNavigate } from "react-router-dom";
import { requestActivateAuthUserTwoFactor } from "src/services/auth/operations";
import { HandleSubmitPropsPasswordValidation, PasswordValidationModal } from "src/shared/common/Password/Modal";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserGeneratedQrCode } from "src/modules/auth/selectors";
import { updateAuthUserTwoFactor } from "src/modules/auth/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Modal } from "src/shared/components/Modal/Modal";

import "./styles.scss";

const AppStoreUrl = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";
const googleStoreUrl = "https://apps.apple.com/us/app/google-authenticator/id388497605";

const appStoreImage = require("src/shared/images/store/appstore.png");
const googleStoreImage = require("src/shared/images/store/playstore.png");

export function ProfileTwoFactorPanel() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);

    const authUser = useAppSelector((state) => getAuthUser(state));
    const qrCode = useAppSelector((state) => getAuthUserGeneratedQrCode(state));

    const handleSwitchOpen = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    const handleSwitchSuccessModalOpen = () => {
        setSuccessModalOpen(!successModalOpen);
    };

    const handleActivateTwoFactor = (params: HandleSubmitPropsPasswordValidation) => {
        return requestActivateAuthUserTwoFactor(params).then(() => {
            dispatch(updateAuthUserTwoFactor({ tfaActive: true }));
            handleSwitchSuccessModalOpen();
        });
    };

    const handleCancel = () => {
        navigate("/settings", { state: { currentTab: "security" } });
    };

    const handleRedirectToSettings = () => {
        navigate("/settings", { state: { currentTab: "security" } });
    };

    return authUser && qrCode ? (
        <div className='two-factor-container'>
            <div className='step'>
                <Typography variant='h5' className='fw-bold' message={t("Profile.activate-2fa")} />
                <Typography className='two-factor-text-content' message={t("Profile.2fa-description")} />
            </div>
            <div className='step'>
                <Typography className='fw-bold ' message={`1. ${t("Profile.download-google-authenticator")}`} />
                <div>
                    <Typography
                        className='two-factor-text-content'
                        message={t("Profile.google-authenticator-description")}
                    />
                    <a href={AppStoreUrl} className='color-lilas-900' target='_blank' rel='noopener noreferrer'>
                        Play Store
                    </a>
                    <Typography message={t("Profile.and-on")} />
                    <a href={googleStoreUrl} className='color-lilas-900' target='_blank' rel='noopener noreferrer'>
                        l'App Store
                    </a>
                    .
                </div>
                <div className='app-store-img-container'>
                    <a href={AppStoreUrl} target='_blank' rel='noopener noreferrer' className='app-store-img'>
                        <img className='img-fluid' src={appStoreImage} alt='Disponible sur app store' />
                    </a>
                    <a href={googleStoreUrl} target='_blank' rel='noopener noreferrer' className='app-store-img'>
                        <img className='img-fluid' src={googleStoreImage} alt='Disponible sur play store' />
                    </a>
                </div>
            </div>
            <div className='step'>
                <Typography className=' fw-bold' message={`2. ${t("Profile.configure-google-authenticator")}`} />
                <Typography
                    className=' two-factor-text-content'
                    message={t("Profile.configure-google-authenticator-description")}
                />
            </div>

            <div className='step'>
                <Typography className='fw-bold' message={t("Profile.qr-code")} />
                <img className='qr-code-img' src={qrCode?.qrCode} alt='Votre QR Code' />
            </div>
            <div className='step'>
                <Typography className=' fw-bold' message={t("Profile.private-key")} />
                <div className='d-flex align-items-center '>
                    <Icon name='key' />
                    <Typography className='pl-2' message={qrCode.key} />
                </div>
            </div>
            <div className='step'>
                <Typography className=' fw-bold' message={`3. ${t("Profile.activate-2fa")}`} />
                <Typography className='two-factor-text-content ' message={t("Profile.activate-2fa-description")} />
            </div>
            <div className='d-flex '>
                <Button
                    variant='tertiary'
                    label={t("CommonUse.cancel")}
                    onClick={handleCancel}
                    testId='cancel-button'
                />

                <Button
                    testId='activate-2fa-button'
                    onClick={handleSwitchOpen}
                    className='ml-2'
                    label={t("Profile.activate-2fa-action")}
                />
            </div>
            <PasswordValidationModal
                header={{
                    title: t("LoginPanel.two-factor-authentication-title"),
                    icon: "shield-check",
                }}
                twoFactorOn
                handleOnSubmit={handleActivateTwoFactor}
                setOpen={handleSwitchOpen}
                open={open}
            />
            <Modal
                handleOnSubmit={handleRedirectToSettings}
                open={successModalOpen}
                hideCancelButton
                header={{
                    icon: "shield-check",
                    title: t<string>("LoginPanel.two-factor-authentication-title"),
                }}
                children={
                    <div className='success-modal-tfa'>
                        <Typography message={t("Profile.success-tfa-title")} className='fw-bold' />
                        <Typography message={t("Profile.tfa-success-messsage")} />
                        <img
                            className=''
                            height={200}
                            src={require("src/shared/images/rapid-transfer/rapid-transfer-success.svg").default}
                            alt=''
                        />
                    </div>
                }
                confirmLabel={t<string>("CommonUse.finish")}
            />
        </div>
    ) : (
        <Navigate to={"/dashboard"} />
    );
}
