import { ExportFrequencies } from "../../const";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

type SelectFrequencyProps = {
    selectedFrequency: SelectOption | undefined;
    setSelectedFrequency: (frequency: SelectOption) => void;
};

export const SelectFrequency = ({ selectedFrequency, setSelectedFrequency }: SelectFrequencyProps) => {
    const { t } = useTranslation();
    const exportFrequecies = useMemo(() => ExportFrequencies(t), [t]);

    const handleSelectFrequency = (frequency: SelectOption) => {
        setSelectedFrequency(frequency);
    };

    const renderFrequenciesAlertMessage = () => {
        let alertMessage = "";
        let alertSubMessage = "";

        if (selectedFrequency) {
            switch (selectedFrequency.id) {
                case "daily":
                    alertMessage = t("Export.daily-export-message");
                    alertSubMessage = t("Export.daily-export-submessage");
                    break;
                case "weekly":
                    alertMessage = t("Export.weekly-export-message");
                    alertSubMessage = t("Export.weekly-export-submessage");
                    break;
                case "monthly":
                    alertMessage = t("Export.monthly-export-message");
                    alertSubMessage = t("Export.monthly-export-submessage");
                    break;
                default:
                    break;
            }
            return <Alert icon='calendar' message={alertMessage} subMessage={alertSubMessage} />;
        }
        return <></>;
    };

    return (
        <>
            <SimpleSelect
                useRadio
                label={t<string>("Export.recurrence")}
                selected={selectedFrequency}
                options={exportFrequecies}
                placeholder={t<string>("Export.select-export-accounts")}
                onSelectionChange={handleSelectFrequency}
                mandatory
            />
            {selectedFrequency && renderFrequenciesAlertMessage()}
        </>
    );
};
