import { CompanyStatus } from "src/shared/models/UserCompany";

import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserLoading } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";

type BlockedAccountAlertProps = {
    topBottom?: boolean;
};

export const BlockedAccountAlert = ({ topBottom = false }: BlockedAccountAlertProps) => {
    const { t } = useTranslation();
    const authUser = useAppSelector((state) => getAuthUser(state));
    const isLoadingAuthUser = useAppSelector((state) => getAuthUserLoading(state));

    return !isLoadingAuthUser && authUser && authUser?.Company?.status !== CompanyStatus.BLOCK_0 ? (
        <Alert
            icon='information-circle'
            topBottom={topBottom}
            color='warning'
            message={t("BlockedAccountAlert.alert-msg")}
            subMessage={t<string>("BlockedAccountAlert.alert-submsg")}
        />
    ) : null;
};
