import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { BillingBiller } from "src/services/billing/biller/type";
import { sectorIcon } from "src/shared/const/sectors";

import { SyntheticEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getCompanyFavoriteBillers, removeBillerFromFavorite } from "src/modules/authCompanies/thunkActions";
import { useAppDispatch } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "../styles.scss";

type Props = { biller: BillingBiller };

export const BillingTopBillersFavoriteItem = ({ biller }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getCompanyFavoriteBillers());
    }, [dispatch]);

    const handleRedirectToPayment = (biller: BillingBiller) => () => {
        navigate(`/billing/pay-bill/${biller.reference.replace("#", "")}`);
    };

    const handleRemoveFromFavorite = (biller: BillingBiller) => (event: SyntheticEvent) => {
        event.stopPropagation();
        setLoading(true);
        dispatch(removeBillerFromFavorite({ billerReference: biller.reference }))
            .unwrap()
            .finally(() => setLoading(false));
    };

    return biller ? (
        <div
            onKeyDown={() => {}}
            key={`biller-${biller.name}`}
            onClick={handleRedirectToPayment(biller)}
            className='top-biller-item'
        >
            <div
                className={classNames("top-biller-image background-gradiant-pink-horizontal-1", {
                    "top-biller-image-background": biller.avatar,
                })}
                style={{
                    ...(biller.avatar
                        ? {
                              backgroundImage: `url(${biller.avatar})`,
                          }
                        : {}),
                }}
            >
                <div className='top-biller-sector'>
                    <Icon name={sectorIcon[biller.sector] ?? "kiosk"} size='sm' color='lilas' />
                    <Typography className='fw-bold' message={t(`Bills.sector-${biller.sector}`)} />
                </div>
                <div className='top-biller-favorite-button'>
                    <Button
                        disabled={loading}
                        onClick={handleRemoveFromFavorite(biller)}
                        icon='heart-filled'
                        variant='secondary'
                        size='sm'
                        color='lilas'
                    />
                </div>
                {!biller.avatar ? (
                    <div className='top-biller-center-sector'>
                        <Icon name={sectorIcon[biller.sector] ?? "kiosk"} size='lg' color='lilas' />
                    </div>
                ) : null}
            </div>
            <div className='top-biller-description'>
                <Typography className='fw-bold' message={biller.name} />
                <Typography className='color-lilas-900' message={biller.reference} />
                <div className='top-biller-informations'>
                    <div>
                        <Icon className='color-neutral-500' name='information-circle' size='sm' />
                    </div>
                    <Typography className='informations' message={biller.description} />
                </div>
            </div>
        </div>
    ) : null;
};
