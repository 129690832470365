import { createSelector } from "@reduxjs/toolkit";

import { getAdminWalletsAsSelectOptions } from "../adminWallets/selectors";
import { RootState } from "src/store";

const getState = (state: RootState) => state.admin.expensePolicies;

export const getAdminExpensePoliciesLoading = createSelector(getState, (state) => state.loading);
export const getAdminExpensePolicies = createSelector(getState, (state) => state.items);
export const getAdminExpensePoliciesCreateForm = createSelector(getState, (state) => state.form);

export const getAdminExpensePoliciesCreateOrUpdateLoading = createSelector(getState, (state) => state.form.loading);
export const getAdminExpensePoliciesCreateOrUpdateError = createSelector(getState, (state) => state.form.error);

const getAdminExpensePoliciesCreate = createSelector(getState, (state) => state.form);
export const getAdminExpensePoliciesCreateId = createSelector(getState, (state) => state.form.id);
export const getAdminExpensePoliciesCreateTitle = createSelector(getState, (state) => state.form.title);
export const getAdminExpensePoliciesCreateAllWallets = createSelector(getState, (state) => state.form.allWallets);
export const getAdminExpensePoliciesCreateAllUsers = createSelector(getState, (state) => state.form.allUsers);
export const getAdminExpensePoliciesCreateExludedWallets = createSelector(
    getState,
    (state) => state.form.excludedWallets
);
export const getAdminExpensePoliciesCreateExcludedUsers = createSelector(getState, (state) => state.form.excludedUsers);
export const getAdminExpensePoliciesCreateIsForBeneficiariesOnly = createSelector(
    getState,
    (state) => state.form.isForBeneficiariesOnly
);
export const getAdminExpensePoliciesCreateLimitPerOperation = createSelector(
    getState,
    (state) => state.form.limitPerOperation
);

export const getAdminExpensePoliciesCreateLimitPerUnitOfTime = createSelector(
    getState,
    (state) => state.form.limitPerUnitOfTime
);
export const getAdminExpensePoliciesCreateUnitOfTime = createSelector(getState, (state) => state.form.unitOfTime);

export const getAdminExpensePoliciesPage = createSelector(getState, (state) => state.filters.page);
export const getAdminExpensePoliciesPageSize = createSelector(getState, (state) => state.filters.pageSize);
export const getAdminExpensePoliciesTotalCount = createSelector(getState, (state) => state.filters.totalCount ?? 2);
export const getAdminExpensePoliciesQuery = createSelector(getState, (state) => state.filters.query);

export const getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy = createSelector(
    getAdminExpensePoliciesCreate,
    getAdminExpensePolicies,
    getAdminWalletsAsSelectOptions,
    (form, expensePolicies, wallets) => {
        const walletIds = wallets.map((wallet) => wallet.id);
        const selectedWallets = walletIds.filter((id) => !form.excludedWallets?.some((option) => option.id === id));

        return selectedWallets.reduce<string[]>((res, walletId) => {
            Object.values(expensePolicies).forEach((expensePolicy) => {
                if (expensePolicy.id !== form?.id) {
                    const expensePolicyWallets = walletIds.filter(
                        (id) => !expensePolicy.excludedWallets?.some((wallet) => wallet.id === parseInt(id))
                    );
                    if (expensePolicyWallets.includes(walletId)) {
                        res.push(walletId);
                    }
                }
            });
            return res;
        }, []);
    }
);

export const getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicyOptions = createSelector(
    getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy,
    getAdminWalletsAsSelectOptions,
    (walletIds, adminWallets) => adminWallets.filter((wallet) => walletIds.includes(wallet.id))
);

export const getAdminExpensePoliciesAllWalletsAlreadyHaveExpensePolicy = createSelector(
    getAdminExpensePolicies,
    getAdminWalletsAsSelectOptions,
    (expensePolicies, wallets) => {
        const walletIds = wallets.map((wallet) => wallet.id);

        return walletIds.reduce<string[]>((res, walletId) => {
            Object.values(expensePolicies).forEach((expensePolicy) => {
                const expensePolicyWallets = walletIds.filter(
                    (id) => !expensePolicy.excludedWallets?.some((wallet) => wallet.id === parseInt(id))
                );
                if (expensePolicyWallets.includes(walletId)) {
                    res.push(walletId);
                }
            });
            return res;
        }, []);
    }
);

export const getAdminExpensePoliciesWalletOptions = createSelector(
    getAdminWalletsAsSelectOptions,
    getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy,
    (walletOptions, walletsWithExpensePolicy) =>
        walletOptions.filter((walletOption) => !walletsWithExpensePolicy.includes(walletOption.id))
);

export const getAdminExpensePoliciesRemainingWalletOptions = createSelector(
    getAdminWalletsAsSelectOptions,
    getAdminExpensePoliciesAllWalletsAlreadyHaveExpensePolicy,
    (walletOptions, walletsWithExpensePolicy) =>
        walletOptions.filter((walletOption) => !walletsWithExpensePolicy.includes(walletOption.id))
);
