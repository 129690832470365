import { fetchWallet, fetchWalletsAuthUser } from "./thunkActions";
import { useParams } from "react-router-dom";

import { useEffect } from "react";

import { getWallet } from "./selectors";
import { useAppDispatch, useAppSelector } from "src/store";

type RouteParams = {
    walletId: string;
};

export function useRouterWallet(fetch = true) {
    const dispatch = useAppDispatch();
    const { walletId } = useParams<keyof RouteParams>() as RouteParams;

    useEffect(() => {
        if (fetch && walletId) {
            dispatch(fetchWallet({ walletId }));
        }
    }, [dispatch, fetch, walletId]);

    return useAppSelector((state) => getWallet(state, { walletId: parseInt(walletId) }));
}

export function useWalletDetails(walletId: number | undefined) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (walletId) {
            dispatch(fetchWallet({ walletId }));
        }
    }, [dispatch, walletId]);

    return useAppSelector((state) => getWallet(state, { walletId: walletId }));
}

export function useWallet(walletId: number | undefined) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchWalletsAuthUser());
    }, [dispatch]);

    return useAppSelector((state) => getWallet(state, { walletId }));
}
