import { SelectOption, SelectOptionWithAvatar } from "../SelectMultipleOptions/SelectMultipleOptions";
import clsx from "classnames";

import { ImgHTMLAttributes, useEffect, useState } from "react";

import { Checkbox } from "../../atoms/Checkbox/Checkbox";
import { Icon, IconProps } from "../../atoms/Icons/Icon";
import { Radio } from "../../atoms/Radio/Radio";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Select, SelectTypes } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "../SelectMultipleOptions/styles.scss";
import "./styles.scss";

interface SimpleSelectProps {
    headerClassname?: string;
    contentClassname?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    color?: "grey" | "white";
    icon?: IconListType;
    iconProps?: IconProps;
    image?: ImgHTMLAttributes<HTMLImageElement>;
    options: SelectOption[] | SelectOptionWithAvatar[];
    selected?: SelectOption | null;
    underMessage?: string;
    useRadio?: boolean;
    variant?: SelectTypes;
    disabled?: boolean;
    onSelectionChange?: (selectedItems: SelectOption) => void;
    onBlur?: () => void;
    testId?: string;
    scrollable?: boolean;
    mandatory?: boolean;
}

export const SimpleSelect = ({
    headerClassname = "",
    contentClassname,
    label,
    placeholder,
    icon,
    iconProps,
    image,
    options,
    selected,
    underMessage,
    useRadio,
    variant,
    disabled = false,
    onSelectionChange,
    onBlur,
    testId,
    color = "grey",
    scrollable,
    mandatory = false,
}: SimpleSelectProps) => {
    const [selectedItem, setSelectedItem] = useState<SelectOption | null | undefined>(selected);
    const [closeSelect, setCloseSelect] = useState(false);

    useEffect(() => {
        setSelectedItem(selected);
    }, [selected]);

    useEffect(() => {
        setCloseSelect(false);
    }, [closeSelect]);

    const handleItemClick = (item: SelectOption) => () => {
        setSelectedItem(item.id === selectedItem?.id ? undefined : item);
        if (onSelectionChange) {
            onSelectionChange(item);
        }
        setCloseSelect(true);
    };

    const title = selectedItem ? selectedItem.label : "";
    const InputComponent = useRadio ? Radio : Checkbox;

    return (
        <div className='simple-select-container'>
            {label && (
                <div className='label-container'>
                    <Typography
                        message={label}
                        className={clsx("fw-bold ", {
                            "color-neutral-400": disabled,
                            "color-neutral-900": !disabled,
                        })}
                    />
                    {mandatory && <Typography className='color-error fw-bold' message={" *"} />}
                </div>
            )}
            <Select
                className={scrollable ? "high-render" : ""}
                forceClose={closeSelect}
                type={variant}
                onBlur={onBlur}
                contentClassName={contentClassname ? contentClassname : "simple-select-option-width"}
                disabled={disabled}
                color={color}
                testId={testId}
                content={{
                    header: {
                        title,
                        placeholder,
                        icon,
                        iconProps,
                        image,
                        disabled,
                        className: `simple-select-option ${headerClassname} ${disabled ? "disabled" : ""}`,
                    },
                    dropDownContent: {
                        body: (
                            <div className='d-flex flex-column w-100'>
                                {options.map((option, index) => (
                                    <div key={index} className='px-2 py-2 cursor-pointer'>
                                        <InputComponent
                                            value={option.id}
                                            checked={selected ? selectedItem?.id === option.id : false}
                                            onClick={handleItemClick(option)}
                                            readOnly
                                            testId={testId + "-option-" + index}
                                            label={
                                                <div className='d-flex align-items-center'>
                                                    {"icon" in option && option.icon && <Icon {...option.icon} />}
                                                    {"image" in option && option.image && (
                                                        <img alt='simple-select-icon' {...option.image} />
                                                    )}
                                                    <Typography className='my-auto' message={option.label} />
                                                </div>
                                            }
                                        />
                                    </div>
                                ))}
                            </div>
                        ),
                    },
                }}
            />
            {underMessage && variant !== "primary" && (
                <div className={`under-message variant-${variant} fadeInUp`}>
                    {variant === "success" && <Icon name='check-circle-filled' />}
                    {variant === "error" && <Icon name='x-circle-filled' />}
                    {variant === "warning" && <Icon name='exclamation-triangle-filled' />}
                    <Typography message={underMessage} className='ml-1 mt-0' />
                </div>
            )}
        </div>
    );
};
