import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getState = (state: RootState) => state.admin.userDetailReducer;

export const getUserDetail = createSelector(getState, (state) => ({
    userDetail: state.userDetail,
    loading: state.loading,
    error: state.error,
}));

export const getCreatedUser = createSelector(getState, (state) => ({
    createdUser: state.userDetail,
    error: state.createUserError,
    loading: state.createUserLoading,
}));

export const getEditUser = createSelector(getState, (state) => ({
    error: state.editUserError,
    loading: state.editUserLoading,
}));

export const getPhoneValidationStatus = createSelector(getState, (state) => ({
    loading: state.phoneValidationLoading,
    error: state.phoneValidationError,
    validated: state.phoneValidated,
}));
