import { TransactionApi, TransactionIconStatusType } from "src/services/transactions/types";
import { moneyOutCancelReasons } from "src/shared/const/transactions";
import { TransactionContext } from "src/shared/context/transaction";
import { computeTimestampToString } from "src/shared/utils/formatDate";
import { formatMoney } from "src/shared/utils/formatMoney";

import { useContext } from "react";

import { t } from "i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Note, NoteContent } from "src/shared/components/Note/Note";

import "./styles.scss";

function getRefundsAmounts(refunds: TransactionApi["refunds"]) {
    return refunds.reduce(
        (res, refund) => {
            const { amount, fees } = refund;
            if (refund.status !== "failed") {
                res.amount += amount ?? 0;
                res.fees += fees ?? 0;
            }
            return res;
        },
        { amount: 0, fees: 0 }
    );
}

const getStatusData = (status: TransactionIconStatusType | null) => {
    switch (status) {
        case "refunded": {
            return {
                color: "success",
                refundTitle: t("HistoryWalletDrawerStatus.refunded-operation"),
                label: t("HistoryWalletDrawerStatus.statut-de-traitement"),
                value: (
                    <div className='status-data-value'>
                        <Icon name='check-circle' color='success' />
                        <Typography message={t("HistoryWalletDrawerStatus.your-operation-refunded")} />
                    </div>
                ),
            } as const;
        }
        case "failed": {
            return {
                color: "success",
                refundTitle: t("HistoryWalletDrawerStatus.operation-annulee"),
                label: t("HistoryWalletDrawerStatus.statut-de-traitement"),
                value: (
                    <div className='status-data-value'>
                        <Icon name='check-circle' color='success' />
                        <Typography message={t("HistoryWalletDrawerStatus.operation-annulee")} />
                    </div>
                ),
            } as const;
        }
        case "refund-pending":
        case "waiting": {
            return {
                color: "warning",
                refundTitle: t(
                    "HistoryWalletDrawerStatus.julaya-est-en-train-de-traiter-votre-demande-de-remboursement"
                ),
                label: t("HistoryWalletDrawerStatus.statut-de-traitement"),
                value: (
                    <div className='status-data-value'>
                        <Icon name='clock' color='warning' />
                        <Typography message={t("HistoryWalletDrawerStatus.traitement-en-cours")} />
                    </div>
                ),
            } as const;
        }
        case "refund-failed": {
            return {
                color: "error",
                refundTitle: t(
                    "HistoryWalletDrawerStatus.nous-n-avons-pas-reussi-a-rembourser-votre-transfert-car-les-fonds-ne-sont-plus-disponibles-sur-le-portefeuille-du-destinataire"
                ),
                label: t("HistoryWalletDrawerStatus.statut-de-traitement"),
                value: (
                    <div className='status-data-value'>
                        <Icon name='x-circle' color='error' />
                        <Typography message={t("HistoryWalletDrawerStatus.echec-du-remboursement")} />
                    </div>
                ),
                icon: "x-circle",
            } as const;
        }
        default: {
            return;
        }
    }
};

const getRefundsData = (transaction: TransactionApi): NoteContent[] | undefined => {
    const { refunds, status, tags } = transaction;

    if (refunds && (status === "refunded" || status === "refund-pending")) {
        const { fees, amount } = getRefundsAmounts(refunds);
        const { createdAt } = refunds[0];
        return [
            {
                title: t(
                    status === "refunded"
                        ? "HistoryWalletDrawerStatus.frais-de-remboursement"
                        : "HistoryWalletDrawerStatus.frais-theoriques"
                ),
                value: formatMoney(fees),
            },
            {
                title: t(
                    status === "refunded"
                        ? "HistoryWalletDrawerStatus.montant-rembourse"
                        : "HistoryWalletDrawerStatus.montant-a-rembourser"
                ),
                value: formatMoney(amount),
            },
            {
                title: t("HistoryWalletDrawerStatus.date-du-remboursement"),
                value:
                    status === "refunded"
                        ? `${computeTimestampToString(createdAt, "DD/MM/YYYY")} - ${computeTimestampToString(
                              createdAt,
                              "HH:mm:ss"
                          )}`
                        : "-",
            },
            {
                title: t("HistoryWalletDrawerStatus.motif"),
                value: tags ? moneyOutCancelReasons[tags] || "-" : "-",
            },
        ];
    }
    return undefined;
};

export const HistoryWalletDrawerStatus = () => {
    const transaction = useContext(TransactionContext);

    const { status, isCancelled } = transaction;

    const statusData = getStatusData(status);
    const refundData = getRefundsData(transaction);
    const visible = isCancelled ?? status === "refunded";

    return visible && statusData ? (
        <div className={`drawer-content-section history-wallet-drawer-status`}>
            <Note
                title={statusData.refundTitle}
                color={statusData.color}
                icon={"retry-filled"}
                content={[
                    {
                        title: statusData.label,
                        value: statusData.value,
                    },
                    ...(refundData ?? []),
                ]}
            />
        </div>
    ) : null;
};
