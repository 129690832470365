import { middlewareRequest } from "../utils/request";
import {
    LoanModel,
    LoanModelApiResponse,
    LoanOfferModelApiResponse,
    LoanOfferStatus,
    LoanStatus,
} from "src/shared/models/Loan";

export type LoanApiFilters = {
    page?: number;
    pageSize?: number;
    query?: string;
    status?: LoanStatus[];
};

export type LoanOfferApiFilters = {
    page?: number;
    pageSize?: number;
    query?: string;
    status?: LoanOfferStatus[];
};

export type LoanTransactionsApiFilters = {
    minDate?: Date | null;
    maxDate?: Date | null;
    status?: [];
    transactionType?: [];
    page?: number;
    pageSize?: number;
    loanUuid?: string;
    offerId?: string;
};

export interface LoanTransactionApiReturn {
    count: number;
    currentPage: number;
    totalPages: number;
    perPage: number;
    data: [];
}

export async function requestGetLoans(filters?: LoanApiFilters) {
    try {
        return await middlewareRequest<LoanModelApiResponse>({ endpoint: "/loans", method: "POST", params: filters });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetLoanById(loanId: number) {
    try {
        return await middlewareRequest<LoanModel>({ endpoint: `/loans/${loanId}`, method: "GET" });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetLoanOffers(filters?: LoanOfferApiFilters) {
    try {
        return await middlewareRequest<LoanOfferModelApiResponse>({
            endpoint: "/loans/offers",
            method: "POST",
            params: filters,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestGetLoanTransactions(filters?: LoanTransactionsApiFilters) {
    try {
        return await middlewareRequest<LoanTransactionApiReturn>({
            endpoint: "/loans/transactions",
            method: "POST",
            params: filters,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
