import { TaskItemModelToCreate } from "./types";

export const defaultTaskItem: TaskItemModelToCreate = {
    amount: null,
    serviceSlug: null,
    comment: null,
    externalReference: "",
    externalFullname: null,
    typeSlug: null,
};
