import {
    clearUserDetail,
    failedCreateUser,
    failedEditUser,
    failedRequestPhoneValidation,
    failedRequestUserDetail,
    failedRequestUserDetailWithPhone,
    receiveActivateUser,
    receiveArchiveUser,
    receiveCreateUser,
    receiveEditUser,
    receivePhoneValidation,
    receiveUserDetail,
    receiveUserDetailWithPhone,
    requestCreatUser,
    requestEditUser,
    requestPhoneValidation,
    requestUserDetail,
} from "./slice";
import {
    requestArchiveUser,
    requestChangeUserActiveStatus,
    requestGetUserDetail,
    requestPostCreateUser,
    requestPostIfUserExist,
    requestPutUpdateUser,
} from "src/services/admin/operations";
import { CreateUserDocumentParams, CreateUserParams, PhoneParams } from "src/services/admin/types";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";
import { UserValuesType } from "src/shared/context/createUser";
import { UserModel } from "src/shared/models/User";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchUserDocument, handleSendUserDocument } from "src/modules/documents/thunkActions";

export const fetchUserDetail = createAsyncThunk("fetchUserDetail", async (userId: number | string, { dispatch }) => {
    dispatch(requestUserDetail());
    try {
        const response = await requestGetUserDetail(userId);
        dispatch(receiveUserDetail(response));
    } catch (error) {
        dispatch(failedRequestUserDetail());
    }
});

export const requestIfUserExist = createAsyncThunk("requestIfUserExist", async (phone: PhoneParams, { dispatch }) => {
    dispatch(requestUserDetail());
    try {
        const response = await requestPostIfUserExist(phone);
        dispatch(receiveUserDetailWithPhone(response));
    } catch (error: any) {
        dispatch(failedRequestUserDetailWithPhone({ error: error?.message as string }));
        return error;
    }
});

export const handlePhoneValidation = createAsyncThunk(
    "handlePhoneValidation",
    async (phone: PhoneParams, { dispatch }) => {
        dispatch(requestPhoneValidation());
        try {
            await requestPostIfUserExist(phone);
            dispatch(receivePhoneValidation());
        } catch (error: any) {
            dispatch(failedRequestPhoneValidation({ error: error?.message as string }));
            return error;
        }
    }
);

export const handleCreateUser = createAsyncThunk(
    "handleCreateUser",
    async (params: CreateUserParams & CreateUserDocumentParams, { dispatch, getState }) => {
        const state: any = getState();
        const user: UserModel = state?.auth?.user;
        dispatch(requestCreatUser());
        try {
            const { passportBehind, passportFront, slug, ...payload } = params;

            const response = await requestPostCreateUser({
                ...payload,
                password: params.password,
            });

            if (params.companyPrefix) {
                user.prefix = params.companyPrefix;
            }

            if (passportBehind || passportFront) {
                const document = { recto: passportFront, verso: passportBehind, slug };
                await dispatch(handleSendUserDocument({ params: document, userId: String(response.id) }));
            }

            dispatch(
                receiveCreateUser({
                    ...response,
                })
            );
            return Promise.resolve();
        } catch (error: any) {
            dispatch(failedCreateUser({ error: error?.message as string }));
            return Promise.reject(error);
        }
    }
);

export const handleUpdatedUser = createAsyncThunk(
    "handleEditUser",
    async (params: UserValuesType & HandleSubmitPropsPasswordValidation, { dispatch }) => {
        dispatch(requestEditUser());
        try {
            const { id, passportBehind, password, passportFront, slug, ...payload } = params;

            const response = await requestPutUpdateUser(id as number, {
                ...payload,
                password,
            });
            dispatch(receiveEditUser(response));
            return response;
        } catch (error: any) {
            dispatch(failedEditUser({ error: error?.message as string }));
            return Promise.reject(error);
        }
    }
);

export const handleUpdatedUserDocuments = createAsyncThunk(
    "handleEditUser",
    async (params: UserValuesType, { dispatch }) => {
        dispatch(requestEditUser());
        try {
            const { id, passportBehind, passportFront, slug } = params;

            if (passportBehind || passportFront) {
                const document = {
                    ...(passportFront && { recto: passportFront }),
                    ...(passportBehind && { verso: passportBehind }),
                    slug,
                };
                const response = await dispatch(handleSendUserDocument({ params: document, userId: String(id) }));
                await dispatch(fetchUserDocument({ userId: id }));
                return Promise.resolve(response);
            }

            return Promise.reject();
        } catch (error: any) {
            dispatch(failedEditUser({ error: error?.message as string }));
            return Promise.reject(error);
        }
    }
);

export const handleChangeUserActiveStatus = createAsyncThunk(
    "handleChangeUserActiveStatus",
    async ({ id, data }: { id: number[]; data: CreateUserParams }, { dispatch }) => {
        dispatch(requestEditUser());
        try {
            const response = await requestChangeUserActiveStatus(id, data);
            dispatch(receiveActivateUser());
            return response;
        } catch (e: any) {
            dispatch(failedEditUser({ error: e.message }));
            return Promise.reject(e);
        }
    }
);

export const handleArchiveUser = createAsyncThunk(
    "handleArchiveUser",
    async ({ id, data }: { id: number; data: CreateUserParams }, { dispatch }) => {
        dispatch(requestEditUser());
        try {
            const res = await requestArchiveUser(id, data);
            dispatch(receiveArchiveUser());
            return res;
        } catch (e: any) {
            dispatch(failedEditUser({ error: e.message }));
            return Promise.reject(e);
        }
    }
);

export const clearUserDetailData = createAsyncThunk("clearUserDetailData", async (_, { dispatch }) => {
    dispatch(clearUserDetail());
});
