import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { getTaskToEdit } from "src/modules/tasks/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export const EditTaskFooterAmount = () => {
    const { t } = useTranslation();
    const task = useAppSelector((state) => getTaskToEdit(state));
    const totalAmount = parseInt(task?.metadata?.sum ?? "0");
    const totalFees = task?.metadata?.fees ?? 0;
    const totalCost = totalAmount + totalFees;

    return (
        <div className='edit-task-footer-item edit-task-footer-item-border d-flex align-items-center justify-content-between '>
            <div className='d-flex fw-bold'>
                <Typography message={t("EditTaskFooterAmount.total")} />
                <Typography
                    className='color-neutral-500 mx-1'
                    message={`${formatMoneyToString({ amount: totalCost })}`}
                />
            </div>

            <ToolTip
                activation
                tooltipClassname='mb-4'
                content={
                    <div className='task-edit-tooltip'>
                        <div className='d-flex align-items-center justify-content-between '>
                            <Typography className='fw-bold' message={t("EditTaskFooterAmount.detail-du-transfert")} />
                            <Icon name='receipt-accepted' />
                        </div>
                        <div className='task-edit-vertical-divider' />
                        <div className='d-flex align-items-center justify-content-between py-1 '>
                            <Typography message={`${t("CommonUse.amount")} : `} />
                            <Typography message={`${formatMoneyToString({ amount: totalAmount })}`} />
                        </div>
                        <div className='d-flex align-items-center justify-content-between py-1 '>
                            <Typography message={`${t("Tasks.julaya-fees")} : `} />
                            <Typography message={`${formatMoneyToString({ amount: totalFees })}`} />
                        </div>
                        {task?.includeReceiverFees && (
                            <div className='d-flex align-items-center py-1'>
                                <Typography
                                    message={t("EditTaskFooterAmount.compensation-des-frais-de-retraits-mobile-money")}
                                />
                                <Typography
                                    message={`${formatMoneyToString({ amount: task?.metadata?.opFees ?? 0 })}`}
                                />
                            </div>
                        )}
                    </div>
                }
            >
                <div className='d-flex h-100 align-items-center'>
                    <Icon color='lilas-900' name='information-circle' />
                </div>
            </ToolTip>
        </div>
    );
};
