import { middlewareRequest } from "../utils/request";
import { RequestWalletsBeneficiariesApi, WalletApi, WalletsApi } from "./types";

export async function requestAuthUserWallets(params?: {
    access?: "INIT" | "SEND" | "TOPUP" | "READ" | "OWN";
    isActive?: boolean;
}) {
    try {
        return await middlewareRequest<WalletsApi>({ endpoint: "/me/wallets", method: "POST", params });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestWallet({ walletId }: { walletId: string | number }) {
    try {
        return await middlewareRequest<WalletApi>({ endpoint: `/wallets/${walletId}`, method: "GET" });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function requestWalletsBeneficiaries({ walletId, query, way, typeSlug, serviceSlug }: { walletId: string | number; query?: string; way: string; typeSlug?: string; serviceSlug?: string }) {
    try {
        return await middlewareRequest<RequestWalletsBeneficiariesApi>({
            endpoint: `/me/companies/services`,
            method: "POST",
            params: { walletId: walletId, externalReference: query, way: way, typeSlug: typeSlug, serviceSlug: serviceSlug },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
