import { NOTIFICATION_MODE, NotificationsDrawerBody } from "./Body";
import { useNavigate } from "react-router-dom";
import { NotificationDrawerContext } from "src/shared/context/notificationDrawer";

import { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

import "./styles.scss";

type Props = {
    show: boolean;
    handleClose: () => void;
};

export const NotificationsDrawer = ({ show, handleClose }: Props) => {
    const { t } = useTranslation();
    const { setSelectedTab } = useContext(NotificationDrawerContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!show) {
            setSelectedTab(NOTIFICATION_MODE.RECENT);
        }
    }, [show]);

    const handleNavigateToSettings = () => {
        navigate("/settings", { state: { currentTab: "notifications" } });
        handleClose();
    };

    return (
        <Drawer
            className='notifications-drawer'
            isOpen={show}
            onClose={handleClose}
            header={{
                title: t("CommonUse.notifications"),
                image: "bell",
                optionalButtons: [{ icon: "gear", onClick: handleNavigateToSettings }],
            }}
            body={<NotificationsDrawerBody isOpen={show} handleClose={handleClose} />}
        />
    );
};
