import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getTransactionsStateItems = (state: RootState) => state.billingTransactions.items;

export const getBillingTransactions = createSelector(
    getTransactionsStateItems,
    (transactionsState) => transactionsState?.data ?? []
);

export const getBillingTransaction = createSelector(
    getBillingTransactions,
    (_state: RootState, { uuid }: { uuid: string | null }) => uuid,
    (walletTransactionsState, transactionUuid) =>
        transactionUuid && walletTransactionsState.find((transaction) => transaction.uuid === transactionUuid)
);

export const getBillingTransactionsCurrentPage = createSelector(
    getTransactionsStateItems,
    (transactionsState) => transactionsState?.currentPage
);

export const getBillingTransactionsLoading = createSelector(
    getTransactionsStateItems,
    (transactionsState) => transactionsState?.isLoading
);

export const getBillingTransactionsCount = createSelector(
    getTransactionsStateItems,
    (transactionsState) => transactionsState?.count
);

export const getBillingTransactionsShowPageSize = createSelector(
    getTransactionsStateItems,
    (transactionsState) => transactionsState?.pageSize
);

export const getBillingTransactionsTotalPages = createSelector(
    getTransactionsStateItems,
    (transactionsState) => transactionsState?.totalPages
);

const getBillingsTransactionFilters = (state: RootState) => state.billingTransactions.filters;

export const getBillingTransactionsIsActiveFilters = createSelector(
    getBillingsTransactionFilters,
    (filters) => Object.keys(filters).length > 0
);

export const getBillingTransactionFilters = createSelector(
    getBillingsTransactionFilters,
    (walletFilters) => walletFilters ?? {}
);

export const getBillingTransactionsQueryFilter = createSelector(
    getBillingsTransactionFilters,
    (filtersState) => filtersState?.query
);

export const getBillingTransactionsStartDateFilter = createSelector(
    getBillingsTransactionFilters,
    (filtersState) => filtersState?.startDate
);

export const getBillingTransactionsEndDateFilter = createSelector(
    getBillingsTransactionFilters,
    (filtersState) => filtersState?.endDate
);

export const getBillingTransactionsStatusFilter = createSelector(
    getBillingsTransactionFilters,
    (filtersState) => filtersState?.status
);

export const getBillingTransactionsArchivedFilter = createSelector(
    getBillingsTransactionFilters,
    (filtersState) => filtersState?.archived
);
