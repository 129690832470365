import { DEMO_WARNING } from "../services/utils/const";
import { AdminRoutes, RouteItem, UserRoutes } from "./const";
import { isChrome, isFirefox } from "react-device-detect";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { NotificationDrawerContext } from "src/shared/context/notificationDrawer";

import { useContext, useEffect, useState } from "react";

import { getNotificationsState } from "../modules/notifications/selectors";
import { fetchNewNotifications, readNotifications } from "../modules/notifications/thunkActions";
import { getAuthUserPreferenceSelectedCompanyId } from "src/modules/auth/selectors";
import { useAutoLogout } from "src/shared/hooks/useAutoLogout";
import { useChameleon } from "src/shared/hooks/useChameleon";
import { useCloseTabLogout } from "src/shared/hooks/useCloseTabLogout";
import { useAppDispatch, useAppSelector } from "src/store";

import { Icon } from "../shared/atoms/Icons/Icon";
import { CompaniesPage } from "./pages/Companies";
import { NavBar } from "src/components/Layout/Navbar/Navbar";
import { NotificationsDrawer } from "src/components/Notifications/Drawer";

import "./styles.scss";

const JulayaImage = require("src/shared/images/logo/julaya_pro.png");

const renderRoutesBreadcrumbFromArray = (routesProps: RouteItem[]) => {
    return routesProps.map(({ path, breadcrumbElement }) =>
        breadcrumbElement ? <Route key={path} path={path} element={breadcrumbElement} /> : null
    );
};

const renderRoutesFromArray = (routes: RouteItem[]) => {
    return routes.map(({ element, path }) => <Route key={path} path={path} element={element} />);
};

export const ConnectedRouter = () => {
    const dispatch = useAppDispatch();
    const { open, setOpen } = useContext(NotificationDrawerContext);
    const [refreshKey, setRefreshKey] = useState(0);
    const location = useLocation();

    useChameleon();
    useAutoLogout(5);
    useCloseTabLogout();

    const selectedCompany = useAppSelector((state) => getAuthUserPreferenceSelectedCompanyId(state));
    const { newNotificationsCount } = useAppSelector(getNotificationsState);

    const companyPage = location.pathname === "/companies" || location.pathname === "/";

    const handleCloseNotificationDrawer = () => {
        setOpen(false);
        dispatch(readNotifications());
    };

    const handleOpenNotificationDrawer = () => {
        setOpen(true);
    };

    const handleRefresh = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    useEffect(() => {
        if (!companyPage) {
            dispatch(fetchNewNotifications());
        }
    }, [companyPage, dispatch]);

    return (
        <>
            <div className='connected-layout'>
                <div className='navbar-container'>
                    <NavBar />
                    <img className='julaya-pro-image' alt='Julaya pro' src={JulayaImage} />
                </div>
                <div className='content-container'>
                    <div className='header-container'>
                        <Routes>
                            {renderRoutesBreadcrumbFromArray(AdminRoutes)}
                            {renderRoutesBreadcrumbFromArray(UserRoutes)}
                        </Routes>

                        {!companyPage && (
                            <div className={`header-buttons ${isFirefox ? "firefox" : ""}`}>
                                <div className='notification-btn' onClick={handleRefresh}>
                                    <Icon name='refresh' color='neutral-900' />
                                </div>
                                <div className='notification-btn' onClick={handleOpenNotificationDrawer}>
                                    <Icon name='bell' color='neutral-900' />
                                    {newNotificationsCount > 0 && <div className='new-badge'></div>}
                                </div>
                            </div>
                        )}
                    </div>
                    <div
                        id='scroll-reset-table-div'
                        className={`router-container ${DEMO_WARNING ? "demo-warning" : ""} ${
                            isFirefox || isChrome ? "firefox" : ""
                        }`}
                    >
                        <Routes key={refreshKey}>
                            {renderRoutesFromArray(AdminRoutes)}
                            {renderRoutesFromArray(UserRoutes)}
                            <Route path='/companies' element={<CompaniesPage />} />
                            <Route
                                path='*'
                                element={selectedCompany ? <Navigate to='/dashboard' /> : <Navigate to='/companies' />}
                            />
                        </Routes>
                    </div>
                </div>
                <div className='mobile-fast-action-container'>
                    <div className='fast-action-button'>Mes comptes</div>
                    <div className='fast-action-button'>Mes tâches</div>
                    <div className='fast-action-button'>Mon profil</div>
                </div>
            </div>

            <NotificationsDrawer show={open} handleClose={handleCloseNotificationDrawer} />
        </>
    );
};
