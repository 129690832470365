import "./styles.scss";

type RangeSliderProps = {
    maxRange: number;
    sliderValue: number;
    jumpValue: number;
    setSliderValue(value: number): void;
};

export const RangeSlider = ({ maxRange, sliderValue, jumpValue, setSliderValue }: RangeSliderProps) => {
    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);

        let adjustedValue;
        if (value + jumpValue > maxRange && value !== maxRange) {
            adjustedValue = maxRange;
        } else {
            adjustedValue = value - (value % jumpValue);
        }

        setSliderValue(adjustedValue);
    };

    const sliderPercentage = (sliderValue / maxRange) * 100;

    return (
        <div className='range-slider-container'>
            <input
                type='range'
                min='1'
                max={maxRange}
                value={sliderValue}
                onChange={handleSliderChange}
                className='range-slider'
                step={jumpValue}
                style={{
                    backgroundSize: `${sliderPercentage}% 100%`,
                }}
            />
            <style>
                {`.range-slider::before {
                    width: calc(${sliderPercentage}% - ${20 * (sliderPercentage / 100)}px);
                }
                .range-slider::after {
                    width: ${100 - sliderPercentage}%;
                }`}
            </style>
        </div>
    );
};
