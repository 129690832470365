import { useNavigate } from "react-router-dom";
import { sectorIcon, sectorOptions } from "src/shared/const/sectors";

import { useTranslation } from "react-i18next";

import { setBillingBillerSectorFilter } from "src/modules/billing/billers/slice";
import { useAppDispatch } from "src/store";

import { Carousel } from "src/shared/atoms/Carousel/Carousel";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

export const BillingTopBillersSectors = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const handleRedirectToList = (sector: SelectOption) => () => {
        dispatch(setBillingBillerSectorFilter([sector]));

        navigate("/billing/pay-bill-list", { state: { sector } });
    };

    return (
        <Carousel
            scrollPage
            header={<Typography variant='h6' className='fw-bold' message={t("Billing.explore-sectors")} />}
            items={sectorOptions(t).map(({ id, label }) => (
                <div
                    key={`biller-${id}`}
                    onClick={handleRedirectToList({ id, label })}
                    className='top-biller-sector-carousel-item'
                >
                    <Icon name={sectorIcon[id]} size='lg' color='lilas' />
                    <Typography className='fw-bold' message={label} />
                </div>
            ))}
        />
    );
};
