import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { BillingGetPaidPanel } from "src/components/Billing/GetPaid/Panel";

const BillingGetPaidPageUnauthorized = () => {
    return <BillingGetPaidPanel />;
};

export const BillingGetPaidPage = withAuthorization({
    allowedRoles: [CompanyRole.ADMIN],
})(BillingGetPaidPageUnauthorized);
