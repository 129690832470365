import { BeneficiaryDrawer } from "../../Beneficiary/Drawer/Container";
import { EditTaskCreateRow } from "./CreateRow";
import { EditTaskRow } from "./EditRow";
import { EditTaskSummaryPanel } from "./Summary/Panel";
import { TasksEditFooterDialog } from "./footer/Dialog";
import { EditTaskFooterSummary } from "./footer/Summary";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { defaultTaskItem } from "src/services/taskItems/const";
import { TaskItemModelToCreate } from "src/services/taskItems/types";
import { WalletBeneficiary } from "src/services/wallets/types";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";
import { parseCreateTaskFile } from "src/utils/xlsx/parseTask";
import * as XLSX from "xlsx";

import { ChangeEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useRouterTaskToEdit } from "src/modules/tasks/hooks";
import { getTask, getTaskToEditLoading } from "src/modules/tasks/selectors";
import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { useRouterTaskItems } from "src/modules/tasksItems/hooks";
import {
    getTaskItemsFileUploading,
    getTaskItemsFileUploadingFailed,
    getTasksItemsIsLoading,
} from "src/modules/tasksItems/selectors";
import {
    handleCreateTaskitems,
    handleDuplicateTaskitems,
    handleUpdateTaskItem,
} from "src/modules/tasksItems/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Select } from "src/shared/atoms/Select/Select";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Table } from "src/shared/components/Table/Table";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";

import "./styles.scss";

type LocationState = {
    duplicateTaskId?: number;
};

export function EditTaskPanel() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const state = useLocation().state as LocationState;

    const [duplicate, setDuplicate] = useState(false);
    const [screen, setScreen] = useState<"edit" | "signature">("edit");
    const [open, setOpen] = useState(false);
    const [taskItemToEdit, setTaskItemToEdit] = useState<TaskItemTmpModel>();
    const [createdItem, setCreatedItem] = useState<TaskItemModelToCreate>();

    const task = useRouterTaskToEdit();
    const taskItems = useRouterTaskItems();

    const taskId = task?.id;

    const taskToDuplicate = useAppSelector((reduxState) =>
        state?.duplicateTaskId ? getTask(reduxState, { taskId: state.duplicateTaskId }) : null
    );
    const taskToEditLoading = useAppSelector((state) => getTaskToEditLoading(state));
    const taskItemsLoading = useAppSelector((state) => getTasksItemsIsLoading(state));
    const taskItemsFileUploading = useAppSelector((state) => getTaskItemsFileUploading(state, { taskId }));
    const taskItemsFileUploadingFailed = useAppSelector((state) => getTaskItemsFileUploadingFailed(state, { taskId }));
    const isNbTransactionsRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.LIMIT_15_TRANSACTION);

    useEffect(() => {
        if (!duplicate && taskToDuplicate && task && !task?.metadata?.sum) {
            setDuplicate(true);
            dispatch(handleDuplicateTaskitems({ taskId: task.id, taskToDuplicate }));
        }
    }, [state]);

    useEffect(() => {
        if (!taskToEditLoading && !task) {
            navigate("/dashboard");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskToEditLoading, task]);

    const handleOpenDrawer = (taskItem: TaskItemTmpModel) => () => {
        setOpen(true);
        setTaskItemToEdit(taskItem);
    };

    const handleCloseDrawer = () => {
        setOpen(false);
        setTaskItemToEdit(undefined);
        if (!createdItem?.serviceSlug) {
            setCreatedItem(undefined);
        }
    };

    const handleSelectBeneficiary = ({
        serviceSelected: { externalFullname, externalReference, serviceSlug },
        typeSlug,
    }: WalletBeneficiary) => {
        if (
            taskId &&
            taskItemToEdit &&
            (externalFullname !== taskItemToEdit?.externalFullname ||
                externalReference !== taskItemToEdit?.externalReference ||
                serviceSlug !== taskItemToEdit.serviceSlug)
        ) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItemToEdit,
                    update: {
                        externalReference,
                        externalFullname: removeExtraSpaces(externalFullname),
                        serviceSlug,
                        typeSlug,
                    },
                })
            ).then(() => dispatch(fetchTaskToEdit({ taskId })));
            handleCloseDrawer();
        }
    };

    const handleSelectBeneficiaryCreateTaskitem = ({
        serviceSelected: { externalFullname, externalReference, serviceSlug },
        typeSlug,
    }: WalletBeneficiary) => {
        setCreatedItem({
            ...defaultTaskItem,
            externalFullname: removeExtraSpaces(externalFullname),
            externalReference,
            serviceSlug,
            typeSlug,
        });
        setOpen(false);
    };

    const handleRenameBeneficiaryCreateTask = (externalFullname: string) => {
        setCreatedItem((oldItem) =>
            oldItem ? { ...oldItem, externalFullname: removeExtraSpaces(externalFullname) } : undefined
        );
    };

    const handleOpenDrawerCreateTaskItem = () => {
        if (disabled) {
            return;
        }
        setTaskItemToEdit(undefined);
        if (!createdItem) {
            setCreatedItem(defaultTaskItem);
        }
        setOpen(true);
    };

    const handleCancelCreateTaskItem = () => {
        setCreatedItem(undefined);
    };

    const onFileLoaded = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            let reader = new FileReader();
            reader.onload = async function (e) {
                let data = e?.target?.result;
                if (data && taskId) {
                    if (isNbTransactionsRestricted) {
                        const readedData = XLSX.read(data, { type: "binary" });

                        const firstSheet = readedData.Sheets[readedData.SheetNames[0]];
                        const dataParseFirstSheet = XLSX.utils.sheet_to_json<string[]>(firstSheet, { header: 1 });
                        const nonEmptyRows = dataParseFirstSheet.filter((row) =>
                            row.some((cell) => cell !== null && cell !== undefined && cell !== "")
                        );

                        if (nonEmptyRows.length - 1 + Object.keys(taskItems).length > 15) {
                            toast.error(t("BulkTransferPage.free-plan-warning-list"));
                            return;
                        }
                    }

                    try {
                        const fileData = parseCreateTaskFile(t, data);
                        if (typeof fileData === "string") {
                            alert(fileData);
                        } else if (fileData) {
                            dispatch(handleCreateTaskitems({ taskId, taskItems: fileData }))
                                .unwrap()
                                .then(() => dispatch(fetchTaskToEdit({ taskId })))
                                .catch((error) => {
                                    alert(t(error.message));
                                });
                        } else {
                            alert(t("Tasks.file-is-not-standart-choose-another"));
                        }
                    } catch {
                        alert(t("Tasks.file-is-not-standart-choose-another"));
                    }
                }
            };
            reader.readAsBinaryString(selectedFile);
            event.target.value = "";
        }
    };

    const handleSwitchToSignature = () => {
        setScreen("signature");
    };

    const handleSwitchToEdit = () => {
        setScreen("edit");
    };

    const freePlanLimit = isNbTransactionsRestricted && Object.keys(taskItems).length >= 15;
    const disabled = !!createdItem || freePlanLimit;
    const fileUploading = Object.keys(taskItemsFileUploading ?? {}).length > 0;

    return (
        <div className='edit-task-container'>
            {screen === "edit" ? (
                <>
                    <Table
                        isLoading={taskItemsLoading && !taskItems}
                        header={[
                            t("CommonUse.identifier"),
                            t("Transactions.receiver-name"),
                            t("EditTaskPanel.montant-du-transfert"),
                            t("Tasks.julaya-fees"),
                            t("CommonUse.comment"),
                            "",
                        ]}
                        rows={[
                            Object.values(taskItems).map((taskItem) => (
                                <EditTaskRow
                                    isDisabled={fileUploading}
                                    key={`task-item-${taskItem.id}`}
                                    taskId={taskId}
                                    taskItem={taskItem}
                                    handleOpenDrawer={handleOpenDrawer(taskItem)}
                                    handleCloseDrawer={handleCloseDrawer}
                                />
                            )),
                            createdItem && (
                                <EditTaskCreateRow
                                    isDisabled={fileUploading}
                                    key={`task-item-${createdItem.externalFullname}`}
                                    taskItem={createdItem}
                                    taskId={taskId}
                                    handleOpenDrawer={handleOpenDrawerCreateTaskItem}
                                    handleRenameBeneficiary={handleRenameBeneficiaryCreateTask}
                                    handleCancelCreateTaksItem={handleCancelCreateTaskItem}
                                />
                            ),
                            taskItemsFileUploadingFailed &&
                                Object.entries(taskItemsFileUploadingFailed).map(([taskItemId, taskFailed]) => {
                                    return (
                                        <EditTaskCreateRow
                                            key={`task-item-file-loading-${taskItemId}`}
                                            taskItemId={taskItemId}
                                            taskItem={taskFailed as unknown as TaskItemModelToCreate}
                                            taskId={taskId}
                                            handleOpenDrawer={handleOpenDrawerCreateTaskItem}
                                            handleRenameBeneficiary={handleRenameBeneficiaryCreateTask}
                                            handleCancelCreateTaksItem={handleCancelCreateTaskItem}
                                            retry
                                        />
                                    );
                                }),
                            fileUploading &&
                                Object.values(taskItemsFileUploading ?? {}).map((taskToLoad, index) => {
                                    return (
                                        <EditTaskCreateRow
                                            key={`task-item-file-loading-${index}`}
                                            taskItem={taskToLoad as unknown as TaskItemModelToCreate}
                                            loading={true}
                                            taskId={taskId}
                                            handleOpenDrawer={handleOpenDrawerCreateTaskItem}
                                            handleRenameBeneficiary={handleRenameBeneficiaryCreateTask}
                                            handleCancelCreateTaksItem={handleCancelCreateTaskItem}
                                        />
                                    );
                                }),
                        ]}
                    />
                    <div className='d-flex w-100 justify-content-center mb-5'>
                        <ToolTip
                            position='top'
                            activation={freePlanLimit}
                            content={
                                <div className='tooltip-limited'>
                                    <Typography message={t("Subscription.operations-limit")} className='fw-bold' />
                                    <Typography message={t("Subscription.unlock-unlimited-access")} />
                                </div>
                            }
                        >
                            <div className={`d-flex edit-task-select-button ${disabled ? "disabled" : ""}`}>
                                <Button
                                    disabled={disabled}
                                    label={t("EditTaskPanel.ajouter-une-operation")}
                                    onClick={handleOpenDrawerCreateTaskItem}
                                    testId='create-task-add-operation-button'
                                />
                                <div className='task-button-vertical-divider' />
                                <Select
                                    forceClose={fileUploading || !!createdItem || open}
                                    fitContent
                                    contentClassName='task-button-select-content'
                                    type='warning'
                                    content={{
                                        header: { disabled: false, className: "task-button-select-button" },
                                        dropDownContent: {
                                            body: (
                                                <div className='edit-task-select-container'>
                                                    <div className='px-2 pt-1 pb-3'>
                                                        <div
                                                            className={`select-item cursor-pointer ${
                                                                disabled ? "disabled" : ""
                                                            }`}
                                                            onClick={handleOpenDrawerCreateTaskItem}
                                                        >
                                                            <Icon name='plus' size='sm' />
                                                            <Typography
                                                                className='fw-bold ml-2'
                                                                message={t("EditTaskPanel.ajouter-une-operation")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='px-2 pb-3'>
                                                        <label className='cursor-pointer' htmlFor='files'>
                                                            <div className='d-flex justify-content-between'>
                                                                <div
                                                                    className={`select-item ${
                                                                        disabled || fileUploading ? "disabled" : ""
                                                                    }`}
                                                                >
                                                                    <Icon name='document' size='sm' />
                                                                    <Typography
                                                                        className='fw-bold ml-2'
                                                                        message={t("EditTaskPanel.utiliser-un-modele")}
                                                                    />
                                                                </div>
                                                                {fileUploading && <Spinner size='xs' />}
                                                            </div>
                                                        </label>
                                                        <input
                                                            type='file'
                                                            disabled={fileUploading || disabled}
                                                            id='files'
                                                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                                            onChange={onFileLoaded}
                                                            style={{ display: "none" }}
                                                        />
                                                    </div>
                                                    <div className='px-2 pb-2'>
                                                        <a
                                                            href={`/models/bulk_transfert_modele_julaya.xlsx`}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                            className='select-item'
                                                        >
                                                            <Icon name='arrow-down-on-square' size='sm' />
                                                            <Typography
                                                                className='fw-bold ml-2'
                                                                message={t("EditTaskPanel.download-model")}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    }}
                                />
                            </div>
                        </ToolTip>
                    </div>
                </>
            ) : (
                <EditTaskSummaryPanel />
            )}
            <div className='edit-task-footer'>
                <TasksEditFooterDialog taskId={taskId} />
                <EditTaskFooterSummary
                    taskCreation={!!createdItem}
                    taskId={taskId}
                    screen={screen}
                    handleSwitchToEdit={handleSwitchToEdit}
                    handleSwitchToSignature={handleSwitchToSignature}
                />
            </div>
            <BeneficiaryDrawer
                isOpen={open}
                walletId={String(task?.walletId)}
                handleSetClose={handleCloseDrawer}
                handleSelectBeneficiary={createdItem ? handleSelectBeneficiaryCreateTaskitem : handleSelectBeneficiary}
            />
        </div>
    );
}
