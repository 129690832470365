import { ArchiveCard } from "./ArchiveCard";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { requestMiddlewareAdminWalletById } from "src/services/admin/operations";
import { UserModel } from "src/shared/models/User";
import { CompanyRoleLabel } from "src/shared/models/UserCompany";
import { AdminWalletModel } from "src/shared/models/Wallet";

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { Signataire } from "src/shared/components/Signataire/Signataire";

import "./styles.scss";

export const AdminWalletDetailsPanel = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const walletId = location.pathname.split("/")[3];
    const [adminWallet, setAdminWallet] = useState<AdminWalletModel>();
    const [signataires, setSignataires] = useState<UserModel[]>([]);
    const [superSignataires, setSuperSignataires] = useState<UserModel[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        requestMiddlewareAdminWalletById(parseInt(walletId)).then((response) => {
            setAdminWallet(response);
            if (response.isOrderedSignatures) {
                setSignataires(
                    response.users
                        .filter((user) => user.order)
                        .sort((a, b) => (a.order && b.order ? a.order - b.order : 1 - 1))
                );
            } else {
                setSignataires(response.users.filter((user) => !user.isSuperSignature) as Array<UserModel>);
            }
            setSuperSignataires(response.users.filter((user) => user.isSuperSignature) as Array<UserModel>);
        });
    }, [walletId]);

    const handleNavigate = () => {
        navigate(`/admin/wallets/${walletId}/edit`);
    };

    return (
        <>
            <PageHeader title={t<string>("Wallets.wallet-details")} />
            <div className='admin-wallet-detail-container mb-3'>
                {!adminWallet ? (
                    <div className='loading-placeholder'>
                        <div className='placeholder' style={{ animationDelay: "0.1s" }} />
                        <div className='placeholder' style={{ animationDelay: "0.2s" }} />
                    </div>
                ) : (
                    <>
                        <div className='admin-wallet-detail-section'>
                            <div className='section-header'>
                                <div className='header-title'>
                                    <Avatar icon='office' />
                                    <Typography className='fw-bold' message={t("Wallets.information")} />
                                </div>
                                <Button variant='primary' label={t("Wallets.edit")} onClick={handleNavigate} />
                            </div>
                            <div className='section-container p-3'>
                                <div className='info-container'>
                                    <div className='info-row'>
                                        <Typography className='color-neutral' message={t("Wallets.name")} />
                                        <Typography className='fw-bold' message={adminWallet.label} />
                                    </div>
                                    <div className='info-row'>
                                        <Typography className='color-neutral' message={t("Wallets.nb-users")} />
                                        <Typography
                                            className='fw-bold'
                                            message={`${adminWallet.users.length} ${t("Users.list")}`}
                                        />
                                    </div>
                                    <div className='info-row'>
                                        <Typography className='color-neutral' message={t("Wallets.ordered-signs")} />
                                        <Typography
                                            className='fw-bold'
                                            message={`${moment(adminWallet.createdAt).format("DD/MM/YYYY")}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='admin-wallet-detail-section'>
                            <div className='section-header'>
                                <div className='header-title'>
                                    <Avatar icon='gear' />
                                    <Typography className='fw-bold' message={t("Wallets.signs")} />
                                </div>
                            </div>

                            <div className='section-container p-3'>
                                <div className='account-params-container'>
                                    <div className='account-params-content'>
                                        <Typography
                                            className='color-neutral fw-bold'
                                            message={t("Wallets.wallet-parameters")}
                                        />
                                        <Typography message={t("Wallets.define-nb-signatories-description")} />
                                    </div>
                                    <div className='account-params-content'>
                                        <div className='mandatory-signatures'>
                                            <div className='mandatory-signatures-number'>
                                                {adminWallet.nbSignatures}
                                            </div>
                                            <Typography className='fw-bold' message={t("Wallets.required-signs")} />
                                        </div>
                                        <div className='mandatory-signatures-ordered'>
                                            <div className='ordered-signatures'>
                                                <div
                                                    className={`ordered-signatures-icon ${
                                                        adminWallet.isOrderedSignatures ? "checked" : ""
                                                    }`}
                                                >
                                                    <Icon
                                                        name={adminWallet.isOrderedSignatures ? "check-bold" : "x-bold"}
                                                        className='color-white'
                                                    />
                                                </div>
                                                <Typography className='fw-bold' message={t("Wallets.ordered-signs")} />
                                            </div>

                                            <div className='ordered-signatures-text'>
                                                <Typography message={t("Wallets.order-your-signatories-description")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {signataires.length > 0 && (
                                    <div className='signataires-container'>
                                        <div className='account-params-content'>
                                            <Typography
                                                className='color-neutral fw-bold'
                                                message={t("Wallets.all-signatories")}
                                            />
                                            <Typography message={t("Wallets.all-signatories-description")} />
                                        </div>

                                        <div className='account-params-content'>
                                            <div className='signataires-list'>
                                                {signataires.length > 0 &&
                                                    signataires.map((item, index) => {
                                                        const previousItem = index > 0 ? signataires[index - 1] : null;
                                                        const isNewOrder =
                                                            !previousItem || item.order !== previousItem.order;
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`signataires-list-row ${
                                                                    adminWallet.isOrderedSignatures ? "" : "hide"
                                                                }`}
                                                            >
                                                                {isNewOrder && (
                                                                    <div className='signataire-icon-and-info'>
                                                                        <Avatar
                                                                            icon='arrow-right-circle'
                                                                            backgroundColor='lilas-50'
                                                                        />
                                                                        <Typography
                                                                            className='color-neutral fw-bold'
                                                                            message={`Signataire #${item.order}`}
                                                                        />
                                                                    </div>
                                                                )}
                                                                <div className='signataire-list-bordered'>
                                                                    <Signataire
                                                                        name={`${item.firstname} ${item.lastname}`}
                                                                        status={t<string>(CompanyRoleLabel[item.role])}
                                                                        isSuperSignataire={
                                                                            item.isSuperSignature
                                                                                ? item.isSuperSignature
                                                                                : false
                                                                        }
                                                                        picture={item.avatar ? item.avatar : null}
                                                                    />
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                <div
                                                    className={`signataire-icon-and-info ${
                                                        adminWallet.isOrderedSignatures ? "" : "hide"
                                                    }`}
                                                >
                                                    <Avatar icon='arrow-right-circle' backgroundColor='lilas-50' />
                                                    <Typography
                                                        className='color-neutral fw-bold'
                                                        message={t("Wallets.send-payment")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='signataires-container'>
                                    <div className='account-params-content'>
                                        <Typography
                                            className='color-neutral fw-bold'
                                            message={t("Wallets.super-signatures")}
                                        />
                                        <Typography message={t("Wallets.super-signatory-description")} />
                                        <Typography
                                            className='fw-bold color-lilas-900'
                                            message={t("Wallets.super-signatory-warning")}
                                        />
                                    </div>
                                    <div className='account-params-content'>
                                        {superSignataires.length > 0 &&
                                            superSignataires.map((item, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <Signataire
                                                            name={`${item.firstname} ${item.lastname}`}
                                                            status={t<string>(CompanyRoleLabel[item.role])}
                                                            isSuperSignataire={
                                                                item.isSuperSignature ? item.isSuperSignature : false
                                                            }
                                                            picture={item.avatar ? item.avatar : null}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ArchiveCard adminWallet={adminWallet} />
                    </>
                )}
            </div>
        </>
    );
};
