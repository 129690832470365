import { countriesPhonePrefixes } from "../../../../shared/const/countryPhonePrefixes";
import { Icon } from "../../Icons/Icon";
import { IconListType } from "../../Icons/IconList";
import { Typography } from "../../Typography/Typography";
import { PhonePrefixSelect } from "./PhonePrefixSelect";
import clsx from "classnames";

import { InputHTMLAttributes, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

export type InputPhoneValue = {
    prefix?: string;
    value?: string;
};

type InputPhoneProps = {
    placeholder: string;
    label?: string;
    hint?: string;
    onChange: (value: InputPhoneValue) => void;
    value?: string;
    prefix?: string;
    variant?: "primary" | "success" | "error" | "warning";
    inputIcon?: IconListType;
    underMessage?: string;
    className?: string;
    disablePhonePrefix?: boolean;
    autoFocus?: boolean;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "onChange">;

export const InputPhone = ({
    placeholder,
    label,
    hint,
    onChange,
    value,
    prefix,
    type = "classic",
    variant = "primary",
    inputIcon,
    underMessage,
    className,
    disabled = false,
    disablePhonePrefix = disabled ? disabled : false,
    autoFocus = false,
}: InputPhoneProps) => {
    const { t } = useTranslation();
    const phonesPrefixes: SelectOption[] = countriesPhonePrefixes.map((prefix) => {
        return { id: prefix.countryCode, label: prefix.prefixLabel, prefixImage: prefix.flag };
    });
    const [isFocused, setIsFocused] = useState(false);
    const [selectedPhonePrefix, setSelectedPhonePrefix] = useState(prefix);
    const [inputValue, setInputValue] = useState(value || "");

    const selected = useMemo(() => {
        const selectedCountry = countriesPhonePrefixes.find((p) => p.phonePrefix === prefix);
        if (selectedCountry) {
            return {
                id: selectedCountry.countryCode,
                label: selectedCountry.prefixLabel,
                prefixImage: selectedCountry.flag,
            };
        }

        return null;
    }, [prefix]);

    useEffect(() => {
        setInputValue(value || "");
        setSelectedPhonePrefix(prefix);
    }, [value, prefix]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        onChange({ prefix: selectedPhonePrefix, value: e.target.value });
    };

    const handleSelectCountryPhonePrefix = (index: SelectOption) => {
        const countryPrefix = countriesPhonePrefixes.find((p) => p.prefixLabel === index.label);

        if (countryPrefix) {
            setSelectedPhonePrefix(countryPrefix.phonePrefix);
            onChange({ prefix: countryPrefix.phonePrefix, value: inputValue });
        }
    };

    return (
        <div
            className={`input-phone-container ${className} variant-${variant} type-${type} `}
            data-testid='input-phone-container'
        >
            {(label || hint) && (
                <div className='input-label'>
                    <Typography
                        message={label}
                        className={`${disabled ? "color-neutral-400" : "color-neutral-900"} fw-bold`}
                    />
                    <Typography message={hint} className='color-neutral-500' />
                </div>
            )}
            <div className={`input variant-${variant} ${isFocused ? "focused" : ""}`}>
                {inputIcon && <Icon name={inputIcon} className='mr-2 my-auto' />}
                <PhonePrefixSelect
                    options={phonesPrefixes}
                    selected={selected}
                    onSelectionChange={handleSelectCountryPhonePrefix}
                    disabled={disablePhonePrefix}
                    placeholder={t<string>("CommonUse.phone-prefix-placeholder")}
                />
                <input
                    autoFocus={autoFocus}
                    disabled={disabled}
                    className={clsx("input-field", {
                        focused: isFocused,
                        disabled: disabled,
                    })}
                    type='number'
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
            </div>

            {underMessage && variant !== "primary" && (
                <div className={`under-message variant-${variant} fadeInUp`} data-testid='input-phone-under-message'>
                    {variant === "success" && <Icon name='check-circle-filled' />}
                    {variant === "error" && <Icon name='x-circle-filled' />}
                    {variant === "warning" && <Icon name='exclamation-triangle-filled' />}
                    <Typography message={underMessage} className='ml-1 mt-0' />
                </div>
            )}
        </div>
    );
};
