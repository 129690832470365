import { logoutResetStore } from "../auth/Actions";
import { UpdateTransactionCommentProps } from "src/services/transactions/operations";
import { TransactionApi, TransactionReceipt, TransactionsApi } from "src/services/transactions/types";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export type WalletTransactionsStateFilters = {
    archived?: boolean;
    query?: string | undefined;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    status?: SelectOption[] | undefined;
    tasks?: SelectOption[] | undefined;
    transactionType?: SelectOption[] | undefined;
};

type TransactionStateDate = {
    isLoading: boolean;
    count: number;
    currentPage: number;
    totalPages: number;
    pageSize: number;
    data: TransactionApi[];
};

interface TransactionState {
    items: { [walletId: string]: TransactionStateDate };
    filters: WalletTransactionsStateFilters;
}

const initialState = {
    items: {},
    filters: {},
} as TransactionState;

export const walletsTransactionSlice = createSlice({
    name: "transactions",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        setWalletTransactionQueryFilter: (state, { payload }: PayloadAction<{ query: string | undefined }>) => {
            const { query } = payload;

            state.filters.query = query;
        },
        setWalletTransactionStartDateFilter: (state, { payload }: PayloadAction<{ date: Date | undefined }>) => {
            const { date } = payload;

            if (!date) {
                delete state.filters.startDate;
            } else {
                state.filters.startDate = date;
            }
        },
        setWalletTransactionEndDateFilter: (state, { payload }: PayloadAction<{ date: Date | undefined }>) => {
            const { date } = payload;

            if (!date) {
                delete state.filters.endDate;
            } else {
                state.filters.endDate = date;
            }
        },
        setWalletTransactionStatusFilter: (state, { payload }: PayloadAction<{ status: SelectOption[] }>) => {
            const { status } = payload;
            const updatedFilter = state.filters;

            if (status && !status.length) {
                delete updatedFilter.status;
            } else {
                updatedFilter.status = status;
            }
        },
        setWalletTransactionTypeFilter: (state, { payload }: PayloadAction<{ transactionType: SelectOption[] }>) => {
            const { transactionType } = payload;
            const updatedFilter = state.filters;

            if (transactionType && !transactionType.length) {
                delete updatedFilter.transactionType;
            } else {
                updatedFilter.transactionType = transactionType;
            }
        },
        setWalletTransactionTaskFilter: (state, { payload }: PayloadAction<{ tasks: SelectOption[] }>) => {
            const { tasks } = payload;
            const updatedFilter = state.filters;

            if (tasks && !tasks.length) {
                delete updatedFilter.tasks;
            } else {
                updatedFilter.tasks = tasks;
            }
        },
        switchWalletTransactionArchivedFilter: (state) => {
            const updatedFilter = state.filters;

            if (updatedFilter?.archived) {
                delete updatedFilter.archived;
            } else {
                updatedFilter.archived = !state.filters?.archived ?? true;
            }
        },
        resetWalletTransactionFilters: (
            state,
            { payload }: PayloadAction<{ filter: keyof WalletTransactionsStateFilters }>
        ) => {
            const { filter } = payload;
            const updatedFilter = state.filters;

            delete updatedFilter[filter];
        },
        resetAllWalletTransactionFilters: (state) => {
            state.filters = {};
        },
        requestWalletTransactions: (state, { payload }: PayloadAction<{ walletId: string }>) => {
            const { walletId } = payload;

            return {
                ...state,
                items: {
                    [walletId]: {
                        ...state.items[walletId],
                        isLoading: true,
                    },
                },
            };
        },
        failedRequestWalletTransactions: (state, { payload }: PayloadAction<{ walletId: string }>) => {
            const { walletId } = payload;

            return {
                ...state,
                items: {
                    ...state.items,
                    [walletId]: {
                        ...state.items[walletId],
                        isLoading: false,
                    },
                },
            };
        },
        receiveWalletTransactions: (
            state,
            { payload }: PayloadAction<{ walletId: string; transactions: TransactionsApi }>
        ) => {
            const { walletId, transactions } = payload;
            const { data, ...props } = transactions;

            return {
                ...state,
                items: {
                    ...state.items,
                    [walletId]: {
                        ...state.items[walletId],
                        ...props,
                        data,
                        isLoading: false,
                    },
                },
            };
        },
        setWalletTransactionComment: (state, { payload }: PayloadAction<UpdateTransactionCommentProps>) => {
            const { walletId, transactionUuid, comment } = payload;

            const transaction = state.items[walletId]?.data?.find((item) => item.uuid === transactionUuid);

            if (transaction) {
                transaction.comment = comment;
            }
        },
        setWalletTransactionAttachment: (
            state,
            { payload }: PayloadAction<{ walletId: string; transactionUuid: string; file: string }>
        ) => {
            const { walletId, transactionUuid, file } = payload;

            const transaction = state.items[walletId]?.data?.find((item) => item.uuid === transactionUuid);

            if (transaction) {
                transaction.attachment = file;
            }
        },
        setWalletTransactionReceipts: (
            state,
            { payload }: PayloadAction<{ walletId: string; transactionUuid: string; file: TransactionReceipt }>
        ) => {
            const { walletId, transactionUuid, file } = payload;

            const transaction = state.items[walletId]?.data?.find((item) => item.uuid === transactionUuid);

            if (transaction) {
                transaction.receipts.push(file);
            }
        },
        deleteWalletTransactionReceipts: (
            state,
            { payload }: PayloadAction<{ walletId: string; transactionUuid: string; fileId: string }>
        ) => {
            const { walletId, transactionUuid, fileId } = payload;

            const transaction = state.items[walletId]?.data?.find((item) => item.uuid === transactionUuid);

            if (transaction) {
                const newReceipts = [...transaction.receipts].filter((receipt) => String(receipt.id) !== fileId);
                transaction.receipts = newReceipts;
            }
        },
        setWalletTransactionCurrentPage: (
            state,
            { payload }: PayloadAction<{ walletId: string; currentPage: number }>
        ) => {
            const { walletId, currentPage } = payload;

            return {
                ...state,
                items: {
                    ...state.items,
                    [walletId]: {
                        ...state.items[walletId],
                        currentPage,
                    },
                },
            };
        },
        setWalletTransactionPageSize: (state, { payload }: PayloadAction<{ walletId: string; pageSize: number }>) => {
            const { walletId, pageSize } = payload;

            return {
                ...state,
                items: {
                    ...state.items,
                    [walletId]: {
                        ...state.items[walletId],
                        pageSize,
                        currentPage: 1,
                    },
                },
            };
        },
    },
});

export const {
    setWalletTransactionCurrentPage,
    setWalletTransactionPageSize,
    setWalletTransactionQueryFilter,
    setWalletTransactionStartDateFilter,
    setWalletTransactionEndDateFilter,
    setWalletTransactionStatusFilter,
    setWalletTransactionTypeFilter,
    setWalletTransactionTaskFilter,
    switchWalletTransactionArchivedFilter,
    resetWalletTransactionFilters,
    resetAllWalletTransactionFilters,
    requestWalletTransactions,
    failedRequestWalletTransactions,
    receiveWalletTransactions,
    setWalletTransactionComment,
    setWalletTransactionAttachment,
    setWalletTransactionReceipts,
    deleteWalletTransactionReceipts,
} = walletsTransactionSlice.actions;

export const walletTransactionsSliceReducer = walletsTransactionSlice.reducer;
