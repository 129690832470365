import { WalletBeneficiary, WalletBeneficiarySection } from "src/services/wallets/types";

import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../store";

const getWalletsBeneficiariesState = (state: RootState) => state.walletsBeneficiaries;

export const getWalletBeneficiariesIsLoading = createSelector(
    getWalletsBeneficiariesState,
    (walletsBeneficiariesState) => walletsBeneficiariesState.isLoading
);

export const getWalletBeneficiaries = createSelector(
    getWalletsBeneficiariesState,
    (_state: RootState, { walletId }: { walletId: string | number | null | undefined }) => walletId,
    (walletsBeneficiariesState, walletId) => (walletId ? walletsBeneficiariesState.beneficiaries[walletId] : undefined)
);

export const getWalletBeneficiariesByCategory = createSelector(getWalletBeneficiaries, (walletsBeneficiaries) => {
    return walletsBeneficiaries?.reduce<{
        [key in WalletBeneficiarySection]?: (WalletBeneficiary & { warningMessage?: string })[];
    }>((res, wallet) => {
        const section = res[wallet.type];

        if (section) {
            section.push(wallet);
        } else {
            res[wallet.type] = [wallet];
        }
        return res;
    }, {});
});
