import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminBeneficiariesPanel } from "src/components/Admin/Beneficiaries/Panel";

const AdminBeneficiariesPageWithoutAuthorizations = () => {
    return <AdminBeneficiariesPanel />;
};

export const AdminBeneficiariesPage = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminBeneficiariesPageWithoutAuthorizations
);
