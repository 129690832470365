import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getState = (state: RootState) => state.document;

export const getUserDocument = createSelector(getState, (state) => ({
    document: state.document,
    loading: state.loading,
    error: state.error,
}))

export const getSendDocument = createSelector(getState, (state) => ({
   loading: state.sendDocumentLoading,
   error: state.sendDocumentError,
}));