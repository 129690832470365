import classNames from "classnames";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, ButtonProps } from "src/shared/atoms/Buttons/Button";
import { Select, SelectProps } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export type SelectableTabledBarProps = {
    selected?: number;
    handleDeselection?: () => void;
    buttons?: ButtonProps[];
    buttonActions?: SelectProps;
    itemName?: string;
};

export const SelectableTabledBar = ({
    selected,
    handleDeselection,
    buttons,
    buttonActions,
    itemName = "",
}: SelectableTabledBarProps) => {
    const { t } = useTranslation();
    const [isCollapsed, setIsCollapsed] = useState(true);

    useEffect(() => {
        if (selected && selected > 0) {
            setIsCollapsed(false);
        } else {
            setIsCollapsed(true);
        }
    }, [selected]);

    return (
        <div className={classNames("selectable-table-bar", { collapsed: isCollapsed })}>
            <div className='content left'>
                <Button variant='secondary' icon='x-mark' onClick={handleDeselection} size='sm' />
                <Typography
                    message={t("SelectableTableBar.item-selected-length", { itemsLength: selected, itemName })}
                    className='fw-bold'
                />
            </div>
            <div className='content'>
                {buttons &&
                    buttons.map((button: ButtonProps, index: number) => (
                        <Button key={index} {...button} variant='secondary' />
                    ))}
                {buttonActions && <Select {...buttonActions} />}
            </div>
        </div>
    );
};
