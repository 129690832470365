import { middlewareRequest } from "../utils/request";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";
import { DynamoDBKey } from "src/shared/models/DynamoDB";
import {
    ExportFormData,
    ExportTemplate,
    ExportTemplateFormType,
    RegularTemplateFormData,
    ScheduledWalletFormData,
} from "src/shared/models/Export";

export type ExportFormatOptions = "csv" | "qif" | "xlsx";

type SendExportTemplateParams = {
    data: RegularTemplateFormData | ScheduledWalletFormData;
} & HandleSubmitPropsPasswordValidation;

export async function sendExportTemplate({ data, password, twoFactor }: SendExportTemplateParams) {
    try {
        const payload = {
            ...data,
            password,
            otp: twoFactor,
        };

        console.error({ data });
        return await middlewareRequest({ endpoint: "/exports/templates", method: "POST", params: payload });
    } catch (e) {
        return Promise.reject(e);
    }
}

export type FetchExportTemplateParams = {
    limit: number;
    types: ExportTemplateFormType[];
    lastEvaluatedKey?: DynamoDBKey | null;
    name?: string;
};

type ExportTemplateResponse = {
    templates: ExportTemplate[];
    lastEvaluatedKey?: DynamoDBKey;
};

export async function fetchExportTemplate({
    limit,
    types,
    lastEvaluatedKey,
    name,
}: FetchExportTemplateParams): Promise<ExportTemplateResponse> {
    try {
        const payload = {
            limit,
            types,
            lastEvaluatedKey,
            name,
        };

        const response: ExportTemplateResponse = await middlewareRequest({
            endpoint: "/exports/templates/list",
            method: "POST",
            params: payload,
        });

        const { templates, lastEvaluatedKey: newLastEvaluatedKey } = response;

        return { templates, lastEvaluatedKey: newLastEvaluatedKey };
    } catch (e) {
        return Promise.reject(e);
    }
}

interface FetchExportTemplatesPayload {
    limit: number;
    types: ExportTemplateFormType[];
    lastEvaluatedKey?: DynamoDBKey | null;
}

export async function fetchAllExportTemplatesByTypes(types: ExportTemplateFormType[]): Promise<ExportTemplate[]> {
    const payload: FetchExportTemplatesPayload = {
        limit: 20,
        types,
    };

    const fetchTemplates = async (
        lastEvaluatedKey: DynamoDBKey | null = null,
        accumulatedTemplates: ExportTemplate[] = []
    ): Promise<ExportTemplate[]> => {
        if (lastEvaluatedKey) {
            payload.lastEvaluatedKey = lastEvaluatedKey;
        }

        const response = await fetchExportTemplate(payload);

        const newTemplates: ExportTemplate[] = accumulatedTemplates.concat(response.templates);
        if (response.lastEvaluatedKey) {
            return await fetchTemplates(response.lastEvaluatedKey, newTemplates);
        }

        return newTemplates;
    };

    return await fetchTemplates();
}

type DeleteExportTemplateParams = {
    id: string;
} & HandleSubmitPropsPasswordValidation;

export async function deleteExportTemplate({ id, password, twoFactor }: DeleteExportTemplateParams) {
    const payload = {
        password,
        twoFactor,
    };
    try {
        return await middlewareRequest({ endpoint: `/exports/templates/${id}`, method: "DELETE", params: payload });
    } catch (e) {
        return Promise.reject(e);
    }
}

export async function patchExportTemplateStatus(template: ExportTemplate) {
    try {
        return await middlewareRequest({
            endpoint: `/exports/templates/${template.id}`,
            method: "PATCH",
            params: {
                ...template,
                status: template.status === "active" ? "disabled" : "active",
            },
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export async function patchExportTemplate(template: any, templateId: string) {
    try {
        return await middlewareRequest({
            endpoint: `/exports/templates/${templateId}`,
            method: "PATCH",
            params: template,
        });
    } catch (e) {
        return Promise.reject(e);
    }
}

export type FetchExportHistoryListParams = {
    limit: number;
    types?: ExportTemplateFormType[];
    lastEvaluatedKey?: DynamoDBKey | null;
    startDate?: string;
    endDate?: string;
    name?: string;
    createdBy?: number;
    isBankStatement?: boolean;
};

type ExportHistoryListResponse = {
    exports: ExportTemplate[];
    lastEvaluatedKey?: DynamoDBKey;
};

export async function fetchExportHistoryList({
    limit,
    types,
    lastEvaluatedKey,
    name,
    startDate,
    endDate,
    createdBy,
    isBankStatement,
}: FetchExportHistoryListParams): Promise<ExportHistoryListResponse> {
    try {
        const payload = {
            limit,
            types,
            lastEvaluatedKey,
            name,
            startDate,
            endDate,
            createdBy,
            isBankStatement,
        };

        const response: ExportHistoryListResponse = await middlewareRequest({
            endpoint: "/exports/list",
            method: "POST",
            params: payload,
        });
        const { exports, lastEvaluatedKey: newLastEvaluatedKey } = response;

        return { exports, lastEvaluatedKey: newLastEvaluatedKey };
    } catch (e) {
        return Promise.reject(e);
    }
}

type SendExportParams = {
    data: ExportFormData | ExportTemplate;
} & HandleSubmitPropsPasswordValidation;

export async function sendExport({ data, password, twoFactor }: SendExportParams) {
    try {
        const payload = {
            ...data,
            password,
            otp: twoFactor,
        };

        return await middlewareRequest({ endpoint: "/exports/request", method: "POST", params: payload });
    } catch (e) {
        return Promise.reject(e);
    }
}
