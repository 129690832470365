import { fetchAdminWallets } from "./thunkActions";
import { AdminWalletParams } from "src/services/admin/types";

import { useEffect } from "react";

import { getAdminWallets, getAdminWalletsAsSelectOptions } from "./selectors";
import { useAppDispatch, useAppSelector } from "src/store";

export function useAdminWallets(params: AdminWalletParams) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAdminWallets({ ...params }));
    }, [dispatch, params]);

    return useAppSelector(getAdminWallets);
}

export function useAdminWalletsAsSelectOptions({ fetch = true }: { fetch?: boolean }) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (fetch) {
            dispatch(fetchAdminWallets({ page: -1 }));
        }
    }, [dispatch, fetch]);

    return useAppSelector(getAdminWalletsAsSelectOptions);
}
