import { WalletBeneficiary } from "src/services/wallets/types";
import { WalletModel } from "src/shared/models/Wallet";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type TransfertDetailsProps = {
    debitAccount: WalletModel | null;
    beneficiary?: WalletBeneficiary;
    amount: number;
};

export const BillsTransfertRecap = ({ debitAccount, beneficiary, amount }: TransfertDetailsProps) => {
    const { t } = useTranslation();

    const fee = beneficiary?.serviceSelected.fees ? (beneficiary.serviceSelected.fees / 100) * amount : 0;

    return (
        <div className='transfert-details-container'>
            <div className='divider' />

            <div className='bills-transfer-details-row'>
                <Typography className='fw-bold' message={t("TransfertDetails.compte-emetteur")} />
                <Typography message={debitAccount?.label} />
            </div>
            <div className='bills-transfer-details-row'>
                <Typography className='fw-bold' message={t("TransfertDetails.solde-disponible")} />
                <Typography message={formatMoneyToString({ amount: debitAccount?.balance ?? 0 })} />
            </div>
            <div className='bills-transfer-details-row'>
                <Typography
                    className='fw-bold'
                    message={t("Bills.transaction-fee", { amount: beneficiary?.serviceSelected.fees ?? 0 })}
                />
                <Typography message={formatMoneyToString({ amount: fee ?? 0 })} />
            </div>
        </div>
    );
};
