export const countries: { [key: string]: { name: string; flag: string } } = {
    civ: {
        name: "Countries.ivory-coast",
        flag: require("src/shared/images/flags/CI.png"),
    },
    sen: {
        name: "Countries.senegal",
        flag: require("src/shared/images/flags/SN.png"),
    },
    ben: {
        name: "Countries.benin",
        flag: require("src/shared/images/flags/BJ.png"),
    },
    bur: {
        name: "Countries.burkina-faso",
        flag: require("src/shared/images/flags/BF.png"),
    },
    fra: {
        name: "Countries.france",
        flag: require("src/shared/images/flags/FR.png"),
    },
    gbs: {
        name: "Countries.guinea-bissau",
        flag: require("src/shared/images/flags/GW.png"),
    },
    mli: {
        name: "Countries.mali",
        flag: require("src/shared/images/flags/ML.png"),
    },
    nig: {
        name: "Countries.niger",
        flag: require("src/shared/images/flags/NE.png"),
    },
    tog: {
        name: "Countries.togo",
        flag: require("src/shared/images/flags/TG.png"),
    },
};

export function getCountryFlag(countryId: string) {
    const country = countries[countryId];
    return country ? country.flag : "#";
}

export function getCountryLabel(countryId: string) {
    const country = countries[countryId];
    return country ? country.name : "";
}

export function getCountryInformations(countryId: string) {
    const country = countries[countryId];
    return country ?? null;
}
