import { formatMoney } from "../../utils/formatMoney";

import { ChangeEvent, InputHTMLAttributes, useState } from "react";

import { useTranslation } from "react-i18next";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type AmountInputProps = {
    label: string;
    value: number;
    handleChange: (value: number) => void;
    variant?: "primary" | "success" | "error" | "warning";
    underMessage?: string | null;
    testId?: string;
    autoFocus?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const AmountInput = ({
    label,
    value,
    handleChange,
    underMessage,
    variant = "primary",
    testId,
    autoFocus,
    ...props
}: AmountInputProps) => {
    const { t } = useTranslation();
    const [val, setVal] = useState(isNaN(value) ? "" : formatMoney(value));
    const [isFocused, setIsFocused] = useState(false);

    const onChangeFocus = () => {
        setIsFocused(true);
    };
    const onChangeBlur = () => {
        setIsFocused(false);
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const digits = parseInt(e.target.value.replace(/\D/g, ""));

        handleChange(digits);

        const formattedValue = isNaN(digits) ? "" : formatMoney(digits);
        setVal(formattedValue);
    };

    return (
        <div className={`amount-input-container`}>
            <div
                className={`amount-input ${"input-" + variant} ${value > 0 ? "filled" : ""} ${
                    isFocused ? "focused" : ""
                }`}
            >
                <Typography className='fw-bold amount-input-label' message={label} />

                {val && (
                    <div className='amount-currency-container'>
                        <Typography className='amount-input-value' message={val || 0} />
                        <Typography
                            className={`amount-currency ${val === "" || val === "0" ? "zero" : ""}`}
                            message={" FCFA"}
                        />
                    </div>
                )}

                <input
                    data-testid={testId}
                    className={`amount-text-input ${val === "" || val === "0" ? "zero" : ""}`}
                    type='text'
                    autoComplete='off'
                    value={val}
                    onChange={onChange}
                    onFocus={onChangeFocus}
                    onBlur={onChangeBlur}
                    autoFocus={autoFocus}
                    {...props}
                />
            </div>
            {underMessage && (
                <div
                    className={`under-message ${
                        variant === "primary" ? "color-neutral" : `variant-${variant}`
                    } fadeInUp`}
                >
                    {variant === "success" && <Icon name='check-circle-filled' data-testid='icon-success' />}
                    {variant === "error" && <Icon name='x-circle-filled' data-testid='icon-error' />}
                    {variant === "warning" && <Icon name='exclamation-triangle-filled' data-testid='icon-warning' />}
                    <Typography message={t(underMessage)} testId='under-message' />
                </div>
            )}
        </div>
    );
};
