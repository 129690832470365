import { LayoutBreadcrumbStandard } from "../Standard";
import { useParams } from "react-router-dom";
import { DrawerContext } from "src/wrapper/context";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { useRouterTask } from "src/modules/tasks/hooks";
import { getTaskToEdit } from "src/modules/tasks/selectors";
import { getWallet } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

export function DashboardBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    icon: "home",
                },
            ]}
        />
    );
}

export function ProfileBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    icon: "home",
                    link: "/dashboard",
                },
                {
                    label: t("CommonUse.account-settings"),
                },
            ]}
        />
    );
}

type RouteWalletParams = {
    walletId: string;
};

export function HistoryWalletBreadcrumb() {
    const { t } = useTranslation();
    const { walletId } = useParams<keyof RouteWalletParams>() as RouteWalletParams;
    const wallet = useAppSelector((state) => getWallet(state, { walletId: parseInt(walletId) }));

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: wallet?.label ?? t("CommonUse.loading"),
                },
            ]}
        />
    );
}

export function HistoryWalletTaskBreadcrumb() {
    const { t } = useTranslation();
    const task = useRouterTask();

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: t("Tasks.my-tasks"),
                    link: "/tasks",
                },
                {
                    label: task?.title ?? "",
                },
            ]}
        />
    );
}

export function CreateTasksBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: t("CommonUse.add-task"),
                },
            ]}
        />
    );
}

export function TasksBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: t("Tasks.my-tasks"),
                },
            ]}
        />
    );
}

export function TaskEditBreadcrumb() {
    const { t } = useTranslation();
    const taskToEdit = useAppSelector((state) => getTaskToEdit(state));

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: t("Tasks.my-tasks"),
                    link: "/tasks",
                },
                {
                    label: taskToEdit?.title ?? "",
                },
            ]}
        />
    );
}

export function RechargementBreadcrumb() {
    const { t } = useTranslation();
    const { setOpen } = useContext(DrawerContext);

    const handleOpenDrawer = () => {
        setOpen("topUp");
    };

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: t("TopUp.top-up-method"),
                    action: handleOpenDrawer,
                    id: "breadcrumb_topup",
                },
                {
                    label: t("RechargementBreadcrumb.recharger-mon-compte"),
                },
            ]}
        />
    );
}

export function CollectBreadcrumb() {
    const { t } = useTranslation();
    const { setOpen } = useContext(DrawerContext);

    const handleOpenDrawer = () => {
        setOpen("topUp");
    };

    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: t("TopUp.top-up-method"),
                    action: handleOpenDrawer,
                    id: "breadcrumb_topup",
                },
                {
                    label: t("TopUp.collect-method-title"),
                },
            ]}
        />
    );
}

export function LoanBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Loans.loans"),
                    icon: "home",
                },
            ]}
        />
    );
}

export function LoanTransfertBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Loans.loans"),
                    link: "/loan/my-loans",
                    icon: "home",
                },
                {
                    label: t("Transactions.pay"),
                },
            ]}
        />
    );
}

export function TransfertBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: t("TransfertBreadcrumb.transfert-rapide"),
                },
            ]}
        />
    );
}

export function BulkTransfertBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Wallets.my-wallets"),
                    link: "/dashboard",
                    icon: "home",
                },
                {
                    label: t("CommonUse.add-task"),
                },
            ]}
        />
    );
}

export function BillingBePaidBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Billing.billing"),
                    link: "/billing/pay-bill",
                    icon: "home",
                },
                {
                    label: t("Billing.be-paid"),
                },
            ]}
        />
    );
}

export function BillingPayBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Billing.billing"),
                    link: "/billing/pay-bill",
                    icon: "home",
                },
                {
                    label: t("Billing.pay-bill"),
                },
            ]}
        />
    );
}

export function BillingHistoryBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Billing.billing"),
                    link: "/billing/pay-bill",
                    icon: "home",
                },
                {
                    label: t("Billing.history"),
                },
            ]}
        />
    );
}

export function ExportBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Export.Exports"),
                    icon: "home",
                },
                {
                    label: t("Export.my-exports"),
                    link: "/export",
                },
            ]}
        />
    );
}

export function ExportTemplatesBreadcrumb() {
    const { t } = useTranslation();
    return (
        <LayoutBreadcrumbStandard
            breadcrumbItems={[
                {
                    label: t("Export.Exports"),
                    icon: "home",
                },
                {
                    label: t("Export.export-models"),
                    link: "/export/models",
                },
            ]}
        />
    );
}
