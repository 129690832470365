import { REACT_APP_ENV } from "./services/utils/const";
import useScript from "./shared/utils/useScript";

import { PropsWithChildren } from "react";

import { getAuthUser } from "./modules/auth/selectors";
import { useAppSelector } from "./store";

declare global {
    var HubSpotConversations: {
        widget: {
            remove: () => void;
            load: () => void;
            open: () => void;
        };
    };
    var hsConversationsSettings: {
        identificationEmail?: string;
        loadImmediately?: boolean;
    };
}

export function HubspotWrapper({ children }: PropsWithChildren) {
    const authUser = useAppSelector((state) => getAuthUser(state));

    const { load: loadHubSpot } = useScript({
        src: "//js.hs-scripts.com/6087706.js",
        attributes: {
            type: "text/javascript",
            id: "hs-script-loader",
            async: true,
            defer: true,
        },
        head: true,
    });

    if ("live" === REACT_APP_ENV) {
        loadHubSpot();
        if (window.HubSpotConversations) {
            if (authUser && authUser.email) {
                window.hsConversationsSettings = {
                    identificationEmail: authUser.email,
                };
            }
            window.HubSpotConversations.widget.load();
        } else {
            window.hsConversationsSettings = {
                loadImmediately: true,
            };
        }
    }

    return <>{children}</>;
}
