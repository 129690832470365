import {
    failedBillingEnterprise,
    receiveBillingEnterprise,
    receiveBillingEnterpriseWallet,
    requestBillingEnterprise,
} from "./slice";
import {
    requestApiBillingEnterprise,
    updateApiBillingBillerDetails,
    updateApiBillingBillerInvoiceReceipt,
} from "src/services/billing/enterprise/operations";
import {
    BillingUpdateBillerDetailsPayload,
    BillingUpdateBillerDisponibilityPayload,
    BillingUpdateBillerInvoiceReceiptPayload,
} from "src/services/billing/enterprise/type";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchBillingEnterprise = createAsyncThunk(
    "fetchBillingEnterprise",
    async (params: { companyId: string | number }, { dispatch }) => {
        dispatch(requestBillingEnterprise());
        try {
            const data = await requestApiBillingEnterprise(params);
            dispatch(receiveBillingEnterprise({ data }));
            return data;
        } catch (error: any) {
            dispatch(failedBillingEnterprise({ error: error.message }));
            return error;
        }
    }
);

export const handleUpdateBillerProfileInvoiceReceipt = createAsyncThunk(
    "handleUpdateBillerProfileInvoiceReceipt",
    async (
        { twoFactor, ...props }: BillingUpdateBillerInvoiceReceiptPayload & HandleSubmitPropsPasswordValidation,
        { dispatch }
    ) => {
        try {
            const data = await updateApiBillingBillerInvoiceReceipt({
                otp: twoFactor,
                ...props,
            });
            dispatch(receiveBillingEnterpriseWallet({ walletReference: data.reference }));
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const handleUpdateBillerProfileDetails = createAsyncThunk(
    "handleUpdateBillerProfileDetails",
    async (
        { twoFactor, ...props }: BillingUpdateBillerDetailsPayload & HandleSubmitPropsPasswordValidation,
        { dispatch }
    ) => {
        try {
            const data = await updateApiBillingBillerDetails({
                otp: twoFactor,
                ...props,
            });
            dispatch(receiveBillingEnterprise({ data }));
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const handleSwitchBillerDisponibility = createAsyncThunk(
    "handleSwitchBillerDisponibility",
    async (
        { twoFactor, ...props }: BillingUpdateBillerDisponibilityPayload & HandleSubmitPropsPasswordValidation,
        { dispatch }
    ) => {
        try {
            const data = await updateApiBillingBillerDetails({
                otp: twoFactor,
                ...props,
            });
            dispatch(receiveBillingEnterprise({ data }));
            return data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);
