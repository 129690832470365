import { Icon } from "../../Icons/Icon";
import { Typography } from "../../Typography/Typography";
import { Input } from "../Input";

import "./styles.scss";

interface NumberInputProps {
    label: string;
    value: number;
    setValue: (value: number) => void;
}

export function NumberInput({ label, value, setValue }: NumberInputProps) {
    const handleChangeNumber = (param: number) => {
        if (value === 0 && param === -1) {
            return;
        }
        setValue(value + param);
    };

    return (
        <label className='number-input-container'>
            <Input placeholder={""} onChange={() => {}} value={value.toString()} />
            <div className='buttons-container'>
                <div className='button' onClick={() => handleChangeNumber(-1)} data-testid='decrement-button'>
                    <Icon name='minus' className='icon' />
                </div>
                <div className='button' onClick={() => handleChangeNumber(1)} data-testid='increment-button'>
                    <Icon name='plus' className='icon' />
                </div>
            </div>

            <Typography variant='span' message={label} />
        </label>
    );
}
