import { RequestAdminDocumentReturn } from "./types";
import { fileUploader } from "src/services/utils/fileUploader";
import { middlewareRequest } from "src/services/utils/request";
import { DocumentApiResponseModel } from "src/shared/models/Document";

export async function handleRequestAdminDocuments() {
    try {
        return await middlewareRequest<DocumentApiResponseModel>({
            endpoint: "/admin/documents",
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

type sendAdminDocumentsProps = {
    slug: keyof RequestAdminDocumentReturn;
    file?: File;
    recto?: File;
    verso?: File;
    group?: string;
};
export async function sendAdminDocuments({ file, slug, recto, verso, group }: sendAdminDocumentsProps) {
    try {
        return await fileUploader({
            endpoint: "/admin/documents",
            params:
                recto && verso && group
                    ? {
                          slug,
                          recto,
                          verso,
                          group,
                      }
                    : {
                          slug,
                          [slug]: file,
                          group,
                      },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
