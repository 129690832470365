import { IconListType } from "./IconList";

import { HTMLAttributes } from "react";

import "./styles.scss";

export type IconSizeType = "sm" | "md" | "lg";
export type IconVariant = "squared";
export type IconStatus = "success" | "error" | "warning" | "neutral" | "primary" | "lilas";

export type IconProps = {
    name: IconListType;
    size?: IconSizeType;
    color?: string;
    variant?: IconVariant;
    status?: IconStatus;
} & HTMLAttributes<HTMLElement>;

export const Icon = ({ name, size = "md", color, variant, status, className, ...props }: IconProps) => {
    let _className = "jla-icon-" + name;

    if (size) {
        _className += " icon-" + size;
    }

    if (color) {
        _className += " color-" + color;
    }

    if (variant) {
        _className += " icon-" + variant;
    }

    if (status) {
        _className += " status " + status;
    }

    return <i {...props} className={` ${_className} ${className}`} />;
};
