import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminWalletDetailsPanel } from "src/components/Admin/Wallets/Details/Panel";

const AdminWalletDetails = () => {
    return <AdminWalletDetailsPanel />;
};

export const AdminWalletDetailsPage = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminWalletDetails
);
