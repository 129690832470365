import { deleteExportTemplate, patchExportTemplateStatus } from "src/services/export/operations";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import {
    BankStatementExportTemplate,
    ExportTemplate,
    ExportTemplateFormType,
    RecurrentExportTemplate,
} from "src/shared/models/Export";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { StatusIcon } from "src/shared/atoms/Icons/StatusIcons";
import { Select } from "src/shared/atoms/Select/Select";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

const successContentImage = require("src/shared/images/user/success.png");

const emptyListImg = require("src/shared/images/empty/welcome.png");
const emptyResearchdImg = require("src/shared/images/empty/empty-user.svg").default;

type ExportTemplateTablesProps = {
    templateType: "regular" | "recurrent";
    exportTemplates: ExportTemplate[];
    isLoading: boolean;
    openExportTemplateDrawer: (
        template?: ExportTemplate,
        duplicate?: boolean,
        preSelectedFormType?: ExportTemplateFormType
    ) => void;
    refreshList: (type: ExportTemplateFormType) => void;
    hasMoreItems: boolean;
    onPageChange: (isNext: boolean) => void;
    canGoBack: boolean;
    currentPage: number;
    queryNotFound: boolean;
};

export const ExportTemplateTables = ({
    templateType,
    exportTemplates,
    isLoading,
    openExportTemplateDrawer,
    refreshList,
    hasMoreItems,
    onPageChange,
    canGoBack,
    currentPage,
    queryNotFound,
}: ExportTemplateTablesProps) => {
    const { t } = useTranslation();

    const [isOpenPasswordModal, setIsOpenPasswordModal] = useState<boolean>(false);
    const [deleteTemplateError, setDeleteTemplateError] = useState();
    const [deleteTemplateSuccess, setDeleteTemplateSuccess] = useState(false);
    const [selectedTemplateToDelete, setSelectedTemplateToDelete] = useState<string | undefined>(undefined);

    const isFeatureExportTemplateLimited = useSubscriptionRestrictions(
        SubscriptionRestrictions.FEATURE_EXPORT_TEMPLATE
    );

    const handleTogglePasswordModal = () => {
        setIsOpenPasswordModal(!isOpenPasswordModal);
    };

    const handleConfirmPasswordModal = async ({
        password,
        twoFactor,
    }: {
        password: string;
        twoFactor: string | null;
    }) => {
        setDeleteTemplateError(undefined);
        setDeleteTemplateSuccess(false);
        if (selectedTemplateToDelete) {
            await deleteExportTemplate({ id: selectedTemplateToDelete, password, twoFactor })
                .then(() => {
                    setDeleteTemplateSuccess(true);
                    setSelectedTemplateToDelete(undefined);
                    if (refreshList) {
                        refreshList(templateType);
                    }
                })
                .catch((error: any) => setDeleteTemplateError(error.message));
        }
    };

    const handleClickActionItem =
        (action: string, template: RecurrentExportTemplate | BankStatementExportTemplate | ExportTemplate) =>
        async () => {
            if (isFeatureExportTemplateLimited) {
                return;
            }
            switch (action) {
                case "activate":
                    await patchExportTemplateStatus(template as RecurrentExportTemplate).then(() => {
                        refreshList("recurrent");
                    });
                    break;
                case "see":
                    openExportTemplateDrawer(template);
                    break;
                case "clone":
                    openExportTemplateDrawer(
                        {
                            ...template,
                            name: template.name + " (1)",
                        },
                        true
                    );
                    break;
                case "remove":
                    setSelectedTemplateToDelete(template.id);
                    handleTogglePasswordModal();
                    break;
                default:
                    break;
            }
        };

    const tableRows =
        templateType === "regular"
            ? exportTemplates.map((template) => {
                  return [
                      {
                          content: <Avatar icon={"document"} color='lilas-900' backgroundColor='lilas-200' />,
                          type: "image",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: <Typography className='fw-bold' message={template.name} />,
                          type: "text",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: <Typography className='fw-bold' message={template.createdBy} />,
                          type: "text",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: (
                              <Typography className='fw-bold' message={computeTimestampToString(template.createdAt)} />
                          ),
                          type: "text",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: (
                              <Select
                                  forceClose={true}
                                  color='grey'
                                  content={{
                                      header: {
                                          icon: isFeatureExportTemplateLimited ? "lock" : "ellipsis-vertical",
                                          disabled: isFeatureExportTemplateLimited,
                                      },
                                      dropDownContent: {
                                          body: (
                                              <div className='export-templates-select-actions'>
                                                  <div
                                                      className='export-templates-action-item'
                                                      onClick={handleClickActionItem("see", template)}
                                                  >
                                                      <Icon name='pencil-square' />
                                                      <Typography message={t("CommonUse.see-edit")} />
                                                  </div>
                                                  <div
                                                      className='export-templates-action-item'
                                                      onClick={handleClickActionItem("clone", template)}
                                                  >
                                                      <Icon name='square-2-stack' />
                                                      <Typography message={t("CommonUse.clone")} />
                                                  </div>
                                                  <div
                                                      className='export-templates-action-item'
                                                      onClick={handleClickActionItem("remove", template)}
                                                  >
                                                      <Icon name='thrash' />
                                                      <Typography message={t("CommonUse.delete")} />
                                                  </div>
                                              </div>
                                          ),
                                      },
                                  }}
                              />
                          ),
                          type: "button",
                      },
                  ];
              })
            : exportTemplates.map((template) => {
                  return [
                      {
                          content: (
                              <Avatar
                                  icon={template.type === "bank_statement" ? "bank" : "clock"}
                                  color='lilas-900'
                                  backgroundColor='lilas-200'
                              />
                          ),
                          type: "image",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: <Typography message={template.name} />,
                          type: "text",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: <Typography message={t(`Export.${template.frequency}`)} />,
                          type: "text",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: <Typography message={template.createdBy} />,
                          type: "text",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: <Typography message={computeTimestampToString(template.createdAt)} />,
                          type: "text",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: <StatusIcon status={template.status} withMessage />,
                          type: "image",
                          onClick: handleClickActionItem("see", template),
                      },
                      {
                          content: (
                              <Select
                                  forceClose={true}
                                  color='grey'
                                  content={{
                                      header: { icon: "ellipsis-vertical" },
                                      dropDownContent: {
                                          body: (
                                              <div className='export-templates-select-actions'>
                                                  <div
                                                      className='export-templates-action-item underlined'
                                                      onClick={handleClickActionItem("activate", template)}
                                                  >
                                                      <Icon name='refresh' />
                                                      <Typography
                                                          message={
                                                              template.status !== "active"
                                                                  ? t("Export.activate")
                                                                  : t("Export.deactivate")
                                                          }
                                                      />
                                                  </div>
                                                  <div className='divider' />
                                                  <div
                                                      className='export-templates-action-item'
                                                      onClick={handleClickActionItem("see", template)}
                                                  >
                                                      <Icon name='pencil-square' />
                                                      <Typography message={t("CommonUse.see-edit")} />
                                                  </div>
                                                  <div
                                                      className='export-templates-action-item'
                                                      onClick={handleClickActionItem("clone", template)}
                                                  >
                                                      <Icon name='square-2-stack' />
                                                      <Typography message={t("CommonUse.clone")} />
                                                  </div>
                                                  <div
                                                      className='export-templates-action-item'
                                                      onClick={handleClickActionItem("remove", template)}
                                                  >
                                                      <Icon name='thrash' />
                                                      <Typography message={t("CommonUse.delete")} />
                                                  </div>
                                              </div>
                                          ),
                                      },
                                  }}
                              />
                          ),
                          type: "button",
                      },
                  ];
              });

    return (
        <div className='export-template-table'>
            <SelectableTable
                isLoading={isLoading}
                paginationDynamo={{
                    onPageChange: onPageChange,
                    hasMoreItems: hasMoreItems,
                    currentPage: currentPage,
                    canGoBack: canGoBack,
                }}
                header={
                    templateType === "regular"
                        ? ["", t("Export.export-name"), t("CommonUse.created-by"), t("Tasks.created-at"), ""]
                        : [
                              "",
                              t("Export.export-name"),
                              t("Export.recurrence"),
                              t("CommonUse.created-by"),
                              t("Tasks.created-at"),
                              t("CommonUse.statut"),
                              "",
                          ]
                }
                empty={
                    queryNotFound ? (
                        <Empty
                            image={emptyResearchdImg}
                            title={t("Table.no-result-found")}
                            description={t<string>("Table.no-result-found-description")}
                        />
                    ) : (
                        <Empty
                            image={emptyListImg}
                            title={t(
                                templateType === "regular"
                                    ? "Export.empty-template-title"
                                    : "Export.empty-scheduled-title"
                            )}
                            description={t<string>(
                                templateType === "regular" ? "Export.empty-template-msg" : "Export.empty-scheduled-msg"
                            )}
                            button={
                                <ToolTip
                                    content={
                                        <div className='tooltip-limited'>
                                            <Typography
                                                message={t("Subscription.not-accessible-feature")}
                                                className='fw-bold'
                                            />
                                            <Typography message={t("Subscription.unlock-unlimited-access")} />
                                        </div>
                                    }
                                    position='right'
                                    activation={isFeatureExportTemplateLimited}
                                >
                                    <Button
                                        disabled={isFeatureExportTemplateLimited}
                                        label={t("Export.new-export-template")}
                                        onClick={() => openExportTemplateDrawer()}
                                        icon='plus-circle'
                                        color='primary'
                                    />
                                </ToolTip>
                            }
                        />
                    )
                }
                rows={tableRows as SelectableTableRow[][]}
            />
            <PasswordValidationModal
                header={{
                    title: t(`${"CommonUse.delete"}`, {
                        entity: t("Export.a-export-template"),
                    }),
                    icon: "arrow-down-on-square",
                }}
                successContent={{
                    image: successContentImage,
                    text: t("Export.export-delete-success").toString(),
                }}
                asyncFromParent
                open={isOpenPasswordModal}
                error={deleteTemplateError}
                succeeded={deleteTemplateSuccess}
                setOpen={handleTogglePasswordModal}
                handleOnSubmit={handleConfirmPasswordModal}
            />
        </div>
    );
};
