import { useTranslation } from "react-i18next";

import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

const helperItems: { image: string; title: string; description: string }[] = [
    {
        image: require("src/shared/images/collect/account.png"),
        title: "TopUp.check-collector-settings",
        description: "TopUp.check-collector-settings-description",
    },
    {
        image: require("src/shared/images/collect/deposit.png"),
        title: "TopUp.do-a-top-up",
        description: "TopUp.do-a-top-up-description",
    },
    {
        image: require("src/shared/images/collect/money-in.png"),
        title: "TopUp.receive-funds",
        description: "TopUp.receive-funds-description",
    },
];

export function CollectHelper() {
    const { t } = useTranslation();

    return (
        <Accordion
            color='white'
            iconColor=''
            icon='question-mark-circle'
            title={t("TopUp.collect-helper-section-title")}
            preSelectedOpen
        >
            <div className='collect-helper-container'>
                {helperItems.map(({ title, description, image }) => (
                    <div className='collect-helper-item' key={title}>
                        <img className='collect-helper-image' alt='' src={image} />
                        <div className='d-flex flex-column'>
                            <Typography className='fw-bold' message={t(title)} />
                            <Typography message={t(description)} />
                        </div>
                    </div>
                ))}
            </div>
        </Accordion>
    );
}
