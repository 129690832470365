import { Icon } from "../../Icons/Icon";
import { IconListType } from "../../Icons/IconList";
import { Typography } from "../../Typography/Typography";
import { Button, ButtonProps } from "../Button";
import classNames from "classnames";

import React, { useEffect, useState } from "react";

import "./styles.scss";

type ButtonSelectProps = {
    buttonProps: ButtonProps;
    selectItems: Array<{ icon: IconListType; title: string; subtitle?: string; onClick?: () => void }>;
    dividerPositions: number[];
    disabled?: boolean;
    displayArrow?: boolean;
};

export const ButtonSelect = ({
    buttonProps,
    selectItems,
    dividerPositions,
    disabled = false,
    displayArrow = true,
}: ButtonSelectProps) => {
    const [openSelect, setOpenSelect] = useState(false);

    const handleOpenSelect = () => {
        setOpenSelect(!openSelect);
    };

    const handleClickItem = (onClick: (() => void) | undefined) => () => {
        if (disabled) {
            return;
        }

        setOpenSelect(false);
        if (onClick) {
            onClick();
        }
    };

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (e.target instanceof Element) {
                if (!e.target.closest(".button-select-container")) {
                    setOpenSelect(false);
                }
            }
        };

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    return (
        <div className='button-select-container'>
            <Button
                {...buttonProps}
                onClick={!displayArrow ? handleOpenSelect : buttonProps.onClick}
                disabled={disabled}
            />
            {displayArrow && (
                <>
                    <div className='button-select-divider' />
                    <Button
                        disabled={disabled}
                        className={classNames({ open: openSelect })}
                        icon={"chevron-down"}
                        onClick={displayArrow ? handleOpenSelect : buttonProps.onClick}
                        variant={buttonProps.variant}
                        size={buttonProps.size}
                        color={buttonProps.color}
                    />
                </>
            )}
            <div className={classNames("select-content", { open: openSelect })}>
                {selectItems.map((item, index) => (
                    <React.Fragment key={index}>
                        <div className='select-content-item' onClick={handleClickItem(item.onClick)}>
                            <div className='select-content-item-icon'>
                                <Icon name={item.icon} />
                            </div>
                            <div className='select-content-item-text'>
                                <Typography className='fw-bold' message={item.title} />
                                {item.subtitle && <Typography message={item.subtitle} />}
                            </div>
                        </div>
                        {dividerPositions.includes(index) && <div className='select-content-divider' />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};
