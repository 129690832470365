import { PropsWithChildren } from "react";

import "./styles.scss";

type PageHeaderProps = {
    title?: string;
};

export const PageHeader = ({ children }: PropsWithChildren<PageHeaderProps>) => {
    return <div className={"page-header-container"}>{children}</div>;
};
