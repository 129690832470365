import { logoutResetStore } from "../auth/Actions";
import { TaskListApiReturn, TaskListApiReturnItem } from "src/services/tasks/types";
import { TaskActivityModel } from "src/shared/models/TaskActivity";
import { computeArrayToObject } from "src/shared/utils/computeArrayToObject";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

interface TasksState {
    isLoading: boolean;
    count?: number;
    data: {
        [taskId: number]: TaskListApiReturnItem;
    };
    filters: {
        query?: string;
        toSign?: boolean;
        walletReferences?: SelectOption[];
    };
    taskToEdit?: TaskListApiReturnItem;
    taskToEditLoading: boolean;
    currentPage: number;
    pageSize: number;
}

export type TaskFiltersKeys = keyof TasksState["filters"];

const initialState = {
    taskToEditLoading: true,
    data: {},
    filters: {},
    isLoading: true,
    currentPage: 1,
    pageSize: 20,
} as TasksState;

export const tasksSlice = createSlice({
    name: "tasks",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestTasks: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        requestTaskToEdit: (state) => {
            return {
                ...state,
                taskToEditLoading: true,
            };
        },
        failedRequestTasks: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
        failedRequestTaskToEdit: (state) => {
            return {
                ...state,
                taskToEditLoading: false,
                taskToEdit: undefined,
            };
        },
        receiveTasks: (state, { payload }: PayloadAction<{ tasks: TaskListApiReturn }>) => {
            const { tasks } = payload;
            const { count } = tasks;

            return {
                ...state,
                data: computeArrayToObject(tasks.data, (task) => String(task.id)),
                count,
                isLoading: false,
            };
        },

        receiveTaskToEdit: (state, { payload }: PayloadAction<{ task: TaskListApiReturnItem }>) => {
            const { task } = payload;

            return {
                ...state,
                taskToEditLoading: false,
                taskToEdit: task,
            };
        },
        receiveTaskComments: (state, { payload }: PayloadAction<{ taskId: number; comments: TaskActivityModel[] }>) => {
            const { taskId, comments } = payload;

            const newTask = state.data?.[taskId];

            return newTask
                ? {
                      ...state,
                      data: {
                          ...state.data,
                          [taskId]: {
                              ...newTask,
                              TaskActivities: comments,
                          },
                      },
                  }
                : state;
        },
        receiveTask: (state, { payload }: PayloadAction<{ task: TaskListApiReturnItem }>) => {
            const { task } = payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    [task.id]: task,
                },
            };
        },
        removeTask: (state, { payload }: PayloadAction<{ taskId: number }>) => {
            const { taskId } = payload;
            const { [taskId]: toDelete, ...toKeep } = state.data;

            void toDelete;

            return {
                ...state,
                data: toKeep,
            };
        },
        setTaskWalletReferenceFilter: (
            state,
            { payload }: PayloadAction<{ walletReferences: SelectOption[] | undefined }>
        ) => {
            const { walletReferences } = payload;

            state.filters.walletReferences = walletReferences;
        },
        setTaskWalletQuery: (state, { payload }: PayloadAction<{ query: string | undefined }>) => {
            const { query } = payload;

            state.filters.query = query;
        },
        switchTaskToSignFilter: (state) => {
            state.filters.toSign = !state.filters.toSign;
        },
        resetTaskFilters: (state) => {
            state.filters = {};
        },
        resetTaskFilter: (state, { payload }: PayloadAction<{ filter: TaskFiltersKeys }>) => {
            delete state.filters?.[payload.filter];
        },
        setTaskCurrentPage: (state, { payload }: PayloadAction<{ currentPage: number }>) => {
            const { currentPage } = payload;

            return {
                ...state,
                currentPage,
            };
        },
        setTaskPageSize: (state, { payload }: PayloadAction<{ pageSize: number }>) => {
            const { pageSize } = payload;

            return {
                ...state,
                pageSize,
                currentPage: 1,
            };
        },
    },
});

export const {
    requestTasks,
    requestTaskToEdit,
    failedRequestTaskToEdit,
    failedRequestTasks,
    receiveTasks,
    receiveTaskToEdit,
    receiveTaskComments,
    receiveTask,
    removeTask,
    setTaskWalletReferenceFilter,
    setTaskWalletQuery,
    switchTaskToSignFilter,
    resetTaskFilters,
    resetTaskFilter,
    setTaskCurrentPage,
    setTaskPageSize,
} = tasksSlice.actions;

export const tasksSliceReducer = tasksSlice.reducer;
