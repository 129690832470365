import { LoginContext } from "src/shared/context/login";

import { ChangeEvent, createRef, useContext, useEffect, useMemo, useState } from "react";

import "./styles.scss";

const CODE_PIN_LENGTH = 4;

export const LoginCodeValidation = () => {
    const { setPassword, setPinCode } = useContext(LoginContext);

    const [values, setValues] = useState<string[]>(Array.from({ length: CODE_PIN_LENGTH }, () => ""));
    const [selectedIndex, setSelectedIndex] = useState(0);

    const inputRefs = useMemo(() => Array.from({ length: CODE_PIN_LENGTH }, () => createRef<HTMLInputElement>()), []);

    useEffect(() => {
        const selectedInput = inputRefs[selectedIndex].current;
        if (selectedInput) {
            selectedInput.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIndex]);

    useEffect(() => {
        setPassword(values.join(""));
        setPinCode(values.join(""));
    }, [values]);

    const handleInputChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target;
        const { value, maxLength } = input;

        if (value.length === maxLength) {
            if (index < inputRefs.length - 1) {
                setSelectedIndex(index + 1);
            }
            setValues((values) => {
                const newValues = [...values];
                newValues[index] = value;
                return newValues;
            });
        }

        if (value.length === 0) {
            if (index > 0) {
                setSelectedIndex(index - 1);
            }
            setValues((values) => {
                const newValues = [...values];
                newValues[index] = value;
                return newValues;
            });
        }
    };

    const handleOnClick = (index: number) => () => {
        setValues((values) => {
            const newValues = [...values];
            newValues[index] = "";
            return newValues;
        });
        setSelectedIndex(index);
    };

    return (
        <div className='code-validation-container'>
            <div className='input-items'>
                {inputRefs.map((ref, index) => (
                    <div key={index}>
                        <input
                            className='input-validation'
                            onClick={handleOnClick(index)}
                            value={values[index]}
                            key={index}
                            type='number'
                            min={0}
                            max={9}
                            maxLength={1}
                            onChange={handleInputChange(index)}
                            ref={ref}
                            data-testid={`code-validation-${index}`}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
