import clsx from "classnames";
import moment, { Moment } from "moment";
import ReactDatetime from "react-datetime";

import { ClassAttributes, TdHTMLAttributes } from "react";

import { useTranslation } from "react-i18next";

import { Select } from "src/shared/atoms/Select/Select";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

interface DatePickerProps {
    label?: React.ReactNode;
    value?: string;
    single?: boolean;
    className?: string;
    startDate: Date | null | undefined;
    endDate?: Date | null | undefined;
    placeholder?: string;
    onChangeStartDate: (date: Date | null) => void;
    onChangeEndDate?: (date: Date | null) => void;
    dateFormat?: string;
    isValidDate?: (currentDate: any, selectedDate: any) => boolean;
}

export const CustomDatePicker = ({
    label,
    single,
    startDate,
    endDate,
    placeholder,
    className = "",
    dateFormat = "DD/MM/YYYY",
    onChangeStartDate,
    onChangeEndDate,
    isValidDate,
}: DatePickerProps) => {
    const { t } = useTranslation();

    const onChangeFunction = (value: Moment | string) => {
        if (single) {
            onChangeStartDate(moment(value).toDate());
        } else {
            if (startDate && endDate) {
                onChangeStartDate(moment(value).toDate());
                onChangeEndDate && onChangeEndDate(null);
            } else if (startDate) {
                if (moment(value).isBefore(moment(startDate))) {
                    onChangeEndDate && onChangeEndDate(startDate);
                    onChangeStartDate(moment(value).toDate());
                } else {
                    onChangeEndDate && onChangeEndDate(moment(value).toDate());
                }
            } else {
                onChangeStartDate(moment(value).toDate());
            }
        }
    };

    const renderDate = (): string => {
        if (single) {
            return startDate ? moment(startDate).format(dateFormat) : placeholder ?? t("CommonUse.select-date");
        }

        if (!startDate && !endDate) {
            return placeholder ?? t("CommonUse.select-date");
        }
        return `${startDate ? moment(startDate).format(dateFormat) : ""} - ${
            endDate ? moment(endDate).format(dateFormat) : ""
        } `;
    };

    const renderDay = (
        props: JSX.IntrinsicAttributes &
            ClassAttributes<HTMLTableDataCellElement> &
            TdHTMLAttributes<HTMLTableDataCellElement>,
        currentDate: Moment,
        selectedDate: Moment
    ) => {
        let selectedStartDate = selectedDate;
        let selectedEndDate = null;
        if (startDate && selectedStartDate) {
            if (selectedStartDate.isAfter(moment(startDate, dateFormat))) {
                selectedStartDate = moment(startDate, dateFormat);
                selectedEndDate = selectedDate;
            } else if (selectedStartDate.isBefore(moment(startDate, dateFormat))) {
                selectedEndDate = moment(startDate, dateFormat);
            }
        }

        // Selected days
        const isActive =
            currentDate.isSame(selectedStartDate) || (selectedEndDate && currentDate.isSame(selectedEndDate));

        // Days between active range
        const isInActiveRange =
            selectedStartDate &&
            selectedEndDate &&
            !selectedStartDate.isSame(selectedEndDate) &&
            currentDate.isBetween(selectedStartDate, selectedEndDate);

        // Days not within current month
        const isNotInCurrentMonth =
            currentDate.isBefore(moment().startOf("month")) || currentDate.isAfter(moment().endOf("month"));

        const isToday = currentDate.isSame(moment().startOf("day"));

        return (
            <td
                {...props}
                className={clsx("rdtDay", {
                    rdtActive: isActive,
                    startDate: !single && isActive && selectedEndDate && currentDate.isSame(selectedStartDate),
                    endDate: isActive && selectedEndDate && currentDate.isSame(selectedEndDate),
                    rdtInActiveRange: isInActiveRange,
                    rdtToday: isToday,
                    rdtNotInCurrentMonth: isNotInCurrentMonth,
                })}
                data-testid={`day-${currentDate.toDate().getUTCDate() + 1}`}
            >
                {!isNotInCurrentMonth ? currentDate.date() : `0${currentDate.date()}`.slice(-2)}
            </td>
        );
    };

    return (
        <div className='custom-date-picker'>
            {label && <Typography message={label} className='fw-bold color-neutral-900' />}
            <Select
                content={{
                    header: {
                        title: renderDate(),
                        icon: "calendar-filled",
                        disabled: false,
                        className: className,
                    },
                    dropDownContent: {
                        body: (
                            <div className='body-content'>
                                <ReactDatetime
                                    isValidDate={isValidDate}
                                    timeFormat={false}
                                    renderDay={renderDay}
                                    renderInput={() => <></>}
                                    onChange={onChangeFunction}
                                    initialValue={startDate ? startDate : ""}
                                />
                            </div>
                        ),
                    },
                }}
            />
        </div>
    );
};
