import { Button } from "../Buttons/Button";
import { Input } from "../Inputs/Input";
import { Typography } from "../Typography/Typography";

import { HTMLAttributes, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { SelectMultipleOptions, SelectOption } from "../../components/SelectMultipleOptions/SelectMultipleOptions";

import "./styles.scss";

const pageSizeOptions: SelectOption[] = [
    { id: "20", label: "20" },
    { id: "50", label: "50" },
    { id: "100", label: "100" },
];

export type PaginateProps = {
    onPageChange: (newPage: number) => void;
    totalCount: number;
    currentPage: number;
    pageSize: number;
    onPageSizeChange?: (newPageSize: number) => void;
    isLoading?: boolean;
} & HTMLAttributes<HTMLElement>;

export const Paginate = ({
    onPageChange,
    totalCount,
    currentPage = 1,
    pageSize = 20,
    onPageSizeChange,
    isLoading = false,
}: PaginateProps) => {
    const { t } = useTranslation();
    const [selectedPageSize, setSelectedPageSize] = useState<SelectOption[] | undefined>();
    const [pageInput, setPageInput] = useState("");
    const [pageInputError, setPageInputError] = useState(false);
    const [closeSelect, setCloseSelect] = useState(false);

    useEffect(() => {
        if (closeSelect) {
            setCloseSelect(false);
        }
    }, [closeSelect]);

    const totalPages = Math.ceil(totalCount / pageSize);

    useEffect(() => {
        document.getElementById("scroll-reset-table-div")?.scrollTo(0, 0);
    }, [currentPage]);

    useEffect(() => {
        const pageInputFilled = pageInput.trim().length > 0;
        const pageInputValid = /^\d+$/i.test(pageInput);
        const pageInputNumber = parseInt(pageInput, 10);
        const pageInputUnderMaxPages = !isNaN(pageInputNumber) && pageInputNumber <= totalPages;
        const pageInputIsNotCurrentPage = pageInputNumber !== currentPage;
        const pageInputMoreThanZero = pageInputNumber > 0;

        setPageInputError(
            !(pageInputValid && pageInputUnderMaxPages && pageInputIsNotCurrentPage && pageInputMoreThanZero) &&
                pageInputFilled
        );
    }, [pageInput, totalPages]);

    useEffect(() => {
        const selectedOption = pageSizeOptions.find((option) => parseInt(option.label, 10) === pageSize);
        setSelectedPageSize(selectedOption ? [selectedOption] : undefined);
    }, [pageSize]);

    const handleSelectedPageSize = (selectedPageSize: SelectOption[]) => {
        setSelectedPageSize(selectedPageSize);
        if (onPageSizeChange) {
            onPageSizeChange(parseInt(selectedPageSize[0].label, 10));
        }
        setCloseSelect(true);
    };

    const handlePageInputChange = (newPageInput: string) => {
        setPageInput(newPageInput);
    };

    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            onPageChange(newPage);
        }
    };

    const generatePageNumbers = useCallback(() => {
        const pages = [];
        const pageLimit = 5;
        let startPage = Math.max(currentPage - 2, 1);
        let endPage = Math.min(startPage + pageLimit - 1, totalPages);

        if (totalPages <= pageLimit) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                endPage = pageLimit;
            } else if (currentPage + 2 >= totalPages) {
                startPage = totalPages - (pageLimit - 1);
            }
        }

        if (startPage > 1) {
            pages.push(1);
            if (startPage > 2) {
                pages.push("...");
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        if (endPage < totalPages - 1) {
            pages.push("...");
        }

        if (endPage < totalPages) {
            pages.push(totalPages);
        }

        return pages;
    }, [pageSize, totalPages, currentPage]);

    const handleChangePage = (page: string) => {
        const newPage = parseInt(page, 10);
        if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages && newPage !== currentPage) {
            onPageChange(newPage);
            setPageInput("");
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && !pageInputError) {
            handleChangePage(pageInput);
        }
    };

    return (
        <div className='paginate-container' data-testid='paginate'>
            <div className='select-page-size-container'>
                <Typography message={t("Pagination.elements-per-page")} className='fw-bold' />
                <SelectMultipleOptions
                    items={pageSizeOptions}
                    selected={selectedPageSize}
                    onSelectionChange={handleSelectedPageSize}
                    useRadio
                    name={""}
                    testId='paginateSelectPageSize'
                    disabled={isLoading}
                    forceClose={closeSelect}
                />
            </div>
            <div className='pagination-container'>
                <Button
                    variant='ghost'
                    className='arrow-buttons'
                    icon='chevron-left'
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1 || isLoading}
                />
                {generatePageNumbers().map((number, index) => (
                    <Button
                        className='number-buttons'
                        key={index}
                        label={number.toString().padStart(2, "0")}
                        color={number === currentPage ? "lilas" : "primary"}
                        variant={number === currentPage ? "secondary" : "ghost"}
                        onClick={() => typeof number === "number" && handlePageChange(number)}
                        disabled={number === "..." || isLoading}
                        testId={"button-" + number.toString()}
                    />
                ))}
                <Button
                    variant='ghost'
                    className='arrow-buttons'
                    icon='chevron-right'
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages || isLoading}
                    testId={"button-next-pagination"}
                />
            </div>
            <div className='direct-page-search-container'>
                <Typography message={t("Pagination.access-to-page")} className='fw-bold' />
                <Input
                    placeholder={"#"}
                    value={pageInput}
                    onChange={handlePageInputChange}
                    onKeyPress={handleKeyPress}
                    disabled={isLoading}
                    variant={pageInputError ? "error" : "primary"}
                />
                <Button
                    icon='magnifying-glass'
                    variant='tertiary'
                    className='search-button'
                    onClick={() => handleChangePage(pageInput)}
                    disabled={isLoading || pageInputError}
                />
            </div>
        </div>
    );
};
