export function splitArrayInSmallerObject<T>(array: T[], size = 5) {
    const arraySplit = [];

    for (let i = 0; i < array.length; i += size) {
        const elements = array.slice(i, i + size).reduce<{ [index: string]: T }>((res, item, index) => {
            res[i + index] = item;
            return res;
        }, {});

        arraySplit.push(elements);
    }

    return arraySplit;
}
