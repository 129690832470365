import { Dispatch, SetStateAction, createContext } from "react";

import { NOTIFICATION_MODE } from "src/components/Notifications/Body";

export type NotificationDrawerContextProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    selectedTab: NOTIFICATION_MODE;
    setSelectedTab: Dispatch<SetStateAction<NOTIFICATION_MODE>>;
};

export const NotificationDrawerContext = createContext<NotificationDrawerContextProps>(
    {} as NotificationDrawerContextProps
);
