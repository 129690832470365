import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getTasksItemsState = (state: RootState) => state.tasksItems;

const getTasksItemsUpdating = createSelector(getTasksItemsState, (tasksItemsState) => tasksItemsState.isUpdating);

export const getTasksItemsIsLoading = createSelector(
    getTasksItemsState,
    (tasksItemsState) => tasksItemsState.isLoading
);

export const getTaskItemsUpdating = createSelector(
    getTasksItemsUpdating,
    (_state: RootState, { taskId }: { taskId: string | number | undefined }) => taskId,
    (tasksItemsState, taskId) => (taskId ? tasksItemsState[taskId] : {})
);

export const getTaskItemsIsUpdating = createSelector(
    getTasksItemsUpdating,
    (_state: RootState, { taskId }: { taskId: string | number | undefined }) => taskId,
    (tasksItemsState, taskId) =>
        taskId && tasksItemsState[taskId]
            ? Object.values(tasksItemsState[taskId]).some((isLoading) => isLoading)
            : false
);

export const getTaskItemsIsUpdatingCount = createSelector(
    getTasksItemsUpdating,
    (_state: RootState, { taskId }: { taskId: string | number | undefined }) => taskId,
    (tasksItemsState, taskId) =>
        taskId && tasksItemsState[taskId]
            ? Object.values(tasksItemsState[taskId]).reduce((res, isLoading) => (isLoading ? res + 1 : res), 0)
            : 0
);

export const getTasksItemsCount = createSelector(getTasksItemsState, (tasksItemsState) => tasksItemsState.count);

export const getTaskItems = createSelector(
    getTasksItemsState,
    (_state: RootState, { taskId }: { taskId: number | string | undefined }) => taskId,
    (tasksItemsState, taskId) => (taskId ? tasksItemsState?.data?.[taskId] ?? {} : {})
);

export const getTaskItemsErrorsCount = createSelector(getTaskItems, (taskItems) =>
    Object.values(taskItems).reduce((res, taskItem) => (taskItem.errorCode ? res + 1 : res), 0)
);

export const getTaskItemsFileUploading = createSelector(
    getTasksItemsState,
    (_state: RootState, { taskId }: { taskId: number | string | undefined }) => taskId,
    (tasksItemsState, taskId) => (taskId ? tasksItemsState.fileUploading?.[taskId] ?? null : null)
);

export const getTaskItemsFileUploadingFailed = createSelector(
    getTasksItemsState,
    (_state: RootState, { taskId }: { taskId: number | string | undefined }) => taskId,
    (tasksItemsState, taskId) => (taskId ? tasksItemsState.fileUploadingFailed?.[taskId] ?? null : null)
);
