import {
    failedCancelTransaction,
    failedMakeTransaction,
    failedRequestBankServices,
    receiveBankServices,
    receiveCancelTransaction,
    receiveMakeTransaction,
    requestBankServices,
    requestCancelTransaction,
    requestMakeTransaction,
} from "./slice";
import {
    CancelTransactionRequestPrams,
    CreateAttachmentParams,
    CreateBillTransaction,
    CreateTransactionParams,
    cancelTransactionRequest,
    createBillTransaction,
    createTransaction,
    getBankServices,
} from "src/services/transactions/operations";
import { WalletModel } from "src/shared/models/Wallet";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchBankServices = createAsyncThunk("fetchBankServices", async (_props: undefined, { dispatch }) => {
    dispatch(requestBankServices());
    try {
        const services = await getBankServices();

        dispatch(receiveBankServices({ services }));
        return services;
    } catch (error) {
        dispatch(failedRequestBankServices());
        return error;
    }
});

export const makeTransaction = createAsyncThunk(
    "makeTransaction",
    async (
        {
            params,
            attachmentParams,
            uniqueId,
        }: { params: CreateTransactionParams; attachmentParams: CreateAttachmentParams | null; uniqueId: string },
        { dispatch }
    ) => {
        dispatch(requestMakeTransaction());
        try {
            const response = await createTransaction(params, attachmentParams, uniqueId);
            if (response) {
                dispatch(receiveMakeTransaction(response));
            } else {
                dispatch(failedMakeTransaction({ error: "" }));
            }
            return response;
        } catch (error: any) {
            dispatch(failedMakeTransaction({ error: error.message }));
            return Promise.reject(error);
        }
    }
);

export type BillReferenceFields = {
    externalReference?: string;
    payerReference?: string;
    invoiceReference?: string;
};

type HandleCreateBillTransactionProps = {
    willCreateTask: boolean;
    selectedDebitWallet: WalletModel;
    amount: number;
    taskUuid: string;
    password: string;
    comment?: string;
    twoFactor: string | null;
    typeSlug: string;
    serviceSlug: string;
    externalFullname: string;
    uniqueId: string;
} & BillReferenceFields;

export const handleCreateBillTransaction = createAsyncThunk(
    "createBillTransaction",
    async (
        {
            willCreateTask,
            taskUuid,
            selectedDebitWallet,
            externalReference,
            externalFullname,
            amount,
            typeSlug,
            serviceSlug,
            password,
            twoFactor,
            comment,
            uniqueId,
            ...props
        }: HandleCreateBillTransactionProps,
        { dispatch }
    ) => {
        dispatch(requestMakeTransaction());
        try {
            const payload: CreateBillTransaction = {
                code: password,
                title: willCreateTask && comment ? comment : externalFullname ?? "Facture",
                taskUuid,
                way: "out",
                items: [
                    {
                        amount,
                        typeSlug,
                        serviceSlug,
                        externalReference,
                        externalFullname,
                        comment,
                        ...props,
                    },
                ],
                walletId: String(selectedDebitWallet?.id),
                signatureKey: uniqueId,
            };
            if (twoFactor?.length) {
                payload.otp = twoFactor;
            }

            const response = await createBillTransaction(payload);
            dispatch(receiveMakeTransaction(response));
            return response;
        } catch (error: any) {
            dispatch(failedMakeTransaction({ error: error.message }));
            return Promise.reject(error);
        }
    }
);

export const cancelTransaction = createAsyncThunk(
    "cancelTransaction",
    async ({ walletId, params }: { walletId: string; params: CancelTransactionRequestPrams }, { dispatch }) => {
        dispatch(requestCancelTransaction());

        try {
            await cancelTransactionRequest(walletId, params);
            dispatch(receiveCancelTransaction());
            return true;
        } catch (error: any) {
            dispatch(failedCancelTransaction({ error: error.message }));
            return false;
        }
    }
);
