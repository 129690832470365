import { ExternalAccountForm, createBeneficiaryExternalAccounts } from "../utils";
import classNames from "classnames";
import { transactionTypesMap } from "src/shared/const/transactions";
import { BeneficiaryModel } from "src/shared/models/Beneficiary";
import { isValidIban } from "src/shared/utils/isValidIban";
import { isValidPhone } from "src/shared/utils/isValidPhone";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminBeneficiaries } from "src/modules/admin/adminBeneficiaries/selectors";
import { clearBeneficiaryIntegration } from "src/modules/admin/adminBeneficiaries/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

import "./styles.scss";

type Props = {
    setExternalAccounts: (value: ExternalAccountForm[]) => void;
    externalAccounts: ExternalAccountForm[];
    selectedBeneficiary: BeneficiaryModel | null;
    validateForms: () => void;
    isBulk?: boolean;
};

export const AdminBeneficiaryDrawerSectionBeneficiaries = ({
    setExternalAccounts,
    externalAccounts,
    selectedBeneficiary,
    validateForms,
    isBulk = false,
}: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [showAddAccountForm, setShowAddAccountForm] = useState(false);
    const [isExternalAccountFormSubmitted, setIsExternalAccountFormSubmitted] = useState(false);
    const [editingAccountIndex, setEditingAccountIndex] = useState<number | null>(null);
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [isEditing, setIsEditing] = useState(isBulk);

    const { integrateBeneficiaryLoading } = useAppSelector(getAdminBeneficiaries);

    const beneficiaryExternalAccounts = createBeneficiaryExternalAccounts();
    const [externalReference, setExternalReference] = useState("");
    const [typeSlug, setTypeSlug] = useState("");
    const selectedTypeSlugOption = beneficiaryExternalAccounts.find((option) => option.id === typeSlug);
    const isPhone = selectedTypeSlugOption && transactionTypesMap[selectedTypeSlugOption.id]?.phoneFormat;

    useEffect(() => {
        setIsFormValidated(externalAccounts.length > 0);
    }, [externalAccounts]);

    const handleOpenPaymentMethodForm = () => {
        setShowAddAccountForm(!showAddAccountForm);
        setExternalReference("");
        setTypeSlug("");
        setEditingAccountIndex(null);
    };

    const handleCancelForm = () => {
        setExternalReference("");
        setTypeSlug("");
        setEditingAccountIndex(null);
        setShowAddAccountForm(false);
    };

    const handleChangeExternalReference = (value: string) => {
        setExternalReference(value);
    };

    const handleChangeTypeSlug = (option: SelectOption) => {
        setTypeSlug(option.id);
    };

    const handleAddAccount = () => {
        setIsExternalAccountFormSubmitted(true);
        const sanitizedExternalReference = externalReference.replace(/\s/g, "");

        const params = {
            externalReference: sanitizedExternalReference,
            typeSlug: typeSlug,
            uuid: null,
        };

        if (editingAccountIndex !== null) {
            const updatedExternalAccounts = [...externalAccounts];
            updatedExternalAccounts[editingAccountIndex] = params;
            setExternalAccounts(updatedExternalAccounts);
        } else {
            setExternalAccounts([...externalAccounts, params]);
        }

        handleChangeValue(params);
        setShowAddAccountForm(false);
        setIsExternalAccountFormSubmitted(false);
        setEditingAccountIndex(null);
    };

    const handleDeleteAccount = (index: number) => () => {
        const newExternalAccounts = [...externalAccounts];
        newExternalAccounts.splice(index, 1);
        setExternalAccounts(newExternalAccounts);
    };

    const handleChangeValue = (values: ExternalAccountForm) => {
        if (isExternalAccountFormSubmitted) {
            const params = {
                externalReference: values.externalReference,
                typeSlug: values.typeSlug,
                uuid: values.uuid,
                countryCode: isPhone ? "" : "",
            };
            setExternalAccounts([...externalAccounts, params]);
        }
    };

    const handleEditAccount = (index: number) => () => {
        const account = externalAccounts[index];
        setExternalReference(account.externalReference);
        setTypeSlug(account.typeSlug);
        setEditingAccountIndex(index);
        setShowAddAccountForm(true);
    };

    const renderBeneficiaryExternalAccountsName = (typeSlug: string) => {
        const account = beneficiaryExternalAccounts.find((account) => account.id === typeSlug);
        return account ? account.label : "";
    };

    const handleToggleEdit = () => {
        dispatch(clearBeneficiaryIntegration());
        setIsEditing(!isEditing);
    };

    const checkExternalAccountError = (account: ExternalAccountForm) => {
        if (account.typeSlug === "bank-transfer") {
            console.log(isValidIban(account.externalReference));
            return !isValidIban(account.externalReference);
        }
        if (
            account.typeSlug === "wave-transfer" ||
            account.typeSlug === "cb-transfer" ||
            account.typeSlug === "disposal"
        ) {
            return !isValidPhone(account.externalReference);
        }

        return !account.externalReference;
    };

    const renderStatus = () => {
        if (!isEditing) {
            return <Typography className={`fw-bold cursor-pointer color-lilas-900`} message={t("CommonUse.modify")} />;
        } else if (showAddAccountForm) {
            return (
                <Typography
                    className={`fw-bold cursor-pointer color-warning`}
                    message={t("CommonUse.being-modified")}
                />
            );
        } else {
            return (
                <Typography
                    className={`fw-bold cursor-pointer ${!isFormValidated ? "color-warning" : "color-success"}`}
                    message={!isFormValidated ? t("CommonUse.to-complete") : t("CommonUse.done")}
                />
            );
        }
    };

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("CreateBeneficiaryAddForm.moyens-de-paiements")} />
                <div onClick={handleToggleEdit}>{renderStatus()}</div>
            </div>

            {!isEditing ? (
                <div className='operation-details-content drawer-content-section-body'>
                    {selectedBeneficiary &&
                        selectedBeneficiary?.externalAccounts.map((externalAccount: ExternalAccountForm, index) => {
                            return (
                                <div
                                    key={index}
                                    className={classNames("account-item", {
                                        error: checkExternalAccountError(externalAccount),
                                    })}
                                >
                                    {transactionTypesMap[externalAccount.typeSlug]?.type === "image" ? (
                                        <Avatar
                                            key={externalAccount.uuid}
                                            avatar={transactionTypesMap[externalAccount.typeSlug].img}
                                        />
                                    ) : (
                                        <Icon
                                            variant='squared'
                                            color='lilas'
                                            name={transactionTypesMap[externalAccount.typeSlug]?.img as IconListType}
                                        />
                                    )}
                                    <div className='account-item-info'>
                                        <Typography
                                            message={renderBeneficiaryExternalAccountsName(externalAccount.typeSlug)}
                                        />
                                        <Typography message={externalAccount.externalReference} />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            ) : (
                <div className='beneficiary-informations-content operation-details-content drawer-content-section-body'>
                    {!externalAccounts.length && (
                        <div className='no-account-added'>
                            <Typography
                                className='fw-bold'
                                message={t("CreateBeneficiaryAddForm.aucun-compte-enregistre")}
                            />
                            <Typography
                                message={t(
                                    "CreateBeneficiaryAddForm.renseignez-des-comptes-pour-payer-plus-rapidement-un-beneficiaire"
                                )}
                            />
                        </div>
                    )}
                    {!!externalAccounts.length && (
                        <div className='account-list'>
                            {externalAccounts.map((account, index) =>
                                index !== editingAccountIndex ? (
                                    <div
                                        className={classNames(
                                            "account-item",
                                            { error: checkExternalAccountError(account) },
                                            isEditing
                                        )}
                                        key={index}
                                    >
                                        <div className='account-item-content'>
                                            {transactionTypesMap[account.typeSlug].type === "image" ? (
                                                <Avatar
                                                    key={account.uuid}
                                                    avatar={transactionTypesMap[account.typeSlug].img}
                                                />
                                            ) : (
                                                <Icon
                                                    variant='squared'
                                                    color='lilas'
                                                    name={transactionTypesMap[account.typeSlug].img as IconListType}
                                                />
                                            )}
                                            <div className='text-part'>
                                                <Typography
                                                    className='fw-bold'
                                                    message={renderBeneficiaryExternalAccountsName(account.typeSlug)}
                                                />
                                                <Typography message={account.externalReference} />
                                            </div>
                                        </div>
                                        <div className='account-item-actions'>
                                            <Button
                                                variant='tertiary'
                                                icon='pencil-square'
                                                onClick={handleEditAccount(index)}
                                            />
                                            <Button
                                                variant='tertiary'
                                                icon='thrash'
                                                onClick={handleDeleteAccount(index)}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='add-account-form'>
                                        <div className='form-part'>
                                            <SimpleSelect
                                                useRadio
                                                label={`${t("Transactions.service")}`}
                                                headerClassname='beneficiary-service-select'
                                                image={selectedTypeSlugOption?.image}
                                                icon={selectedTypeSlugOption?.icon?.name}
                                                iconProps={selectedTypeSlugOption?.icon}
                                                selected={selectedTypeSlugOption}
                                                onSelectionChange={handleChangeTypeSlug}
                                                options={beneficiaryExternalAccounts}
                                                placeholder={t<string>("CreateBeneficiaryAddForm.choix-du-service")}
                                            />
                                            <Input
                                                label={
                                                    isPhone
                                                        ? t("CommonUse.phone-number").toString()
                                                        : t("CommonUse.reference").toString()
                                                }
                                                placeholder={
                                                    isPhone
                                                        ? t("Users.enter-phone-number").toString()
                                                        : "Ex : CIXX XXXX XXXX XXXX"
                                                }
                                                onChange={handleChangeExternalReference}
                                                value={externalReference}
                                                allowedChar={isPhone ? /^[0-9 ]*$/ : /^[a-zA-Z0-9 ]*$/}
                                            />
                                        </div>
                                        <div className='buttons-part'>
                                            <Button
                                                label={t("CommonUse.cancel")}
                                                variant='tertiary'
                                                className='flex-grow-1'
                                                onClick={handleCancelForm}
                                            />
                                            <Button
                                                className='flex-grow-1'
                                                icon={editingAccountIndex !== null ? "pencil" : "plus-circle"}
                                                label={
                                                    editingAccountIndex !== null
                                                        ? t("CommonUse.modify")
                                                        : t("CreateBeneficiaryAddForm.ajouter-un-compte")
                                                }
                                                disabled={selectedTypeSlugOption === null || externalReference === ""}
                                                onClick={handleAddAccount}
                                            />
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    )}
                    {showAddAccountForm && editingAccountIndex === null && (
                        <div className='add-account-form'>
                            <div className='form-part'>
                                <SimpleSelect
                                    label={`${t("Transactions.service")}`}
                                    useRadio
                                    headerClassname='beneficiary-service-select'
                                    image={selectedTypeSlugOption?.image}
                                    icon={selectedTypeSlugOption?.icon?.name}
                                    iconProps={selectedTypeSlugOption?.icon}
                                    selected={selectedTypeSlugOption}
                                    onSelectionChange={handleChangeTypeSlug}
                                    options={beneficiaryExternalAccounts}
                                    placeholder={t<string>("CreateBeneficiaryAddForm.choix-du-service")}
                                />
                                <Input
                                    label={
                                        isPhone
                                            ? t("CommonUse.phone-number").toString()
                                            : t("CommonUse.reference").toString()
                                    }
                                    placeholder={
                                        isPhone ? t("Users.enter-phone-number").toString() : "Ex : CIXX XXXX XXXX XXXX"
                                    }
                                    onChange={handleChangeExternalReference}
                                    value={externalReference}
                                    allowedChar={isPhone ? /^[0-9 ]*$/ : /^[a-zA-Z0-9 ]*$/}
                                />
                            </div>
                            <div className='buttons-part'>
                                <Button
                                    label={t("CommonUse.cancel")}
                                    variant='tertiary'
                                    className='flex-grow-1'
                                    onClick={handleCancelForm}
                                />
                                <Button
                                    className='flex-grow-1'
                                    label={t("CreateBeneficiaryAddForm.ajouter-un-compte")}
                                    icon='plus-circle'
                                    disabled={selectedTypeSlugOption === null || externalReference === ""}
                                    onClick={handleAddAccount}
                                />
                            </div>
                        </div>
                    )}
                    {!showAddAccountForm && (
                        <div className='add-account-button'>
                            <Button
                                icon='plus-circle'
                                label={t("CreateBeneficiaryAddForm.ajouter-un-compte")}
                                onClick={handleOpenPaymentMethodForm}
                            />
                        </div>
                    )}
                </div>
            )}

            {isEditing && (
                <>
                    <div className='button-part'>
                        <Button
                            variant='tertiary'
                            className='flex-grow-1'
                            label={t("CommonUse.cancel")}
                            onClick={handleToggleEdit}
                        />
                        <Button
                            variant='primary'
                            className='flex-grow-1'
                            label={!integrateBeneficiaryLoading ? t("CommonUse.validate") : t("CommonUse.sending")}
                            loading={integrateBeneficiaryLoading}
                            disabled={!isFormValidated || integrateBeneficiaryLoading}
                            onClick={validateForms}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
