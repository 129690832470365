import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminUsersPanel } from "src/components/Admin/Users/Panel";

const AdminUsersListPageWithoutAuthorizations = () => {
    return <AdminUsersPanel />;
};

export const AdminUsersListPage = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminUsersListPageWithoutAuthorizations
);
