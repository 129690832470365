import { BillingBiller } from "src/services/billing/biller/type";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export type BillersCategoryFilter = "direct-billers" | "favorite-billers" | null;

interface BillersState {
    isLoading: boolean;
    data?: BillingBiller[];
    filters: { query?: string; sector?: SelectOption[]; category: BillersCategoryFilter };
    currentPage: number;
    pageSize: number;
    totalCount: number;
}

const initialState: BillersState = {
    isLoading: false,
    totalCount: 20,
    currentPage: 1,
    pageSize: 20,
    filters: {
        category: null,
    },
};

export const billersSlice = createSlice({
    name: "billersSlice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestBillingBillers: (state) => {
            state.isLoading = true;
        },
        receiveBillingBillers: (
            state,
            { payload }: PayloadAction<{ data: BillingBiller[]; totalCount: number; currentPage: number }>
        ) => {
            const { data, totalCount = 20, currentPage } = payload;

            state.isLoading = false;
            state.totalCount = totalCount;
            state.data = data;
            state.currentPage = currentPage;
        },
        failedBillingBillers: (state) => {
            state.isLoading = false;
        },
        setBillingBillerQueryFilter: (state, { payload }: PayloadAction<{ query: string }>) => {
            state.filters.query = payload.query;
            state.currentPage = 1;
        },
        setBillingBillerPageSize: (state, { payload }: PayloadAction<{ pageSize: number }>) => {
            const { pageSize } = payload;

            return {
                ...state,
                pageSize,
                currentPage: 1,
            };
        },
        setBillingBillerCurrentPage: (state, { payload }: PayloadAction<{ currentPage: number }>) => {
            const { currentPage } = payload;

            return {
                ...state,
                currentPage,
            };
        },
        setBillingBillerSectorFilter: (state, { payload }: PayloadAction<BillersState["filters"]["sector"]>) => {
            if (payload && payload.length > 0) {
                state.filters.sector = payload;
            } else if (state.filters.sector) {
                delete state.filters.sector;
            }
        },
        resetBillingBillerSectorFilters: (state) => {
            delete state.filters.sector;
            state.filters.category = null;
        },
        resetBillingBillerFilters: (state) => {
            state.filters = {
                category: null,
            };
        },
        handleSetBillerCategoryFilter: (state, { payload }: PayloadAction<BillersState["filters"]["category"]>) => {
            state.filters.category = payload;
        },
    },
});

export const {
    setBillingBillerPageSize,
    setBillingBillerCurrentPage,
    requestBillingBillers,
    receiveBillingBillers,
    failedBillingBillers,
    setBillingBillerSectorFilter,
    setBillingBillerQueryFilter,
    resetBillingBillerFilters,
    resetBillingBillerSectorFilters,
    handleSetBillerCategoryFilter,
} = billersSlice.actions;

export const billingBillersSliceReducer = billersSlice.reducer;
