import {
    ClassAttributes,
    ComponentClass,
    Fragment,
    FunctionComponent,
    InputHTMLAttributes,
    ReactNode,
    createElement,
} from "react";

export type VariantTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "p";
export type TypographySizeType = "xs" | "sm" | "md" | "lg";

export interface TypographyProps {
    variant?: VariantTypes;
    size?: TypographySizeType;
    message: ReactNode | string;
    className?: string;
    withPlaceholder?: boolean;
    testId?: string;
}

type VariantMap = {
    [key in VariantTypes]: {
        type: FunctionComponent | ComponentClass | string;
        className?: string;
    };
};

const variantMap: VariantMap = {
    h1: {
        type: "h1",
    },
    h2: {
        type: "h2",
    },
    h3: {
        type: "h3",
    },
    h4: {
        type: "h4",
    },
    h5: {
        type: "h5",
    },
    h6: {
        type: "h6",
    },
    span: {
        type: "span",
    },
    p: {
        type: "p",
    },
};

export const Typography = ({
    variant = "span",
    message,
    className,
    size = "sm",
    withPlaceholder = false,
    testId,
    ...props
}: TypographyProps) => {
    const _v = variant && (variantMap[variant].type || Fragment);
    const _c = { className: `${className ? className : ""} ${size} ${variantMap[variant].className || ""}` } || {};
    return createElement(
        _v,
        {
            ...props,
            ..._c,
            "data-testid": testId,
        } as InputHTMLAttributes<HTMLInputElement> & ClassAttributes<HTMLInputElement>,
        (!message || message === "") && withPlaceholder ? "-" : message
    );
};
