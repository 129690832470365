import classNames from "classnames";

import { ReactNode, useEffect, useState } from "react";

import { Icon, IconVariant } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "../styles.scss";

type SwitchAccordionType = {
    header: {
        title: string;
        icon?: IconListType;
        disabled?: boolean;
        iconType?: IconVariant;
        toggleBy?: "switch" | "arrow";
    };
    content: ReactNode;
};

type SwitchAccordionProps = {
    items: Array<SwitchAccordionType>;
    preSelectedOpen?: number[];
    itemsOpen?: number[];
    onOpenItems?: (indexes: number[]) => void;
    showOverflow?: boolean;
};

export const SwitchAccordion = ({
    items,
    preSelectedOpen,
    itemsOpen,
    onOpenItems,
    showOverflow = false,
}: SwitchAccordionProps) => {
    const [shownItems, setShownItems] = useState<number[]>(preSelectedOpen || []);

    useEffect(() => {
        if (itemsOpen) {
            setShownItems(itemsOpen);
        }
    }, [itemsOpen]);

    const handleShownItem = (index: number) => {
        if (shownItems.includes(index)) {
            const items = shownItems.filter((item) => item !== index);
            setShownItems(items);
            onOpenItems && onOpenItems(items);
        } else {
            const items = [...shownItems, index];
            setShownItems(items);
            onOpenItems && onOpenItems(items);
        }
    };

    return (
        <div className='accordion-container'>
            {items &&
                items.map((item: SwitchAccordionType, index: number) => {
                    const toggleBy = item.header.toggleBy || "arrow";
                    return (
                        <div
                            className={classNames("accordion ", {
                                "overflow-initial": showOverflow && shownItems.length,
                            })}
                            key={index}
                        >
                            <div
                                className={`accordion-header ${item.header.disabled ? "disabled" : ""} ${
                                    shownItems.includes(index) ? "show" : "hide"
                                } `}
                                data-testid={`accordion-header-${index}`}
                                onClick={() => toggleBy === "arrow" && handleShownItem(index)}
                            >
                                <div className='d-flex align-items-center'>
                                    {toggleBy === "switch" && (
                                        <Switch
                                            className='mr-2'
                                            label=''
                                            isOn={shownItems.includes(index)}
                                            onToggle={() => handleShownItem(index)}
                                        />
                                    )}
                                    {item.header.icon && (
                                        <Icon name={item.header.icon} variant={item.header.iconType} />
                                    )}
                                    <Typography
                                        className={item.header.icon ? "mx-2" : ""}
                                        message={item.header.title}
                                    />
                                </div>
                                {toggleBy === "arrow" && (
                                    <Icon className={shownItems.includes(index) ? "show" : ""} name={"chevron-up"} />
                                )}
                            </div>
                            {!item.header.disabled && (
                                <div
                                    className={classNames("accordion-content", {
                                        show: shownItems.includes(index),
                                        hide: !shownItems.includes(index),
                                        "overflow-initial": showOverflow && shownItems.length,
                                    })}
                                >
                                    <div className='content-padding'>{item.content}</div>
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};
