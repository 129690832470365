import { TransfertDrawerBody } from "./Body";
import { DrawerContext } from "src/wrapper/context";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

import "./styles.scss";

export function TransfertDrawer() {
    const { t } = useTranslation();
    const { open, setOpen } = useContext(DrawerContext);

    const handleCloseDrawer = () => {
        setOpen(undefined);
    };

    return (
        <Drawer
            isOpen={open === "transfer"}
            body={<TransfertDrawerBody />}
            header={{ title: t("TransferDrawer.choose-a-method"), image: "arrows-right-left" }}
            onClose={handleCloseDrawer}
        />
    );
}
