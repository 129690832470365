import { ExternalAccountForm } from "../utils";
import { CreateBeneficiaryAddForm } from "./AddBeneficiaryForm";
import { CreateBeneficiaryInformationForm, InformationsParams } from "./InformationsForm";
import { BeneficiaryModel } from "src/shared/models/Beneficiary";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { handleCreateAdminBeneficiary } from "src/modules/admin/adminBeneficiaries/thunkActions";
import { useAppDispatch } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

import "./styles.scss";

export type CreateBeneficiaryPayload = InformationsParams & { externalAccounts: ExternalAccountForm[] };

type CreateBeneficiaryDrawerBodyProps = {
    isOpen: boolean;
    handleCloseDrawer: (refresh?: boolean, beneficiary?: BeneficiaryModel) => void;
    selectedBeneficiary: BeneficiaryModel | null;
    isBulk?: boolean;
};

export const CreateBeneficiaryDrawerContainer = ({
    isOpen,
    handleCloseDrawer,
    selectedBeneficiary,
    isBulk = false,
}: CreateBeneficiaryDrawerBodyProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [step, setStep] = useState<number>(0);

    const [informations, setInformations] = useState<InformationsParams | null>(null);
    const [externalAccounts, setExternalAccounts] = useState<ExternalAccountForm[]>([]);
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [isExternalAccountValidated, setIsExternalAccountValidated] = useState(false);

    useEffect(() => {
        setExternalAccounts([]);
    }, [isOpen]);

    const validateForms = () => {
        if (!informations) return;

        const payload: CreateBeneficiaryPayload = {
            email: informations.email,
            firstname: informations.firstname,
            lastname: informations.lastname,
            label: informations.label,
            externalAccounts,
        };
        if (isBulk) {
            handleCloseDrawer(false, payload as BeneficiaryModel);
            return;
        }
        dispatch(handleCreateAdminBeneficiary(payload));
    };

    useEffect(() => {
        if (!isExternalAccountValidated && !isFormValidated) {
            setStep(0);
        } else if (isFormValidated) {
            setStep(1);
        }
    }, [isFormValidated, isExternalAccountValidated]);

    const handleCancel = () => {
        handleCloseDrawer();
        setExternalAccounts([]);
    };

    return (
        <Drawer
            isOpen={isOpen}
            onClose={handleCancel}
            header={{
                title: `${t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") })}`,
                image: "user-plus",
            }}
            body={
                <div className='create-beneficiary-drawer-body-container'>
                    <div className='forms-container'>
                        <CreateBeneficiaryInformationForm
                            isFormValidated={isFormValidated}
                            setInformations={setInformations}
                            setIsFormValidated={setIsFormValidated}
                            editInformations={selectedBeneficiary}
                        />
                        <CreateBeneficiaryAddForm
                            disabled={step === 0}
                            setIsFormValidated={setIsExternalAccountValidated}
                            isFormValidated={isExternalAccountValidated}
                            setExternalAccounts={setExternalAccounts}
                            externalAccounts={
                                selectedBeneficiary ? selectedBeneficiary.externalAccounts : externalAccounts
                            }
                        />
                    </div>
                    <div className='button-send-beneficiary'></div>
                </div>
            }
            footer={{
                primaryButton: {
                    label: t("CommonUse.validate"),
                    disabled: !isFormValidated || !isExternalAccountValidated,
                    onClick: validateForms,
                },
                secondaryButton: {
                    label: t("CommonUse.cancel"),
                    onClick: handleCancel,
                },
                optionalText:
                    step > 0
                        ? `${t("CreateBeneficiaryAddForm.means-of-payment")}`
                        : `${t("CreateBeneficiaryAddForm.beneficiary-information")}`,
            }}
        />
    );
};
