import classNames from "classnames";
import { WalletBeneficiary, WalletBeneficiarySection } from "src/services/wallets/types";
import { operationTypes } from "src/shared/const/transactions";
import { getCountryFlag, getCountryLabel } from "src/shared/utils/getCountryInformations";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getWalletBeneficiariesByCategory,
    getWalletBeneficiariesIsLoading,
} from "src/modules/walletsBeneficiaries/selector";
import { fetchAuthorizedBeneficiaryWallet } from "src/modules/walletsBeneficiaries/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { Alert } from "src/shared/atoms/Alert/Alert";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { List } from "src/shared/components/ExpandableList/ExpandableList";

import "./styles.scss";

const emptyListImg = require("src/shared/images/empty/empty-user.svg").default;

const categoryTitle: { [key in WalletBeneficiarySection]: string } = {
    history: "BeneficiaryDrawerBody.mes-transactions-recentes",
    wallet: "Beneficiaries.my-accounts",
    beneficiary: "Beneficiaries.my-beneficiaries",
    "external-ref": "",
};

const defaultBeneficiary: { [key: string]: [] } = {
    beneficiary: [],
};

type Props = {
    walletId: string | number;
    handleSelectBeneficiary: (beneficiary: WalletBeneficiary) => void;
    selectOnlyBeneficiary?: boolean;
};

export function BeneficiaryDrawerBody({ walletId, selectOnlyBeneficiary, handleSelectBeneficiary }: Props) {
    const dispatch = useAppDispatch();
    const [query, setQuery] = useState("");
    const { t } = useTranslation();

    const isLoading = useAppSelector((state) => getWalletBeneficiariesIsLoading(state));
    const beneficiaries = useAppSelector((state) =>
        getWalletBeneficiariesByCategory(state, {
            walletId,
        })
    );

    const deboucedQuery = useDebounce(query, 700);

    const isWalletRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_WALLET);

    useEffect(() => {
        if (walletId) {
            dispatch(fetchAuthorizedBeneficiaryWallet({ walletId, query: deboucedQuery, way: "out" }));
        }
    }, [walletId, deboucedQuery, dispatch]);

    const handleSetQueryFilter = (newQuery: string) => {
        setQuery(newQuery);
    };

    const handleOnClickBeneficiary = (beneficiary: WalletBeneficiary) => () => {
        handleSelectBeneficiary(beneficiary);
    };

    return (
        <div className='beneficiary-drawer-body-container'>
            <div className='beneficiary-drawer-searchbar-container'>
                <Input
                    placeholder={t("Beneficiaries.search")}
                    inputIcon='magnifying-glass'
                    onChange={handleSetQueryFilter}
                    value={query}
                    className='search-input'
                />
            </div>

            <div className='beneficiaries-categories-container'>
                {Object.entries(isLoading ? defaultBeneficiary : beneficiaries ?? {}).map(([category, data]) => (
                    <div className='beneficiaries-list-container'>
                        {!isLoading && (
                            <Typography
                                className='fw-bold'
                                message={t(categoryTitle[category as WalletBeneficiarySection] ?? "")}
                            />
                        )}
                        <List
                            isLoading={isLoading}
                            extended
                            loadingRowsToshow={8}
                            animation='fadeInUp'
                            keyName='beneficiary-account'
                            items={data.map((beneficiary, index) => {
                                const operationType = operationTypes?.[beneficiary.typeSlug];
                                const countryName = getCountryLabel(beneficiary.country.slug);

                                let isDisabled = false;

                                if (
                                    selectOnlyBeneficiary &&
                                    !beneficiary.serviceSelected.isBeneficiary &&
                                    !["beneficiary", "wallet"].includes(category)
                                ) {
                                    isDisabled = true;
                                } else if (
                                    isWalletRestricted &&
                                    !beneficiary.serviceSelected.isDefaultWallet &&
                                    ["transfer"].includes(beneficiary.typeSlug)
                                ) {
                                    isDisabled = true;
                                }

                                return (
                                    <>
                                        <div
                                            onClick={isDisabled ? undefined : handleOnClickBeneficiary(beneficiary)}
                                            id='topup_bank-transfer_option_bank'
                                            className={classNames("beneficiaries-item", { disabled: isDisabled })}
                                            key={`account-${beneficiary.serviceSelected.externalReference}`}
                                            data-testid={"drawer-beneficiary-item-" + category + "-" + index}
                                        >
                                            <div className='left-content'>
                                                <TransactionServiceImage
                                                    serviceSlug={beneficiary.serviceSelected.serviceSlug}
                                                />
                                                <div className='list-item-title'>
                                                    <Typography
                                                        className='fw-bold'
                                                        message={
                                                            beneficiary.serviceSelected.externalFullname
                                                                ? beneficiary.serviceSelected.externalFullname
                                                                : beneficiary.serviceSelected.serviceTitle
                                                        }
                                                    />
                                                    <Typography
                                                        variant='span'
                                                        message={beneficiary.serviceSelected.externalReference}
                                                    />
                                                </div>
                                            </div>
                                            <div className='right-content'>
                                                <div className='list-item-content'>
                                                    <div className='list-item-content-right'>
                                                        <img
                                                            src={getCountryFlag(beneficiary.country.slug)}
                                                            alt={beneficiary.country.slug + "-flag"}
                                                            style={{ width: 15 }}
                                                        />
                                                        <Typography variant='span' message={t(countryName)} />
                                                    </div>
                                                    <div className='list-item-content-right'>
                                                        <Icon color='lilas-900' size={"sm"} name={operationType.icon} />
                                                        <Typography variant='span' message={operationType.title} />
                                                    </div>
                                                </div>
                                                <Icon name='chevron-right' />
                                            </div>
                                        </div>
                                        {beneficiary?.warningMessage ? (
                                            <div className='mt-2'>
                                                <Alert
                                                    color='warning'
                                                    icon='exclamation-triangle-filled'
                                                    message={t(beneficiary.warningMessage)}
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                );
                            })}
                        />
                    </div>
                ))}
                {!isLoading && (!beneficiaries || Object.keys(beneficiaries).length === 0) && (
                    <Empty
                        image={emptyListImg}
                        title={t("Table.no-result-found")}
                        description={t<string>("Table.no-result-found-description")}
                    />
                )}
            </div>
        </div>
    );
}
