import { failedRequestWalletBeneficiaries, receiveWalletBeneficiaries, requestWalletBeneficiaries } from "./slice";
import { requestWalletsBeneficiaries } from "src/services/wallets/operations";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAuthorizedBeneficiaryWallet = createAsyncThunk(
    "fetchAuthorizedWalletsAuthUser",
    async ({ walletId, query, way, typeSlug, serviceSlug }: { walletId: number | string; query?: string; way: string; typeSlug?: string; serviceSlug?: string }, { dispatch }) => {
        dispatch(requestWalletBeneficiaries());
        try {
            const walletBeneficiaries = await requestWalletsBeneficiaries({
                walletId,
                query,
                typeSlug,
                serviceSlug,
                way
            });
            dispatch(receiveWalletBeneficiaries({ walletBeneficiaries: walletBeneficiaries, walletId }));
        } catch (error) {
            dispatch(failedRequestWalletBeneficiaries());
            throw error;
        }
    }
);
