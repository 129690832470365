import classNames from "classnames";
import { BillingBillerUpdatePayload } from "src/services/billing/enterprise/type";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getBillingEnterprise } from "src/modules/billing/enterprise/selectors";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Switch } from "src/shared/atoms/Switch/Switch";
import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    edit: "details" | "invoiceReceipt" | null;
    handleSetPayload: (props: BillingBillerUpdatePayload) => () => void;
};

export function BillingGetPaidDrawerSectionDisable({ edit, handleSetPayload }: Readonly<Props>) {
    const { t } = useTranslation();

    const enterpriseData = useAppSelector((state) => getBillingEnterprise(state));

    const editDisabled = edit !== null;

    const payload = useMemo(() => ({ isPublic: !enterpriseData?.isPublic } as const), [enterpriseData?.isPublic]);

    return (
        <div
            className={classNames("drawer-content-section get-paid-drawer-section", {
                disabled: editDisabled,
            })}
        >
            <div className='header'>
                <Typography
                    className='fw-bold'
                    message={t(
                        enterpriseData?.isPublic ? "Bills.active-biller-profile" : "Bills.disabled-biller-profile"
                    )}
                />
                <Switch disabled={editDisabled} isOn={enterpriseData?.isPublic} onToggle={handleSetPayload(payload)} />
            </div>
            <div className='drawer-divider' />

            <Alert
                color='warning'
                message={t("Bills.disable-biller-profile-alert-title")}
                subMessage={t<string>("Bills.disable-biller-profile-alert-description")}
            />
        </div>
    );
}
