import { getIconName } from "./const";
import clsx from "classnames";
import { NotificationModel } from "src/shared/models/Notification";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { archiveNotification } from "src/modules/notifications/thunkActions";
import { useAppDispatch } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Typography } from "src/shared/atoms/Typography/Typography";

export type NotificationCardProps = {
    notification: NotificationModel;
};

export const NotificationCard = ({ notification }: NotificationCardProps) => {
    const dispatch = useAppDispatch();

    const archive = () => {
        dispatch(archiveNotification({ id: notification.uuid }));
    };

    return (
        <div className={clsx("notification-card", { new: !notification.read })}>
            <Avatar
                icon={getIconName(notification.NotificationType.slug)}
                backgroundColor={(notification.level === "info" ? "warning" : notification.level) + "-50"}
                color={(notification.level === "info" ? "warning" : notification.level) + "-500"}
            />

            <div className='notification-card-content'>
                <div className='notification-card-content-text'>
                    <Typography className='fw-bold' message={notification.title} />
                    <Typography message={notification.subtitle} />
                    <Typography
                        className='color-lilas-900 mt-4'
                        message={computeTimestampToString(notification.createdAt, "DD/MM/YYYY - HH:mm")}
                    />
                </div>
                {!notification.archivedAt && <Button icon='x-mark-filled' variant='secondary' onClick={archive} />}
            </div>
        </div>
    );
};
