import { ChangeEvent, ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Alert, AlertProps } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Input } from "src/shared/atoms/Inputs/Input";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export type HandleSubmitPropsPasswordValidation = { password: string; twoFactor: string | null };

export type PasswordValidationProps = {
    open: boolean;
    setOpen: () => void;
    handleOnSubmit: ({ password, twoFactor }: HandleSubmitPropsPasswordValidation) => Promise<any>;
    onSuccess?: () => void;
    error?: string | null;
    twoFactorOn?: true;
    header?: { title: string; icon?: IconListType };
    successContent?: { title?: string; image?: string; children?: ReactNode; text?: string };
    successCustomButton?: ReactElement;
    successButtonLabel?: string;
    successComponent?: ReactElement;
    successButtonId?: string;
    asyncFromParent?: boolean;
    succeeded?: boolean;
    successSize?: "sm" | "md";
    alert?: AlertProps;
    validationWord?: string;
};

export const PasswordValidationModal = ({
    header,
    open,
    setOpen,
    handleOnSubmit,
    onSuccess,
    successContent,
    successComponent,
    successButtonLabel,
    successCustomButton,
    asyncFromParent,
    twoFactorOn,
    succeeded,
    successButtonId,
    error,
    successSize = "sm",
    alert,
    validationWord,
}: PasswordValidationProps) => {
    const { t } = useTranslation();

    const [password, setPassword] = useState("");
    const [twoFactor, setTwoFactor] = useState("");
    const [validationWordValue, setValidationWordValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errors, setErrors] = useState<{ password?: string; twoFactor?: string; request?: string }>({});

    const confirmButtonRef = useRef<HTMLButtonElement>(null);

    const twoFactorActive = useAppSelector((state) => twoFactorOn ?? getAuthUser(state)?.tfaActive);

    const [isFormValidated, setIsFormValidated] = useState(false);

    useEffect(() => {
        setPassword("");
        setTwoFactor("");
        setValidationWordValue("");
        setLoading(false);
        setShowSuccess(false);
        setErrors({});
    }, [open]);

    useEffect(() => {
        if (confirmButtonRef.current) {
            confirmButtonRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [open]);

    useEffect(() => {
        const isTwoFactorValid = twoFactorActive ? !!twoFactor : true;
        const isPasswordValid = !!password;
        const isValidationWordFilled = validationWord ? !!validationWordValue : true;
        const isValidationWordValid = validationWord ? validationWordValue === validationWord : true;

        setIsFormValidated(isTwoFactorValid && isPasswordValid && isValidationWordFilled && isValidationWordValid);
    }, [password, twoFactor, twoFactorActive, validationWord, validationWordValue]);

    useEffect(() => {
        if (succeeded) {
            setShowSuccess(true);
            setErrors({});
        } else {
            setShowSuccess(false);
        }

        if (error) {
            setErrors({ password: error });
        } else if (asyncFromParent) {
            setErrors({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [succeeded, error]);

    const handleSubmitForm = async () => {
        if (loading) {
            return;
        }
        const checkedErrors = checkFormErrors(twoFactorActive, password, twoFactor);

        if (checkedErrors) {
            setErrors(checkedErrors);
        } else {
            setErrors({});
            setLoading(true);
            handleOnSubmit({ password, twoFactor })
                .then(() => {
                    if (!successContent && !successComponent && open) {
                        setOpen();
                    } else {
                        if (!asyncFromParent) {
                            setShowSuccess(true);
                        }
                    }
                })
                .catch((error) => {
                    if (error.message === "Api-errors.user-2fa-code-invalid") {
                        setErrors({ twoFactor: error.message });
                    } else {
                        if (!asyncFromParent && error?.message) {
                            setErrors({ password: error.message });
                        }
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleOnSuccess = () => {
        if (onSuccess) {
            onSuccess();
        }
        setOpen();
    };

    const handleChangePassword = (newPassword: string) => {
        setPassword(newPassword);
    };

    const handleChangeTwoFactor = (newTwoFactor: string) => {
        setTwoFactor(newTwoFactor);
    };

    const handleAutocompleteOff = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    const handleGlobalKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                handleSubmitForm();
            }
        },
        [handleSubmitForm]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleGlobalKeyPress);

        return () => document.removeEventListener("keydown", handleGlobalKeyPress);
    }, [handleGlobalKeyPress, handleSubmitForm]);

    const checkFormErrors = (twoFactorActive?: boolean, password?: string, twoFactor?: string) => {
        const newErrors: { password?: string; twoFactor?: string } = {};

        if (password?.trim().length === 0) {
            newErrors.password = t<string>("AdminAccountsFormsProfile.champ-obligatoire");
        }
        if (twoFactorActive && twoFactor?.trim().length === 0) {
            newErrors.twoFactor = t<string>("AdminAccountsFormsProfile.champ-obligatoire");
        }

        if (Object.keys(newErrors).length !== 0) {
            return newErrors;
        }
        return;
    };

    const handleChangeValidationWord = (newValidationWord: string) => {
        setValidationWordValue(newValidationWord);
    };

    return (
        <div className='modal-container'>
            {open && (
                <div className='modal'>
                    <div className={`modal-content ${showSuccess ? successSize : "sm"}`}>
                        {header && (
                            <div className='modal-header'>
                                <div className='modal-header-content'>
                                    {header?.icon && <Avatar icon={header.icon} />}
                                    <Typography className='fw-bold' message={header?.title} />
                                </div>
                            </div>
                        )}

                        <div
                            className={`password-validation-container ${Object.keys(errors).length ? "errored" : ""} `}
                        >
                            {showSuccess ? (
                                <>
                                    {successContent && (
                                        <div className='success-content'>
                                            {successContent.title && (
                                                <Typography className='fw-bold' message={successContent.title} />
                                            )}
                                            {successContent.image && (
                                                <div className='d-flex justify-content-center'>
                                                    <img src={successContent.image} alt='SUCCESS' />
                                                </div>
                                            )}
                                            {successContent.children ? successContent.children : null}
                                            <Typography className='fw-bold' message={successContent.text} />
                                        </div>
                                    )}

                                    {successComponent ? successComponent : null}
                                </>
                            ) : (
                                <>
                                    {alert && <Alert {...alert} classname='mb-2' />}
                                    <div className='password-validation-text'>
                                        <Icon name='lock' size='sm' color='lilas-900' />
                                        <Typography message={t("CommonUse.enter-password")} />
                                    </div>
                                    <div className='form-container'>
                                        {validationWord && (
                                            <Input
                                                autoFocus
                                                label={t<string>("PasswordModal.validation-word-sentence", {
                                                    entity: validationWord,
                                                })}
                                                id='validation-word'
                                                name='validation-word'
                                                placeholder={t("PasswordModal.validation-placeholder", {
                                                    entity: validationWord,
                                                })}
                                                value={validationWordValue ?? ""}
                                                onChange={handleChangeValidationWord}
                                            />
                                        )}
                                        <form autoComplete='current-password' onSubmit={handleAutocompleteOff}>
                                            <Input
                                                autoFocus
                                                label={t<string>("LoginPanel.mot-de-passe")}
                                                id='password'
                                                type='password'
                                                name='password'
                                                placeholder={t("PasswordModal.enter-password")}
                                                value={password ?? ""}
                                                variant={!!errors.password ? "error" : "primary"}
                                                underMessage={errors.password ? t<string>(errors.password) : undefined}
                                                onChange={handleChangePassword}
                                                testId='modal-password-input'
                                            />
                                        </form>
                                        {twoFactorActive && (
                                            <Input
                                                label={t<string>("PasswordModal.auth-code")}
                                                id='otp'
                                                name='otp'
                                                placeholder={t("PasswordModal.add-temporary-code")}
                                                value={twoFactor}
                                                variant={!!errors.twoFactor ? "error" : "primary"}
                                                onChange={handleChangeTwoFactor}
                                                underMessage={
                                                    errors.twoFactor ? t<string>(errors.twoFactor) : undefined
                                                }
                                                testId='modal-otp-input'
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>

                        <div className='modal-footer'>
                            {showSuccess ? (
                                <>
                                    {successCustomButton ?? null}
                                    <Button
                                        className='w-100'
                                        label={t(successButtonLabel ?? "CommonUse.continue")}
                                        onClick={handleOnSuccess}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        className='w-100'
                                        label={t("CommonUse.cancel")}
                                        variant='tertiary'
                                        id={successButtonId}
                                        onClick={setOpen}
                                        disabled={loading}
                                    />
                                    <Button
                                        className='w-100'
                                        label={t("CommonUse.confirm")}
                                        loading={loading}
                                        onClick={handleSubmitForm}
                                        id='password-modal_button_confirm'
                                        disabled={!isFormValidated || loading}
                                        ref={confirmButtonRef}
                                        testId='modal-password-button-confirm'
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
