import {
    BillingTransactionsStateFilters,
    deleteBillingTransactionReceipts,
    failedRequestBillingTransactions,
    receiveBillingTransactions,
    requestBillingTransactions,
    setBillingTransactionAttachment,
    setBillingTransactionReceipts,
} from "./slice";
import {
    DeleteFileToTransactionProps,
    RefundtransactionsProps,
    deleteFileToTransaction,
    refundTransactions,
    requestGetBillingTransactions,
    updateTransactionAttachmentOrReceipts,
} from "src/services/transactions/operations";
import { transactionStatusArchived } from "src/shared/const/transactions";
import { formatDateForRequest } from "src/shared/utils/formatDate";

import { createAsyncThunk } from "@reduxjs/toolkit";

const computeFilterToBillingFiltersApi = (filters: BillingTransactionsStateFilters, page: number, pageSize: number) => {
    const { query, startDate, endDate, archived, status } = filters;

    const statusFilter = status?.map((filter) => filter.id) ?? [];

    if (archived) {
        statusFilter?.push(transactionStatusArchived);
    }

    return {
        query: query === "" ? undefined : query,
        minDate: formatDateForRequest(startDate),
        maxDate: formatDateForRequest(endDate),
        page,
        pageSize,
        status: statusFilter,
    };
};

export const fetchBillingTransactions = createAsyncThunk(
    "fetchBillingTransactions",
    async (
        { filters, page, pageSize }: { filters: BillingTransactionsStateFilters; page: number; pageSize?: number },
        { dispatch }
    ) => {
        dispatch(requestBillingTransactions());
        try {
            const transactions = await requestGetBillingTransactions(
                computeFilterToBillingFiltersApi(filters, page, pageSize ?? 20)
            );
            dispatch(receiveBillingTransactions({ transactions }));
            return transactions;
        } catch (error) {
            dispatch(failedRequestBillingTransactions());
            return Promise.reject(error);
        }
    }
);

type HandleUpdateBillingTransactionAttachmentProps = {
    type: string;
    file: File;
    transactionUuid: string;
    uploader: string | null;
    walletId: string;
};

export const handleUpdateBillingTransactionAttachment = createAsyncThunk(
    "handleUpdateBillingTransactionAttachment",
    async (
        { type, file, transactionUuid, walletId, uploader }: HandleUpdateBillingTransactionAttachmentProps,
        { dispatch }
    ) => {
        try {
            const response = await updateTransactionAttachmentOrReceipts({
                walletId,
                transactionUuid,
                type,
                file,
                uploader,
            });
            dispatch(setBillingTransactionAttachment({ file: response.data, transactionUuid }));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const handleUpdateBillingTransactionReceipts = createAsyncThunk(
    "handleUpdateBillingTransactionAttachment",
    async (
        { type, file, transactionUuid, walletId, uploader }: HandleUpdateBillingTransactionAttachmentProps,
        { dispatch }
    ) => {
        try {
            const response = await updateTransactionAttachmentOrReceipts({
                walletId,
                transactionUuid,
                type,
                file,
                uploader,
            });
            dispatch(setBillingTransactionReceipts({ file: response, transactionUuid }));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

export const handleDeleteBillingTransactionReceipt = createAsyncThunk(
    "handleUpdateBillingTransactionAttachment",
    async ({ walletId, fileId, transactionUuid, fileType }: DeleteFileToTransactionProps, { dispatch }) => {
        try {
            const response = await deleteFileToTransaction({
                walletId,
                transactionUuid,
                fileId,
                fileType,
            });
            dispatch(deleteBillingTransactionReceipts({ fileId, transactionUuid }));
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }
);

type HandleRequestCancelTransaction = RefundtransactionsProps & {
    filters: BillingTransactionsStateFilters;
    page: number;
};

export const handleRequestCancelTransaction = createAsyncThunk(
    "handleRequestCancelTransaction",
    async (
        { walletId, reason, uuids, password, twoFactor, page, filters }: HandleRequestCancelTransaction,
        { dispatch }
    ) => {
        try {
            const res = await refundTransactions({ walletId, uuids, password, twoFactor, reason });
            dispatch(fetchBillingTransactions({ filters, page }));
            return res;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
);
