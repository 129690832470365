import { HandleSubmitPropsPasswordValidation, PasswordValidationModal } from "src/shared/common/Password/Modal";
import { AdminExpensePolicySuccess } from "../Success";
import { AdminExpensePolicyCreateDrawerBody } from "./Body";

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import {
    getAdminExpensePoliciesCreateLimitPerOperation,
    getAdminExpensePoliciesCreateLimitPerUnitOfTime,
    getAdminExpensePoliciesCreateOrUpdateError,
    getAdminExpensePoliciesCreateOrUpdateLoading,
    getAdminExpensePoliciesCreateTitle,
    getAdminExpensePoliciesCreateUnitOfTime,
    getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy,
} from "src/modules/admin/expensePolicies/selectors";
import { resetAdminExpensePolicyCreate } from "src/modules/admin/expensePolicies/slice";
import { handleCreateAdminExpensePolicies } from "src/modules/admin/expensePolicies/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

type Props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export function AdminExpensePolicyCreateDrawerContainer({ open, setOpen }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [openValidationModal, setOpenValidationModal] = useState(false);
    const [hidden, setHidden] = useState(false);
    const walletsWithExpensePolicy = useAppSelector((state) =>
        getAdminExpensePoliciesWalletsAlreadyHaveExpensePolicy(state)
    );

    const handleCloseCreateDrawer = () => {
        setOpen(false);
        dispatch(resetAdminExpensePolicyCreate());
    };

    const handleSwitchOpenValidationModal = () => {
        setOpenValidationModal((oldOpen) => {
            setHidden(!oldOpen);
            return !oldOpen;
        });
    };

    const handleSubmit = async (props: HandleSubmitPropsPasswordValidation) => {
        return dispatch(
            handleCreateAdminExpensePolicies({
                ...props,
                walletsWithExpensePolicy,
            })
        ).unwrap();
    };

    const handleOnSuccess = () => {
        setOpen(false);
        setHidden(false);
        dispatch(resetAdminExpensePolicyCreate());
    };

    const limitPerOperation = useAppSelector((state) => getAdminExpensePoliciesCreateLimitPerOperation(state));
    const limitPerUnitOfTime = useAppSelector((state) => getAdminExpensePoliciesCreateLimitPerUnitOfTime(state));
    const unitOfTime = useAppSelector((state) => getAdminExpensePoliciesCreateUnitOfTime(state));
    const title = useAppSelector((state) => getAdminExpensePoliciesCreateTitle(state));
    const loading = useAppSelector((state) => getAdminExpensePoliciesCreateOrUpdateLoading(state));
    const error = useAppSelector((state) => getAdminExpensePoliciesCreateOrUpdateError(state));

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter" && !(!title || !unitOfTime || !limitPerUnitOfTime || !limitPerOperation) && !openValidationModal && open) {
                handleSwitchOpenValidationModal();
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    });

    return (
        <>
            <Drawer
                isHidden={hidden}
                isOpen={open}
                onClose={handleCloseCreateDrawer}
                body={<AdminExpensePolicyCreateDrawerBody  />}
                footer={{
                    primaryButton: {
                        disabled: loading || !title || !unitOfTime || !limitPerUnitOfTime || !limitPerOperation,
                        onClick: handleSwitchOpenValidationModal,
                        label: t("Expense-policy.add-a-rule"),
                    },
                    secondaryButton: {
                        label: t("CommonUse.cancel"),
                        onClick: handleCloseCreateDrawer,
                    },
                }}
                header={{ title: t("Expense-policy.add-expense-rule"), image: "adjustments-horizontal" }}
            />
            <PasswordValidationModal
                header={{
                    title: t("Expense-policy.create-an-expense-policy"),
                    icon: "adjustments-horizontal",
                }}
                successComponent={<AdminExpensePolicySuccess creation />}
                onSuccess={handleOnSuccess}
                setOpen={handleSwitchOpenValidationModal}
                open={openValidationModal}
                handleOnSubmit={handleSubmit}
                error={error}
            />
        </>
    );
}
