import { AdminAccountsFormsRole, RolesParams } from "../../Forms/Role/Role";
import classnames from "classnames";
import { CreateUserContext } from "src/shared/context/createUser";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

export const AdminUserCreateDrawerSectionsRole = () => {
    const { step, attachToWallet, handleValidatedStep, userValues, setUserValues } = useContext(CreateUserContext);
    const { userDetail } = useAppSelector((state) => getUserDetail(state));
    const { t } = useTranslation();

    const handleValidatedRole = (validated: boolean) => {
        handleValidatedStep(validated, 3);
    };

    const handleChangeRole = (values: RolesParams) => {
        setUserValues({ ...values, ...userValues, addAccessType: values.addAccessType, accessType: values.accessType });
    };

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Users.roles")} />
                <Typography
                    message={step > 2 ? t("CommonUse.done") : t("CommonUse.to-complete")}
                    className={classnames("fw-bold", step > 2 ? "color-success" : "color-warning-500")}
                />
            </div>
            <div className='drawer-content-section-body'>
                <AdminAccountsFormsRole
                    detail={!userDetail?.isAlreadyMember ? userDetail : null}
                    attachToWallet={attachToWallet}
                    onChange={handleChangeRole}
                    onValidated={handleValidatedRole}
                />
            </div>
        </div>
    );
};
