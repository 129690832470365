import {
    BillingEnterprise,
    BillingUpdateBillerDetailsPayload,
    BillingUpdateBillerDisponibilityPayload,
    BillingUpdateBillerInvoiceReceiptPayload,
} from "./type";
import { middlewareRequest } from "src/services/utils/request";
import { WalletApi } from "src/services/wallets/types";

export async function requestApiBillingEnterprise({ companyId }: { companyId: string | number }) {
    try {
        return await middlewareRequest<BillingEnterprise>({
            endpoint: `/billing/billers/${companyId}`,
            method: "GET",
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function updateApiBillingBillerInvoiceReceipt(
    params: BillingUpdateBillerInvoiceReceiptPayload & { password: string; otp: null | string }
) {
    try {
        return await middlewareRequest<WalletApi>({
            endpoint: "/admin/wallets/default",
            method: "PUT",
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function updateApiBillingBillerDetails(
    params: (BillingUpdateBillerDetailsPayload | BillingUpdateBillerDisponibilityPayload) & {
        password: string;
        otp: null | string;
    }
) {
    try {
        return await middlewareRequest<BillingEnterprise>({
            endpoint: "/billing/billers",
            method: "PUT",
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
