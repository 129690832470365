import { logoutResetStore } from "../../auth/Actions";
import { UpdateTransactionCommentProps } from "src/services/transactions/operations";
import { TransactionApi, TransactionReceipt, TransactionsApi } from "src/services/transactions/types";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export type BillingTransactionsStateFilters = {
    archived?: boolean;
    query?: string;
    startDate?: Date;
    endDate?: Date;
    status?: SelectOption[];
    tasks?: SelectOption[];
    transactionType?: SelectOption[];
};

type TransactionStateDate = {
    isLoading: boolean;
    count: number;
    currentPage: number;
    totalPages: number;
    pageSize: number;
    data: TransactionApi[];
};

interface TransactionState {
    items: TransactionStateDate;
    filters: BillingTransactionsStateFilters;
}

const initialState = {
    items: { totalPages: 20, currentPage: 1 },
    filters: {},
} as TransactionState;

export const billingTransactionSlice = createSlice({
    name: "billingTransactionSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        setBillingTransactionShowPageSize: (state, { payload }: PayloadAction<{ pageSize: number }>) => {
            const { pageSize } = payload;

            state.items.pageSize = pageSize;
            state.items.currentPage = 1;
        },
        setBillingTransactionCurrentPage: (state, { payload }: PayloadAction<{ currentPage: number }>) => {
            const { currentPage } = payload;

            state.items.currentPage = currentPage;
        },
        setBillingTransactionQueryFilter: (state, { payload }: PayloadAction<{ query: string | undefined }>) => {
            const { query } = payload;

            state.filters.query = query;
        },
        setBillingTransactionStartDateFilter: (state, { payload }: PayloadAction<{ date: Date | undefined }>) => {
            const { date } = payload;

            if (!date) {
                delete state.filters.startDate;
            } else {
                state.filters.startDate = date;
            }
        },
        setBillingTransactionEndDateFilter: (state, { payload }: PayloadAction<{ date: Date | undefined }>) => {
            const { date } = payload;

            if (!date) {
                delete state.filters.endDate;
            } else {
                state.filters.endDate = date;
            }
        },
        setBillingTransactionStatusFilter: (state, { payload }: PayloadAction<{ status: SelectOption[] }>) => {
            const { status } = payload;
            const updatedFilter = state.filters;

            if (status && !status.length) {
                delete updatedFilter.status;
            } else {
                updatedFilter.status = status;
            }
        },
        setBillingTransactionTypeFilter: (state, { payload }: PayloadAction<{ transactionType: SelectOption[] }>) => {
            const { transactionType } = payload;
            const updatedFilter = state.filters;

            if (transactionType && !transactionType.length) {
                delete updatedFilter.transactionType;
            } else {
                updatedFilter.transactionType = transactionType;
            }
        },
        setBillingTransactionTaskFilter: (state, { payload }: PayloadAction<{ tasks: SelectOption[] }>) => {
            const { tasks } = payload;
            const updatedFilter = state.filters;

            if (tasks && !tasks.length) {
                delete updatedFilter.tasks;
            } else {
                updatedFilter.tasks = tasks;
            }
        },
        switchBillingTransactionArchivedFilter: (state) => {
            const updatedFilter = state.filters;

            if (updatedFilter?.archived) {
                delete updatedFilter.archived;
            } else {
                updatedFilter.archived = !state.filters?.archived || true;
            }
        },
        resetBillingTransactionFilters: (
            state,
            { payload }: PayloadAction<{ filter: keyof BillingTransactionsStateFilters }>
        ) => {
            const { filter } = payload;
            const updatedFilter = state.filters;

            delete updatedFilter[filter];
        },
        resetAllBillingTransactionFilters: (state) => {
            state.filters = {};
        },
        requestBillingTransactions: (state) => {
            return {
                ...state,
                items: {
                    ...state.items,
                    isLoading: true,
                },
            };
        },
        failedRequestBillingTransactions: (state) => {
            return {
                ...state,
                items: {
                    ...state.items,
                    isLoading: false,
                },
            };
        },
        receiveBillingTransactions: (state, { payload }: PayloadAction<{ transactions: TransactionsApi }>) => {
            const { transactions } = payload;
            const { data, ...props } = transactions;

            return {
                ...state,
                items: {
                    ...state.items,
                    ...props,
                    data,
                    isLoading: false,
                },
            };
        },
        setBillingTransactionComment: (state, { payload }: PayloadAction<UpdateTransactionCommentProps>) => {
            const { transactionUuid, comment } = payload;

            const transaction = state.items?.data?.find((item) => item.uuid === transactionUuid);

            if (transaction) {
                transaction.comment = comment;
            }
        },
        setBillingTransactionAttachment: (
            state,
            { payload }: PayloadAction<{ transactionUuid: string; file: string }>
        ) => {
            const { transactionUuid, file } = payload;

            const transaction = state.items?.data?.find((item) => item.uuid === transactionUuid);

            if (transaction) {
                transaction.attachment = file;
            }
        },
        setBillingTransactionReceipts: (
            state,
            { payload }: PayloadAction<{ transactionUuid: string; file: TransactionReceipt }>
        ) => {
            const { transactionUuid, file } = payload;

            const transaction = state.items?.data?.find((item) => item.uuid === transactionUuid);

            if (transaction) {
                transaction.receipts.push(file);
            }
        },
        deleteBillingTransactionReceipts: (
            state,
            { payload }: PayloadAction<{ transactionUuid: string; fileId: string }>
        ) => {
            const { transactionUuid, fileId } = payload;

            const transaction = state.items?.data?.find((item) => item.uuid === transactionUuid);

            if (transaction) {
                const newReceipts = [...transaction.receipts].filter((receipt) => String(receipt.id) !== fileId);
                transaction.receipts = newReceipts;
            }
        },
    },
});

export const {
    setBillingTransactionShowPageSize,
    setBillingTransactionCurrentPage,
    setBillingTransactionQueryFilter,
    setBillingTransactionStartDateFilter,
    setBillingTransactionEndDateFilter,
    setBillingTransactionStatusFilter,
    setBillingTransactionTypeFilter,
    setBillingTransactionTaskFilter,
    switchBillingTransactionArchivedFilter,
    resetBillingTransactionFilters,
    resetAllBillingTransactionFilters,
    requestBillingTransactions,
    failedRequestBillingTransactions,
    receiveBillingTransactions,
    setBillingTransactionComment,
    setBillingTransactionAttachment,
    setBillingTransactionReceipts,
    deleteBillingTransactionReceipts,
} = billingTransactionSlice.actions;

export const billingTransactionsSliceReducer = billingTransactionSlice.reducer;
