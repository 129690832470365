import { AdminDashboardQuestsItem } from "./Item";
import { QuestKeys } from "src/services/admin/types";

import { useTranslation } from "react-i18next";

import { useAdminQuests } from "src/modules/admin/quests/hooks";
import { getAdminDashboardInfoData, getAdminDashboardInfoLoading } from "src/modules/admin/selectors";
import { useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Typography } from "src/shared/atoms/Typography/Typography";

export function AdminDashboardQuestsSection() {
    const { t } = useTranslation();

    const questsData = useAppSelector((state) => getAdminDashboardInfoData(state)?.quests);
    const isLoading = useAppSelector((state) => getAdminDashboardInfoLoading(state));
    const quests = useAdminQuests();

    const progress = Object.values(questsData ?? {}).reduce((res, data) => {
        if (data.status) {
            res = res + 1;
        }
        return res;
    }, 0);
    const totalProgress = Object.values(questsData ?? {}).length ?? 1;
    const percentageCompleted = 100 * (progress / totalProgress);

    return (
        <div className='todo-widget-container'>
            <div className='todo-widget-header'>
                <Avatar icon='trending-up' />
                <Typography className='fw-bold' message={t("Dashboard.quest-progression")} />
            </div>
            <div className='todo-list-container no-gap'>
                {isLoading ? (
                    <div className='loading-placeholder'>
                        <div className='placeholder' style={{ animationDelay: "0.1s" }} />
                        <div className='placeholder' style={{ animationDelay: "0.3s" }} />
                        <div className='placeholder' style={{ animationDelay: "0.6s" }} />
                    </div>
                ) : (
                    <>
                        {totalProgress > 0 && (
                            <>
                                <Typography
                                    className='fw-bold'
                                    message={t("{{progress}}/{{totalProgress}} actions principales réalisées", {
                                        progress,
                                        totalProgress,
                                    })}
                                />
                                <div className='progress-bar-container'>
                                    <div className='progress-bar-fill' style={{ width: `${percentageCompleted}%` }} />
                                </div>
                            </>
                        )}
                        {Object.entries(quests).map(([key, { name, link, state, action }]) => (
                            <AdminDashboardQuestsItem
                                key={key}
                                questKey={key as QuestKeys}
                                name={name}
                                action={action}
                                link={link}
                                state={state}
                            />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}
