import { DetailsBeneficiaryDrawerContainer } from "../DetailsDrawer/Container";
import { BeneficiariesCreateActions } from "../Panel";
import { AdminBeneficiariesFilters } from "./Filters";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { transactionTypesMap } from "src/shared/const/transactions";
import { BeneficiaryModel } from "src/shared/models/Beneficiary";
import { computeTimestampToString } from "src/shared/utils/formatDate";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminBeneficiaries } from "src/modules/admin/adminBeneficiaries/selectors";
import {
    clearBeneficiaryDeleteIntegration,
    setAdminBeneficiaryPage,
    setAdminBeneficiaryPageSize,
} from "src/modules/admin/adminBeneficiaries/slice";
import {
    fetchAdminBeneficiaries,
    handleDeleteAdminBeneficiary,
} from "src/modules/admin/adminBeneficiaries/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { ButtonSelect } from "src/shared/atoms/Buttons/ButtonSelect/ButtonSelect";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

const emptyArchivedImg = require("src/shared/images/empty/empty-user.svg").default;
const successImage = require("src/shared/images/user/success.png");

type BeneficiariesListProps = {
    handleDisplayAddBeneficiary: (value: boolean, action?: BeneficiariesCreateActions) => void;
};
export const BeneficiariesList = ({ handleDisplayAddBeneficiary }: BeneficiariesListProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const isBeneficiaryRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_BENEFICIARY);

    const [query, setQuery] = useState("");
    const [selectedBeneficiary, setSelectedBeneficiary] = useState<BeneficiaryModel | null>(null);
    const {
        currentPage,
        loading,
        beneficiaries,
        totalCount,
        deleteBeneficiaryResponse,
        deleteBeneficiaryError,
        deleteBeneficiaryLoading,
        pageSize,
    } = useAppSelector(getAdminBeneficiaries);

    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [showSuccess, setShowSuccess] = useState(false);

    const [openPasswordValidationModalDelete, setOpenPasswordValidationModalDelete] = useState(false);

    const debouncedQuery = useDebounce(query, 700);

    useEffect(() => {
        handleFetchAdminBeneficiaries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize, currentPage, debouncedQuery]);

    useEffect(() => {
        if (deleteBeneficiaryResponse) {
            setShowSuccess(true);
        }
    }, [deleteBeneficiaryResponse]);

    const handleFetchAdminBeneficiaries = () => {
        dispatch(fetchAdminBeneficiaries({ page: currentPage, pageSize, query: debouncedQuery }));
    };

    const handleChangePageSize = (newPageSize: number) => {
        dispatch(setAdminBeneficiaryPageSize({ pageSize: newPageSize }));
    };

    const handleChangePage = (newPage: number) => {
        dispatch(setAdminBeneficiaryPage({ page: newPage }));
    };

    useEffect(() => {
        if (deleteBeneficiaryResponse && !deleteBeneficiaryLoading && !deleteBeneficiaryError) {
            dispatch(clearBeneficiaryDeleteIntegration());
            handleFetchAdminBeneficiaries();
            setSelectedBeneficiary(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteBeneficiaryError, deleteBeneficiaryResponse, deleteBeneficiaryLoading]);

    const handleSetQuery = (query: string) => {
        setQuery(query);
    };

    const handleOpenDetailDrawer = (beneficiary: BeneficiaryModel) => {
        setSelectedBeneficiary(beneficiary);
    };

    const handleEditBeneficiary = (refresh?: boolean) => {
        setSelectedBeneficiary(null);
        if (refresh) {
            handleFetchAdminBeneficiaries();
        }
    };

    const handleDownloadModel = () => {
        const link = document.createElement("a");
        link.href = "/models/beneficiares_modele_julaya.xlsx";
        link.download = "beneficiares_modele_julaya.xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleOpenPasswordValidationModalDelete = () => {
        setShowSuccess(false);
        setOpenPasswordValidationModalDelete(!openPasswordValidationModalDelete);
    };

    const handleDelete = async (password: string, twoFactor: string | null) => {
        await dispatch(
            handleDeleteAdminBeneficiary({
                beneficiariesIds: selectedRows.map((index) => beneficiaries[index].id.toString()),
                password,
                twoFactor,
            })
        );
    };

    const tableRows = beneficiaries.map((beneficiary, index) => {
        const { firstname, lastname, label, externalAccounts, createdAt } = beneficiary;
        return [
            {
                content: <Avatar firstname={firstname} lastname={lastname} />,
                type: "image",
                onClick: () => handleOpenDetailDrawer(beneficiary),
            },
            {
                content: <Typography className='fw-bold' message={firstname + " " + lastname} />,
                onClick: () => handleOpenDetailDrawer(beneficiary),
            },
            {
                content: <Typography className='fw-bold' message={label ? label : "-"} />,
                onClick: () => handleOpenDetailDrawer(beneficiary),
            },
            {
                content: (
                    <div className='external-account-avatars' key={index}>
                        {externalAccounts.map((network) => {
                            return transactionTypesMap[network.typeSlug]?.type === "image" ? (
                                <Avatar
                                    key={network.uuid}
                                    avatar={transactionTypesMap[network.typeSlug].img}
                                    size='sm'
                                />
                            ) : (
                                <Icon
                                    variant='squared'
                                    color='lilas'
                                    size='sm'
                                    name={transactionTypesMap[network.typeSlug]?.img as IconListType}
                                />
                            );
                        })}
                    </div>
                ),
                onClick: () => handleOpenDetailDrawer(beneficiary),
            },
            {
                content: <Typography className='fw-bold' message={computeTimestampToString(createdAt)} />,
                onClick: () => handleOpenDetailDrawer(beneficiary),
            },
        ];
    });

    return (
        <>
            <PageHeader title={t<string>("Beneficiaries.my-beneficiaries")}>
                <div className='page-header-items'>
                    <AdminBeneficiariesFilters query={query} setQuery={handleSetQuery} />
                    <ToolTip
                        position='bottom'
                        content={
                            <div className='tooltip-limited'>
                                <Typography message={t("Subscription.not-accessible-feature")} className='fw-bold' />
                                <Typography message={t("Subscription.unlock-unlimited-access")} />
                            </div>
                        }
                        activation={isBeneficiaryRestricted}
                    >
                        <ButtonSelect
                            disabled={isBeneficiaryRestricted}
                            buttonProps={{
                                icon: "plus-circle",
                                label: t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") }),
                                onClick: () => handleDisplayAddBeneficiary(true),
                            }}
                            selectItems={[
                                {
                                    icon: "plus-circle",
                                    title: t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") }),
                                    onClick: () => handleDisplayAddBeneficiary(true, "single"),
                                },
                                {
                                    icon: "document",
                                    title: t("EditTaskPanel.utiliser-un-modele"),
                                    onClick: () => handleDisplayAddBeneficiary(true, "bulk"),
                                },
                                {
                                    icon: "arrow-down-on-square",
                                    title: t("EditTaskPanel.telecharger-un-modele"),
                                    onClick: handleDownloadModel,
                                },
                            ]}
                            dividerPositions={[0]}
                        />
                    </ToolTip>
                </div>
            </PageHeader>
            <SelectableTable
                isLoading={loading}
                selectable
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                selectedBar={{
                    itemName: "bénéficiaires",
                    buttonActions: {
                        content: {
                            header: {
                                title: t<string>("CommonUse.multiple-action"),
                                icon: "squares",
                            },
                            dropDownContent: {
                                body: (
                                    <div
                                        className='selectable-bar-actions'
                                        onClick={handleOpenPasswordValidationModalDelete}
                                    >
                                        <Icon name='thrash' />
                                        <Typography message={t("CommonUse.delete")} />
                                    </div>
                                ),
                            },
                        },
                    },
                }}
                pagination={{
                    currentPage,
                    pageSize: pageSize,
                    totalCount,
                    onPageChange: handleChangePage,
                    onPageSizeChange: handleChangePageSize,
                }}
                header={[
                    "",
                    t("Beneficiaries.beneficiary"),
                    t("CommonUse.description"),
                    t("CommonUse.payment-method"),
                    t("Beneficiaries.date-modification"),
                ]}
                empty={
                    query ? (
                        <Empty
                            image={emptyArchivedImg}
                            title={t("Table.no-result-found")}
                            description={t<string>("Table.no-result-found-description")}
                        />
                    ) : (
                        <div className='beneficiaries-empty-table'>
                            <img src={require("src/shared/images/beneficiaries/connect-users.png")} alt='empty-table' />
                            <Typography className='fw-bold' message={t("Beneficiaries.add-beneficiaries")} size='sm' />
                            <Typography
                                className='fw-bold color-neutral-500'
                                message={t("Beneficiaries.empty-sentence")}
                                size='sm'
                            />
                            <ToolTip
                                position='bottom'
                                content={
                                    <div className='tooltip-limited'>
                                        <Typography
                                            message={t("Subscription.not-accessible-feature")}
                                            className='fw-bold'
                                        />
                                        <Typography message={t("Subscription.unlock-unlimited-access")} />
                                    </div>
                                }
                                activation={isBeneficiaryRestricted}
                            ></ToolTip>
                            <ButtonSelect
                                disabled={isBeneficiaryRestricted}
                                buttonProps={{
                                    icon: "plus-circle",
                                    label: t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") }),
                                    onClick: () => handleDisplayAddBeneficiary(true),
                                }}
                                selectItems={[
                                    {
                                        icon: "plus-circle",
                                        title: t("CommonUse.add", { entity: t("Beneficiaries.a-beneficiary") }),
                                        onClick: () => handleDisplayAddBeneficiary(true, "single"),
                                    },
                                    {
                                        icon: "document",
                                        title: t("EditTaskPanel.utiliser-un-modele"),
                                        onClick: () => handleDisplayAddBeneficiary(true, "bulk"),
                                    },
                                    {
                                        icon: "arrow-down-on-square",
                                        title: t("EditTaskPanel.telecharger-un-modele"),
                                        onClick: handleDownloadModel,
                                    },
                                ]}
                                dividerPositions={[0]}
                            />
                        </div>
                    )
                }
                rows={tableRows as SelectableTableRow[][]}
            />
            <DetailsBeneficiaryDrawerContainer
                isOpen={selectedBeneficiary !== null}
                handleCloseDrawer={(refresh) => handleEditBeneficiary(refresh)}
                selectedBeneficiary={selectedBeneficiary}
                isBulk={false}
            />
            <PasswordValidationModal
                header={{
                    title: t("Beneficiaries.delete-bene"),
                    icon: "user-group",
                }}
                successContent={{
                    text: t<string>("PasswordModal.validated-demand"),
                    image: successImage,
                }}
                setOpen={handleOpenPasswordValidationModalDelete}
                error={deleteBeneficiaryError}
                asyncFromParent
                succeeded={showSuccess}
                open={!!openPasswordValidationModalDelete}
                handleOnSubmit={({ password, twoFactor }) => handleDelete(password, twoFactor)}
            />
        </>
    );
};
