import { TasksFilters } from "./Filters";
import { TasksListContainer } from "./List/Container";
import { TasksTableContainer } from "./Table/Container";
import { useLocation, useNavigate } from "react-router-dom";
import { DrawerContext } from "src/wrapper/context";

import { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { ToggleSwitch, ToggleSwitchOption } from "src/shared/atoms/Toggler/Toggler";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

import "./styles.scss";

export const firstOptionDisplay: ToggleSwitchOption = { icon: "table-cell", label: "TasksPanel.tableau" };
export const secondOptionDisplay: ToggleSwitchOption = { icon: "list-bullet", label: "TasksPanel.liste" };

export const TasksPanel = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { setOpen } = useContext(DrawerContext);

    const [display, setDisplay] = useState<ToggleSwitchOption>(
        pathname === "/tasks-list" ? secondOptionDisplay : firstOptionDisplay
    );
    const [forceClose, setForceClose] = useState(false);

    const filters = useMemo(
        () => ({
            page: 1,
        }),
        []
    );

    useEffect(() => {
        setDisplay(pathname.includes("/tasks-list") ? secondOptionDisplay : firstOptionDisplay);
    }, [pathname]);

    useEffect(() => {
        if (forceClose) {
            setForceClose(false);
        }
    }, [forceClose]);

    const handleChangeDisplay = (newDisplay: ToggleSwitchOption) => {
        setDisplay(newDisplay);
        if (newDisplay.label === secondOptionDisplay.label) {
            navigate("/tasks-list");
        } else {
            navigate("/tasks");
        }
    };

    const handleRedirectToBulk = () => {
        setOpen("transfer");
    };

    return (
        <div className='tasks-panel'>
            <PageHeader title={t<string>("Tasks.my-tasks")}>
                <div className='d-flex justify-content-between mb-2'>
                    <div className='d-flex align-items-center'>
                        <ToggleSwitch
                            selected={display}
                            onChange={handleChangeDisplay}
                            firstOption={firstOptionDisplay}
                            secondOption={secondOptionDisplay}
                        />

                        <TasksFilters display={display} />
                    </div>

                    <Button
                        onClick={handleRedirectToBulk}
                        variant='ghost'
                        icon='plus-circle'
                        color='lilas'
                        label={t("CommonUse.new-transfer")}
                    />
                </div>
            </PageHeader>
            {display === firstOptionDisplay ? <TasksTableContainer /> : <TasksListContainer filters={filters} />}
        </div>
    );
};
