import { TaskListApiReturnItem } from "src/services/tasks/types";

import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Accordion } from "src/shared/components/Accordion/Accordion";

const statusDescriptions: {
    [key: string]: {
        icon: IconListType;
        color: "primary" | "success" | "warning" | "error";
        label: string;
        description?: string;
    };
} = {
    complete: {
        icon: "check-circle",
        color: "success",
        label: "CommonUse.done",
    },
    draft: {
        icon: "pencil",
        color: "primary",
        label: "Tasks.draft",
        description: "Tasks.draft-task-description",
    },
    "to-validate": {
        icon: "pencil-square",
        color: "primary",
        label: "Tasks.to-sign",
        description: "Tasks.to-sign-task-description",
    },
    sending: {
        icon: "clock",
        color: "warning",
        label: "Tasks.sending",
        description: "Tasks.status-sending-description",
    },
    waiting: {
        icon: "clock",
        color: "warning",
        label: "Tasks.pending",
        description: "Tasks.group-transfer-on-going",
    },
    pending: {
        icon: "clock",
        color: "warning",
        label: "Tasks.pending",
        description: "Tasks.group-transfer-on-going",
    },
    "to-retry": {
        icon: "x-circle",
        color: "error",
        label: "Tasks.failed",
        description: "Tasks.some-transactions-failed",
    },
    cancelled: {
        icon: "x-mark-filled",
        color: "warning",
        label: "CommonUse.cancel",
        description: "CommonUse.contact-support",
    },
} as const;

type Props = {
    task: TaskListApiReturnItem;
};

export function TasksDetailsDrawerSectionsStatus({ task }: Props) {
    const { t } = useTranslation();
    const { status } = task;

    const statusDescription = status && statusDescriptions[status];

    return (
        <div className='drawer-content-section task-details-status'>
            <Accordion
                icon='check-circle'
                iconSize='sm'
                iconColor='white'
                preSelectedOpen
                title={t("Tasks.status-task")}
                color='white'
            >
                {statusDescription && (
                    <Alert
                        color={statusDescription.color === "primary" ? "lilas" : statusDescription.color}
                        message={t(statusDescription.label)}
                        icon={statusDescription.icon}
                        subMessage={
                            statusDescription?.description ? t<string>(statusDescription.description) : undefined
                        }
                    />
                )}
            </Accordion>
        </div>
    );
}
