import { TransactionNotify } from "src/shared/models/Task";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { useTransactionTimerRedirection } from "src/modules/transactions/hooks";

import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type SuccessModalContentProps = {
    totalAmountDischarged: number;
    currentAccountBalance: number;
    walletId: number;
    createdTransaction?: TransactionNotify;
};

export const SuccessModalContent = ({
    totalAmountDischarged,
    currentAccountBalance,
    walletId,
    createdTransaction,
}: SuccessModalContentProps) => {
    const timer = useTransactionTimerRedirection({ walletId, createdTransaction });
    const { t } = useTranslation();

    return (
        <div className='success-modal-content-container'>
            <Typography
                className='fw-bold'
                message={t("SuccessModalContent.votre-operation-a-ete-enregistree-avec-succes")}
            />
            <Typography message={t("Tasks.redirect", { nbSeconds: timer })} />
            <img
                className='mx-auto'
                height={200}
                src={require("src/shared/images/rapid-transfer/rapid-transfer-success.svg").default}
                alt=''
            />

            <div className='divider' />

            <div className='balance-informations'>
                <Typography message={t("Tasks.total-amount")} />
                <Typography className='fw-bold' message={`${formatMoneyToString({ amount: totalAmountDischarged })}`} />
            </div>

            <div className='divider' />

            <div className='balance-informations'>
                <Typography message={t("Tasks.new-balance")} />
                <Typography
                    className='fw-bold color-lilas-900'
                    message={`${formatMoneyToString({ amount: currentAccountBalance - totalAmountDischarged })}`}
                />
            </div>
        </div>
    );
};
