import classNames from "classnames";

import { HtmlHTMLAttributes, ReactNode } from "react";

import { AnimationType } from "src/shared/atoms/Delayed/Delayed";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";

import "./styles.scss";

const loadingRowsToshow = 10;

type Props = {
    keyName: string;
    isLoading?: boolean;
    items: ReactNode[];
    animation?: AnimationType;
    selectedValue?: number | null;
    disabled?: boolean;
    variant?: "primary" | "secondary";
    page?: number;
    lastPage: boolean;
    handleOnScroll: (page?: number | string) => void;
} & HtmlHTMLAttributes<HTMLUListElement>;

export function ScrollableList({
    keyName,
    items,
    isLoading,
    animation,
    selectedValue,
    disabled,
    variant = "primary",
    page,
    lastPage = false,
    handleOnScroll,
    ...props
}: Props) {
    const onScroll = (event: React.UIEvent) => {
        if (isLoading) {
            return;
        }

        const element = event.target as HTMLDivElement;

        if (element.scrollHeight - element.offsetHeight - element.scrollTop < 1) {
            if (!lastPage) {
                handleOnScroll(page ? page + 1 : undefined);
            }
        }
    };

    return (
        <div className={`scrollable-list-container ${variant}`} onScroll={onScroll}>
            {isLoading && items.length === 0 && (
                <ul {...props} data-testid='scrollable-skeleton-list-container'>
                    {Array.from({ length: loadingRowsToshow }, (_, index) => index).map((value) => (
                        <li
                            key={`skeleton-${value}`}
                            className='table-placeholder'
                            style={{ animationDelay: (100 * value).toString() + "ms" }}
                        />
                    ))}
                </ul>
            )}
            {items.length > 0 ? (
                <ul {...props} onScroll={onScroll} data-testid='scrollable-list-container'>
                    {items.map((component, index) => (
                        <li
                            key={`scrollable-list-item-${component}`}
                            className={classNames({
                                active: selectedValue === index,
                                disabled: disabled,
                            })}
                            value={index}
                        >
                            {component}
                        </li>
                    ))}
                    {isLoading && (
                        <li>
                            <Spinner size='lg' />
                        </li>
                    )}
                </ul>
            ) : null}
        </div>
    );
}
