import classNames from "classnames";

import React, { ReactNode, useEffect, useRef, useState } from "react";

import { IconButton } from "src/shared/components/IconButton/IconButton";

import "./styles.scss";

const defaultItemsToSkip = 1;

interface CarouselProps {
    items: ReactNode[];
    loading?: boolean;
    header?: ReactNode;
    headerButton?: ReactNode;
    scrollPage?: boolean;
    empty?: ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ header, headerButton, items, loading, scrollPage, empty }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const [itemWidth, setItemWidth] = useState<number | null>(null);

    //@ts-expect-error
    useEffect(() => {
        const getItemWidth = () => {
            if (containerRef?.current?.children?.[0]) {
                const firstItem = containerRef.current.children[0];
                if (firstItem) {
                    setItemWidth(firstItem.clientWidth);
                }
            }
        };

        if (!itemWidth) {
            window.addEventListener("resize", getItemWidth);
            getItemWidth();
            return () => window.removeEventListener("resize", getItemWidth);
        }
    }, [itemWidth, containerRef, items]);

    const handlePrev = () => {
        const containerWidth = containerRef?.current?.offsetWidth ?? 0;
        if (itemWidth) {
            const itemsToSkip = scrollPage ? Math.floor(containerWidth / itemWidth) : defaultItemsToSkip;
            setCurrentIndex((prevIndex) => Math.max(0, prevIndex - itemsToSkip));
        }
    };

    const handleNext = () => {
        const containerWidth = containerRef?.current?.offsetWidth ?? 0;

        if (containerWidth && itemWidth) {
            const maxWidth = items.length * itemWidth;
            const current = currentIndex * itemWidth;
            const itemsToSkip = scrollPage ? Math.floor(containerWidth / itemWidth) : defaultItemsToSkip;
            if (maxWidth - current >= containerWidth) {
                setCurrentIndex((prevIndex) => prevIndex + itemsToSkip);
            } else {
                setCurrentIndex(0);
            }
        }
    };

    const transformValue = -currentIndex * (itemWidth ?? 0);

    return (
        <div className='carousel-container' data-testid='carousel-container'>
            <div
                className={classNames("carousel-header", {
                    "space-between": header,
                    "align-end": (headerButton && !header) || (!header && !headerButton),
                })}
            >
                {header ? <div data-testid='carousel-header'>{header}</div> : null}
                <div className='carousel-buttons-container'>
                    {headerButton ?? null}
                    <IconButton
                        testId={"previous"}
                        color='grey-dark'
                        disabled={loading}
                        iconProps={{ name: "chevron-left" }}
                        handleOnClick={handlePrev}
                    />
                    <IconButton
                        testId={"next"}
                        color='grey-dark'
                        disabled={loading}
                        iconProps={{ name: "chevron-right" }}
                        handleOnClick={handleNext}
                    />
                </div>
            </div>
            <div className='carousel'>
                <div
                    ref={containerRef}
                    className='carousel-items'
                    style={{
                        transform: `translateX(${transformValue}px)`,
                    }}
                >
                    {items.map((item, index) => (
                        <div key={`carousel-item-${index}`} className='carousel-item'>
                            {item}
                        </div>
                    ))}
                </div>
            </div>
            {empty && empty}
        </div>
    );
};
