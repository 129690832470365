import { ColumnItems, ExportFrequencies, exportFormatOptions } from "../../const";
import moment from "moment";
import {
    billingtransactionStatusesFilterOptions,
    transactionTypesFilterOptions,
    transactionTypesSlug,
} from "src/shared/const/transactions";

import { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useTasksOptionsForAuthorizedWallets } from "src/modules/tasks/hooks";
import { getWalletsAsSelectOptions } from "src/modules/wallets/selectors";
import { fetchWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

type ExportInformationsDrawerProps = {
    isOpen: boolean;
    handleOpenInformationsDrawer: () => void;
    exportItem: any;
    ctaButtons: (cta: string, exportUrl?: string) => void;
};

export const ExportInformationsDrawer = ({
    isOpen,
    handleOpenInformationsDrawer,
    exportItem,
    ctaButtons,
}: ExportInformationsDrawerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const walletOptions = useAppSelector((state) => getWalletsAsSelectOptions(state));
    const exportFrequecies = useMemo(() => ExportFrequencies(t), [t]);
    const columnsItems = useMemo(() => ColumnItems(t), [t]);

    const selectedWalletsId = useMemo(
        () => (exportItem?.walletIds ? exportItem?.walletIds.map((wallet: number) => wallet.toString()) : []),
        [exportItem?.walletIds]
    );
    const { tasksOptions, loading } = useTasksOptionsForAuthorizedWallets(selectedWalletsId);

    useEffect(() => {
        if (!walletOptions.length) {
            dispatch(fetchWalletsAuthUser());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const walletIdComponents = useMemo(() => {
        if (exportItem?.walletIds) {
            return exportItem?.walletIds.map((walletId: number) => {
                const walletOption = walletOptions.find((option) => option.id === walletId.toString());
                return walletOption ? (
                    <Typography key={walletId} size='xs' className='listed' message={walletOption.label} />
                ) : null;
            });
        }
    }, [exportItem?.walletIds, walletOptions]);

    const tasksComponents = useMemo(() => {
        if (!loading && exportItem?.taskIds) {
            if (exportItem?.taskIds.length === 0) {
                return <Typography message={t("Export.tasks-none-selected-msg")} />;
            } else if (exportItem?.taskIds.includes("all")) {
                return <Typography message={t("Export.tasks-all-selected-msg")} />;
            }
            return exportItem?.taskIds.map((taskId: string) => {
                const taskOption = tasksOptions.find((option) => option.id === taskId);
                return taskOption ? (
                    <Typography key={taskOption.id} size='xs' className='listed' message={taskOption.label} />
                ) : null;
            });
        }
    }, [exportItem?.taskIds, tasksOptions, loading]);

    const transactionTypeComponents = useMemo(() => {
        if (exportItem?.transactionTypes) {
            return exportItem?.transactionTypes.includes("all")
                ? transactionTypesFilterOptions.map((option) => (
                      <Typography key={option.id} size='xs' className='listed' message={option.label} />
                  ))
                : exportItem?.transactionTypes.map((slug: string) => {
                      const transactionType = transactionTypesFilterOptions.find(
                          (option) => transactionTypesSlug[option.id as any] === slug
                      );
                      return transactionType ? (
                          <Typography
                              key={transactionType.id}
                              size='xs'
                              className='listed'
                              message={transactionType.label}
                          />
                      ) : null;
                  });
        }
    }, [exportItem, transactionTypesFilterOptions]);

    const transactionStatusComponents = useMemo(() => {
        if (exportItem?.transactionStatus) {
            return exportItem.transactionStatus.includes("all")
                ? billingtransactionStatusesFilterOptions(t).map((option) => {
                      return <Typography key={option.id} size='xs' className='listed' message={option.label} />;
                  })
                : exportItem.transactionStatus.map((status: string) => {
                      const statusOption = billingtransactionStatusesFilterOptions(t).find(
                          (option) => option.id === status
                      );
                      return statusOption ? (
                          <Typography key={status} size='xs' className='listed' message={statusOption.label} />
                      ) : null;
                  });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportItem?.transactionStatus, billingtransactionStatusesFilterOptions]);

    const columnComponents = useMemo(() => {
        if (exportItem?.columns) {
            return exportItem.columns.includes("all")
                ? columnsItems.map((column) => {
                      return <Typography key={column.id} size='xs' className='listed' message={column.label} />;
                  })
                : exportItem?.columns.map((columnId: any) => {
                      const format = columnsItems.find((option) => option.id === columnId);
                      return format ? (
                          <Typography key={columnId} size='xs' className='listed' message={format.label} />
                      ) : null;
                  });
        }
    }, [exportItem?.columns, columnsItems]);

    const formatComponents = useMemo(() => {
        if (exportItem?.formats) {
            return exportItem?.formats.includes("all")
                ? exportFormatOptions.map((option) => (
                      <Typography key={option.id} size='xs' className='listed' message={option.label} />
                  ))
                : exportItem?.formats.map((typeId: any) => {
                      const format = exportFormatOptions.find((option) => option.id === typeId);
                      return format ? (
                          <Typography key={typeId} size='xs' className='listed' message={format.label} />
                      ) : null;
                  });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportItem?.formats, exportFormatOptions]);

    const renderDateFormat = () => {
        if (moment(exportItem?.minDate).isValid() && moment(exportItem?.maxDate).isValid()) {
            return `${moment(exportItem?.minDate).format("DD/MM/YYYY")} - ${moment(exportItem?.maxDate).format(
                "DD/MM/YYYY"
            )}`;
        } else if (moment(exportItem?.minDate).isValid()) {
            return moment(exportItem?.minDate).format("DD/MM/YYYY");
        } else if (moment(exportItem?.maxDate).isValid()) {
            return moment(exportItem?.maxDate).format("DD/MM/YYYY");
        } else {
            return t("CommonUse.all");
        }
    };

    return (
        <Drawer
            isOpen={isOpen}
            onClose={handleOpenInformationsDrawer}
            header={{ title: t("Export.export-details"), image: "arrow-down-on-square" }}
            body={
                <div className='export-informations-drawer-body'>
                    <div className='export-informations-item'>
                        <div className='export-informations-item-header'>
                            <Icon size='sm' name='information-circle' color='lilas' />
                            <Typography message={t("CommonUse.details")} className='fw-bold' />
                        </div>
                        <div className='export-informations-item-list'>
                            <Typography
                                size='xs'
                                className='listed'
                                message={t("Export.name", { value: exportItem?.name })}
                            />
                            <Typography
                                size='xs'
                                className='listed'
                                message={t("Export.export-date", {
                                    value: moment(exportItem?.createdAt).format("DD/MM/YYYY HH:mm"),
                                })}
                            />
                            <Typography
                                size='xs'
                                className='listed'
                                message={t("Export.export-type", {
                                    value: exportItem?.isBankStatement
                                        ? t("Export.bank-statement")
                                        : t("Export.custom"),
                                })}
                            />

                            {exportItem?.frequency && (
                                <Typography
                                    size='xs'
                                    className='listed'
                                    message={t("Export.export-frequency", {
                                        value: exportFrequecies.find((type) => type.id === exportItem?.frequency)
                                            ?.label,
                                    })}
                                />
                            )}
                            <Typography
                                size='xs'
                                className='listed'
                                message={t("Export.exported-by", { value: exportItem?.createdBy })}
                            />
                        </div>
                    </div>

                    <div className='export-informations-item'>
                        <div className='export-informations-item-header'>
                            <Icon size='sm' name='calendar' color='lilas' />
                            <Typography message={t("Export.export-date-range")} className='fw-bold' />
                        </div>
                        <div className='export-informations-item-list'>
                            <Typography size='xs' className='listed' message={renderDateFormat()} />
                        </div>
                    </div>

                    {exportItem?.walletIds && (
                        <div className='export-informations-item'>
                            <div className='export-informations-item-header'>
                                <Icon size='sm' name='wallet' color='lilas' />
                                <Typography message={t("Export.exported-accounts")} className='fw-bold' />
                            </div>
                            <div className='export-informations-item-list'>{walletIdComponents}</div>
                        </div>
                    )}

                    {exportItem?.taskIds && (
                        <div className='export-informations-item'>
                            <div className='export-informations-item-header'>
                                <Icon size='sm' name='wallet' color='lilas' />
                                <Typography message={t("Export.exported-tasks")} className='fw-bold' />
                            </div>
                            <div className='export-informations-item-list'>{tasksComponents}</div>
                        </div>
                    )}

                    {exportItem?.transactionStatus && (
                        <div className='export-informations-item'>
                            <div className='export-informations-item-header'>
                                <Icon size='sm' name='clock' color='lilas' />
                                <Typography message={t("Tasks.transaction-status")} className='fw-bold' />
                            </div>
                            <div className='export-informations-item-list'>{transactionStatusComponents}</div>
                        </div>
                    )}

                    {exportItem?.transactionTypes && (
                        <div className='export-informations-item'>
                            <div className='export-informations-item-header'>
                                <Icon size='sm' name='arrows-right-left' color='lilas' />
                                <Typography message={t("Export.transaction-types")} className='fw-bold' />
                            </div>
                            <div className='export-informations-item-list'>{transactionTypeComponents}</div>
                        </div>
                    )}

                    {exportItem?.columns && (
                        <div className='export-informations-item'>
                            <div className='export-informations-item-header'>
                                <Icon size='sm' name='table-cell' color='lilas' />
                                <Typography message={t("Export.export-columns")} className='fw-bold' />
                            </div>
                            <div className='export-informations-item-list'>{columnComponents}</div>
                        </div>
                    )}

                    {exportItem?.formats && (
                        <div className='export-informations-item'>
                            <div className='export-informations-item-header'>
                                <Icon size='sm' name='gear' color='lilas' />
                                <Typography message={t("Export.export-formats")} className='fw-bold' />
                            </div>
                            <div className='export-informations-item-list'>{formatComponents}</div>
                        </div>
                    )}

                    {exportItem?.recipients.length > 0 && (
                        <div className='export-informations-item'>
                            <div className='export-informations-item-header'>
                                <Icon size='sm' name='user-group' color='lilas' />
                                <Typography message={t("CommonUse.email")} className='fw-bold' />
                            </div>
                            <div className='export-informations-item-list'>
                                {exportItem?.recipients.map((email: string) => {
                                    return <Typography key={email} size='xs' className='listed' message={email} />;
                                })}
                            </div>
                        </div>
                    )}
                </div>
            }
            footer={{
                primaryButton: {
                    disabled: exportItem?.status !== "downloadable",
                    label: t("Export.download-again"),
                    onClick: () => ctaButtons("download", exportItem.exportUrl),
                },
                secondaryButton: {
                    label: t("Export.reuse-filters"),
                    onClick: () => ctaButtons("reuse"),
                },
            }}
        />
    );
};
