import { ColumnItems } from "../../const";
import { ExportFormSelection } from "../shared/FormSelection";
import { ExportPersonalisedCreation } from "./Personalised";
import moment from "moment";
import { sendExport, sendExportTemplate } from "src/services/export/operations";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";
import { billingtransactionStatusesFilterOptions, transactionTypesFilterOptions } from "src/shared/const/transactions";
import { ExportFormData, ExportFormType, ExportTemplate, RegularExportTemplate } from "src/shared/models/Export";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getWalletsAsSelectOptions } from "src/modules/wallets/selectors";
import { fetchWalletsAuthUser } from "src/modules/wallets/thunkActions";
import { WalletTransactionsStateFilters } from "src/modules/walletsTransactions/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

const successContentImage = require("src/shared/images/user/success.png");

type CreateBodyDrawerProps = {
    isOpen: boolean;
    handleOpenCreateDrawer: () => void;
    preSelectedFilters?: WalletTransactionsStateFilters;
    preSelectedWalletId?: any;
    preSelectedTemplate?: ExportTemplate;
    refreshList?: () => void;
};

export const CreateExportDrawer = ({
    isOpen,
    handleOpenCreateDrawer,
    preSelectedFilters,
    preSelectedWalletId,
    preSelectedTemplate,
    refreshList,
}: CreateBodyDrawerProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [selectedFormType, setSelectedFormType] = useState<ExportFormType | undefined>(undefined);

    const [isFormValidated, setIsFormValidated] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [isOpenPasswordModal, setIsOpenPasswordModal] = useState<boolean>(false);
    const [createExportError, setCreateExportError] = useState();
    const [createExportSuccess, setCreateExportSuccess] = useState(false);
    const [formData, setFormData] = useState({});
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);
    const walletOptions = useAppSelector((state) => getWalletsAsSelectOptions(state));
    const [selectedTemplate, setSelectedTemplate] = useState<ExportTemplate>();
    const [formDataFromFilter, setFormDataFromFilter] = useState<ExportFormData | undefined>(undefined);

    useEffect(() => {
        setSelectedFormType(undefined);
    }, [isOpen]);

    const handleSelectTemplate = (template?: ExportTemplate) => {
        setSelectedFormType("personalised");
        setSelectedTemplate(template);
    };

    const handleSelectFormType = (formType?: ExportFormType) => {
        setSelectedFormType(formType);
        setSelectedTemplate(undefined);
    };

    useEffect(() => {
        if (!walletOptions.length) {
            dispatch(fetchWalletsAuthUser());
        }
    }, []);

    const handleFormDataChange = useCallback((data: ExportFormData) => {
        setFormData((prevFormData) => {
            if (JSON.stringify(prevFormData) !== JSON.stringify(data)) {
                return { ...prevFormData, ...data };
            }
            return prevFormData;
        });
    }, []);

    useEffect(() => {
        if (preSelectedFilters && preSelectedWalletId && isOpen) {
            const formData = {
                name: "",
                walletIds: [preSelectedWalletId],
                columns: [],
                transactionStatus: preSelectedFilters.status
                    ? preSelectedFilters.status.map((option) => option.id)
                    : billingtransactionStatusesFilterOptions(t).map((option) => option.id),
                transactionTypes: preSelectedFilters.transactionType
                    ? preSelectedFilters.transactionType.map((option) => option.id)
                    : transactionTypesFilterOptions.map((option) => option.id),
                minDate: preSelectedFilters.startDate
                    ? moment(preSelectedFilters.startDate).format("YYYY-MM-DD")
                    : undefined,
                maxDate: preSelectedFilters.endDate
                    ? moment(preSelectedFilters.endDate).format("YYYY-MM-DD")
                    : undefined,
                taskIds: preSelectedFilters.tasks ? preSelectedFilters.tasks.map((option) => option.id) : [],
                isBankStatement: selectedFormType === "wallet",
            };

            handleSelectFormType("personalised");
            setFormDataFromFilter(formData);
        }
    }, [preSelectedFilters, preSelectedWalletId, isOpen]);

    useEffect(() => {
        if (preSelectedTemplate && isOpen) {
            const transactionTypes = preSelectedTemplate.transactionTypes?.includes("all")
                ? transactionTypesFilterOptions.map((option) => option.id)
                : preSelectedTemplate.transactionTypes;
            const transactionStatus = preSelectedTemplate.transactionStatus?.includes("all")
                ? billingtransactionStatusesFilterOptions(t).map((option) => option.id)
                : preSelectedTemplate.transactionStatus;
            const columns = preSelectedTemplate.columns?.includes("all")
                ? ColumnItems(t).map((column: { id: string; label: string }) => column.id)
                : preSelectedTemplate.columns;
            const taskIds: string[] = [];

            const formData = {
                ...preSelectedTemplate,
                name: preSelectedTemplate.name,
                transactionTypes: transactionTypes,
                transactionStatus: transactionStatus,
                columns: columns,
                taskIds,
                isBankStatement: selectedFormType === "wallet",
            };
            handleSelectTemplate(formData as ExportTemplate);
        }
    }, [preSelectedTemplate, isOpen, t]);

    const handleTogglePasswordModal = () => {
        setIsOpenPasswordModal(!isOpenPasswordModal);
        setHidden(!hidden);
    };

    const handleCancelButton = () => {
        handleOpenCreateDrawer();
    };

    const handleConfirmPasswordModal = async ({
        password,
        twoFactor,
    }: {
        password: string;
        twoFactor: string | null;
    }) => {
        setCreateExportError(undefined);
        setCreateExportSuccess(false);
        formValidation(false);
        if (formData) {
            await sendExport({ data: formData as ExportTemplate | ExportFormData, password, twoFactor })
                .then(async () => {
                    if (saveAsTemplate) {
                        const formDataForTemplate = {
                            ...formData,
                            type: selectedFormType === "personalised" ? "regular" : "bank_statement",
                        };
                        await sendExportTemplate({
                            data: formDataForTemplate as RegularExportTemplate,
                            password,
                            twoFactor,
                        }).then(() => {});
                    }
                })
                .then(() => {
                    setCreateExportSuccess(true);
                    if (refreshList) {
                        refreshList();
                    }
                    handleOpenCreateDrawer();
                })
                .catch((error: any) => setCreateExportError(error.message));
        }
    };

    const formValidation = (validated: boolean) => {
        setIsFormValidated(!validated);
    };

    const handleSaveAsTemplate = (save: boolean) => {
        setSaveAsTemplate(save);
    };

    return (
        <>
            <Drawer
                isHidden={hidden}
                isOpen={isOpen}
                onClose={handleOpenCreateDrawer}
                header={{
                    title: t("Export.export"),
                    image: "arrow-down-on-square",
                }}
                body={
                    <div className='create-export-template-drawer-body-container'>
                        <ExportFormSelection
                            mode='regular'
                            selectedFormType={selectedFormType}
                            handleSelectedFormType={handleSelectFormType}
                            handleSelectedTemplate={handleSelectTemplate}
                        />
                        {selectedFormType && (
                            <ExportPersonalisedCreation
                                formType={selectedFormType}
                                handleFormDataChange={handleFormDataChange}
                                template={selectedTemplate}
                                preSelectedFilters={formDataFromFilter}
                                sendFormValidation={formValidation}
                                handleSaveAsTemplate={handleSaveAsTemplate}
                            />
                        )}
                    </div>
                }
                footer={{
                    primaryButton: {
                        label: t("Export.export"),
                        onClick: handleTogglePasswordModal,
                        disabled: isFormValidated,
                    },
                    secondaryButton: {
                        label: t("CommonUse.cancel"),
                        onClick: handleCancelButton,
                    },
                }}
            />
            <PasswordValidationModal
                header={{
                    title: t("Export.export"),
                    icon: "arrow-down-on-square",
                }}
                successContent={{
                    image: successContentImage,
                    children: (
                        <div className='export-success-content-modal'>
                            <Typography className='fw-bold' message={t("Export.export-success-modal-title")} />
                            <Typography message={t("Export.export-success-modal-subtext")} />
                        </div>
                    ),
                }}
                asyncFromParent
                open={isOpenPasswordModal}
                error={createExportError}
                succeeded={createExportSuccess}
                setOpen={handleTogglePasswordModal}
                handleOnSubmit={handleConfirmPasswordModal}
            />
        </>
    );
};
