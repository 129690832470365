import { useNavigate } from "react-router-dom";
import { TaskListApiReturnItem } from "src/services/tasks/types";

import { useTranslation } from "react-i18next";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

type Props = {
    task: TaskListApiReturnItem;
};

export function TasksDetailsDrawerTransactionStatus({ task }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { status, metadata, walletId, id } = task;

    const handleRedirect = () => {
        if (status === "draft" || status === "to-validate") {
            return;
        }
        navigate(`/history/wallet/${walletId}/task/${id}`);
    };

    return (
        <div className='task-details-drawer drawer-content-section task-details-transaction-status'>
            <Accordion
                icon='list-bullet'
                preSelectedOpen
                iconSize='sm'
                iconColor='white'
                title={t("Tasks.transaction-status")}
                color='white'
                disabled={["draft", "to-validate"].includes(status ?? "")}
            >
                <div className='task-details-body'>
                    <div className='task-details-body-item'>
                        <Icon color='success' name='check-circle' />
                        <Typography className='drawer-section-label ml-2' message={t("Tasks.success")} />
                        <Typography className='drawer-section-content fw-bold' message={metadata?.nbSuccess} />
                    </div>
                    <div className='task-details-body-item'>
                        <Icon color='warning' name='clock' />
                        <Typography className='drawer-section-label ml-2' message={t("Tasks.pending")} />
                        <Typography className='drawer-section-content fw-bold' message={metadata?.nbPending} />
                    </div>
                    <div className='task-details-body-item'>
                        <Icon color='error' name='x-circle' />
                        <Typography className='drawer-section-label ml-2' message={t("Tasks.failed-to-retry")} />
                        <Typography className='drawer-section-content fw-bold' message={metadata?.nbFailed} />
                    </div>
                </div>
                {!["draft", "to-validate", "sending"].includes(status ?? "") && (
                    <div className='transaction-button-link'>
                        <Button
                            onClick={handleRedirect}
                            icon='list-bullet'
                            variant='tertiary'
                            label={t("Transactions.list")}
                        />
                    </div>
                )}
            </Accordion>
        </div>
    );
}
