import { BillingGetPaidDrawerSectionDetailsContainer } from "./Sections/Details/Container";
import { BillingGetPaidDrawerSectionDisable } from "./Sections/Disable";
import { BillingGetPaidDrawerSectionInvoiceReceipt } from "./Sections/InvoiceReceipt";
import { PasswordValidationModal } from "src/shared/common/Password/Modal";

import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useUpdateBillerProfile } from "src/modules/billing/enterprise/hooks";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

import "./styles.scss";

type Props = {
    open: boolean;
    handleCloseDrawer: () => void;
};

export function HistoryWalletDrawerContainer({ open, handleCloseDrawer }: Readonly<Props>) {
    const { t } = useTranslation();

    const { edit, payload, setEdit, handleSetPayload, handleUpdateBiller, handleCloseDialog } =
        useUpdateBillerProfile();

    useEffect(() => {
        return () => {
            setEdit(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <>
            <Drawer
                isOpen={open}
                isHidden={!!payload}
                onClose={handleCloseDrawer}
                header={{
                    title: t("Bills.biller-profile"),
                    image: "kiosk",
                }}
                body={
                    <>
                        <BillingGetPaidDrawerSectionInvoiceReceipt
                            edit={edit}
                            setEdit={setEdit}
                            handleSetPayload={handleSetPayload}
                        />
                        <BillingGetPaidDrawerSectionDetailsContainer
                            edit={edit}
                            setEdit={setEdit}
                            handleSetPayload={handleSetPayload}
                        />
                        <BillingGetPaidDrawerSectionDisable edit={edit} handleSetPayload={handleSetPayload} />
                    </>
                }
            />
            <PasswordValidationModal open={!!payload} setOpen={handleCloseDialog} handleOnSubmit={handleUpdateBiller} />
        </>
    );
}
