import { AdminAccountsFormsProfile, ProfileParams } from "../../Forms/Profile/Profile";
import classnames from "classnames";
import { CreateUserContext } from "src/shared/context/createUser";

import { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { fetchUserDocument } from "src/modules/documents/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

export const AdminUserCreateDrawerSectionsProfile = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { step, userValues, setUserValues, handleValidatedStep } = useContext(CreateUserContext);
    const { userDetail } = useAppSelector(getUserDetail);

    useEffect(() => {
        if (!userDetail && userValues?.countryOfCitizenship) {
            dispatch(fetchUserDocument({ countryOfCitizenship: userValues.countryOfCitizenship }));
        }
        // eslint-disable-next-line
    }, [userValues?.countryOfCitizenship]);

    const handleValidatedProfile = (validated: boolean) => {
        handleValidatedStep(validated, 2);
    };

    const handleChangeValue = (values: ProfileParams) => {
        setUserValues({ ...userValues, ...values });
    };

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("CommonUse.personal-information")} />
                <Typography
                    message={step > 1 ? t("CommonUse.done") : t("CommonUse.to-complete")}
                    className={classnames("fw-bold", step > 1 ? "color-success" : "color-warning-500")}
                />
            </div>
            <div className='drawer-content-section-body'>
                <AdminAccountsFormsProfile
                    detail={!userDetail?.isAlreadyMember ? userDetail : null}
                    onChange={handleChangeValue}
                    handleValidatedProfile={handleValidatedProfile}
                />
            </div>
        </div>
    );
};
