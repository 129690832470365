import {
    failedRequestFavoriteBillers,
    receiveCompanies,
    receiveFavoriteBillers,
    requestCompanies,
    requestFavoriteBillers,
} from "./slice";
import {
    requestAddBillerAsFavorite,
    requestCompanyFavoriteBillers,
    requestRemoveBillerAsFavorite,
} from "src/services/billing/biller/operations";
import { requestAuthUserCompanies } from "src/services/companies/operations";
import { getInStorage } from "src/shared/utils/storage";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCompaniesAuthUser = createAsyncThunk(
    "fetchCompaniesAuthUser",
    async (_props: undefined, { dispatch }) => {
        dispatch(requestCompanies());

        try {
            const companies = await requestAuthUserCompanies();
            dispatch(receiveCompanies({ companies }));
        } catch (error: any) {
            throw error;
        }
    }
);

export const addBillerToFavorite = createAsyncThunk(
    "addBillerToFavorite",
    async ({ billerReference }: { billerReference: string | number }, { dispatch }) => {
        try {
            await requestAddBillerAsFavorite({ billerReference });
            const res = dispatch(getCompanyFavoriteBillers());
            return res;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
);

export const removeBillerFromFavorite = createAsyncThunk(
    "removeBillerFromFavorite",
    async ({ billerReference }: { billerReference: string | number }, { dispatch }) => {
        try {
            await requestRemoveBillerAsFavorite({ billerReference });
            const res = dispatch(getCompanyFavoriteBillers());
            return res;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
);

export const getCompanyFavoriteBillers = createAsyncThunk(
    "getCompanyFavoriteBillers",
    async (_props: undefined, { dispatch }) => {
        try {
            dispatch(requestFavoriteBillers());
            const currentCompanyId = getInStorage("currentCompanyId");
            const { favoriteBillers } = await requestCompanyFavoriteBillers();
            dispatch(receiveFavoriteBillers({ favoriteBillers, companyId: currentCompanyId }));
            return favoriteBillers;
        } catch (error: any) {
            dispatch(failedRequestFavoriteBillers());
            return Promise.reject(error);
        }
    }
);
