import moment from "moment";
import { LoanModel } from "src/shared/models/Loan";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { StatusIcon } from "src/shared/atoms/Icons/StatusIcons";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Accordion } from "src/shared/components/Accordion/Accordion";

import "./styles.scss";

type LoanDetailsDrawerOperationDetailsProps = {
    currentLoan: LoanModel;
};

export const LoanDetailsDrawerOperationDetails = ({ currentLoan }: LoanDetailsDrawerOperationDetailsProps) => {
    const { t } = useTranslation();

    return (
        <div className='operation-details-container'>
            <Accordion title={t("CommonUse.operation-details")} preSelectedOpen={true}>
                <div className='operation-details-content'>
                    {/* <div className='content-item'>
                    <Typography message="Opération sur" />
                    <Typography message="Compte principal - WAL-0VKQI2BHC8" />
                </div> */}

                    <div className='content-item'>
                        <Typography message={t("Transactions.status")} />
                        <div className='status-icon-container'>
                            <StatusIcon
                                status={currentLoan.status.includes("active") ? "pending" : "done-loan"}
                                withMessage={true}
                            />
                        </div>
                    </div>

                    <div className='content-item'>
                        <Typography message={t("Loans.amount")} />
                        <Typography message={`${formatMoneyToString({ amount: currentLoan.amountToDisburse })}`} />
                    </div>

                    <div className='content-item'>
                        <Typography message={t("Loans.disbursment-fees")} />
                        <Typography
                            message={`${formatMoneyToString({
                                amount: currentLoan.feeCharged,
                                zeroValue: true,
                            })}`}
                        />
                    </div>

                    <div className='content-item'>
                        <Typography message={t("Transactions.date")} />
                        <Typography message={moment(currentLoan.createdAt).format("DD/MM/YYYY - HH:mm")} />
                    </div>

                    <div className='content-item'>
                        <Typography message={t("CommonUse.reference")} />
                        <Typography message={currentLoan.uuid} className='color-primary' />
                    </div>
                </div>
            </Accordion>
        </div>
    );
};
