import { ExpensePolicies, ExpensePolicy, ExpensePolicyCreateOrUpdatePayload } from "./types";
import { middlewareRequest } from "src/services/utils/request";

export async function handleRequestAdminExpensePolicies(params: { page: number; query?: string; pageSize: number }) {
    try {
        return await middlewareRequest<{ policies: ExpensePolicies; page: number; totalCount: number }>({
            method: "POST",
            endpoint: "/admin/expense-policy/list",
            params: { ...params },
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function handleRequestAdminExpensePolicy({ expensePolicyId }: { expensePolicyId: string | number }) {
    try {
        return await middlewareRequest<ExpensePolicy>({
            endpoint: `/admin/expense-policy/${expensePolicyId}`,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function createAdminExpensePolicy(
    payload: ExpensePolicyCreateOrUpdatePayload & { password: string; otp?: string }
) {
    try {
        return await middlewareRequest<ExpensePolicy>({
            endpoint: `/admin/expense-policy`,
            method: "POST",
            params: payload,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export type ExpensePolicyUpdateProps = {
    expensePolicyId: string | number;
    payload: ExpensePolicyCreateOrUpdatePayload & { password: string; otp?: string };
};

export async function updateAdminExpensePolicy({ expensePolicyId, payload }: ExpensePolicyUpdateProps) {
    try {
        return await middlewareRequest<ExpensePolicy>({
            endpoint: `/admin/expense-policy/${expensePolicyId}`,
            method: "PUT",
            params: payload,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function deleteAdminExpensePolicy({
    expensePolicyId,
    ...params
}: { expensePolicyId: string | number } & { password: string; otp?: string }) {
    try {
        return await middlewareRequest({
            endpoint: `/admin/expense-policy/${expensePolicyId}`,
            method: "DELETE",
            params,
        });
    } catch (error) {
        return Promise.reject(error);
    }
}
