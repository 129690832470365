import { CreateExportDrawer } from "../Export/Drawers/Export/CreateDrawer";
import { HistoryWalletDrawerContainer } from "./Wallet/Drawer/Container";
import { HistoryWalletFilters } from "./Wallet/Filters";
import { useLocation, useParams } from "react-router-dom";
import { DrawerContext } from "src/wrapper/context";

import { useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useRouterWallet } from "src/modules/wallets/hooks";
import {
    getWalletTransactionFilters,
    getWalletTransactions,
    getWalletTransactionsCount,
    getWalletTransactionsCurrentPage,
    getWalletTransactionsIsActiveFilters,
    getWalletTransactionsLoading,
    getWalletTransactionsQueryFilter,
    getWalletTransactionsShowPageSize,
} from "src/modules/walletsTransactions/selectors";
import { setWalletTransactionCurrentPage, setWalletTransactionPageSize } from "src/modules/walletsTransactions/slice";
import { fetchWalletTransactions } from "src/modules/walletsTransactions/thunkActions";
import { useDebounce } from "src/shared/hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "src/store";

import { DashboardListItem } from "src/components/Dashboard/ListItem";
import { TransactionHistoryTable } from "src/components/History/Table";
import { Button } from "src/shared/atoms/Buttons/Button";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

import "./styles.scss";

export type HistoryWalletStateFilters = {
    status?: string[];
    query?: string | undefined;
    minDate?: string | null;
    maxDate?: string | null;
    page: number;
    transactionType?: number[] | undefined;
};

type RouteParams = {
    walletId: string;
    taskId?: string;
};

type LocationState = { taskUuid?: string };

export const WalletHistoryPanel = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { setOpen, setProvidedState } = useContext(DrawerContext);

    const state = useLocation().state as LocationState;
    const wallet = useRouterWallet();
    const { walletId, taskId } = useParams<keyof RouteParams>() as RouteParams;

    const [selectedTransactionUuid, setSelectedTransactionUuid] = useState<string | null>(null);
    const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

    const filters = useAppSelector((state) => getWalletTransactionFilters(state));
    const isLoading = useAppSelector((state) => getWalletTransactionsLoading(state, { walletId }));
    const totalCount = useAppSelector((state) => getWalletTransactionsCount(state, { walletId }));
    const transactions = useAppSelector((state) => getWalletTransactions(state, { walletId }));
    const activeFilters = useAppSelector((state) => getWalletTransactionsIsActiveFilters(state));
    const currentPage = useAppSelector((state) => getWalletTransactionsCurrentPage(state, { walletId }));
    const pageSize = useAppSelector((state) => getWalletTransactionsShowPageSize(state, { walletId }));
    const query = useAppSelector((state) => getWalletTransactionsQueryFilter(state));

    const debouncedQuery = useDebounce(query, 700);

    useEffect(() => {
        const taskUuid = state?.taskUuid;
        if (transactions && !isLoading && taskUuid) {
            const openDrawer = transactions?.find((transaction) => transaction.uuid === taskUuid);
            if (openDrawer) {
                setSelectedTransactionUuid(taskUuid);
            }
        }
    }, [isLoading, state, transactions]);

    const handleOpenDrawer = (transactionId: string | null) => () => {
        setSelectedTransactionUuid(transactionId);
    };

    const handleCloseDrawer = () => {
        setSelectedTransactionUuid(null);
    };

    useEffect(() => {
        if (!isLoading) {
            handleFetchWalletTransactions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, pageSize, walletId, debouncedQuery]);

    const handleFetchWalletTransactions = () => {
        dispatch(
            fetchWalletTransactions({
                walletId,
                filters: { ...filters, ...(taskId ? { tasks: [{ id: taskId, label: "" }] } : {}) },
                page: currentPage,
                pageSize: pageSize,
            })
        );
    };

    const handleRedirectToTopUp = () => {
        setOpen("topUp");
        setProvidedState({ walletId: walletId, sendStateOnRedirection: true });
    };

    const handleOpenCreateDrawer = () => {
        setCreateDrawerOpen(!createDrawerOpen);
    };

    const handleSetCurrentPage = (newPage: number) => {
        dispatch(setWalletTransactionCurrentPage({ walletId, currentPage: newPage }));
    };

    const handleSetPageSize = (newPageSize: number) => {
        dispatch(setWalletTransactionPageSize({ walletId, pageSize: newPageSize }));
    };

    return (
        <div className='transaction-history-panel'>
            <HistoryWalletDrawerContainer
                walletId={walletId}
                selectedTransactionUuid={selectedTransactionUuid}
                handleCloseDrawer={handleCloseDrawer}
            />
            <div className='wallet-panel-container'>
                <PageHeader>
                    <div className='wallet-panel-page-header'>
                        <div className='history-list-item'>
                            <DashboardListItem icon='wallet' wallet={wallet} />
                        </div>
                        <div className='wallet-panel-header-container'>
                            <HistoryWalletFilters taskId={taskId} walletId={walletId} />
                            <div className='button-container'>
                                <Button
                                    disabled={!wallet?.canTopUp || !wallet}
                                    onClick={handleRedirectToTopUp}
                                    icon='wallet'
                                    label={t("Transactions.top-up-wallet")}
                                    variant='secondary'
                                />
                                <Button
                                    onClick={handleOpenCreateDrawer}
                                    icon='arrow-down-on-square'
                                    label={t("Export.export-view")}
                                    variant='secondary'
                                />
                            </div>
                        </div>
                    </div>
                </PageHeader>
                <TransactionHistoryTable
                    transactions={transactions}
                    handleOpenDrawer={handleOpenDrawer}
                    currentPage={currentPage}
                    showPageSize={pageSize}
                    totalCount={totalCount}
                    setCurrentPage={handleSetCurrentPage}
                    setPageSize={handleSetPageSize}
                    handleFetchWalletTransactions={handleFetchWalletTransactions}
                    activeFilters={activeFilters}
                    isLoading={isLoading}
                />
                <CreateExportDrawer
                    handleOpenCreateDrawer={handleOpenCreateDrawer}
                    isOpen={createDrawerOpen}
                    preSelectedFilters={filters}
                    preSelectedWalletId={walletId}
                />
            </div>
        </div>
    );
};
