import { formatMoney } from "src/shared/utils/formatMoney";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUserExpensePolicyByWallet } from "src/modules/auth/selectors";
import { useWalletDetails } from "src/modules/wallets/hooks";
import { useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";

import "./styles.scss";

type Props = {
    walletId?: number;
};

export function TransferAlert({ walletId }: Readonly<Props>) {
    const { t } = useTranslation();

    const wallet = useWalletDetails(walletId);
    const expensePolicy = useAppSelector((state) => getAuthUserExpensePolicyByWallet(state, { walletId: walletId }));

    const expenseWalletData = useMemo(
        () =>
            expensePolicy && walletId
                ? expensePolicy.spentMoneyPerWallet.find((item) => item.walletId === walletId)
                : null,
        [expensePolicy, walletId]
    );

    const spentMoney = expenseWalletData?.spentMoney ?? 0;
    const remainingBalance = expensePolicy ? expensePolicy?.limitPerUnitOfTime - spentMoney : null;

    if (wallet?.canSendWoLimits) {
        return null;
    } else if (wallet?.canInit) {
        if (!expensePolicy) {
            return (
                <Alert
                    color='warning'
                    message={t("Expense-policy.alert-find-transfer-in-task-page-title")}
                    subMessage={t<string>("Expense-policy.alert-find-transfer-in-task-page-description")}
                />
            );
        } else if (remainingBalance) {
            return (
                <Alert
                    color='warning'
                    message={t("Expense-policy.transfer-without-approbation-title")}
                    subMessage={t<string>(`Expense-policy.transfer-without-approbation-description`, {
                        amount: formatMoney(expensePolicy.limitPerOperation),
                        limit: formatMoney(expensePolicy.limitPerUnitOfTime),
                        unitOfTime: t(`CommonUse.${expensePolicy.unitOfTime}`),
                    })}
                />
            );
        }
        return (
            <Alert
                color='warning'
                message={t("Expense-policy.limit-without-approval-exceeded")}
                subMessage={t<string>("Expense-policy.alert-transfer-as-a-task-description")}
            />
        );
    } else if (remainingBalance && expensePolicy) {
        return (
            <Alert
                color='warning'
                message={t("Expense-policy.transfer-without-approbation-title")}
                subMessage={t<string>(`Expense-policy.transfer-without-approbation-not-signatory-user`, {
                    amount: formatMoney(expensePolicy.limitPerOperation),
                    limit: formatMoney(expensePolicy.limitPerUnitOfTime),
                    unitOfTime: t(`CommonUse.${expensePolicy.unitOfTime}`),
                })}
            />
        );
    } else if (!remainingBalance && expensePolicy) {
        return (
            <Alert
                color='error'
                message={t("Expense-policy.transfer-impossible")}
                subMessage={t<string>("Expense-policy.expense-policy-limit-reach")}
            />
        );
    }

    return null;
}
