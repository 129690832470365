import { TasksTableColumn } from "./TableColumn";
import { TaskStatus } from "src/shared/models/Task";

import { useTranslation } from "react-i18next";

import { getTasksIsLoading } from "src/modules/tasks/selectors";
import { useAppSelector } from "src/store";

import { Spinner } from "src/shared/atoms/Spinner/Spinner";

const taskStatusTypes: {
    [status: string]: {
        slug: string;
        title: string;
        status: TaskStatus[];
    };
} = {
    draft: {
        slug: "draft",
        title: "Tasks.draft",
        status: ["draft"],
    },
    "to-validate": {
        slug: "to-validate",
        title: "TasksTableContainer.en-attente-de-signature-s",
        status: ["to-validate"],
    },
    pending: {
        slug: "pending",
        title: "Tasks.pending",
        status: ["pending", "sending"],
    },
    "to-retry": {
        slug: "to-retry",
        title: "TasksTableContainer.a-rejouer",
        status: ["to-retry"],
    },
    complete: {
        slug: "complete",
        title: "CommonUse.done",
        status: ["complete"],
    },
};

export function TasksTableContainer() {
    const { t } = useTranslation();

    const isLoading = useAppSelector((state) => getTasksIsLoading(state));

    return (
        <>
            {isLoading ? (
                <div className='d-flex w-100 h-100 justify-content-center align-items-center pt-3'>
                    <Spinner size='lg' />
                </div>
            ) : (
                <div className='tasks-table-list-container'>
                    <div className='tasks-table-list'>
                        {Object.values(taskStatusTypes).map((status) => (
                            <TasksTableColumn key={status.slug} title={t(status.title)} status={status.status} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
