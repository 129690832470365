import { REACT_APP_ENV } from "../../services/utils/const";
import { countryCodeCheck } from "src/shared/models/UserCompany";

import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { getAuthCompanies } from "../authCompanies/selectors";

const getAuthState = (state: RootState) => state.auth;

export const getAuthUser = createSelector(getAuthState, (authState) => authState.user);

export const getAuthUserLoading = createSelector(getAuthState, (authState) => authState.loading);

export const getUseBaseRouter = createSelector(getAuthState, (authState) => authState.useBaseRouter);

export const getAuthUserError = createSelector(getAuthState, (authState) => authState?.error);

export const getAuthUserRemainingConnection = createSelector(
    getAuthState,
    (authState) => authState?.remainingConnection ?? 0
);

export const getIsBeninUser = createSelector(getAuthState, (authState) => authState.user?.countryCode === "00229");

export const getUserCountryCode = createSelector(getAuthState, (authState) => authState.user?.countryCode);

export const getUserCountryTag = createSelector(getAuthState, (authState) =>
    authState.user?.countryCode ? countryCodeCheck[authState.user?.countryCode] : null
);

const allowedCompanyArrayAgl = [74]; // TODO: allow transitaire to see AGL entry in menu
export const getIsAglUser = createSelector(getAuthState, (authState) => {
    if (REACT_APP_ENV === "live") {
        return authState.preferences.selectedCompanyId &&
            allowedCompanyArrayAgl.includes(authState.preferences.selectedCompanyId)
            ? true
            : false;
    }
    return true;
});

export const getAuthUserRole = createSelector(getAuthUser, (authUser) => authUser?.role);

export const getAuthUserGeneratedQrCode = createSelector(getAuthState, (state) => state?.qrCode);

export const getAuthUserPreferenceSelectedCompanyId = createSelector(
    getAuthState,
    (authState) => authState.preferences.selectedCompanyId
);

export const getAuthUserPreferenceSelectedCompany = createSelector(
    getAuthUserPreferenceSelectedCompanyId,
    getAuthCompanies,
    (companyId, companies) => companies.find((company) => company.id === companyId)
);

export const getAuthUserPreferencePreferredCompanyId = createSelector(
    getAuthState,
    (authState) => authState.preferences.preferredCompanyId
);

export const getAuthUserExpensePolicyByWallet = createSelector(
    getAuthState,
    getAuthUserPreferenceSelectedCompanyId,
    (_state: RootState, { walletId }: { walletId: number | undefined }) => walletId,
    (authState, companyId, walletId) =>
        walletId
            ? authState.expensePolicies
                  ?.filter((expensePolicy) => expensePolicy.companyId === companyId)
                  ?.find(
                      (expensePolicy) =>
                          !expensePolicy.excludedWallets?.some((excludedWallet) => excludedWallet.id === walletId)
                  )
            : null
);
