import { ExpensePolicies, ExpensePolicy } from "src/services/admin/expensePolicy/types";
import { computeArrayToObject } from "src/shared/utils/computeArrayToObject";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export type AdminExpensePolicyForm = {
    id?: number;
    title?: string;
    allWallets: boolean;
    allUsers: boolean;
    excludedWallets?: SelectOption[];
    excludedUsers?: SelectOption[];
    isForBeneficiariesOnly: boolean;
    limitPerOperation?: number;
    limitPerUnitOfTime?: number;
    unitOfTime?: SelectOption;
};

type AdminExpensePolicyFilters = {
    page: number;
    pageSize: number;
    query: string;
    totalCount: number;
};

interface AdminExpensePoliciesState {
    loading: boolean;
    form: AdminExpensePolicyForm & { loading: boolean; error: string | null };
    filters: AdminExpensePolicyFilters;
    items: { [expensePolicyId: string]: ExpensePolicy };
}

const initialCreateForm: AdminExpensePoliciesState["form"] = {
    allUsers: true,
    allWallets: true,
    isForBeneficiariesOnly: false,
    loading: false,
    error: null,
};

const initialState: AdminExpensePoliciesState = {
    loading: false,
    items: {},
    filters: {
        page: 1,
        pageSize: 20,
        query: "",
        totalCount: 0,
    },
    form: initialCreateForm,
};

export const adminExpensePoliciesSlice = createSlice({
    name: "adminExpensePoliciesSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestAdminExpensePolicies: (state) => {
            state.loading = true;
        },
        receiveAdminExpensePolicy: (state, { payload }: PayloadAction<ExpensePolicy>) => {
            state.items[payload.id] = payload;
            state.loading = false;
        },
        removeAdminExpensePolicy: (state, { payload }: PayloadAction<{ id: string | number }>) => {
            if (payload.id && state.items[payload.id]) {
                delete state.items[payload.id];
            }
        },
        receiveAdminExpensePolicies: (
            state,
            { payload }: PayloadAction<{ policies: ExpensePolicies; page: number; totalCount: number }>
        ) => {
            state.items = computeArrayToObject(payload.policies, (expensePolicy) => String(expensePolicy.id));
            state.filters.totalCount = payload.totalCount;
            state.loading = false;
        },
        failedRequestAdminExpensePolicies: (state) => {
            state.loading = false;
        },

        requestAdminExpensePolicyCreateOrUpdate: (state) => {
            state.form.error = null;
            state.form.loading = true;
        },
        failedAdminExpensePolicyCreateOrUpdate: (state, { payload }: PayloadAction<{ error: string }>) => {
            state.form.error = payload.error;
            state.form.loading = false;
        },

        switchAdminExpensePolicyCreateAllWallets: (state) => {
            state.form.allWallets = !state.form.allWallets;
        },
        switchAdminExpensePolicyCreateAllUsers: (state) => {
            state.form.allUsers = !state.form.allUsers;
        },
        setAdminExpensePolicyCreateExcludedWallets: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyForm["excludedWallets"] }>
        ) => {
            state.form.excludedWallets = payload.value;
        },
        setAdminExpensePolicyCreateExcludedUsers: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyForm["excludedUsers"] }>
        ) => {
            state.form.excludedUsers = payload.value;
        },
        setAdminExpensePolicyCreateLimitPerOperation: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyForm["limitPerOperation"] }>
        ) => {
            state.form.limitPerOperation = payload.value;
        },
        setAdminExpensePolicyCreateLimitPerUnitOfTime: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyForm["limitPerUnitOfTime"] }>
        ) => {
            state.form.limitPerUnitOfTime = payload.value;
        },
        setAdminExpensePolicyCreateUnitOfTime: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyForm["unitOfTime"] }>
        ) => {
            state.form.unitOfTime = payload.value;
        },
        switchAdminExpensePolicyCreateIsForBeneficiariesOnly: (state) => {
            state.form.isForBeneficiariesOnly = !state.form.isForBeneficiariesOnly;
        },
        setAdminExpensePolicyCreateName: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyForm["title"] }>
        ) => {
            state.form.title = payload.value;
        },
        setAdminExpensePolicyCreate: (state, { payload }: PayloadAction<{ value: AdminExpensePolicyForm }>) => {
            state.form = { ...payload.value, loading: false, error: null };
        },
        resetAdminExpensePolicyCreate: (state) => {
            state.form = {
                allUsers: true,
                allWallets: true,
                isForBeneficiariesOnly: false,
                loading: false,
                error: null,
            };
        },
        setAdminExpensePolicyFilterPage: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyFilters["page"] }>
        ) => {
            state.filters.page = payload.value;
        },
        setAdminExpensePolicyPageSize: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyFilters["pageSize"] }>
        ) => {
            state.filters.pageSize = payload.value;
        },
        setAdminExpensePolicyPage: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyFilters["page"] }>
        ) => {
            state.filters.page = payload.value;
        },
        setAdminExpensePolicyFilterQuery: (
            state,
            { payload }: PayloadAction<{ value: AdminExpensePolicyFilters["query"] }>
        ) => {
            state.filters.page = 1;
            state.filters.query = payload.value;
        },
    },
});

export const {
    requestAdminExpensePolicies,
    receiveAdminExpensePolicies,
    failedRequestAdminExpensePolicies,

    requestAdminExpensePolicyCreateOrUpdate,
    receiveAdminExpensePolicy,
    removeAdminExpensePolicy,
    failedAdminExpensePolicyCreateOrUpdate,

    switchAdminExpensePolicyCreateAllWallets,
    switchAdminExpensePolicyCreateAllUsers,
    switchAdminExpensePolicyCreateIsForBeneficiariesOnly,
    setAdminExpensePolicyCreateExcludedWallets,
    setAdminExpensePolicyCreateExcludedUsers,
    setAdminExpensePolicyCreateLimitPerOperation,
    setAdminExpensePolicyCreateLimitPerUnitOfTime,
    setAdminExpensePolicyCreateUnitOfTime,
    setAdminExpensePolicyCreateName,
    setAdminExpensePolicyCreate,
    resetAdminExpensePolicyCreate,

    setAdminExpensePolicyFilterPage,
    setAdminExpensePolicyPageSize,
    setAdminExpensePolicyPage,
    setAdminExpensePolicyFilterQuery,
} = adminExpensePoliciesSlice.actions;
