import { FormContainer } from "../FormContainer";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { useNavigate } from "react-router-dom";
import { requestDeleteAuthUserTwoFactor, requestGenerateAuthUserQrCode } from "src/services/auth/operations";
import { UserAuthApiReturn } from "src/services/auth/types";
import { HandleSubmitPropsPasswordValidation, PasswordValidationModal } from "src/shared/common/Password/Modal";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { setAuthUserGeneratedQrCode, updateAuthUserTwoFactor } from "src/modules/auth/slice";
import { useAppDispatch } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type SecuritySettingsProps = {
    user: UserAuthApiReturn;
    setIsCurrentlyModifying: (isModifying: boolean) => void;
};
export const SecuritySettings = ({ user, setIsCurrentlyModifying }: SecuritySettingsProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [successMessage, setSuccessMessage] = useState<string | null>();
    const [open, setOpen] = useState(false);

    const handleSwitchOpen = () => {
        setOpen((oldOpen) => !oldOpen);
    };

    const handleSubmit = (params: HandleSubmitPropsPasswordValidation) => {
        if (user.tfaActive) {
            return requestDeleteAuthUserTwoFactor(params).then(() =>
                dispatch(updateAuthUserTwoFactor({ tfaActive: false }))
            );
        } else {
            return requestGenerateAuthUserQrCode(params).then((res) => {
                dispatch(setAuthUserGeneratedQrCode(res));
                navigate("/settings/2fa");
            });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setSuccessMessage(null);
        }, 5000);
    }, [successMessage]);

    const handleChangeSuccess = (newSuccessMessage: string) => {
        setSuccessMessage(newSuccessMessage);
    };

    return (
        <div className='security-settings-tab'>
            {successMessage ? <Alert color='success' message={successMessage} /> : null}
            <div className='form-contents'>
                <div className='left-content'>
                    <ChangePasswordForm
                        setIsCurrentlyModifying={(isModifying) => setIsCurrentlyModifying(isModifying)}
                    />
                </div>
                <div className='right-content'>
                    <FormContainer
                        icon={"shield-check"}
                        title={t("LoginPanel.two-factor-authentication-title")}
                        subTitle={t<string>("CommonUse.recommended")}
                        children={
                            <div className='twofa-body'>
                                <div className='text-part'>
                                    <Typography className='fw-bold' message={t("Profile.2fa-recommended")} />
                                    <Typography className='color-neutral-400' message={t("Profile.2fa-description")} />
                                </div>
                                {user.tfaActive && (
                                    <Tag
                                        label={t<string>("CommonUse.strong-auth-activated")}
                                        icon='check-circle'
                                        color='success'
                                        type='accent'
                                        size='sm'
                                    />
                                )}
                            </div>
                        }
                        buttons={{
                            primary: {
                                onClick: handleSwitchOpen,
                                label: user.tfaActive ? t("Profile.deactivate-2fa") : t("Profile.activate-2fa-action"),
                                testId: "two-factor-button",
                            },
                        }}
                    />
                </div>
            </div>
            <PasswordValidationModal
                header={{
                    title: t("LoginPanel.two-factor-authentication-title"),
                    icon: "shield-check",
                }}
                handleOnSubmit={handleSubmit}
                setOpen={handleSwitchOpen}
                open={open}
                onSuccess={() => handleChangeSuccess(t("Tasks.success"))}
            />
        </div>
    );
};
