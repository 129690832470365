import { HistoryWalletDrawerBodyProps } from "../Body";
import { useNavigate } from "react-router-dom";
import { topupErrorReasons } from "src/shared/const/transactions";
import { TransactionContext } from "src/shared/context/transaction";
import { CompanyRole } from "src/shared/models/UserCompany";

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { useIsUserAuthorized } from "src/shared/hooks/useIsUserAuthorized";

import { Alert } from "src/shared/atoms/Alert/Alert";

export const HistoryWalletDrawerTopupRetry = ({
    handleOpenPasswordDialog,
}: Pick<HistoryWalletDrawerBodyProps, "handleOpenPasswordDialog">) => {
    const { t } = useTranslation();
    const transaction = useContext(TransactionContext);
    const { partnerComment, isCancelled } = transaction;
    const isUserUnauthorized = useIsUserAuthorized({ allowedRoles: [CompanyRole.READ] });
    const navigate = useNavigate();

    const handleButtonArchive = () => {
        handleOpenPasswordDialog({ action: "archive", data: {} });
    };

    const redirectToBankDisposal = () => {
        navigate("/transfer/bank-disposal");
    };
    return transaction.status === "failed" && transaction.TransactionType.way === "in" && !isCancelled ? (
        <Alert
            topBottom
            icon='x-circle'
            color='error'
            message={partnerComment ? t("TopupErrorReasons.topup-fail") : t("TopupErrorReasons.topup-fail-no-reason")}
            subMessage={partnerComment ? t<string>(topupErrorReasons[partnerComment]) : undefined}
            buttons={[
                {
                    disabled: isUserUnauthorized,
                    label: t("Transactions.Archive"),
                    variant: "tertiary",
                    className: "flex-grow-1",
                    onClick: handleButtonArchive,
                },
                {
                    disabled: isUserUnauthorized,
                    label: t("TopupErrorReasons.new-topup"),
                    color: "error",
                    onClick: redirectToBankDisposal,
                },
            ]}
        />
    ) : null;
};
