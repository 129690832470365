import { RechargementHelperDrawerBody } from "./Body";

import { Dispatch, SetStateAction } from "react";

import { useTranslation } from "react-i18next";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";

type Props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

export const RechargementHelperDrawerContainer = ({ open, setOpen }: Props) => {
    const { t } = useTranslation();

    const handleSwitchOpenDialog = () => {
        setOpen((open) => !open);
    };

    return (
        <Drawer
            isOpen={open}
            onClose={handleSwitchOpenDialog}
            header={{
                title: t("RechargementPanel.how-to-do-a-top-up"),
                image: "bulb",
            }}
            body={<RechargementHelperDrawerBody />}
        />
    );
};
