import jsPDF from "jspdf";
import { WalletApi } from "src/services/wallets/types";
import { BankData, countryCodeBank } from "src/shared/const/countryPhonePrefixes";
import { generateIbanHtml } from "src/utils/pdfGeneration/iban";
import { DrawerContext } from "src/wrapper/context";

import { useContext, useState } from "react";

import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

import { getAuthUser, getAuthUserPreferenceSelectedCompany } from "src/modules/auth/selectors";
import { getWallet } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

import { julayaFontBase64 } from "src/shared/styles/fonts/julaya-fonticon-v2/julaya-font-base-64";

const clipboardTranslations: { [key: string]: string } = {
    beneficiary: "BankDisposal.account-holder",
    iban: "TopUp.iban",
    bic: "TopUp.bic",
    beneficiaryName: "Iban.domiciliation",
};

const generateClipboardText = (
    t: TFunction<"translation", string, "translation">,
    wallet: WalletApi,
    companyName: string,
    bankData: BankData
) => {
    const data = {
        ...(companyName ? { beneficiary: companyName } : {}),
        ...(wallet.iban ? { iban: wallet.iban } : {}),
        ...(bankData?.bic ? { bic: bankData.bic } : {}),
        ...(bankData?.bankName ? { beneficiaryName: t(bankData.bankName) } : {}),
    };

    return Object.entries(data)
        .map(([key, value]) => `${t(clipboardTranslations[key])} : ${value}`)
        .join("\n");
};

export function IbanDrawerSectionRib() {
    const { t } = useTranslation();
    const { providedState } = useContext(DrawerContext);

    const [confirmCopy, setConfirmCopy] = useState(false);

    const companyName = useAppSelector((state) => getAuthUserPreferenceSelectedCompany(state)?.name ?? "");
    const wallet = useAppSelector((state) =>
        getWallet(state, { walletId: providedState?.walletId ? parseInt(providedState?.walletId) : undefined })
    );
    const authUser = useAppSelector((state) => getAuthUser(state));

    const handleConfirmCopy = () => {
        setConfirmCopy(true);

        setTimeout(() => {
            setConfirmCopy(false);
        }, 2000);
    };

    const handleCopyToCLipboard = () => {
        if (wallet) {
            navigator.clipboard.writeText(
                generateClipboardText(t, wallet, companyName, countryCodeBank[authUser?.countryCode ?? ""] ?? "-")
            );
            handleConfirmCopy();
        }
    };

    const handleGeneratePdf = () => {
        if (wallet && authUser) {
            const html = generateIbanHtml({ t, companyName, wallet, countryCode: authUser.countryCode });
            if (html) {
                const doc = new jsPDF("p", "pt", "a4");
                doc.addFileToVFS("julayaFont.ttf", julayaFontBase64);
                doc.addFont("julayaFont.ttf", "Inter", "normal");
                doc.setFont("Inter");
                const pdf = doc.html(html);
                pdf.save("iban.pdf");
            }
        }
    };

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <div className='d-flex align-items-center'>
                    <Icon name='bank' variant='squared' color='lilas' size='lg' />
                    <Typography className='ml-2' message={t("Iban.iban-section-title")} />
                </div>
            </div>
            <div className='drawer-content-section-body'>
                <Button
                    className='w-100'
                    icon='square-2-stack'
                    label={t("Iban.copy-iban")}
                    onClick={handleCopyToCLipboard}
                />
                {confirmCopy && (
                    <div className='d-flex mt-3 w-100 justify-content-center'>
                        <Icon name='check-circle-filled' color='success' size='sm' />
                        <Typography message={t("Profile.link-copied")} className='fw-bold color-success' />
                    </div>
                )}
                <Button
                    className='w-100 mt-3'
                    icon='document'
                    label={t("Iban.generate-rib")}
                    onClick={handleGeneratePdf}
                />
            </div>
        </div>
    );
}
