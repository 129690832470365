import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminDocumentsPanel } from "src/components/Admin/Documents/Panel";

const AdminDocumentsUnauthorized = () => {
    return <AdminDocumentsPanel />;
};

export const AdminDocuments = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminDocumentsUnauthorized
);
