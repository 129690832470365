import { LoanDetailsDrawerOperationDetails } from "./OperationDetails/OperationDetails";
import { LoanDetailsDrawerTimeTable } from "./TimeTable/TimeTable";

type LoanDetailsDrawerBodyProps = {
    currentLoan: any;
    historyTransactions?: any[];
    disburseTransactions?: any[];
};
export const LoanDetailsDrawerBody = ({ currentLoan, historyTransactions }: LoanDetailsDrawerBodyProps) => {
    return (
        <>
            <LoanDetailsDrawerTimeTable currentLoan={currentLoan} historyTransactions={historyTransactions} />
            <LoanDetailsDrawerOperationDetails currentLoan={currentLoan} />
            {/* <LoanDetailsDrawerCommentary disburseTransactions={disburseTransactions} /> */}
            {/* <LoanDetailsDrawerDocuments currentLoan={currentLoan} /> */}
        </>
    );
};
