import classNames from "classnames";

import { SyntheticEvent } from "react";

import { Icon, IconProps } from "src/shared/atoms/Icons/Icon";

import "./styles.scss";

type Props = {
    classname?: string;
    handleOnClick?: (e: SyntheticEvent) => void;
    disabled?: boolean;
    color?: "grey" | "grey-dark" | "red";
    iconProps: IconProps;
    testId?: string;
};

export function IconButton({ classname, color = "grey", handleOnClick, disabled, iconProps, testId }: Readonly<Props>) {
    return (
        <button
            className={classNames(`icon-button color-${color}`, {
                classname,
                disabled,
            })}
            onClick={disabled ? undefined : handleOnClick}
            disabled={disabled}
            data-testid={testId}
        >
            <Icon {...iconProps} />
        </button>
    );
}
