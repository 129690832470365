import { ExportTemplateFormType, RegularFormTypeInfo, TemplateFormTypeInfo } from "src/shared/models/Export";

import { TFunction } from "i18next";

import { IconListType } from "src/shared/atoms/Icons/IconList";
import { TagColor } from "src/shared/atoms/Tag/Tag";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export const ExportFrequencies = (t: TFunction<"translation", string, "translation">) => [
    { label: t("Export.daily"), id: "daily" },
    { label: t("Export.weekly"), id: "weekly" },
    { label: t("Export.monthly"), id: "monthly" },
];

export const ExportTypesNames = (t: TFunction<"translation", string, "translation">) => [
    { label: t("Export.regular"), id: "regular" },
    { label: t("Export.recurrent"), id: "recurrent" },
    { label: t("Export.bank-statement"), id: "bank_statement" },
    { label: t("Export.personalized"), id: "personalised" },
    { label: t("Wallets.wallet"), id: "wallet" },
];

export const exportTypeTags: {
    [key in ExportTemplateFormType]: {
        label: string;
        icon: IconListType;
        color: TagColor;
    };
} = {
    recurrent: {
        label: "CommonUse.scheduled-export",
        icon: "clock",
        color: "lilas",
    },
    bank_statement: {
        label: "CommonUse.bank-statement",
        icon: "bank",
        color: "success",
    },
    regular: {
        label: "Export.personalised",
        icon: "pencil-square",
        color: "lilas",
    },
};

export const ColumnItems = (t: TFunction<"translation", string, "translation">) => [
    { id: "uuid", label: t("CommonUse.identifier") },
    { id: "type", label: t("CommonUse.type") },
    { id: "credit", label: t("Export.credit") },
    { id: "debit", label: t("Export.debit") },
    { id: "date", label: t("Export.date-hour") },
    { id: "comment", label: t("CommonUse.comment") },
    { id: "status", label: t("CommonUse.status") },
    { id: "operatorId", label: t("Export.operator-id") },
    { id: "beforeBalance", label: t("Export.previous-balance") },
    { id: "afterBalance", label: t("Tasks.new-balance") },
    { id: "initAt", label: t("Tasks.created-at") },
    { id: "serviceName", label: t("Transactions.service") },
    { id: "sender", label: t("Export.sender") },
    { id: "receiverInfo", label: t("Export.firstname-lastname") },
    { id: "destination", label: t("Transactions.receiver") },
    { id: "customReference", label: t("Users.user-reference") },
    { id: "countryName", label: t("CommonUse.country") },
    { id: "signatures", label: t("Tasks.signatories") },
    { id: "signaturesDate", label: t("Export.sign-date") },
    { id: "details", label: t("CommonUse.details") },
];

export const exportFormats: { [format: string]: SelectOption } = {
    csv: { id: "csv", label: "CSV" },
    qif: { id: "qif", label: "QIF" },
    xlsx: { id: "xlsx", label: "Excel (.xlsx)" },
};
export const exportFormatOptions: SelectOption[] = Object.values(exportFormats);

export const regularFormItems: RegularFormTypeInfo[] = [
    {
        type: "personalised",
        iconName: "pencil-square",
        messages: {
            title: "Export.personalised",
            description: "Export.personalised-msg",
        },
    },
    {
        type: "wallet",
        iconName: "wallet",
        messages: {
            title: "Export.bank-statement-pdf",
            description: "Export.recomended",
        },
    },
];

export const templateFormItems: TemplateFormTypeInfo[] = [
    {
        type: "regular",
        iconName: "document",
        messages: {
            title: "Export.export-template",
            description: "Export.export-template-msg",
        },
    },
    {
        type: "recurrent",
        iconName: "clock",
        messages: {
            title: "Export.scheduled-template",
            description: "Export.scheduled-template-msg",
        },
    },
    {
        type: "bank_statement",
        iconName: "bank",
        messages: {
            title: "Export.scheduled-bank-statement",
            description: "Export.scheduled-bank-statement-msg",
        },
    },
];
export const ExportTypeAsOptions = (t: TFunction<"translation", string, "translation">) => [
    {
        id: "custom",
        label: t("Export.personalized"),
    },
    {
        id: "scheduled",
        label: t("Export.scheduled"),
    },
    {
        id: "bankStatement",
        label: t("Export.bank-statement"),
    },
];
