import { sendAdminDocuments } from "src/services/admin/documents/operations";
import { RequestAdminDocumentReturn } from "src/services/admin/documents/types";
import { DocumentModel, DocumentStatusKey } from "src/shared/models/Document";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAdminDocumentsLoading } from "src/modules/admin/documents/selectors";
import { fetchAdminDocuments } from "src/modules/admin/documents/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { Icon, IconStatus } from "src/shared/atoms/Icons/Icon";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { FileUploaderDragnDrop } from "src/shared/components/FileUploader/FileUploaderDragNDrop";
import { IconButton } from "src/shared/components/IconButton/IconButton";

type IconData = { icon: IconListType; status: IconStatus };

const documentStatusIcon: { [key in DocumentStatusKey]: IconData } = {
    waiting: { icon: "document", status: "warning" },
    missing: { icon: "question-mark-circle", status: "warning" },
    done: { icon: "document", status: "success" },
    failed: { icon: "document", status: "error" },
};

const noDocumentIcon: IconData = {
    icon: "arrow-right-circle",
    status: "primary",
};

const acceptedFiles = ["image/*", "application/pdf"];

const templates: { [key: string]: { name: string; url: string } } = {
    fatca: { name: "AdminDocumentsItem.fatca-template", url: "/models/FATCA-template.pdf" },
    bic: { name: "AdminDocumentsItem.bic-template", url: "/models/BIC-template.pdf" },
};

type AdminDocumentsItemProps = {
    document: DocumentModel;
};

export function AdminDocumentsItem({ document }: AdminDocumentsItemProps) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [documentFile, setDocumentFile] = useState<{
        file?: File;
        slug: keyof RequestAdminDocumentReturn;
        recto?: File;
        verso?: File;
        group?: string;
    } | null>();
    const iconData = document.hasDocument ? documentStatusIcon[document.status] : noDocumentIcon;

    const [openViewDrawer, setOpenViewDrawer] = useState(false);
    const [viewDrawerImage, setViewDrawerImage] = useState<string | undefined>();
    const [viewDrawerPdf, setViewDrawerPdf] = useState<string | undefined>();
    const [viewDrawerName, setViewDrawerName] = useState<string | undefined>();
    const [isLoadingUploading, setIsLoadingUploading] = useState(false);
    const isFetchingDocuments = useAppSelector((state) => getAdminDocumentsLoading(state));

    const [displayFailed, setDisplayFailed] = useState(document.status === "failed");

    const template = templates[document.slug];

    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (document.pages === 1) {
            setIsValidated(!!documentFile?.file);
        } else {
            setIsValidated(!!documentFile?.recto && !!documentFile?.verso);
        }
    }, [documentFile, document.pages]);

    const handleRetrieveFile = (type?: "recto" | "verso") => (file: File) => {
        setDocumentFile(
            type
                ? {
                      ...documentFile,
                      slug: document.slug,
                      [type]: file,
                      group: document.group,
                  }
                : {
                      file,
                      slug: document.slug,
                      group: document.group,
                  }
        );
    };

    const handleDeleteFile = (type?: "recto" | "verso") => () => {
        if (type) {
            const { [type]: todelete, ...toKeep } = documentFile as {
                recto: File;
                verso: File;
                slug: keyof RequestAdminDocumentReturn;
            };
            void todelete;

            setDocumentFile(toKeep);
        } else {
            setDocumentFile(null);
        }
    };

    const handleSendDocument = () => {
        setIsLoadingUploading(true);
        if (documentFile) {
            sendAdminDocuments(documentFile).then(() => {
                setIsLoadingUploading(false);
                dispatch(fetchAdminDocuments());
            });
        }
    };

    const handleOpenViewDrawer = (url: string) => () => {
        const extension = url.split(".").pop()?.toLowerCase();
        const name = url.split("/")[url.split("/").length - 1];

        setViewDrawerName(name);
        if (extension) {
            if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
                setViewDrawerImage(url);
            } else if (extension === "pdf") {
                setViewDrawerPdf(url);
            }
        }

        setOpenViewDrawer(true);
    };

    const handleCloseViewDrawer = () => {
        setViewDrawerImage(undefined);
        setViewDrawerPdf(undefined);
        setOpenViewDrawer(false);
    };

    const handleSetDisplayFailed = () => {
        setDisplayFailed(false);
    };

    return (
        <div key={document.id} className='admin-documents-item'>
            <div className='item-container'>
                <div className='item-header'>
                    <Typography className='fw-bold' message={t(`AdminDocumentsItem.${document.slug}`)} />
                    {document.comment && displayFailed && (
                        <Typography
                            message={t("AdminDocumentsItem.failed-reason-message", { reason: document.comment })}
                            size='xs'
                            className='color-neutral-500'
                        />
                    )}
                </div>
                {document.images?.map((url: string) => {
                    return (document.status === "failed" && displayFailed) || document.status !== "failed" ? (
                        <div className='item-content'>
                            <div className='left-content'>
                                <Avatar
                                    icon={iconData.icon}
                                    color={iconData.status === "primary" ? "lilas-900" : iconData.status + "-500"}
                                    backgroundColor={
                                        iconData.status === "primary"
                                            ? "gradiant-pink-horizontal-2"
                                            : iconData.status + "-50"
                                    }
                                    size='sm'
                                />
                                <Typography message={url.substring(url.lastIndexOf("/") + 1)} />
                            </div>
                            <div className='right-content'>
                                <IconButton
                                    iconProps={{ name: "eye", size: "sm" }}
                                    color='grey-dark'
                                    handleOnClick={handleOpenViewDrawer(url)}
                                />
                                {document.status === "failed" && (
                                    <IconButton
                                        iconProps={{ name: "thrash", size: "sm" }}
                                        color='grey-dark'
                                        handleOnClick={handleSetDisplayFailed}
                                    />
                                )}
                            </div>
                        </div>
                    ) : null;
                })}
            </div>
            {(!document.hasDocument || (document.status === "failed" && !displayFailed)) && (
                <>
                    <div className='document-file-uploader'>
                        {document.pages === 1 ? (
                            <FileUploaderDragnDrop
                                file={documentFile && documentFile.file}
                                handleOnSubmit={handleRetrieveFile()}
                                handleDelete={handleDeleteFile()}
                                acceptedFiles={acceptedFiles}
                                preview
                            />
                        ) : (
                            <>
                                <FileUploaderDragnDrop
                                    title={t<string>("AdminDocumentsItem.recto")}
                                    file={documentFile && documentFile.recto}
                                    handleOnSubmit={handleRetrieveFile("recto")}
                                    handleDelete={handleDeleteFile("recto")}
                                    acceptedFiles={acceptedFiles}
                                    preview
                                />

                                <FileUploaderDragnDrop
                                    title={t<string>("AdminDocumentsItem.verso")}
                                    file={documentFile && documentFile.verso}
                                    handleOnSubmit={handleRetrieveFile("verso")}
                                    handleDelete={handleDeleteFile("verso")}
                                    acceptedFiles={acceptedFiles}
                                    preview
                                />
                            </>
                        )}
                        {template && (
                            <div className='alert-document-container'>
                                <div className='first-part'>
                                    <Icon name='exclamation-circle' color='lilas' />
                                    <Typography
                                        message={t("AdminDocumentsItem.download-and-fill")}
                                        className='fw-bold'
                                    />
                                </div>
                                <a
                                    href={template.url}
                                    download={t(template.name)}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='btn btn-secondary text-nowrap'
                                >
                                    <Button label={t("AdminDocumentsItem.download-form")} />
                                </a>
                            </div>
                        )}
                    </div>
                    <div className='d-flex w-100 justify-content-end'>
                        <Button
                            disabled={!isValidated || isLoadingUploading || isFetchingDocuments}
                            label={t("CommonUse.send")}
                            onClick={handleSendDocument}
                            loading={isLoadingUploading || (documentFile?.file && isFetchingDocuments)}
                        />
                    </div>
                </>
            )}
            <Drawer
                isOpen={openViewDrawer}
                onClose={handleCloseViewDrawer}
                header={{ image: "document", title: viewDrawerName ?? "" }}
                body={
                    <>
                        {viewDrawerImage && (
                            <div className='preview-img' style={{ backgroundImage: `url(${viewDrawerImage})` }} />
                        )}
                        {viewDrawerPdf && <iframe className='iframe-preview' src={viewDrawerPdf} />}
                    </>
                }
            />
        </div>
    );
}
