import { NotificationSetting } from "./Notifications/NotificationSettings";
import { ProfileSettings } from "./Profile/ProfileSettings";
import { SecuritySettings } from "./Security/SecuritySettings";
import { useLocation } from "react-router-dom";

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getAuthUser } from "src/modules/auth/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { TabOption, Tabs } from "src/shared/atoms/Tabs/Tabs";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Modal } from "src/shared/components/Modal/Modal";

import "./styles.scss";

type LocationState = {
    currentTab?: PROFILE_TAB;
};

export enum PROFILE_TAB {
    PROFILE = "profile",
    SECURITY = "security",
    NOTIFICATIONS = "notifications",
}

export const UserSettings = () => {
    const { t } = useTranslation();
    const authUser = useAppSelector((state) => getAuthUser(state));
    const locationState = useLocation().state as LocationState;
    const [isCurrentlyModifying, setIsCurrentlyModifying] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [pendingTab, setPendingTab] = useState<TabOption | null>(null);

    const notificationTabs = useMemo<TabOption[]>(
        () => [
            { icon: "user-circle", label: t("Profile.profile-settings"), value: PROFILE_TAB.PROFILE },
            {
                icon: "shield-check",
                label: t("Profile.security-settings"),
                value: PROFILE_TAB.SECURITY,
            },
            { icon: "bell", label: t("Profile.notif-settings"), value: PROFILE_TAB.NOTIFICATIONS },
        ],
        [t]
    );

    const findInitialTab = (): TabOption => {
        const currentTab = locationState?.currentTab ?? PROFILE_TAB.PROFILE;
        const foundTab = notificationTabs.find((tab) => tab.value === currentTab && !tab.disabled);
        return foundTab || notificationTabs.find((tab) => !tab.disabled) || notificationTabs[0];
    };

    const [selectedTab, setSelectedTab] = useState<TabOption>(findInitialTab());

    useEffect(() => {
        if (selectedTab.disabled) {
            setSelectedTab(notificationTabs.find((tab) => !tab.disabled) || notificationTabs[0]);
        }
    }, [selectedTab, notificationTabs]);

    const handleChangeTab = (tabOption: TabOption) => {
        if (tabOption !== selectedTab) {
            if (isCurrentlyModifying) {
                setPendingTab(tabOption);
                handleOpenConfirmModal();
            } else {
                setSelectedTab(tabOption);
            }
        }
    };

    const confirmTabChange = () => {
        if (pendingTab) {
            setSelectedTab(pendingTab);
            setPendingTab(null);
            handleSetIsCurrentlyModifying(false);
        }
        setOpenConfirmModal(false);
    };

    const cancelTabChange = () => {
        setPendingTab(null);
        setOpenConfirmModal(false);
    };

    const handleSetIsCurrentlyModifying = (isModifying: boolean) => {
        setIsCurrentlyModifying(isModifying);
    };

    const handleOpenConfirmModal = () => {
        setOpenConfirmModal(!openConfirmModal);
    };

    return (
        <div className='profile-page-container'>
            <div className='tabs-container'>
                <Tabs
                    options={notificationTabs}
                    selected={selectedTab}
                    onChange={handleChangeTab}
                    variant='secondary'
                />
            </div>
            <div className='user-settings-content-container'>
                {authUser && selectedTab.value === PROFILE_TAB.PROFILE && (
                    <ProfileSettings user={authUser} setIsCurrentlyModifying={handleSetIsCurrentlyModifying} />
                )}
                {authUser && selectedTab.value === PROFILE_TAB.SECURITY && (
                    <SecuritySettings user={authUser} setIsCurrentlyModifying={handleSetIsCurrentlyModifying} />
                )}
                {authUser && selectedTab.value === PROFILE_TAB.NOTIFICATIONS && (
                    <NotificationSetting user={authUser} setIsCurrentlyModifying={handleSetIsCurrentlyModifying} />
                )}
            </div>
            <Modal
                header={{
                    icon: "pencil-square",
                    title: t("CommonUse.edit"),
                }}
                children={
                    <div className='edit-modal-content'>
                        <Icon name='exclamation-circle' size='sm' color='lilas' />
                        <Typography message={t("UserSettings.modal-confirmation-edit")} />
                    </div>
                }
                open={openConfirmModal}
                cancelLabel={t<string>("UserSettings.cancel-edit")}
                confirmLabel={t<string>("UserSettings.continue-edit")}
                handleOnCancel={confirmTabChange}
                handleOnSubmit={cancelTabChange}
            />
        </div>
    );
};
