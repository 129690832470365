import { IconButton } from "../IconButton/IconButton";
import { FileUploaderDragNDropProps, FileUploaderDragnDrop } from "./FileUploaderDragNDrop";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { ProgressBar } from "src/shared/atoms/ProgressBar/ProgressBar";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

export type FileUploaderFiles = {
    image: string;
    name: string;
    message?: string;
    id: string;
    fileType: "attachment" | "receipt";
    error?: boolean;
};

type FileDataWithoutId = Omit<FileUploaderFiles, "id">;

export type LoadingFileUploader = FileDataWithoutId & { error?: boolean; loading: boolean; file: File };

type Props = {
    fileUploaderProps: Exclude<FileUploaderDragNDropProps, "disabled">;
    files: FileUploaderFiles[];
    disabled?: boolean;
    loadingFiles?: LoadingFileUploader[];
    deletingFiles?: { [fileId: string]: boolean };
    variant?: "white" | "grey" | "transparent";
    deleteFile?: (fileId: string) => void;
    cancelLoading?: (file: File) => void;
    retryUpload?: (file: File) => void;
};

export function FileUploaderDragnDropWithFiles({
    fileUploaderProps,
    loadingFiles,
    files,
    deletingFiles = {},
    disabled,
    variant = "grey",
    deleteFile,
    cancelLoading,
    retryUpload,
}: Props) {
    const handleRetryUpload = (file: File) => () => {
        if (retryUpload) {
            retryUpload(file);
        }
    };

    const handleCancelLoading = (file: File) => () => {
        if (cancelLoading) {
            cancelLoading(file);
        }
    };

    const handleDeleteFile = (fileId: string) => () => {
        if (deleteFile) {
            deleteFile(fileId);
        }
    };

    const isLoading = loadingFiles?.some((file) => file.loading);

    return (
        <>
            <div className='file-uploader-with-files'>
                {files.map((file, index) => {
                    return (
                        <div key={file.name} className={`section mb-2 variant-${variant}`}>
                            <div
                                key={`receipt-${file.image}`}
                                className='d-flex align-items-center w-100 justify-content-between'
                            >
                                <div className='file-uploader-flex-min-width'>
                                    <img className='image' alt='file' src={file.image} />
                                    <div className='d-flex flex-column overflow-hidden'>
                                        <Typography
                                            className='file-uploader-text-overflow fw-bold mb-1'
                                            message={file.name}
                                        />
                                        {file.message ? (
                                            file.error ? (
                                                <div className={`d-flex align-items-center`}>
                                                    <Icon color={"error"} name='x-circle-filled' />
                                                    <Typography
                                                        size='xs'
                                                        className='color-error-500 ml-1'
                                                        message={file.message}
                                                    />
                                                </div>
                                            ) : (
                                                <Typography
                                                    size='xs'
                                                    className='color-neutral-500'
                                                    message={file.message}
                                                />
                                            )
                                        ) : null}
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <a
                                        className='button-container type-primary'
                                        href={file.image}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <IconButton iconProps={{ name: "eye" }} />
                                    </a>
                                    {deleteFile && !deletingFiles[file.id] ? (
                                        <IconButton
                                            classname='ml-2'
                                            handleOnClick={handleDeleteFile(file.id)}
                                            color='red'
                                            iconProps={{ name: "thrash", color: "error" }}
                                            testId={`delete-button-file-${index}`}
                                        />
                                    ) : (
                                        <Spinner />
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
                {loadingFiles
                    ? loadingFiles.map((loadingFile, index) => (
                          <div key={loadingFile.name} className={`section mb-2 variant-${variant}`}>
                              <div
                                  key={`receipt-${loadingFile.image}`}
                                  className='d-flex align-items-center w-100 justify-content-between'
                              >
                                  <div className='d-flex w-100'>
                                      <img className='image' alt='loading file' src={loadingFile.image} />
                                      <div className='d-flex flex-column w-100'>
                                          <Typography className='fw-bold  mb-1' message={loadingFile.name} />
                                          {loadingFile.loading ? <ProgressBar className='mt-1' /> : null}
                                          {loadingFile.message ? (
                                              loadingFile.error ? (
                                                  <div className={`d-flex align-items-center `}>
                                                      <Icon color={"error"} name='x-circle-filled' />
                                                      <Typography
                                                          size='xs'
                                                          className='color-error-500'
                                                          message={loadingFile.message}
                                                      />
                                                  </div>
                                              ) : (
                                                  <Typography
                                                      size='xs'
                                                      className='color-neutral-500'
                                                      message={loadingFile.message}
                                                  />
                                              )
                                          ) : null}
                                      </div>
                                  </div>
                                  {loadingFile.error && !loadingFile.loading && retryUpload ? (
                                      <div className='ml-2'>
                                          <IconButton
                                              handleOnClick={handleRetryUpload(loadingFile.file)}
                                              iconProps={{ name: "retry", color: "neutral" }}
                                              color='grey-dark'
                                              testId={`retry-button-file-${index}`}
                                          />
                                      </div>
                                  ) : null}
                                  {loadingFile.loading && cancelLoading ? (
                                      <div className='ml-2'>
                                          <IconButton
                                              handleOnClick={handleCancelLoading(loadingFile.file)}
                                              iconProps={{ name: "x-mark", color: "neutral" }}
                                              color='grey-dark'
                                              testId={`cancel-button-file-${index}`}
                                          />
                                      </div>
                                  ) : null}
                              </div>
                          </div>
                      ))
                    : null}
            </div>
            <FileUploaderDragnDrop {...fileUploaderProps} loading={isLoading} disabled={disabled} />
        </>
    );
}
