import { CompanyRole } from "src/shared/models/UserCompany";
import { withAuthorization } from "src/shared/utils/withAuthorization";

import { AdminDashboardPanel } from "src/components/Admin/Dashboard/Panel";

const AdminDashboard = () => {
    return <AdminDashboardPanel />;
};

export const AdminDashboardPage = withAuthorization({ allowedRoles: [CompanyRole.ADMIN, CompanyRole.ADMIN_ONLY] })(
    AdminDashboard
);
