import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "src/store";

const getState = (state: RootState) => state.admin.adminBeneficiariesReducer;

export const getAdminBeneficiaries = createSelector(getState, (state) => ({
    beneficiaries: state.adminBeneficiaries,
    loading: state.loading,
    totalCount: state.count,
    pageSize: state.pageSize,
    totalPage: state.totalPage,
    currentPage: state.page,
    integrateBeneficiaryLoading: state.integrateBeneficiaryLoading,
    integrateBeneficiaryError: state.integrateBeneficiaryError,
    integratedBeneficiaryResponse: state.integratedBeneficiaryResponse,
    deleteBeneficiaryLoading: state.deleteBeneficiaryLoading,
    deleteBeneficiaryError: state.deleteBeneficiaryError,
    deleteBeneficiaryResponse: state.deleteBeneficiaryResponse,
}));
