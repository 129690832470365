import { fetchUsersList } from "./thunkActions";

import { useEffect } from "react";

import { getAccountsAsOptions, getAdminAccountsList } from "./selectors";
import { useAppDispatch, useAppSelector } from "src/store";

export function useAdminAccountsList() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchUsersList({ page: -1, role: ["USER", "ADMIN", "READ"], query: "" }));
    }, [dispatch]);

    return useAppSelector(getAdminAccountsList);
}

export function useAdminAccountsAsSelectOptions(fetch = true) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (fetch) {
            dispatch(fetchUsersList({ page: -1, role: ["USER", "ADMIN", "READ"], query: "" }));
        }
    }, [dispatch, fetch]);

    return useAppSelector(getAccountsAsOptions);
}
