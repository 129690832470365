import { AdminCreateWalletResponse, AdminWalletResponse } from "src/services/admin/types";
import { AdminWalletModel } from "src/shared/models/Wallet";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

interface AdminWalletsState {
    loading: boolean;
    adminWallets: AdminWalletModel[];
    adminWalletsCount: number;
    pageSize: number;
    currentPage: number;
    integratedWallet: AdminWalletModel | null;
    integrateWalletLoading: boolean;
    integrateWalletError: string | null;
}

const initialState = {
    loading: false,
    adminWallets: [],
    adminWalletsCount: 0,
    pageSize: 20,
    currentPage: 1,
    integrateWalletLoading: false,
    integrateWalletError: null,
    integratedWallet: null,
} as AdminWalletsState;

export const adminWalletsSlice = createSlice({
    name: "adminWalletsSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestAdminWallets: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        receiveAdminWallets: (state, { payload }: PayloadAction<AdminWalletResponse>) => {
            return {
                ...state,
                adminWallets: payload.data,
                adminWalletsCount: payload.count,
                loading: false,
            };
        },
        failedRequestAdminWallets: (state, action: PayloadAction<{ message: string; status: number }>) => {
            const error = action.payload.message;
            return {
                ...state,
                integrateWalletError: error,
                loading: false,
            };
        },
        requestIntegrateAdminWallet: (state) => {
            return {
                ...state,
                integrateWalletError: null,
                integrateWalletLoading: true,
            };
        },
        receiveIntegrateAdminWallet: (state, { payload }: PayloadAction<AdminCreateWalletResponse>) => {
            return {
                ...state,
                integratedWallet: payload,
                integrateBeneficiaryError: null,
                integrateWalletLoading: false,
            };
        },

        failedIntegrateAdminWallets: (state, action: PayloadAction<{ message: string; status: number }>) => {
            const error = action.payload.message;
            return {
                ...state,
                integrateWalletError: error,
                integrateWalletLoading: false,
            };
        },
        clearAdminWalletIntegration: (state) => {
            return {
                ...state,
                integrateWalletError: null,
                integratedWallet: null,
                integrateWalletLoading: false,
            };
        },
        setAdminWalletPageSize: (state, { payload }: PayloadAction<{ pageSize: number }>) => {
            const { pageSize } = payload;

            return {
                ...state,
                pageSize,
                currentPage: 1,
            };
        },
        setAdminWalletCurrentPage: (state, { payload }: PayloadAction<{ currentPage: number }>) => {
            const { currentPage } = payload;

            return {
                ...state,
                currentPage,
            };
        },
    },
});

export const {
    requestAdminWallets,
    receiveAdminWallets,
    failedRequestAdminWallets,
    failedIntegrateAdminWallets,
    requestIntegrateAdminWallet,
    receiveIntegrateAdminWallet,
    clearAdminWalletIntegration,
    setAdminWalletCurrentPage,
    setAdminWalletPageSize,
} = adminWalletsSlice.actions;
