import { failedRequestAdminActivityLogs, receiveAdminActivityLogs, requestAdminActivityLogs } from "./slice";
import {
    RequestMiddlewareAdminActivityLogsParams,
    requestMiddlewareAdminActivityLogs,
} from "src/services/admin/activityLogs/operation";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchAdminActivityLog = createAsyncThunk(
    "fetchAdminActivityLog",
    async (params: RequestMiddlewareAdminActivityLogsParams, { dispatch }) => {
        dispatch(requestAdminActivityLogs());
        try {
            const response = await requestMiddlewareAdminActivityLogs(params);
            dispatch(receiveAdminActivityLogs(response));
        } catch (error: any) {
            dispatch(failedRequestAdminActivityLogs());
        }
    }
);
