import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useTranslation } from "react-i18next";

import { getBillingEnterpriseBillsAmount } from "src/modules/billing/enterprise/selectors";
import { useAppSelector } from "src/store";

import { Icon } from "src/shared/atoms/Icons/Icon";
import { Typography } from "src/shared/atoms/Typography/Typography";

export function BillingGetPaidModuleBillAmount() {
    const { t } = useTranslation();

    const billsAmount = useAppSelector((state) => getBillingEnterpriseBillsAmount(state));

    return (
        <div className='bill-module-container'>
            <Icon name='receipt-accepted' />
            <Typography className='color-neutral-500 fw-bold' message={t("Bills.bill-amount-paid")} />
            <Typography
                size='lg'
                className='color-lilas-900 fw-bold'
                message={formatMoneyToString({ amount: billsAmount, zeroValue: true })}
            />
        </div>
    );
}
