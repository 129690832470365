import { Icon } from "../Icons/Icon";
import { Typography } from "../Typography/Typography";

import { HTMLAttributes } from "react";

import "./styles.scss";

export type PaginationDynamoProps = {
    onPageChange: (isNext: boolean) => void;
    hasMoreItems: boolean;
    currentPage: number;
    canGoBack: boolean;
    isLoading?: boolean;
} & HTMLAttributes<HTMLElement>;

export const PaginationDynamo = ({
    onPageChange,
    hasMoreItems,
    currentPage,
    canGoBack,
    isLoading = false,
}: PaginationDynamoProps) => {
    const fetchNextPage = () => {
        if (!isLoading && hasMoreItems) {
            onPageChange(true);
        }
    };

    const fetchPreviousPage = () => {
        if (!isLoading && canGoBack) {
            onPageChange(false);
        }
    };

    return (
        <div className='paginate-dynamo-container' data-testid='pagination-dynamo'>
            <div className='paginate-dynamo-content'>
                <Typography className={"paginate-anotation my-auto"} message={`Page ${currentPage}`} />

                <div
                    className={`paginate-button first ${!canGoBack || isLoading ? "disabled" : ""}`}
                    onClick={fetchPreviousPage}
                    data-testid='previous-page'
                >
                    <Icon name='arrow-left' />
                </div>

                <div
                    className={`paginate-button last ${!hasMoreItems || isLoading ? "disabled" : ""}`}
                    onClick={fetchNextPage}
                    data-testid='next-page'
                >
                    <Icon name='arrow-right' />
                </div>
            </div>
        </div>
    );
};
