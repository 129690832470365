import { Icon } from "../Icons/Icon";
import { IconListType } from "../Icons/IconList";
import { Typography } from "../Typography/Typography";

import "./styles.scss";

export type TabOption = { icon?: IconListType; label: string; value: string; disabled?: boolean };

export type TabsProps = {
    options: TabOption[];
    selected: TabOption;
    onChange: (value: TabOption) => void;
    variant?: "primary" | "secondary";
};

export const Tabs = ({ onChange, options, selected, variant = "primary" }: TabsProps) => {
    const isActive = (tabOption: TabOption) => {
        return selected.value === tabOption.value;
    };

    const handleChangeTab = (tab: TabOption) => () => {
        if (!tab.disabled) {
            onChange(tab);
        }
    };

    return (
        <div className={`tabs-${variant}`}>
            {options.map((option, index) => (
                <div
                    key={option.value}
                    className={`tab-item  ${isActive(option) ? "active" : ""} ${option.disabled ? "disabled" : ""}`}
                    onClick={handleChangeTab(option)}
                    data-testid={"tab-item-" + index}
                >
                    {option.icon && <Icon name={option.icon} />}
                    <Typography message={option.label} />
                </div>
            ))}
        </div>
    );
};
