import { BankDisposalFormType, RECHARGEMENT_STEP } from "../Panel";
import { useDisposalMethodList } from "../const";
import clsx from "classnames";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";

import { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { IconListType } from "src/shared/atoms/Icons/IconList";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { Typography } from "src/shared/atoms/Typography/Typography";

import "./styles.scss";

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
    currentStep: RECHARGEMENT_STEP;
    handleSetNextStep: () => void;
};

export const MethodSelectContainer = ({ form, currentStep, handleSetNextStep }: Props) => {
    const { t } = useTranslation();
    const methodList = useDisposalMethodList();
    const [selectedMethod, setSelectedMethod] = useState(0);

    const onMethodSelect = (index: number) => () => {
        setSelectedMethod(index);
        form.setFieldValue("disposalMethod", methodList[index].slug);
    };

    const handleGlobalKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (currentStep === RECHARGEMENT_STEP.METHOD_SELECT) {
                if (event.key === "Enter") {
                    if (selectedMethod === 0) {
                        onMethodSelect(0)();
                        handleSetNextStep();
                    }
                    if (form.values.disposalMethod) {
                        handleSetNextStep();
                    }
                } else if (event.key === "Tab") {
                    if (currentStep === RECHARGEMENT_STEP.METHOD_SELECT) {
                        event.preventDefault();
                        const nextIndex = selectedMethod === null ? 0 : (selectedMethod + 1) % methodList.length;
                        onMethodSelect(nextIndex)();
                    }
                }
            }
        },
        [selectedMethod, methodList.length, handleSetNextStep]
    );

    useEffect(() => {
        form.setFieldValue("disposalMethod", methodList[selectedMethod].slug);
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleGlobalKeyPress);

        return () => document.removeEventListener("keydown", handleGlobalKeyPress);
    }, [selectedMethod, methodList.length, handleSetNextStep]);

    return (
        <div className='method-select-container'>
            <div className='method-select-title'>
                <Typography
                    message={t("MethodSelectContainer.transferez-des-fonds-sur-votre-compte-julaya-en-utilisant-le")}
                />
                <Alert
                    icon='question-mark-circle'
                    message={t(
                        "MethodSelectContainer.attention-certaines-methodes-peuvent-prendre-plus-ou-moins-de-temps"
                    )}
                    subMessage={t<string>(
                        "MethodSelectContainer.en-effet-les-delais-de-traitement-peuvent-varier-en-fonction"
                    )}
                />
            </div>
            {methodList.map((item, index) => (
                <div
                    key={item.slug}
                    className='method-select-item-container'
                    id='topup_bank-transfer_method'
                    onClick={onMethodSelect(index)}
                >
                    <div className={clsx("method-select-item", { selected: selectedMethod === index })}>
                        <div className='method-select-left-part'>
                            <Avatar icon={item.icon as IconListType} />
                            <div className='text-part'>
                                <Typography message={item.title} className='fw-bold' />
                                <Typography size='xs' message={item.description} />
                            </div>
                        </div>

                        {item.isFaster && (
                            <Tag icon='bolt' label={t<string>("MethodSelectContainer.plus-rapide")} color='neutral' />
                        )}
                    </div>
                    {selectedMethod === index && (
                        <div className='subtext-part'>
                            {item.alert && <Alert icon='bulb' message={item.alert} />}
                            <Typography message={item.details} />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
