import { exportFormatOptions, exportFormats } from "../../const";
import { ExportFormatData, RegularTemplateFormData } from "src/shared/models/Export";

import { useEffect, useState } from "react";

import { t } from "i18next";

import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Checkbox } from "src/shared/atoms/Checkbox/Checkbox";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Input } from "src/shared/atoms/Inputs/Input";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { SelectMultipleOptions, SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

type ExportFormatProps = {
    handleFormDataChanged: (data: ExportFormatData) => void;
    sendIsFormValidated: (validated: boolean) => void;
    forceFormat?: boolean;
    template?: RegularTemplateFormData;
    savable?: boolean;
    handleSetSaveTemplate?: (save: boolean) => void;
};

export const ExportFormat = ({
    handleFormDataChanged,
    sendIsFormValidated,
    forceFormat,
    template,
    savable = false,
    handleSetSaveTemplate,
}: ExportFormatProps) => {
    const [emailList, setEmailList] = useState<string[]>(template?.recipients ? template?.recipients : []);
    const [emailInput, setEmailInput] = useState("");
    const [emailErrors, setEmailErrors] = useState<string | undefined>();
    const [validatedForm, setValidatedForm] = useState(false);
    const [saveTemplate, setSaveTemplate] = useState(false);
    const [selectedExports, setSelectedExports] = useState<SelectOption[] | undefined>([]);

    const isFeatureExportTemplateLimited = useSubscriptionRestrictions(
        SubscriptionRestrictions.FEATURE_EXPORT_TEMPLATE
    );

    useEffect(() => {
        setEmailErrors(undefined);
    }, [emailInput]);

    const handleSetSelectedExports = (newSelectedExports: SelectOption[]) => {
        setSelectedExports(newSelectedExports.length === 0 ? undefined : newSelectedExports);
    };

    const handleRemoveEmail = (emailToRemove: string) => () => {
        setEmailList(emailList.filter((email) => email !== emailToRemove));
    };

    const getFormData = () => {
        const selectedFormatIds = selectedExports?.map((exportFormat) => exportFormat.id) || [];

        return {
            formats: selectedFormatIds.length > 0 ? selectedFormatIds : undefined,
            recipients: emailList,
        };
    };

    useEffect(() => {
        if (template) {
            if (template.formats) {
                let selectedExportsFromTemplate: SelectOption[] | undefined;
                if (template.formats.includes("all")) {
                    selectedExportsFromTemplate = exportFormatOptions;
                } else {
                    selectedExportsFromTemplate = template.formats
                        .map((format) => exportFormats[format])
                        .filter(Boolean);
                }
                setSelectedExports(selectedExportsFromTemplate);
            }
            if (template?.recipients) {
                setEmailList(template.recipients);
            }
        }
    }, [template]);

    useEffect(() => {
        handleFormDataChanged(getFormData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailList, selectedExports]);

    useEffect(() => {
        const isFormatsValid =
            forceFormat || (selectedExports !== null && !!selectedExports && selectedExports.length > 0);

        const isFormValid = isFormatsValid;
        setValidatedForm(isFormValid);
        sendIsFormValidated(isFormValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedExports, forceFormat]);

    useEffect(() => {
        setEmailErrors(undefined);
    }, [emailInput]);

    const handleChangeEmailInput = (value: string) => {
        setEmailInput(value);
    };

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" || event.key === " ") {
            event.preventDefault();
            handleAddEmail();
        }
    };

    const handleAddEmail = () => {
        const trimmedEmail = emailInput.trim();
        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedEmail)) {
            setEmailList([...emailList, trimmedEmail]);
            setEmailInput("");
            setEmailErrors(undefined);
        } else {
            setEmailErrors("Invalid email format");
        }
    };

    const handleSaveTemplateChange = () => {
        setSaveTemplate(!saveTemplate);
    };

    useEffect(() => {
        if (handleSetSaveTemplate) {
            handleSetSaveTemplate(saveTemplate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveTemplate]);

    return (
        <div className='drawer-content-section'>
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("Export.export-settings")} />
                <Typography
                    className={`${!validatedForm ? "color-warning" : "color-success"} fw-bold`}
                    message={!validatedForm ? t("CommonUse.to-complete") : t("CommonUse.done")}
                />
            </div>
            <div className='drawer-content-section-body'>
                <div className='export-format-container'>
                    {!forceFormat && (
                        <SelectMultipleOptions
                            label={t<string>("Export.export-formats")}
                            headerClassname='fw-lighter'
                            items={exportFormatOptions}
                            selected={selectedExports}
                            name={t("Export.select-export-formats")}
                            onSelectionChange={handleSetSelectedExports}
                            allSelectable
                            mandatory
                        />
                    )}
                    {emailList.length > 0 && (
                        <div className='email-list-show'>
                            {emailList.map((email) => (
                                <div key={email} className='item'>
                                    <Typography message={email} />
                                    <Icon
                                        onClick={handleRemoveEmail(email)}
                                        name='x-circle-filled'
                                        className='cursor-pointer'
                                        size='sm'
                                        color='neutral-500'
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    <div className='email-input-container'>
                        <Input
                            placeholder={t("CreateBeneficiaryInformationForm.adresse-mail-facultatif")}
                            label={t<string>("CreateBeneficiaryInformationForm.adresse-mail-facultatif")}
                            value={emailInput}
                            onChange={handleChangeEmailInput}
                            onKeyDown={handleEnterKeyPress}
                            underMessage={emailErrors ? t<string>(emailErrors) : undefined}
                            variant={emailErrors ? "error" : "primary"}
                            className='flex-grow-1'
                        />
                        <Button
                            onClick={handleAddEmail}
                            label={t("Export.add-email")}
                            className={emailErrors ? "error" : ""}
                        />
                    </div>
                    {savable && (
                        <ToolTip
                            position='bottom'
                            content={
                                <div className='tooltip-limited'>
                                    <Typography
                                        message={t("Subscription.not-accessible-feature")}
                                        className='fw-bold'
                                    />
                                    <Typography message={t("Subscription.unlock-unlimited-access")} />
                                </div>
                            }
                            activation={isFeatureExportTemplateLimited}
                        >
                            <Checkbox
                                label={
                                    <div className='checkbox-save-template'>
                                        <Typography className='fw-bold' message={t("Export.save-as-template")} />
                                        <Typography
                                            className='color-neutral-500'
                                            message={t("Export.save-as-template-to-reuse")}
                                        />
                                    </div>
                                }
                                checked={saveTemplate}
                                onChange={handleSaveTemplateChange}
                                disabled={isFeatureExportTemplateLimited}
                            />
                        </ToolTip>
                    )}
                </div>
            </div>
        </div>
    );
};
