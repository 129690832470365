import { BankDisposalFormType } from "../../Panel";
import { BankSelectDrawerBody } from "./Body";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import { TransactionServiceSlug } from "src/services/transactions/types";
import { ServiceModel } from "src/shared/models/Service";
import { getInStorageUserPreferences } from "src/shared/utils/storage";

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { getBankServicesList } from "src/modules/transactions/selectors";
import { fetchBankServices } from "src/modules/transactions/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { Drawer } from "src/shared/atoms/Drawer/Drawer";
import { DrawerInput } from "src/shared/atoms/Inputs/Drawer/Input";

import "./styles.scss";

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
    forceOpen: boolean;
    setForceOpen: Dispatch<SetStateAction<boolean>>;
};

export const BankSelectDrawerContainer = ({ form, forceOpen, setForceOpen }: Props) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [showDrawer, setShowDrawer] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const openDrawer = () => setShowDrawer(true);
    const closeDrawer = () => setShowDrawer(false);

    const servicesByTypes = useAppSelector((state) => getBankServicesList(state));
    const userPreferredBank = getInStorageUserPreferences("bankDisposalPreferredBank");

    useEffect(() => {
        dispatch(
            fetchBankServices()
        );
    }, [dispatch]);

    useEffect(() => {
        if (!form.values.service || forceOpen) {
            setShowDrawer(true);
            setForceOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceOpen]);

    useEffect(() => {
        if (userPreferredBank) {
            setIsLoading(true);
            const serviceFound = servicesByTypes.find((service) => service.slug === userPreferredBank);
            if (serviceFound) {
                onSelectService(serviceFound);
            }
            setIsLoading(false);
        }
    }, [servicesByTypes, userPreferredBank]);

    const onSelectService = (service: ServiceModel) => {
        form.setFieldValue("service", service);
        closeDrawer();
    };

    return (
        <div className='bank-select-drawer'>
            <DrawerInput
                label={!form.values.service ? t<string>("CommonUse.select-account") : form.values.service.title}
                handleClickDrawerInput={openDrawer}
                icon='bank'
                serviceSlug={form.values.service?.slug as TransactionServiceSlug}
                disabled={isLoading}
            />

            <Drawer
                isOpen={showDrawer}
                onClose={closeDrawer}
                header={{
                    title: t("BankDisposal.select-bank"),
                    image: "wallet",
                }}
                body={<BankSelectDrawerBody selectService={onSelectService} />}
            />
        </div>
    );
};
