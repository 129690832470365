import { logoutResetStore } from "../auth/Actions";
import { WalletBeneficiary } from "src/services/wallets/types";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface WalletBeneficiariesState {
    isLoading: boolean;
    beneficiaries: { [walletId: string]: WalletBeneficiary[] };
}

const initialState = {
    isLoading: true,
    beneficiaries: {},
} as WalletBeneficiariesState;

export const walletsBeneficiariesSlice = createSlice({
    name: "walletsBeneficiaries",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestWalletBeneficiaries: (state) => {
            state.isLoading = true;
        },
        failedRequestWalletBeneficiaries: (state) => {
            state.isLoading = false;
        },
        receiveWalletBeneficiaries: (
            state,
            action: PayloadAction<{ walletBeneficiaries: WalletBeneficiary[]; walletId: string | number }>
        ) => {
            return {
                ...state,
                isLoading: false,
                beneficiaries: {
                    ...state.beneficiaries,
                    [action.payload.walletId]: action.payload.walletBeneficiaries,
                },
            };
        },
    },
});

export const { requestWalletBeneficiaries, failedRequestWalletBeneficiaries, receiveWalletBeneficiaries } =
    walletsBeneficiariesSlice.actions;

export const walletsBeneficiariesSliceReducer = walletsBeneficiariesSlice.reducer;
