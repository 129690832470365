import { switchWalletTransactionArchivedFilter } from "./slice";
import { fetchWalletTransactions } from "./thunkActions";
import {
    refundTransactions,
    requestArchiveTransaction,
    requestRetryTransaction,
} from "src/services/transactions/operations";
import { HandleSubmitPropsPasswordValidation } from "src/shared/common/Password/Modal";

import { useState } from "react";

import {
    getWalletTransaction,
    getWalletTransactionFilters,
    getWalletTransactionsCurrentPage,
    getWalletTransactionsShowPageSize,
} from "./selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";

export type TransactionDrawerModalActions = "archive" | "cancel" | "receipts" | "retry";

type Props = {
    walletId: string;
    selectedTransactionUuid: string | null;
    onSuccess: () => void;
};

export const useTransactionStatusChange = ({ walletId, selectedTransactionUuid, onSuccess }: Props) => {
    const dispatch = useAppDispatch();

    const [actionDialog, setActionDialog] = useState<
        { action: TransactionDrawerModalActions; data: { reason?: SelectOption } | undefined } | undefined
    >();
    const [passwordModalError, setPasswordModalError] = useState(null);

    const transaction = useAppSelector((state) =>
        getWalletTransaction(state, { walletId, uuid: selectedTransactionUuid })
    );
    const filters = useAppSelector((state) => getWalletTransactionFilters(state));
    const page = useAppSelector((state) => getWalletTransactionsCurrentPage(state, { walletId }) ?? 1);
    const showPageSize = useAppSelector((state) => getWalletTransactionsShowPageSize(state, { walletId }) ?? 20);

    const handleCloseDialog = () => {
        setActionDialog(undefined);
    };

    const handleConfirm = async (props: HandleSubmitPropsPasswordValidation) => {
        setPasswordModalError(null);

        if (actionDialog && transaction) {
            const { uuid } = transaction;
            const { action, data } = actionDialog;

            if (uuid) {
                switch (action) {
                    case "archive": {
                        await requestArchiveTransaction({
                            uuid,
                            walletId,
                            ...props,
                        })
                            .then((res) => {
                                if (res.ok) {
                                    onSuccess();
                                    handleCloseDialog();
                                    dispatch(switchWalletTransactionArchivedFilter());
                                    dispatch(
                                        fetchWalletTransactions({
                                            walletId,
                                            filters: { ...filters, archived: true },
                                            page,
                                            pageSize: showPageSize,
                                        })
                                    );
                                }
                            })
                            .catch((error) => setPasswordModalError(error.message));
                        break;
                    }
                    case "cancel": {
                        if (data?.reason) {
                            await refundTransactions({
                                walletId,
                                uuids: [uuid],
                                reason: data.reason.id,
                                ...props,
                            })
                                .then(() => {
                                    onSuccess();
                                    handleCloseDialog();
                                    dispatch(
                                        fetchWalletTransactions({ walletId, filters, page, pageSize: showPageSize })
                                    );
                                })
                                .catch((error) => setPasswordModalError(error.message));
                        }
                        break;
                    }
                    case "retry": {
                        await requestRetryTransaction({ walletId, uuids: [uuid], ...props })
                            .then(() => {
                                onSuccess();
                                handleCloseDialog();
                                dispatch(fetchWalletTransactions({ walletId, filters, page, pageSize: showPageSize }));
                            })
                            .catch((error) => setPasswordModalError(error.message));
                    }
                }
            }
        }
        return Promise.reject();
    };

    return { passwordModalError, setPasswordModalError, actionDialog, setActionDialog, handleConfirm };
};
