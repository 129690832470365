import { EditTaskSummaryPanel } from "../Edit/Summary/Panel";
import { SignatureTaskFooterSummary } from "./Footer";

export function TasksSignaturePanel() {
    return (
        <div className='d-flex flex-column h-100'>
            <EditTaskSummaryPanel />
            <SignatureTaskFooterSummary />
        </div>
    );
}
