import { EditTaskInputCell } from "./InputCell";
import { TaskItemTmpModel } from "src/shared/models/TaskItemTmp";
import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { removeExtraSpaces } from "src/shared/utils/removeExtraSpaces";

import { useTranslation } from "react-i18next";

import { fetchTaskToEdit } from "src/modules/tasks/thunkActions";
import { getTaskItemsUpdating } from "src/modules/tasksItems/selectors";
import { deleteTaskItem, handleUpdateTaskItem } from "src/modules/tasksItems/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { TransactionServiceImage } from "src/components/History/Info/ServiceImage";
import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Spinner } from "src/shared/atoms/Spinner/Spinner";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { IconButton } from "src/shared/components/IconButton/IconButton";

import "./styles.scss";

type Props = {
    isDisabled?: boolean;
    taskId: number | undefined;
    taskItem: TaskItemTmpModel;
    handleOpenDrawer: () => void;
    handleCloseDrawer: () => void;
};

export function EditTaskRow({ isDisabled, taskItem, taskId, handleOpenDrawer, handleCloseDrawer }: Props) {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const taskItemsUpdating = useAppSelector((state) => getTaskItemsUpdating(state, { taskId }));

    const disabled = isDisabled || (taskItemsUpdating?.[taskItem.id] ?? false);

    const handleDeleteTaskItem = () => {
        if (taskId) {
            dispatch(deleteTaskItem({ taskId, taskItem })).then(() => dispatch(fetchTaskToEdit({ taskId })));
        }
    };

    const handleUpdateExternalFullname = (newExternalFullname: string) => {
        if (taskId && newExternalFullname !== taskItem.externalFullname) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItem,
                    update: {
                        externalFullname: removeExtraSpaces(newExternalFullname),
                    },
                })
            );
            handleCloseDrawer();
        }
    };

    const handleUpdateComment = (newComment: string) => {
        if (taskId && newComment !== taskItem.comment) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItem,
                    update: {
                        comment: newComment,
                    },
                })
            );
            handleCloseDrawer();
        }
    };

    const handleUpdateAmount = (newAmount: string) => {
        if (taskId && parseInt(newAmount) !== taskItem.amount) {
            dispatch(
                handleUpdateTaskItem({
                    taskId,
                    taskItem: taskItem,
                    update: {
                        amount: parseInt(newAmount),
                    },
                })
            ).then(() => dispatch(fetchTaskToEdit({ taskId })));
            handleCloseDrawer();
        }
    };

    const { errorCode } = taskItem;
    const errorMessage = errorCode
        ? typeof errorCode === "string"
            ? `Api-errors.${errorCode.toLowerCase().replaceAll("_", "-")}`
            : "Api-errors.some-error-occured"
        : null;

    return (
        <>
            <tr className={taskItem.errorCode ? "edit-task-row-error" : "" + "row-container"}>
                <td>
                    <div className='row-item'>
                        <TransactionServiceImage
                            classname='d-flex align-items-center justify-content-center'
                            serviceSlug={taskItem.serviceSlug}
                        />
                        <Typography className='pl-2' message={taskItem.externalReference} />
                        <IconButton
                            disabled={disabled}
                            classname='cursor-pointer ml-2'
                            handleOnClick={disabled ? undefined : handleOpenDrawer}
                            iconProps={{
                                name: "pencil",
                            }}
                        />
                    </div>
                </td>
                <td>
                    <EditTaskInputCell
                        disabled={disabled}
                        initialValue={taskItem.externalFullname ?? ""}
                        onValidate={handleUpdateExternalFullname}
                        type='string'
                    >
                        <Typography
                            message={taskItem.externalFullname ?? ""}
                            className='edit-task-table-cell-content'
                        />
                    </EditTaskInputCell>
                </td>
                <td>
                    <EditTaskInputCell
                        disabled={disabled}
                        initialValue={String(taskItem.amount)}
                        onValidate={handleUpdateAmount}
                        type='number'
                        testId='edit-task-row-amount-input'
                    >
                        <Typography
                            message={formatMoneyToString({ amount: taskItem.amount ?? 0 })}
                            className='edit-task-table-cell-content'
                        />
                    </EditTaskInputCell>
                </td>
                <td>
                    <Typography message={formatMoneyToString({ amount: taskItem.fees })} />
                </td>
                <td>
                    <EditTaskInputCell
                        disabled={disabled}
                        initialValue={taskItem.comment ?? ""}
                        onValidate={handleUpdateComment}
                    >
                        <Typography className='edit-task-table-cell-content' message={taskItem.comment} />
                    </EditTaskInputCell>
                </td>
                <td>
                    <div className='d-flex align-items-start'>
                        {taskItemsUpdating?.[taskItem.id] ? (
                            <Spinner size='xs' />
                        ) : (
                            <Button
                                disabled={disabled}
                                onClick={handleDeleteTaskItem}
                                icon='thrash'
                                variant='tertiary'
                            />
                        )}
                    </div>
                </td>
            </tr>
            {errorMessage ? (
                <tr key={`task-item-${taskItem.id}-error`}>
                    <td colSpan={6} className='p-0'>
                        <Alert icon='information-circle' color='error' message={t(errorMessage)} />
                    </td>
                </tr>
            ) : null}
        </>
    );
}
