import { formatMoneyToString } from "src/shared/utils/formatMoney";
import { expensePolicyUnitOfTimeOptions } from "./const";

import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import {
    getAdminExpensePoliciesCreateLimitPerOperation,
    getAdminExpensePoliciesCreateLimitPerUnitOfTime,
    getAdminExpensePoliciesCreateUnitOfTime,
} from "src/modules/admin/expensePolicies/selectors";
import {
    setAdminExpensePolicyCreateLimitPerOperation,
    setAdminExpensePolicyCreateLimitPerUnitOfTime,
    setAdminExpensePolicyCreateUnitOfTime,
} from "src/modules/admin/expensePolicies/slice";
import { useAppDispatch, useAppSelector } from "src/store";

import { Alert } from "src/shared/atoms/Alert/Alert";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Input } from "src/shared/atoms/Inputs/Input";
import { StepAccordion } from "src/shared/components/Accordion/StepAccordion/StepAccordion";
import { SelectOption } from "src/shared/components/SelectMultipleOptions/SelectMultipleOptions";
import { SimpleSelect } from "src/shared/components/SimpleSelect/SimpleSelect";

type Props = {
    step?: number;
    handleSetStep?: (newStep: number) => () => void;
};

export function AdminExpensePolicyCreateDrawerAmount({ step, handleSetStep }: Props) {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const limitPerOperation = useAppSelector((state) => getAdminExpensePoliciesCreateLimitPerOperation(state));
    const limitPerUnitOfTime = useAppSelector((state) => getAdminExpensePoliciesCreateLimitPerUnitOfTime(state));
    const unitOfTime = useAppSelector((state) => getAdminExpensePoliciesCreateUnitOfTime(state));

    const handleChangeLimitPerOperation = (value: string) => {
        dispatch(setAdminExpensePolicyCreateLimitPerOperation({ value: parseInt(value) }));
    };

    const handleChangeLimitPerUnitOfTime = (value: string) => {
        dispatch(setAdminExpensePolicyCreateLimitPerUnitOfTime({ value: parseInt(value) }));
    };

    const handleChangeUnitOfTime = (value: SelectOption) => {
        dispatch(setAdminExpensePolicyCreateUnitOfTime({ value: value.id === unitOfTime?.id ? undefined : value }));
    };

    useEffect(() => {
        const handleGlobalEnterPress = (event: any) => {
            if (event.key === "Enter" && handleSetStep && step === 2) {
                if (
                    !(
                        !limitPerOperation ||
                        !limitPerUnitOfTime ||
                        !unitOfTime ||
                        limitPerOperation > limitPerUnitOfTime
                    )
                )
                    handleSetStep(3)();
            }
        };

        document.addEventListener("keydown", handleGlobalEnterPress);

        return () => {
            document.removeEventListener("keydown", handleGlobalEnterPress);
        };
    });

    return (
        <div className='expense-policy-amount-step'>
            <StepAccordion
                showSuccess={false}
                showOverflow
                title={t("Expense-policy.add-maximum-amount")}
                subTitle={t<string>(
                    limitPerOperation && limitPerUnitOfTime && unitOfTime
                        ? "Expense-policy.limit-money-of-operation"
                        : "Expense-policy.no-limit-per-operation",
                    {
                        limitPerOperation: formatMoneyToString({ amount: limitPerOperation ?? null }),
                        limitPerUnitOfTime: formatMoneyToString({ amount: limitPerUnitOfTime ?? null }),
                        unitOfTime: t(unitOfTime?.label ?? ""),
                    }
                )}
                icon='arrows-right-left'
                currentStep={handleSetStep ? step : 5}
                onOpen={handleSetStep ? handleSetStep(2) : undefined}
                step={2}
                footer={
                    handleSetStep && (
                        <Button
                            disabled={
                                !limitPerOperation ||
                                !limitPerUnitOfTime ||
                                !unitOfTime ||
                                limitPerOperation > limitPerUnitOfTime
                            }
                            label={t("CommonUse.validate")}
                            onClick={handleSetStep(3)}
                        />
                    )
                }
            >
                <div className='expense-policy-amount-section'>
                    <Input
                        autoFocus
                        inputClassname='background-white'
                        type='number'
                        label={t<string>("Expense-policy.maximum-amount-without-approve")}
                        placeholder={formatMoneyToString({ amount: 0, zeroValue: true })}
                        value={limitPerOperation ?? ""}
                        onChange={handleChangeLimitPerOperation}
                        underMessage={t<string>("Expense-policy.limit-per-transaction")}
                    />
                    <div className='unit-of-time-section'>
                        <Input
                            inputClassname='background-white'
                            type='number'
                            label={t<string>("Expense-policy.within-the-limit-of")}
                            placeholder={formatMoneyToString({ amount: 0, zeroValue: true })}
                            value={limitPerUnitOfTime ?? ""}
                            onChange={handleChangeLimitPerUnitOfTime}
                            underMessage={t<string>("Expense-policy.total-limit")}
                        />
                        <SimpleSelect
                            useRadio
                            headerClassname='background-white'
                            placeholder={t<string>("Expense-policy.choose-frequency")}
                            label={t<string>("Expense-policy.frequency")}
                            selected={unitOfTime}
                            options={expensePolicyUnitOfTimeOptions(t)}
                            onSelectionChange={handleChangeUnitOfTime}
                        />
                    </div>
                    {limitPerOperation !== undefined &&
                        limitPerUnitOfTime !== undefined &&
                        limitPerOperation > limitPerUnitOfTime && (
                            <Alert
                                color='error'
                                icon='x-circle-filled'
                                message={t(
                                    "Expense-policy.limit-per-operation-can-t-be-more-than-limit-per-unit-of-time"
                                )}
                            />
                        )}
                </div>
            </StepAccordion>
        </div>
    );
}
