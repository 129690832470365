import { ActivityActionSlug } from "./types";

import { TFunction } from "i18next";

export const activityLogActionSlugs = (t: TFunction<"translation", string, "translation">) =>
    [
        { id: "deleteTask", label: t("AdminActivity.delete-task") },
        { id: "login", label: t("AdminActivity.connexion") },
        { id: "walletHistory", label: t("Wallets.consult-account") },
        { id: "createExport", label: t("Export.export") },
        { id: "taskSigned", label: t("AdminActivity.sign-task") },
        { id: "addTaskComment", label: t("AdminActivity.add-task-comment") },
        { id: "cancelTask", label: t("AdminActivity.cancel-task") },
        { id: "createBeneficiary", label: t("AdminActivity.create-beneficiary") },
        { id: "addDocument", label: t("AdminActivity.add-document") },
        { id: "createUser", label: t("AdminActivity.create-user") },
        { id: "archiveUser", label: t("AdminActivity.archive-user") },
        { id: "attachReceipt", label: t("AdminActivity.add-receipt") },
        { id: "activate2FA", label: t("AdminActivity.activate-2fa") },
        { id: "createWallet", label: t("AdminActivity.create-wallet") },
        { id: "archiveWallet", label: t("AdminActivity.archive-wallet") },
        { id: "rapidTransfer", label: t("TransfertPanel.transfert-rapide") },
        { id: "topUp", label: t("RechargementPanel.rechargement") },
        { id: "retryRapidTransfer", label: t("AdminActivity.retry-rapid-transfer") },
        { id: "cancelRapidTransfer", label: t("AdminActivity.cancel-rapid-transfer") },
        { id: "taskEdition", label: t("AdminActivity.edit-task") },
        { id: "walletSignatairesModified", label: t("AdminActivity.signataire-order-modified") },
        { id: "editBeneficiary", label: t("AdminActivity.edit-beneficiary") },
        { id: "editUser", label: t("AdminActivity.edit-user") },
        { id: "editRole", label: t("AdminActivity.edit-role") },
        { id: "markTaskComplete", label: t("AdminActivity.task-mark-as-complete") },
        { id: "deleteBeneficiary", label: t("AdminActivity.delete-beneficiary") },
        { id: "delete2FA", label: t("AdminActivity.delete-2fa") },
        {
            id: "updateTemplate",
            label: t("AdminActivity.update-template-label"),
        },
        { id: "createTemplate", label: t("AdminActivity.create-template") },
        { id: "deleteTemplate", label: t("AdminActivity.delete-template") },
    ].sort((a, b) => a.label.localeCompare(b.label));

export const activityLogDescription: { [key in ActivityActionSlug]: string } = {
    login: "AdminActivity.login-description",
    walletHistory: "AdminActivity.wallet-history-description",
    createExport: "AdminActivity.export-description",
    taskSigned: "AdminActivity.task-signed-description",
    addTaskComment: "AdminActivity.create-task-comment-description",
    cancelTask: "AdminActivity.cancel-task-description",
    createBeneficiary: "AdminActivity.create-beneficiary-description",
    addDocument: "AdminActivity.add-document-description",
    createUser: "AdminActivity.create-user-description",
    archiveUser: "AdminActivity.archive-user-description",
    attachReceipt: "AdminActivity.attach-receipt-description",
    activate2FA: "AdminActivity.activate-2fa-description",
    createWallet: "AdminActivity.create-wallet-description",
    archiveWallet: "AdminActivity.archive-wallet-description",
    rapidTransfer: "AdminActivity.rapid-transfer-description",
    topUp: "AdminActivity.top-up-description",
    retryRapidTransfer: "AdminActivity.retry-rapid-transfer-description",
    cancelRapidTransfer: "AdminActivity.cancel-rapid-transfer-description",
    taskEdition: "AdminActivity.task-edition-description",
    walletSignatairesModified: "AdminActivity.wallet-signataires-modified-description",
    editBeneficiary: "AdminActivity.edit-beneficiary-description",
    editUser: "AdminActivity.edit-user-description",
    editRole: "AdminActivity.edit-role-description",
    markTaskComplete: "AdminActivity.mark-task-complete-description",
    deleteBeneficiary: "AdminActivity.delete-beneficiary-description",
    delete2FA: "AdminActivity.delete-2fa-description",
    deleteTask: "AdminActivity.delete-task-description",
    createTemplate: "AdminActivity.template-export",
    updateTemplate: "AdminActivity.update-template-description",
    deleteTemplate: "AdminActivity.delete-template-description",
};
