import { AdminWalletsFilters } from "./Filters";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AdminWalletParams } from "src/services/admin/types";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAdminWallets } from "src/modules/admin/adminWallets/hooks";
import { setAdminWalletCurrentPage, setAdminWalletPageSize } from "src/modules/admin/adminWallets/slice";
import { fetchAdminWallets } from "src/modules/admin/adminWallets/thunkActions";
import { SubscriptionRestrictions, useSubscriptionRestrictions } from "src/shared/hooks/useSubscriptionRestrictions";
import { useAppDispatch } from "src/store";

import { Avatar } from "src/shared/atoms/Avatar/Avatar";
import { Button } from "src/shared/atoms/Buttons/Button";
import { Icon } from "src/shared/atoms/Icons/Icon";
import { Tag } from "src/shared/atoms/Tag/Tag";
import { ToolTip } from "src/shared/atoms/Tooltip/Tooltip";
import { Typography } from "src/shared/atoms/Typography/Typography";
import { Empty } from "src/shared/components/Empty/Empty";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";
import { SelectableTable, SelectableTableRow } from "src/shared/components/SelectableTable/SelectableTable";

import "./styles.scss";

const emptyListImg = require("src/shared/images/empty/empty-admin.svg").default;
const emptyArchivedImg = require("src/shared/images/empty/empty-user.svg").default;

export type AdminWalletsStateFilters = {
    status?: string[];
    query?: string | undefined;
    page: number;
};

const defaultFilter: AdminWalletParams = {
    page: 1,
    archived: false,
    pageSize: 20,
    query: "",
};

export const AdminWalletsPanel = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const [params, setParams] = useState(defaultFilter);

    const { wallets, loading, totalCount, pageSize, currentPage } = useAdminWallets(params);

    const isWalletRestricted = useSubscriptionRestrictions(SubscriptionRestrictions.RESTRICTIONS_WALLET);

    useEffect(() => {
        dispatch(
            fetchAdminWallets({
                ...params,
                page: currentPage,
                pageSize,
            })
        );
    }, [currentPage, pageSize, params]);

    useEffect(() => {
        dispatch(setAdminWalletCurrentPage({ currentPage: 1 }));
    }, [params]);

    const handleCurrentPage = (newCurrentPage: number) => {
        dispatch(setAdminWalletCurrentPage({ currentPage: newCurrentPage }));
    };

    const handleSetPageSize = (newPageSize: number) => {
        dispatch(setAdminWalletPageSize({ pageSize: newPageSize }));
    };

    const navigateToCreate = () => {
        navigate("/admin/wallets/create");
    };

    const onClickCopy = (reference: string) => {
        navigator.clipboard.writeText(reference);
        toast.success(t("Wallets.wallet-ref-copied"));
    };

    const handleRedirectToDetails = (id: number) => {
        navigate(`/admin/wallets/${id}/details`);
    };

    const tableRows = wallets.map((wallet) => {
        return [
            {
                content: <Avatar icon='wallet' />,
                type: "image",
                onClick: () => handleRedirectToDetails(wallet.id),
            },
            {
                content: <Typography message={wallet.label} className='fw-bold ml-1' />,
                type: "text",
                onClick: () => handleRedirectToDetails(wallet.id),
            },
            {
                content: (
                    <div className='d-flex align-items-center gap-1'>
                        <Typography message={wallet.reference} className='fw-bold' />
                        <Icon
                            className='cursor-pointer'
                            name='square-2-stack'
                            onClick={(event) => {
                                event.stopPropagation();
                                onClickCopy(wallet.reference);
                            }}
                        />
                    </div>
                ),
                type: "text",
                onClick: () => handleRedirectToDetails(wallet.id),
            },
            {
                content: (
                    <Tag
                        size='sm'
                        type={"accent"}
                        color={(wallet.balance ?? 0) > 0 ? "success" : "neutral"}
                        label={formatMoneyToString({ amount: wallet.balance ?? 0 })}
                    />
                ),
                type: "text",
                onClick: () => handleRedirectToDetails(wallet.id),
            },
            {
                content: (
                    <div className='centered-cell'>
                        {wallet.isOrderedSignatures ? (
                            <Icon className='color-lilas-900' name='check-circle' />
                        ) : (
                            <Icon className='color-neutral-400' name='x-circle' />
                        )}
                    </div>
                ),
                type: "text",
                onClick: () => handleRedirectToDetails(wallet.id),
            },
            {
                content: (
                    <div className='centered-cell'>
                        <Typography message={wallet.nbSignatures} />
                    </div>
                ),
                type: "text",
                onClick: () => handleRedirectToDetails(wallet.id),
            },
        ];
    });

    return (
        <div className='admin-wallet-table-container'>
            <PageHeader title={t<string>("Wallets.my-wallets")}>
                <div className='filter-container'>
                    <AdminWalletsFilters
                        archiveLabel={t("Wallets.archived-wallets")}
                        filters={params}
                        setFilters={setParams}
                    />
                    <ToolTip
                        position='bottom'
                        content={
                            <div className='tooltip-limited'>
                                <Typography message={t("Subscription.not-accessible-feature")} className='fw-bold' />
                                <Typography message={t("Subscription.unlock-unlimited-access")} />
                            </div>
                        }
                        activation={isWalletRestricted}
                    >
                        <Button
                            disabled={isWalletRestricted}
                            onClick={navigateToCreate}
                            variant='ghost'
                            icon='plus-circle'
                            color='lilas'
                            label={t("CommonUse.add", { entity: t("Wallets.a-wallet") })}
                        />
                    </ToolTip>
                </div>
            </PageHeader>
            <SelectableTable
                isLoading={loading}
                pagination={{
                    currentPage,
                    totalCount,
                    pageSize: pageSize,
                    onPageChange: handleCurrentPage,
                    onPageSizeChange: handleSetPageSize,
                }}
                header={[
                    "",
                    t("CommonUse.label"),
                    t("CommonUse.reference"),
                    t("CommonUse.balance"),
                    <Icon name='task' />,
                    <Icon name='double-check-circle' />,
                ]}
                empty={
                    params !== defaultFilter ? (
                        <Empty
                            image={emptyArchivedImg}
                            title={t("Table.no-result-found")}
                            description={t<string>("Table.no-result-found-description")}
                        />
                    ) : (
                        <Empty
                            image={emptyListImg}
                            title={t("Table.welcome-to-julaya")}
                            description={t<string>("Table.welcome-to-julaya-description")}
                            button={
                                <ToolTip
                                    position='right'
                                    content={
                                        <div className='tooltip-limited'>
                                            <Typography
                                                message={t("Subscription.not-accessible-feature")}
                                                className='fw-bold'
                                            />
                                            <Typography message={t("Subscription.unlock-unlimited-access")} />
                                        </div>
                                    }
                                    activation={isWalletRestricted}
                                >
                                    <Button
                                        disabled={isWalletRestricted}
                                        onClick={navigateToCreate}
                                        icon='plus-circle'
                                        label={t("CommonUse.add", { entity: t("Wallets.a-wallet") })}
                                    />
                                </ToolTip>
                            }
                        />
                    )
                }
                rows={tableRows as SelectableTableRow[][]}
            />
        </div>
    );
};
