import { IconListType } from "src/shared/atoms/Icons/IconList";

export const NOTIFICATION_ICONS: {
    [key: string]: { name: IconListType };
} = {
    "bank-disposal": {
        name: "wallet",
    },
    "document-status": {
        name: "document",
    },
    "account-kyc": {
        name: "shield-check",
    },
    single: {
        name: "bolt",
    },
    batch: {
        name: "task",
    },
    "card-holder-kyc": {
        name: "document",
    },
    "task-to-sign": {
        name: "pencil-square",
    },
    "card-status": {
        name: "credit-card",
    },
    purchase: {
        name: "cart",
    },
    "batch-send": {
        name: "task",
    },
    "batch-sign": {
        name: "task",
    },
    "batch-comment": {
        name: "task",
    },
    "batch-mark-as-done": {
        name: "task",
    },
    "bill-payment": {
        name: "receipt-accepted",
    },
    "bill-repayment": {
        name: "receipt-accepted",
    },
    company_kyb: {
        name: "document",
    },
};

export const getIconName = (slug: string) => {
    const icon = NOTIFICATION_ICONS[slug];
    if (icon) {
        return icon.name;
    }

    return "bolt";
};
