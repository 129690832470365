import { getWalletBeneficiariesByCategory } from "../walletsBeneficiaries/selector";
import { fetchAuthorizedBeneficiaryWallet } from "../walletsBeneficiaries/thunkActions";
import { useLocation, useNavigate } from "react-router-dom";
import { WalletApi, WalletBeneficiary } from "src/services/wallets/types";
import { TransactionNotify } from "src/shared/models/Task";

import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { getSendWallets } from "../wallets/selectors";
import { useAppDispatch, useAppSelector } from "src/store";

import {  TRANSFERT_STEPS } from "src/components/Transfert/Panel";

export function useTransactionTimerRedirection({
    createdTransaction,
    walletId,
}: {
    walletId: number;
    createdTransaction?: TransactionNotify;
}) {
    const navigate = useNavigate();
    const [timer, setTimer] = useState(10);

    //@ts-expect-error
    useEffect(() => {
        if (timer > 0) {
            const timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(timeoutId);
        } else {
            if (!createdTransaction?.isSent) {
                navigate("/tasks", { state: { taskId: createdTransaction?.id } });
            } else {
                navigate(`/history/wallet/${walletId.toString()}`);
            }
        }
    }, [timer, navigate, walletId]);

    return timer;
}

type PopulateRapidTransfertState = {
    setAmount: Dispatch<SetStateAction<number>>;
    setComment: Dispatch<SetStateAction<string>>;
    setSelectedDebitAccount: Dispatch<SetStateAction<WalletApi | null>>;
    setBeneficiary: Dispatch<SetStateAction<WalletBeneficiary | null>>;
    setStep: Dispatch<SetStateAction<TRANSFERT_STEPS>>;
};

type RapidTransfertLocationState =
    | {
          amount: number;
          selectedDebitAccountId: number;
          beneficiaryExternalReference: string;
          beneficiaryServiceSlug: string;
          comment: string;
      }
    | undefined;

export function usePopulateRapidTransfertState({
    setAmount,
    setComment,
    setBeneficiary,
    setSelectedDebitAccount,
    setStep,
}: PopulateRapidTransfertState) {
    const dispatch = useAppDispatch();
    const providedState = useLocation().state as RapidTransfertLocationState;

    const [stateFilled, setStateFilled] = useState(false);

    const beneficiaries = useAppSelector((state) =>
        getWalletBeneficiariesByCategory(state, {
            walletId: providedState?.selectedDebitAccountId,
        })
    );
    const wallets = useAppSelector((state) => getSendWallets(state));

    useEffect(() => {
        if (providedState?.selectedDebitAccountId) {
            dispatch(fetchAuthorizedBeneficiaryWallet({ walletId: providedState.selectedDebitAccountId, way: 'out'}));
        }
    }, [providedState?.selectedDebitAccountId, dispatch]);

    useEffect(() => {
        if (!stateFilled) {
            const beneficiary = Object.values(beneficiaries ?? {}).reduce<WalletBeneficiary | undefined>(
                (res, data) => {
                    const beneficiary = data.find(
                        (beneficiary) =>
                            beneficiary.serviceSelected.externalReference === providedState?.beneficiaryExternalReference &&
                            providedState?.beneficiaryServiceSlug === beneficiary.serviceSelected.serviceSlug
                    );

                    return res ?? beneficiary;
                },
                undefined
            );
            const wallet = Object.values(wallets).find((wallet) => wallet.id === providedState?.selectedDebitAccountId);

            if (providedState && beneficiary && wallet) {
                setAmount(providedState.amount);
                setComment(providedState.comment);
                setBeneficiary(beneficiary);
                setSelectedDebitAccount(wallet);
                setStep(TRANSFERT_STEPS.DETAILS);
                setStateFilled(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providedState, beneficiaries, wallets]);
}
