import { TFunction } from "i18next";

import { IconListType } from "../atoms/Icons/IconList";
import { SelectOption } from "../components/SelectMultipleOptions/SelectMultipleOptions";

export const sectorOptions = (t: TFunction<string, string, string>): SelectOption[] => [
    { id: "other", label: t("Bills.sector-other") },
    { id: "manufacturing", label: t("Bills.sector-manufacturing") },
    { id: "agriculture", label: t("Bills.sector-agriculture") },
    { id: "education", label: t("Bills.sector-education") },
    { id: "transport", label: t("Bills.sector-transport") },
    { id: "health-care", label: t("Bills.sector-health-care") },
    { id: "real-estate", label: t("Bills.sector-real-estate") },
    { id: "hospitality", label: t("Bills.sector-hospitality") },
    { id: "oil-and-gas", label: t("Bills.sector-oil-and-gas") },
    { id: "renewable-energy", label: t("Bills.sector-renewable-energy") },
    { id: "electricity", label: t("Bills.sector-electricity") },
    { id: "water", label: t("Bills.sector-water") },
    { id: "electronics", label: t("Bills.sector-electronics") },
    { id: "law", label: t("Bills.sector-law") },
    { id: "security", label: t("Bills.sector-security") },
    { id: "research", label: t("Bills.sector-research") },
    { id: "trade", label: t("Bills.sector-trade") },
    { id: "financial-services", label: t("Bills.sector-financial-services") },
    { id: "insurance", label: t("Bills.sector-insurance") },
    { id: "mining", label: t("Bills.sector-mining") },
    { id: "infrastructure", label: t("Bills.sector-infrastructure") },
    { id: "management", label: t("Bills.sector-management") },
    { id: "aerospace", label: t("Bills.sector-aerospace") },
    { id: "advertising", label: t("Bills.sector-advertising") },
    { id: "waste-management", label: t("Bills.sector-waste-management") },
    { id: "fashion", label: t("Bills.sector-fashion") },
    { id: "forestry", label: t("Bills.sector-forestry") },
    { id: "distribution", label: t("Bills.sector-distribution") },
    { id: "food", label: t("Bills.sector-food") },
    { id: "retail", label: t("Bills.sector-retail") },
    { id: "construction", label: t("Bills.sector-construction") },
    { id: "telecommunications", label: t("Bills.sector-telecommunications") },
    { id: "biotechnology", label: t("Bills.sector-biotechnology") },
    { id: "e-commerce", label: t("Bills.sector-e-commerce") },
    { id: "engineering", label: t("Bills.sector-engineering") },
    { id: "marketing", label: t("Bills.sector-marketing") },
    { id: "textiles", label: t("Bills.sector-textiles") },
    { id: "government", label: t("Bills.sector-government") },
];

export const sectorIcon: { [key: string]: IconListType } = {
    other: "kiosk",
    manufacturing: "factory",
    agriculture: "agriculture",
    education: "academic",
    transport: "bus",
    "health-care": "health-care",
    "real-estate": "office",
    hospitality: "desk-bell",
    "oil-and-gas": "gas",
    "renewable-energy": "leaf",
    electricity: "bolt",
    water: "water",
    electronics: "electronics",
    law: "scale",
    security: "shield-check",
    research: "magnifying-glass",
    trade: "kiosk",
    "financial-services": "bank",
    insurance: "umbrella",
    mining: "mining",
    infrastructure: "cube",
    management: "user-group",
    aerospace: "rocket",
    advertising: "newspaper",
    "waste-management": "thrash",
    fashion: "mode",
    forestry: "tree",
    distribution: "truck",
    food: "food",
    retail: "boxes",
    construction: "wrench-screwdriver",
    telecommunications: "phone",
    biotechnology: "biotechnology",
    "e-commerce": "cart",
    engineering: "gear",
    marketing: "marketing",
    textiles: "palette",
    government: "embassy",
};
