import { BankDisposalFormType } from "./Panel";
import { FormikHandlers, FormikHelpers, FormikState } from "formik";
import { formatMoneyToString } from "src/shared/utils/formatMoney";

import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { getBankDisposalWallets } from "src/modules/wallets/selectors";
import { useAppSelector } from "src/store";

import { Typography } from "src/shared/atoms/Typography/Typography";

type Props = {
    form: FormikState<BankDisposalFormType> & FormikHelpers<BankDisposalFormType> & FormikHandlers;
    showSuccess: boolean;
};

export const RechargementSuccess = ({ form }: Props) => {
    const { t } = useTranslation();

    const wallets = useAppSelector((state) => getBankDisposalWallets(state));

    const selectedWallet = useMemo(() => {
        return wallets.find((item) => String(item.id) === String(form.values.walletId));
    }, [wallets, form.values.walletId]);

    return (
        <>
            <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                <Typography className='fw-bold mb-1' message={t("RechargementSuccess.success-dialog-title")} />
                <Typography className='mb-3' message={t("RechargementSuccess.success-dialog-subtitle")} />

                <img
                    className='mx-auto mb-2'
                    height={200}
                    src={require("src/shared/images/rapid-transfer/rapid-transfer-success.svg").default}
                    alt=''
                />
            </div>

            <div className='divider my-3' />

            <div className='d-flex align-items-center justify-content-between mb-3 text-white-space-pre'>
                <Typography className='fw-bold' message={t("DisposalInfoContainer.compte-a-recharger")} />
                <Typography
                    className='fw-bold ml-3 no-text-overflow'
                    message={`${selectedWallet?.label ?? ""} - ${selectedWallet?.reference ?? ""}`}
                />
            </div>
            <div className='d-flex align-items-center justify-content-between'>
                <Typography className='fw-bold' message={t("Tasks.total-amount")} />
                <Typography
                    className='fw-bold color-success-500'
                    message={`+ ${formatMoneyToString({ amount: form.values.amount })}`}
                />
            </div>
        </>
    );
};
