import { CreateUserDetailResponse } from "src/services/admin/types";
import { UserModel } from "src/shared/models/User";
import { CompanyRole } from "src/shared/models/UserCompany";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { logoutResetStore } from "src/modules/auth/Actions";

interface AdminUserDetailState {
    loading: boolean;
    userDetail: UserModel | null;
    error: string | null;
    phoneValidated: boolean;
    phoneValidationLoading: boolean;
    phoneValidationError: string | null;
    createUserLoading: boolean;
    createUserError: string | null;
    editUserLoading: boolean;
    editUserError: string | null;
}

const initialState = {
    loading: false,
    userDetail: null,
    error: null,

    phoneValidated: false,
    phoneValidationError: null,
    phoneValidationLoading: false,

    createUserError: null,
    createUserLoading: false,

    editUserError: null,
    editUserLoading: false,
} as AdminUserDetailState;

const reformUserData = (response: UserModel) => ({
    ...response,
    isActive: response?.UserCompanies?.[0]?.isActive,
    addAccessType: response.accessType !== CompanyRole.COLLECTOR,
    accessType: response.role !== CompanyRole.COLLECTOR ? response.role : undefined,
    addWaveCollect: !!response.collectorInfo,
    customWavePhone: response?.customWavePhone ? response.customWavePhone : response.phone,
    wavePhone: response?.collectorInfo?.phone || "",
    waveCountryCode: response?.collectorInfo?.countryCode,
    waveWalletId: response?.collectorInfo?.wallet?.id || "",
});

export const adminUserDetailSlice = createSlice({
    name: "adminUserDetail",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(logoutResetStore, () => {
            return initialState;
        });
    },
    reducers: {
        requestUserDetail: (state) => {
            return {
                ...state,
                userDetail: null,
                loading: true,
            };
        },
        receiveUserDetail: (state, { payload }: PayloadAction<CreateUserDetailResponse>) => {
            return {
                ...state,
                userDetail: reformUserData({
                    ...payload,
                    isActive: payload.isActive,
                    prefix: payload.gender === 0 ? "Monsieur" : "Madame",
                    accessType: payload.role,
                    role: payload.role,
                    wallets: payload.Wallets,
                    userWallets: payload?.UserWallets || [],
                }),
                loading: false,
            };
        },
        receiveUserDetailWithPhone: (state, { payload }: PayloadAction<CreateUserDetailResponse | null>) => {
            return {
                ...state,
                userDetail: payload ? reformUserData(payload) : payload,
                loading: false,
                error: null,
            };
        },
        failedRequestUserDetail: (state) => {
            return {
                ...state,
                userDetail: null,
                loading: false,
            };
        },
        failedRequestUserDetailWithPhone: (state, action: PayloadAction<{ error: string }>) => {
            const {
                payload: { error },
            } = action;

            return {
                ...state,
                loading: false,
                error: error,
            };
        },
        requestPhoneValidation: (state) => {
            return {
                ...state,
                phoneValidationError: null,
                phoneValidated: false,
                phoneValidationLoading: true,
            };
        },
        receivePhoneValidation: (state) => {
            return {
                ...state,
                phoneValidated: true,
                phoneValidationLoading: false,
                phoneValidationError: null,
            };
        },
        failedRequestPhoneValidation: (state, action: PayloadAction<{ error: string }>) => {
            const {
                payload: { error },
            } = action;
            return {
                ...state,
                phoneValidated: false,
                phoneValidationLoading: false,
                phoneValidationError: error,
            };
        },
        requestCreatUser: (state) => {
            return {
                ...state,
                createUserLoading: true,
            };
        },
        receiveCreateUser: (state, { payload }: PayloadAction<CreateUserDetailResponse>) => {
            return {
                ...state,
                userDetail: payload ? reformUserData(payload) : payload,
                createUserLoading: false,
                createUserError: null,
            };
        },
        failedCreateUser: (state, action: PayloadAction<{ error: string }>) => {
            const {
                payload: { error },
            } = action;
            return {
                ...state,
                createUserLoading: false,
                createUserError: error,
            };
        },
        requestEditUser: (state) => {
            return {
                ...state,
                editUserLoading: true,
                editUserError: null,
            };
        },
        receiveEditUser: (state, { payload }: PayloadAction<CreateUserDetailResponse>) => {
            const data = {
                ...payload,
                active: payload.isActive,
                prefix: payload.gender === 0 ? "Monsieur" : "Madame",
                accessType: payload.role,
                role: payload.role,
                wallets: payload.Wallets,
                userWallets: payload?.UserWallets || [],
            };

            return {
                ...state,
                userDetail: payload ? reformUserData(data) : data,
                editUserLoading: false,
                editUserError: null,
            };
        },
        failedEditUser: (state, action: PayloadAction<{ error: string }>) => {
            const {
                payload: { error },
            } = action;
            return {
                ...state,
                editUserLoading: false,
                editUserError: error,
            };
        },
        receiveActivateUser: (state) => {
            return {
                ...state,
                editUserLoading: false,
                editUserError: null,
            };
        },
        receiveArchiveUser: (state) => {
            return {
                ...state,
                editUserLoading: false,
                editUserError: null,
            };
        },
        clearUserDetail: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    requestUserDetail,
    failedRequestUserDetail,
    receiveUserDetail,
    receiveUserDetailWithPhone,
    failedRequestUserDetailWithPhone,
    requestPhoneValidation,
    receivePhoneValidation,
    failedRequestPhoneValidation,
    requestCreatUser,
    receiveCreateUser,
    failedCreateUser,
    requestEditUser,
    receiveEditUser,
    failedEditUser,
    receiveActivateUser,
    receiveArchiveUser,
    clearUserDetail,
} = adminUserDetailSlice.actions;
