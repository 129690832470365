import { AdminAccountsFormsRole, RolesParams } from "../../Forms/Role/Role";
import { roles } from "../../const";
import classnames from "classnames";
import { EditUserContext } from "src/shared/context/editUser";

import { useContext, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { getUserDetail } from "src/modules/admin/userDetail/selectors";
import { useAppSelector } from "src/store";

import { Button } from "src/shared/atoms/Buttons/Button";
import { Radio } from "src/shared/atoms/Radio/Radio";
import { Typography } from "src/shared/atoms/Typography/Typography";

export function AdminAccountDetailDrawerSectionsRole() {
    const { editingSection, setEditingSection, canRoleEdit, setPayload, handleOpenPasswordDialog } =
        useContext(EditUserContext);
    const { t } = useTranslation();

    const { userDetail } = useAppSelector((state) => getUserDetail(state));
    const [isValidated, setIsValidated] = useState(false);

    const isEditing = editingSection === "role";

    const [color, text] = useMemo(() => {
        if (!canRoleEdit) {
            return ["color-lilas-500", t("CommonUse.non-modifiable")];
        } else if (!isEditing) {
            return ["color-lilas-900", t("CommonUse.modify")];
        } else {
            return ["color-warning", t("CommonUse.being-modified")];
        }
        // eslint-disable-next-line
    }, [canRoleEdit, editingSection]);

    const handleChangeValue = (values: RolesParams) => {
        const params = {
            ...(userDetail?.collectorInfo && {
                collectorInfo: {
                    walletId: userDetail?.collectorInfo?.wallet?.id,
                    phone: userDetail?.collectorInfo?.phone,
                    countryCode: userDetail?.collectorInfo?.countryCode,
                },
            }),
            accessType: values.accessType,
        };
        setPayload(params);
    };

    const handleValidatedProfile = (validated: boolean) => {
        setIsValidated(validated);
    };

    const handleToggleEditing = () => {
        if (canRoleEdit) {
            setEditingSection(!isEditing ? "role" : null);
            setPayload(null);
        }
    };

    return (
        <div
            className={classnames("drawer-content-section users-details-drawer-role", {
                disabled: editingSection && !isEditing,
            })}
        >
            <div className='drawer-content-section-header'>
                <Typography className='fw-bold' message={t("CommonUse.user-role")} />
                <div className={classnames({ disabled: editingSection && !isEditing })} onClick={handleToggleEditing}>
                    <Typography className={`fw-bold cursor-pointer ${color}`} message={text} />
                </div>
            </div>

            <div className='drawer-content-section-body'>
                {!isEditing ? (
                    <>
                        <div className='roles-container'>
                            {roles.map(
                                (item, index) =>
                                    userDetail?.role === item.role && (
                                        <Radio
                                            key={index}
                                            checked
                                            readOnly
                                            label={
                                                <div className='d-flex flex-column'>
                                                    <div className='mb-2'>
                                                        <Typography className='fw-bold' message={item.label} />
                                                    </div>
                                                    <Typography
                                                        className='sm color-neutral'
                                                        message={item.description}
                                                    />
                                                </div>
                                            }
                                        />
                                    )
                            )}
                        </div>
                    </>
                ) : (
                    <AdminAccountsFormsRole
                        editMode
                        detail={userDetail}
                        onChange={handleChangeValue}
                        onValidated={handleValidatedProfile}
                    />
                )}
            </div>

            {isEditing && (
                <div className='drawer-content-section-footer'>
                    <div className='button-part'>
                        <Button
                            label={t("CommonUse.cancel")}
                            variant='tertiary'
                            onClick={handleToggleEditing}
                            className='flex-grow-1'
                        />
                        <Button
                            disabled={!isValidated}
                            label={t("CommonUse.modify")}
                            className='flex-grow-1'
                            onClick={handleOpenPasswordDialog}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}
