import { BillingHistoryFilters } from "./Filters";

import { useEffect, useState } from "react";

import {
    getBillingTransactionFilters,
    getBillingTransactions,
    getBillingTransactionsCount,
    getBillingTransactionsCurrentPage,
    getBillingTransactionsIsActiveFilters,
    getBillingTransactionsLoading,
    getBillingTransactionsShowPageSize,
} from "src/modules/billing/billingTransactions/selectors";
import {
    setBillingTransactionCurrentPage,
    setBillingTransactionShowPageSize,
} from "src/modules/billing/billingTransactions/slice";
import { fetchBillingTransactions } from "src/modules/billing/billingTransactions/thunkActions";
import { useAppDispatch, useAppSelector } from "src/store";

import { TransactionHistoryTable } from "src/components/History/Table";
import { HistoryWalletDrawerContainer } from "src/components/History/Wallet/Drawer/Container";
import { PageHeader } from "src/shared/components/PageHeader/PageHeader";

import "./styles.scss";

export const BillingHistoryPanel = () => {
    const dispatch = useAppDispatch();

    const [selectedTransactionUuid, setSelectedTransactionUuid] = useState<string | null>(null);
    const filters = useAppSelector((state) => getBillingTransactionFilters(state));

    const isLoading = useAppSelector((state) => getBillingTransactionsLoading(state));
    const totalCount = useAppSelector((state) => getBillingTransactionsCount(state));
    const pageSize = useAppSelector((state) => getBillingTransactionsShowPageSize(state));
    const currentPage = useAppSelector((state) => getBillingTransactionsCurrentPage(state));

    const transactions = useAppSelector((state) => getBillingTransactions(state));
    const activeFilters = useAppSelector((state) => getBillingTransactionsIsActiveFilters(state));

    useEffect(() => {
        handleFetchBillingTransactions();
    }, [currentPage, filters, pageSize]);

    const handleFetchBillingTransactions = () => {
        dispatch(
            fetchBillingTransactions({
                filters,
                page: currentPage,
                pageSize,
            })
        );
    };

    const handleSetPagesize = (newPageSize: number) => {
        dispatch(setBillingTransactionShowPageSize({ pageSize: newPageSize }));
    };

    const handleSetCurrentPage = (newCurrentPage: number) => {
        dispatch(setBillingTransactionCurrentPage({ currentPage: newCurrentPage }));
    };

    const handleOpenDrawer = (transactionId: string | null) => () => {
        setSelectedTransactionUuid(transactionId);
    };

    const handleCloseDrawer = () => {
        setSelectedTransactionUuid(null);
    };

    return (
        <>
            <div className='billing-history-container'>
                <PageHeader>
                    <BillingHistoryFilters />
                </PageHeader>
                <TransactionHistoryTable
                    transactions={transactions}
                    handleOpenDrawer={handleOpenDrawer}
                    currentPage={currentPage}
                    setPageSize={handleSetPagesize}
                    showPageSize={pageSize}
                    totalCount={totalCount}
                    setCurrentPage={handleSetCurrentPage}
                    activeFilters={activeFilters}
                    isLoading={isLoading}
                />
            </div>
            <HistoryWalletDrawerContainer
                selectedTransactionUuid={selectedTransactionUuid}
                handleCloseDrawer={handleCloseDrawer}
            />
        </>
    );
};
